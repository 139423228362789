import React from 'react'
import { Box } from 'rebass'
import { Label, Textarea } from '@rebass/forms'

const AreaField = ({ value, onChange, name, errors = null, helpText = '', label = null, placeholder = '', disabled, slim = false }) => (
    <Box>
        {label && <Label htmlFor={name} mb={1} flexDirection='column'>{label} {helpText && <><br /><small>{helpText}</small></>}</Label>}
        <Textarea
            id={name}
            name={name}
            placeholder={placeholder}
            value={value || ''}
            onChange={(e) => onChange(e.target.value)}
            fontSize={0}
            fontWeight='500'
            p={slim ? 1 : 3}
            fontFamily='body'
            minHeight={slim ? '' : '120px'}
            disabled={disabled}
        />
        {errors && (
            <Box mt={-18} mb={20} color='red' fontSize={12}>
                {errors[0]}
            </Box>
        )}
    </Box>
)

export default AreaField
