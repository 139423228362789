import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import CardDetails from './CardDetails';
import { loadStripe } from '@stripe/stripe-js';

const PaymentMethod = ({ children, afterAction, secret, onReady, isLoading }) => {
    const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

    const options = {
        clientSecret: secret,
    };

    return (
        <Elements stripe={promise} options={options}>
            <CardDetails afterAction={afterAction} onReady={onReady} forceLoading={isLoading}>
                {children}
            </CardDetails>
        </Elements>
    );
};

export default PaymentMethod;
