import React, { useEffect, useState } from 'react';
import { Flex, Box, Button, Text } from 'rebass';
import { toast } from 'react-toastify';
import { getCompanyOverview } from '@app/Api';
import LoadingSpinner from '~/LoadingSpinner';
import TaskLink from '~/Dashboard/CompanyStages/TaskLink';
import { formatCurrency, getErrorMessage, isOnboarded, percentCompleted } from '@util/Functions';
import { Label } from '@rebass/forms';
import TaskProgressBar from '~/Dashboard/TaskProgressBar';
import { Link } from 'react-router-dom';
import { PageTitle } from '~/Common/PageTitle';
import { useTranslation } from "react-i18next";
import { Card } from '~/Common/Card';
import { OverviewRow } from '~/Dashboard/OverviewRow';
import { NotAvailableTooltip } from '~/Dashboard/NotAvailableTooltip';
import DynamicRevaluation from 'Pages/Common/DynamicRevaluation';
import PayForSubscription from 'Pages/Common/PayForSubscription';
import { Alert } from '~/Common/Alert';
import { connect } from 'react-redux';
import { updateCompany } from '@app/Store';


const CompanyOverview = ({ companyId, updateCompany }) => {
    const [company, setCompany] = useState(null);
    const [currentTasks, setCurrentTasks] = useState([]);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (companyId) {
            setLoading(true);
            getCompanyOverview(companyId)
                .then(({ data }) => {
                    setCompany(data)
                    updateCompany(data)
                    setCurrentTasks(data.currentTasks)
                    setLoading(false);
                })
                .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to load company data'), { toastId: 'getCompanyOverviewError' }));
        }
    }, [companyId, updateCompany]);

    if (loading || !company) return <LoadingSpinner />;

    const expandUsers = (users) => (Array.isArray(users) && users.length ? users.map(function (user) {
        return user.name ? user.name : user.email;
    }).join(', ') : <NotAvailableTooltip type="users">None</NotAvailableTooltip>);

    const subscription = company?.subscription

    return (
        <>
            {subscription && subscription?.permissions?.purchase && subscription?.trialing && subscription?.cancelled && <>
                <Alert isCloseable={false} variant='error'>
                    <Flex justifyContent="space-between" alignItems="center">
                        <Box>
                            {t('Make sure to activate your subscription before')}{' '}
                            <Text as="span" fontWeight={600}>{subscription?.endsAt}</Text>{'. '}
                            <Text fontSize={12} fontWeight={600}>
                                {t('Renew your client subscription now for ')}
                                {formatCurrency(subscription?.price?.unit_amount / 100, subscription?.price?.currency)} {t('p/month')}
                                {t(', otherwise the subscription will automatically cancel at the end of the grace period.')}
                            </Text>
                        </Box>
                        <PayForSubscription companyId={companyId} buttonText={t('Activate Subscription')} variant="gray" sx={{ py: '16px' }} shouldActivate={true} />
                    </Flex>
                </Alert>
            </>}

            <Box px={3}>
                <PageTitle
                    subtitle={t('companyOverview.subtitle')}
                />
            </Box>
            {company?.dynamicRevaluation && <DynamicRevaluation dynamicRevaluation={company.dynamicRevaluation} />}
            <Flex>
                <Box width={1 / 2}>
                    <Card title={`Overview for ${company.name}`}>
                        <Box mb={3} fontSize={'14px'} color="gray3" as="p">
                            {company.description}
                        </Box>
                        <Box mb={3} mt="20px" color="gray3" sx={{ letterSpacing: 0.2 }}>
                            <OverviewRow title="Start date">{company.created}</OverviewRow>
                            <OverviewRow title="Onboarding date">{company?.onboardingStartedOn ?? <NotAvailableTooltip />}</OverviewRow>
                            <OverviewRow title="Draft review date">{company?.reports?.current?.submittedForReviewOn ?? <NotAvailableTooltip />}</OverviewRow>
                            <OverviewRow title="Report released date">{company?.reports?.current?.publishedOn ?? <NotAvailableTooltip />}</OverviewRow>
                            <OverviewRow title="New notifications">{company?.notifications ?? 0}</OverviewRow>
                            <OverviewRow title="Last Insights Report">{company?.lastInsightReport ?? <NotAvailableTooltip />}</OverviewRow>
                            <OverviewRow title="Company Users">{expandUsers(company.users)}</OverviewRow>
                        </Box>
                        <Box mb={4} mt={4}>
                            <Flex>
                                <Label fontSize={10} fontWeight={500} mb={2} color="gray3">
                                    {currentTasks.completed} OF {currentTasks.total} TASKS COMPLETE
                                </Label>
                                <Box ml="auto" variant="taskPercent">
                                    {percentCompleted(currentTasks.completed, currentTasks.total)}%
                                </Box>
                            </Flex>
                            <TaskProgressBar completed={currentTasks.completed} total={currentTasks.total} />
                        </Box>
                        <Box>
                            {!isOnboarded(company) && (
                                <Link to={`/advisor/company/${companyId}/onboarding`}>
                                    <Button variant="primary">Complete Onboarding</Button>
                                </Link>
                            )}
                            {isOnboarded(company) && (
                                <Link to={`/advisor/company/${companyId}/stages`}>
                                    <Button variant="primary">Complete Projects</Button>
                                </Link>
                            )}
                        </Box>
                    </Card>
                </Box>
                <Box width={1 / 2}>
                    <Card title="Current Tasks">
                        {currentTasks?.tasks?.map((task) => (
                            <TaskLink {...task} key={task.id} companyId={companyId} leftBorder />
                        ))}
                        {!currentTasks?.tasks?.length > 0 && <Box color="gray3">There are no pending tasks</Box>}
                    </Card>
                </Box>
            </Flex>
        </>
    );
};

export default connect((state) => ({}), { updateCompany })(CompanyOverview);
