import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex, Image } from 'rebass';
import { Icon } from '@svg';
import styles from './Assets/styles.module.scss';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '~/LoadingSpinner';
import { connect } from 'react-redux';
import { getActionItems } from '@app/Api/Company';
import { setActionItems } from '@app/Store/storeActionItems';
import { toast } from 'react-toastify';
import { defaultCompanyLogoLink, getErrorMessage } from '@util/Functions';
import { useMenuClose } from '@hooks/useMenuClose';
import { Button } from '~/Common/Button';
import { useIsMounted } from '@hooks/useIsMounted';
import ActionItemTile from './ActionItemTile';
import { Bubble } from '~/Common/Bubble';

const ActionItems = ({
    actionItems,
    companyId,
    requestClose,
    onEditItem,
    onCreateItem,
    setActionItems,
    user,
}) => {
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const wrapperRef = useRef(null);
    const isMounted = useIsMounted();
    const currentCompanyId = actionItems?.currentCompanyId;

    useMenuClose(wrapperRef, (e) => {
        if (!e?.target || typeof e.target.className.includes !== 'function' || !e.target.className.includes('button--action-items')) {
            requestClose();
        }
    });

    useState(() => {
        if (currentCompanyId === companyId || companyId === null) {
            return;
        }

        setLoading(true);
        getActionItems(companyId)
            .then(({ data }) => {
                if (isMounted.current) {
                    setActionItems({
                        currentCompanyId: companyId,
                        ...data,
                    });
                }
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to get company action items')))
            .finally(() => {
                if (isMounted.current) {
                    setLoading(false)
                }
            });
    }, [companyId, currentCompanyId]);

    if (isLoading) {
        return (
            <div className={styles.actionItemsWrapper}>
                <LoadingSpinner size="50" />
            </div>
        );
    } else if (companyId == null) {
        return (
            <div className={styles.actionItemsWrapper} ref={wrapperRef}>
                <Box
                    sx={{
                        maxHeight: '600px',
                        overflowY: 'auto',
                    }}
                >
                    {Array.isArray(actionItems.all.data) && actionItems.all.data.length ? actionItems.all.data.map(({ id, name, logo, actionItems }) => {
                        const url = `/advisor/company/${id}/`;
                        return (
                            <Box
                                key={id}
                                to={`${url}overview`}
                                as={Link}
                                sx={{
                                    display: 'block',
                                    padding: '12px 24px',
                                    transition: 'background-color 0.2s ease',
                                    borderRadius: '8px',
                                    background: '#f5f7f8',
                                    '&:hover': {
                                        background: '#d1f5ff',
                                    },
                                    '&:not(:last-of-type)': {
                                        mb: 2,
                                    }
                                }}
                            >
                                <Flex alignItems="center" justifyContent="space-between" sx={{ gap: '16px' }}>
                                    <Flex fontSize={13} fontWeight={600} sx={{ gap: '16px' }}>
                                        <Image
                                            src={logo || defaultCompanyLogoLink}
                                            alt="Company"
                                            onError={(e) => (e.target.src = defaultCompanyLogoLink)}
                                            className={styles.companyLogo}
                                            sx={{
                                                flexShrink: 0,
                                                width: '20px',
                                                height: '20px',
                                                borderRadius: '50%'
                                            }}
                                        />
                                        <Box>{name}</Box>
                                    </Flex>
                                    <Bubble
                                        sx={{
                                            background: '#e9eaef',
                                            color: '#8e93a0'
                                        }}
                                    >
                                        {Array.isArray(actionItems) ? actionItems.length : 0}
                                    </Bubble>
                                </Flex>
                            </Box>
                        );
                    }, []) : (
                        <Box
                            sx={{
                                textAlign: 'center',
                                py: 3,
                                fontWeight: 600,
                                maxHeight: '600px',
                                overflowY: 'auto',
                            }}
                        >
                            {t('There are no current action items.')}
                        </Box>
                    )}
                </Box>
            </div>
        );
    }

    return (
        <div className={`${styles.actionItemsWrapper} ${styles.hasItems}`} ref={wrapperRef}>
            {typeof onCreateItem === 'function' && user?.permissions?.actionItems?.create && (
                <Button
                    variant="secondary"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        width: '100%',
                        color: '#00C2FF',
                    }}
                    onClick={onCreateItem}
                >
                    <Icon size={19} icon="calendar" />
                    {t('Create an action item')}
                </Button>
            )}
            <Box
                sx={{
                    maxHeight: '600px',
                    overflowY: 'auto',
                    mt: 3,
                    pb: 3,
                }}
            >
                {Array.isArray(actionItems?.actionItems) &&
                    actionItems?.actionItems.map((m, index) => (
                        <ActionItemTile key={index} actionItem={m} onEditItem={onEditItem} />
                    ))}
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    py: '18px',
                    px: '30px',
                    height: '60px',
                    borderTop: '1px solid #E6EAEB',
                    fontWeight: 600,
                    color: '#949AA6',
                }}
            >
                {t('Completed')}
                {` (${actionItems?.complete ? actionItems.complete : 0})`}
            </Box>
        </div>
    );
};

export default connect(
    (state) => ({
        actionItems: state.actionItems,
        user: state.user,
    }),
    { setActionItems }
)(ActionItems);
