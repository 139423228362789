import React from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Text } from 'rebass';
import { Icon } from '@svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';

import UserAvatar from '~/Dashboard/Avatar';
import { Skeleton } from '../Skeleton';

const CompanyHeaderStyled = styled(Box)`
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 60px;
    ${(props) => props.sx}
`;

export const CompanyHeader = ({ companyId, companyName, companyLogo, condensed, color, sx, isAdvisor = true, ...props }) => {
    const companies = useSelector((state) => state.companies);

    const getCompanyDetails = () => {
        if (companies && companies.length) {
            return companies.find((company) => company.id === parseInt(companyId));
        }
        return false;
    };

    const company = getCompanyDetails();

    const linkToDashBoard = isAdvisor ? `/advisor/company/${companyId}/settings` : `/company/profile/settings/${companyId}`

    return (
        <CompanyHeaderStyled sx={sx} {...props}>
            <Link to={linkToDashBoard} ml={2} style={{ cursor: 'pointer' }}>
                {company ? <UserAvatar
                    logo={companyLogo || company?.logo}
                    theme={company?.logoTheme}
                    mb="15px"
                    firstName={company?.name}
                    width={condensed ? '27px' : '60px'}
                    height={condensed ? '27px' : '60px'}
                /> : <Skeleton variant="circle" size={condensed ? '27px' : '60px'} mb="15px" />}
            </Link>

            {!condensed && (
                <>
                    {company && !company?.live ? <Box className={styles.trainingRibbon}>Training</Box> : null}
                    {company && company?.archived ? <Box className={styles.archivedRibbon}>Archived</Box> : null}
                    {company ? <Text as="p" fontSize="16px" fontWeight="600" mb="2px">
                        {companyName ? companyName : company?.name}
                    </Text> : <Skeleton height="24px" width="80px" mb="2px" />}

                    {company ?
                        <Flex
                            as={Link}
                            to={linkToDashBoard}
                            sx={{
                                color: color,
                                mb: '65px',
                                mt: 'auto',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontWeight: 600,
                                fontSize: 0,
                                transition: 'background 0.3s ease-in-out',
                                px: 2,
                                py: 1,
                                borderRadius: '8px',
                                '&:hover': {
                                    background: '#413E70',
                                    color: '#fff'
                                }
                            }}
                        >
                            <Icon icon="cog" />
                            {isAdvisor ? (
                                <>
                                    <Text ml={2}>
                                        Company Settings
                                    </Text>
                                </>
                            ) : (
                                <>
                                    <Text ml={2} color={color}>
                                        Account Details
                                    </Text>
                                </>
                            )}
                        </Flex>
                        : null}
                </>
            )}
        </CompanyHeaderStyled>
    );
};
