import React, { useEffect, useRef } from 'react'
import { Flex, Box, Heading, Button, Text } from 'rebass';
import 'react-toastify/dist/ReactToastify.min.css'
// eslint-disable-next-line
import { jsx } from 'theme-ui'
import { Link, useHistory } from 'react-router-dom';
import { Icon } from '@svg';
import { isAdvisor, isAnalyst } from '@util/Functions';
import UserAvatar from '~/Dashboard/Avatar';
import { LanguageSwitcher } from '~/Common/LanguageSwitcher';
import { getRoleLabel } from '@util/Functions/User';
import { Skeleton } from '~/Common/Skeleton';

export const AccountMenu = ({ user, show, closeMenu, sx, ...props }) => {
    const history = useHistory();
    const menuRef = useRef(false);
    const accreditedAdvisor = isAdvisor(user) || isAnalyst(user);

    const menuItems = [
        {
            title: 'User Settings',
            to: accreditedAdvisor ? '/advisor/settings' : '/company/profile/settings',
            icon: 'cog'
        },
        {
            title: 'Logout',
            to: '/logout',
            icon: 'exit'
        }
    ];

    useEffect(() => {
        const onClick = e => {
            if (show && menuRef.current && !menuRef.current.contains(e.target)) {
                closeMenu();
            }
        };
        const onKeyPress = e => {
            if (show && e.keyCode === 27) {
                closeMenu();
            }
        }

        document.addEventListener('click', onClick);
        document.addEventListener('keyup', onKeyPress);
        return () => {
            document.removeEventListener('click', onClick);
            document.removeEventListener('keyup', onKeyPress);
        };
    }, [show, closeMenu]);



    return (
        <Box ref={menuRef} sx={{
            width: '240px',
            position: 'absolute',
            right: '30px',
            py: '16px',
            background: '#fff',
            boxShadow: '0px 0px 12px rgba(44, 41, 96, 0.08)',
            borderRadius: '16px',
            opacity: show ? 1 : 0,
            transform: show ? 'translateY(0)' : 'translateY(-100%)',
            transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out',
            pointerEvents: show ? 'all' : 'none',
            zIndex: '999',
            ...sx
        }} {...props}>
            <Flex
                as={Link}
                to={accreditedAdvisor ? `/advisor/settings` : `/company/profile/settings`}
                sx={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    mb: 4
                }}
            >
                {user ? (
                    <UserAvatar
                        firstName={user?.firstName}
                        surname={user?.surname}
                        email={user?.email}
                        avatar={user?.avatar}
                        mb="9px"
                        size="74px"
                        fontSize="24px"
                        icon="camera"
                    />
                ) : (
                    <Box mb="9px">
                        <Skeleton variant="circle" size="74px" mb={2} />
                        <Skeleton height="24px" />
                    </Box>
                )}

                <Heading fontSize={'14px'} mr={1}>
                    {user?.firstName !== null && user?.surname !== null ? <>
                        {user?.firstName} {user?.surname}
                    </> : <>
                        <Box fontSize={'12px'}>{user?.email}</Box>
                    </>}
                    {user?.accessLevel !== null && (
                        <Text as="p" sx={{ fontSize: '13px', textAlign: 'center', fontWeight: '300' }}>
                            {getRoleLabel(user?.accessLevel)}
                        </Text>
                    )}
                </Heading>
            </Flex>
            {process.env.NODE_ENV !== 'production' && (
                <Box sx={{
                    borderTop: '1px solid #EFF2F4',
                    pt: 2,
                    mb: 2,
                    fontSize: '0',
                    px: '16px'
                }}>
                    <LanguageSwitcher sx={{ justifyContent: 'center' }} />
                </Box>
            )}
            <Box sx={{
                borderTop: '1px solid #EFF2F4',
                pt: 2,
                px: '16px',
            }}>
                {menuItems.map(({ icon, ...item }, key) => (
                    <Button key={key} variant="transparent" mr={2} px={2} onClick={() => user ? history.push(item.to) : false} sx={{ width: '100%' }}>
                        <Flex alignItems="center" sx={{ cursor: 'pointer' }}>
                            {user ? (
                                <>
                                    <Flex sx={{ alignItems: 'center', justifyContent: 'center', width: '20px' }}>
                                        {icon && <Icon icon={icon} stroke="#093467" title={item.title} />}
                                    </Flex>
                                    <Text as="h3" ml={3} sx={{ fontSize: '16px' }}>{item.title}</Text>
                                </>
                            ) : (
                                <>
                                    <Skeleton variant="circle" size="20px" />
                                    <Skeleton ml={3} height="24px" width="70%" />
                                </>
                            )}
                        </Flex>
                    </Button>
                ))}
            </Box>
        </Box>
    )
}
