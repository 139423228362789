import React from 'react';
import { Flex, Box } from 'rebass';
import { useTranslation } from "react-i18next";
import { Card } from '~/Common/Card';
import DynamicRevaluationChart from '~/Dashboard/DynamicRevaluationChart';
import { Grid } from 'theme-ui';
import { DataCard } from '~/Common/DataCard';
import { Icon } from '@svg';


const DynamicRevaluation = ({ dynamicRevaluation }) => {
    const { t } = useTranslation();
    const statistics = dynamicRevaluation?.statistics

    return <Card title={t('Dynamic Revaluation')} icon="chart" iconSize={16}>
        <Grid columns="auto 242px" gap={24}>
            <Card sx={{ bg: 'white', m: 0 }}>
                <DynamicRevaluationChart logoStart={true} data={dynamicRevaluation?.graph} />
            </Card>
            <Flex flexDirection="column" sx={{ height: '100%', gap: '16px' }}>
                <DataCard
                    title={t('Real-Time Valuation')}
                    fontSize="40px"
                    sx={{ flex: 1 }}
                    caption={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Icon icon="valuationUp" color="#16E4B3" mr="8px" />
                            {`${statistics?.realtime?.percent}%${statistics?.realtime?.report ? ` Since '${statistics.realtime.report.toString().slice(2)} Report` : ''}`}
                        </Box>
                    }
                >
                    {statistics?.realtime?.value?.formatted}
                </DataCard>
                <DataCard
                    title={t('Total Valuation Increase')}
                    fontSize="40px"
                    sx={{ flex: 1 }}
                    caption={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Icon icon="valuationUp" color="#16E4B3" mr="8px" />
                            {`${statistics?.total?.percent}% Total`}
                        </Box>
                    }
                >
                    {statistics?.total?.value?.formatted}
                </DataCard>
            </Flex>
        </Grid>
    </Card>
};

export default DynamicRevaluation;
