import React from "react";
import { Box } from "rebass";
import styles from './styles.module.scss';

export const Bubble = ({
    children,
    count,
    top = undefined,
    right = undefined,
    left = undefined,
    bottom = undefined,
    small = false,
    size: _size = undefined,
    fontSize = undefined,
    show = true,
    sx,
    ...props
}) => {
    const isAbsolute = top || right || left || bottom;
    const size = _size !== undefined ? _size : (small ? '16px' : '20px');

    return (
        <Box
            {...props}
            className={styles.bubble}
            sx={{
                width: size,
                height: size,
                lineHeight: size,
                fontSize: fontSize === undefined ? (small ? '10px' : '12px') : fontSize,
                opacity: show ? 1 : 0,
                ...(isAbsolute ? {
                    position: 'absolute',
                    top,
                    right,
                    left,
                    bottom
                } : {}),
                ...sx
            }}
        >
            {count ?? children}
        </Box>
    )
}
