import React, { useEffect, useState } from 'react';
import { getCompanyCustomVariables, setCompanyCustomVariables } from '@app/Api';
import { toast } from 'react-toastify';
import LoadingSpinner from '~/LoadingSpinner';
import { getErrorMessage } from '@util/Functions';
import { Box, Button, Flex, Heading } from 'rebass';
import { PageTitle } from '~/Common/PageTitle';
import { SelectField, TextField } from '~/Form';
import { connect } from 'react-redux';
import { isAnalyst } from '@util/Functions/User';
import { useTranslation } from 'react-i18next';
import { Form } from '~/Common/Form';

const AdvisorCustomVariables = ({ companyId, user }) => {
    const [loading, setLoading] = useState(false);
    const [customVariables, setCustomVariables] = useState({});
    const [industryTargets, setIndustryTargets] = useState({});
    const [financialYear, setFinancialYear] = useState();
    const [years, setYears] = useState([]);
    const [errors, setErrors] = useState({});
    const [locked, setLocked] = useState(true);
    const [globalVariables, setGlobalVariables] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (companyId) {
            setLoading(true);
            getCompanyCustomVariables({ companyId })
                .then(({ customVariables, industryTargets, locked, globalVariables, years, year }) => {
                    setCustomVariables(customVariables);
                    setIndustryTargets(industryTargets);
                    setLocked(locked);
                    setGlobalVariables(globalVariables);
                    setYears(years);
                    setFinancialYear(year);
                    setLoading(false);
                })
                .catch(({ response }) =>
                    toast.error(getErrorMessage(response?.data, 'Unable to load custom variables'))
                );
        }
    }, [companyId]);

    const hasEditDisabled = !isAnalyst(user);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (hasEditDisabled) return null;
        setLoading(true);
        setErrors({});
        setCompanyCustomVariables({ companyId, customVariables, year: financialYear })
            .then((response) => {
                if (response.message) {
                    toast.error(response.message);
                }

                toast.success('Custom Variables uploaded successfully');
            })
            .catch(({ response }) => {
                response?.data?.errors && setErrors(response.data.errors);
                setLoading(false);
                toast.error(getErrorMessage(response?.data, 'Unable to update company custom variables'));
            })
            .finally(() => setLoading(false));
    };

    const changeYear = (year) => {
        setFinancialYear(year);
        setLoading(true);
        getCompanyCustomVariables({ companyId, year: year })
            .then(({ customVariables, industryTargets, locked, globalVariables, years }) => {
                setCustomVariables(customVariables);
                setIndustryTargets(industryTargets);
                setLocked(locked);
                setGlobalVariables(globalVariables);
                setYears(years);
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to load custom variables')))
            .finally(() => setLoading(false));
    };

    const isGlobalVariable = (key) => globalVariables.includes(key);

    if (loading) return <LoadingSpinner />;

    return (
        <Box pb={4}>
            <Box px={3}>
                <PageTitle subtitle={t('companyCustomVariables.subtitle')}>
                    <SelectField
                        name="year"
                        value={financialYear}
                        onChange={changeYear}
                        options={years.map((year) => ({ label: year, value: year }))}
                        sx={{ padding: '10px 30px 10px 16px', minWidth: '200px', fontSize: 14, fontWeight: 600 }}
                        boxSx={null}
                    />
                </PageTitle>
            </Box>
            <Box variant="card.wrapper">
                <Box variant="card.header">Custom Variables</Box>
                <Box variant="card.container">
                    <Form onSubmit={handleSubmit}>
                        <Box mb={4}>
                            Fields marked with * are required for reports generation but can be entered at any time.
                        </Box>

                        <Box>
                            <Heading mb={3}>Beta Factor Scorecard</Heading>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Average market leveraging % *"
                                        value={customVariables.averageMarketLeveraging}
                                        onChange={(averageMarketLeveraging) =>
                                            setCustomVariables({ ...customVariables, ...{ averageMarketLeveraging } })
                                        }
                                        errors={errors['customVariables.averageMarketLeveraging']}
                                        disabled={
                                            hasEditDisabled || (isGlobalVariable('averageMarketLeveraging') && locked)
                                        }
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Marginal interest rate (kmd) % *"
                                        value={customVariables.marginalInterestRate}
                                        onChange={(marginalInterestRate) =>
                                            setCustomVariables({ ...customVariables, ...{ marginalInterestRate } })
                                        }
                                        errors={errors['customVariables.marginalInterestRate']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Marginal corporate tax rate (t) %"
                                        value={customVariables.marginalCorporateTaxRate}
                                        onChange={(marginalCorporateTaxRate) =>
                                            setCustomVariables({ ...customVariables, ...{ marginalCorporateTaxRate } })
                                        }
                                        placeholder={0}
                                        errors={errors['customVariables.marginalCorporateTaxRate']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Risk-free rate of return (Rf) *"
                                        value={customVariables.riskFreeRateOfReturn}
                                        onChange={(riskFreeRateOfReturn) =>
                                            setCustomVariables({ ...customVariables, ...{ riskFreeRateOfReturn } })
                                        }
                                        errors={errors['customVariables.riskFreeRateOfReturn']}
                                        disabled={
                                            hasEditDisabled || (isGlobalVariable('riskFreeRateOfReturn') && locked)
                                        }
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Market Risk Premium (MRP) *"
                                        value={customVariables.marketRiskPremium}
                                        onChange={(marketRiskPremium) =>
                                            setCustomVariables({ ...customVariables, ...{ marketRiskPremium } })
                                        }
                                        errors={errors['customVariables.marketRiskPremium']}
                                        disabled={hasEditDisabled || (isGlobalVariable('marketRiskPremium') && locked)}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Book Value of Total Debt / Total Finance *"
                                        value={customVariables.marketValueDebt}
                                        onChange={(marketValueDebt) =>
                                            setCustomVariables({ ...customVariables, ...{ marketValueDebt } })
                                        }
                                        errors={errors['customVariables.marketValueDebt']}
                                        disabled={hasEditDisabled || (isGlobalVariable('marketValueDebt') && locked)}
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Last Year End *"
                                        value={customVariables.lastYearEnd}
                                        onChange={(lastYearEnd) =>
                                            setCustomVariables({ ...customVariables, ...{ lastYearEnd } })
                                        }
                                        errors={errors['customVariables.lastYearEnd']}
                                        disabled={hasEditDisabled || (isGlobalVariable('lastYearEnd') && locked)}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Size Premium"
                                        value={customVariables.sizePremium}
                                        onChange={(sizePremium) =>
                                            setCustomVariables({ ...customVariables, ...{ sizePremium } })
                                        }
                                        errors={errors['customVariables.sizePremium']}
                                        disabled={hasEditDisabled || (isGlobalVariable('sizePremium') && locked)}
                                    />
                                </Box>
                            </Flex>
                        </Box>
                        <Box>
                            <Heading mb={3}>Profit Gap Analysis</Heading>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Ave. Benchmark Cost of Sales-Materials % *"
                                        value={customVariables.benchmarkCostOfSalesMaterials}
                                        onChange={(benchmarkCostOfSalesMaterials) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ benchmarkCostOfSalesMaterials },
                                            })
                                        }
                                        errors={errors['customVariables.benchmarkCostOfSalesMaterials']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Cost of sales (Materials / Other) *"
                                        value={customVariables.costOfSalesMaterials}
                                        onChange={(costOfSalesMaterials) =>
                                            setCustomVariables({ ...customVariables, ...{ costOfSalesMaterials } })
                                        }
                                        errors={errors['customVariables.costOfSalesMaterials']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Ave. Benchmark Wages, Salaries & Oncosts % *"
                                        value={customVariables.benchmarkCostSalaries}
                                        onChange={(benchmarkCostSalaries) =>
                                            setCustomVariables({ ...customVariables, ...{ benchmarkCostSalaries } })
                                        }
                                        errors={errors['customVariables.benchmarkCostSalaries']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Cost of sales (Wages, Salaries & Oncosts) *"
                                        value={customVariables.costOfSalesSalaries}
                                        onChange={(costOfSalesSalaries) =>
                                            setCustomVariables({ ...customVariables, ...{ costOfSalesSalaries } })
                                        }
                                        errors={errors['customVariables.costOfSalesSalaries']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Ave. Benchmark Non-Salary Overhead% *"
                                        value={customVariables.benchmarkCostNonSalaryOverhead}
                                        onChange={(benchmarkCostNonSalaryOverhead) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ benchmarkCostNonSalaryOverhead },
                                            })
                                        }
                                        errors={errors['customVariables.benchmarkCostNonSalaryOverhead']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Non-Salary Overhead *"
                                        value={customVariables.nonSalaryOverhead}
                                        onChange={(nonSalaryOverhead) =>
                                            setCustomVariables({ ...customVariables, ...{ nonSalaryOverhead } })
                                        }
                                        errors={errors['customVariables.nonSalaryOverhead']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                        </Box>
                        <Box>
                            <Heading mb={3}>Value Potential</Heading>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Class Best EBIT Rate *"
                                        value={customVariables.classBestEbitRate}
                                        onChange={(classBestEbitRate) =>
                                            setCustomVariables({ ...customVariables, ...{ classBestEbitRate } })
                                        }
                                        errors={errors['customVariables.classBestEbitRate']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Number of Shares Issued *"
                                        value={customVariables.numberOfShares}
                                        onChange={(numberOfShares) =>
                                            setCustomVariables({ ...customVariables, ...{ numberOfShares } })
                                        }
                                        errors={errors['customVariables.numberOfShares']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Overwrite Attractiveness NOPAT multiple"
                                        value={customVariables.nopatMultipleAttractiveness}
                                        onChange={(nopatMultipleAttractiveness) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ nopatMultipleAttractiveness },
                                            })
                                        }
                                        errors={errors['customVariables.nopatMultipleAttractiveness']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Overwrite Listed Company NOPAT multiple"
                                        value={customVariables.nopatMultipleListedCompany}
                                        onChange={(nopatMultipleListedCompany) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ nopatMultipleListedCompany },
                                            })
                                        }
                                        errors={errors['customVariables.nopatMultipleListedCompany']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Value Potential and Profit Gap Year"
                                        value={customVariables.overwrittenBaseYear}
                                        onChange={(overwrittenBaseYear) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ overwrittenBaseYear },
                                            })
                                        }
                                        errors={errors['customVariables.overwrittenBaseYear']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2} />
                            </Flex>
                            <Heading mb={3}>Business Valuation</Heading>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Surplus Assets"
                                        value={customVariables.surplusAssets}
                                        onChange={(surplusAssets) =>
                                            setCustomVariables({ ...customVariables, ...{ surplusAssets } })
                                        }
                                        placeholder="0"
                                        errors={errors['customVariables.surplusAssets']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Surplus Assets Description"
                                        value={customVariables.surplusAssetsDescription}
                                        onChange={(surplusAssetsDescription) =>
                                            setCustomVariables({ ...customVariables, ...{ surplusAssetsDescription } })
                                        }
                                        placeholder="0"
                                        errors={errors['customVariables.surplusAssetsDescription']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Financial Assets"
                                        value={customVariables.financialAssets}
                                        onChange={(financialAssets) =>
                                            setCustomVariables({ ...customVariables, ...{ financialAssets } })
                                        }
                                        placeholder="0"
                                        errors={errors['customVariables.financialAssets']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Non-Operating Assets"
                                        value={customVariables.nonOperatingAssets}
                                        onChange={(nonOperatingAssets) =>
                                            setCustomVariables({ ...customVariables, ...{ nonOperatingAssets } })
                                        }
                                        placeholder="0"
                                        errors={errors['customVariables.nonOperatingAssets']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Loans to Directors"
                                        value={customVariables.loanToDirectors}
                                        onChange={(loanToDirectors) =>
                                            setCustomVariables({ ...customVariables, ...{ loanToDirectors } })
                                        }
                                        placeholder="0"
                                        errors={errors['customVariables.loanToDirectors']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Loans from Directors"
                                        value={customVariables.loanFromDirectors}
                                        onChange={(loanFromDirectors) =>
                                            setCustomVariables({ ...customVariables, ...{ loanFromDirectors } })
                                        }
                                        placeholder="0"
                                        errors={errors['customVariables.loanFromDirectors']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                        </Box>
                        <Box>
                            <Heading mb={3}>Strategic Overview</Heading>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Benchmark Result (0 to 100) *"
                                        value={customVariables.scorecardBenchmark}
                                        onChange={(scorecardBenchmark) =>
                                            setCustomVariables({ ...customVariables, ...{ scorecardBenchmark } })
                                        }
                                        errors={errors['customVariables.scorecardBenchmark']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2} />
                            </Flex>
                        </Box>

                        <Box>
                            <Heading mb={3}>Financial Scorecard Ratio Targets</Heading>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Current Ratio (above)"
                                        value={customVariables['Current Ratio']}
                                        onChange={(value) =>
                                            setCustomVariables({ ...customVariables, ...{ 'Current Ratio': value } })
                                        }
                                        placeholder={industryTargets['Current Ratio'] || 2}
                                        errors={errors['customVariables.Current Ratio']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Quick Ratio (above)"
                                        value={customVariables['Quick Ratio']}
                                        onChange={(value) =>
                                            setCustomVariables({ ...customVariables, ...{ 'Quick Ratio': value } })
                                        }
                                        placeholder={industryTargets['Quick Ratio'] || 1}
                                        errors={errors['customVariables.Quick Ratio']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Account Receivable Days (below)"
                                        value={customVariables['Account Receivable Days']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Account Receivable Days': value },
                                            })
                                        }
                                        placeholder={industryTargets['Account Receivable Days'] || 45}
                                        errors={errors['customVariables.Account Receivable Days']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Account Payable Days (below)"
                                        value={customVariables['Account Payable Days']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Account Payable Days': value },
                                            })
                                        }
                                        placeholder={industryTargets['Account Payable Days'] || 55}
                                        errors={errors['customVariables.Account Payable Days']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Inventory (WIP) Days (below)"
                                        value={customVariables['Inventory (wip) Days']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Inventory (wip) Days': value },
                                            })
                                        }
                                        placeholder={industryTargets['Inventory (wip) Days'] || 60}
                                        errors={errors['customVariables.Inventory (wip) Days']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Gross Profit Margin (above)"
                                        value={customVariables['Gross Profit Margin']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Gross Profit Margin': value },
                                            })
                                        }
                                        placeholder={industryTargets['Gross Profit Margin'] || 35}
                                        errors={errors['customVariables.Gross Profit Margin']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Profitability % (above)"
                                        value={customVariables['Profitability %']}
                                        onChange={(value) =>
                                            setCustomVariables({ ...customVariables, ...{ 'Profitability %': value } })
                                        }
                                        placeholder={industryTargets['Profitability %'] || 5}
                                        errors={errors['customVariables.Profitability %']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Nopat % (above)"
                                        value={customVariables['Nopat %']}
                                        onChange={(value) =>
                                            setCustomVariables({ ...customVariables, ...{ 'Nopat %': value } })
                                        }
                                        placeholder={industryTargets['Nopat %'] || 3.5}
                                        errors={errors['customVariables.Nopat %']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Return On Equity (above)"
                                        value={customVariables['Return On Equity']}
                                        onChange={(value) =>
                                            setCustomVariables({ ...customVariables, ...{ 'Return On Equity': value } })
                                        }
                                        placeholder={industryTargets['Return On Equity'] || 12}
                                        errors={errors['customVariables.Return On Equity']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Return On Total Assets (above)"
                                        value={customVariables['Return On Total Assets']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Return On Total Assets': value },
                                            })
                                        }
                                        placeholder={industryTargets['Return On Total Assets'] || 7}
                                        errors={errors['customVariables.Return On Total Assets']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Return On Capital Employed (above)"
                                        value={customVariables['Return On Capital Employed']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Return On Capital Employed': value },
                                            })
                                        }
                                        placeholder={industryTargets['Return On Capital Employed'] || 10}
                                        errors={errors['customVariables.Return On Capital Employed']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Ecroce (above)"
                                        value={customVariables['Ecroce']}
                                        onChange={(value) =>
                                            setCustomVariables({ ...customVariables, ...{ Ecroce: value } })
                                        }
                                        placeholder={industryTargets['Ecroce'] || 8}
                                        errors={errors['customVariables.Ecroce']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Activity Ratio (above)"
                                        value={customVariables['Activity Ratio']}
                                        onChange={(value) =>
                                            setCustomVariables({ ...customVariables, ...{ 'Activity Ratio': value } })
                                        }
                                        placeholder={industryTargets['Activity Ratio'] || 5}
                                        errors={errors['customVariables.Activity Ratio']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Asset Turnover Ratio (above)"
                                        value={customVariables['Asset Turnover Ratio']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Asset Turnover Ratio': value },
                                            })
                                        }
                                        placeholder={industryTargets['Asset Turnover Ratio'] || 4}
                                        errors={errors['customVariables.Asset Turnover Ratio']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="GM Return on Inventory (above)"
                                        value={customVariables['GM Return on Inventory']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'GM Return on Inventory': value },
                                            })
                                        }
                                        placeholder={industryTargets['GM Return on Inventory'] || 300}
                                        errors={errors['customVariables.GM Return on Inventory']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Working Capital Absorption Rate (below)"
                                        value={customVariables['Working Capital Absorption Rate']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Working Capital Absorption Rate': value },
                                            })
                                        }
                                        placeholder={industryTargets['Working Capital Absorption Rate'] || 35}
                                        errors={errors['customVariables.Working Capital Absorption Rate']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Interest Coverage Ratio (above)"
                                        value={customVariables['Interest Coverage Ratio']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Interest Coverage Ratio': value },
                                            })
                                        }
                                        placeholder={industryTargets['Interest Coverage Ratio'] || 3}
                                        errors={errors['customVariables.Interest Coverage Ratio']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Debt to Equity Ratio (below)"
                                        value={customVariables['Debt to Equity Ratio']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Debt to Equity Ratio': value },
                                            })
                                        }
                                        placeholder={industryTargets['Debt to Equity Ratio'] || 1}
                                        errors={errors['customVariables.Debt to Equity Ratio']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Sustainable Growth Rate (above)"
                                        value={customVariables['Sustainable Growth Rate']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Sustainable Growth Rate': value },
                                            })
                                        }
                                        placeholder={industryTargets['Sustainable Growth Rate'] || 0}
                                        errors={errors['customVariables.Sustainable Growth Rate']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Marginal Cash (above)"
                                        value={customVariables['Marginal Cash']}
                                        onChange={(value) =>
                                            setCustomVariables({ ...customVariables, ...{ 'Marginal Cash': value } })
                                        }
                                        placeholder={industryTargets['Marginal Cash'] || 0}
                                        errors={errors['customVariables.Marginal Cash']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Earnings per Share (above)"
                                        value={customVariables['Earnings per Share']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Earnings per Share': value },
                                            })
                                        }
                                        placeholder={industryTargets['Earnings per Share'] || 0}
                                        errors={errors['customVariables.Earnings per Share']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Free Cash Flow (above)"
                                        value={customVariables['Free Cash Flow']}
                                        onChange={(value) =>
                                            setCustomVariables({ ...customVariables, ...{ 'Free Cash Flow': value } })
                                        }
                                        placeholder={industryTargets['Free Cash Flow'] || 0}
                                        errors={errors['customVariables.Free Cash Flow']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                        </Box>
                        <Box>
                            <Heading mb={3}>Credit Scorecard Ratio Targets</Heading>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Debt Payback (yrs) (below)"
                                        value={customVariables['Debt Payback (yrs)']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Debt Payback (yrs)': value },
                                            })
                                        }
                                        placeholder={industryTargets['Debt Payback (yrs)'] || 8}
                                        errors={errors['customVariables.Debt Payback (yrs)']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Interest Times Cover (above)"
                                        value={customVariables['Interest Times Cover']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Interest Times Cover': value },
                                            })
                                        }
                                        placeholder={industryTargets['Interest Times Cover'] || 1.5}
                                        errors={errors['customVariables.Interest Times Cover']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Debt to Total Assets (below)"
                                        value={customVariables['Debt to Total Assets']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Debt to Total Assets': value },
                                            })
                                        }
                                        placeholder={industryTargets['Debt to Total Assets'] || 50}
                                        errors={errors['customVariables.Debt to Total Assets']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Estimated Recoverable Value (above)"
                                        value={customVariables['Estimated Recoverable Value']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Estimated Recoverable Value': value },
                                            })
                                        }
                                        placeholder={industryTargets['Estimated Recoverable Value'] || 0}
                                        errors={errors['customVariables.Estimated Recoverable Value']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Debt Facilities / EBITDA (below)"
                                        value={customVariables['Debt Facilities / EBITDA']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Debt Facilities / EBITDA': value },
                                            })
                                        }
                                        placeholder={industryTargets['Debt Facilities / EBITDA'] || 2.5}
                                        errors={errors['customVariables.Debt Facilities / EBITDA']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Debt Service Cover (above)"
                                        value={customVariables['Debt Service Cover']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Debt Service Cover': value },
                                            })
                                        }
                                        placeholder={industryTargets['Debt Service Cover'] || 1.1}
                                        errors={errors['customVariables.Debt Service Cover']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Notional Debt Service (yrs) (below)"
                                        value={customVariables['Notional Debt Service (yrs)']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Notional Debt Service (yrs)': value },
                                            })
                                        }
                                        placeholder={industryTargets['Notional Debt Service (yrs)'] || 7}
                                        errors={errors['customVariables.Notional Debt Service (yrs)']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2} />
                            </Flex>
                        </Box>

                        <Box>
                            <Heading mb={3}>Estimate Recoverable Value Formula</Heading>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Account Receivable"
                                        value={customVariables['Account Receivable - EstRec%']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Account Receivable - EstRec%': value },
                                            })
                                        }
                                        placeholder={industryTargets['Account Receivable - EstRec%'] || 80}
                                        errors={errors['customVariables.Account Receivable - EstRec%']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Inventory"
                                        value={customVariables['Inventory - EstRec%']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Inventory - EstRec%': value },
                                            })
                                        }
                                        placeholder={industryTargets['Inventory - EstRec%'] || 60}
                                        errors={errors['customVariables.Inventory - EstRec%']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Other Current Asset"
                                        value={customVariables['Other Current Asset - EstRec%']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Other Current Asset - EstRec%': value },
                                            })
                                        }
                                        placeholder={industryTargets['Other Current Asset - EstRec%'] || 90}
                                        errors={errors['customVariables.Other Current Asset - EstRec%']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Fixed Assets"
                                        value={customVariables['Fixed Assets - EstRec%']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Fixed Assets - EstRec%': value },
                                            })
                                        }
                                        placeholder={industryTargets['Fixed Assets - EstRec%'] || 50}
                                        errors={errors['customVariables.Fixed Assets - EstRec%']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Other Non-current Assets"
                                        value={customVariables['Other Non-current Assets - EstRec%']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Other Non-current Assets - EstRec%': value },
                                            })
                                        }
                                        placeholder={industryTargets['Other Non-current Assets - EstRec%'] || 90}
                                        errors={errors['customVariables.Other Non-current Assets - EstRec%']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2}>
                                    <TextField
                                        label="Investments"
                                        value={customVariables['Investments - EstRec%']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Investments - EstRec%': value },
                                            })
                                        }
                                        placeholder={industryTargets['Investments - EstRec%'] || 95}
                                        errors={errors['customVariables.Investments - EstRec%']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                            </Flex>
                            <Flex>
                                <Box width={1 / 2} mr={3}>
                                    <TextField
                                        label="Preferential Payments"
                                        value={customVariables['Preferential Payments - EstRec%']}
                                        onChange={(value) =>
                                            setCustomVariables({
                                                ...customVariables,
                                                ...{ 'Preferential Payments - EstRec%': value },
                                            })
                                        }
                                        placeholder={industryTargets['Preferential Payments - EstRec%'] || 10}
                                        errors={errors['customVariables.Preferential Payments - EstRec%']}
                                        disabled={hasEditDisabled}
                                    />
                                </Box>
                                <Box width={1 / 2} />
                            </Flex>
                        </Box>

                        <Box textAlign="right">
                            <Button variant={hasEditDisabled ? 'disabled' : 'primary'}>Save</Button>
                        </Box>
                    </Form>
                </Box>
            </Box>
        </Box>
    );
};

export default connect((state) => ({ user: state.user }))(AdvisorCustomVariables);
