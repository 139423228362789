import { theme } from '@app/theme';

export const companyStatuses = {
    1: { key: 'onboarding', name: 'Onboarding', sx: theme.companyStatus.onboarding, dotColor: '#C3C7D0' },
    2: { key: 'inProgress', name: 'In Progress', sx: theme.companyStatus.inProgress, dotColor: '#5B21B6' },
    3: { key: 'projects', name: 'Projects', sx: theme.companyStatus.completed, dotColor: '#34D399' },
};

export const onboardingAndInProgressCompanyStatuses = {
    1: { key: 'onboarding', name: 'Onboarding', sx: theme.companyStatus.onboarding, dotColor: '#C3C7D0' },
    2: { key: 'inProgress', name: 'In Progress', sx: theme.companyStatus.inProgress, dotColor: '#5B21B6' },
};

export const stepStatuses = {
    1: { key: 'inProgress', name: 'In Progress', sx: theme.companyStatus.inProgress, dotColor: '#5B21B6' },
    2: { key: 'complete', name: 'Complete', sx: theme.companyStatus.completed, dotColor: '#C3C7D0' },
};

export const getCompanyStatus = (status, inReview = false, overdue = false, inactive = false, archived = false) => {
    if (archived) {
        return {
            key: 'archived',
            name: 'Archived',
            sx: theme.companyStatus.archived,
            dotColor: '#92400E'
        }
    } else if (inactive) {
        return {
            key: 'inactive',
            name: 'Inactive',
            sx: theme.companyStatus.inactive,
            dotColor: '#92400E'
        }
    } else if (overdue) {
        return {
            key: 'overdue',
            name: 'Overdue',
            sx: theme.companyStatus.overdue,
            dotColor: '#991B1B'
        }
    } else if (inReview) {
        return {
            key: 'inReview',
            name: 'In Review',
            sx: theme.companyStatus.inReview,
            dotColor: '#92400E'
        }
    } else {
        return companyStatuses[status] || companyStatuses[1];
    }
};

export const getCompanyStatusByName = (name) => {
    return Object.values(companyStatuses).find((i) => i.name === name);
};
