import React, { useRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Flex, Heading, Button } from 'rebass';
import { getCompanyFinancialReports } from '@app/Api';
import LoadingSpinner from '~/LoadingSpinner';
import styles from './styles.module.scss';
import { Icon } from '@svg';
import { formatCurrency, formatDateMonthYear, getErrorMessage, isAllCaps, ucWords } from '@util/Functions';
import cx from 'classnames';
import { SelectField } from '~/Form';
import { connect } from 'react-redux';
import { setFinancialHistoryYear } from '@app/Store';
import { PageTitle } from '~/Common/PageTitle';
import { Card } from '~/Common/Card';
import { theme } from '@app/theme';
import { useTranslation } from "react-i18next";

const FinancialReports = ({ companyId, uploadFinancials, financialHistoryYear, setFinancialHistoryYear }) => {
    const fileInput = useRef(null);
    const [activeReport, setActiveReport] = useState(null);
    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const [reportYear, setReportYear] = useState('');
    const [yearsList, setYearsList] = useState([]);
    const [filteredReports, setFilteredReports] = useState([]);
    const [currency, setCurrency] = useState('AUD');
    const { t } = useTranslation();

    const filterReports = (year) => {
        year = parseInt(year);
        const filteredReports = reports.filter(({ period }) => parseInt(period) === year);
        setFilteredReports(filteredReports);
        setReportYear(year);
        setActiveReport(null);
        setFinancialHistoryYear(year);
    };

    useEffect(() => {
        if (companyId) {
            setLoading(true);
            getCompanyFinancialReports({ companyId })
                .then(({ reports, currency }) => {
                    setLoading(false);
                    setReports(reports);
                    setCurrency(currency)
                    if (reports.length) {
                        const uniquePeriods = reports
                            .map(({ period }) => period)
                            .filter((value, index, self) => self.indexOf(value) === index)
                            .reverse();
                        const newYearsList = uniquePeriods.map((period) => ({ label: period, value: period }));
                        setYearsList(newYearsList);
                        const year =
                            financialHistoryYear && newYearsList.some(({ label }) => financialHistoryYear === label)
                                ? financialHistoryYear
                                : uniquePeriods[0];
                        const filteredReports = reports.filter(({ period }) => period === year);
                        setFilteredReports(filteredReports);
                        setReportYear(year);
                    }
                })
                .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to get financials')));
        }
    }, [companyId, financialHistoryYear]);

    if (loading) return <LoadingSpinner />;

    return (
        <Box>
            <Box px={3}>
                <PageTitle
                    subtitle={t('financialHistory.subtitle')}
                >
                    <SelectField
                        name="year"
                        value={reportYear}
                        onChange={filterReports}
                        options={yearsList}
                        boxSx={null}
                    />
                </PageTitle>
            </Box>

            <Flex>
                <Card width={1 / 3} title="Statements" gapless={true} mt={0}>
                    {filteredReports.map((report) => (
                        <Box className={styles.reportBlock} onClick={() => setActiveReport(report)} key={report.id}>
                            <Flex backgroundColor={activeReport?.id === report.id && 'white'}>
                                <Box width={1}>
                                    <Heading fontSize={12} mb={1}>
                                        {report.title} {report.period}
                                    </Heading>
                                    <Box className={styles.stageDescription}>{formatDateMonthYear(report.created)}</Box>
                                </Box>

                                <Flex alignItems="center" px={2}>
                                    <Icon icon="arrow" stroke="#00AEEF" title="View Report" />
                                </Flex>
                            </Flex>
                        </Box>
                    ))}
                    {!filteredReports.length && <Box p={30}>There are no financial statements to display.</Box>}

                    {uploadFinancials && (
                        <Box ml="auto" p={20} display="none">
                            <Button variant="primarySmall" onClick={() => fileInput.current.click()}>
                                <Flex>
                                    <Flex as="span" pr={2} alignItems="center">
                                        {filteredReports.length ? 'Update' : 'Upload'} Financial Statement
                                    </Flex>
                                    <Icon
                                        icon="upload"
                                        title="Add Financial Statement"
                                        stroke={theme.colors.primary}
                                        width={22}
                                        height={22}
                                    />
                                </Flex>
                                <Icon
                                    icon="upload"
                                    title="Add Financial Statement"
                                    stroke={theme.colors.primary}
                                    width={22}
                                    height={22}
                                />
                            </Button>
                            <input
                                ref={fileInput}
                                type="file"
                                accept=".xlsx, .xls, .csv"
                                name="file"
                                style={{ display: 'none' }}
                                onChange={(e) => uploadFinancials(e.target.files[0])}
                            />
                        </Box>
                    )}
                </Card>
                <Card title={activeReport ?
                    `${activeReport.title} ${activeReport.period}` :
                    (!reports?.length ?
                        (uploadFinancials
                            ? 'Upload a Financial Statement to view the data'
                            : 'There are no financial reports'
                        )
                        : 'Select a financial statement to view'
                    )
                } width={2 / 3} groupSx={{
                    pt: 0,
                    mt: 15
                }} mt={0}>
                    {activeReport && (
                        activeReport.data.map((block, index) => {
                            return (
                                <Box className={styles.activeReportBlock} pb={15} key={index}>
                                    {block.map(([title, value], indexBlock) => {
                                        const isHeader = isAllCaps(title);
                                        const secondHeader = value !== null;
                                        const el = (
                                            <Flex
                                                className={cx(
                                                    secondHeader && isHeader && styles.activeReportBar,
                                                    isHeader && styles.activeReportHeader,
                                                    !isHeader && styles.reportRow
                                                )}
                                                key={indexBlock}
                                                mb={isHeader ? 1 : 0}
                                                mt={isHeader ? 2 : 0}
                                            >
                                                <Box>{ucWords(title)}</Box>
                                                {value !== null && (
                                                    <Box ml="auto">
                                                        {activeReport.title === 'Financial Ratios'
                                                            ? value
                                                            : formatCurrency(value, currency)}
                                                    </Box>
                                                )}
                                            </Flex>
                                        );
                                        return el;
                                    })}
                                </Box>
                            );
                        })
                    )}
                </Card>
            </Flex>
        </Box>
    );
};

export default connect((state) => ({ financialHistoryYear: state.yearSelector.financialHistoryYear }), { setFinancialHistoryYear })(FinancialReports);
