import React, { useEffect, useState } from 'react';
import { CardItem } from '~/Common/Card';
import { connect } from 'react-redux';
import LoadingSpinner from '~/LoadingSpinner';
import { getOrganisationMembers } from '@app/Api/Organisation';
import { Box, Flex } from 'rebass';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@util/Functions';
import { useTranslation } from 'react-i18next';
import { isCeo } from '@util/Functions/User';
import { TeamListCEO } from './TeamListCEO';
import { Badge } from '~/Common/Badge';

const OrganisationHome = ({ org, user }) => {
    const [isLoading, setLoading] = useState(true);
    const [stats, setStats] = useState({});
    const [teams, setTeams] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true);
        if (org) {
            getOrganisationMembers(org.id)
                .then(({ statistics, teams }) => {
                    setStats(statistics);
                    setTeams(Array.isArray(teams) ? teams : [teams]);
                })
                .catch(({ response }) =>
                    toast.error(getErrorMessage(response?.data, 'Unable to load organisation dashboard data'), { toastId: 'getOrganisationMembersError' })
                )
                .finally(() => setLoading(false));
        }
    }, [org]);

    const emptyMessage = isCeo(user)
        ? t('This supervisor is not managing any advisors.')
        : t('You are not managing any advisors.')

    const Stat = ({ stat, ...props }) => {
        const overdue = stat?.overdue ?? 0
        return isLoading ? <LoadingSpinner size="30" /> :
            <Flex flexDirection={'column'} alignItems={'flex-end'}>
                {stat?.total ?? ('N/A')}
                {overdue > 0 && <Badge variant={props.variant ?? 'error'}>{stat.overdue} {props?.label ?? 'overdue'}</Badge>}
            </Flex>
    }

    return (
        <>
            {stats && <Box sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(5, 1fr)',
                gap: 3,
                p: 3,
                mt: -4,
                mx: -2,
                bg: '#fff',
                mb: 4
            }}>
                <Box sx={{
                    gridArea: '1 / 1 / 1 / 6',
                    color: 'blueDark',
                    fontSize: '16px',
                    fontWeight: 600,
                }}>
                    {t('Project Progress')}
                </Box>
                <CardItem
                    title="Onboarding"
                    to={{ pathname: '/organisation/companies', state: { show: 'onboarding' } }}
                    variant="statCard"
                >
                    <Stat stat={stats.onboarding} />
                </CardItem>
                <CardItem
                    title="In Progress"
                    to={{ pathname: '/organisation/companies', state: { show: 'inProgress' } }}
                    variant="statCard"
                >
                    <Stat stat={stats.inProgress} />
                </CardItem>
                <CardItem title="In Review" variant="statCard">
                    <Stat stat={stats.inReview} />
                </CardItem>
                <CardItem
                    title="Projects"
                    to={{ pathname: '/organisation/companies', state: { show: 'projects' } }}
                    variant="statCard"
                >
                    <Stat stat={stats.projects} variant={'purple'} label={'inactive'} />
                </CardItem>
                <CardItem title="Archive" variant="statCard">
                    <Stat stat={stats.archive} />
                </CardItem>
            </Box>}

            <TeamListCEO
                heading={t('Team Management')}
                teams={teams}
                emptyMessage={emptyMessage}
                isLoading={isLoading}
            />
        </>
    );
};

export default connect((state) => ({ user: state.user, org: state.organisation }))(OrganisationHome);
