import React from 'react'
import { Box } from 'rebass';
import { theme } from '@app/theme'

export const Badge = ({ children, variant, sx, ...props }) => {
    const getVariantSx = () => {
        if (theme.badges.hasOwnProperty(variant)) {
            return theme.badges[variant];
        }
        return theme.badges.default;
    }

    return (
        <Box sx={{
            display: 'inline-flex',
            fontSize: '8px',
            color: '#9594AF',
            alignItems: 'center',
            justifyContent: 'center',
            p: '4px 10px',
            borderRadius: '20px',
            lineHeight: 1,
            textTransform: 'uppercase',
            fontWeight: 600,
            letterSpacing: '0.5px',
            ...getVariantSx(),
            ...sx
        }} {...props}>
            {children}
        </Box>
    )
}
