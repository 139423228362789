import React, { useRef, useState } from 'react';
import { Box, Button, Flex } from 'rebass';
import { Icon } from '@svg';
import { getTaskStatus, taskStatuses } from '@util/Enum/TaskStatus';
import cx from 'classnames';
import styles from './styles.module.scss';
import { updateTaskStatus } from '@app/Api';
import { toast } from 'react-toastify';
import { getErrorMessage, updateUserStore } from '@util/Functions';
import { Portal } from '@material-ui/core';

const TaskStatusChange = ({ taskStatus, companyTaskId, notifyUpdated, normalisation = false, alignRight = true }) => {
    const ref = useRef(null);
    const [expandStatusMenu, setExpandStatusMenu] = useState(false);

    const changeTaskStatus = (status) => {
        setExpandStatusMenu(false);
        updateTaskStatus(companyTaskId, status, normalisation)
            .then(({ data }) => {
                notifyUpdated(normalisation ? data?.task?.normalisationStatus : data?.task?.status);
                updateUserStore();
                toast.success('Task status updated successfully');
            })
            .catch(({ response }) =>
                toast.error(getErrorMessage(response?.data, 'Error while marking task as viewed'))
            );
    };

    const { sx: statusStyle, name: statusName } = getTaskStatus(taskStatus);
    const box = ref.current ? ref.current.getBoundingClientRect() : {};

    return (
        <Flex ref={ref}>
            <Button
                variant="task"
                sx={statusStyle}
                className={styles.taskStatusButton}
                onClick={() => setExpandStatusMenu(!expandStatusMenu)}
            >
                {statusName}
            </Button>
            <Box className={styles.taskStatusBox}>
                <Icon
                    icon="arrowUp"
                    title="Expand"
                    color="black"
                    className={cx(styles.expandTaskMenu, expandStatusMenu ? '' : styles.rotate)}
                    onClick={() => setExpandStatusMenu(!expandStatusMenu)}
                />
                {expandStatusMenu && (
                    <Portal>
                        <Box className={styles.taskStatusMenu} sx={{
                            top: box.top + box.height + 10,
                            left: alignRight ? box.right - 146 : box.left,
                        }} p={2}>
                            {taskStatuses.map(({ name, sx }, statusId) => (
                                <Box p={1} key={statusId}>
                                    <Button
                                        variant="task"
                                        className={styles.taskStatusButton}
                                        sx={sx}
                                        p={2}
                                        onClick={() => changeTaskStatus(statusId)}
                                        width="120px"
                                    >
                                        {name}
                                    </Button>
                                </Box>
                            ))}
                        </Box>
                    </Portal>
                )}
            </Box>
        </Flex>
    );
};

export default TaskStatusChange;
