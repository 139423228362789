import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getErrorMessage, currentFinancialYear, yearsList } from '@util/Functions';
import { createCompanyScorecard } from '@app/Api';
import LoadingSpinner from '~/LoadingSpinner';
import { useTranslation } from "react-i18next";
import { AdvisorCompanyScorecardEdit } from 'Pages';
import { tasks } from '@util/Enum';
import { SelectField } from '~/Form';

const ScorecardTaskView = ({ task, companyId, ...props }) => {
    const [loading, setLoading] = useState(true);
    const [scorecardId, setScorecardId] = useState(0);
    const [betaFactor, setBetaFactor] = useState(null);
    const [financialYear, setFinancialYear] = useState(currentFinancialYear);
    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true);

        createCompanyScorecard({ year: financialYear, companyId: companyId, type: task?.scorecardType, onboarding: true })
            .then((data) => {
                if (task.taskId === tasks.betaFactor) {
                    setBetaFactor(data);
                } else {
                    setScorecardId(data);
                }
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'There was an error while trying to create the scorecard')))
            .finally(() => setLoading(false));
    }, [companyId, task, financialYear]);

    if (loading) return <LoadingSpinner />;

    return (
        <AdvisorCompanyScorecardEdit
            companyId={companyId}
            scorecardId={scorecardId}
            heading={t(task.name)}
            task={task}
            onboarding={true}
            backLabel={props.backLabel}
            backLink={props.backLink}
            taskComponent={props.taskComponent}
            betaFactor={betaFactor}
        >
            <SelectField
                name="financialYear"
                value={financialYear}
                onChange={setFinancialYear}
                options={yearsList()}
                sx={{ padding: '10px 30px 10px 16px', minWidth: '200px', fontSize: 14, fontWeight: 600 }}
                boxSx={null}
            />
        </AdvisorCompanyScorecardEdit>
    );
};

export default ScorecardTaskView;
