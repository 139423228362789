import { getInitials, defaultCompanyLogoLink, defaultUserLogoLink, isAllCaps, formatCurrency, currencySymbol, formatNumber, formatPercent, getErrorMessage, percentCompleted, ucWords, camelCaseWords, ucWordsCamelCase } from "./String"
import { isAnalyst, isAdvisor, isCompany, isAdmin, isCompanyOnboarded, redirectAuthenticated, isTestUser, isConsultant } from "./User"
import { downloadTemplate, downloadAttachment, downloadReportPreview, downloadReportFile, downloadWysiwygFile, downloadDraftReportFile } from "./Download"
import { dateFromString, formatDateDiff, formatDateMonth, formatDateMonthYear, formatDateWeekday, formatDateFull, formatDatePretty, formatDate, currentYear, currentFinancialYear, yearsList, monthListSmall, monthList, formatDateYear } from "./DateTime"
import { isOnboarded, isTestCompany, getBIRFilename } from "./Company"
import { updateUserStore, updateSettingsStore } from "./UpdateStore"
import { FinancialScorecard, NonFinancialScorecard, CreditScorecard, ExitReadinessScorecard, BetaFactorSliderScorecard, FoundationScorecard, StrategicGrowthScorecard, RiskScorecard, EsgScorecard, ReportCreated, ReportSubmitted, ReportChiefAnalystReview, ReportFinalised, ReportShared, ReportAdviserReview } from "./Constants"
import { permit } from "./Permissions"
import { downloadFile } from "./Generic"

// Turn off console logs for production
if (process.env.NODE_ENV === 'production') {
    console.log = () => { };
}

export {
    getInitials, defaultCompanyLogoLink, defaultUserLogoLink, isAllCaps, formatCurrency, currencySymbol, formatNumber, formatPercent, getErrorMessage, percentCompleted, ucWords, camelCaseWords, ucWordsCamelCase,
    isAnalyst, isAdvisor, isCompany, isAdmin, isCompanyOnboarded, redirectAuthenticated, isTestUser, isConsultant,
    downloadTemplate, downloadAttachment, downloadReportPreview, downloadReportFile, downloadWysiwygFile, downloadDraftReportFile,
    dateFromString, formatDateDiff, formatDateMonth, formatDateMonthYear, formatDateWeekday, formatDateFull, formatDatePretty, formatDate, currentYear, currentFinancialYear, yearsList, monthListSmall, monthList, formatDateYear,
    isOnboarded, isTestCompany, getBIRFilename,
    updateUserStore, updateSettingsStore,
    FinancialScorecard, NonFinancialScorecard, CreditScorecard, ExitReadinessScorecard, BetaFactorSliderScorecard, FoundationScorecard, StrategicGrowthScorecard, RiskScorecard, EsgScorecard, ReportCreated, ReportSubmitted, ReportChiefAnalystReview, ReportFinalised, ReportShared, ReportAdviserReview,
    permit,
    downloadFile
}
