import React, { useEffect, useState } from "react";
import { Icon } from "@svg/Icon";
import {
    formatCurrency,
} from "@util/Functions";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { Box, Flex } from "rebass";
import { Tabs } from "~/Common/Tabs";
import theme from "@app/theme";
import zoomPlugin from "chartjs-plugin-zoom";
import { Chart as ChartJS, LinearScale, TimeScale, PointElement, LineElement, Tooltip } from 'chart.js';
import 'chartjs-adapter-moment';

const tabLayout = [
    {
        key: "valuation",
        name: "Business Insights Report",
        sx: {
            borderColor: "#2C2960",
        },
    },
    {
        key: "dynamicRevaluation",
        name: "Dynamic Revaluation",
        sx: {
            borderColor: "#19CEFF",
        },
    },
    {
        key: "potential",
        name: "Potential Value",
        sx: {
            borderColor: "#34D399",
        },
    },
];

const DynamicRevaluationChart = ({
    data,
    currency = "AUD",
    height = "300px",
    logoStart = false,
}) => {
    const { t } = useTranslation();
    const [showing, setShowing] = useState(tabLayout.map((i) => i.key));
    const [datasets, setDatasets] = useState([]);

    ChartJS.register(zoomPlugin, LinearScale, TimeScale, PointElement, LineElement, Tooltip)

    useEffect(() => {
        const valuations = data?.valuations
        const valuation = showing.includes("valuation")
        const revaluation = showing.includes("dynamicRevaluation")
        const potential = showing.includes("potential")

        const config = {
            valuation: {
                pointRadius: 8,
                pointHoverRadius: 9,
                pointBackgroundColor: theme.colors.primary,
                pointBorderColor: 'white',
                pointBorderWidth: 1
            },
            dynamicRevaluation: {
                pointRadius: 4,
                pointHoverRadius: 5,
                pointBackgroundColor: 'white',
                pointBorderColor: theme.colors.blueLight,
                pointBorderWidth: 2
            }
        }

        let points = []
        let potentials = []
        let radius = []
        let hoverRadius = []
        let backgroundColor = []
        let borderColor = []
        let borderWidth = []

        if (Array.isArray(valuations)) {
            valuations.forEach((val) => {
                if (valuation) {
                    points.push({
                        x: val.timestamp,
                        y: val.value.raw
                    })

                    radius.push(config.valuation.pointRadius)
                    hoverRadius.push(config.valuation.pointHoverRadius)
                    backgroundColor.push(config.valuation.pointBackgroundColor)
                    borderColor.push(config.valuation.pointBorderColor)
                    borderWidth.push(config.valuation.pointBorderWidth)
                }

                if (potential) {
                    potentials.push({
                        x: val.timestamp,
                        y: val.potential.raw
                    })
                }

                if (revaluation) {
                    val.revaluations.forEach(reval => {
                        points.push({
                            x: reval.timestamp,
                            y: reval.value.raw
                        })

                        radius.push(config.dynamicRevaluation.pointRadius)
                        hoverRadius.push(config.dynamicRevaluation.pointHoverRadius)
                        backgroundColor.push(config.dynamicRevaluation.pointBackgroundColor)
                        borderColor.push(config.dynamicRevaluation.pointBorderColor)
                        borderWidth.push(config.dynamicRevaluation.pointBorderWidth)
                    })
                }
            })
        }


        setDatasets([
            ...(potential ? [
                {
                    label: t("Potential Value"),
                    data: potentials,
                    borderColor: theme.colors.green,
                    borderWidth: 2,
                    borderDash: [10, 10], // Dotted line style [lineLength, gapLength]
                    pointRadius: config.dynamicRevaluation.pointRadius,
                    pointHoverRadius: config.dynamicRevaluation.pointHoverRadius,
                    pointBackgroundColor: config.dynamicRevaluation.pointBackgroundColor,
                    pointBorderColor: theme.colors.green,
                    pointBorderWidth: config.dynamicRevaluation.pointBorderWidth,
                    fill: "transparent",
                    lineTension: 0,
                    steppedLine: true,
                },
            ] : []),
            ...(valuation || revaluation ? [
                {
                    label: t("Business Insights Report"),
                    data: points,
                    borderColor: theme.colors.primary,
                    borderWidth: 2,
                    pointRadius: radius,
                    pointHoverRadius: hoverRadius,
                    pointBackgroundColor: backgroundColor,
                    pointBorderColor: borderColor,
                    pointBorderWidth: borderWidth,
                    fill: "transparent",
                    lineTension: 0,
                }
            ] : [])
        ])
    }, [data, showing, t])

    const handleTabChange = (tabs) => {
        setShowing(tabs);
    };

    return (
        Array.isArray(datasets) && datasets && (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    height: "100%",
                }}
            >
                <Flex
                    sx={{
                        gap: "16px",
                    }}
                >
                    {logoStart && (
                        <Box sx={{ flex: 1 }}>
                            <Icon
                                icon="dynamicRevaluation"
                                width="98px"
                                height="24px"
                            />
                        </Box>
                    )}
                    <Tabs
                        styleVariant="circle"
                        variant="outlined"
                        multiSelect={true}
                        selected={showing}
                        onChange={handleTabChange}
                        tabs={tabLayout}
                    />
                </Flex>
                <Box sx={{ height, flex: 1 }}>
                    <Line
                        data={{
                            datasets: datasets
                        }}
                        options={{
                            scales: {
                                y: {
                                    max: data?.yMax && Math.ceil((data.yMax * 1.1) / 1000000) * 1000000,
                                    min: data?.yMin && Math.floor((data.yMin * 0.9) / 1000000) * 1000000,
                                    ticks: {
                                        stepSize: 1000000,
                                        callback: (value) =>
                                            `${formatCurrency(value / 1000000, currency, 0)}M`,
                                    },
                                    gridLines: {
                                        display: true
                                    },
                                },

                                x: {
                                    type: 'time',
                                    time: {
                                        tooltipFormat: 'D MMMM YYYY',
                                        minUnit: 'month',
                                    },
                                    gridLines: {
                                        display: true
                                    },
                                    ticks: {
                                        maxRotation: 0,
                                        maxTicksLimit: 8,
                                        stepSize: 3,
                                        autoSkip: false,
                                    },
                                    min: data?.xMin && data.xMin,
                                },
                            },
                            legend: {
                                display: false,
                            },

                            plugins: {
                                tooltip: {
                                    enabled: true,
                                    callbacks: {
                                        label: (context) =>
                                            `${formatCurrency(context.raw.y / 1000000, currency, 2)}M`,
                                    },
                                },
                                zoom: {
                                    zoom: {
                                        wheel: {
                                            enabled: true,
                                        },
                                        mode: 'x',
                                        rangeMin: {
                                            x: data?.xMin && data.xMin * 0.5,
                                        },
                                        rangeMax: {
                                            x: data?.xMax && data.xMax * 1.01,
                                        },

                                    },
                                    pan: {
                                        enabled: true,
                                        mode: 'x',
                                    },
                                    limits: {
                                        x: {
                                            min: data?.xMin && data.xMin * 0.99,
                                            max: data?.xMax && data.xMax * 1.01,
                                            minRange: 6 * 4 * 7 * 1 * 24 * 3600 * 1000
                                        },
                                    }
                                },
                            }
                        }}
                    />
                </Box>
                {!logoStart && (
                    <Icon
                        icon="dynamicRevaluation"
                        width="98px"
                        height="24px"
                    />
                )}
            </Box>
        )
    );
};

export default DynamicRevaluationChart;
