import { Icon } from "@svg/Icon";
import React from "react";
import { Box, Text } from "rebass";

export const List = ({ items, children, ...props }) => {
    return Array.isArray(items) && items.length
        ? items.map((item, index) => (
              <Box
                  key={item?.title ?? index}
                  sx={{
                      display: "flex",
                      alignItems: "center",
                      p: "16px",
                      gap: "8px",
                      borderBottom: "1px solid #F5F7F8",
                      fontSize: "14px,",
                  }}
              >
                  {item?.icon && (
                      <Icon
                          color={item?.iconColor ?? "white"}
                          icon={item.icon}
                          size="24px"
                          sx={{
                              padding: "6px",
                              bg: item?.iconBackground ?? "#16E4B3",
                              borderRadius: "4px",
                              boxSizing: "content-box",
                          }}
                      />
                  )}
                  <Box
                      sx={{
                          flex: 1,
                      }}
                  >
                      {item.title}
                  </Box>
                  <Text
                      sx={{
                          fontSize: "10px",
                      }}
                  >
                      {item.description}
                  </Text>
              </Box>
          ))
        : children ?? null;
};
