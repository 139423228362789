import React from 'react';
import { Box, Flex, Text, Image } from 'rebass';
import { currentYear } from '@util/Functions';
import { Link } from 'react-router-dom';
import styles from '../Login/styles.module.scss';
import { BackButton } from '~/Common/BackButton';
import { Form } from '~/Common/Form';
import { Grid } from 'theme-ui';
import { Icon } from '@svg/Icon';
import LoadingSpinner from '~/LoadingSpinner';

const forgotPasswordLink = { textDecoration: 'none', color: '#656D78', fontSize: '12px' };

const CredentialsLayout = ({
    heading,
    subheading,
    handleSubmit,
    children,
    footerButton,
    isSubmitting,
    handleBack,
    useSpinner = true,
    ...props
}) => {
    return (
        <Grid
            sx={{
                gridTemplateColumns: ['1fr', null, null, null, '1fr 1fr'],
                minHeight: '100vh',
                alignItems: 'center',
                bg: '#F5F7F8',
                gap: 0,
            }}
        >
            <Box sx={{ textAlign: 'center', mt: '100px' }}>
                <Image
                    src={`${process.env.PUBLIC_URL}/img/capitaliz-logo.svg`}
                    alt="Capitaliz Logo"
                    className={styles.companyTitle}
                    sx={{ width: 180, p: 0, mb: 4 }}
                />

                <Flex
                    alignItems="center"
                    sx={{
                        '@media(min-height: 800px)': {
                            pb: '100px',
                        },
                    }}
                >
                    <Form width={520} mx="auto" textAlign="center" onSubmit={handleSubmit}>
                        {'function' === typeof handleBack && (
                            <Flex
                                sx={{
                                    transition: 'opacity 0.3s ease-in-out',
                                    ...(isSubmitting && {
                                        opacity: 0,
                                        pointerEvents: 'none',
                                        transition: 'opacity 0.3s 0.3s ease-in-out',
                                    }),
                                }}
                            >
                                <BackButton onClick={handleBack} label="Back" mb={3} />
                            </Flex>
                        )}
                        <Box variant="card.wrapper" bg="white" p={4} pb="58px">
                            {heading && (
                                <Text as="h3" fontSize="30px" mb="10px">
                                    {heading}
                                </Text>
                            )}

                            {subheading && (
                                <Text sx={{ fontSize: '20px', mb: 30, maxWidth: '400px', mx: 'auto' }}>
                                    {subheading}
                                </Text>
                            )}

                            {useSpinner && isSubmitting ? <LoadingSpinner size={50} margin={25} /> : children}

                            {footerButton.link !== undefined && (
                                <Box pt={3}>
                                    <Link to={footerButton.link} style={forgotPasswordLink}>
                                        {footerButton.title}
                                    </Link>
                                </Box>
                            )}
                        </Box>
                    </Form>
                </Flex>

                <Box
                    sx={{
                        p: 4,
                        textAlign: 'left',
                        fontSize: '10px',
                        color: '#656D78',
                        '@media(min-height: 800px)': {
                            position: 'absolute',
                            left: 0,
                            bottom: 0,
                        },
                    }}
                >
                    <Box mb="10px">
                        <a target="_blank" rel="noopener noreferrer" href="https://status.capitaliz.com/">
                            Check Status
                        </a>
                    </Box>
                    <Box mb="10px">
                        <Link to="/terms-conditions">Terms & Conditions</Link>
                    </Box>
                    <Box>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.capitaliz.com/privacy-policy/">
                            Privacy Policy
                        </a>
                    </Box>
                    <Box pt={30} fontSize={8}>
                        © {currentYear} Capitaliz Holdings. All right reserved.
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    position: 'relative',
                    backgroundImage: `url(${process.env.PUBLIC_URL}/img/login-background.jpg)`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '100%',
                    display: ['none', null, null, null, 'block'],
                }}
            >
                <Icon
                    icon="texture"
                    width="auto"
                    height="450px"
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                    }}
                />
            </Box>
        </Grid>
    );
};

CredentialsLayout.defaultProps = {
    footerButton: {},
    heading: '',
    subheading: '',
};

export default CredentialsLayout;
