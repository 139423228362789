import React, { useState, useEffect } from 'react';
import {
    defaultUserLogoLink,
    getInitials,
} from '@util/Functions';
import { Box } from 'rebass';
import styles from './styles.module.scss';
import { Icon } from '@svg';

const UserAvatar = ({ name: _name, firstName: _firstName, surname: _surname, email, avatar, logo, size, fontSize, icon, theme, ...props }) => {
    const name = _name ? _name.split(' ') : false;
    const firstName = name ? name[0] : _firstName;
    const surname = name ? name[1] : _surname;
    const [image, setImage] = useState(logo);
    const hideText = ((!firstName && !email) || image) ? true : false;

    useEffect(() => setImage(logo), [logo]);

    return (
        <Box className={`${styles.companyLogoBox} ${theme === 1 ? styles.darkMode : ''}`} size={size} {...props}>
            {avatar && (
                <img
                    src={avatar}
                    alt={firstName}
                    onError={(e) => (e.target.src = defaultUserLogoLink)}
                    className={styles.avatarImg}
                />
            )}
            {!avatar && !hideText && (
                <Box className={styles.avatarText} sx={{ fontSize: fontSize ?? (!surname ? '18px' : '14px') }}>{firstName !== null && firstName !== '' ? getInitials(firstName, surname) : getInitials(email)}</Box>
            )}
            {image && (
                <img
                    src={image}
                    alt="Company"
                    onError={(e) => setImage(false)}
                    className={styles.companyLogo}
                />
            )}
            {icon && (
                <Box sx={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    bg: '#fff',
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50px',
                    boxShadow: '0px 0px 12px rgba(44, 41, 96, 0.08)'
                }}>
                    <Icon icon={icon} />
                </Box>
            )}
        </Box>
    );
}

export default UserAvatar;
