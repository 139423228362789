import React, { useState } from 'react';
import { Box, Flex } from 'rebass';
import theme from '@app/theme';
import { Button } from '~/Common/Button';
import { FormControlLabel, Switch } from '@material-ui/core';

export const DemoButtons = ({ ...props }) => {
    const [fullWidth, setFullWidth] = useState(false);
    const [showIcons, setShowIcons] = useState(false);

    return (
        <Flex sx={{
            gap: '16px',
            alignItems: 'center',
            flexDirection: 'column',
        }}>
            <FormControlLabel
                value="icons"
                control={<Switch label="Show Icons" onChange={(e) => setShowIcons(e.target.checked)} />}
                label="Icons"
            />
            <FormControlLabel
                value="fullWidth"
                control={<Switch label="Full Width" onChange={(e) => setFullWidth(e.target.checked)} />}
                label="Full Width"
            />
            <Box sx={{
                display: 'grid',
                gridTemplate: 'auto / 1fr',
                maxWidth: '500px',
                margin: '0 auto',
                gridGap: '16px'
            }}>
                {Object.entries(theme.buttons).map(([key, styles]) => (
                    <Flex key={key} sx={{ justifyContent: 'center', p: 3 }}>
                        <Button
                            variant={key}
                            sx={{ width: fullWidth && key !== 'iconCircle' ? '100%' : null }}
                            icon={showIcons || key === 'iconCircle' ? 'link' : undefined}
                        >
                            {key ==='iconCircle' ? '' : key}
                        </Button>
                    </Flex>
                ))}
            </Box>
        </Flex>
    );
};
