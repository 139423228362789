import React from 'react';
import { isAdvisor } from '@util/Functions';
import { Flex, Box } from 'rebass';
import { connect } from 'react-redux';
import PayForSubscription from 'Pages/Common/PayForSubscription';
import ManagePayments from './Company/ManagePayments';
import CompanyUsers from './Company/CompanyUsers';
import ArchivedReports from './Company/ArchivedReports';
import ManageSubscriptions from './User/ManageSubscriptions';
import ManageTwoFactor from './User/ManageTwoFactor';
import UserSettings from './User/UserSettings';
import CompanySettings from './Company/CompanySettings';
import CodatIntegrations from './Company/CodatIntegrations';

const AccountDetails = ({ company, user, show }) => {
    const subscription = company?.subscription
    const canSubscribe = isAdvisor(user) && subscription?.permissions?.purchase;
    const isCompanyLayout = show?.company && !show?.user;

    return (
        <>
            <Flex>
                <Flex width={isCompanyLayout ? 2 / 3 : 1 / 3} flexDirection="column">
                    {show?.user && <UserSettings />}
                    {isCompanyLayout && <CompanySettings />}
                </Flex>

                <Flex width={2 / 3} flexDirection="column">
                    {company && !subscription?.paid && (
                        <Box variant="warning" mt={0}>
                            <Box variant="warningHeader">Company Access is locked</Box>
                            <Box variant="card.container">
                                <Box>
                                    Your access to this company is currently locked. Please{' '}
                                    {canSubscribe
                                        ? 'restart your subscription to receive'
                                        : 'contact your adviser to grant you'}{' '}
                                    access.
                                </Box>
                                {canSubscribe && (
                                    <Box mt={3}>
                                        <PayForSubscription companyId={company.id} variant="warning" />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    )}
                    {show?.company && show?.user && <CompanySettings />}
                    {show?.company && !company?.archived ? <CompanyUsers /> : null}
                    {show?.company && company && (company?.archived || !subscription?.paid) ? <ArchivedReports /> : null}
                    {show?.company && user?.permissions?.companies?.managePayments && subscription?.required && (
                        <ManagePayments />
                    )}
                    {show?.user && isAdvisor(user) && <ManageSubscriptions />}
                    {show?.user && <ManageTwoFactor />}
                    {show?.company && <CodatIntegrations />}
                </Flex>
            </Flex>
        </>
    );
};

export default connect((state) => ({ user: state.user, company: state.company }))(AccountDetails);
