import React from 'react'
import { useSelector } from 'react-redux'
import { Box } from 'rebass'
import FinancialReports from '~/Dashboard/FinancialReports'

const CompanyFinancialReports = ({ companyId }) =>
    <Box>
        <FinancialReports companyId={parseInt(companyId ?? useSelector(state => state.user?.companyId))} />
    </Box>

export default CompanyFinancialReports
