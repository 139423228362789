import React, { useState } from 'react'
import { Box, Button } from 'rebass'
import { TextField } from '~/Form'
import { login, updatePassword } from '@app/Api'
import { toast } from 'react-toastify'
import { updateUserStore } from '@util/Functions'
import CredentialsLayout from '../CredentialsLayout/CredentialsLayout'
import LoginTwoFactorChallenge from '../LoginTwoFactorChallenge'

const Login = ({ email, hash }) => {

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({})
    const decodedEmail = decodeURIComponent(email)
    const [step, setStep] = useState(1)
    const [twoFactorConfirmed, setTwoFactorConfirmed] = useState(true)

    const handleSubmit = (e) => {
        e.preventDefault()
        setErrors({})
        setIsSubmitting(true);
        updatePassword({ email: decodedEmail, passwordReset: hash, password, confirmPassword })
            .then(() =>
                login({ email: decodedEmail, password, device_name: 'app' })
                    .then((data) => {
                        if (localStorage.getItem('authTokenScope') === 'twoFactorRequired') {
                            setTwoFactorConfirmed(data?.twoFactorConfirmed)
                            setStep(2)
                        } else {
                            updateUserStore()
                        }
                    })
                    .catch(({ response }) => {
                        response?.data?.errors && setErrors(response.data.errors)
                        toast.error(response?.data?.message || 'There was an unexpected error while trying to log in')
                    })
            )
            .catch(({ response }) => {
                const err = response?.data?.errors
                err && setErrors(response.data.errors)
                toast.error(err?.[Object.keys(err)]?.[0] || response?.data?.message || 'There was an error while trying to reset password')
                setIsSubmitting(false);
            })
    }

    const content = () => {
        switch (step) {
            case 1:
                return <CredentialsLayout
                    heading="New Password"
                    subheading="Please enter your new password"
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                    footerButton={{ link: '/', title: 'Login Page' }}
                >
                    <Box mb={2} sx={{ textAlign: 'left', fontWeight: 600 }}>
                        <TextField placeholder='Email' name='email' value={decodedEmail} type='email' label='Email' errors={errors.email} disabled={true} />
                        <TextField placeholder='Password' name='password' value={password} type='password' onChange={(val) => setPassword(val)} label='Password' errors={errors.password} />
                        <TextField placeholder='Confirm Password' name='confirmPassword' value={confirmPassword} type='password' onChange={(val) => setConfirmPassword(val)} label='Confirm Password' errors={errors.confirmPassword} />

                        {isSubmitting ? (
                            <Button variant="submitting" width="100%" disabled="disabled">Submitting...</Button>
                        ) : (
                            <Button variant="primary" width="100%">Confirm password</Button>
                        )}
                    </Box>
                </CredentialsLayout>
            case 2:
                return <LoginTwoFactorChallenge twoFactorConfirmed={twoFactorConfirmed} />
            default:
                return <></>
        }
    }

    return <>{content()}</>
}

export default Login
