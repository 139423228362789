import axios from 'axios'


export const createComment = (type, id, comment, uuid = null) =>
    axios.post(`advisor/comment/${type}/${id}`, {
        comment: comment,
        uuid: uuid
    })
        .then(({ data }) => data)

export const getComments = (type, id) =>
    axios.get(`advisor/comment/${type}/${id}`)
        .then(({ data }) => data)
