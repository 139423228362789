import React, { useEffect } from 'react';
import { logout } from '@app/Api';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
    resetAnalysts,
    resetCompanies,
    resetNotifications,
    resetOrganisation,
    resetSettings,
    resetStages,
    resetTasks,
    resetUser,
} from '@app/Store';
import { toast } from 'react-toastify';

const Logout = ({ resetUser, resetStages, resetNotifications, resetCompanies }) => {
    useEffect(() => {
        resetUser();
        resetStages();
        resetNotifications();
        resetCompanies();
        resetSettings();
        resetAnalysts();
        resetOrganisation();
        resetTasks();
        logout();

        toast.info('Your session has expired, please login again to continue.');
    });

    return <Redirect to="/" />;
};

export default connect(null, { resetUser, resetStages, resetNotifications, resetCompanies })(Logout);
