import React from 'react';
import { Badge } from '../Badge';
import { companyStepStatuses } from '@util/Enum/CompanyStepStatus';

export const CompanyStepStatus = ({ status, ...props }) => {
    return (
        <Badge
            sx={{
                whiteSpace: 'nowrap'
            }}
            mr={2}
            variant={
                status === 3 ? 'success'
                    : status === 2 ? 'purple'
                        : 'warning'
            }>
            {companyStepStatuses[status]?.label}
        </Badge>
    )
};
