import React, { useEffect, useRef, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { redirectAuthenticated } from '@util/Functions';
import { isCompany } from '@util/Functions';
import Dashboard from '@section/Dashboard';
import { Logout, NoMatch404Page } from '../../Pages';
import LogRocket from 'logrocket';
import { useIdleTimer } from 'react-idle-timer';
import { Modal } from '~/Common/Modal';
import { Flex } from 'rebass';
import { useTranslation } from 'react-i18next';
import { Button } from '~/Common/Button';
import { addToRecentlyViewedCompanies } from '@app/Store/storeUi';

// Idle timeout in seconds, 0 = disabled
const idleTimeout = parseInt(process.env.REACT_APP_SCREEN_TIMEOUT ?? 0);

export const DemoRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={
            process.env.NODE_ENV === 'production'
                ? ({
                    match: {
                        params: { url },
                    },
                }) => <Dashboard Component={NoMatch404Page} props={{ pageUrl: url }} />
                : (props) => <Dashboard Component={Component} {...props} />
        }
    />
);

export const GuestRoute = ({ component: Component, ...rest }) => {
    const user = useSelector((s) => s.user);
    const authenticatedPath = redirectAuthenticated(user);

    if (authenticatedPath && authenticatedPath !== '/') return <Redirect to={authenticatedPath} />;

    return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export const TwoFactorRoute = ({ component: Component, ...rest }) => {
    if (localStorage.getItem('authTokenScope') !== 'twoFactorRequired' || !localStorage.getItem('authToken')) {
        return <Redirect to="/" />;
    }

    return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export const PrivateRoute = ({ component: Component, role, notRole, ...rest }) => {
    const mounted = useRef(false);
    const user = useSelector((s) => s.user);
    let isAllowed = true;
    const { t } = useTranslation();
    const [timedOut, setTimedOut] = useState(false);
    const [showIdlePrompt, setShowIdlePrompt] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(60);

    const { reset, getRemainingTime } = useIdleTimer({
        timeout: 1000 * 60 * (idleTimeout - 1),
        promptTimeout: 1000 * 60 * 1, // 1 minute
        onPrompt: () => {
            setShowIdlePrompt(idleTimeout !== 0);
        },
        onIdle: () => {
            setTimedOut(idleTimeout !== 0);
        },
    });

    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    }, []);

    useEffect(() => {
        if (showIdlePrompt) {
            setTimeout(() => {
                if (!mounted.current) {
                    return;
                }
                const remaining = Math.round(getRemainingTime() / 1000);
                setTimeRemaining(remaining > 60 ? 60 : remaining);
            }, 1000);
        }
    });

    if (timedOut) {
        return <Logout />;
    }

    if (window.zE) {
        window.zE('webWidget', 'prefill', {
            name: {
                value: `${user?.firstName} ${user?.surname}`,
            },
            email: {
                value: user?.email,
            },
        });
    }

    if (user) {
        if ((process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'production') && (!LogRocket?._id || LogRocket?._id !== user?.id)) {
            LogRocket.identify(user?.id, {
                name: `${user?.firstName} ${user?.surname}`.trim(),
                email: user?.email,
            });

            LogRocket._id = user?.id;
        }

        if (role) {
            isAllowed = isAllowed && Array.isArray(role) ? role.includes(user.accessLevel) : role === user.accessLevel;
        }
        if (notRole) {
            isAllowed = isAllowed && Array.isArray(notRole) ? !notRole.includes(user.accessLevel) : role !== user.accessLevel;
        }
    }

    return (
        <Route
            {...rest}
            render={(props) =>
                !!user && isAllowed ? (
                    <>
                        <Modal
                            isOpen={showIdlePrompt}
                            flex={true}
                            flexDirection="column"
                            gap="32px"
                            maxWidth={500}
                            maxHeight="unset"
                            title="Your session is about to expire, are you still here?"
                            icon="exit"
                        >
                            <Flex sx={{ gap: 3 }}>
                                <Button variant="secondary" flex={1} onClick={() => setTimedOut(true)}>
                                    {t('Logout')} ({timeRemaining})
                                </Button>
                                <Button
                                    variant="primary"
                                    flex={1}
                                    onClick={() => {
                                        reset();
                                        setShowIdlePrompt(false);
                                    }}
                                >
                                    Stay Logged In
                                </Button>
                            </Flex>
                        </Modal>
                        <Component {...props} />
                    </>
                ) : (
                    <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                )
            }
        />
    );
};

export const CompanyRoute = ({ component: Component, ...rest }) => {
    const companies = useSelector((s) => s.companies);
    const user = useSelector((s) => s.user);
    const dispatch = useDispatch();

    const companyId = rest?.computedMatch?.params?.companyId ?
        parseInt(rest.computedMatch.params.companyId) :
        (isCompany(user) ? user?.companyId : null);

    const company = companyId ? companies.find((company) => company.id === companyId) : null;

    if (company) {
        dispatch(addToRecentlyViewedCompanies(companyId));

        const subscription = company?.subscription;
        const paid = subscription ? subscription.paid : false;
        const archived = company ? company.archived : false;
        const redirect = isCompany(user) ? '/company/profile/settings' : `/advisor/company/${companyId}/settings`;

        return paid && !archived ? <PrivateRoute {...rest} component={Component} /> :
            <Redirect to={{ pathname: redirect }} />;
    }

    return <></>
};
