import React, { useEffect, useState } from 'react';
import { useDrag } from "react-dnd";
import styles from '../Assets/styles.module.scss';
import cx from 'classnames';

const RoadmapBar = ({ children, priority, taskId, monthYear, moveTaskDates, startMonth, endMonth }) => {

  const [width, setWidth] = useState(null);

  let widthTask = endMonth - startMonth + 1

  const [collected, drag, preview] = useDrag(() => ({
    type: `task${taskId}`,
    item: { taskId },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) moveTaskDates(taskId, dropResult.date - monthYear)
    },
    previewOptions: {
      captureDraggingState: true,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }), [monthYear]);

  const getStyle =  {
    width: collected.isDragging ? width * widthTask :width,
    maxWidth: collected.isDragging ? width * widthTask :width,
  }

  useEffect(() => {
    const element = document.getElementById('task-width');
    if (element) {
      setWidth(element.offsetWidth);
    }
  }, []);



  return (
    <div ref={preview} >
    <div id='task-width' className={cx(styles[priority], styles.grabbable)} style={getStyle} ref={drag} key={taskId}>
      {children}
      </div>
    </div>
  );
}

export default RoadmapBar;
