import React from 'react';
import { useDrag } from "react-dnd";
import styles from '../Assets/styles.module.scss';
import cx from 'classnames'

const RoadmapExtend = ({ taskId, direction, extendTaskDates }) => {
  const [, drag] = useDrag(() => ({
    type: `task${taskId}`,
    item: { taskId },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult)
        extendTaskDates(taskId, direction, dropResult.date)
    }
  }))
  return <div className={cx(styles.grabbableExpand, styles[direction])} ref={drag} key={Math.random()} />
}

export default RoadmapExtend