import React from 'react';
import { Box, Flex, Heading } from 'rebass';
import { sliderTypesBetaFactor, sliderTypesRisk } from '@util/Functions/Constants';
import { useTranslation } from "react-i18next";
import { BetaFactorSliderScorecard, RiskScorecard, StrategicGrowthScorecard, FoundationScorecard } from '@util/Functions';

const SliderScorecard = ({ sliders, scorecardType, finalResult }) => {
    const { t } = useTranslation()
    const isBetaFactor = scorecardType === BetaFactorSliderScorecard
    const isRisk = scorecardType === RiskScorecard
    const isStrategicGrowth = scorecardType === StrategicGrowthScorecard
    const isFoundation = scorecardType === FoundationScorecard
    const slidersGroups = isBetaFactor ? sliderTypesBetaFactor : sliderTypesRisk
    const riskValues = isRisk || isStrategicGrowth || isFoundation

    return <Box>
        {isRisk && <Flex mb={4} alignItems={'center'} justifyContent={'space-between'}>
            <Heading>Result</Heading>
            {finalResult && <Box>{finalResult}</Box>}
        </Flex>}

        {slidersGroups.map((group, id) => {
            return (
                <Box mb={5} key={`group-${id}`}>
                    <Heading size="1">{group}</Heading>
                    <Box>
                        <Flex alignItems="center" color="blueDark" p="2" fontWeight="600" fontSize="10px">
                            <Box width={4 / 10} />
                            <Box width={3 / 10}>Low Risk {isBetaFactor && '-0.5'}</Box>
                            <Box width={1 / 10} textAlign="center">{isBetaFactor && '0'}</Box>
                            <Box width={3 / 10} textAlign="right">High Risk {isBetaFactor && '+0.5'}</Box>
                            <Box width={1 / 10} />
                        </Flex>

                        {sliders?.map(({ name, value, type, comment, realValue }, index) => {
                            if (type === group.toLowerCase()) {
                                const bgColor = value < 0.1 ? '#19CEFF' : '#2C2960';
                                const progressBar = Math.round(Math.min((riskValues ? parseFloat(realValue) / 0.1 : (value + 0.5)) * 100, 100)) + '%';
                                return (
                                    <Box key={`slider-${index}`} py="2" mb={3} fontSize={1} fontWeight="500">
                                        <Flex alignItems="center">
                                            <Box width={4 / 10} pr={3}>
                                                {t(name)}
                                            </Box>
                                            <Box width={7 / 10}>
                                                <Box variant="barProgressBackground">
                                                    <Box
                                                        variant="barProgress"
                                                        width={progressBar}
                                                        backgroundColor={bgColor}
                                                        height="10px"
                                                    />
                                                </Box>

                                                {comment && (
                                                    <Box
                                                        backgroundColor="white"
                                                        p={2}
                                                        my={2}
                                                        color="#6F7782"
                                                        fontSize={0}
                                                    >
                                                        {comment}
                                                    </Box>
                                                )}
                                            </Box>
                                            <Box width={1 / 10} textAlign="center" fontSize={16}>
                                                {isBetaFactor ? value : realValue}
                                            </Box>
                                        </Flex>
                                    </Box>
                                );
                            }
                            return null;
                        })}
                    </Box>
                </Box>
            );
        })}
    </Box>
};

export default SliderScorecard;
