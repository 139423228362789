import { createSlice } from '@reduxjs/toolkit'

const settings = createSlice({
    name: 'settings',
    initialState: {
        countries: [],
        entities: [
            'Company',
            'Partnership',
            'Trust'
        ],
        territories: [],
        options: {
            countries: [],
            entities: [],
            territories: [],
        },
    },
    reducers: {
        setSettings: (state, { payload }) => payload,
        resetSettings: () => {
            return {
                countries: [],
                entities: [
                    'Company',
                    'Partnership',
                    'Trust'
                ],
                territories: [],
                options: {
                    countries: [],
                    entities: [],
                    territories: [],
                },
            };
        },
    }
})

export const { setSettings, resetSettings } = settings.actions

export default settings.reducer
