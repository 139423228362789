import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Box, Flex } from 'rebass';
import TaskLink from '~/Dashboard/CompanyStages/TaskLink';
import LoadingSpinner from '~/LoadingSpinner';
import { getStages } from '@app/Api';
import { selectIncompleteTasks, setStages } from '@app/Store';
import { getErrorMessage } from '@util/Functions';
import { PageTitle } from '~/Common/PageTitle';
import { Card } from '~/Common/Card';
import { useTranslation } from "react-i18next";
import { OverviewRow } from '~/Dashboard/OverviewRow';
import DynamicRevaluation from 'Pages/Common/DynamicRevaluation';

const CompanyHome = ({ user, state, companies, setStages, companyId }) => {
    const tasks = selectIncompleteTasks(state);
    const [loading, setLoading] = useState(false);
    const [description, setDescription] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true);
        getStages(companyId)
            .then(({ stages, description }) => {
                setStages(stages);
                setDescription(description)
                setLoading(false);
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to load onboarding data'), { toastId: 'getStagesError' }));
    }, [setStages, companyId]);

    if (loading) return <LoadingSpinner />;


    const id = companyId ?? user?.companyId
    const company = id ? companies.find(company => parseInt(company.id) === parseInt(id)) : null;

    return (
        <>
            <Box px={3}>
                <PageTitle
                    subtitle={t('companyOverview.subtitle')}
                />
            </Box>
            {company?.dynamicRevaluation && <DynamicRevaluation dynamicRevaluation={company.dynamicRevaluation} />}
            <Flex>
                <Box width={1 / 2}>
                    <Card title={`Overview for ${company?.name}`}>
                        {description}

                        <Box mb={3} mt="20px">
                            <OverviewRow title="Start date">{company?.created ?? 'N/A'}</OverviewRow>
                            <OverviewRow title="Onboarding date">{company?.onboardingCompletedOn ?? 'N/A'}</OverviewRow>
                            <OverviewRow title="Draft review date">{company?.reports?.current?.submittedForReviewOn ?? 'N/A'}</OverviewRow>
                            <OverviewRow title="Report released date">{company?.reports?.last?.publishedOn ?? 'N/A'}</OverviewRow>
                            <OverviewRow title="New notifications">{company?.notifications ?? 0}</OverviewRow>
                        </Box>
                    </Card>
                </Box>
                <Box width={1 / 2}>
                    <Card title="Current Tasks">
                        {tasks?.map((task) => (
                            <TaskLink {...task} key={task.id} companyId={companyId} />
                        ))}
                        {!tasks?.length > 0 && <Box color="gray3">There are no pending tasks</Box>}
                    </Card>
                </Box>
            </Flex>
        </>
    );
};

export default connect((state) => ({ user: state.user, state: state, companies: state.companies }), { setStages })(CompanyHome);
