import { setUser, setStages, setCompanies, setSettings, setOrganisation } from '@app/Store';
import { getUserStore, getSettings } from '@app/Api';
import { setTrainingMode } from '@app/Store/storeUi';
import { isAdvisor, isTestUser } from '@util/Functions';
import i18n from '@translations/i18n';
import { store } from '../../index.js';
import { toast } from 'react-toastify';

export const updateUserStore = () =>
    getUserStore()
        .then(({ organisation, user, stages, companies }) => {
            i18n.changeLanguage(user.lang);
            store.dispatch(setUser(user));
            store.dispatch(setCompanies(Array.isArray(companies) ? companies : [companies]));
            store.dispatch(setStages(stages));
            store.dispatch(setOrganisation(organisation));
            if (isAdvisor(user) && isTestUser(user)) {
                store.dispatch(setTrainingMode('training'));
            }

            updateSettingsStore();
        })
        .catch(({ response }) => {
            toast.error(
                response?.data?.message || 'There was an unexpected error while trying to get user store info',
                {
                    toastId: 'session_expired',
                }
            );
        });

export const updateSettingsStore = () => {
    getSettings().then((data) => {
        store.dispatch(setSettings(data));
    });
};
