import React, { useEffect, useState } from 'react';
import { Box, Button, Flex } from 'rebass';
import { getNotifications, readAllNotifications, setNotificationAsSeen, setTaskAsSeen, updateTaskStatus } from '@app/Api';
import LoadingSpinner from '~/LoadingSpinner';
import { toast } from 'react-toastify';
import { defaultCompanyLogoLink, formatDateDiff, formatDateFull, getErrorMessage } from '@util/Functions';
import styles from './Assets/styles.module.scss';
import TaskLink from '~/Dashboard/CompanyStages/TaskLink';
import TaskComments from '~/Dashboard/TaskView/TaskComments';
import PostTaskComment from '~/Dashboard/TaskView/PostTaskComment';
import { connect } from 'react-redux';
import { isTaskStatusCompleted, taskStatusCode } from '@util/Enum';
import { Link, useHistory } from 'react-router-dom';
import TaskStatusChange from '~/Dashboard/TaskStatusChange';
import { readNotification, readTaskNotification, subtractUnreadNotificationCount } from '@app/Store';
import { Icon } from '@svg';
import { Button as CommonButton } from '~/Common/Button';

const AdvisorIndex = ({ user, subtractUnreadNotificationCount, readNotification, readTaskNotification }) => {
    const history = useHistory();
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeNotification, setActiveNotification] = useState(null);
    const [messages, setMessages] = useState(null);
    const [attachments, setAttachments] = useState(null);
    const [companyTaskId, setCompanyTaskId] = useState(null);
    const [companyId, setCompanyId] = useState(null);
    const [taskStatus, setTaskStatus] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || loading) {
            return;
        }
        if (hasMorePages) {
            setPage(currentPage => currentPage + 1);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    });

    useEffect(() => {
        setLoading(true);
        getNotifications({
            params: {
                page: page
            }
        })
            .then((results) => {
                setHasMorePages(results?.meta?.current_page < results?.meta?.last_page);
                setNotifications(prevItems => [...prevItems, ...results?.data]);
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to load notifications')))
            .finally(() => setLoading(false));
    }, [page]);

    const changeActiveTask = (notificationId) => {
        setActiveNotification(notificationId);
        const notification = notifications[notificationId];
        if (!notification.seen) {
            setTaskAsSeen(notification.companyTaskId).then(() => {
                // set all notifications for this company task as seen ( viewing task shows all messages )
                const newNotifications = [...notifications].map((n) => n.companyTaskId === notification.companyTaskId ? { ...n, ...{ seen: 1 } } : n);
                setNotifications(newNotifications);
                subtractUnreadNotificationCount(1);
                // set state
                readTaskNotification(notification.companyTaskId);
            });
        }
        setMessages(notification.messages);
        setAttachments(notification.attachments);
        setCompanyTaskId(notification.companyTaskId);
        setCompanyId(notification.companyId);
        setTaskStatus(notification.status);
    };

    const changeTaskStatus = (status) => {
        updateTaskStatus(companyTaskId, status)
            .then(() => {
                postTaskStatusUpdate(status);
                toast.success('Task status updated successfully');
            })
            .catch(({ response }) =>
                toast.error(getErrorMessage(response?.data, 'Error while marking task as viewed'))
            );
    };

    const postTaskStatusUpdate = (status) => {
        const newNotifications = [...notifications];
        newNotifications[activeNotification].status = status;
        setNotifications(newNotifications);
        setTaskStatus(status);
    };

    const markNotificationIdAsSeen = (id, companyId, reportId, valuationId, seen) => {
        setNotificationAsSeen(id)
            .then(() => {
                readNotification(id);

                const newNotifications = [...notifications].map((n) => n.id === id ? { ...n, ...{ seen: 1 } } : n);
                setNotifications(newNotifications);

                if (!seen) {
                    subtractUnreadNotificationCount(1);
                }

                if (reportId) return history.push(`/advisor/company/${companyId}/reports`)
                if (valuationId) return history.push(`/advisor/company/${companyId}/valuations/edit/${valuationId}`)
                if (companyId) return history.push(`/advisor/company/${companyId}/overview`)
            });
    }

    const markAllNotificationsSeen = () => {
        readAllNotifications()
            .then(({ data }) => {
                toast.success('Successfully marked all notifications as read');
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to read all notifications')));
    }

    const completed = taskStatusCode('completed');
    const hasNotifications = notifications?.length > 0;

    const isValueGap = notification => notification.includes('Value Gap Assessment')

    return (
        <Flex>
            <Box width={1 / 2}>
                <Box variant="card.wrapper">
                    <Box variant="card.header">
                        <Flex alignItems={'center'} justifyContent={'space-between'}>
                            <Box>Most Recent</Box>
                            <Button onClick={() => markAllNotificationsSeen()}>
                                Read All
                            </Button>
                        </Flex>
                    </Box>
                    <Box variant="card.containerGapless">
                        {hasNotifications &&
                            notifications.map(
                                ({ id, notification, reportId, companyId, valuationId, companyName, companyLogo, taskName, companyTaskId, status, seen, created }, index) => {
                                    if (companyTaskId) {
                                        const isActive = activeNotification === index;
                                        return (
                                            <Box
                                                onClick={() => changeActiveTask(index)}
                                                key={id}
                                                backgroundColor={isActive ? 'white' : null}
                                                py={3}
                                                px={30}
                                                fontSize={12}
                                                className={styles.notificationBlock}
                                            >
                                                <Flex mb={2} sx={{ position: 'relative' }}>
                                                    <img
                                                        src={companyLogo || defaultCompanyLogoLink}
                                                        alt="Company"
                                                        onError={(e) => (e.target.src = defaultCompanyLogoLink)}
                                                        className={styles.companyLogo}
                                                    />
                                                    <Box my={1}>
                                                        <b>{notification}</b> for <b>{companyName}</b>
                                                    </Box>
                                                    {!seen && (
                                                        <Box className={styles.notificationIcon} title="New">
                                                            <Icon icon="notification" />
                                                        </Box>
                                                    )}
                                                </Flex>
                                                {companyTaskId && <TaskLink
                                                    id={companyTaskId}
                                                    name={taskName}
                                                    status={status}
                                                    withLink={false}
                                                />}

                                                <Box mt={2} color="#6F7782" className={styles.notificationDate}>
                                                    {formatDateDiff(created)}<span className={styles.notificationDateRaw}> &bull; {formatDateFull(created)}</span>
                                                </Box>
                                            </Box>
                                        );
                                    } else {
                                        return <Box
                                            onClick={() => markNotificationIdAsSeen(id, companyId, reportId, valuationId, seen)}
                                            key={id}
                                            py={3}
                                            px={30}
                                            fontSize={12}
                                            className={styles.notificationBlock}
                                        >
                                            <Flex mb={2}>
                                                <img
                                                    src={companyLogo || defaultCompanyLogoLink}
                                                    alt="Company"
                                                    onError={(e) => (e.target.src = defaultCompanyLogoLink)}
                                                    className={styles.companyLogo}
                                                />
                                                <Box my={1}>
                                                    <b>{companyName}</b> {notification}
                                                </Box>
                                            </Flex>

                                            <Flex className={styles.taskBlock} mb={10} alignItems="center">
                                                <Flex alignItems="center" fontSize={1}>
                                                    {reportId != null ? 'View Report' : valuationId != null ? 'View Valuation' : companyId != null && companyName}
                                                    {isValueGap(notification) && 'Value Gap Assessments'}
                                                </Flex>
                                                <Box className={styles.taskStatusBox} ml="auto" mt={1}>
                                                    {(reportId != null || valuationId != null || companyId != null) ? <Button variant="primarySmall" mr={2}>'View'</Button> :
                                                        (isValueGap(notification) && <CommonButton to={'exit-assessment'} variant="primarySmall" mr={2}>'View'</CommonButton>)}
                                                    {!seen && (
                                                        <Box className={styles.notificationIcon} title="New">
                                                            <Icon icon="notification" />
                                                        </Box>
                                                    )}
                                                </Box>
                                                <Flex alignItems="center">
                                                    <Icon icon="arrow" stroke="#00AEEF" title="View Task" />
                                                </Flex>
                                            </Flex>

                                            <Box mt={2} color="#6F7782" className={styles.notificationDate}>
                                                {formatDateDiff(created)}<span className={styles.notificationDateRaw}> &bull; {formatDateFull(created)}</span>
                                            </Box>
                                        </Box>
                                    }
                                }
                            )}
                        {!hasNotifications && <Box p={4}>You don't have any notifications</Box>}
                    </Box>

                    {loading ? <LoadingSpinner /> : null}
                </Box>
            </Box>

            <Box width={1 / 2}>
                {activeNotification !== null && activeNotification < notifications?.length && (
                    <Box variant="card.wrapper">
                        <Flex className={styles.messageHeader} p={3}>
                            {!isTaskStatusCompleted(taskStatus) && (
                                <Button
                                    variant="secondary"
                                    mr={2}
                                    onClick={() => changeTaskStatus(completed)}
                                    fontSize={12}
                                >
                                    Mark Complete
                                </Button>
                            )}
                            <Link to={`/advisor/company/${companyId}/stages/task/${companyTaskId}`}>
                                <Button variant="secondary" mr={2} className={styles.viewTaskButton} fontSize={12}>
                                    <Flex>
                                        <Icon icon="eye" title="View Task" />
                                        <Box ml={1} mt="1px">
                                            View Task
                                        </Box>
                                    </Flex>
                                </Button>
                            </Link>
                            <Flex ml="auto">
                                <Box className={styles.changeStatus}>Change Status</Box>
                                <TaskStatusChange
                                    taskStatus={taskStatus}
                                    companyTaskId={companyTaskId}
                                    notifyUpdated={postTaskStatusUpdate}
                                />
                            </Flex>
                        </Flex>

                        <Box p={4}>
                            <TaskComments
                                isAdvisor={true}
                                attachments={attachments}
                                messages={messages}
                                updateMessages={(messages) => setMessages(messages)}
                                updateAttachments={(attachments) => setAttachments(attachments)}
                            />
                        </Box>

                        <PostTaskComment
                            companyTaskId={companyTaskId}
                            user={user}
                            updateMessages={(messages) => setMessages(messages)}
                            updateAttachments={(attachments) => setAttachments(attachments)}
                            sx={{ backgroundColor: '#FFFFFF', borderRadius: '0 0 10px 10px' }}
                        />
                    </Box>
                )}
            </Box>
        </Flex>
    );
};

export default connect((state) => ({ user: state.user, newNotificationsCount: state.notifications.newCount }), {
    readNotification,
    readTaskNotification,
    subtractUnreadNotificationCount
})(AdvisorIndex);
