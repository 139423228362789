import React, { useEffect, useState } from 'react';
import { Flex, Heading, Button } from 'rebass';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import { connect } from 'react-redux';
import { getErrorMessage, isCompany } from '@util/Functions';
import UserAvatar from '~/Dashboard/Avatar';
import { AccountMenu } from '~/Dashboard/AccountMenu';
import { Icon } from '@svg';
import { Bubble } from '~/Common/Bubble';
import { getAllActionItems } from '@app/Api/Company';
import { setActionItems } from '@app/Store/storeActionItems';
import { useIsMounted } from '@hooks/useIsMounted';
import { toast } from 'react-toastify';
import { Skeleton } from '~/Common/Skeleton';

const Header = ({ user, children, toggleWhatsNew, toggleNotifications, toggleShowActionItems, newNotificationsCount, actionItemsStore, setActionItems }) => {
    const isMounted = useIsMounted();
    const history = useHistory();
    const company = isCompany(user);
    const [menuOpen, setMenuOpen] = useState(false);
    const actionItemsCompany = actionItemsStore.currentCompanyId;
    const actionItemsStatus = actionItemsStore.all.status;

    const pendingCount = actionItemsCompany
        ? (Array.isArray(actionItemsStore?.actionItems) ? actionItemsStore.actionItems.filter(item => item.status === 1).length : 0)
        : (actionItemsStatus === 'complete' ? actionItemsStore.all.data.reduce((total, company) => total + company.actionItems.filter(item => item.status === 1).length, 0) : 0);

    useEffect(() => {
        if (actionItemsStatus !== 'idle') {
            return;
        }

        getAllActionItems()
            .then(({ data }) => {
                if (isMounted.current) {
                    setActionItems({
                        all: {
                            status: 'complete',
                            data
                        },
                    });
                }
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to get action items')))
    }, [isMounted, setActionItems, actionItemsStatus])

    return (
        <Flex alignItems="center" sx={{
            background: '#fff',
            py: 2,
            px: '30px'
        }}>
            {children}
            <Flex m={0} ml="auto" alignItems="center">
                {user ? (
                    <>
                        {toggleWhatsNew && (
                            <Button
                                variant="header"
                                onClick={toggleWhatsNew}
                                className={styles.headerIconButton}
                            >
                                <Icon icon="whatsNew" title="What's New" size={20} />
                            </Button>
                        )}

                        {(!company || !!toggleNotifications) && (
                            <Button
                                variant="header"
                                onClick={() => (company ? toggleNotifications() : history.push('/advisor/inbox'))}
                                className={styles.headerIconButton}
                            >
                                <Icon icon="bell2" title="Alerts" size={20} />
                                <Bubble top="-6px" right="-5px" sx={{ width: 'auto', minWidth: '20px' }} show={newNotificationsCount > 0}>
                                    {newNotificationsCount}
                                </Bubble>
                            </Button>
                        )}

                        {user?.permissions?.actionItems?.view && (
                            <Button variant="header" onClick={toggleShowActionItems} className={`${styles.headerIconButton} button--action-items`}>
                                <Icon icon="calendar" title="Action Items" size={20} sx={{ pointerEvents: 'none' }} />
                                <Bubble top="-6px" right="-5px" show={pendingCount > 0}>
                                    {pendingCount < 100 ? pendingCount : '99+'}
                                </Bubble>
                            </Button>
                        )}
                    </>
                ) : (
                    <>
                        <Skeleton variant="circle" size="30px" mr={2} />
                        <Skeleton variant="circle" size="30px" mr={2} />
                        <Skeleton variant="circle" size="30px" mr={2} />
                    </>
                )}


                <Button
                    variant="header"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        p: '4px 16px',
                        ml: 3,
                    }}
                    onClick={() => setMenuOpen(!menuOpen)}
                >
                    {user ? (
                        <Heading fontSize={'14px'}>
                            {user?.firstName} {user?.surname}
                        </Heading>
                    ) : <Skeleton height="24px" width="100px" mr={1} />}

                    {user ? <UserAvatar firstName={user?.firstName} surname={user?.surname} avatar={user?.avatar} mx="12px" size="30px" /> : <Skeleton variant="circle" size="30px" mr={2} />}

                    <Flex sx={{
                        alignItems: 'center',
                        color: 'blueDark',
                    }}>
                        <Icon
                            icon="arrow"
                            sx={{
                                transform: menuOpen ? 'rotate(-90deg)' : 'rotate(90deg)',
                                transition: 'transform 0.3s ease-in-out'
                            }}
                        />
                    </Flex>
                </Button>
            </Flex>
            <AccountMenu sx={{ top: '70px' }} user={user} show={menuOpen} closeMenu={() => setMenuOpen(false)} />
        </Flex>
    );
};

export default connect((state) => ({
    user: state.user,
    actionItemsStore: state.actionItems,
}), { setActionItems })(Header);
