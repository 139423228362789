import React from "react"
import { Box } from "rebass"
import { CardItem } from "../Card"

export const DataCard = ({ children, caption, fontSize = 22, sx, ...props }) => {
    return (
        <CardItem {...props} sx={{
            height: 'auto',
            alignItems: 'flex-start',
            flexDirection: 'column',
            padding: '28px 16px',
            fontWeight: 400,
            ...sx
        }} titleSx={{
            ...props.titleSx,
            flex: '0 !important',
            fontWeight: 600,
        }}>
            <Box fontSize={fontSize} fontWeight={700} color="blueDark" sx={{
                display: 'flex',
                alignItems: 'center',
                flex: 1
            }}>
                {children}
            </Box>
            {caption && <Box fontSize={10} color="captionGray">{caption}</Box>}
        </CardItem>
    )
}
