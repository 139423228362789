import React from 'react';
import { connect } from 'react-redux';
import AddbackTaskView from 'Pages/Common/TaskView/Addback';
import { isAdvisor, isAnalyst } from '@util/Functions';

const AddbackNormalisation = ({ companyId, user }) => {
    return <AddbackTaskView
        companyId={companyId}
        backLink={`/advisor/company/${companyId}/normalisation`}
        backLabel={'Back to Normalisation'}
        accreditedAdvisor={isAdvisor(user) || isAnalyst(user)}
        classify={true}
    />
};

export default connect((state) => ({ user: state.user }))(AddbackNormalisation);
