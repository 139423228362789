import React, { useState } from 'react';
import { Box, Flex, Button } from 'rebass';
import { AreaField } from '~/Form';
import { toast } from 'react-toastify';

const SliderReasonBetaFactor = ({ comment, setComment }) => {
    const [message, setMessage] = useState(comment);
    const [saved, setSaved] = useState(!!comment);
    const [hasErrors, setHasErrors] = useState(false);

    const saveComment = () => {
        if (message) {
            setComment(message);
            setSaved(true);
            setHasErrors(false);
        } else {
            setHasErrors(true);
            toast.error('Enter the reason why the slider has been changed so much');
        }
    };

    return (
        <Flex>
            <Box flex={1} mr={2}>
                {saved ? (
                    <Box mb={2}>{comment}</Box>
                ) : (
                    <AreaField
                        label="Please provide a comment"
                        value={message}
                        onChange={(val) => setMessage(val)}
                        slim={true}
                        errors={hasErrors ? ['Reason required'] : null}
                    />
                )}
            </Box>
            <Box>
                {saved ? (
                    <Button onClick={() => setSaved(false)}>Edit</Button>
                ) : (
                    <Button onClick={saveComment}>Save</Button>
                )}
            </Box>
        </Flex>
    );
};

export default SliderReasonBetaFactor;
