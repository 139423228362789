import React, { useState } from 'react'
import FinancialReports from '~/Dashboard/FinancialReports'
import { uploadFinancialReports } from '@app/Api'
import { toast } from 'react-toastify'
import LoadingSpinner from '~/LoadingSpinner'
import { getErrorMessage, updateUserStore } from '@util/Functions'
import { connect } from 'react-redux'
import { isAnalyst } from '@util/Functions/User'

const AdvisorReportFinancials = ({ companyId, user }) => {
    const [loading, setLoading] = useState(false)

    const canUploadFinancials = isAnalyst(user)

    if (loading) return <LoadingSpinner />

    if (!canUploadFinancials) return <FinancialReports companyId={companyId} />

    const handleUpload = (attachment) => {
        if (attachment) {
            setLoading(true)
            const formData = new FormData()
            formData.append('attachment', attachment)
            formData.append('companyId', companyId)
            uploadFinancialReports(formData)
                .then(() => {
                    updateUserStore()
                        .then(() => toast.success('Financials uploaded successfully'))
                        .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to get user store.')))
                        .finally(() => setLoading(false))
                })
                .catch(({ response }) => {
                    setLoading(false)
                    toast.error(getErrorMessage(response?.data, 'Unable to upload financials'))
                })
        }
    }

    return <FinancialReports companyId={companyId} uploadFinancials={handleUpload} />
}

export default connect((state) => ({ user: state.user }))(AdvisorReportFinancials);