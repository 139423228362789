import React from 'react';
import { Icon } from '@svg/Icon';
import { Box, Button, Text } from 'rebass';

export const Tabs = ({ label, children, sx, tabs, selected, onChange, multiSelect = false, styleVariant = '', variant = 'default', ...props }) => {
    const isMulti = multiSelect && Array.isArray(selected);

    const handleChange = (tab) => () => {
        if (typeof onChange !== 'function') {
            return;
        }

        if (isMulti) {
            let items = [...selected];

            if (items.includes(tab.key)) {
                items.splice(items.indexOf(tab.key), 1);
            } else {
                items.push(tab.key);
            }

            onChange(items)
        } else {
            onChange(tab);
        }
    }
    const tabVariant =
        typeof styleVariant === 'string' && styleVariant.length
            ? styleVariant.charAt(0).toUpperCase() + styleVariant.slice(1)
            : styleVariant;

    if (!Array.isArray(tabs)) {
        return null;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 3,
                ...(tabVariant !== ''
                    ? {
                        gap: 2,
                    }
                    : {}),
                ...sx,
            }}
            {...props}
        >
            {label && (
                <Text
                    variant={`variants.labelTab${tabVariant}`}
                    sx={{
                        fontWeight: 600,
                        fontSize: '12px',
                        color: 'blueDark2',
                    }}
                >
                    {label}
                </Text>
            )}
            {tabs.map((tab, key) => (
                <Button
                    key={tab.key ?? key}
                    variant={`tab${tabVariant}`}
                    className={`${selected === tab.key || (isMulti && Array.isArray(selected) && selected.includes(tab.key)) ? 'active' : ''} theme--${variant}`}
                    onClick={handleChange(tab)}
                    disabled={tab?.disabled}
                    sx={{
                        '&, &.theme--outlined': tab.sx,
                        '&.theme--outlined.active': {
                            borderColor: tab?.sx?.borderColor,
                            backgroundColor: tab?.sx?.borderColor,
                        }
                    }}
                >
                    {tab.icon && (
                        <Icon icon={tab.icon} color={tab.iconColor ?? '#2C2960'} sx={{ mr: 2 }} height="14px" />
                    )}
                    {tab.name}
                </Button>
            ))}
        </Box>
    );
};
