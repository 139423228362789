import React from 'react'
import styled from '@emotion/styled'
import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { theme } from '@app/theme'

import successIcon from '../../../Assets/Svg/success.svg'
import warningIcon from '../../../Assets/Svg/warn.svg'
import errorIcon from '../../../Assets/Svg/error.svg'

const WrappedToastContainer = ({ className, ...rest }) => (
    <div className={className} style={{ backgroundColor: 'blueDark' }}>
        <ToastContainer hideProgressBar={true} transition={Slide} autoClose={3000} {...rest} />
    </div>
)

export const Toast = styled(WrappedToastContainer)`
    .Toastify__toast-container {
        width: calc(100% - 320px);
    }

    .Toastify__close-button {
        align-self: center;
        color: ${theme.colors.blueDark};

        & > svg {
            height: 21px;
            width: 21px;
        }
    }

    .Toastify__toast {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white no-repeat 20px center;

        &-body {
            display: flex;
            margin: 0;
            font-weight: 500;
            font-size: 14px;
            color: ${theme.colors.blueDark};
            padding-left: 40px;
        }

        &--error {
            background-image: url(${errorIcon});
        }

        &--warning {
            background-image: url(${warningIcon});
        }

        &--success {
            background-image: url(${successIcon});
        }
    }
`
