import React, { useState } from 'react'
import { formatCurrency, getErrorMessage, updateUserStore } from '@util/Functions'
import { cancelSubscription, cancellationIntent, resumeSubscription } from "@app/Api"
import { toast } from 'react-toastify'
import { Flex, Button, Box } from 'rebass'
import { Card } from '~/Common/Card'
import LoadingSpinner from '~/LoadingSpinner'
import { connect } from 'react-redux'
import { Modal } from '~/Common/Modal'

const ManagePayments = ({ company, user }) => {
    const [loading, setLoading] = useState(false);
    const [outstanding, setOutstanding] = useState({});
    const [confirmCancellation, setConfirmCancellation] = useState(false);
    const companyId = company?.id;

    const checkOutstanding = () => {
        setLoading(true);
        cancellationIntent(companyId)
            .then(({ data }) => {
                setOutstanding(data)
                setConfirmCancellation(true)
            })
            .catch(({ response }) => {
                toast.error(getErrorMessage(response?.data, 'Unable to cancel subscription'));
            })
            .finally(() => {
                setLoading(false)
            });
    }

    const submitCancellation = () => {
        setLoading(true);
        cancelSubscription(companyId)
            .then((data) => {
                updateUserStore().then(() => setLoading(false));
                toast.success(data?.message ? data.message : 'Subscription cancelled');
            })
            .catch(({ response }) => {
                toast.error(getErrorMessage(response?.data, 'Unable to cancel subscription'));
                setLoading(false)
                setConfirmCancellation(false)
            });
    }

    const submitResume = () => {
        setLoading(true);
        resumeSubscription(companyId)
            .then((data) => {
                updateUserStore().then(() => setLoading(false));
                toast.success(data?.message ? data.message : 'Subscription resumed');
            })
            .catch(({ response }) => {
                toast.error(getErrorMessage(response?.data, 'Unable to resume subscription'));
                setLoading(false)
            });
    }

    const outstandingValue = () => {
        let value = outstanding?.outstanding;
        let currency = outstanding?.currency;

        return value ? formatCurrency(value / 100, currency.toUpperCase()) : null
    }

    const advisor = outstanding?.advisor?.name
    const subscription = company?.subscription

    return loading ? <LoadingSpinner /> : <>
        <Flex width={1} flexDirection="column">
            <Card title="Manage Subscription & Refunds">
                {subscription?.cancelled ? `The company subscription has been cancelled and will end on ${subscription?.endsAt}` :
                    (subscription?.trialing && `This company is on a trial subscription and will end on ${subscription?.trialEnd}`)
                }
                <Flex>
                    {subscription?.cancelled ?
                        <Button onClick={() => submitResume()} flexGrow="1" flexBasis="0" m={2} variant={subscription && subscription.paid ? "tertiary" : "disabled"} disabled={subscription && !subscription.paid}>
                            Resume Subscription (Restore Company)
                        </Button> :
                        <Button onClick={() => checkOutstanding()} flexGrow="1" flexBasis="0" m={2} variant={subscription && subscription.paid ? "tertiary" : "disabled"} disabled={subscription && !subscription.paid}>
                            Cancel Subscription (Remove Company)
                        </Button>
                    }
                </Flex>
            </Card>
        </Flex>
        <Modal isOpen={confirmCancellation}>
            <Flex flexDirection={'column'} textAlign={'center'}>
                {outstandingValue() && <>
                    <Box as="p" mb={2}>{`${advisor} will be charged an early cancellation fee of ${outstandingValue()}`}</Box>
                </>}
                <Box as="p" mb={2}>{`The subscription will end on ${outstanding?.end_date}`}</Box>
                <Box as="p" mb={2}>Please confirm the cancellation</Box>
                <Flex>
                    <Button onClick={() => submitCancellation()} variant={"tertiary"} flexGrow="1" flexBasis="0" m={2}>
                        Confirm Cancellation
                    </Button>
                    <Button onClick={() => setConfirmCancellation(false)} variant={"tertiary"} flexGrow="1" flexBasis="0" m={2}>
                        Keep Subscription
                    </Button>
                </Flex>
            </Flex>
        </Modal>
    </>
}

export default connect((state) => ({ user: state.user, company: state.company }))(ManagePayments);
