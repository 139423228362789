export const FinancialScorecard = 1;
export const NonFinancialScorecard = 2;
export const CreditScorecard = 3;
export const ExitReadinessScorecard = 4;
export const BetaFactorSliderScorecard = 5;
export const FoundationScorecard = 6;
export const StrategicGrowthScorecard = 7;
export const RiskScorecard = 8;
export const EsgScorecard = 9;

export const ReportCreated = 0;
export const ReportSubmitted = 1;
export const ReportFinalised = 2;
export const ReportChiefAnalystReview = 3;
export const ReportAdviserReview = 4;
export const ReportShared = 5;

export const sliderTypesBetaFactor = ['Economy', 'Industry', 'Business'];

export const sliderTypesRisk = ['Economic', 'Industry', 'Company'];

export const sliderTypesSustainableGrowth = ['Risks'];