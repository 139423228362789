import React from 'react';
import { Flex, Box } from 'rebass'

export const OverviewRow = ({ title, children, sx, ...props }) => (
    <Flex
        sx={{
            mb: 2,
            justifyContent: 'space-between',
            fontSize: '14px'
        }}
        {...props}
    >
        <Box sx={{ color: '#9594AF' }}>{title}</Box>
        <Box sx={{ color: '#2C2960', fontWeight: 600, textAlign: 'right', pl: 2 }}>{children}</Box>
    </Flex>
)
