import React from 'react'
import { Box, Button, Flex } from 'rebass'
import { Link, withRouter } from 'react-router-dom'
import { getTaskStatus } from '@util/Enum'
import styles from './Assets/stylesCurrentTask.module.scss'
import cx from 'classnames'

const CurrentTask = ({ tasks, onboarded }) => {
    const pageUrl = window.location.pathname
    return <Box fontSize={11}>
        {tasks.map(({ id, name, status, stageName }, key) => {
            const url = `/company/projects/task/${id}`;
            const { sx: statusStyle, name: statusName } = getTaskStatus(status)
            return <Link to={url} key={key}>
                <Flex key={id} className={cx(styles.menuLinks, pageUrl === url ? styles.active : undefined)} mx={3} my={1}>
                    <Box>
                        <Box className={styles.stageName}>{stageName}</Box>
                        <Box className={styles.taskName}>{name}</Box>
                    </Box>
                    <Button variant='smallTask' ml='auto' sx={statusStyle}>{statusName}</Button>
                </Flex>
            </Link>
        })}

        {!tasks.length && <Box p={4}>
            <Link to='/'>You have no active tasks.</Link>
        </Box>}

        <Link to={onboarded ? '/company/projects' : '/'}>
            <Box className={styles.allTasks} m={3} p={3}>View All</Box>
        </Link>
    </Box>
}

export default withRouter(CurrentTask)
