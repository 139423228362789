import { getActionItemsDashboard } from '@app/Api'
import { debounce } from '@material-ui/core'
import { getErrorMessage } from '@util/Functions'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Box, Flex, Link, Text } from 'rebass'
import { PageTitle } from '~/Common/PageTitle'
import { Table } from '~/Common/Table'
import { CustomSelect, TextField } from '~/Form'
import { Card } from '~/Common/Card'
import CreateActionItemModal from '~/Modals/CreateActionItemModal'
import { IconButton } from '~/Common/IconButton'
import { ActionItemStatus } from '~/Common/ActionItemStatus'

const TableGroup = ({
    row,
    rowProps,
    components: {
        CollapseColumn,
    },
    children,
    ...props
}) => {
    return (
        <Flex {...rowProps} sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            fontWeight: 700,
            p: '20px 24px',
        }}>
            {row.title}
            <CollapseColumn
                size={24}
                iconSize={12}
                sx={{
                    borderRadius: '50%',
                    background: '#ECFAFE',
                }}
            />
        </Flex>
    )
}


const getColumns = ({ setSelectedActionItem }, user) => {
    let columns = [
        {
            key: 'task',
            title: 'Project Name',
            sx: {
                minWidth: '380px',
            },
            render: (row) => (
                <Link
                    to={`/advisor/company/${row.companyId}/stages/task/${row.companyTask.id}`}
                    sx={{ fontWeight: 400 }}
                    onClick={() => setSelectedActionItem(row)}
                >
                    {row?.task}
                </Link>
            ),
        },
        {
            key: 'status',
            title: 'Status',
            render: (row) => {
                return <ActionItemStatus status={row.status} isOverdue={row.overdue} />
            },
        },
        {
            key: 'assignee',
            title: 'Assignee',
            render: (row) => (
                <Text
                    sx={{
                        fontWeight: 400,
                        fontSize: '12px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {row?.assignee?.name}
                </Text>
            ),
        },
        {
            key: 'dueDate',
            title: 'Due Date',
            render: (row) => (
                <Text
                    sx={{
                        fontWeight: 400,
                        fontSize: '12px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {row?.dueDate}
                </Text>
            ),
        },
    ]

    if (user?.permissions?.actionItems?.create) {
        columns.push({
            key: 'actions',
            sx: {
                justifyContent: 'flex-end'
            },
            sortable: false,
            render: (row) => (
                <IconButton
                    label="View Action"
                    onClick={() => setSelectedActionItem(row)}
                />
            ),
        })
    }

    return columns
};

const ActionItems = ({ companyId, user }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false)
    const [selectedActionItem, setSelectedActionItem] = useState(null);
    const [assignees, setAssignees] = useState([])
    const [items, setItems] = useState([])
    const [reload, setReload] = useState(true);
    const columns = useRef(getColumns({ setSelectedActionItem }, user)).current;
    const searchRef = useRef();
    const [params, setParams] = useState({
        search: null,
        orderBy: null,
        assignee: null,
        status: null,
        stage: null,
    })

    const updateParam = (key, value) => {
        if (key in params && params.key !== value) {
            setParams(oldParams => ({ ...oldParams, [key]: value }))
            setReload(true);
        }
    }

    const sortByColumn = (column, order) => updateParam('orderBy', `${column},${order}`);

    const debouncedSearch = useCallback(
        debounce((e, force = false) => {
            if (searchRef.current.value.length >= 2 || force) {
                updateParam('search', searchRef.current.value);
            } else if (searchRef.current.value.length === 0) {
                updateParam('search', null);
            }
        }, 300),
        [searchRef]
    );

    const company = companyId ?? user?.companyId

    useEffect(() => {
        if (company && reload) {
            setReload(false);
            setLoading(true)
            getActionItemsDashboard(company, params)
                .then(({ data }) => {
                    setAssignees(data?.assignees)
                    setItems(data?.actionItems)
                })
                .catch(({ response }) =>
                    toast.error(getErrorMessage(response?.data, 'Unable to load action items'))
                )
                .finally(() => setLoading(false))
        }
    }, [company, params, reload])

    return <>
        <Box px={3}>
            <PageTitle
                subtitle={t('actionItems.subtitle')}
            />
        </Box>
        <Card groupSx={{ p: 0 }} borderRadius={8}>
            <Flex sx={{ p: '16px 24px', bg: '#fff', borderTop: '1px solid #F5F7F8' }}>
                <Box sx={{ flex: 1 }}>
                    <TextField
                        variant="inputs.thin"
                        ref={searchRef}
                        autoComplete={'off'}
                        onChange={(e) => {
                            debouncedSearch(e);
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                debouncedSearch(e, true);
                            }
                        }}
                        placeholder="Search for action item"
                        icon="search"
                        mb={0}
                        boxSx={{ width: '320px' }}
                        iconColor="#C3C7D0"
                    />
                </Box>
                <CustomSelect
                    name="assignee"
                    value={params.assignee}
                    onChange={(option) => updateParam('assignee', option.value)}
                    text="Filter by Assignee"
                    icon="users"
                    options={assignees.map(({ id, name }) => ({ value: id, label: name }))}
                    sx={{
                        minWidth: '180px',
                        ml: 3,
                    }}
                    boxSx={null}
                    nullable={true}
                    clearSelection={() => updateParam('assignee', null)}
                />
                <CustomSelect
                    name="status"
                    value={params.status}
                    onChange={(option) => updateParam('status', option.value)}
                    text="Filter by Status"
                    icon="filter"
                    options={[
                        {
                            value: 'pending',
                            label: 'Pending',
                            color: '#EBC02C'
                        },
                        {
                            value: 'complete',
                            label: 'Complete',
                            color: '#34D399'
                        },
                        {
                            value: 'validated',
                            label: 'Validated',
                            color: '#5B21B6'
                        },
                        {
                            value: 'overdue',
                            label: 'Overdue',
                            color: '#FF5B5B'
                        },
                    ]}
                    sx={{
                        minWidth: '180px',
                        ml: 3,
                    }}
                    boxSx={null}
                    nullable={true}
                />
            </Flex>
            <Table
                columns={columns}
                data={Object.entries(items).map(i => ({
                    title: i[0],
                    rows: i[1],
                }))}
                nested="rows"
                showCollapse={false}
                groupHeader={TableGroup}
                isLoading={loading}
                minWidth={1000}
                emptyTemplate={{
                    icon: 'done',
                    heading: 'No action items',
                    content: 'There are no action items matching the current options',
                }}
                wrapperSx={{ pb: 0 }}
                boxSx={{ p: 0, gap: 0 }}
                rowSx={{
                    bg: '#F5F7F8',
                    '&:not(:last-child)': {
                        borderBottom: '1px solid #E8EAEF'
                    }
                }}
                columnSort={sortByColumn}
            />
        </Card>
        <CreateActionItemModal
            companyId={company}
            isOpen={!!selectedActionItem}
            selectedItem={selectedActionItem}
            requestClose={() => setSelectedActionItem(null)}
            onEditItem={() => setReload(true)}
        />
    </>

}

export default connect((state) => ({
    user: state.user,
}), {})(ActionItems)
