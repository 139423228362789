import { createSlice } from '@reduxjs/toolkit'

const company = createSlice({
    name: 'company',
    initialState: null,
    reducers: {
        setCompany: (state, { payload }) => payload,
        resetCompany: () => null,
    }
})

export const { setCompany, resetCompany } = company.actions

export default company.reducer
