import axios from 'axios'

export const getNotifications = (params) =>
    axios.get(`notification/get`, params)
        .then(({ data }) => data)

export const getUnreadNotificationCount = () =>
    axios.get(`notification/unread-count`)
        .then(({ data }) => data?.count)

export const readAllNotifications = () =>
    axios.get(`notification/read-all`)
        .then(({ data }) => data)

export const setNotificationAsSeen = (notificationId) =>
    axios.get(`notification/seen/${notificationId}`)
        .then(({ data }) => data)

export const setTaskAsSeen = (companyTaskId) =>
    axios.get(`notification/seen-task/${companyTaskId}`)
        .then(({ data }) => data)
