import React, { useState } from 'react';
import { Box, Button, Text } from 'rebass';
import { TextField } from '~/Form';
import { toast } from 'react-toastify';
import { resetPassword } from '@app/Api';
import CredentialsLayout from '../CredentialsLayout/CredentialsLayout';

const Login = () => {
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState({});

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        resetPassword({ email })
            .then(() => {
                localStorage.clear();
                setSubmitted(true);
                setIsSubmitting(false);
            })
            .catch(({ response }) => {
                response?.data?.errors && setErrors(response.data.errors);
                toast.error(response?.data?.message || 'There was an unexpected error while trying to log in');
                setIsSubmitting(false);
            });
    };

    return (
        <Box>
            <CredentialsLayout handleSubmit={handleSubmit} isSubmitting={isSubmitting} heading="Reset Password">
                {submitted && (
                    <Box>
                        <Text sx={{ fontSize: '20px', mb: 30, maxWidth: '300px', mx: 'auto' }}>
                            We've emailed you instructions on how to reset your password. If you don't see it, don't
                            forget to check your spam folder.
                        </Text>

                        <Box pt={30} fontSize={12}>
                            You can close this tab/window.
                        </Box>
                    </Box>
                )}

                {!submitted && (
                    <Box>
                        <Box mb={2}>
                            <Text sx={{ fontSize: '20px', mb: 30, maxWidth: '300px', mx: 'auto' }}>
                                To reset your password, we need to send you an email.
                            </Text>

                            <TextField
                                name="email"
                                value={email}
                                onChange={(val) => setEmail(val)}
                                label="Email"
                                errors={errors.email}
                            />
                        </Box>

                        {isSubmitting ? (
                            <Button variant="submitting" width="100%" disabled="disabled">Submitting...</Button>
                        ) : (
                            <Button variant="primary" width="100%">Reset password</Button>
                        )}
                    </Box>
                )}
            </CredentialsLayout>
        </Box>
    );
};

export default Login;
