import React from 'react';
import { Button } from '../Button';

export const SupportLink = ({ sx, ...props }) => {
    return (
        <Button
            sx={{
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                borderRadius: '50px',
                zIndex: 99999,
                ...sx,
            }}
            icon="commentNew"
            to="/support"
            {...props}
        >
            Support
        </Button>
    );
};
