import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Box, Button, Flex, Heading } from 'rebass';
import styles from './styles.module.scss';
import { Icon } from '@svg';
import { Input } from "@rebass/forms";

const IndexPage = ({
    file,
    pageWidth = 545,
    wrapperHeight = "100%",
}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [isFullWidth, setIsFullWidth] = useState(false);
    const [outOfPageNum, setOutOfPageNum] = useState(false);


    const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

    const nextPage = () => pageNumber < numPages && setPageNumber(pageNumber + 1);
    const previousPage = () => pageNumber > 1 && setPageNumber(pageNumber - 1);
    const toggleWidth = () => setIsFullWidth((prev) => !prev);
    
    const goToPage = (value) => {
        const newPage = parseInt(value, 10);
        if (!isNaN(newPage) && newPage > 0 && newPage <= numPages) {
            setPageNumber(newPage);
            setOutOfPageNum(false);
        }else if((newPage === 0 || newPage > numPages) && newPage !== null ){
            setOutOfPageNum(true);
        }
    };



    const renderPagination = (sx, showJumpTo = true) => (
        <Box sx={sx} width={1}>
            <Flex alignItems="center" justifyContent="center">
               <Icon icon="arrow" onClick={previousPage} className={styles.paginationLeft} sx={{transform:'translate(RotateX'}} />
                <Box className={styles.paginationCenter}>
                    <p>

                    Page 
                    </p>
                   
                    <Input
                    placeholder={pageNumber}
                    type="text"
                    sx={{
                        width:'50px',
                        height: '40px',
                        marginBottom: '0px',
                        textAlign:'center',
                    }}
                    onChange={(e) =>goToPage(e.target.value)}
                    onBlur={(event) => { event.target.value = ''; }}
                    onKeyDown={(e) => {
                        if ((e.key === 'ArrowLeft' || e.key === 'ArrowDown')  && pageNumber > 1) {
                            setPageNumber(pageNumber - 1);
                            e.target.value = ''
                        }
                    }}
                    onKeyUp={(e) => {
                        if ((e.key === 'ArrowUp' || e.key === 'ArrowRight')&& pageNumber < numPages) {
                            setPageNumber(pageNumber + 1);
                            e.target.value = ''
                        }
                    }}
                    />

                    <p>
                     of {numPages}
                    </p>
                </Box>

                <Icon icon="arrow" onClick={nextPage} className={styles.paginationRight}/>
            </Flex>
           {outOfPageNum &&  <p className={styles.textWarning}>the Page number is out of the page range.</p> }
        </Box>
    );

    return (
        <Box variant="card.wrapper" p={30}>
            <Flex justifyContent="space-between">
                <Heading>Document Preview</Heading>
                <Flex sx={{gap:2}}>
                <Button onClick={toggleWidth} variant="preview" className={ !isFullWidth ? 'active' : ''}  >
                    <Icon icon="minimize" stroke={ !isFullWidth ? '#FFFFFF' : 'blueDark'} color={ !isFullWidth ? '#FFFFFF' : 'blueDark'}  />
                    <p>Fit width</p>
                </Button>
                <Button onClick={toggleWidth} variant="preview" className={ isFullWidth ? 'active' : ''} >
                    <Icon icon="maximize" stroke={ isFullWidth ? '#FFFFFF' : 'blueDark'} color={ isFullWidth ? '#FFFFFF' : 'blueDark'} />
                    <p>Full width</p>
                </Button>
                </Flex>
            </Flex>

            {renderPagination({ mb: 4 })}
            <Box
                mx="auto"
                width={1}
                sx={{
                    overflow: 'hidden',
                    maxHeight: wrapperHeight,
                    '& canvas': {
                        margin: '0 auto',
                        height: 'auto !important',
                        maxWidth: '100%',
                        maxHeight: '100%',
                    },
                }}
            >
                
                <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page
                        width={isFullWidth ? window.innerWidth : pageWidth}
                        pageNumber={pageNumber}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                    />
                </Document>
            </Box>
            {renderPagination({ mt: 4 })}
        </Box>
    );
};

export default IndexPage;
