import { getContentPage } from '@app/Api';
import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useEffect } from 'react';
import LoadingSpinner from '~/LoadingSpinner';
import { Box, Text } from 'rebass';
import { theme } from '@app/theme';
import { Card } from '~/Common/Card';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from '~/Common/Button';
import { Icon } from '@svg/Icon';

const styles = {
    '& .hs-form-field': {
        marginBottom: 1,
    },
    '& .hs-input': {
        ...theme.input,
        width: '100%',
    },
    '& .field > label': {
        ...theme.label,
        display: 'block',
        marginBottom: 2,
    },
    '& [type=file]': {
        display: 'none',
    },
    '& .hs-button': {
        ...theme.buttons.primary,
    },
    '& .hs-error-msgs': {
        marginTop: -3,
    },
    '& textarea': {
        resize: 'vertical',
        minHeight: '120px',
    },
};

const AttachmentButton = ({ input }) => {
    const [files, setFiles] = useState(null);

    useEffect(() => {
        const handleChange = (e) => {
            if (e.target.files.length) {
                setFiles(
                    [...e.target.files].map((file) => {
                        return file.name;
                    })
                );
            } else {
                setFiles(null);
            }
        };
        input.addEventListener('change', handleChange);
        return () => input.removeEventListener('change', handleChange);
    }, [input]);

    return (
        <>
            {Array.isArray(files) &&
                files.map((file) => (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 3,
                            alignItems: 'center',
                            px: 3,
                            py: 2,
                            background: '#e8e8e8',
                            color: '#727272',
                            mb: 2,
                            borderRadius: '8px',
                            fontSize: '12px',
                        }}
                    >
                        <Icon icon="paper3" />
                        {file}
                    </Box>
                ))}
            <Button
                as="label"
                htmlFor="file_field-8bb7fcc8-92a5-48a9-8975-74f41e29cbd9"
                icon="attachment"
                onClick={() => input.click()}
                sx={{
                    bg: '#f0f0f0',
                    display: 'inline-flex',
                    mb: 4,
                    color: '#5e5e5e',
                }}
            >
                <Text sx={{ ml: 1, fontSize: 0 }}>{'Add Attachment'}</Text>
            </Button>
        </>
    );
};

const Support = ({ pageUrl }) => {
    const [loading, setLoading] = useState(true);
    const [loadingForm, setLoadingForm] = useState(true);
    const [content, setContent] = useState('');
    const [embedded, setEmbedded] = useState(false);
    const history = useHistory();
    const { t } = useTranslation();
    const formRef = useRef();
    const initialValues = history.location.state;

    useEffect(() => {
        getContentPage(pageUrl).then((content) => {
            setLoading(false);
            setContent(content);
        });
    }, [pageUrl]);

    const setFormRef = (ref) => {
        if (ref && !embedded) {
            setEmbedded(true);

            const script = document.createElement('script');
            script.src = '//js.hsforms.net/forms/embed/v2.js';
            document.body.appendChild(script);

            script.addEventListener('load', () => {
                if (window.hbspt) {
                    window.hbspt.forms.create({
                        region: 'na1',
                        portalId: '22800991',
                        formId: '8bb7fcc8-92a5-48a9-8975-74f41e29cbd9',
                        target: '#hubspotForm',
                        onFormReady: () => {
                            if (typeof initialValues === 'object') {
                                Object.entries(initialValues).forEach((field) => {
                                    const element = document.querySelector(`#hubspotForm [name="${field[0]}"]`);
                                    if (element) {
                                        element.value = field[1];
                                    }
                                });
                            }

                            document.querySelectorAll('#hubspotForm [type=file]').forEach((input) => {
                                const container = document.createElement('div');
                                input.after(container);
                                ReactDOM.render(<AttachmentButton input={input} />, container);
                            });

                            setLoadingForm(false);
                        },
                    });
                }
            });
        }

        return (formRef.current = ref);
    };

    const isLoading = loading || loadingForm;

    return (
        <Box sx={{ maxWidth: '900px', pb: 6 }}>
            <Card title={t('Support')}>
                {isLoading ? <LoadingSpinner /> : <>{content}</>}
                <Box id="hubspotForm" sx={{ ...styles, display: isLoading ? 'none' : 'block' }} ref={setFormRef} />
            </Card>
        </Box>
    );
};

export default Support;
