import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { Box } from 'rebass';
import { Icons, Icon } from '@svg';
import { theme } from '@app/theme';

const sidebarLinkCss = css`
    background: transparent;
    padding: 10px;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    transition: all 0.25s ease;

    &:last-of-type {
        margin-bottom: 0;
    }
`;

const SidebarLinkNav = styled.nav`
    width: 100%;
    padding: 0 ${({ condensed }) => (condensed ? '0' : '8px')};

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        width: 100%;

        a {
            padding: 10px ${({ condensed }) => (condensed ? '6px' : '19px')};
            line-height: 20px;

            &:hover {
                background-color: ${theme.colors.blueLight20};
            }
        }

        span {
            &:hover {
                background: transparent;
            }
        }

        ul {
            margin-bottom: 10px;

            li {
                margin-bottom: 3px;

                a {
                    padding-top: 8px;
                    padding-bottom: 8px;
                }
            }
        }
    }

    li {
        margin-bottom: 10px;
        padding: 0;
    }
`;

const SidebarLinkParent = styled.span`
    ${sidebarLinkCss}
    ${({ condensed }) =>
        condensed
            ? `
            padding: 5px;
            justify-content: center;
        `
            : 'padding-left: 20px;'}

    &:hover {
        background: transparent;
    }
`;

const SidebarLinkStyled = styled(Link)`
    ${sidebarLinkCss}
    ${({ condensed }) =>
        condensed
            ? `
            padding: 5px;
            justify-content: center;
        `
            : 'padding-left: 20px;'}

    border-radius: ${({ issub, condensed }) => (issub || !condensed ? '8px' : '0')};
    margin-left: ${({ issub, condensed }) => (issub && !condensed ? '20px' : 0)};
    background: ${({ isactive }) => (isactive ? theme.colors.blueLight20 : 'transparent')};
`;

const SidebarIcon = styled(Box)`
    margin-right: ${({ condensed }) => (condensed ? '0' : '10px')};
    flex-shrink: 0;
    display: flex;
    align-items: center;
`;

const SidebarTodo = styled(Box)`
    margin-left: auto;
    background: ${(props) => props.fill};
    border-radius: 8px;
    padding: 2px 10px;
    font-size: 10px;
`;

const SuccessStyled = styled(Icons.success)`
    margin-left: auto;
    height: 15px;
    width: 15px;
`;

const SidebarItems = styled.ul`
    position: relative;

    ${({ condensed, isopen }) =>
        condensed
            ? `
            position: absolute;
            padding: 20px !important;
            border-radius: 8px;
            background: #fff;
            left: 100%;
            margin-left: 10px !important;
            top: 0;
            width: 300px !important;
            z-index: 999;
            box-shadow: 0px 0px 20px rgba(0, 36, 128, 0.1);

            opacity: ${isopen ? 1 : 0};
            pointer-events: ${isopen ? 'all' : 'none'};
            transition: opacity 0.3s;
    `
            : ''}
`;

const SidebarParent = styled.div`
    position: relative;
`;

const Links = ({ id, link, issub, condensed, iconSize = 20 }) => {
    const pageUrl = window.location.pathname;
    const [isOpen, setOpen] = useState(false);
    const mouseLeaveRef = useRef(null);

    if (link.subLinks) {
        const handleOpen = () => {
            clearTimeout(mouseLeaveRef.current);
            setOpen(true);
        };
        const handleClose = () => {
            mouseLeaveRef.current = setTimeout(() => setOpen(false), 500);
        };

        return (
            <SidebarParent key={id} condensed={condensed} onMouseEnter={handleOpen} onMouseLeave={handleClose}>
                <ul>
                    <li>
                        <SidebarLinkParent condensed={condensed}>
                            <SidebarIcon condensed={condensed}>{link.icon && <Icon size={iconSize} icon={link.icon} />}</SidebarIcon>
                            {(!condensed || issub) && link.title}
                        </SidebarLinkParent>
                    </li>
                </ul>
                <SidebarItems condensed={condensed} isopen={isOpen}>
                    {link.subLinks.map((link, id) => (
                        <Links key={id} id={id} link={link} issub={true} condensed={condensed} />
                    ))}
                </SidebarItems>
            </SidebarParent>
        );
    } else {
        const isActive =
            pageUrl.indexOf(link.link) === 0 ||
            (link.subActiveLinks !== undefined && link.subActiveLinks.indexOf(pageUrl) >= 0)
                ? 1
                : 0;
        const fill = isActive ? '#19CEFF' : '#E8FBFF';
        return (
            <li key={id}>
                <SidebarLinkStyled
                    to={link.link}
                    condensed={condensed ? 1 : 0}
                    issub={issub ? 1 : 0}
                    isactive={isActive}
                >
                    <SidebarIcon condensed={condensed}>{link.icon && <Icon size={iconSize} icon={link.icon} />}</SidebarIcon>
                    {(!condensed || issub) && (
                        <>
                            {link.title}
                            {link.todo && link.todo === 'completed' && <SuccessStyled fill={fill} />}
                            {link.todo && link.todo !== 'completed' && (
                                <SidebarTodo fill={fill}>{link.todo}</SidebarTodo>
                            )}
                        </>
                    )}
                </SidebarLinkStyled>
            </li>
        );
    }
};

export const SidebarLink = ({ links, condensed }) => {
    return (
        <SidebarLinkNav condensed={condensed}>
            <ul>
                {links.map((link, id) => (
                    <Links key={id} id={id} link={link} issub={false} condensed={condensed} />
                ))}
            </ul>
        </SidebarLinkNav>
    );
};

SidebarLink.defaultProps = {
    links: [],
};
