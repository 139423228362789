import React, { useState } from 'react';
import { Icon } from '@svg';
import { Box, Flex } from 'rebass';
import { Close } from 'theme-ui';
import { theme } from '@app/theme';

export const Alert = ({
    children,
    isCloseable = true,
    icon = 'questionMark2',
    iconProps,
    variant = 'default',
    ...props
}) => {
    const [isOpen, setIsOpen] = useState(true);
    return (
        <>
            {isOpen && (
                <Flex variant={`alerts.default`} sx={theme.alerts[variant] ?? {}} {...props}>
                    <Icon icon={icon} size="20px" mr={3} {...iconProps} />
                    <Box mr={3} flex={1}>{children}</Box>
                    {isCloseable && <Close ml="auto" mr={-2} onClick={() => setIsOpen(false)} />}
                </Flex>
            )}
        </>
    );
};
