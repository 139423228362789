import React from 'react';
import { Box } from 'rebass';
import { Label } from '@rebass/forms';
import ReactSelect from 'react-select';
import ReactSelectAsync from 'react-select/async';

const customStyles = {
    control: (base, state) => ({
        ...base,
        // none of react-select's styles are passed to <Control />
        padding: '5px 5px',
        border: state.isFocused ? '1px solid #19CEFF' : '1px solid rgba(132, 146, 166, 0.2)',
        outline: state.isFocused ? '3px auto #19CEFF' : 0,
        borderRadius: '5px',
        backgroundColor: 'white',
        fontSize: '16px',
        fontFamily: 'Open Sauce Two, system-ui, sans-serif',
        '&:hover': {
            border: '1px solid #19CEFF',
        },
        boxShadow: 'none',
    }),
    option: (base, state) => ({
        ...base,
        color: state.isSelected ? '#093467' : '#2C2960',
        backgroundColor: state.isFocused
            ? state.isSelected
                ? '#19CEFF'
                : 'rgba(132, 146, 166, 0.2)'
            : state.isSelected
                ? '#19CEFF'
                : 'white',
    }),
};

const SearchableSelectField = React.forwardRef(
    (
        {
            value,
            onChange,
            name,
            errors = null,
            label = null,
            helpText = '',
            placeholder,
            options,
            loadOptions,
            disabled,
            menuPosition,
            sx,
            boxSx = { marginBottom: 20 },
            nullable = false,
            ...props
        },
        ref
    ) => {
        const Select = loadOptions ? ReactSelectAsync : ReactSelect;

        return (
            <Box sx={boxSx}>
                {label && (
                    <Label htmlFor={name} mb={1} flexDirection="column">
                        {label}{' '}
                        {helpText && (
                            <>
                                <br />
                                <small>{helpText}</small>
                            </>
                        )}
                    </Label>
                )}
                <Select
                    id={name}
                    name={name}
                    value={value !== undefined && value !== null ? value : ''}
                    onChange={onChange}
                    styles={customStyles}
                    options={options}
                    loadOptions={loadOptions}
                    disabled={disabled}
                    ref={ref}
                    placeholder={placeholder}
                    isClearable={nullable}
                    menuPosition={menuPosition}
                    {...props}
                />
                {errors && (
                    <Box mt="2px" mb={20} color="red" fontSize={12}>
                        {errors[0]}
                    </Box>
                )}
            </Box>
        );
    }
);

export default SearchableSelectField;
