import React from 'react';
import { Icon } from '@svg/Icon';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { Button as ButtonRebass } from 'rebass';

export const Button = ({ children, variant, icon, iconProps, iconSize = 13, sx, to, inline = false, ...props }) => {
    const externalLink = typeof to === 'string' && to.toLowerCase().startsWith('http') ? true : false;

    return (
        <ButtonRebass
            variant={variant ?? 'primary'}
            sx={{
                fontFamily: 'OpenSauceTwo, Arial',
                display: inline ? 'inline-flex' : 'flex',
                alignItems: 'center',
                gridGap: '8px',
                cursor: 'pointer',
                justifyContent: 'center',
                ...sx,
            }}
            as={!to ? undefined : externalLink ? 'a' : Link}
            to={!externalLink ? to : undefined}
            href={externalLink ? to : undefined}
            {...props}
        >
            {icon && <Icon icon={icon} sx={{ minWidth: iconSize }} {...iconProps} />}
            {children}
        </ButtonRebass>
    );
};

Button.defaultProps = {
    variant: 'primary',
};
