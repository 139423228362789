import styles from './styles.module.scss';
import { Box, Button, Flex } from 'rebass';
import React, { useRef, useState } from 'react';
import { addTaskComment } from '@app/Api';
import { toast } from 'react-toastify';
import UserAvatar from '~/Dashboard/Avatar';
import WysiwygField from '~/Form/WysiwygField';
import { Text } from '@theme-ui/components';
import { Icon } from '@svg';
import LoadingSpinner from '~/LoadingSpinner';

const PostTaskComment = ({ companyTaskId, updateMessages, updateAttachments, user, sx = {} }) => {
    const [comment, setComment] = useState('');
    const [errors, setErrors] = useState({});
    const [attachments, setAttachments] = useState([]);
    const [loading, isLoading] = useState(false);
    const fileInput = useRef(null);

    const handleSubmitComment = () => {
        isLoading(true);
        const formData = new FormData();
        if (attachments.length)
            attachments.map((attachment, id) => formData.append(`attachment[${id}]`, attachment))

        formData.append('comment', comment);
        formData.append('companyTaskId', companyTaskId);
        addTaskComment(formData)
            .then((task) => {
                updateMessages(task.messages);
                updateAttachments(task.attachments);
                setComment('');
                setAttachments([]);
                setErrors({});
                toast.success('Comment added successfully');
            })
            .catch(({ response }) => {
                const errorMessage = response?.data?.message || 'Unable to add comment';
                toast.error(errorMessage);
                response?.data?.errors && setErrors(response.data.errors);
            })
            .finally(() => isLoading(false))
    };

    const removeAttachment = (id) => {
        const newAttachments = [...attachments]
        newAttachments.splice(id, 1)
        setAttachments(newAttachments)
    }

    return (loading ? <LoadingSpinner /> :
        <Box className={styles.postMessage} p={4} sx={sx}>
            <Flex>
                <UserAvatar firstName={user.firstName} surname={user.surname} email={user?.email} avatar={user.avatar} mr={2} />
                <Box flex={1}>
                    <Box>
                        <WysiwygField value={comment} setValue={setComment} />
                        {errors.comment && (
                            <Box mb={-15} color="red" fontSize={12}>
                                {errors.comment[0]}
                            </Box>
                        )}
                    </Box>
                    <Flex mt={3}>
                        <Box flex={1}>
                            <Flex
                                as="button"
                                onClick={() => fileInput.current.click()}
                                sx={{
                                    border: 'none',
                                    p: 0,
                                    bg: 'transparent',
                                    alignItems: 'center',
                                    color: 'blue',
                                    transition: 'easeDefault',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        color: 'blueDark',
                                    },
                                }}
                            >
                                <Icon icon="attachment" title="Add Attachment" className={styles.attachmentIcon} />
                                <Text sx={{ ml: 1, fontSize: 0 }}>Add Attachment</Text>
                            </Flex>

                            {attachments.map(({ name }, id) =>
                                <Flex variant="attachmentLink" onClick={() => removeAttachment(id)} key={id}>
                                    <Icon icon="close" stroke="#656D78" title="Close Popup" />
                                    <Box fontSize={12}>
                                        {name}
                                    </Box>
                                </Flex>
                            )}
                            <input
                                ref={fileInput}
                                type="file"
                                name="file[]"
                                multiple
                                className={styles.attachmentInput}
                                onChange={e => setAttachments([...attachments].concat(Array.from(e.target.files)))}
                            />
                        </Box>
                        <Box textAlign="right">
                            <Button variant={loading ? 'disabled' : 'primary'} ml="auto" onClick={handleSubmitComment}>
                                Leave Comment
                            </Button>
                        </Box>
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );
};

export default PostTaskComment;
