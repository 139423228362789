import React from 'react';
import { Heading, Text, Box } from 'rebass';
import { useTranslation } from 'react-i18next';

const StatsRow = ({ title, children, color, ...props }) => (
    <Box
        sx={{
            display: 'grid',
            gridTemplate: 'auto / repeat(2, auto)',
            borderTop: '1px solid #F5F7F8',
            py: 1,
        }}
    >
        <Text color="blueDark2">{title}</Text>
        <Box
            sx={{
                textAlign: 'right',
                fontWeight: 600,
                color: color ?? '#2C2960',
            }}
        >
            {children}
        </Box>
    </Box>
);

const StatisticTile = ({ children, name, growth, groupSx, stats, ...props }) => {
    const { t } = useTranslation();

    return (
        <Box variant="card.wrapper" width={1} sx={{ bg: 'white', m: 0 }} {...props}>
            <Box
                p={4}
                px="16px"
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'flex-end',
                }}
            >
                <Heading color="blueDark" fontWeight={600} fontSize="16px">
                    {name}
                </Heading>

                <Box mt={1}>{children}</Box>

                {growth?.chart?.period && (
                    <Text fontSize={12} mt={3} color="blueDark2">{`${t('Annual Growth')} (${
                        growth?.chart?.period
                    })`}</Text>
                )}
                {growth?.chart?.url && (
                    <img
                        alt=""
                        src={growth.chart.url}
                        width="100%"
                        height="auto"
                        style={{ pointerEvents: 'none', userSelect: 'none' }}
                    />
                )}

                <Box
                    sx={{
                        flex: 1,
                        mt: '18px',
                        fontSize: '12px',
                    }}
                >
                    {growth?.historical && (
                        <StatsRow title={`${t('Annual Growth')} (${growth?.historical?.period})`}>
                            {growth?.historical?.value}
                        </StatsRow>
                    )}
                    {growth?.forecast && (
                        <StatsRow title={`${t('Annual Growth')} (${growth?.forecast?.period})`}>
                            {growth?.forecast?.value}
                        </StatsRow>
                    )}
                    {Array.isArray(stats) && stats.map(({ value, ...stat }) => <StatsRow {...stat}>{value}</StatsRow>)}
                </Box>
            </Box>
        </Box>
    );
};

export default StatisticTile;
