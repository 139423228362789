import React, { useState, useEffect, useRef } from 'react';
import { setStages } from '@app/Store';
import { Box } from 'rebass';
import { connect } from 'react-redux';
import LoadingSpinner from '~/LoadingSpinner';
import { getReports, getCompanyStages } from '@app/Api';
import { Card } from '~/Common/Card';
import { Button } from '~/Common/Button';
import { useTranslation } from "react-i18next";
import RoadmapTable from '~/Dashboard/CompanyStages/RoadMap/RoadmapTable'
import axios from 'axios'
import {
    downloadReportFile,
    permit
} from '@util/Functions';
import ReactToPrint from 'react-to-print';
import { getBIRFilename } from '@util/Functions/Company';

const ArchivedReports = ({ user, company, stages, setStages }) => {
    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const roadmapRef = useRef();
    const { t } = useTranslation();
    const handleDownloadReport = (reportId, year) => downloadReportFile({ reportId, fileName: getBIRFilename(company, year) });

    useEffect(() => {
        setLoading(true);
        const subscription = company?.subscription

        if (company?.archived || (subscription && !subscription?.paid)) {
            Promise.all([
                getReports({
                    companyId: company?.id
                }),
                getCompanyStages({
                    companyId: company?.id,
                    onboardingStages: false
                })
            ]).then(axios.spread((...response) => {
                setReports(response[0]?.reports.filter(({ permissions }) => permit(permissions, 'report.download')));
                setStages(response[1]?.data);
            })).finally(() => setLoading(false));
        }
    }, [company, setStages]);

    return loading ? (
        <LoadingSpinner />
    ) : company ? (
        <>
            <Card title="Archived Reports">
                {Boolean(reports?.length) ? (
                    <Box>
                        {reports.map(({ id, year }) => (
                            <Box key={`download-report-${id}`}>
                                <Button
                                    variant="transparent"
                                    sx={{
                                        textAlign: 'left'
                                    }}
                                    onClick={() => handleDownloadReport(id, year)}
                                >
                                    Insights {t('reports.singular')} - {year}
                                </Button>
                            </Box>
                        ))}
                    </Box>
                ) : (
                    <>
                        {t('There were no generated reports')}
                    </>
                )}
            </Card>

            <Box>
                <ReactToPrint
                    trigger={() => (
                        <Button variant="primarySmall" ml={3}>
                            Print Roadmap
                        </Button>
                    )}
                    content={() => roadmapRef.current}
                />
            </Box>

            <RoadmapTable
                readOnly={true}
                roadmapRef={roadmapRef}
                company={company}
                stages={stages}
                updateStages={null}
            />
        </>

    ) : null;
};

export default connect(
    (state) => ({
        user: state.user,
        company: state.company,
        stages: state.stages
    }),
    { setStages }
)(ArchivedReports);
