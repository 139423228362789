
import React, { useState, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { monthListSmall, monthList, yearsList, getErrorMessage } from '@util/Functions';
import { Box, Button, Flex, Heading } from 'rebass'
import styles from '../Assets/styles.module.scss';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { getTaskStatus } from '@util/Enum';
import { taskStatusOptions } from '@util/Enum';
import { SelectField } from '~/Form';
import { toast } from 'react-toastify';
import { updateTaskRoardmap, updateTaskStatus } from '@app/Api';
import { Modal } from '~/Common/Modal';
import { Icon } from '@svg';
import { ProjectIcon } from '~/Common/ProjectIcon'
import RoadmapContainer from './RoadmapContainer'
import RoadmapExtend from './RoadmapExtend'
import RoadmapBar from './RoadmapBar'
import { updateProjectRoardmap } from '@app/Api/Tasks';

const RoadmapTable = ({
    readOnly = false,
    company,
    stages,
    updateStages,
    roadmapRef,
    tableRef,
    setTableRef,
}) => {

    const [editProjectId, setEditProjectId] = useState(null);
    const [taskDetails, setTaskDetails] = useState(null);
    const [taskStatus, setTaskStatus] = useState(null);
    const [changeStartDate, setChangeStartDate] = useState(null);
    const [errors, setErrors] = useState([]);

    const refs = useRef([])

    let companyId = company?.id;

    const roadmapMonthsRange = useMemo(() => {
        let minStartMonth = null;
        let maxEndMonth = null;

        Array.isArray(stages) && stages.forEach(({ steps }) =>
            steps.forEach(({ tasks }) =>
                tasks.forEach(({ startMonth, endMonth }) => {
                    if (startMonth && (!minStartMonth || minStartMonth > startMonth)) minStartMonth = startMonth;
                    if (endMonth && (!maxEndMonth || maxEndMonth < endMonth)) maxEndMonth = endMonth;
                })
            )
        )

        if (!maxEndMonth) return minStartMonth ? [minStartMonth] : [];
        return Array.from({ length: maxEndMonth - minStartMonth + 1 }, (_, i) => minStartMonth + i);
    }, [stages]);

    const getPriority = (priority, status) => {
        if (status === 3) return 'completedPriority';
        switch (priority) {
            case 1:
                return 'lowPriority';
            case 3:
                return 'highPriority';
            default:
                return 'mediumPriority';
        }
    };

    const formatDate = (monthYear) => `${monthListSmall[monthYear % 12]}${Math.floor(monthYear / 12)}`;

    const extendTaskDates = (taskId, direction, taskDate) => {
        let findTask = findTaskById(taskId);
        if (!findTask) return;
        let { id, stepName, taskName, priority, startMonth, endMonth } = { ...findTask };

        if (direction === 'left') startMonth = taskDate;
        else endMonth = taskDate;
        if (endMonth >= startMonth) editTaskDetails(id, stepName, taskName, priority, startMonth, endMonth, true);
    };

    const moveTaskDates = (taskId, dateDifference) => {
        let findTask = findTaskById(taskId);
        if (!findTask) return;
        let { id, stepName, taskName, priority, startMonth, endMonth } = { ...findTask };

        startMonth += dateDifference;
        endMonth += dateDifference;
        editTaskDetails(id, stepName, taskName, priority, startMonth, endMonth, true);
    };

    const findTaskById = (taskId) => {
        let findTask = null;

        Array.isArray(stages) && stages.every(stage => {
            const steps = stage?.steps

            Array.isArray(steps) && steps.every(step => {
                const tasks = step?.tasks

                if (Array.isArray(tasks)) {
                    findTask = tasks.find(({ id }) => id === taskId)
                }

                return !findTask
            })

            return !findTask
        })

        return findTask;
    };

    const editTaskDetails = (id, stepName, taskName, priority, startMonth, endMonth, save) => {
        if (readOnly) {
            return;
        }

        setErrors([]);
        if (updateStages !== undefined) {
            const data = {
                id,
                stepName,
                name: taskName,
                priority,
                startMonth: startMonth !== null ? startMonth % 12 : null,
                startYear: startMonth !== null ? Math.floor(startMonth / 12) : null,
                endMonth: endMonth !== null ? endMonth % 12 : null,
                endYear: endMonth !== null ? Math.floor(endMonth / 12) : null,
            };
            setEditProjectId(null);

            if (save) updateTask(data);
            else setTaskDetails(data);
        }
    };

    const isWithinRange = (monthYear, startMonth, endMonth) => {
        if (!startMonth) return false;
        if (!endMonth) return startMonth === monthYear;
        return monthYear <= endMonth && monthYear >= startMonth;
    };

    const updateProject = (updatedProjectDetails) => {
        if (readOnly) {
            return;
        }
        setErrors([]);
        updateProjectRoardmap({ ...updatedProjectDetails, companyId })
            .then(() => {
                updateStages();
                setChangeStartDate(null);
            })
            .catch(({ response }) => {
                const errorMessage = response?.data?.message || 'Unable to add data';
                toast.error(errorMessage);
                response?.data?.errors && setErrors(response.data.errors);
            })

    }

    const updateTask = (updatedTaskDetails) => {
        if (readOnly) {
            return;
        }
        setErrors([]);
        updateTaskRoardmap({ ...updatedTaskDetails })
            .then(() => {
                updateStages();
                setTaskDetails(null);
            })
            .catch(({ response }) => {
                const errorMessage = response?.data?.message || 'Unable to add comment';
                toast.error(errorMessage);
                response?.data?.errors && setErrors(response.data.errors);
            });
    };

    const updateStatus = () => {
        if (readOnly) {
            return;
        }
        if (taskStatus) {
            updateTaskStatus(taskStatus.id, taskStatus.status)
                .then(() => {
                    updateStages();
                    setEditProjectId(null);
                    setTaskStatus(null);
                    toast.success('Task status updated successfully');
                })
                .catch(({ response }) =>
                    toast.error(getErrorMessage(response?.data, 'Error while marking task as viewed'))
                );
        }
    };

    const roadmapLongDevided = (arr) => {
        const last3year = arr.length > 36 ? arr.slice(arr.length - 36, arr.length) : [...arr]
        const totalpage = Math.round(arr.length / 12)
        let finalroadmap = []

        for (let i = 0; i < totalpage; i++) {
            if (last3year.length > 0) {
                let splicedroadmap = last3year.splice(0, 36)
                finalroadmap.push(splicedroadmap)
            }
        }

        return finalroadmap.length === 0 ? [[]] : finalroadmap;

    }


    return Array.isArray(stages) && stages.length ? <>
        {taskStatus && (
            <Modal isOpen={true}>
                <Flex>
                    <Heading>Edit "{taskStatus.taskName}" status</Heading>
                    <Box ml="auto">
                        <Box variant="clickable" p={10} m="auto" pt={2} onClick={() => setTaskStatus(null)}>
                            <Icon icon="close" stroke="#656D78" title="Close Popup" />
                        </Box>
                    </Box>
                </Flex>

                <SelectField
                    value={taskStatus.status}
                    onChange={(status) => setTaskStatus({ ...taskStatus, ...{ status } })}
                    label="Select Status"
                    options={taskStatusOptions}
                    errors={errors['priority']}
                    nullable={true}
                />

                <Box width={1} mt={2} textAlign="right">
                    <Button variant="secondary" mr={2} onClick={() => setTaskStatus(null)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => updateStatus()}>
                        Update
                    </Button>
                </Box>
            </Modal>
        )}

        {changeStartDate && (
            <Modal isOpen={true}>
                <Flex>
                    <Heading>Change Project Start Date</Heading>
                    <Box ml="auto">
                        <Box variant="clickable" p={10} m="auto" pt={2} onClick={() => setChangeStartDate(null)}>
                            <Icon icon="close" stroke="#656D78" title="Close Popup" />
                        </Box>
                    </Box>
                </Flex>
                <Flex mt={2}>
                    <Box width={1 / 2} pr={2}>
                        <SelectField
                            value={changeStartDate.startMonth}
                            onChange={(startMonth) => setChangeStartDate({ ...changeStartDate, ...{ startMonth } })}
                            label="Start Month"
                            options={monthList.map((label, value) => ({ label, value }))}
                            errors={errors['startMonth']}
                            nullable={true}
                        />
                    </Box>
                    <Box width={1 / 2}>
                        <SelectField
                            value={changeStartDate.startYear}
                            options={yearsList(10, 7, false, true)}
                            onChange={(startYear) => setChangeStartDate({ ...changeStartDate, ...{ startYear } })}
                            label="Start Year"
                            errors={errors['startYear']}
                        />
                    </Box>
                </Flex>
                <Box width={1} mt={2} textAlign="right">
                    <Button variant="secondary" mr={2} onClick={() => setChangeStartDate(null)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => updateProject(changeStartDate)}>
                        Update
                    </Button>
                </Box>
            </Modal>
        )}

        {taskDetails && (
            <Modal isOpen={true}>
                <Flex>
                    <Heading>Edit "{taskDetails.name}" Timeline</Heading>
                    <Box ml="auto">
                        <Box variant="clickable" p={10} m="auto" pt={2} onClick={() => setTaskDetails(null)}>
                            <Icon icon="close" stroke="#656D78" title="Close Popup" />
                        </Box>
                    </Box>
                </Flex>
                <Box className={styles.selectPriorityContainer}>
                    <SelectField
                        value={taskDetails.priority}
                        onChange={(priority) => setTaskDetails({ ...taskDetails, ...{ priority } })}
                        label="Select Priority"
                        options={[
                            { label: 'Low Priority', value: 1 },
                            { label: 'Medium Priority', value: 2 },
                            { label: 'High Priority', value: 3 },
                        ]}
                        errors={errors['priority']}
                        nullable={true}
                    />
                </Box>
                <Flex mt={2}>
                    <Box width={1 / 2} pr={2}>
                        <SelectField
                            value={taskDetails.startMonth}
                            onChange={(startMonth) => setTaskDetails({ ...taskDetails, ...{ startMonth } })}
                            label="Start Month"
                            options={monthList.map((label, value) => ({ label, value }))}
                            errors={errors['startMonth']}
                            nullable={true}
                        />
                    </Box>
                    <Box width={1 / 2}>
                        <SelectField
                            value={taskDetails.startYear}
                            options={yearsList(10, 7, false, true)}
                            onChange={(startYear) => setTaskDetails({ ...taskDetails, ...{ startYear } })}
                            label="Start Year"
                            errors={errors['startYear']}
                        />
                    </Box>
                </Flex>
                <Flex mt={2}>
                    <Box width={1 / 2} pr={2}>
                        <SelectField
                            value={taskDetails.endMonth}
                            onChange={(endMonth) => setTaskDetails({ ...taskDetails, ...{ endMonth } })}
                            label="End Month"
                            options={monthList.map((label, value) => ({ label, value }))}
                            errors={errors['endMonth']}
                            nullable={true}
                        />
                    </Box>
                    <Box width={1 / 2}>
                        <SelectField
                            value={taskDetails.endYear}
                            options={yearsList(10, 7, false, true)}
                            onChange={(endYear) => setTaskDetails({ ...taskDetails, ...{ endYear } })}
                            label="End Year"
                            errors={errors['endYear']}
                        />
                    </Box>
                </Flex>
                <Box width={1} mt={2} textAlign="right">
                    <Button variant="secondary" mr={2} onClick={() => setTaskDetails(null)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => updateTask(taskDetails)}>
                        Update
                    </Button>
                </Box>
            </Modal>
        )}

        <Box variant="card.wrapper" p={3} mt={4} ref={roadmapRef} className={styles.printContainer}>
            {company && (
                <Box className={styles.printHeader}>
                    <h1>Capitaliz - Implementation Roadmap</h1>
                    <h2>{company.name}</h2>
                </Box>
            )}

            <Flex sx={{ justifyContent: 'space-between' }} mb={2}>
                <Flex fontSize="12px">
                    <Button
                        variant='primarySmall'
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '6px',
                            padding: '0px 17px'
                        }}
                        onClick={() => setChangeStartDate(true)}
                    >
                        <Icon icon='calendar' />
                        <p>
                            Change project start date
                        </p>
                    </Button>
                </Flex>
                <Flex textAlign="right" fontSize="12px" >
                    <Flex alignItems="center" ml="auto">
                        <Icon icon="dot" color="#F44545" />
                        <Box ml="1">High Priority</Box>
                    </Flex>
                    <Flex alignItems="center" ml={3}>
                        <Icon icon="dot" color="#EC9A1E" />
                        <Box ml="1">Medium Priority</Box>
                    </Flex>
                    <Flex alignItems="center" ml={3}>
                        <Icon icon="dot" color="#EBBF25" />
                        <Box ml="1">Low Priority</Box>
                    </Flex>
                    <Flex alignItems="center" ml={3}>
                        <Icon icon="dot" color="#19CEFF" />
                        <Box ml="1">Complete</Box>
                    </Flex>
                </Flex>
            </Flex>
            <Box className={styles.roadmapContainer} ref={tableRef}>
                <DndProvider backend={HTML5Backend}>
                    <table className={styles.roadmapTable}>
                        <thead>
                            <tr className={styles.stickyHeader}>
                                <th style={{ width: "20%" }}>Stage</th>
                                <th style={{ width: "30%" }}>Step</th>
                                <th style={{ width: "35%" }} className={styles.stickColTop}>Project</th>
                                {roadmapMonthsRange.map((monthYear, index) => (
                                    <th key={index}>
                                        <Box className={styles.roadmapTableDate}>
                                            {formatDate(monthYear)}
                                        </Box>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {stages.map(({
                                stageId,
                                totalTasks,
                                completedTasks,
                                name: stage,
                                steps
                            }) => steps.map(({
                                tasks,
                                step: stepName
                            }, stepIndex) => tasks.map(({
                                id,
                                name: taskName,
                                priority,
                                startMonth,
                                endMonth,
                                status
                            }, taskIndex) => {
                                const { sx: statusStyle, name: statusName } = getTaskStatus(status);
                                const showStage = stepIndex === 0 && taskIndex === 0
                                const showStep = taskIndex === 0

                                return <tr key={`${stageId}-${id}`}>
                                    {showStage && <td
                                        rowSpan={totalTasks}
                                        className={styles.colStageName}
                                    >
                                        <ProjectIcon id={stageId} />
                                        <Box fontWeight="bold">{stage}</Box>
                                        <Box mt="2" className={styles.taskBubble}>
                                            {completedTasks} / {totalTasks}
                                        </Box>
                                    </td>}
                                    {showStep && <td
                                        rowSpan={tasks.length}
                                        className={styles.colStepName}
                                    >
                                        {stepName}
                                    </td>}
                                    <th>
                                        <Flex alignItems="center">
                                            <Box mr="1">
                                                <Link
                                                    to={`/advisor/company/${company?.id}/stages/task/${id}`}
                                                >
                                                    {taskName}
                                                </Link>
                                            </Box>
                                            <Button sx={statusStyle} variant="roadmapTask">
                                                {statusName}
                                            </Button>
                                            {updateStages && (
                                                <Box className={styles.roardmapEditContainer}>
                                                    <Icon
                                                        icon="dots"
                                                        onClick={() =>
                                                            setEditProjectId(
                                                                editProjectId !== id ? id : null
                                                            )
                                                        }
                                                    />
                                                    {editProjectId === id && (
                                                        <Box className={styles.roadmapEditBox}>
                                                            <Box
                                                                onClick={() =>
                                                                    editTaskDetails(
                                                                        id,
                                                                        stepName,
                                                                        taskName,
                                                                        priority,
                                                                        startMonth,
                                                                        endMonth
                                                                    )
                                                                }
                                                            >
                                                                Edit Roadmap
                                                            </Box>
                                                            <Box>
                                                                <Link
                                                                    to={`/advisor/company/${company?.id}/stages/task/${id}`}
                                                                >
                                                                    View Project
                                                                </Link>
                                                            </Box>
                                                            <Box
                                                                onClick={() =>
                                                                    setTaskStatus({
                                                                        id,
                                                                        status,
                                                                        taskName,
                                                                    })
                                                                }
                                                            >
                                                                Update Status
                                                            </Box>
                                                        </Box>
                                                    )}
                                                </Box>
                                            )}
                                        </Flex>
                                    </th>
                                    {roadmapMonthsRange.map((monthYear, index) => (
                                        <RoadmapContainer
                                            date={monthYear}
                                            key={index}
                                            taskId={id}
                                            index={index}
                                        >
                                            {isWithinRange(monthYear, startMonth, endMonth) && (
                                                <RoadmapBar
                                                    priority={getPriority(priority, status)}
                                                    monthYear={monthYear}
                                                    taskId={id}
                                                    moveTaskDates={moveTaskDates}
                                                    key={id}
                                                    refs={refs}
                                                    index={index}
                                                    startMonth={startMonth}
                                                    endMonth={endMonth}
                                                >
                                                    {monthYear === startMonth && (
                                                        <RoadmapExtend
                                                            direction="left"
                                                            extendTaskDates={extendTaskDates}
                                                            taskId={id}
                                                            key={Math.random()}
                                                        />
                                                    )}
                                                    {monthYear === endMonth && (
                                                        <RoadmapExtend
                                                            direction="right"
                                                            extendTaskDates={extendTaskDates}
                                                            taskId={id}
                                                            key={Math.random()}
                                                        />
                                                    )}
                                                </RoadmapBar>
                                            )}
                                        </RoadmapContainer>
                                    ))}
                                </tr>
                            })))}
                        </tbody>
                    </table>
                    <div className={styles.printableTable} >
                        {roadmapLongDevided(roadmapMonthsRange).map((roadmap, index) =>
                            <table key={index} className={styles.roadmapTable} style={{ pageBreakAfter: "always" }}>
                                <thead>
                                    <tr className={styles.stickyHeader}>
                                        <th className={styles.fillTable}>Stage</th>
                                        <th className={styles.fillTable}>Step</th>
                                        <th className={styles.stickColTop}>Project</th>
                                        {roadmap.map((monthYear, index) => (
                                            <th key={index} className={styles.monthCols}>
                                                <Box className={styles.roadmapTableDate}>
                                                    {formatDate(monthYear).slice(0, 3) + " " + formatDate(monthYear).slice(3)}
                                                </Box>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {stages.map(({
                                        stageId,
                                        totalTasks,
                                        completedTasks,
                                        name: stage,
                                        steps
                                    }) => steps.map(({
                                        tasks,
                                        step: stepName
                                    }, stepIndex) => tasks.map(({
                                        id,
                                        name: taskName,
                                        priority,
                                        startMonth,
                                        endMonth,
                                        status
                                    }, taskIndex) => {
                                        const { sx: statusStyle, name: statusName } = getTaskStatus(status);
                                        const showStage = stepIndex === 0 && taskIndex === 0
                                        const showStep = taskIndex === 0

                                        return <tr key={`${stageId}-${id}`} className={styles.testFont}>
                                            {showStage && <td
                                                rowSpan={totalTasks}
                                                className={styles.colStageName}
                                            >
                                                <ProjectIcon id={stageId} />
                                                <Box fontWeight="bold">{stage}</Box>
                                                <Box mt="2" className={styles.taskBubble}>
                                                    {completedTasks} / {totalTasks}
                                                </Box>
                                            </td>}
                                            {showStep && <td
                                                rowSpan={tasks.length}
                                                className={styles.colStepName}
                                            >
                                                {stepName}
                                            </td>}
                                            <th>
                                                <Flex alignItems="center">
                                                    <Box mr="1">
                                                        <Link
                                                            to={`/advisor/company/${company?.id}/stages/task/${id}`}
                                                        >
                                                            {taskName}
                                                        </Link>
                                                    </Box>
                                                    <Button sx={statusStyle} variant="roadmapTask" className={styles.statusButton}>
                                                        {statusName}
                                                    </Button>
                                                    {updateStages && (
                                                        <Box className={styles.roardmapEditContainer}>
                                                            <Icon
                                                                icon="dots"
                                                                onClick={() =>
                                                                    setEditProjectId(
                                                                        editProjectId !== id ? id : null
                                                                    )
                                                                }
                                                            />
                                                            {editProjectId === id && (
                                                                <Box className={styles.roadmapEditBox}>
                                                                    <Box
                                                                        onClick={() =>
                                                                            editTaskDetails(
                                                                                id,
                                                                                stepName,
                                                                                taskName,
                                                                                priority,
                                                                                startMonth,
                                                                                endMonth
                                                                            )
                                                                        }
                                                                    >
                                                                        Edit Roadmap
                                                                    </Box>
                                                                    <Box>
                                                                        <Link
                                                                            to={`/advisor/company/${company?.id}/stages/task/${id}`}
                                                                        >
                                                                            View Project
                                                                        </Link>
                                                                    </Box>
                                                                    <Box
                                                                        onClick={() =>
                                                                            setTaskStatus({
                                                                                id,
                                                                                status,
                                                                                taskName,
                                                                            })
                                                                        }
                                                                    >
                                                                        Update Status
                                                                    </Box>
                                                                </Box>
                                                            )}
                                                        </Box>
                                                    )}
                                                </Flex>
                                            </th>
                                            {roadmap.map((monthYear, index) => (
                                                <RoadmapContainer
                                                    date={monthYear}
                                                    key={index}
                                                    taskId={id}
                                                    index={index}
                                                >
                                                    {isWithinRange(monthYear, startMonth, endMonth) && (
                                                        <RoadmapBar
                                                            priority={getPriority(priority, status)}
                                                            monthYear={monthYear}
                                                            taskId={id}
                                                            moveTaskDates={moveTaskDates}
                                                            key={Math.random()}
                                                        >
                                                            {monthYear === startMonth && (
                                                                <RoadmapExtend
                                                                    direction="left"
                                                                    extendTaskDates={extendTaskDates}
                                                                    taskId={id}
                                                                    key={id}
                                                                />
                                                            )}
                                                            {monthYear === endMonth && (
                                                                <RoadmapExtend
                                                                    direction="right"
                                                                    extendTaskDates={extendTaskDates}
                                                                    taskId={id}
                                                                    key={Math.random()}
                                                                />
                                                            )}
                                                        </RoadmapBar>
                                                    )}
                                                </RoadmapContainer>
                                            ))}
                                        </tr>
                                    })))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </DndProvider>

            </Box>
        </Box>
    </> : <></>
};

export default RoadmapTable;
