import React, { useState, useEffect } from 'react'
import LoadingSpinner from '~/LoadingSpinner'
import { getUserDetails } from '@app/Api'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { setCompany } from '@app/Store'
import SettingsRender from '~/Dashboard/Settings'
import { getErrorMessage } from '@util/Functions'

const Settings = ({ setCompany, companyId }) => {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        getUserDetails(companyId)
            .then(({ company }) => {
                setCompany(company)
                setLoading(false)
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to load settings data'), { toastId: 'getUserDetailsError' }))
    }, [setCompany, companyId])

    return loading ? <LoadingSpinner /> : <SettingsRender show={{ user: true, company: true }} />
}

export default connect(null, { setCompany })(Settings)
