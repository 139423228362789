import React, { useRef, useState, useEffect, useCallback, Fragment } from 'react';
import { Box, Button, Flex, Heading, Text, Link } from 'rebass';
import LoadingSpinner from '~/LoadingSpinner';
import { Icon } from '@svg';
import { NavLink, useHistory } from 'react-router-dom';
import Pagination from '../Pagination';
import { Skeleton } from '../Skeleton';

const isBottom = (root, offset) => root.scrollTop >= root.scrollHeight - root.clientHeight - offset;

const TableWrapper = ({ children, sx, ...props }) => {
    return (
        <Flex
            sx={{
                flexDirection: 'column',
                position: 'relative',
                bg: '#FFFFFF',
                boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.06)',
                fontSize: '14px',
                color: '#2C2960',
                pb: '8px',
                ...sx,
            }}
            {...props}
        >
            {children}
        </Flex>
    );
};

const TableGroup = ({ children, sx, minWidth, hasRows, ...props }) => {
    return (
        <Flex sx={{ background: '#f6f6f6', p: 2, flexDirection: 'column', gap: 2, mb: 2, borderRadius: '8px', ...sx }}>
            {children}
        </Flex>
    );
};

const TableBody = ({ children, sx, minWidth, hasRows, ...props }) => {
    return (
        <Box sx={{ overflowY: 'hidden', overflowX: 'auto', width: '100%' }}>
            <Flex
                sx={{
                    flexDirection: 'column',
                    minWidth: hasRows ? minWidth : 'none',
                    ...sx,
                }}
                {...props}
            >
                {children}
            </Flex>
        </Box>
    );
};

const CollapseColumn = ({ isSubrow, isHeader, isOpen, size = 48, sx, iconSize = 15, ...props }) => {
    return (
        <TableColumn
            column={{
                width: size,
                sx: {
                    p: 0,
                    cursor: 'pointer',
                    justifyContent: 'center',
                    height: size,
                    ...sx,
                },
            }}
            {...props}
        >
            {!isSubrow && !isHeader && (
                <Icon
                    icon="arrow"
                    height={iconSize}
                    sx={{
                        pointerEvents: 'none',
                        transform: `rotate(${isOpen ? 90 : 0}deg)`,
                        transition: 'transform 0.5s ease',
                    }}
                />
            )}
        </TableColumn>
    );
};

const TableColumn = ({ children, column = {}, sx, isSubrow, ...props }) => (
    <Box
        sx={{
            flexBasis: '100%',
            flexGrow: column.grow ?? 1,
            flexShrink: column.shrink ?? 1,
            minWidth: column.width ?? 'unset',
            maxWidth: column.width ?? 'unset',
            width: column.width ?? '100%',
            textAlign: column.align ?? 'left',
            justifyContent: column.align ?? 'left',
            py: isSubrow ? '4px' : '12.5px',
            px: '24px',
            display: 'flex',
            alignItems: 'center',
            ...sx,
            ...column.sx,
        }}
        {...props}
    >
        {children}
    </Box>
);

const TableRow = ({ borderRadius = '8px', children, sx, childRow, isSubrow, rowLink, ...props }) => {
    const rowRef = useRef(null);
    const history = useHistory();

    return (
        <Flex
            onClick={() => rowLink && history.push(rowLink)}
            ref={rowRef}
            sx={{
                borderRadius,
                background: isSubrow ? '#fafafa' : 'transparent',
                border: isSubrow ? '1px solid #efefef' : 'none',
                '&:not(.table--header)': {
                    transition: 'background 0.3s ease-in-out',
                    '&:hover': {
                        bg: '#ECFAFE',
                    },
                },
                ...(rowLink ? {
                    cursor: 'pointer'
                } : {}),
                ...sx,
            }}
            {...props}
        >
            {children}
        </Flex>
    );
};

export const Table = ({
    children,
    nested,
    showCollapse = true,
    groupHeader: GroupHeader,
    data: _data,
    skip,
    columns,
    columnSort,
    showHeader = true,
    isLoading,
    emptyTemplate = false,
    minWidth = '1000px',
    rowProps,
    infiniteScroll,
    infiniteScrollDelay = 200,
    infiniteScrollOffset = 20,
    boxSx,
    rowSx,
    wrapperSx,
    page,
    pagination,
    ...props
}) => {
    const data = typeof skip === 'function' ? _data.filter((value, index, array) => !skip(value, index, array)) : _data;
    const hasRows = Array.isArray(data) && data.length > 0;
    const [sortBy, setSortBy] = useState(false);
    const [sortOrder, setSortOrder] = useState('asc');
    const [isLoadingMore, setLoadingMore] = useState(false);
    const [hasLoadedAll, setLoadedAll] = useState(false);
    const [collapsed, setCollapsed] = useState([]);
    const rootElement = useRef(document.getElementById('root'));
    const scrollReady = useRef(true);

    const toggleCollapsed = (row) => {
        setCollapsed((c) => ({
            ...c,
            [row]: !c[row],
        }));
    };

    const handleColumnClick = (e, column) => {
        if (column.key === sortBy) {
            const order = sortOrder === 'asc' ? 'desc' : 'asc';
            setSortOrder(order);
            columnSort(column.key, order);
        } else {
            setSortBy(column.key);
            columnSort(column.key, sortOrder);
        }
    };

    const getRowProps = (row) => {
        var finalRowProps = { ...('function' === typeof rowProps ? rowProps(row) : rowProps) }

        finalRowProps = {
            ...finalRowProps,
            sx: {
                ...finalRowProps.sx,
                ...rowSx,
            }
        }

        if (finalRowProps?.to) {
            finalRowProps.sx = {
                ...finalRowProps.sx,
                '&:hover': {
                    ...finalRowProps.sx['&:hover'],
                    background: (t) => t.colors.secondaryLight,
                },
            };
        }

        return finalRowProps;
    };

    const handleScroll = useCallback(
        (e, force) => {
            if (typeof infiniteScroll !== 'function' || hasLoadedAll) {
                return;
            }
            if (isBottom(document.documentElement, infiniteScrollOffset) && (scrollReady.current || force)) {
                scrollReady.current = false;
                setLoadingMore(true);
                infiniteScroll().then((e) => {
                    if (Array.isArray(e) && !e.length) {
                        setLoadedAll(true);
                    }
                    setTimeout(() => (scrollReady.current = true), infiniteScrollDelay);
                    setLoadingMore(false);
                });
            }
        },
        [infiniteScroll, infiniteScrollDelay, infiniteScrollOffset, hasLoadedAll]
    );

    useEffect(() => {
        if (typeof infiniteScroll === 'function') {
            document.addEventListener('scroll', handleScroll);
            return () => document.removeEventListener('scroll', handleScroll);
        }
    }, [infiniteScroll, handleScroll]);

    useEffect(() => {
        // Load automatically if page is too short
        if (!isLoadingMore && !isLoading && isBottom(rootElement.current, infiniteScrollOffset)) {
            handleScroll(null, true);
        }
    }, [infiniteScrollOffset, isLoading, isLoadingMore, handleScroll]);

    useEffect(() => {
        if (page <= 1) {
            setLoadedAll(false);
        }
    }, [page]);

    const showLoading = isLoading && (!isLoadingMore || !hasRows);
    const hasChildren = nested;

    return (
        <TableWrapper sx={wrapperSx} {...props}>
            <TableBody hasRows={hasRows} minWidth={showLoading ? 0 : minWidth}>
                {showHeader && hasRows && !showLoading && (
                    <TableRow
                        className="table--header"
                        borderRadius={0}
                        sx={{
                            borderBottom: 'none',
                            bg: '#F5F7F8',
                            px: hasChildren ? 3 : '8px',
                        }}
                    >
                        {hasChildren && showCollapse && <CollapseColumn isHeader={true} />}
                        {columns.map((column) => {
                            const isSortable = columnSort && column.sortable !== false;
                            return (
                                <TableColumn
                                    key={column.key}
                                    column={column}
                                    sx={{
                                        fontSize: '12px',
                                        color: sortBy === column.key ? '#19CEFF' : '#9594AF',
                                        py: '10px',
                                    }}
                                    as={isSortable ? Link : Box}
                                    onClick={isSortable ? (e) => handleColumnClick(e, column) : undefined}
                                    className={sortBy === column.key ? `table--sort sort--${sortOrder}` : ''}
                                >
                                    {column.title}
                                    {isSortable && <Icon color="#C3C7D0" icon="sort" sx={{ ml: 2 }} />}
                                </TableColumn>
                            );
                        })}
                    </TableRow>
                )}
                <Flex flexDirection="column" sx={{ p: '8px', gap: 1, ...boxSx }}>
                    {!showLoading &&
                        (hasRows ? (
                            data.map((row, key) => {
                                const hasChildRows = nested && Array.isArray(row[nested]);
                                const Component = hasChildren
                                    ? (!GroupHeader
                                        ? TableGroup
                                        : Box
                                    ) : Fragment;

                                return row ? (
                                    <Component key={key}>
                                        {GroupHeader ? (
                                            <GroupHeader
                                                index={key}
                                                row={row}
                                                rowProps={getRowProps(row, false)}
                                                hasChildren={hasChildren}
                                                columns={columns}

                                                // Allow custom component to render default components
                                                components={{
                                                    CollapseColumn: (props) => hasChildren ? (
                                                        <CollapseColumn
                                                            isOpen={!collapsed[key]}
                                                            isSubrow={!hasChildRows}
                                                            onClick={() => toggleCollapsed(key)}
                                                            {...props}
                                                        />
                                                    ) : null,
                                                    TableColumns: ({ sx, ...props }) => columns.map((column, index) => (
                                                        <TableColumn
                                                            key={column.key}
                                                            column={column}
                                                            sx={{
                                                                ...sx,
                                                                pl: hasChildren && index === 0 ? '12px' : undefined,
                                                                pr: hasChildren && index === 0 ? '36px' : undefined,
                                                            }}
                                                            {...props}
                                                        >
                                                            {'function' === typeof column.render
                                                                ? column.render(row, false) ?? '-'
                                                                : row.hasOwnProperty(column.key)
                                                                    ? row[column.key]
                                                                    : '-'}
                                                        </TableColumn>
                                                    ))
                                                }}
                                            />
                                        ) : (
                                            <TableRow {...getRowProps(row, false)}>
                                                {hasChildren && showCollapse && (
                                                    <CollapseColumn
                                                        isOpen={!collapsed[key]}
                                                        isSubrow={!hasChildRows}
                                                        onClick={() => toggleCollapsed(key)}
                                                    />
                                                )}
                                                {columns.map((column, index) => (
                                                    <TableColumn
                                                        key={column.key}
                                                        column={column}
                                                        sx={{
                                                            pl: hasChildren && index === 0 ? '12px' : undefined,
                                                            pr: hasChildren && index === 0 ? '36px' : undefined,
                                                        }}
                                                    >
                                                        {'function' === typeof column.render
                                                            ? column.render(row, false) ?? '-'
                                                            : row.hasOwnProperty(column.key)
                                                                ? row[column.key]
                                                                : '-'}
                                                    </TableColumn>
                                                ))}
                                            </TableRow>
                                        )}
                                        {hasChildRows &&
                                            !collapsed[key] &&
                                            row[nested].map((subrow, subkey) => (
                                                <TableRow
                                                    key={`subrow${subkey}`}
                                                    isSubrow={!GroupHeader}
                                                    borderRadius={GroupHeader ? 0 : undefined}
                                                    {...getRowProps(subrow, true)}
                                                >
                                                    {showCollapse && <CollapseColumn isSubrow={true} />}
                                                    {columns.map((column) => (
                                                        <TableColumn key={column.key} column={column} isSubrow={!GroupHeader}>
                                                            {'function' === typeof column.render
                                                                ? column.render(subrow, true) ?? '-'
                                                                : subrow.hasOwnProperty(column.key)
                                                                    ? subrow[column.key]
                                                                    : '-'}
                                                        </TableColumn>
                                                    ))}
                                                </TableRow>
                                            ))}
                                    </Component>
                                ) : null;
                            })
                        ) : (
                            <Box px={2}>
                                {emptyTemplate && (
                                    <Flex
                                        sx={{
                                            py: 5,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        {emptyTemplate?.icon && (
                                            <Icon icon={emptyTemplate.icon} color="tealDark" mb={'28px'} width="84px" />
                                        )}
                                        {emptyTemplate?.heading && (
                                            <Heading variant="h2" sx={{ fontSize: '36px' }}>
                                                {emptyTemplate.heading}
                                            </Heading>
                                        )}
                                        {emptyTemplate?.content && (
                                            <Text mt={1} variant="subtext">
                                                {emptyTemplate.content}
                                            </Text>
                                        )}
                                        {emptyTemplate?.button && (
                                            <Button
                                                as={NavLink}
                                                to={emptyTemplate?.button?.to || '/'}
                                                sx={{
                                                    minWidth: '160px',
                                                    mt: '56px',
                                                }}
                                            >
                                                {emptyTemplate?.button?.title || 'Back to dashboard'}
                                            </Button>
                                        )}
                                    </Flex>
                                )}
                                {children}
                            </Box>
                        ))}
                </Flex>
                {(isLoading || isLoadingMore) && (
                    <Flex
                        sx={{
                            flexDirection: 'column',
                            bg: 'rgba(255, 255, 255, 0.5)',
                        }}
                    >
                        {!isLoadingMore ? (
                            <>
                                <Skeleton height="32px" width="100%" mb={3} mt="-14px" />

                                {(new Array(24).fill(null)).map((item, key) => (
                                    <Box px={3} key={key}>
                                        <Skeleton height="16px" width="100%" mb={3} />
                                    </Box>
                                ))}
                            </>
                        ) : (
                            <LoadingSpinner size={30} mt={2} />
                        )}
                    </Flex>
                )}
            </TableBody>
            {pagination && <Pagination
                pages={pagination?.pages}
                fetch={pagination?.fetch}
                sx={{ my: '8px' }}
            />}
        </TableWrapper>
    );
};
