import { createSlice } from '@reduxjs/toolkit';

const task = createSlice({
    name: 'task',
    initialState: null,
    reducers: {
        setTask: (state, { payload }) => payload
    },
});

export const { setTask } = task.actions;

export default task.reducer;
