import React, { useState } from 'react'
import axios from 'axios'
import { enableTwoFactor, confirmTwoFactor, getTwoFactorQrCode, getTwoFactorRecoveryCodes, refreshTwoFactorRecoveryCodes } from "@app/Api"
import { toast } from 'react-toastify'
import { formatDateFull } from '@util/Functions'
import { Card } from '~/Common/Card'
import { Flex, Box, Button, Link, Heading, Text } from 'rebass'
import { Icon } from '@svg'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Modal } from '~/Common/Modal';
import LoadingSpinner from '~/LoadingSpinner'
import { VerifyField } from '~/Form'

const ManageTwoFactor = ({ user }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [twoFactorEnabled, setTwoFactorEnabled] = useState(user?.two_factor_enabled);
    const [showTwoFactorExtras, setShowTwoFactorExtras] = useState(false);
    const [twoFactorQrCode, setTwoFactorQrCode] = useState(null);
    const [twoFactorRecoveryCodes, setTwoFactorRecoveryCodes] = useState([]);
    const [code, setCode] = useState('');

    const handleEnableTwoFactor = () => {
        setLoading(true);

        enableTwoFactor().then(() => {
            axios.all([
                getTwoFactorQrCode(),
                getTwoFactorRecoveryCodes()
            ]).then(axios.spread((qrResponse, recoveryResponse) => {
                setTwoFactorQrCode(qrResponse?.svg);
                setTwoFactorRecoveryCodes(recoveryResponse);
                setShowTwoFactorExtras(true);
                setTwoFactorEnabled('pending');
                setModalOpen(true)

                toast.success(t('settings.two_factor.pending.success'), {
                    toastId: 'two_factor-success',
                });
            }))
        }).catch(({ response }) => {
            toast.error(response?.data?.message || t('settings.two_factor.pending.error'), {
                toastId: 'two_factor-error',
            });
        }).finally(() => setLoading(false));
    };

    const handleConfirmTwoFactor = () => {
        setLoading(true);

        confirmTwoFactor({
            code: code,
            device_name: 'app'
        }).then(() => {
            setShowTwoFactorExtras(true);
            setTwoFactorEnabled(true);
            setModalOpen(false);

            toast.success(t('settings.two_factor.enabled.success'), {
                toastId: 'two_factor-success',
            });
        }).catch(({ response }) => {
            toast.error(response?.data?.message || t('settings.two_factor.enabled.error'), {
                toastId: 'two_factor-error',
            });
        }).finally(() => setLoading(false));
    };

    const handleRefreshRecoveryCodes = () => {
        setLoading(true);

        refreshTwoFactorRecoveryCodes().then(() => {
            getTwoFactorRecoveryCodes().then((response) => {
                setTwoFactorRecoveryCodes(response);
                setShowTwoFactorExtras(true);

                toast.success(t('settings.two_factor.recoveryCodes.success'), {
                    toastId: 'two_factor-success',
                });
            })
        }).catch(({ response }) => {
            toast.error(response?.data?.message || t('settings.two_factor.recoveryCodes.error'), {
                toastId: 'two_factor-error',
            });
        }).finally(() => setLoading(false));
    };

    const renderButton = () => {
        switch (twoFactorEnabled) {
            case 'pending':
                return (
                    <Button variant="bordered" onClick={(e) => setModalOpen(true)}>{t('settings.two_factor.pending.buttonText')}</Button>
                );

            case true:
                return (
                    <Button variant="light" onClick={(e) => handleRefreshRecoveryCodes()}>{t('settings.two_factor.enabled.regenerateButtonText')}</Button>
                );

            case false:
            default:
                return (
                    <Button variant="bordered" onClick={(e) => handleEnableTwoFactor()}>{t('settings.two_factor.disabled.buttonText')}</Button>
                );
        }
    };

    return loading ? <LoadingSpinner /> : <>
        <Modal flex={true} isOpen={modalOpen}>
            <Flex flexGrow="1" flexDirection="column" justifyContent="space-between">
                <Flex>
                    <Box ml="auto" mb={2}>
                        <Link as="a" href="#close" variant="clickable" onClick={() => setModalOpen(false)}>
                            <Icon icon="close" stroke="#656D78" title="Close Popup" />
                        </Link>
                    </Box>
                </Flex>
                <Flex flexDirection="column" flexGrow="1" alignItems="center" justifyContent="center">
                    <Heading textAlign="center" mb={2}>{t('settings.two_factor.modal.heading')}</Heading>
                    <Box textAlign="center" mb={4}>{t('settings.two_factor.modal.subheading')}</Box>

                    {twoFactorQrCode && <>
                        <Flex sx={{
                            mb: 4,
                            flexDirection: ['column', 'row'],
                            width: '90%',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Box sx={{
                                width: '130px',
                                height: '120px',
                                display: 'flex',
                                minWidth: '130px',
                                '& svg': {
                                    width: '120px',
                                    height: '120px'
                                }
                            }}>
                                <div dangerouslySetInnerHTML={{ __html: twoFactorQrCode }} />
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                maxWidth: '200px'
                            }}>
                                <Text as="p" variant="captionTitle" mb={1}>{t('QR Code')}</Text>
                                <Text as="p" variant="caption" color="dark">{t('settings.two_factor.instructions.qrCode')}</Text>
                            </Box>
                        </Flex>
                    </>}

                    <Box sx={{ width: '100%' }} mb={4}>
                        <VerifyField
                            name="code"
                            onChangeOverride={(val) => setCode(val)} />
                    </Box>

                    <Button variant="tertiary" onClick={() => handleConfirmTwoFactor()} mb={4}>{t('Confirm')}</Button>
                </Flex>
            </Flex>
        </Modal>

        <Card title="Manage Account Security">
            <Heading as="h3" variant="settings_heading" mb={3}>{t('settings.two_factor.heading')}</Heading>
            <Text as="p" variant="body_large" color="slate" mb={4}>
                {t('settings.two_factor.subheading')}
            </Text>
            <Box mb={4}>
                <Text as="p" sx={{ fontWeight: 500 }} mb={2}>{t(twoFactorEnabled ? 'settings.two_factor.enabled.heading' : 'settings.two_factor.disabled.heading')}</Text>
                {twoFactorEnabled && user?.two_factor_confirmed_at && (
                    <Text as="p" variant="caption" color="dark" mb={4}>Enabled at: {formatDateFull(user?.two_factor_confirmed_at)}</Text>
                )}
                <Text as="p" variant="caption" color="dark" mb={4}>
                    {t(twoFactorEnabled ? 'settings.two_factor.enabled.subheading' : 'settings.two_factor.disabled.subheading')}
                </Text>

                {showTwoFactorExtras && <Box mb={2}>
                    {twoFactorRecoveryCodes.length > 0 && <>
                        <Text as="p" variant="captionTitle" mb={1}>{t('Recovery Codes')}</Text>
                        <Text as="p" variant="caption" color="dark" mb={3}>{t('settings.two_factor.instructions.recoveryCodes')}</Text>
                        <Box mb={4}>
                            {twoFactorRecoveryCodes.map(recoveryCode => (
                                <Text as="div" key={`rc-${recoveryCode}`} variant="caption" color="dark">{recoveryCode}</Text>
                            ))}
                        </Box>
                    </>}
                </Box>}

                {renderButton()}
            </Box>
        </Card>
    </>
}

export default connect((state) => ({ user: state.user }))(ManageTwoFactor);
