import axios from 'axios'
import { toast } from 'react-toastify'

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(function (config) {
    const authToken = localStorage.getItem('authToken')
    if (authToken) config.headers.Authorization = 'Bearer ' + authToken
    return config
})

axios.interceptors.response.use((response) => response,
    error => {
        switch (error.response?.status) {
            case 401:
                toast.error('Sorry, your session has expired. Please try login again', {
                    toastId: 'session_expired'
                })
                if (window.location.pathname !== '/' && localStorage.getItem('authTokenScope') !== 'twoFactorRequired') {
                    window.location.href = '/';
                }
                return;
            case 429:
                toast.error('Too many attempts, please try again shortly.', {
                    toastId: 'too_many_attempts'
                })
                return Promise.resolve(error);
            default:
                return Promise.reject(error)
        }
    })

export const getCsrfCookie = () => axios.get(`${process.env.REACT_APP_API_BASE_URL}/sanctum/csrf-cookie`)
