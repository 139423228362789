import React from 'react';
import { Icon } from '@svg';
import { Shine } from '@svg/Squares';

export const ProjectIcon = ({ id, color = 'blueDark2' }) => {
    const icon =
        id === 1 ? 'identifyValue' :
        id === 2 ? 'protectValue' :
        id === 3 ? 'maximiseValue' :
        id === 4 ? 'extractValue' :
        id === 5 ? 'valuation' :
        id === 6 ? 'briefcase' :
        id === 7 ? 'currency' :
        id === 8 ? 'paper3' :
        id === 9 ? 'asic' :
        id === 10 ? 'notepad' :
        null;

    return icon !== null ? <Icon icon={icon} color={color} size="20px"/> : <Shine />;
};
