import React from 'react'
import { Box, Button, Flex, Heading } from 'rebass'
import { Icon } from '@svg'

const PopupStageDetails = ({ triggerClose, name, details }) =>
    <Box>
        <Flex>
            <Heading pb={4}>{name}</Heading>
            <Box ml='auto'>
                <Box variant='clickable' p={20} m='auto' onClick={triggerClose} pt={2}>
                    <Icon icon="close" stroke="#656D78" title="Close Popup" />
                </Box>
            </Box>
        </Flex>
        <Box pb={4}>{details}</Box>
        <Button variant='primary' onClick={triggerClose} width='100%'>Okay</Button>
    </Box>

export default PopupStageDetails
