import { getCompanyCustomVariables, setOnboardingCustomVariables } from '@app/Api';
import React, { useEffect, useState } from 'react'
import { SelectField, TextField } from '~/Form'
import { toast } from 'react-toastify';
import { getErrorMessage } from '@util/Functions';
import { Box, Button, Flex, Heading } from 'rebass';
import styles from "./styles.module.scss"
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '~/Common/Skeleton';

const NumberOfSharesInput = ({ company }) => {
  const [customVariables, setCustomVariables] = useState({});
  const [years, setYears] = useState();
  const [financialYear, setFinancialYear] = useState();
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation();

  useEffect(() => {
    if (company) {
      setLoading(true);
      getCompanyCustomVariables({ companyId: company?.id, year: financialYear, onboarding: true })
        .then(({ customVariables, year, years }) => {
          setFinancialYear(year);
          setYears(years)
          setCustomVariables(customVariables);
        })
        .catch(({ response }) =>
          toast.error(getErrorMessage(response?.data, 'Unable to load custom variables'), {
            toastId: "getCompanyCustomVariables",
          })
        )
        .finally(() => setLoading(false));
    }
  }, [company, financialYear]);

  const handleSubmit = () => {
    setLoading(true)
    setOnboardingCustomVariables({ companyId: company?.id, customVariables, year: financialYear })
      .then(() => {
        toast.success(t('Custom Variables uploaded successfully'));
      })
      .catch(({ response }) => {
        toast.error(getErrorMessage(response?.data, t('Unable to update company custom variables')), {
          toastId: "setOnboardingCustomVariables",
        });
      })
      .finally(() => setLoading(false));
  }

  const disabled = loading || !customVariables?.numberOfShares;

  return <Box variant="card.wrapper">
    {loading ? <Skeleton height="260px" /> : <>
      <Heading pt={20} px={30} pb={"10px"} >
        <Flex>
          <Box width={2 / 3}>
            Number of Shares Issued
          </Box>
          <Box width={1 / 3}>
            <SelectField
              name="year"
              value={financialYear}
              onChange={value => setFinancialYear(value)}
              options={years?.map((year) => ({ label: year, value: year }))}
              sx={{ padding: '10px 30px 10px 16px', minWidth: '150px', fontSize: 14, fontWeight: 600 }}
              boxSx={null}
            />
          </Box>
        </Flex>
      </Heading>
      <Box px={4} py={"15px"}>
        <TextField
          className={styles.hideInputNumber}
          type="number"
          label={t("Number of Shares Issued *")}
          value={customVariables?.numberOfShares || ""}
          onChange={(numberOfShares) => setCustomVariables(current => ({ ...current, numberOfShares }))}
          disabled={loading}
        />
      </Box>
      <Box textAlign="right" px={4} pb={4}>
        <Button disabled={disabled} onClick={handleSubmit} variant={disabled ? 'disabled' : 'primary'} ml="auto" width={100}>
          Save
        </Button>
      </Box>
    </>}
  </Box>
}

export default connect((state) => ({ company: state.company }))(NumberOfSharesInput);
