import React, { useState, useEffect } from 'react';
import { getErrorMessage, isAdvisor, isAnalyst } from '@util/Functions';
import { getIndustries, setAdvisorCompanyDetails, getCompanies, getSettings } from '@app/Api';
import { toast } from 'react-toastify';
import { getLanguages } from '@util/Enum';
import { Box, Button } from 'rebass';
import { Card } from '~/Common/Card';
import { TextField, SelectField, SearchableSelectField } from '~/Form';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setCompany, setCompanies } from '@app/Store';
import LoadingSpinner from '~/LoadingSpinner';
import { ImageUpload } from '~/Form/ImageUpload';
import { setCompanyLogo } from '@app/Api/Company';
import { Checkbox } from '~/Common/Checkbox';

const CompanySettings = ({ company, setCompany, setCompanies, settings, user }) => {
    const [companyIndustry, setCompanyIndustry] = useState('');
    const [companyIndustries, setCompanyIndustries] = useState([]);
    const [jobTypes, setJobTypes] = useState([]);
    const [errors, setErrors] = useState({});
    const [archivedChanged, setArchiveChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    const [entityOptions, setEntityOptions] = useState(null)
    const { t } = useTranslation();
    const [uploading, setUploading] = useState(false);
    const countryCode = company?.country_code;
    const industryId = company?.industryId;
    const emdash = String.fromCharCode(8212);
    const canEdit = isAnalyst(user) || isAdvisor(user);
    const archiveOptions = [
        { label: 'Live (monthly subscription applies)', value: false },
        { label: 'Archived', value: true },
    ];
    useEffect(() => {
        if (countryCode) {
            getIndustries({
                params: {
                    country_code: countryCode,
                },
            })
                .then((values) => {
                    const industryList = values.map(({ id, code, name }) => {
                        if (industryId === id) {
                            setCompanyIndustry({ value: id, label: `${code} - ${name}` });
                        }
                        return { value: id, label: `${code} - ${name}` };
                    });

                    setCompanyIndustries(industryList);
                })
                .catch(({ response }) =>
                    toast.error(getErrorMessage(response?.data, 'Unable to load industries data'), { toastId: 'getIndustriesError' })
                );
            getSettings().then((data) => {
                const mapEntity = data.entities[countryCode].map((e) => { return { value: e, label: e } })
                setEntityOptions(mapEntity)
                setJobTypes(data?.jobTypes);
            });
        }
    }, [countryCode, industryId]);

    const updateCompanies = () => {
        setLoading(true);
        getCompanies({})
            .then(({ companies }) => {
                setCompanies(companies);
                setLoading(false);
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to load company data'), { toastId: 'getCompaniesError' }));
    };

    const handleLogoSubmit = ({ file }) => {
        setUploading(true);
        setCompanyLogo(company.id, file)
            .then(({ message }) => {
                toast.success(message);
            })
            .catch(({ response }) => {
                toast.error(response.data.message ?? 'Unable to update the company logo');
            })
            .finally(() => setUploading(false));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setAdvisorCompanyDetails({ company })
            .then(() => {
                setErrors({});
                updateCompanies();
                toast.success('Settings updated successfully');

                // So we load the screen again if the archived status has changed
                if (archivedChanged) {
                    window.location.reload(false);
                }
            })
            .catch(({ response }) => {
                toast.error(getErrorMessage(response?.data, 'There was an error while trying to update your settings'));
                response?.data?.errors && setErrors(response.data.errors);
            });
    };

    const updateCompany = (field, modify) => (value) => {
        return setCompany({
            ...company,
            [field]: typeof modify === 'function' ? modify(value) : value,
        })
    }

    const country = settings?.countries?.[company?.country_code];

    const language = getLanguages.find((lang) => lang.value === company?.lang)?.label;

    return loading ? (
        <LoadingSpinner />
    ) : company ? (
        <Card title="Company Details">
            <Box as="form" onSubmit={handleSubmit}>
                {company && (
                    <Box>
                        {user?.permissions?.companies?.editDetails ? (
                            <>
                                {canEdit && (
                                    <Box sx={{
                                        borderBottom: '1px solid #6F7782',
                                        mb: 3,
                                        pb: 3,
                                    }}>
                                        <ImageUpload
                                            uploading={uploading}
                                            text={t('upload.logo.title')}
                                            description={t('upload.logo.subtitle')}
                                            value={company.logo_original}
                                            theme={company.logo_theme}
                                            onChange={handleLogoSubmit}
                                        />
                                        <Checkbox
                                            color="#6F7782"
                                            checked={company.logo_theme ?? 0}
                                            onChange={updateCompany('logo_theme', e => e.target.checked ? 1 : 0)}
                                            label={t('upload.logo.darkToggle')}
                                        />
                                    </Box>
                                )}

                                <TextField
                                    placeholder="Company Name/Trading Name"
                                    name="name"
                                    value={company.name}
                                    onChange={updateCompany('name')}
                                    label="Company Name"
                                    errors={errors['company.name']}
                                />

                                <TextField
                                    placeholder={t(
                                        `location.businessRegistrationNumber.${company.country_code}`,
                                        'Business Registration Number'
                                    )}
                                    name="abn"
                                    value={company.acn}
                                    onChange={updateCompany('acn')}
                                    label={t(
                                        `location.businessRegistrationNumber.${company.country_code}`,
                                        'Business Registration Number'
                                    )}
                                    errors={errors['company.acn']}
                                />

                                <TextField
                                    placeholder="Address"
                                    name="address"
                                    value={company.address}
                                    onChange={updateCompany('address')}
                                    label="Address"
                                    errors={errors['company.address']}
                                />

                                <TextField
                                    placeholder="Suburb"
                                    name="suburb"
                                    value={company.suburb}
                                    onChange={updateCompany('suburb')}
                                    label="Suburb"
                                    errors={errors['company.suburb']}
                                />

                                {settings.options.territories[company.country_code] && (
                                    <SelectField
                                        name="State"
                                        value={company.state}
                                        onChange={updateCompany('state')}
                                        label="State"
                                        errors={errors['company.state']}
                                        options={settings.options.territories[company.country_code]}
                                    />
                                )}

                                <TextField
                                    placeholder="Postcode"
                                    name="postcode"
                                    value={company.postcode}
                                    onChange={updateCompany('postcode')}
                                    label="Postcode"
                                    errors={errors['company.postcode']}
                                />

                                <SelectField
                                    name="Country"
                                    value={company.country_code}
                                    onChange={updateCompany('country_code')}
                                    label="Country"
                                    errors={errors['company.country_code']}
                                    options={settings.options.countries}
                                />

                                <SelectField
                                    name="Language"
                                    value={company.lang}
                                    onChange={updateCompany('lang')}
                                    label="Language"
                                    errors={errors['company.lang']}
                                    options={getLanguages}
                                />

                                <TextField
                                    placeholder="Phone"
                                    name="phone"
                                    value={company.phone}
                                    onChange={updateCompany('phone')}
                                    label="Phone"
                                    errors={errors['company.phone']}
                                />

                                <SearchableSelectField
                                    name="industry"
                                    value={companyIndustry}
                                    onChange={(val) => {
                                        setCompany({ ...company, ...{ industryId: val?.value } });
                                        setCompanyIndustry(val);
                                    }}
                                    label="Industry"
                                    errors={errors['company.industryId']}
                                    options={companyIndustries}
                                />

                                {/* <DateField
                                    wrapperClassName={styles.fullWidthDate}
                                    placeholder="Chart Date"
                                    name="chart_date"
                                    value={formatDate(company.chart_date, '', 'YYYY-MM-DD')}
                                    onChange={updateCompany('chart_date', v => formatDate(v, '', 'YYYY-MM-DD'))}
                                    label="Chart Date"
                                    errors={errors['company.chart_date']}
                                /> */}

                                {canEdit ? (
                                    <>
                                        <SelectField
                                            name="archived"
                                            value={company?.archived}
                                            onChange={() => {
                                                setCompany({
                                                    ...company,
                                                    ...{ archived: !company?.archived ? true : false },
                                                });
                                                setArchiveChanged(true);
                                            }}
                                            label={'Live or Archived Status'}
                                            errors={errors['company.archived']}
                                            options={archiveOptions}
                                        />

                                        <SelectField
                                            label="Select Type of Entity"
                                            placeholder="Select Entity"
                                            name="addCompanyEntity"
                                            value={company?.entity}
                                            onChange={updateCompany("entity")}
                                            options={entityOptions}
                                            errors={errors?.entity}
                                        />

                                        <SelectField
                                            label="Job Type"
                                            placeholder="Select Job"
                                            name="jobType"
                                            value={company?.jobType?.id}
                                            onChange={(val) => {
                                                const type = val ? jobTypes.find(type => parseInt(type.id) === parseInt(val)) : null
                                                setCompany({ ...company, ...{ jobType: type, job_type_id: val } });
                                            }}
                                            options={jobTypes.map(({ id, name }) => ({ value: id, label: name }))}
                                            errors={errors['company.job_type_id']}
                                        />

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            <Button variant="primary" textAlign="center" minWidth="120px">
                                                Save
                                            </Button>
                                        </Box>
                                    </>
                                ) : null}
                            </>
                        ) : (
                            <>
                                <ImageUpload
                                    uploading={uploading}
                                    text={t('upload.logo.title')}
                                    description={t('upload.logo.subtitle')}
                                    value={company.logo_original}
                                    onChange={handleLogoSubmit}
                                />
                                <Box mb={3}>
                                    <Box variant="subHeader">Company Name</Box>
                                    <Box>{company.name ? company.name : emdash}</Box>
                                </Box>
                                <Box mb={3}>
                                    <Box variant="subHeader">
                                        {t(
                                            `location.businessRegistrationNumber.${company.country_code}`,
                                            'Business Registration Number'
                                        )}
                                    </Box>
                                    <Box>{company.acn ? company.acn : emdash}</Box>
                                </Box>
                                <Box mb={3}>
                                    <Box variant="subHeader">Address</Box>
                                    <Box>{company.address ? company.address : emdash}</Box>
                                </Box>
                                <Box mb={3}>
                                    <Box variant="subHeader">Suburb</Box>
                                    <Box>{company.suburb ? company.suburb : emdash}</Box>
                                </Box>
                                <Box mb={3}>
                                    <Box variant="subHeader">State</Box>
                                    <Box>{company.state ? company.state : emdash}</Box>
                                </Box>
                                <Box mb={3}>
                                    <Box variant="subHeader">Postcode</Box>
                                    <Box>{company.postcode ? company.postcode : emdash}</Box>
                                </Box>
                                <Box mb={3}>
                                    <Box variant="subHeader">Country</Box>
                                    <Box>{country ? country : emdash}</Box>
                                </Box>
                                <Box mb={3}>
                                    <Box variant="subHeader">Language</Box>
                                    <Box>{language ? language : emdash}</Box>
                                </Box>
                                <Box mb={3}>
                                    <Box variant="subHeader">Phone</Box>
                                    <Box>{company.phone ? company.phone : emdash}</Box>
                                </Box>
                                <Box mb={3}>
                                    <Box variant="subHeader">Industry</Box>
                                    <Box>{companyIndustry.label ? companyIndustry.label : emdash}</Box>
                                </Box>
                                {/* <Box mb={3}>
                                    <Box variant="subHeader">Chart Date</Box>
                                    <Box>{company.chart_date ? formatDateMonth(company.chart_date) : emdash}</Box>
                                </Box> */}
                            </>
                        )}
                    </Box>
                )}
            </Box>
        </Card>
    ) : null;
};

export default connect(
    (state) => ({
        settings: state.settings,
        user: state.user,
        company: state.company,
    }),
    { setCompany, setCompanies }
)(CompanySettings);
