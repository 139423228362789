import React, { useState } from 'react';
import { Box, Button } from 'rebass';
import { TextField } from '~/Form';
import { login } from '@app/Api';
import CredentialsLayout from '../CredentialsLayout/CredentialsLayout';
import LoginTwoFactorChallenge from '../LoginTwoFactorChallenge';
import { updateUserStore } from '@util/Functions';
import { Redirect } from 'react-router-dom';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [step, setStep] = useState(1);
    const [twoFactorConfirmed, setTwoFactorConfirmed] = useState(true);
    const [admin, setAdmin] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        login({ email, password, device_name: 'app' })
            .then((data) => {
                if (localStorage.getItem('authTokenScope') === 'twoFactorRequired') {
                    setTwoFactorConfirmed(data?.twoFactorConfirmed)
                    setStep(2)
                    setIsSubmitting(false);
                } else {
                    updateUserStore();
                }
            })
            .catch(({ response }) => {
                setIsSubmitting(false);
                setAdmin(!!response?.data?.admin);
                response?.data?.errors && setErrors(response.data.errors);
            });
    };

    const back = (e) => {
        e.preventDefault();

        if (step > 1) {
            localStorage.removeItem('authTokenScope');
            localStorage.removeItem('authToken');
            setStep(step - 1);
        }
    };

    const content = () => {
        switch (step) {
            case 1:
                return (
                    <CredentialsLayout
                        heading="Login"
                        subheading="Please sign in by entering your details"
                        handleSubmit={handleSubmit}
                        footerButton={{ link: '/forgot-password', title: 'Forgot Password?' }}
                        isSubmitting={isSubmitting}
                        useSpinner={true}
                    >
                        <Box mb={2} sx={{ textAlign: 'left', fontWeight: 600 }}>
                            <TextField
                                value={email}
                                name="email"
                                type="email"
                                onChange={(val) => setEmail(val)}
                                label="Email*"
                                errors={errors.email}
                            />

                            <TextField
                                name="password"
                                value={password}
                                type="password"
                                onChange={(val) => setPassword(val)}
                                label="Password*"
                                errors={errors.password}
                            />
                        </Box>

                        <Button variant="primary" width="100%" sx={{ py: '10px' }}>
                            Login
                        </Button>
                    </CredentialsLayout>
                );
            case 2:
                return (
                    <LoginTwoFactorChallenge
                        twoFactorConfirmed={twoFactorConfirmed}
                        handleBack={back}
                    />
                );
            default:
                return <></>;
        }
    };

    return admin ? <Redirect to="/admin-error" /> : <>{content()}</>;
};

export default Login;
