import axios from 'axios';

export const getOrganisation = (orgId) => axios.get(`organisation/${orgId}`).then(({ data }) => data);

export const getOrganisationMembers = (orgId) => axios.get(`organisation/${orgId}/members`).then(({ data }) => data);

export const getOrganisationCompanies = (orgId, search) =>
    axios.get(`organisation/${orgId}/companies`, { params: { search } }).then(({ data }) => data);

export const addUser = (orgId, data = null) =>
    axios.post(`organisation/${orgId}/members`, data).then(({ data }) => data);

export const assignAdvisorToCompany = (orgId, data) =>
    axios.post(`organisation/${orgId}/advisor`, data).then(({ data }) => data);

export const unassignAdvisorFromCompany = (orgId, data) =>
    axios.delete(`organisation/${orgId}/advisor`, { params: data }).then(({ data }) => data);

export const assignSupervisorToCompany = (orgId, data) =>
    axios.post(`organisation/${orgId}/supervisor`, data).then(({ data }) => data);

export const unassignSupervisorFromCompany = (orgId, data) =>
    axios.delete(`organisation/${orgId}/supervisor`, { params: data }).then(({ data }) => data);

export const updateOrganisation = (orgId, data = null) =>
    axios.patch(`organisation/${orgId}`, data).then(({ data }) => data);

export const updateOrganisationPayment = (orgId, data = null) =>
    axios.post(`organisation/${orgId}/payment-method`, data).then(({ data }) => data);
