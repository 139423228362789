import React, { forwardRef, useState } from 'react';
import { Box } from 'rebass';
import { Label } from '@rebass/forms';
import { Icon } from '@svg';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import { dateFromString } from '@util/Functions';

const DateField = forwardRef(({ value = undefined, onChange, name, helpText = '', errors = null, label = null, disabled = false, boxSx = null, icon, iconColor = 'inherit', wrapperClassName = null, ...props }, ref) => {
    const [_value, setValue] = useState(dateFromString(value));

    return (
        <Box sx={boxSx}>
            {label && (
                <Label htmlFor={name} mb={1} flexDirection="column">
                    {label}{' '}
                    {helpText && (
                        <>
                            <br />
                            <small>{helpText}</small>
                        </>
                    )}
                </Label>
            )}
            <Box sx={{ position: 'relative' }}>
                <ReactDatePicker
                    portalId="root"
                    popperModifiers={[
                        {
                            name: 'arrow',
                            options: {
                                padding: ({ popper }) => ({
                                    right: popper.width - 32,
                                }),
                            },
                        },
                    ]}
                    id={name}
                    name={name}
                    ref={ref}
                    selected={_value}
                    showPopperArrow={false}
                    dateFormat="MMMM d, yyyy"
                    onChange={(date) => {
                        setValue(date);
                        if (typeof onChange === 'function') {
                            onChange(date, name);
                        }
                    }}
                    disabled={disabled}
                    {...props}
                    wrapperClassName={wrapperClassName}
                />
                {icon && (
                    <Box
                        sx={{
                            position: 'absolute',
                            right: '15px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            fontSize: '0px',
                            pointerEvents: 'none',
                        }}
                    >
                        <Icon color={iconColor} icon={icon} />
                    </Box>
                )}
            </Box>
            {errors && (
                <Box mt={-18} mb={20} color="red" fontSize={12}>
                    {errors[0]}
                </Box>
            )}
        </Box>
    );
});

export default DateField;
