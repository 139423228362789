import theme from "@app/theme"
import { Icon } from "@svg/Icon"
import React, { useEffect, useState } from "react"
import { Flex } from "rebass"

const Chevron = ({ condition, fetch, sx, iconSx }) => <Flex
    size={24}
    justifyContent={'center'}
    alignItems={'center'}
    sx={{
        cursor: condition ? 'pointer' : 'auto',
        ...sx
    }}
    onClick={() => condition && fetch()}
>
    <Icon
        icon="arrow"
        width={5}
        color={theme.colors.primary}
        sx={{
            opacity: condition ? 1 : 0.5,
            ...iconSx
        }}
    />
</Flex>

const Pagination = ({
    fetch,
    pages,
    sx
}) => {
    const [page, setPage] = useState(1)

    useEffect(() => {
        setPage(pages.current_page)
    }, [pages])

    const getNext = next => {
        setPage(next)
        fetch(next)
    }

    return <Flex justifyContent={'center'} alignItems={'center'} sx={sx}>
        <Chevron
            condition={page > 1}
            fetch={() => getNext(page - 1)}
            sx={{ mr: '6px' }}
            iconSx={{ transform: 'rotate(180deg)' }}
        />

        {[...Array(pages.last_page).keys()].map(paginate => {
            const currentPage = page === paginate + 1

            return <Flex
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                    gap: '2px',
                    size: 24,
                    borderRadius: '4px',
                    backgroundColor: currentPage ? theme.colors.primary : 'white',
                    cursor: currentPage ? 'auto' : 'pointer',
                    fontWeight: 500,
                    color: currentPage ? 'white' : theme.colors.primary
                }}
                key={paginate}
                onClick={() => getNext(paginate + 1)}
            >
                {paginate + 1}
            </Flex>
        }
        )}

        <Chevron
            condition={page < pages.last_page}
            fetch={() => getNext(page + 1)}
            sx={{ ml: '6px' }}
        />
    </Flex>
}

export default Pagination