import React, { useRef, useState, useEffect } from 'react';
import { defaultUserLogoLink, getErrorMessage, isAdvisor } from '@util/Functions';
import { getLanguages } from '@util/Enum';
import { Flex, Box, Button } from 'rebass';
import styles from '../styles.module.scss';
import { TextField, SelectField } from '~/Form';
import { Icon } from '@svg';
import { setUser } from '@app/Store';
import { connect } from 'react-redux';
import { setUserDetails, setUserAvatar, setAdvisorLogo } from '@app/Api';
import { toast } from 'react-toastify';
import { Card } from '~/Common/Card';
import { ImageUpload } from '~/Form/ImageUpload';
import { useTranslation } from 'react-i18next';

const UserSettings = ({ user, setUser }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [isUploading, setUploading] = useState(false);
    const avatarInput = useRef(null);
    const [errors, setErrors] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        setCurrentUser(user);
    }, [user]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setUserDetails({ user: currentUser })
            .then((userDetails) => {
                setErrors({});
                toast.success('Settings updated successfully');
                setUser({ ...user, ...userDetails });
            })
            .catch(({ response }) => {
                toast.error(getErrorMessage(response?.data, 'There was an error while trying to update your settings'));
                response?.data?.errors && setErrors(response.data.errors);
            });
    };

    const setAvatar = (avatar) => {
        if (avatar) {
            const formData = new FormData();
            formData.append('avatar', avatar);
            setUserAvatar(formData)
                .then(({ data }) => {
                    setErrors({});
                    setUser({ ...user, avatar: data?.avatar });
                    toast.success('Avatar updated successfully');
                })
                .catch(({ response }) => {
                    toast.error(
                        getErrorMessage(response?.data || 'There was an error while trying to update your avatar')
                    );
                    response?.data?.errors && setErrors(response.data.errors);
                });
        }
    };

    const handleLogoSubmit = ({ file }) => {
        if (file) {
            setUploading(true);
            const formData = new FormData();
            formData.append('logo', file);
            setAdvisorLogo(formData)
                .then(({ data }) => {
                    setErrors({});
                    setUser({ ...user, logo: data?.logo });
                    toast.success('Logo updated successfully');
                })
                .catch(({ response }) => {
                    toast.error(
                        getErrorMessage(response?.data || 'There was an error while trying to update your logo')
                    );
                    response?.data?.errors && setErrors(response.data.errors);
                })
                .finally(() => setUploading(false));
        }
    };

    return currentUser ? (
        <Card title="Your Details" as="form" onSubmit={handleSubmit}>
            <Flex pb={20}>
                <Box onClick={() => avatarInput.current.click()} minWidth="83px">
                    {user.avatar && (
                        <img
                            src={user.avatar || ''}
                            onError={(e) => (e.target.src = defaultUserLogoLink)}
                            alt="Upload"
                            className={styles.userAvatar}
                        />
                    )}
                    {!user.avatar && <Icon icon="uploadPhoto" title="Upload Photo" />}
                    <input
                        ref={avatarInput}
                        type="file"
                        name="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={(e) => setAvatar(e.target.files[0])}
                    />
                </Box>
                <Flex ml={2} p={3} justifyContent="flex-start" flexDirection="column">
                    <Box variant="attachmentLink" onClick={() => avatarInput.current.click()}>
                        {t('upload.photo.title')}
                    </Box>
                    <Box>{t('upload.photo.subtitle')}</Box>
                </Flex>
            </Flex>

            {errors.avatar && (
                <Box mt={-18} mb={20} color="red" fontSize={12}>
                    {errors.avatar[0]}
                </Box>
            )}

            {isAdvisor(user) && (
                <ImageUpload
                    uploading={isUploading}
                    text={t('upload.logo.title')}
                    description={t('upload.logo.subtitle')}
                    value={user.logo}
                    onChange={handleLogoSubmit}
                    errors={errors.logo}
                />
            )}

            <TextField
                placeholder="First Name"
                name="firstName"
                value={currentUser.firstName}
                onChange={(firstName) => setCurrentUser({ ...currentUser, ...{ firstName } })}
                label="First Name"
                errors={errors['user.firstName']}
            />

            <TextField
                placeholder="Surname"
                name="surname"
                value={currentUser.surname}
                onChange={(surname) => setCurrentUser({ ...currentUser, ...{ surname } })}
                label="Surname"
                errors={errors['user.surname']}
            />

            <TextField
                placeholder="Role"
                name="role"
                value={currentUser.role}
                onChange={(role) => setCurrentUser({ ...currentUser, ...{ role } })}
                label="Role"
                errors={errors['user.role']}
            />

            <TextField
                placeholder="Email"
                name="email"
                type="email"
                value={currentUser.email}
                onChange={(email) => setCurrentUser({ ...currentUser, ...{ email } })}
                label="Email"
                errors={errors['user.email']}
            />

            <TextField
                placeholder="Mobile"
                name="mobile"
                value={currentUser.phone}
                onChange={(phone) => setCurrentUser({ ...currentUser, ...{ phone } })}
                label="Mobile"
                errors={errors['user.phone']}
            />

            <SelectField
                name="Language"
                value={currentUser.lang}
                onChange={(lang) => setCurrentUser({ ...currentUser, ...{ lang } })}
                label="Language"
                errors={errors['user.lang']}
                options={getLanguages}
            />

            <Box textAlign="right">
                <Button variant="primary">Save</Button>
            </Box>
        </Card>
    ) : null;
};

export default connect((state) => ({ user: state.user }), { setUser })(UserSettings);
