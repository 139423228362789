import axios from 'axios'

export const getSetupIntent = user =>
    axios.get(`advisor/${user}/payments/setup/intent`)
        .then(({ data }) => data)

export const getSubscriptionPayment = user =>
    axios.get(`advisor/${user}/payments/setup/subscription`)
        .then(({ data }) => data)

export const getReportPayment = user =>
    axios.get(`advisor/${user}/payments/setup/report`)
        .then(({ data }) => data)

export const purchaseSubscription = (user, data) =>
    axios.post(`advisor/${user}/payments/subscription`, data)
        .then(({ data }) => data)

export const cancellationIntent = (company) =>
    axios.post(`advisor/company/${company}/subscription/cancel-intent`)
        .then(({ data }) => data)

export const cancelSubscription = (company) =>
    axios.post(`advisor/company/${company}/subscription/cancel`)
        .then(({ data }) => data)

export const pauseSubscription = (company) =>
    axios.post(`advisor/company/${company}/subscription/pause`)
        .then(({ data }) => data)

export const resumeSubscription = (company) =>
    axios.post(`advisor/company/${company}/subscription/resume`)
        .then(({ data }) => data)

export const activateSubscription = (company, data) =>
    axios.post(`advisor/company/${company}/subscription/activate`, data)
        .then(({ data }) => data)

export const refundCharge = (charge) =>
    axios.post(`${charge}/refund`)
        .then(({ data }) => data)

export const purchaseReport = (user, data) =>
    axios.post(`advisor/${user}/payments/report`, data)
        .then(({ data }) => data)

export const getStripeCustomerPortal = user =>
    axios.get(`advisor/${user}/payments/manage`)
        .then(({ data }) => data)
