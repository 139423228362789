import React, { useRef } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import { Box } from 'rebass'
import ImageUploader from "quill-image-uploader"
import 'react-quill/dist/quill.snow.css'
import { toast } from 'react-toastify'
import { getErrorMessage } from '@util/Functions'
import { wysiwygUploadFile, wysiwygUploadImage } from '@app/Api'
import ImageResize from "quill-image-resize-module-react"

Quill.register("modules/imageResize", ImageResize)
Quill.register("modules/imageUploader", ImageUploader)

const WysiwygField = ({ placeholder, value, setValue, fullEdit = false, companyId = null, extraClasses = '' }) => {

    const reactQuillRef = useRef(null)
    const fileInput = useRef(null)

    const handleImageUpload = async (file) => {
        if (file) {
            const bodyFormData = new FormData();
            bodyFormData.append("image", file);
            return await wysiwygUploadImage(bodyFormData)
                .then((url) => url)
                .catch(({ response }) =>
                    toast.error(getErrorMessage(response?.data, 'Unable to upload image')))
        }
    }

    const handleFileUpload = async (file) => {
        if (file) {
            const bodyFormData = new FormData();
            bodyFormData.append("file", file);
            bodyFormData.append("companyId", companyId);
            wysiwygUploadFile(bodyFormData)
                .then(({ fileName, url }) => {
                    if (url && fileName) {
                        const fullUrl = `${window.location.origin}/download/${url}/${fileName}`
                        const editor = reactQuillRef.current.getEditor()
                        editor.insertText(editor.getSelection(), fileName, 'link', fullUrl)
                    }
                })
                .catch(({ response }) =>
                    toast.error(getErrorMessage(response?.data, 'Unable to upload file')))
        }
    }

    const modules = React.useMemo(() => {
        const container = fullEdit ?
            [
                [{ header: [1, 2, 3, 4, 5, 6] }, { font: [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                companyId ? ['link', 'image', 'file'] : ['link', 'image'],
            ] : [
                ['bold', 'italic', 'underline', 'strike'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['link']
            ]
        return {
            toolbar: {
                container: container,
                handlers: {
                    file: () => fileInput.current.click()
                }
            },
            clipboard: {
                matchVisual: false,
            },
            imageUploader: {
                upload: handleImageUpload
            },
            imageResize: {
                handleStyles: {
                    displaySize: true,
                    backgroundColor: "black",
                    border: "none",
                    color: "white",
                },
                modules: ["Resize", "DisplaySize"],
            },
        }
    }, [fullEdit, companyId])

    return <Box className={`text-editor ${extraClasses}`}>
        <ReactQuill
            ref={reactQuillRef}
            theme="snow"
            value={value}
            onChange={setValue}
            placeholder={placeholder}
            modules={modules} />
        <input
            ref={fileInput}
            type="file"
            name="file"
            style={{ display: 'none' }}
            onChange={e => handleFileUpload(e.target.files[0])}
        />
    </Box>
}

export default WysiwygField