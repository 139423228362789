import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Flex, Box, Heading, Button } from 'rebass';
import { Modal } from '~/Common/Modal';
import { toast } from 'react-toastify';
import { isAdvisor, downloadTemplate, getErrorMessage } from '@util/Functions';
import { PopupStageDetails, TaskComments, PostTaskComment } from '~/Dashboard/TaskView';
import { Icon } from '@svg';
import { getTaskStatus, tasks } from '@util/Enum';
import { getTaskDetails, setTaskAsSeen, updateCustomTask, deleteCustomTask } from '@app/Api';
import LoadingSpinner from '~/LoadingSpinner';
import TaskStatusChange from '~/Dashboard/TaskStatusChange';
import { readTaskNotification } from '@app/Store';
import { BackButton } from '~/Common/BackButton';
import { isAnalyst, isConsultant } from '@util/Functions/User';
import { useTranslation } from "react-i18next";
import './styles.module.scss';
import AddbackTaskView from './Addback';
import BenchmarkingTaskView from './Benchmarking';
import ScorecardTaskView from './Scorecard';
import EffectiveTaskRateTaskView from './EffectiveTaxRate';
import { AttachmentPreview } from '~/Common/AttachmentPreview';
import { Form } from '~/Common/Form';
import { TextField, TextAreaField } from '~/Form';
import { useHistory } from 'react-router-dom';
import ActionItemTile from '@section/ActionItems/ActionItemTile';
import CreateActionItemModal from '~/Modals/CreateActionItemModal';
import { Confirm } from '~/Common/Confirm';
import NumberOfSharesInput from '~/Advisor/NumberOfSharesInput';

const TaskView = ({ companyTaskId, user, companies, readTaskNotification }) => {
    const [selectedActionItem, setSelectedActionItem] = useState(null);
    const [loading, setLoading] = useState(true);
    const [task, setTask] = useState({});
    const [messages, setMessages] = useState(null);
    const [actionItems, setActionItems] = useState(null);
    const [attachments, setAttachments] = useState(null);
    const [templates, setTemplates] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [company, setCompany] = useState({});
    const [showCustomTaskForm, setShowCustomTaskForm] = useState(false);
    const [confirmDeleteTask, setConfirmDeleteTask] = useState(false);
    const [errors, setErrors] = useState([]);
    const { t } = useTranslation();
    const [currentAttachment, setCurrentAttachment] = useState(null);
    const history = useHistory();
    const urlregex = /^(http|https):\/\//;

    const requestTaskDetails = useCallback(() => {
        getTaskDetails(companyTaskId)
            .then(({ data }) => {
                if (data?.details?.name) {
                    setTask(data?.details);
                    setMessages(data?.messages);
                    setActionItems(data?.actionItems);
                    setAttachments(data?.attachments);
                    setTemplates(data?.templates);
                    setLoading(false);
                    // Mark notifications relating to this task as seen
                    setTaskAsSeen(companyTaskId).then(() => readTaskNotification(companyTaskId));
                    const company = companies.find(({ id }) => data?.details.companyId === id);
                    if (company) {
                        setCompany(company);
                    }
                } else toast.error('Unable to load valid task data', { toastId: 'getTaskDetailsError' });
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to load task data'), { toastId: 'getTaskDetailsError' }))
            .finally(() => setModalOpen(false));
    }, [companies, companyTaskId, readTaskNotification]);

    useEffect(() => {
        setLoading(true);
        requestTaskDetails();
    }, [requestTaskDetails, companyTaskId, readTaskNotification, companies]);

    if (loading) return <LoadingSpinner />;

    const accreditedAdvisor = isAdvisor(user) || isAnalyst(user);
    const { sx: statusStyle, name: statusName } = getTaskStatus(task.status);
    const postTaskStatusUpdate = (status) => setTask({ ...task, ...{ status } });
    const backLabel = task?.isOnboarding ? 'Back to Onboarding' : 'Back to Projects'
    const backLink = accreditedAdvisor ? (task.isOnboarding ? `/advisor/company/${task.companyId}/onboarding` : `/advisor/company/${task.companyId}/stages`) :
        (isConsultant(user) ? `/company/projects/${task.companyId}` : `/company/projects`)

    const handleUpdateCustomTask = () => {
        setShowCustomTaskForm(true);
    };

    const handleDeleteCustomTask = () => {
        deleteCustomTask(companyTaskId).then(() => {
            toast.success('Task removed successfully', { toastId: 'taskSuccess' });
            history.push(backLink);
        }).catch(({ response }) => {
            const errorMessage = response?.data?.message || 'Unable to add task';
            toast.error(errorMessage, { toastId: 'taskError' });
            response?.data?.errors && setErrors(response.data.errors);
        });
    };

    const handleCustomTaskSubmit = (e) => {
        e.preventDefault();
        const data = {
            name: e.target.name.value,
            description: e.target.description.value,
        };

        setErrors([]);
        updateCustomTask(companyTaskId, data).then(() => {
            setShowCustomTaskForm(false);
            setTask((prev) => ({ ...prev, name: data.name }));
            setTask((prev) => ({ ...prev, description: data.description }));
            toast.success('Task updated successfully', { toastId: 'taskSuccess' });
        }).catch(({ response }) => {
            const errorMessage = response?.data?.message || 'Unable to add task';
            toast.error(errorMessage, { toastId: 'taskError' });
            response?.data?.errors && setErrors(response.data.errors);
        });
    };

    if (company.id) {
        if (task?.scorecardType) {
            return <ScorecardTaskView
                task={task}
                companyId={company.id}
                backLabel={backLabel}
                backLink={backLink}
                taskComponent={
                    <Box variant="card.wrapper" p={30}>
                        <Box
                            sx={{
                                letterSpacing: '0.5px',
                                textTransform: 'uppercase',
                                fontSize: '10px',
                                fontWeight: 500,
                                marginBottom: 2,
                            }}
                        >
                            {t(task.stageName)}
                        </Box>
                        <Box mb={3} fontSize={18} fontWeight={700}>
                            {t(task.name)}
                        </Box>
                        <Box mb={3}>
                            {accreditedAdvisor && (
                                <Flex alignItems="center">
                                    <Box mr={2}>Change Status</Box>
                                    <TaskStatusChange
                                        taskStatus={task.status}
                                        companyTaskId={task.id}
                                        notifyUpdated={postTaskStatusUpdate}
                                    />
                                </Flex>
                            )}
                            {!accreditedAdvisor && (
                                <Button variant="task" mb={3} width={100} p={2} sx={statusStyle}>
                                    {statusName}
                                </Button>
                            )}
                        </Box>
                        <Box
                            variant="clickable"
                            fontSize={12}
                            sx={{ color: '#6F7782' }}
                            onClick={() => setModalOpen(true)}
                        >
                            {t(task.description)}
                        </Box>
                    </Box>
                }
            />
        } else {
            switch (task.taskId) {
                case tasks.addbacks:
                    return <AddbackTaskView
                        companyTaskId={companyTaskId}
                        companyId={company.id}
                        user={user}
                        accreditedAdvisor={accreditedAdvisor}
                        backLabel={backLabel}
                        backLink={backLink}
                        templates={templates}
                    />

                case tasks.benchmarking:
                    return <BenchmarkingTaskView
                        initTask={task}
                        companyId={company.id}
                        user={user}
                        accreditedAdvisor={accreditedAdvisor}
                        backLabel={backLabel}
                        backLink={backLink}
                        templates={templates}
                    />

                case tasks.effectiveTaxRate:
                    return <EffectiveTaskRateTaskView
                        initTask={task}
                        accreditedAdvisor={accreditedAdvisor}
                        backLink={backLink}
                        backLabel={backLabel}
                        companyId={company.id}
                    />

                default:
                    break;
            }
        }
    }

    return (
        <Box mt={4}>
            <BackButton to={backLink} label={backLabel} pl={3} />
            <CreateActionItemModal
                companyId={company.id}
                isOpen={!!selectedActionItem}
                selectedItem={selectedActionItem}
                requestClose={() => setSelectedActionItem(null)}
                onEditItem={() => requestTaskDetails()}
            />
            <Modal isOpen={modalOpen}>
                <PopupStageDetails
                    triggerClose={() => setModalOpen(false)}
                    details={t(task.description)}
                    name={t(task.stageName)}
                />
            </Modal>

            <AttachmentPreview attachment={currentAttachment} close={() => setCurrentAttachment(null)} />

            <Confirm
                isOpen={confirmDeleteTask}
                title={t(Boolean(messages?.length) ? 'Unable to delete tasks that already contain comments or attachments' : 'Are you sure you would like to delete this task?')}
                requestClose={() => setConfirmDeleteTask(false)}
                onConfirm={handleDeleteCustomTask}
                isWarning={true}
                confirmText={t('Delete Task')}
            />

            {showCustomTaskForm ? (
                <Modal isOpen={true}>
                    <Form onSubmit={handleCustomTaskSubmit}>
                        <Flex>
                            <Heading>Edit task</Heading>
                            <Box ml="auto">
                                <Box variant="clickable" p={10} m="auto" pt={2} onClick={() => setShowCustomTaskForm(false)}>
                                    <Icon icon="close" stroke="#656D78" title="Close Popup" />
                                </Box>
                            </Box>
                        </Flex>

                        <TextField
                            name="name"
                            label="Name"
                            defaultValue={task?.name}
                            errors={errors?.name}
                        />

                        <TextAreaField
                            label="Description"
                            placeholder="Please include a short description about this task"
                            name="description"
                            defaultValue={task?.description}
                            errors={errors?.description}
                        />

                        <Box width={1} mt={2} textAlign="right">
                            <Button variant="secondary" type="button" mr={2} onClick={() => setShowCustomTaskForm(false)}>
                                Cancel
                            </Button>
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </Box>
                    </Form>
                </Modal>
            ) : null}

            <Flex width={1}>
                <Box width={1 / 3}>
                    <Box variant="card.wrapper" p={30}>
                        <Box
                            sx={{
                                letterSpacing: '0.5px',
                                textTransform: 'uppercase',
                                fontSize: '10px',
                                fontWeight: 500,
                                marginBottom: 2,
                            }}
                        >
                            {t(task.stageName)}
                        </Box>
                        <Box mb={3} fontSize={18} fontWeight={700}>
                            {t(task.name)}
                        </Box>

                        {task.url && (
                            <Box mb={3} fontSize={14} fontWeight={400}>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={
                                        urlregex.test(task.url)
                                            ? task.url
                                            : `//${task.url}`
                                    }
                                    style={{
                                        textDecoration: "underline",
                                        color: "#00AEEF",
                                    }}
                                >
                                    Link to training module
                                </a>
                            </Box>
                        )}

                        <Box mb={3}>
                            {accreditedAdvisor && task?.permissions?.updateStatus ?
                                <Flex alignItems="center">
                                    <Box mr={2}>Change Status</Box>
                                    <TaskStatusChange
                                        taskStatus={task.status}
                                        companyTaskId={task.id}
                                        notifyUpdated={postTaskStatusUpdate}
                                    />
                                </Flex> :
                                <Button variant="task" mb={3} width={100} p={2} sx={statusStyle}>
                                    {statusName}
                                </Button>
                            }
                        </Box>
                        <Box
                            variant="clickable"
                            fontSize={12}
                            sx={{ color: '#6F7782' }}
                            onClick={() => setModalOpen(true)}
                        >
                            {t(task.description)}
                        </Box>
                        {accreditedAdvisor && task.custom ? (
                            <Box mt={4}>
                                <Button variant="primary" mr={2} onClick={() => handleUpdateCustomTask()}>
                                    Edit Task
                                </Button>

                                <Button variant="red" onClick={() => setConfirmDeleteTask(true)}>
                                    Delete Task
                                </Button>
                            </Box>
                        ) : null}
                    </Box>

                    {Array.isArray(actionItems) && actionItems.length > 0 && <Box variant="card.wrapper">
                        <Box variant="card.header">
                            <Flex>
                                <Heading>Action Items</Heading>
                            </Flex>
                        </Box>
                        <Box variant="card.container">
                            {actionItems?.map((item) => (
                                <ActionItemTile key={`actionItem${item.id}`} actionItem={item} showProject={false} onEditItem={() => setSelectedActionItem(item)} />
                            ))}
                        </Box>
                    </Box>}

                    {Array.isArray(attachments) && attachments.length > 0 && <Box variant="card.wrapper">
                        <Box variant="card.header">
                            <Flex>
                                <Heading>Asset Attachments</Heading>
                                <Box ml={'auto'}>
                                    <Icon icon="download" title="Download Attachment" stroke="#00AEEF" />
                                </Box>
                            </Flex>
                        </Box>
                        <Box variant="card.container">
                            {attachments?.map((att) => (
                                <Box pb={3} key={`assetAttachments${att.id}`}>
                                    <Box variant="attachmentLink" fontSize={12} onClick={() => setCurrentAttachment(att)}>
                                        {att.fileName}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>}

                    {Array.isArray(templates) && templates.length > 0 && <Box variant="card.wrapper">
                        <Box variant="card.header">
                            <Flex>
                                <Heading>Useful Templates</Heading>
                                <Box ml={'auto'}>
                                    <Icon icon="download" title="Download Templates" stroke="#00AEEF" />
                                </Box>
                            </Flex>
                        </Box>
                        <Box variant="card.container">
                            {templates?.map((att) => (
                                <Box pb={3} key={`templates${att.id}`}>
                                    <Box variant="attachmentLink" fontSize={12} onClick={() => downloadTemplate(att)}>
                                        {att.fileName}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>}
                </Box>
                <Box width={2 / 3}>
                    <Box variant="card.wrapper">
                        <Heading py={20} px={30}>
                            Message {accreditedAdvisor ? company.name : 'advisor'}
                        </Heading>
                        <PostTaskComment
                            companyTaskId={companyTaskId}
                            user={user}
                            updateMessages={setMessages}
                            updateAttachments={setAttachments}
                        />
                        <Box p={4}>
                            <TaskComments
                                messages={messages}
                                attachments={attachments}
                                isAdvisor={accreditedAdvisor}
                                updateMessages={(messages) => setMessages(messages)}
                                updateAttachments={(attachments) => setAttachments(attachments)}
                            />
                        </Box>
                    </Box>
                    {isAdvisor(user) && task?.taskId === tasks?.generalInformation && <NumberOfSharesInput companyId={company.id} />}
                </Box>
            </Flex>
        </Box>
    );
};

export default connect((state) => ({ user: state.user, companies: state.companies }), { readTaskNotification })(
    TaskView
);
