import { theme } from '@app/theme';

export const taskStatuses = [
    { name: 'TO BE STARTED', sx: theme.taskStatus.toBeStarted },
    { name: 'PROGRESS', sx: theme.taskStatus.incomplete },
    { name: 'IN REVIEW', sx: theme.taskStatus.inReview },
    { name: 'COMPLETE', sx: theme.taskStatus.complete },
    { name: 'NOT GIVEN', sx: theme.taskStatus.notGiven },
];

export const taskStatusOptions = [
    { label: 'To Be Started', value: 0 },
    { label: 'Progress', value: 1 },
    { label: 'In Review', value: 2 },
    { label: 'Complete', value: 3 },
    { label: 'Not Given', value: 4 },
];

export const getTaskStatus = (status, daysOverdue) => {
    if (daysOverdue) {
        return {
            name: `${daysOverdue} DAYS OVERDUE`,
            sx: theme.taskStatus.overdue,
        };
    }
    return taskStatuses[status] || taskStatuses[0];
};

export const getTaskStatusColor = (status) => taskStatuses[status]?.sx.backgroundColor || '#BDC5CD';

export const isTaskStatusCompleted = (status) => status === 3;

export const taskStatusCode = (textStatus) => {
    switch (textStatus) {
        case 'completed':
            return 3;
        case 'in-review':
            return 2;
        case 'incomplete':
            return 1;
        default:
            return 0;
    }
};
