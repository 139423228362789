import React, { useEffect, useState } from 'react';
import { Box, Flex } from 'rebass';
import { toast } from 'react-toastify';
import { currentFinancialYear, defaultCompanyLogoLink, formatDateMonthYear, getErrorMessage, isAdvisor, yearsList } from '@util/Functions';
import LoadingSpinner from '~/LoadingSpinner';
import { Icon } from '@svg';
import { Link, useHistory } from 'react-router-dom';
import { PageTitle } from '~/Common/PageTitle';
import { theme } from '@app/theme';
import { createValuation, listValuations } from '@app/Api';
import { connect } from 'react-redux';
import { isAnalyst } from '@util/Functions/User';
import { useTranslation } from "react-i18next";
import { Card } from '~/Common/Card';
import { SelectField } from '~/Form';
import { Button } from '~/Common/Button';
import UserAvatar from '~/Dashboard/Avatar';

const Valuations = ({ companyId = null, user }) => {
    const [loading, setLoading] = useState(false);
    const [valuations, setValuations] = useState([]);
    const [errors, setErrors] = useState(null);
    const [reportYear, setReportYear] = useState(currentFinancialYear);
    const { t } = useTranslation();

    const history = useHistory();

    const advisor = isAdvisor(user) || isAnalyst(user);

    useEffect(() => {
        setLoading(true);
        listValuations({ companyId })
            .then((valuations) => {
                setValuations(valuations);
                setLoading(false);
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to load valuations')));
    }, [companyId]);

    if (loading) return <LoadingSpinner />;

    const handleCreateValuation = () => {
        setLoading(true);

        if (companyId) {
            createValuation({ companyId, year: reportYear })
                .then((valuationId) => {
                    history.push(`/advisor/company/${companyId}/valuations/edit/${valuationId}`);
                })
                .catch(({ response }) => {
                    if (response.data.status) {
                        setErrors(response.data.status);
                    }
                    setLoading(false);
                    toast.error(getErrorMessage(response?.data, 'Unable to create valuation'));
                });
        }
    };

    const isValuationSubmitted = (status) => status < 2;

    const linkStyle = { color: '#19ceff', marginLeft: 5, textDecoration: 'underline' };

    return (
        <Box>
            <Box px={3}>
                <PageTitle
                    subtitle={t('valuations.subtitle')}
                >
                    {advisor && (
                        <Flex ml="auto" alignItems="center">
                            <SelectField
                                name="year"
                                value={reportYear}
                                onChange={val => setReportYear(val)}
                                options={yearsList()}
                                sx={{ padding: '10px 30px 10px 16px', minWidth: '200px', fontSize: 14, fontWeight: 600 }}
                                boxSx={null}
                            />
                            <Button variant="primary" onClick={handleCreateValuation} sx={{ ml: 3, py: 2, lineHeight: '27px' }}>
                                <Box pr={1}>Create Valuation</Box>
                                <Icon
                                    icon="addCircle"
                                    stroke={theme.colors.primary}
                                    title="Add Valuation"
                                    width={21}
                                    height={21}
                                />
                            </Button>
                        </Flex>
                    )}
                </PageTitle>
            </Box>

            {errors && (
                <Card p={20}>
                    <Box mb={1}>
                        You are unable to create a Valuation for this Financial Year. Please complete the missing
                        information:
                    </Box>
                    <Box mt={2}>
                        <Flex alignItems="center" mb={1}>
                            {errors.financials ? (
                                <Icon icon="completedNo" size="18" />
                            ) : (
                                <Icon icon="completedYes" size="18" />
                            )}
                            <Box fontSize={1} fontWeight={600} ml={1}>
                                Financials History
                                {errors.financials ? (
                                    <Link to={`/advisor/company/${companyId}/financial-reports`} style={linkStyle}>
                                        edit
                                    </Link>
                                ) : (
                                    ''
                                )}
                            </Box>
                        </Flex>
                        <Flex alignItems="center" mb={1}>
                            {errors.customVariables ? (
                                <Icon icon="completedNo" size="18" />
                            ) : (
                                <Icon icon="completedYes" size="18" />
                            )}
                            <Box fontSize={1} fontWeight={600} ml={1}>
                                Custom Variables
                                {errors.customVariables ? (
                                    <Link to={`/advisor/company/${companyId}/custom-variables`} style={linkStyle}>
                                        edit
                                    </Link>
                                ) : (
                                    ''
                                )}
                            </Box>
                        </Flex>
                        <Flex alignItems="center" mb={1}>
                            {errors.scorecard ? (
                                <Icon icon="completedNo" size="18" />
                            ) : (
                                <Icon icon="completedYes" size="18" />
                            )}
                            <Box fontSize={1} fontWeight={600} ml={1}>
                                Scorecards
                                {errors.scorecard ? (
                                    <Link to={`/advisor/company/${companyId}/scorecards`} style={linkStyle}>
                                        edit
                                    </Link>
                                ) : (
                                    ''
                                )}
                            </Box>
                        </Flex>
                    </Box>
                </Card>
            )}

            {valuations?.length > 0 && (
                <Flex sx={{ flexWrap: 'wrap' }}>
                    {valuations.map(({ id, year, logo, updated, status }) => (
                        <Box width={[1 / 1, '', '', 1 / 2, '', 1 / 3, 1 / 4]} key={id}>
                            <Box
                                variant="card.wrapper"
                                p={20}
                                key={id}
                                sx={{ bg: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                            >
                                <Flex mb={3}>
                                    <UserAvatar
                                        logo={logo || defaultCompanyLogoLink}
                                        size={20}
                                        mr={2}
                                        flexShrink={0}
                                    />
                                    <Box fontWeight={600}>Valuation - {year}</Box>
                                </Flex>
                                <Box>
                                    <Box as="p" fontSize={12} mb={3}>
                                        {t('valuations.cardTitle')}
                                    </Box>

                                    <hr color="#19CEFF" />

                                    <Flex mt={3}>
                                        <Box fontSize={12} mr={3} fontWeight="600">
                                            Last Edit:
                                        </Box>
                                        <Box fontSize={12} color="#6F7782" ml="auto">
                                            {formatDateMonthYear(updated, 'N/A')}
                                        </Box>
                                    </Flex>

                                    <Link
                                        to={
                                            companyId
                                                ? `/advisor/company/${companyId}/valuations/edit/${id}`
                                                : `/company/valuations/${id}`
                                        }
                                    >
                                        <Button mt={3} variant="gray" width={1} fontSize={12}>
                                            {advisor && isValuationSubmitted(status) ? 'Edit' : 'View'} Valuation
                                        </Button>
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Flex>
            )}
            {!valuations?.length && (
                <Card p={20}>
                    There are no valuations available
                </Card>
            )}
        </Box>
    );
};

export default connect((state) => ({ user: state.user }))(Valuations);
