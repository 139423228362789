import axios from 'axios';

export const getCompany = (companyId) => axios.get(`advisor/company/${companyId}`).then(({ data }) => data);

export const getValuationCompany = (valuationId) =>
    axios.get(`advisor/valuation-company/${valuationId}`).then(({ data }) => data);

export const setAnalyst = (companyId, user) =>
    axios.post(`advisor/company/${companyId}/analyst`, { user }).then(({ data }) => data);

export const setChiefAnalyst = (companyId, user) =>
    axios.post(`advisor/company/${companyId}/chief-analyst`, { user }).then(({ data }) => data);

export const setCompanyLogo = (companyId, file) => {
    const formData = new FormData();
    formData.append('logo', file);
    return axios.post(`company/${companyId}/logo`, formData).then(({ data }) => data);
};

export const getCharges = (companyId) => axios.get(`advisor/company/${companyId}/charges`).then(({ data }) => data);

export const getActionItems = (companyId) => axios.get(`company/${companyId}/action-items`).then(({ data }) => data);

export const getActionItemsDashboard = (companyId, params) => axios.get(`company/${companyId}/action-items/dashboard`, { params: params })
    .then(({ data }) => data);

export const getAllActionItems = () => axios.get(`company/action-items`).then(({ data }) => data);

export const createActionItem = (companyId, params) => axios.post(`company/${companyId}/action-items`, params).then(({ data }) => data);

export const updateActionItem = (companyId, actionItemId, params) => axios.patch(`company/${companyId}/action-items/${actionItemId}`, params).then(({ data }) => data);

export const deleteActionItem = (companyId, actionItemId) => axios.delete(`company/${companyId}/action-items/${actionItemId}`).then(({ data }) => data);
