import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from '~/Common/Modal';
import { Box, Flex, Link, Heading } from 'rebass';
import { Button } from '~/Common/Button';
import { Icon } from '@svg/Icon';
import { SelectField, TextField } from '~/Form';
import { addUser } from '@app/Api/Organisation';
import styles from './style.module.scss';
import { setOrganisation } from '@app/Store';
import { Form } from '~/Common/Form';
import { getErrorMessage } from '@util/Functions';
import { toast } from 'react-toastify';

const AddUserModal = ({ isOpen, setOpen, org, setOrganisation }) => {
    const [errors, setErrors] = useState([]);
    const [activeStep, setActiveStep] = useState(0);

    const steps = [
        {
            title: 'New User',
            fieldNames: ['firstName', 'surname'],
            fields: (
                <>
                    <TextField
                        placeholder="Enter your first name"
                        name="firstName"
                        label="First Name"
                        boxSx={{ width: '100%' }}
                        errors={errors?.firstName}
                    />
                    <TextField
                        placeholder="Enter your surname"
                        name="surname"
                        label="Surname"
                        boxSx={{ width: '100%' }}
                        errors={errors?.surname}
                    />
                </>
            ),
        },
        {
            title: 'New User',
            fieldNames: ['email'],
            fields: (
                <>
                    <TextField
                        placeholder="Email address"
                        name="email"
                        type="email"
                        label="Email Address"
                        boxSx={{ width: '100%' }}
                        errors={errors?.firstName}
                    />
                </>
            ),
        },
        {
            title: 'Choose Access Level',
            fieldNames: ['accessLevel'],
            fields: (
                <>
                    <SelectField
                        name="accessLevel"
                        placeholder="— Select user access level —"
                        options={[
                            {
                                label: 'Advisor',
                                value: 2,
                            },
                            {
                                label: 'Supervisor',
                                value: 7,
                            },
                        ]}
                        boxSx={{
                            width: '100%',
                            mb: 3,
                        }}
                        errors={errors?.accessLevel}
                    />
                </>
            ),
        },
    ];

    const gotoErrorStep = (errors) => {
        const errorFields = Object.keys(errors ?? {});
        const stepIndex = steps.findIndex((step) => {
            return step.fieldNames.some((field) => errorFields.includes(field));
        });
        setActiveStep(stepIndex);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);

        addUser(org.id, {
            firstName: data.get('firstName'),
            surname: data.get('surname'),
            email: data.get('email'),
            accessLevel: data.get('accessLevel'),
        })
            .then(({ data }) => {
                setOrganisation(data);
                setOpen(false);
                setActiveStep(0);
                toast.success('User has been created successfully');
            })
            .catch(({ response }) => {
                response?.data?.errors && setErrors(response.data.errors);
                gotoErrorStep(response?.data?.errors);
                toast.error(getErrorMessage(response?.data, 'Something went wrong while creating the user'), {
                    toastId: 'addUser',
                });
            });
    };

    const handleNext = (e) => {
        if (activeStep < steps.length - 1) {
            e.preventDefault();
            setActiveStep((index) => index + 1);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            flex={true}
            maxWidth={531}
            sx={{ p: 0 }}
        >
            <Flex flexGrow="1" flexDirection="column" justifyContent="space-between">
                <Flex sx={{ bg: '#fff' }}>
                    <Box p="14px">
                        <Box
                            sx={{
                                bg: '#D1F5FF',
                                borderRadius: '20px',
                                padding: '4px 10px',
                                fontWeight: 600,
                                fontSize: '9px',
                                lineHeight: '10px',
                                letterSpacing: '0.5px',
                            }}
                        >
                            {activeStep + 1} / {steps.length}
                        </Box>
                    </Box>
                    <Box ml="auto" p={2}>
                        <Link as="a" href="#close" variant="circleButton" onClick={() => { setOpen(false); setActiveStep(0); }}>
                            <Icon icon="close" stroke="#656D78" title="Close Popup" />
                        </Link>
                    </Box>
                </Flex>
                <Form onSubmit={handleSubmit}>
                    <Flex
                        sx={{
                            width: `${steps.length * 100}%`,
                            transform: `translateX(-${activeStep * (100 / steps.length)}%)`,
                            transition: 'transform 0.4s ease-in-out',
                        }}
                    >
                        {steps.map((step, index) => (
                            <Flex
                                key={index}
                                className={index === activeStep ? styles.activeStep : styles.step}
                                width="100%"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Heading textAlign="center" mb={2} sx={{ fontSize: '16px', color: '#413E70' }}>
                                    {step.title}
                                </Heading>
                                <Box
                                    textAlign="center"
                                    mb={4}
                                    sx={{
                                        fontSize: '14px',
                                        color: '#6F7782',
                                    }}
                                >
                                    {step?.subtitle}
                                </Box>
                                {step.fields}
                                <Button
                                    onClick={handleNext}
                                    variant="primary"
                                    mb={4}
                                    sx={{ lineHeight: 1, minWidth: '130px' }}
                                >
                                    Next
                                </Button>
                            </Flex>
                        ))}
                    </Flex>
                </Form>
                <Flex
                    sx={{
                        gap: 3,
                        width: '100%',
                        padding: '24px',
                    }}
                >
                    {steps.map((step, index) => (
                        <Box
                            key={index}
                            sx={{
                                flex: 1,
                                bg: activeStep === index ? '#19CEFF' : '#E5E9EB',
                                borderRadius: '16px',
                                height: '4px',
                                transition: 'background 0.3s ease-in-out',
                                cursor: 'pointer',
                            }}
                            onClick={() => setActiveStep(index)}
                        />
                    ))}
                </Flex>
            </Flex>
        </Modal>
    );
};

export default connect((state) => ({ user: state.user, org: state.organisation }), { setOrganisation })(AddUserModal);
