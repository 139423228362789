import React from 'react';
import { Box } from 'rebass';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';

import { SidebarLink } from '~/Common/SidebarLink';
import { CompanyHeader } from '~/Common/CompanyHeader';
import { Icon } from '@svg';
import { toggleSidebarOpen } from '@app/Store/storeUi';
import { isAdvisor } from '@util/Functions';

const SidebarStyled = styled(Box)`
    height: 100%;
    width: ${(props) => (props.visible ? '290px' : '48px')};
    left: ${(props) => (props.showMenu ? '284px' : '68px')};
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
    transition: all 0.25s ease;
    will-change: left;
    padding: 0;
    background: #f6f8f9;
    z-index: 9;
    box-shadow: 0px 0px 20px rgba(0, 36, 128, 0.1);
    overflow-y: auto;
`;

const SidebarWrapper = styled(Box)`
    margin-top: 110px;

    @media (max-height: 1000px) {
        margin-top: 0;
    }
`;

export const Sidebar = ({ children, links, companyId, ...props }) => {
    const user = useSelector((state) => state.user);
    const sidebarOpen = useSelector((state) => state.ui.sidebarOpen);
    const dispatch = useDispatch();

    return (
        <SidebarStyled px={3} visible={sidebarOpen} {...props}>
            <Box
                sx={{
                    cursor: 'pointer',
                    textAlign: 'right',
                    width: '100%',
                    pt: '21px',
                    pr: sidebarOpen ? 3 : 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: sidebarOpen ? 'flex-end' : 'center',
                }}
                onClick={() => dispatch(toggleSidebarOpen())}
            >
                <Icon icon={sidebarOpen ? 'hamburgerClose' : 'menu'} stroke="#000" sx={{
                    transform: sidebarOpen ? 'none' : 'scaleX(-1) translateX(-4px)',
                }} />
            </Box>

            <CompanyHeader companyId={companyId} condensed={!sidebarOpen} isAdvisor={isAdvisor(user)} />

            <SidebarWrapper width="100%">
                <SidebarLink links={links} condensed={!sidebarOpen} />
            </SidebarWrapper>

            {children}
        </SidebarStyled>
    );
};

Sidebar.defaultProps = {
    links: [],
    companyName: '',
    companyId: '',
};
