import React, { useEffect, useMemo, useState } from 'react';
import { Box, Flex } from 'rebass';
import { getCompanyScorecardList, getScorecardList } from '@app/Api';
import ScorecardList from '~/Dashboard/ScorecardList';
import { toast } from 'react-toastify';
import { currentFinancialYear, getErrorMessage, yearsList } from '@util/Functions';
import LoadingSpinner from '~/LoadingSpinner';
import { SelectField } from '~/Form';
import { setScorecardYear } from '@app/Store';
import { connect } from 'react-redux';
import { PageTitle } from '~/Common/PageTitle';
import { Icon } from '@svg';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const Scorecards = ({ companyId, scorecardYear, setScorecardYear }) => {
    const [scorecards, setScorecards] = useState([]);
    const [scorecardTypes, setScorecardTypes] = useState([]);
    const [loading, setLoading] = useState(null);
    const [financialYear, setFinancialYear] = useState('');
    const [financialDataYears, setFinancialDataYears] = useState([]);
    const [errors, setErrors] = useState(null);
    const [resetTrigger, setResetTrigger] = useState(0);

    const linkStyle = { color: '#19ceff', marginLeft: 5, textDecoration: 'underline' };
    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true);
        const apiCall = companyId ? getCompanyScorecardList({ companyId }) : getScorecardList();
        apiCall
            .then(({ data }) => {
                setScorecards(data?.scorecards);
                setScorecardTypes(data?.scorecardTypes);
                const year = scorecardYear ? scorecardYear : currentFinancialYear;
                setFinancialYear(year);
                setLoading(false);
                const newFinancialDataYears = data?.financialDataYears ? data.financialDataYears.split(',') : [];
                setFinancialDataYears(newFinancialDataYears);
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to get scorecard')));
    }, [companyId, scorecardYear, resetTrigger]);

    const filteredScorecards = useMemo(() => scorecardTypes.map(scorecardType => {
        let newScorecard = { name: scorecardType.name, type: scorecardType.id, description: scorecardType.description };
        scorecards.find((scorecard) => {
            if (
                parseInt(scorecard.year) === parseInt(financialYear) &&
                parseInt(scorecardType.id) === parseInt(scorecard?.type?.id)
            ) {
                newScorecard = { ...newScorecard, ...scorecard };
                return true;
            }
            return false;
        });
        return newScorecard;
    }), [financialYear, scorecardTypes, scorecards]);

    const changeYear = (year) => {
        setFinancialYear(year);
        setScorecardYear(year);
    };

    const requiresFinancials = (year) => companyId && !financialDataYears.includes(year.toString());

    if (loading) return <LoadingSpinner />;

    return (
        <Box>
            <Box px={3}>
                <PageTitle
                    subtitle={t('scorecards.subtitle')}
                >
                    <SelectField
                        name="year"
                        value={financialYear}
                        onChange={changeYear}
                        options={yearsList()}
                        sx={{ padding: '10px 30px 10px 16px', minWidth: '200px', fontSize: 14, fontWeight: 600 }}
                        boxSx={null}
                    />
                </PageTitle>
            </Box>
            {errors && (
                <Box p={20} variant="card.wrapper">
                    <Box mb={1}>
                        You are unable to create the Scorecard for this Financial Year. Please complete the missing
                        information:
                    </Box>
                    {errors.customVariables !== undefined && <Box mt={2}>
                        <Flex alignItems="center" mb={1}>
                            {errors.customVariables ?
                                <Icon icon="completedNo" size="18" /> :
                                <Icon icon="completedYes" size="18" />
                            }
                            <Box fontSize={1} fontWeight={600} ml={1}>
                                Custom Variables: Beta Factor
                                {errors.customVariables ? <Link to={`/advisor/company/${companyId}/custom-variables`} style={linkStyle}>edit</Link> : ''}
                            </Box>
                        </Flex>
                    </Box>}
                    <Box mt={2}>
                        {errors.scorecards?.map(({ name }) => <Flex alignItems="center" mb={1}>
                            <Icon icon="completedNo" size="18" />
                            <Box fontSize={1} fontWeight={600} ml={1}>
                                Scorecard: {name}
                                <Link to={`/advisor/company/${companyId}/scorecards`} style={linkStyle}>edit</Link>
                            </Box>
                        </Flex>)}
                    </Box>
                </Box>
            )}
            {scorecards && (
                <ScorecardList
                    companyId={companyId}
                    scorecards={filteredScorecards}
                    year={financialYear}
                    requiresFinancials={requiresFinancials(financialYear)}
                    financialYear={financialYear}
                    setErrors={setErrors}
                    resetTrigger={setResetTrigger}
                />
            )}
        </Box>
    );
};

export default connect((state) => ({ scorecardYear: state.yearSelector.scorecardYear }), { setScorecardYear })(
    Scorecards
);
