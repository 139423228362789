import React, { useState, useEffect } from 'react';
import { Icon } from '@svg';
import { Flex, Box, Heading, Button, Image } from 'rebass';
import LoadingSpinner from '~/LoadingSpinner';
import { Modal } from '~/Common/Modal';
import { toast } from 'react-toastify';
import { downloadAttachment } from '@util/Functions';
import { getAttachment } from '@app/Api';
import PDFViewer from '~/Dashboard/PDFViewer';
import { useBreakpointIndex } from '@theme-ui/match-media'

const initialModalState = {
    open: false,
    blob: null,
    image: null,
    attachment: null
};

export const AttachmentPreview = ({
    attachment,
    close
}) => {
    const [downloading, setDownloading] = useState(false);
    const breakpoint = useBreakpointIndex();
    const supportedExtensions = ['pdf', 'jpg', 'png', 'jpeg', 'gif', 'webp'];
    const [modalPreview, setModalPreview] = useState(initialModalState);

    useEffect(() => {
        if (attachment?.id && modalPreview.open === false) {
            const extension = attachment?.fileName ? attachment?.fileName.toLowerCase().split('.').pop() : null;

            // Determine download experience based on file type or window size
            if (breakpoint >= 4 && supportedExtensions.includes(extension)) {
                setDownloading(true);
                setModalPreview({
                    open: true,
                    blob: null,
                    image: null,
                    attachment: attachment
                });
                getAttachment(attachment?.id)
                    .then((file) => {
                        switch (extension) {
                            case 'pdf':
                                setModalPreview({
                                    open: true,
                                    blob: file,
                                    attachment: attachment
                                });
                                break;

                            case 'jpg':
                            case 'png':
                            case 'jpeg':
                            case 'gif':
                            case 'webp':
                                setModalPreview({
                                    open: true,
                                    image: file,
                                    attachment: attachment
                                });
                                break;

                            default: return;
                        }
                    })
                    .catch(() => {
                        toast.error('There was an error while trying to download the file preview', { toastId: "filePreviewError" })
                    })
                    .finally(() => setDownloading(false));
            } else {
                downloadAttachment(attachment);
            }
        } else if (attachment === null) {
            setModalPreview(initialModalState);
        }
    }, [modalPreview, attachment, breakpoint, supportedExtensions]);



    return (
        <>
            {modalPreview && (
                <Modal
                    isOpen={modalPreview.open}
                    maxWidth={1200}
                    minWidth={500}
                >
                    <Box sx={{
                        position: 'relative',
                        maxHeight: '80vh',
                        overflowY: 'auto',
                        marginBottom: 5
                    }}>
                        <Flex>
                            {modalPreview?.heading ? <Heading pb={4}>{modalPreview.heading}</Heading> : null}
                            {close ? <Box ml="auto">
                                <Box variant="clickable" p={20} m="auto" onClick={close} pt={2}>
                                    <Icon icon="close" stroke="#656D78" title="Close Popup" />
                                </Box>
                            </Box> : null}
                        </Flex>

                        {downloading && <Box p={4}><LoadingSpinner size={30} /></Box>}
                        {modalPreview?.blob && <PDFViewer file={modalPreview.blob} pageWidth={900} wrapperHeight={'1300px'} />}
                        {modalPreview?.image && <Image src={URL.createObjectURL(modalPreview.image)} />}
                    </Box>
                    <Box sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: '60px',
                        paddingTop: 3,
                        paddingBottom: 3,
                        paddingLeft: 2,
                        paddingRight: 2,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        backgroundColor: 'primary'
                    }}>
                        <Button variant="primary" onClick={() => downloadAttachment(modalPreview?.attachment)} mr="2">
                            Download
                        </Button>
                        {close ? <Button variant="secondary" onClick={close} mr="2">
                            Close
                        </Button> : null}
                    </Box>
                </Modal>
            )}
        </>
    );
};
