import { createSlice } from '@reduxjs/toolkit'

const yearSelector = createSlice({
    name: 'yearSelector',
    initialState: { scorecardYear: null, financialHistoryYear: null },
    reducers: {
        setScorecardYear: (state, {payload}) => {
            state.scorecardYear = payload;
            return state
        },
        setFinancialHistoryYear: (state, {payload}) => {
            state.financialHistoryYear = payload;
            return state
        }
    }
})

export const { setScorecardYear, setFinancialHistoryYear } = yearSelector.actions

export default yearSelector.reducer
