import React, { forwardRef } from 'react';
import { Box } from 'rebass';
import { Label, Textarea } from '@rebass/forms';

const TextAreaField = forwardRef(
    (
        {
            value = undefined,
            onChange,
            name,
            helpText = '',
            errors = null,
            label = null,
            placeholder = '',
            type = 'text',
            resize = true,
            rows,
            disabled = false,
            allowNull = true,
            boxSx,
            ...props
        },
        ref
    ) => (
        <Box sx={boxSx}>
            {label && (
                <Label htmlFor={name} mb={1} flexDirection='column'>
                    {label}{' '}
                    {helpText && (
                        <>
                            <br />
                            <small>{helpText}</small>
                        </>
                    )}
                </Label>
            )}
            <Textarea
                id={name}
                rows={rows}
                name={name}
                type={type}
                ref={ref}
                placeholder={placeholder}
                value={value ?? (!allowNull ? '' : undefined)}
                onChange={(e) => typeof onChange === 'function' && onChange(e.target.value, name)}
                disabled={disabled}
                sx={{ resize: resize ? 'both' : 'none' }}
                {...props}
            />
            {errors && (
                <Box mt={-18} mb={20} color="red" fontSize={12}>
                    {errors[0]}
                </Box>
            )}
        </Box>
    )
);

export default TextAreaField;
