import { downloadWysiwygFile } from '@util/Functions'
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import LoadingSpinner from '~/LoadingSpinner'

const DownloadFile = ({ fileLocation, fileName }) => {

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        downloadWysiwygFile({ fileLocation, fileName })
        setLoading(false)
    }, [fileName, fileLocation])

    if (loading) return <LoadingSpinner />

    return <Redirect to='/' />
}

export default DownloadFile