import axios from 'axios'
import { downloadFile } from '@util/Functions'

export const indexCompanyTasks = ({
    orderBy,
    groupBy,
    filters,
    search,
    page
}) =>
    axios.get('company/task/index', {
            params: {
                search: typeof search === 'string' && search.length > 0 ? search : undefined,
                groupBy,
                orderBy,
                page,
                filters: Array.isArray(filters) ? (filters.length ? filters.join(',') : undefined) : undefined
            }
        })
        .then(({ data }) => data)

export const exportCompanyTasks = ({
    orderBy,
    groupBy,
    filters,
    search,
    page
}) =>
    axios.get('company/task/export', {
            params: {
                search: typeof search === 'string' && search.length > 0 ? search : undefined,
                groupBy,
                orderBy,
                page,
                filters: Array.isArray(filters) ? (filters.length ? filters.join(',') : undefined) : undefined
            }
        })
        .then((r) => {
            downloadFile(
                r.data,
                'Task Export.csv',
                r.headers['content-type']
            )
        })

export const getTaskDetails = (companyTaskId) =>
    axios.post(`company/task/get`, { companyTaskId })
        .then(({ data }) => data)

export const addTaskComment = (comment) =>
    axios.post('company/task/comment/add', comment)
        .then(({ data }) => data)

export const deleteTaskComment = (companyTaskCommentUuid) =>
    axios.delete(`company/task/comment/${companyTaskCommentUuid}`)
        .then(({ data }) => data)

export const toggleCommentLike = (companyTaskCommentId) =>
    axios.post(`company/task/comment/toggle-like`, { companyTaskCommentId })
        .then(({ data }) => data)

export const getAttachment = (commentAttachmentId) =>
    axios.request({ url: `company/task/attachment/get`, method: 'POST', responseType: 'blob', data: { commentAttachmentId } })
        .then(({ data }) => data)

export const getTemplate = (templateId) =>
    axios.request({ url: `company/task/template/get`, method: 'POST', responseType: 'blob', data: { templateId } })
        .then(({ data }) => data)

export const updateTaskRoardmap = (task) =>
    axios.post('advisor/task/roadmap/update', task)
        .then(({ data }) => data)

export const updateProjectRoardmap = (project) =>
    axios.post('advisor/task/roadmap/adjust', project)
        .then(({ data }) => data)
