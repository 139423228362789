import React, { forwardRef } from 'react';
import ReactInputVerificationCode from 'react-input-verification-code';
import { Box } from 'rebass';
import { Label } from '@rebass/forms';
import { theme } from '@app/theme';
import { VerifyStyled } from './styled';

// forward ref so we can focus with parent components
const VerifyField = forwardRef(
    (
        {
            label,
            name,
            apiName,
            type,
            className,
            placeholder,
            disabled,
            controlledInput,
            validationError,
            showLabel,
            onChangeOverride,
            onChange,
            customValueChange,
            validateField,
            fields,
            autoFocus = true,
            helpText,
            errors,
            sx,
            ...props
        },
        ref
    ) => {
        const fieldProps = { ...props };

        return (
            <VerifyStyled sx={sx}>
                {(showLabel && label) && (
                    <Label htmlFor={name} mb={1} flexDirection='column'>
                        {label} {helpText && <><br /><small>{helpText}</small></>}
                    </Label>
                )}

                <ReactInputVerificationCode
                    {...fieldProps}
                    {...props}
                    type={type}
                    length={fields}
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    ref={ref}
                    onChange={(value) => {
                        if (onChangeOverride) {
                            onChangeOverride(value);
                        } else {
                            if (validateField !== undefined) {
                                validateField(name);
                            }
                        }
                    }}
                />
                {errors && (
                    <Box mt={2} mb={20} color="red" fontSize={12}>
                        {errors[0]}
                    </Box>
                )}
            </VerifyStyled>
        );
    }
);

VerifyField.defaultProps = {
    validationError: false,
    placeholder: '',
    showLabel: false,
    controlledInput: true,
    type: 'text',
    required: false,
    disabled: false,
    fields: 6,
    autoComplete: 'true',
    inputStyle: {
        fontFamily: theme.fonts.body,
        borderRadius: '2px',
        fontSize: '16px',
        height: '50px',
        fontWeight: 'bold',
        backgroundColor: 'transparent',
        color: theme.colors.text,
    },
    inputStyleInvalid: {
        border: `1px solid ${theme.colors.error}`
    }
};

export default VerifyField;
