import React from 'react';
import { connect } from 'react-redux';
import { Card } from '~/Common/Card';
import { Flex, Text } from 'rebass';
import { Table } from '~/Common/Table';
import UserAvatar from '~/Dashboard/Avatar';
import { useTranslation } from 'react-i18next';
import { getCompanyStatus } from '@util/Enum';
import { Badge } from '~/Common/Badge';

const Billing = ({ org }) => {
    const { t } = useTranslation();
    const members = org?.structure?.members
    const companies = org?.structure?.companies
    const reports = org?.structure?.reports

    return <>
        <Card title="Users" groupSx={{ p: 0 }}>
            <Table
                columns={[
                    {
                        key: 'user',
                        title: 'User',
                        render: (row) => (
                            <Flex sx={{ alignItems: 'center' }}>
                                <UserAvatar
                                    name={row.name}
                                    avatar={row.avatar}
                                    mr={2}
                                />
                                {row.name}
                            </Flex>
                        ),
                    },
                    {
                        key: 'role',
                        title: 'Role',
                        render: (row) => <Text>{row.roles.map((r) => r.name).join(', ')}</Text>,
                    },
                    {
                        key: 'created',
                        title: 'Created',
                        render: (row) => (
                            <Text
                                sx={{
                                    fontSize: '10px',
                                    color: '#6F7782',
                                }}
                            >
                                {row.created}
                            </Text>
                        ),
                    },
                ]}
                data={members}
                isLoading={!Array.isArray(members)}
                emptyTemplate={{
                    icon: 'users',
                    heading: 'No users',
                    content: 'There are currently no organisation users',
                }}
            />
        </Card>

        <Card title="Companies" groupSx={{ p: 0 }}>
            <Table
                columns={[
                    {
                        key: 'company_name',
                        title: 'Name',
                        render: (row) => (
                            <Flex sx={{ alignItems: 'center' }}>
                                <UserAvatar
                                    name={row.name}
                                    avatar={row.logo}
                                    mr={2}
                                />
                                {row.name}
                            </Flex>
                        ),
                    },
                    {
                        key: 'status',
                        title: 'Status',
                        render: (row) => {
                            const status = getCompanyStatus(row.status)

                            return status ? <Badge
                                sx={status.sx}
                            >
                                {t(status.name)}
                            </Badge> : '-'
                        },
                    },
                    {
                        key: 'created',
                        title: 'Created',
                        render: (row) => (
                            <Text
                                sx={{
                                    fontSize: '10px',
                                    color: '#6F7782',
                                }}
                            >
                                {row.created}
                            </Text>
                        ),
                    },
                ]}
                data={companies}
                isLoading={!Array.isArray(companies)}
                emptyTemplate={{
                    icon: 'staff',
                    heading: 'No companies',
                    content: 'There are currently no organisation companies',
                }}
            />
        </Card>

        <Card title="Reports" groupSx={{ p: 0 }}>
            <Table
                columns={[
                    {
                        key: 'company',
                        title: 'Company',
                        render: (row) => {
                            const company = companies.find(c => c.id === row?.companyId)

                            return company ? <Flex sx={{ alignItems: 'center' }}>
                                <UserAvatar
                                    name={company.name}
                                    avatar={company.logo}
                                    mr={2}
                                />
                                {company.name}
                            </Flex> : '-'
                        },
                    },
                    {
                        key: 'year',
                        title: 'Financial Year',
                        render: (row) => <Text>{row?.year}</Text>,
                    },
                    {
                        key: 'updated',
                        title: 'Updated',
                        render: (row) => (
                            <Text
                                sx={{
                                    fontSize: '10px',
                                    color: '#6F7782',
                                }}
                            >
                                {row?.lastUpdatedOn}
                            </Text>
                        ),
                    },
                ]}
                data={reports}
                isLoading={!Array.isArray(reports)}
                emptyTemplate={{
                    icon: 'paper',
                    heading: 'No reports',
                    content: 'There are currently no business insight reports',
                }}
            />
        </Card>
    </>;
};

export default connect((state) => ({ org: state.organisation }))(Billing);
