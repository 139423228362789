export const breakpoints = ['576px', '768px', '992px', '1290px', '1600px', '1920px'];

export const theme = {
    breakpoints: breakpoints,
    fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
    space: [0, 4, 8, 16, 32, 64, 128, 256],
    styles: {
        root: {
            fontFamily: 'body',
            lineHeight: 'body',
            fontWeight: 'body',
        },
        p: {
            lineHeight: 2,
        },
    },
    colors: {
        blue: '#07c',
        blueDark: '#2C2960',
        blueDark2: '#9594AF',
        blueLight: '#19CEFF',
        blueLight2: '#25AACD',
        blueLight20: '#D1F5FF',
        captionGray: '#6F7782',
        lightGray: '#8E93A0',
        darkGray: '#767985',
        black: '#0E0E2C',
        gray: '#F6F8F9',
        gray2: '#E8ECEE',
        gray3: '#656D78',
        mainBlue: '#093467',
        primary: '#2C2960',
        neutral: '#AABBC3',
        background: '#E5E9EB',
        red: '#F44545',
        redDark: '#bc1818',
        orange: '#EC9A1E',
        orangeLight: '#EBC02C',
        blueHighlight: '#ECFAFE',
        green: '#16E4B3',
        purple: '#EDE9FE',
        warning: '#FEF3C7',
        error: '#FEE2E2',
        success: '#D1FAE5',
    },
    fonts: {
        body: 'Open Sauce Two, system-ui, sans-serif',
        heading: 'inherit',
        monospace: 'Menlo, monospace',
    },
    fontWeights: {
        body: 400,
        heading: 700,
        bold: 700,
    },
    lineHeights: {
        body: 1.5,
        heading: 1.25,
    },
    shadows: {
        small: '0 0 4px rgba(0, 0, 0, .125)',
        large: '0 0 24px rgba(0, 0, 0, .125)',
    },
    icons: {
        default: {},
        headerIcon: {
            width: '32px !important',
            height: '32px !important',
            p: '8px',
            color: 'primary',
            bg: 'blueHighlight',
            borderRadius: '16px',
        }
    },
    taskStatus: {
        toBeStarted: { backgroundColor: '#FEF3C7', color: '#92400E' },
        incomplete: { backgroundColor: '#EDE9FE', color: '#5B21B6' },
        inReview: { backgroundColor: '#FEF3C7', color: '#92400E' },
        complete: { backgroundColor: '#D1FAE5', color: '#065F46' },
        notGiven: { backgroundColor: '#F2F2F2', color: '#BDBDBD' },
        overdue: { backgroundColor: '#FEE2E2', color: '#991B1B' },
    },
    companyStatus: {
        onboarding: { bg: '#F2F2F2', color: '#8E93A0' },
        inProgress: { bg: '#EDE9FE', color: '#5B21B6' },
        completed: { bg: '#D1FAE5', color: '#065F46' },
        overdue: { bg: '#FEE2E2', color: '#991B1B' },
        inReview: { bg: '#FEF3C7', color: '#92400E' },
        inactive: { bg: '#F2F2F2', color: '#BDBDBD' },
        archived: { bg: '#F2F2F2', color: '#BDBDBD' },
    },
    alerts: {
        default: {
            bg: '#eee',
            alignItems: 'center',
            lineHeight: 1.5,
            py: 2,
            px: 4,
            mb: 3,
            borderRadius: '8px',
        },
        success: {
            bg: '#e3ffda',
        },
        warning: {
            bg: 'warning',
        },
        error: {
            bg: 'error',
        }
    },
    variants: {
        chip: {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            bg: '#F5F7F8',
            padding: '6px 10px',
            borderRadius: '16px',
            fontWeight: 600,
            fontSize: '14px',
            color: '#2C2960',
            '& span': {
                fontSize: '8px',
                lineHeight: '10px',
                letterSpacing: '0.5px',
                textTransform: 'uppercase',
                fontWeight: 600,
            }
        },
        statusTag: {
            borderRadius: '12px',
            padding: '3px 5px',
            fontSize: '8px',
            lineHeight: '10px',
            textTransform: 'uppercase',
            letterSpacing: '0.5px',
            fontWeight: 600
        },
        link: {
            fontSize: '12px',
            position: 'relative',
            fontWeight: 600,
            color: '#6F7782',
            transition: 'color 0.3s ease-in-out',
            cursor: 'pointer',
            borderRadius: '4px',
            '&:hover': {
                color: '#19CEFF',

                '&::after': {
                    width: '100%',
                },
            },
            '&::after': {
                content: '""',
                position: 'absolute',
                transition: 'width 0.3s ease-in-out',
                left: 0,
                bottom: 0,
                width: '0',
                height: '1px',
                background: '#19CEFF',
            },
        },
        actionLink: {
            position: 'relative',
            fontWeight: 600,
            fontSize: 0,
            cursor: 'pointer',
            transition: 'color 0.3s ease-in-out',
            color: 'blueDark2',
            borderRadius: '4px',
            '&:hover': {
                color: '#19CEFF',

                '&::after': {
                    width: '100%',
                },
            },
            '&::after': {
                content: '""',
                position: 'absolute',
                transition: 'width 0.3s ease-in-out',
                left: 0,
                bottom: 0,
                width: '0',
                height: '1px',
                background: '#19CEFF',
            },
        },
        transparent: {
            cursor: 'pointer',
        },
        tileLink: {
            display: 'block',
            fontSize: '12px',
            mt: 1,
            bg: '#F5F7F8',
            borderRadius: '8px',
            transition: '0.3s ease-in-out',

            '& .icon': {
                color: '#9594AF',
                transition: 'color 0.3s ease-in-out',
            },
            '&:hover': {
                background: '#ECFAFE',

                '& .icon:not(.no-hover)': {
                    color: '#19CEFF',
                },
            },
        },
        accordion: {
            wrapper: {},
            header: {
                display: 'flex',
                alignItems: 'center',
                transition: 'padding 0.5s ease-in-out',
                px: '20px',
                fontWeight: 600,
                fontSize: '14px',
                color: 'blueDark',
                lineHeight: '20px',
                cursor: 'pointer',
            },
            caption: {
                fontSize: '12px',
                color: '#9594AF',
                fontWeight: 400,
                ml: 3,
            },
            body: {
                transition: 'max-height 0.5s ease-in-out, opacity 0.3s ease-in-out 0.2s',
            },
        },
        card: {
            wrapper: {
                mx: 3,
                mb: 32,
                backgroundColor: '#F5F7F8',
                overflow: 'hidden',
                borderRadius: 25,
            },
            header: {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                padding: '18px 30px',
                color: 'mainBlue',
                fontSize: 19,
                fontWeight: 600,
                background: 'white',
                alignItems: 'center',
            },
            container: {
                borderRadius: '0 0 10px 10px',
                padding: '24px',
                minHeight: '20px',
            },
            containerGapless: {
                borderRadius: '0 0 10px 10px',
            },
            item: {
                alignItems: 'center',
                bg: '#ffffff',
                borderRadius: '12px',
                fontSize: '29px',
                fontWeight: 600,
                boxShadow: '0px 0px 12px rgba(44, 41, 96, 0.08)',
                p: 4,

                '& .card-item--title': {
                    display: 'inline-flex',
                    alignItems: 'center',
                    fontSize: '14px',
                    flex: 1,
                },

                '&:is(a) .link--icon': {
                    transform: 'translateX(-100%)',
                    opacity: 0,
                    transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
                },
                '&:is(a):hover .link--icon': {
                    transform: 'translateX(0)',
                    opacity: 1,
                },
            },
        },
        tabCard: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            bg: 'white',
            borderRadius: '0 0 16px 16px',
        },
        statCard: {
            alignItems: 'center',
            bg: '#F6FAFB',
            borderRadius: '12px',
            fontSize: '29px',
            fontWeight: 600,
            px: 3,
            py: '20px',
            height: '61px',

            '& .card-item--title': {
                display: 'inline-flex',
                alignItems: 'center',
                fontSize: '14px',
                color: '#6F7782',
                flex: 1,
            },

            '&:is(a) .link--icon': {
                transform: 'translateX(-100%)',
                opacity: 0,
                transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
            },
            '&:is(a):hover .link--icon': {
                transform: 'translateX(0)',
                opacity: 1,
            },
        },
        statBorderless: {
            alignItems: 'start',
            bg: '#fff',
            fontSize: '22px',
            fontWeight: 600,
            px: 4,
            py: '16px',
            maxHeight: '82px',
            borderRight: '1px solid #E8EAEF',
            flexWrap: 'wrap',
            gap: '12px',
            lineHeight: 1,

            '&:last-child': {
                borderRight: 'none',
            },

            '& .card-item--title': {
                display: 'inline-flex',
                alignItems: 'center',
                fontSize: '12px',
                color: '#6F7782',
                fontWeight: 400,
                flex: 1,
                lineHeight: '22px',
            },

            '&:is(a) .link--icon': {
                transform: 'translateX(-100%)',
                opacity: 0,
                transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
            },
            '&:is(a):hover .link--icon': {
                transform: 'translateX(0)',
                opacity: 1,
            },
        },
        avatar: {
            backgroundColor: '#C4C4C4',
            borderRadius: '50%',
            height: 50,
            width: 50,
            textAlign: 'center',
            paddingTop: 15,
            color: 'white',
            border: '1px solid #A3A3A3',
            cursor: 'context-menu',
        },
        barProgressBackground: {
            backgroundColor: '#E8ECEE',
            borderRadius: 8,
            // height: 5,
            width: '100%',
            position: 'relative',
        },
        barProgress: {
            backgroundColor: 'blueLight',
            borderRadius: 8,
            height: 5,
        },
        subHeader: {
            fontSize: 8,
            letterSpacing: '0.025rem',
            textTransform: 'uppercase',
            fontWeight: 500,
            color: '#ABAEB8',
        },
        headerShading: {
            background: 'linear-gradient(180deg, #F3F3F3 0.92%, #F7F7F7 4.9%, #FCFCFC 14.08%, #FFFFFF 27.25%)',
        },
        attachmentLink: {
            color: '#00AEEF',
            cursor: 'pointer',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        clickable: {
            cursor: 'pointer',
        },
        circleButton: {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            bg: '#F5F7F8',
            width: '30px',
            height: '30px',
        },
        taskPercent: {
            fontSize: 12,
            color: 'blueDark',
            fontWeight: 600,
        },
        small: {
            fontSize: 12,
        },
        training: {
            backgroundColor: 'red',
            color: 'white',
            fontSize: 12,
            padding: 1,
            fontWeight: 600,
            textAlign: 'center',
        },
        warning: {
            backgroundColor: 'red',
            color: 'white',
            margin: 20,
            borderRadius: 20,
        },
        warningHeader: {
            borderRadius: 25,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            padding: '18px 24px 0',
            fontSize: 19,
            fontWeight: 600,
            backgroundColor: 'red',
            color: 'white',
        },
        labelTab: {
            display: 'flex',
            alignItems: 'center',
            background: '#f0f0f0',
            color: 'blueDark',
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '12px',
            padding: '6px 25px',
            borderRadius: '0',
            transition: 'all 0.3s ease',
            fontFamily: 'OpenSauceTwo, Arial',
            '&:first-of-type': {
                borderRadius: '5px 0 0 5px',
            },
            '&:last-child': {
                borderRadius: '0 5px 5px 0',
            },
        },
    },
    scorecard: {
        description: {
            fontSize: '14px',
            color: '#6F7782',
        },
    },
    text: {
        light: {
            fontSize: '12px',
            color: '#8E93A0',
        },
        lightSmall: {
            fontSize: '8px',
            color: '#9594AF',
            lineHeight: '150%',
        },
        dark: {
            color: 'blueDark',
            fontSize: '12px',
            fontWeight: 700,
        },
        caption: {
            fontSize: 0,
            fontWeight: 'thin',
        },
        captionTitle: {
            fontSize: 1,
            fontWeight: 'bold',
        }
    },
    badges: {
        default: {
            bg: '#8E93A0',
            color: '#fff',
        },
        warning: {
            bg: 'warning',
            color: '#92400E',
        },
        error: {
            bg: 'error',
            color: '#991B1B',
        },
        purple: {
            bg: 'purple',
            color: '#5B21B6',
        },
        success: {
            bg: 'success',
            color: '#065F46'
        }
    },
    inputs: {
        thin: {
            padding: '6px 15px',
            marginBottom: '20px',
            border: '1px solid #C3C7D0',
            borderRadius: '8px',
            backgroundColor: 'white',
            fontSize: '12px',
            '&:hover': {
                borderColor: 'blueLight',
            },
            'input&::placeholder': {
                color: '#8E93A0',
                opacity: 1 /* Firefox */,
            },
        },
        secondary: {
            padding: '12px 17px',
            marginBottom: '20px',
            border: '1px solid rgba(132, 146, 166, 0.2)',
            borderRadius: '8px',
            backgroundColor: '#F5F7F8',
            fontSize: '14px',
            color: 'blueDark',
            '&:hover': {
                borderColor: 'blueLight',
            },
        },
    },
    input: {
        padding: '10px 15px',
        marginBottom: '20px',
        border: '1px solid rgba(132, 146, 166, 0.2)',
        borderRadius: '8px',
        backgroundColor: 'white',
        fontSize: '16px',
        '&:hover': {
            borderColor: 'blueLight',
        },
    },
    select: {
        padding: '20px',
        border: '1px solid rgba(132, 146, 166, 0.2)',
        borderRadius: '8px',
        backgroundColor: 'white',
        '&:hover': {
            borderColor: 'blueLight',
        },
    },
    heading: {
        color: 'mainBlue',
        fontSize: 18,
    },
    label: {
        marginBottom: '10px',
        fontSize: '12px',
        fontWeight: '600',
        color: 'blueDark',
    },
    textarea: {
        padding: '10px 15px',
        marginBottom: '20px',
        border: '1px solid rgba(132, 146, 166, 0.2)',
        borderRadius: '8px',
        backgroundColor: 'white',
        fontSize: '16px',
        fontFamily: 'body',
        '&:hover': {
            borderColor: 'blueLight',
        },
    },
    radio: {
        color: 'black',
    },
    forms: {
        sidebarInput: {
            background: '#413E70',
            borderRadius: '10px',
            border: 'none',
            fontSize: '12px',
            px: '12px',
            py: 2,
        },
        radio: {
            label: {
                display: 'flex',
                alignItems: 'center',
                fontSize: 0,
                cursor: 'pointer',
            },
        },
        checkbox: {
            label: {
                display: 'flex',
                alignItems: 'center',
                fontSize: 0,
                cursor: 'pointer',
                fontWeight: 400,
                color: '#6F7782',
            },
        },
        section: {
            borderRadius: '8px',
            bg: 'white',
            padding: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
        },
    },
    buttons: {
        primary: {
            lineHeight: 1.2,
            background: '#00C2FF',
            color: 'blueDark',
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '14px',
            padding: '13px 25px',
            borderRadius: '8px',
            transition: 'all 0.3s ease',
            fontFamily: 'OpenSauceTwo, Arial',
            border: 'none',
            '&:hover': {
                backgroundColor: 'blueDark',
                color: 'white',
            },
        },
        paginations: {
            padding: '12px 17px',
            marginBottom: '20px',
            border: '1px solid rgba(132, 146, 166, 0.2)',
            borderRadius: '8px',
            backgroundColor: 'white',
            color: 'blueDark',
            fontWeight: '500',
            fontSize: '12px',
            '&:hover': {
                borderColor: 'blueLight',
                backgroundColor: 'blueLight20',
            },
            '&.active': {
                borderColor: 'blueLight',
                backgroundColor: 'blueLight20',
            },

            div: {
                padding: '2px 8px',
                backgroundColor: '#F5F7F8',
                borderRadius: '26px',
                fontSize: '12px',
                '&.active': {
                    backgroundColor: 'white',
                    color: 'blueDark'
                },
            }

        },
        primarySmall: {
            lineHeight: 1.2,
            background: '#00C2FF',
            color: 'blueDark',
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '12px',
            padding: '7px 17px',
            borderRadius: '8px',
            transition: 'all 0.3s ease',
            fontFamily: 'OpenSauceTwo, Arial',
            '&:hover': {
                backgroundColor: 'blueDark',
                color: 'white',
            },
        },
        secondary: {
            lineHeight: 1.2,
            backgroundColor: 'white',
            border: '1px solid rgba(76, 108, 146, 0.2)',
            color: '#093467',
            cursor: 'pointer',
            fontWeight: 600,
            borderRadius: '8px',
            padding: '12px 20px',
            fontSize: '14px',
            transition: 'all 0.3s ease',
            fontFamily: 'OpenSauceTwo, Arial',
            '&:hover': {
                borderColor: 'blueLight',
            },
        },
        preview: {
            borderColor: '#E8EAEF',
            color: 'blueDark',
            fontFamily: 'OpenSauceTwo',
            borderRadius: '8px',
            transition: 'all 0.3s ease-in',
            cursor: 'pointer',
            backgroundColor: '#FFFFFF',
            display: 'flex',
            gap: '5px',
            height: '40px',
            width: '112px',
            alignItems: 'center',
            fontSize: '12px',
            fontWeight: '500',
            '&.active': {
                color: 'white',
                bg: 'blueDark',
                border: 'blueDark',
            },
            '&:hover': {
                border: '1px solid darkGray'

            },
            '&:focus': {
                outline: "none",
            },
        },
        gray: {
            lineHeight: 1.2,
            bg: '#F5F7F8',
            color: 'blueDark',
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '14px',
            padding: '7px 17px',
            borderRadius: '8px',
            transition: 'all 0.3s ease',
            fontFamily: 'OpenSauceTwo, Arial',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            '&:hover': {
                backgroundColor: 'blueDark',
                color: 'white',
            },
        },
        smallGray: {
            lineHeight: 1.2,
            bg: '#F5F7F8',
            color: 'blueDark',
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '12px',
            padding: '7px 17px',
            borderRadius: '8px',
            transition: 'all 0.3s ease',
            fontFamily: 'OpenSauceTwo, Arial',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
                backgroundColor: 'blueDark',
                color: 'white',
            },
        },
        iconCircle: {
            lineHeight: 1.2,
            bg: '#F5F7F8',
            color: '#9594AF',
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '12px',
            padding: '6px',
            borderRadius: '50%',
            transition: 'all 0.3s ease',
            fontFamily: 'OpenSauceTwo, Arial',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& svg': {
                maxWidth: '14px',
                maxHeight: '14px',
            },
            '&:hover': {
                backgroundColor: 'blueDark',
                color: 'white',
            },
        },
        flex: {
            lineHeight: 1.2,
            display: 'flex',
            textAlign: 'left',
            alignItems: 'center',
            bg: 'transparent',
            p: 0,
            color: 'blueDark',
            cursor: 'pointer',
        },
        tab: {
            lineHeight: 1.2,
            display: 'flex',
            alignItems: 'center',
            background: '#F5F7F8',
            color: 'blueDark',
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '12px',
            padding: '6px 25px',
            borderRadius: '0',
            transition: 'all 0.3s ease',
            fontFamily: 'OpenSauceTwo, Arial',
            '&:disabled': {
                opacity: '0.5',
                cursor: 'default',
            },
            '&:hover:not(:disabled)': {
                backgroundColor: 'blueHighlight',
                color: 'blueDark',
            },
            '&.active': {
                background: '#D1F5FF',
                color: 'blueDark',
                zIndex: 10,
                '&.red': {
                    background: 'red',
                    color: '#fff',
                },
            },
            '&:first-of-type': {
                borderRadius: '5px 0 0 5px',
            },
            '&:last-child': {
                borderRadius: '0 5px 5px 0',
            },
        },
        tabCircle: {
            lineHeight: 1.2,
            display: 'flex',
            alignItems: 'center',
            background: '#F5F7F8',
            color: 'blueDark',
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '12px',
            padding: '7px 16px',
            borderRadius: '20px',
            transition: 'all 0.3s ease',
            fontFamily: 'OpenSauceTwo, Arial',
            '&:disabled': {
                opacity: '0.5',
                cursor: 'default',
            },
            '&:hover:not(:disabled)': {
                backgroundColor: 'blueHighlight',
                color: 'blueDark',
            },
            '&.active': {
                background: '#D1F5FF',
                color: 'blueDark',
            },
            '&.theme--dark': {
                background: 'blueDark',
                color: '#D1F5FF',
            },
            '&.theme--outlined': {
                background: 'transparent',
                border: '1px solid',
                borderColor: '#00C2FF',
                '&.active': {
                    borderColor: '#2C2960',
                    background: '#2C2960',
                    color: '#fff',
                },
            },
        },
        tabHeader: {
            lineHeight: 1.2,
            display: 'flex',
            gap: 1,
            flex: 1,
            alignItems: 'center',
            background: '#fff',
            justifyContent: 'center',
            color: 'blueDark',
            cursor: 'pointer',
            fontWeight: 500,
            fontSize: '12px',
            padding: '12px',
            borderRadius: '8px 8px 0px 0px',
            transition: 'all 0.3s ease',
            fontFamily: 'OpenSauceTwo, Arial',
            opacity: 0.4,
            '&:disabled': {
                opacity: '0.5',
                cursor: 'default',
            },
            '&:hover:not(:disabled)': {
                backgroundColor: 'blueHighlight',
                color: 'blueDark',
            },
            '&:focus': {
                outline: 0,
            },
            '&.active': {
                opacity: 1,
                color: 'blueDark',
                zIndex: 10,
                outline: 0,
            },
        },
        task: {
            backgroundColor: 'blueLight',
            color: 'blueDark',
            borderRadius: '20px',
            padding: '5px 10px !important',
            lineHeight: 1,
            fontSize: '8px',
            transition: 'all 0.3s ease',
            fontFamily: 'OpenSauceTwo, Arial',
            fontWeight: 600,
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: 'blueDark',
                color: 'white',
            },
        },
        smallTask: {
            lineHeight: 1.2,
            borderRadius: 5,
            padding: '0px 5px',
            fontSize: '8px',
            textTransform: 'uppercase',
            width: 70,
            minWidth: 70,
            fontFamily: 'OpenSauceTwo, Arial',
            transition: 'all 0.3s ease',
        },
        companyActions: {
            lineHeight: 1.2,
            padding: '5px 10px',
            fontSize: '12px',
            backgroundColor: 'white',
            border: (t) => `1px solid ${t.colors.lightGray}`,
            color: 'lightGray',
            fontFamily: 'OpenSauceTwo, Arial',
            transition: 'all 0.3s ease',
        },
        rounded: {
            lineHeight: 1.2,
            display: 'flex',
            fontFamily: 'OpenSauceTwo, Arial',
            bg: '#f4f4f4',
            color: 'primary',
            fontSize: '12px',
            fontWeight: 500,
            alignItems: 'center',
            py: 1,
            px: 3,
            borderRadius: '100px',
            cursor: 'pointer',
            '& svg': {
                transition: 'color 0.3s ease-in-out',
            },
            '&:hover': {
                bg: 'blueLight20',
            },
        },
        transparent: {
            lineHeight: 1.2,
            backgroundColor: 'transparent',
            fontFamily: 'OpenSauceTwo, Arial',
            transition: 'all 0.3s ease',
            color: 'blueDark',
            fontSize: '12px',
            fontWeight: 600,
            cursor: 'pointer',
            '& svg': {
                transition: 'color 0.3s ease-in-out',
            },
            '&:hover': {
                bg: 'blueLight20',
            },
            '&.active': {
                bg: 'blueLight20',
            },
        },
        header: {
            lineHeight: 1.2,
            background: 'transparent',
            borderRadius: '100px',
            transition: 'background 0.5s ease-in-out',
            color: 'primary',
            '&:hover': {
                background: '#F5F7F8',
            },
        },
        menuitem: {
            lineHeight: 1.2,
            fontSize: '12px',
            cursor: 'pointer',
            borderRadius: '8px',
            p: '7px 9px',
            '&.active svg path': {
                stroke: 'blueLight',
            },
            '&:hover, &.active': {
                background: '#413E70',
            },
        },
        transparentBorder: {
            lineHeight: 1.2,
            background: 'transparent',
            color: 'blueDark',
            cursor: 'pointer',
            fontWeight: 700,
            fontSize: '14px',
            padding: '10px 15px',
            border: '1px solid',
            borderColor: 'blueLight',
            borderRadius: '8px',
            transition: 'all 0.3s ease',
            fontFamily: 'OpenSauceTwo, Arial',
            '&:hover': {
                backgroundColor: 'blueDark',
                borderColor: 'blueDark',
                color: 'white',
            },
        },
        transparentBorder2: {
            lineHeight: 1.2,
            background: 'transparent',
            color: 'blueDark',
            cursor: 'pointer',
            fontWeight: 700,
            fontSize: '12px',
            padding: '11px',
            border: '1px solid',
            borderColor: '#E8EAEF',
            borderRadius: '8px',
            transition: 'all 0.3s ease',
            fontFamily: 'OpenSauceTwo, Arial',
            '&:hover': {
                backgroundColor: 'blueDark',
                borderColor: 'blueDark',
                color: 'white',
            },
        },
        disabled: {
            lineHeight: 1.2,
            background: 'lightGrey',
            color: 'darkGrey',
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '14px',
            padding: '13px 20px',
            borderRadius: '8px',
            transition: 'all 0.3s ease',
            fontFamily: 'OpenSauceTwo, Arial',
        },
        submitting: {
            lineHeight: 1.2,
            background: 'lightGrey',
            color: 'darkGrey',
            cursor: 'progress',
            fontWeight: 600,
            fontSize: '14px',
            padding: '13px 20px',
            borderRadius: '8px',
            transition: 'all 0.3s ease',
            fontFamily: 'OpenSauceTwo, Arial',
        },
        roadmapTask: {
            lineHeight: 1.2,
            marginLeft: 'auto',
            flexShrink: '0',
            fontSize: '8px',
            padding: '5px',
            minWidth: '75px',
        },
        tertiary: {
            lineHeight: 1.2,
            backgroundColor: 'blueDark',
            color: 'white',
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '14px',
            padding: '13px 25px',
            borderRadius: '8px',
            transition: 'all 0.3s ease',
            fontFamily: 'OpenSauceTwo, Arial',
            '&:hover': {
                backgroundColor: 'blueLight',
                color: 'blueDark',
            },
        },
        attachmentLink: {
            lineHeight: 1.2,
            color: '#00AEEF',
            cursor: 'pointer',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            padding: 0,
            backgroundColor: 'transparent',
            textAlign: 'left',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        red: {
            lineHeight: 1.2,
            color: 'white',
            background: 'red',
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '14px',
            padding: '13px 25px',
            borderRadius: '8px',
            transition: 'all 0.3s ease',
            fontFamily: 'OpenSauceTwo, Arial',
            '&:hover': {
                backgroundColor: 'blueDark',
                color: 'white',
            },
        },
        warningSmall: {
            lineHeight: 1.2,
            color: 'white',
            background: '#EC9A1E',
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '12px',
            padding: '10px 15p',
            borderRadius: '8px',
            transition: 'all 0.3s ease',
            fontFamily: 'OpenSauceTwo, Arial',
            '&:hover': {
                backgroundColor: 'blueDark',
                color: 'white',
            },
        },
        warning: {
            lineHeight: 1.2,
            color: 'white',
            background: (t) => `${t.colors.redDark}`,
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '14px',
            padding: '13px 25px',
            borderRadius: '8px',
            transition: 'all 0.3s ease',
            fontFamily: 'OpenSauceTwo, Arial',
            '&:hover': {
                backgroundColor: 'black',
                color: 'white',
            },
        },

    },
    dropdown: {
        wrapper: {
            position: 'relative',
            whiteSpace: 'nowrap',
            fontSize: '12px',
            maxWidth: '350px',
        },
        button: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            bg: 'white',
            color: 'lightGray',
            fontSize: 'inherit',
            fontWeight: 400,
            borderRadius: '8px',
            p: '8px 9px',
            cursor: 'pointer',
            transition: 'background 0.3s ease-in-out, border-color 0.3s ease-in-out',
            '&.outline, &.outline:focus': {
                border: '1px solid transparent',
                '&:not(:hover)': {
                    borderColor: '#C3C7D0',
                    '&::after': {
                        bg: '#C3C7D0'
                    }
                }
            },
            '& .value': {
                color: 'blueDark',
                fontWeight: 700,
            },
            '&:hover': {
                bg: 'blueLight20',
            },
            '&::after': {
                content: '""',
                width: '1px',
                height: '100%',
                bg: '#fff',
                position: 'absolute',
                top: 0,
                right: '26px',
                transition: 'background 0.3s ease-in-out',
            },
        },
        options: {
            display: 'grid',
            gridGap: '8px',
            position: 'absolute',
            top: '100%',
            zIndex: 99,
            background: 'white',
            padding: 2,
            boxShadow: '0px 0px 12px rgba(44, 41, 96, 0.08)',
            borderRadius: '8px',
            marginTop: '5px',
            width: 'max-content',
            minWidth: '100%',
            fontSize: '12px',
        },
        item: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 'inherit',
            gap: '8px',
            lineHeight: '1.334',
            bg: 'transparent',
            padding: 0,
            borderRadius: '8px',
            color: 'primary',
            textAlign: 'left',
            cursor: 'pointer',
            p: '6px 8px',
            transition: 'background 0.3s ease-in-out',
            '&:hover': {
                fontWeight: 600,
                bg: 'blueHighlight',
            },
            '&.active': {
                outline: '3px solid #19CEFF',
                outlineOffset: '3px',
            },
        },
        optionsTitle: {
            p: '0 9px',
            fontSize: '16px',
            fontWeight: 600,
            mt: 1,
            mb: 2,
            pr: '72px',
        },
        optionsLabel: {
            p: '0 9px',
            fontWeight: 600,
            mt: 1,
        },
    },
    analystSelect: {
        options: {
            display: 'grid',
            gridGap: '10px',
            minWidth: '250px',
            position: 'absolute',
            left: 0,
            top: '100%',
            background: 'white',
            mt: '10px',
            p: 3,
            borderRadius: '8px',
            zIndex: 999,
        },
        item: {
            fontSize: '14px',
            bg: 'transparent',
            padding: 0,
            color: 'black',
            textAlign: 'left',
            cursor: 'pointer',
            '&:hover': {
                fontWeight: 600,
            },
            '&.active': {
                outline: '3px solid #19CEFF',
                outlineOffset: '3px',
            },
        },
    },
    modal: {
        title: {
            display: 'flex',
            alignItems: 'center',
            bg: '#fff',
            gap: 3,
            padding: 3,
        },
        children: {
            width: '100%',
            padding: 3,
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            background: '#F5F7F8',
            borderRadius: '16px',
            border: 'none',
            boxShadow: '0px 0px 34px -9px #48484861',
            overflow: 'hidden',
            padding: 0,
        },
        overlay: {
            position: 'fixed',
            inset: 0,
            background: 'rgba(255,255,255,0.7)',
        },
        variants: {
            light: {
                title: {
                    fontWeight: 600,
                    padding: '24px',
                    borderBottom: '1px solid #E8EAEF',
                },
                content: {
                    background: '#fff',
                    padding: 0,
                },
                children: {
                    padding: '24px',
                }
            }
        }
    },
};

export default theme;
