export const TRANSLATIONS_EN = {
    login: {
        two_factor_error: 'The supplied code was invalid',
        two_factor_title: 'Two Factor Authentication',
    },
    buttons: {
        upload: 'Upload',
        save: 'Save changes',
        submit: 'Submit',
        login: 'Login',
        discard: 'Discard changes',
    },
    reports: {
        singular: 'Report',
        plural: 'Reports',
        subtitle:
            'Generate a comprehensive set of 12 reports providing the rationale behind the financial valuation determined.',
        summary:
            'The analysis comprises 12 reports including strategic, financial, and non-financial assessments; cash flow, sustainable growth, and credit assessment; benchmarking; profit gap analysis and non-financial KPI results; sale readiness and attractiveness; non-financial KPI commentary, and Valuation Summary.',
        noresults: 'There are no reports available',
        errors: {
            create: 'You are unable to create a Report for this Financial Year. Please complete the missing information:',
        },
        valuation: 'Please finalise the valuation before approving the report for advisor review',
        finalise: 'Lock Report (No Further Changes)'
    },
    addbacks: {
        title: 'Addbacks',
        subtitle: '',
    },
    ibisWorld: {
        menuItem: 'IBISWorld Industry Data',
        title: 'Industry at a Glance',
    },
    actionItems: {
        title: 'Action Items',
        subtitle: '',
    },
    benchmarking: {
        title: 'Benchmarking',
        subtitle: '',
    },
    upload: {
        default: {
            title: 'Upload an image',
            subtitle: 'Drop files here or click to select',
        },
        photo: {
            title: 'Upload your photo',
            subtitle: 'Photos help us recognise you in Capitaliz',
        },
        logo: {
            title: 'Upload the company logo',
            subtitle: 'Photos help us recognise you. By default your logo will appear on a white background. If your logo is white, it will appear on a black background to ensure visibility.',
            darkToggle: 'My logo is white and needs to appear on a black background.',
        },
    },
    settings: {
        title: '{{type}} Settings',
        subtitle:
            '',
        account: {
            subtitle:
                'You can adjust the company details and your personal details on this page, and upload your profile avatar.',
            companySubtitle: 'You can adjust the company details on this page.',
            userSubtitle: 'You can adjust your personal details on this page and upload your profile avatar.',
        },
        accountDetails: {
            title: 'Account Details',
            subtitle: 'Here you’ll find your account details.',
        },
        billing: {
            title: 'Membership & Billing',
            subtitle: 'Here you’ll find your membership and billing information for your organisation.',
        },
        notifications: {
            title: 'Notification Settings',
            subtitle:
                '',
        },
        permissions: {
            title: 'Accounts & Permissions',
            subtitle: 'You’ll find accounts within your organisations and relevant permission settings.',
        },
        organisation: {
            title: 'Organisation Settings',
            subtitle: 'Here you’ll find settings for your organisation.',
        },
        two_factor: {
            heading: 'Two Factor Authentication',
            subheading:
                'Add additional security to your account using two factor authentication. This will be used during your login experience only.',
            instructions: {
                qrCode: "Scan the QR code using your phone's Google Authenticator app.",
                recoveryCodes:
                    'Store these recovery codes in a secure password manager. They are single use and each can be used once to recover access to your account if your two factor authentication device is lost.',
            },
            modal: {
                heading: 'Two Factor Authentication',
                subheading:
                    "We need to confirm you've got your Google Authenticator setup correctly. Please supply the token once you're ready.",
            },
            pending: {
                heading: 'Two factor authentication is pending',
                subheading:
                    "When two factor authentication is enabled, you will be prompted for a secure, random token during login. You may retrieve this token from your phone's Google Authenticator application.",
                buttonText: 'Confirm setup',
                success: 'Please confirm your two factor authentication is enabled and supply the code',
                error: 'Unable to enable two factor authentication',
            },
            enabled: {
                heading: 'Two factor authentication is now enabled.',
                subheading:
                    "Now that two factor authentication is enabled, you will be prompted for a secure, random token during login. You may retrieve this token from your phone's Google Authenticator application. Remember that recovery codes can only be used once. It's recommended you regenerate and store new recovery codes if you use one.",
                buttonText: 'Disable',
                regenerateButtonText: 'Regenerate recovery codes',
                success: 'Two factor authentication enabled successfully',
                error: 'Unable to enable two factor authentication',
            },
            disabled: {
                heading: 'You have not enabled two factor authentication.',
                subheading:
                    "When two factor authentication is enabled, you will be prompted for a secure, random token during login. You may retrieve this token from your phone's Google Authenticator application.",
                buttonText: 'Enable',
                success: 'Two factor authentication disabled successfully',
                error: 'Unable to disable two factor authentication',
            },
            recoveryCodes: {
                success: 'New recovery codes have been generated',
                error: 'Unable to regenerate recovery codes',
            },
        }
    },
    companyOverview: {
        title: 'Dashboard',
        subtitle:
            'A high-level summary of your progression through the Capitaliz Business Insights Report preparation process, including a summary of tasks completed, pending and outstanding.',
        value_maximisation: 'Value maximisation for {{name}}',
    },
    companyDR: {
        title: 'Dynamic Revaluation',
        subtitle: 'A high-level summary of your progression through the Capitaliz Business Insights Report preparation process, including a summary of tasks completed, pending and outstanding.'
    },
    companyStages: {
        subtitle:
            '',
    },
    normalisation: {
        title: 'Normalisation',
        subtitle: '',
    },
    financialImports: {
        title: 'Financial Imports',
        subtitle:
            'This function enables you to upload and view financial statements including the Financial and Operations Balance Sheets, Profit & Loss, and Key Financial Ratios for multiple historic periods.',
    },
    companyCustomVariables: {
        title: 'Custom Variables',
        subtitle:
            'Custom Variables play a vital role in financial analysis, benchmarking, and valuation algorithms. This information is completed by our internal analysts, so you do not need to worry about it.',
    },
    scorecards: {
        title: 'Scorecards',
        subtitle: 'Insights Report rating system used by lenders, investors, buyers, and stakeholders',
    },
    valuations: {
        title: 'Valuation',
        subtitle:
            'Valuation is the last step in the analysis, where we leverage the assessments of risk and maintainable earnings to determine the value of the business.',
        cardTitle:
            'This function will produce a financial valuation report, which can be adjusted as required, but will be locked once finalised.',
    },
    financialHistory: {
        title: 'Financial History',
        subtitle:
            'This function enables you to upload and view financial statements including the Financial and Operations Balance Sheets, Profit & Loss, and Key Financial Ratios for multiple historic periods.',
    },
    reportsEdit: {
        title: 'Generate New Business Insights Report',
        subtitle:
            'You can customise the report by adding pages, disabling pages or reordering page sequence on the right-hand side. Once completed, please press “Submit Report” button to send the report to our Analyst for review.',
    },
    newCompany: {
        buttonText: 'Add Company',
        step1: {
            title: 'New Company',
            subtitle: "Tell us about the company. Please provide company's information to set up a new account.",
        },
        step2: {
            title: 'Add Company Details',
            subtitle: 'Please provide the business related information',
        },
        step3: {
            title: 'Add Company Address',
            subtitle: 'Please provide the address and contact information',
        },
        step5: {
            title: 'A new company profile has been created!',
            subtitle: '',
            invites: {
                title: 'Send invites to {{companyName}}',
                subtitle:
                    'Each user will recieve an invitation by email to create an account and set up their profile on Capitaliz. For existing users, they will be notified they now have access to view this company.',
                send: 'Once you have checked over the above list, click "Send Invites" button below',
            },
        },
    },
    location: {
        country: {
            US: 'United States',
            AU: 'Australia',
            CA: 'Canada',
            UK: 'United Kingdom',
            NZ: 'New Zealand',
        },
        state: {},
        businessRegistrationNumber: {
            US: 'EIN',
            AU: 'ABN/ACN',
            CA: 'BN',
            GB: 'CRN',
            NZ: 'NZBN',
        },
    },
    'Compliance Uptodate': 'Compliance Up-to-date',
    'Other (user defined risk)': 'Business Risk (non-financial)',
    'Risk Free Rate Of Return': 'Risk-Free Rate Of Return',
    'After Tax W A C C': 'After-Tax WACC',
    'Before Tax W A C C': 'Before-Tax WACC',
    'Inventory (wip) Days': 'Inventory (WIP) Days',
    'Non-current Liabilities (ex Debt)': 'Non-current Liabilities (ex-Debt)',
    'Nopat %': 'NOPAT %',
    Ecroce: 'ECROCE',
    'Mgmt Skills / Performance Record': 'Mgmt. Skills / Performance Record',
    'Stage Three: Maximise Value': 'Stage Three: Maximise Value',
    'Is functional responsibility spread appropriately among individuals in the organisation':
        'Is functional responsibility spread appropriately among individuals in the organisation?',
    'Actual Rent Paid': 'Actual rent paid (if different to market rate)',
};
