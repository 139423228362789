import { useEffect, useCallback } from 'react';

const isOutsideRef = (ref, el) => ref?.current && !ref.current.contains(el);

export const useMenuClose = (ref, callback, debug = false) => {

    const escFunction = useCallback(e => {
        if(e.keyCode === 27) {
            callback(e.keyCode);
        }
    }, [ callback ]);

    const clickEscFunction = useCallback(e => {
        if (process.env.NODE_ENV !== 'production' && debug) {
            console.log(
                'Clicked: ', e.target,
                'Ref: ', ref.current,
                'Close? ', !ref.current.contains(e.target)
            )
        }

        // Check if element clicked is outside all values within ref
        const isOutside = Array.isArray(ref) ? ref.every(r => isOutsideRef(r, e.target)) : isOutsideRef(ref, e.target);

        if (isOutside) {
            callback(e);
        }
    }, [ callback , ref, debug ]);

    return useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        document.addEventListener("mouseup", clickEscFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
            document.removeEventListener("mouseup", clickEscFunction, false);
        };
    }, [ escFunction, clickEscFunction ]);

};
