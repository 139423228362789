import { combineReducers } from 'redux';
import user from './storeUser';
import settings from './storeSettings';
import stages from './storeStages';
import task from './storeTask';
import tasks from './storeTasks';
import notifications from './storeNotifications';
import companies from './storeCompanies';
import organisation from './storeOrganisation';
import yearSelector from './storeYearSelector';
import ui from './storeUi';
import company from './storeCompany';
import analysts from './storeAnalysts';
import actionItems from './storeActionItems';

export default combineReducers({
    user,
    settings,
    stages,
    task,
    tasks,
    notifications,
    companies,
    organisation,
    yearSelector,
    ui,
    company,
    analysts,
    actionItems,
});
