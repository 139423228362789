import axios from 'axios'

export const uploadFinancialReports = (financials) =>
    axios.post('analyst/financial/upload', financials)
        .then(({ data }) => data)

export const getCompanyFinancialReports = (company) =>
    axios.post('company/financial/get', company)
        .then(({ data }) => data)


export const uploadFinancialImports = (financials) =>
    axios.post('advisor/financial-import/upload', financials)
        .then(({ data }) => data)

export const getCompanyFinancialImports = (company) =>
    axios.post('advisor/financial-import/get', company)
        .then(({ data }) => data)

export const updateCompanyFinancialImports = (company) =>
    axios.post('advisor/financial-import/update', company)
        .then(({ data }) => data)

export const normaliseCompanyFinancialImports = (company) =>
    axios.post('advisor/financial-import/normalise', company)
        .then(({ data }) => data)

export const finaliseCompanyFinancialImports = (company) =>
    axios.post('advisor/financial-import/finalise', company)
        .then(({ data }) => data)

export const getCompanyFinancialImportComments = (companyId, sectionName) =>
    axios.post('advisor/financial-import/comment/get', { companyId, sectionName })
        .then(({ data }) => data)

export const createCompanyFinancialImportComments = (companyId, sectionName, comment) =>
    axios.post('advisor/financial-import/comment/create', { companyId, sectionName, comment })
        .then(({ data }) => data)

export const downloadCompanyFinancialImports = (importId, companyId) =>
    axios.request({ url: `advisor/financial-import/download`, method: 'POST', responseType: 'blob', data: { id: importId, companyId } })
        .then(({ data }) => data)

export const importCodat = (company) =>
    axios.get(`advisor/financial-import/${company?.id}/codat`)
        .then(({ data }) => data)