import React from 'react';
import { Box } from 'rebass';
import { useTranslation } from "react-i18next";
import { getCompanyStatus, stepStatuses } from '@util/Enum/CompanyStatus';
import { exitAssessmentStatuses } from '@util/Enum/ExitAssessmentStatus';

export const StatusTag = ({ sx, status, type = 'company', inReview = false, overdue = false, inactive = false, archived = false, absolute = false, ...props }) => {
    const { t } = useTranslation();

    const statusInfo = () => {
        switch (type) {
            case 'step':
                return stepStatuses?.[status] ?? {}
            case 'exit':
                return exitAssessmentStatuses?.[status] ?? {}
            default:
                return getCompanyStatus(status, inReview, overdue, inactive, archived)
        }
    }

    return (
        <Box
            {...props}
            sx={{
                ...(absolute ? {
                    position: 'absolute',
                    top: 0,
                    left: '20px',
                    borderRadius: '0px 0px 8px 8px',
                    padding: '6px 10px 4px 10px',
                } : {}),
                ...statusInfo().sx,
                ...sx,
            }}
            variant="statusTag"
        >
            {t(statusInfo().name)}
        </Box>
    )
};
