import { addCompanyNopatValue } from "@app/Api/Advisor";
import { Input, Textarea } from "@rebass/forms";
import { Icon } from "@svg/Icon";
import { currencySymbol, formatCurrency, formatDateMonth } from "@util/Functions";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Flex } from "rebass";
import { List } from "~/Common/List";
import { Modal } from "~/Common/Modal";
import { TabCard } from "~/Common/TabCard";
import Tooltip from "~/Common/Tooltip";

const ValuationListBase = ({ items, ...props }) => {
    const { t } = useTranslation();

    return Array.isArray(items) ? (
        <List items={items} {...props}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 600,
                p: '16px',
                height: '100%'
            }}>
                {t('No items to show')}
            </Box>
        </List>
    ) : null;
}

const NopatList = ({ items, ...props }) => <ValuationListBase
    items={Array.isArray(items) ? items.map((i) => {
        const description = <Flex alignItems="center">
            {i.comment && <Tooltip text={i.comment} boxSx={{ lineHeight: '1', mr: '4px' }}>
                <Icon icon="questionMark3" />
            </Tooltip>}
            {i.updated && `Updated: ${formatDateMonth(i.updated)}`}
        </Flex>

        return {
            icon: 'nopat',
            iconBackground: '#16E4B3',
            iconColor: '#2C2960',
            title: i.value,
            description: description,
        }
    }) : []}
    {...props}
/>

const ValuationList = ({ items, ...props }) => <ValuationListBase items={Array.isArray(items) ? items.map((i) => ({
    icon: 'revaluation',
    iconBackground: '#2C2960',
    iconColor: '#fff',
    title: i.value,
    description: i.year
})) : []} {...props} />

export const ValuationTabs = ({ companyId, data, currency = 'AUD', afterUpdate }) => {
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const [value, setValue] = useState('');
    const [comment, setComment] = useState('');
    const [valuationData, setValuationData] = useState(null);
    const [nopatData, setNopatData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [permissions, setPermissions] = useState([]);

    const requestClose = () => setShowModal(false);

    const handleAddNopatValue = () => {
        setLoading(true);
        addCompanyNopatValue(companyId, { nopat: value, comment: comment })
            .then(({ data }) => {
                setNopatData(data?.nopat?.values);
                setPermissions(data?.nopat?.permissions)
                setShowModal(false);
                afterUpdate && afterUpdate(data?.dynamicRevaluation?.graph)
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        setNopatData(data?.nopat?.values);
        setValuationData(data?.valuations)
        setPermissions(data?.nopat?.permissions)
    }, [data])

    return (
        <>
            <TabCard
                tabs={[
                    {
                        key: 'netProfit',
                        name: 'Net Operating Profit',
                        icon: 'nopat',
                        component: NopatList,
                    },
                    {
                        key: 'businessValuation',
                        name: 'Business Valuation',
                        icon: 'revaluation',
                        component: ValuationList,
                    },
                ]}
                tabProps={{
                    netProfit: {
                        items: nopatData
                    },
                    businessValuation: {
                        items: valuationData
                    }
                }}
                contentHeight={320}
                sx={{
                    overflow: 'visible'
                }}
            >
                <Box sx={{ display: 'flex', gap: '8px', fontSize: '10px', alignItems: 'center' }}>
                    <Tooltip text={t('This is the projected FY NOPAT (normalised).')} boxSx={{ lineHeight: '1' }}>
                        <Icon icon="questionMark3" />
                    </Tooltip>
                    {t('Update Net Operating Profit')}
                </Box>
                {permissions?.create && <Button onClick={() => setShowModal(true)}>{t('Update Value')}</Button>}
            </TabCard>
            <Modal
                isOpen={showModal}
                flex={true}
                flexDirection="column"
                maxWidth={420}
                variant="light"
                title={t('Update Net Operating Profit')}
                requestClose={requestClose}
            >
                <Input
                    value={formatCurrency(value, currency, 0)}
                    placeholder={currencySymbol(null)}
                    onChange={(e) => setValue(e.target.value.replace(/\D/g, ''))}
                />
                <Textarea
                    value={comment}
                    placeholder={t('Reason for update')}
                    onChange={(e) => setComment(e.target.value)}
                    sx={{
                        resize: 'none',
                        height: '200px'
                    }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <Button variant="secondary" onClick={requestClose} disabled={isLoading}>Cancel</Button>
                    <Button variant="primary" onClick={handleAddNopatValue} disabled={isLoading}>Update Value</Button>
                </Box>
            </Modal>
        </>
    )
}
