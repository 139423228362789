import axios from 'axios'

export const listValuations = (valuation) =>
    axios.post('company/valuation/list', valuation)
        .then(({ data }) => data?.data)

export const getValuation = (valuation) =>
    axios.post('company/valuation/get', valuation)
        .then(({ data }) => data)

export const updateValuation = (valuation) =>
    axios.post('advisor/valuation/update', valuation)
        .then(({ data }) => data)

export const createValuation = (valuation) =>
    axios.post('advisor/valuation/create', valuation)
        .then(({ data }) => data)

export const overrideValuation = (valuationId, data) =>
    axios.post(`advisor/valuation/${valuationId}/override`, data)
        .then(({ data }) => data)