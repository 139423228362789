import { Box, Flex, Heading } from "rebass"
import React from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { isAdmin } from "@util/Functions"
import { Button } from "~/Common/Button"
import { useTranslation } from "react-i18next"

const AdminError = ({ user }) => {
    const { t } = useTranslation();

    if (user && !isAdmin(user)) {
        return <Redirect to="/" />
    }

    return (
        <Box mt={6} textAlign='center'>
            <Heading>{t('You are logged in as an admin')}</Heading>
            <Box mt={5} mb={2}>{t('Please ensure you logged out of the Admin application before proceeding:')}</Box>
            <Flex sx={{
                mt: 4,
                alignItems: 'center',
                justifyContent: 'center',
                gridGap: 3,
                color: '#7e7e7e'
            }}>
                <Button
                    to={`${process.env.REACT_APP_API_BASE_URL}/nova/logout`}
                    variant="secondary"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                        setTimeout(() => {
                            window.location.href = process.env.PUBLIC_URL
                        }, 500);
                    }}
                >
                    {t('Logout & Continue')}
                </Button>
                <Box>- {t('or')} -</Box>
                <Button
                    to={`${process.env.REACT_APP_API_BASE_URL}/nova`}
                    variant="secondary"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {t('Open Nova Portal')}
                </Button>
            </Flex>
        </Box>
    )
}

export default connect((state) => ({ user: state.user }))(AdminError)
