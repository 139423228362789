import React, { useState } from 'react'
import { uploadFinancialImports } from '@app/Api'
import { toast } from 'react-toastify'
import LoadingSpinner from '~/LoadingSpinner'
import { getErrorMessage } from '@util/Functions'
import { connect } from 'react-redux'
//import { isAnalyst } from '@util/Functions/User'
import FinancialImports from '~/Dashboard/FinancialImports'

const AdvisorFinancialImports = ({ companyId, user }) => {
    const [loading, setLoading] = useState(false)

    //const canUploadFinancials = isAnalyst(user)

    if (loading) return <LoadingSpinner />

    const handleUpload = (attachment) => {
        if (attachment) {
            setLoading(true)
            const formData = new FormData()
            formData.append('attachment', attachment)
            formData.append('companyId', companyId)
            uploadFinancialImports(formData)
                .then(() => toast.success('Financials imported successfully'))
                .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to upload financials')))
                .finally(() => setLoading(false))
        }
    }

    return <FinancialImports companyId={companyId} uploadFinancials={handleUpload} />
}

export default connect((state) => ({ user: state.user }))(AdvisorFinancialImports);