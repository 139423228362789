import React, { useEffect } from 'react';
import { Text, Box, Flex } from 'rebass';
import { useDashboard } from '@hooks/useDashboard';

export const PageTitle = ({ title, subtitle, children, ...props }) => {
    const { setTitle } = useDashboard();

    useEffect(() => {
        if (title) {
            setTitle(title);
        }
        return () => setTitle(null);
    }, [title, setTitle])

    if (!subtitle && !children) {
        return null;
    }

    return (
        <Flex
            sx={{
                fontFamily: 'OpenSauceTwo, Arial',
                mt: 0,
                mb: '40px',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
            }}
            {...props}
        >
            <Box>
                {subtitle && (
                    <Text
                        as="h2"
                        fontSize="16px"
                        color="blueDark"
                        mt={title ? 2 : 0}
                        fontWeight="400"
                        maxWidth="690px"
                        dangerouslySetInnerHTML={{ __html: subtitle }}
                        sx={{
                            '& p:first-of-type': {
                                mt: 0,
                            },
                            '& p:last-child': {
                                mb: 0,
                            }
                        }}
                    />
                )}
            </Box>

            {children && (
                <Box
                    sx={{
                        '& select': {
                            padding: '10px 30px 10px 16px',
                            minWidth: '200px',
                            fontSize: 14,
                            fontWeight: 600,
                        }
                    }}
                >
                    {children}
                </Box>
            )}
        </Flex>
    );
};
