import React, { useState, useEffect, useMemo } from 'react';
import { Flex, Box, Heading, Button } from 'rebass';
import { toast } from 'react-toastify';
import { downloadTemplate, getErrorMessage, formatCurrency } from '@util/Functions';
import { Icon } from '@svg';
import { PopupStageDetails } from '~/Dashboard/TaskView';
import LoadingSpinner from '~/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { BackButton } from '~/Common/BackButton';
import { getTaskStatus } from '@util/Enum';
import { getBenchmarkings, setBenchmarkings as setBenchmarkingsRequest } from '@app/Api';
import TaskStatusChange from '~/Dashboard/TaskStatusChange';
import { Input } from '@rebass/forms';
import CommentModule from '~/Common/CommentModule';
import { Modal } from '~/Common/Modal';
import Tooltip from '~/Common/Tooltip';
import { Form } from '~/Common/Form';

const BenchmarkingTaskView = ({ initTask, companyId, accreditedAdvisor, backLink, backLabel, templates }) => {
    const [loading, setLoading] = useState(true);
    const [task, setTask] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [commentSectionUuid, setCommentUuid] = useState('');
    const [currency, setCurrency] = useState('AUD');
    const [benchmarkings, setBenchmarkings] = useState({});
    const [categories, setCategories] = useState([]);
    const [items, setItems] = useState([]);
    const [id, setId] = useState(null);
    const [errors, setErrors] = useState([]);

    const { sx: statusStyle, name: statusName } = getTaskStatus(task.status);
    const postTaskStatusUpdate = (status) => setTask({ ...task, ...{ status } });
    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true);
        setTask(initTask);
        getBenchmarkings(companyId)
            .then((response) => {
                setBenchmarkings(response?.benchmarkings?.data);
                setId(response?.benchmarkings?.id);
                setCategories(response?.categories);
                setItems(response?.items);
                setCurrency(response?.currency);
                setErrors([]);
            })
            .catch(({ response }) => {
                setErrors(response?.data?.errors);
                toast.error(getErrorMessage(response?.data, 'Unable to get benchmarkings'));
            })
            .finally(() => setLoading(false));
    }, [companyId, initTask]);

    const amount = useMemo(() => {
        const direct = Number(benchmarkings?.[2]?.[2]);
        const indirect = Number(benchmarkings?.[3]?.[2]);
        const numeric = !isNaN(indirect) && !isNaN(direct);

        return numeric ? formatCurrency(direct + indirect, currency) : null;
    }, [benchmarkings, currency]);

    const employees = useMemo(() => {
        const direct = Number(benchmarkings?.[2]?.[1]);
        const indirect = Number(benchmarkings?.[3]?.[1]);
        const numeric = !isNaN(indirect) && !isNaN(direct);

        return numeric ? (direct + indirect) : null;
    }, [benchmarkings]);

    if (loading) return <LoadingSpinner />;

    const handleBenchmarkingSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setBenchmarkingsRequest(companyId, benchmarkings)
            .then((response) => {
                setBenchmarkings(response?.benchmarkings?.data);
                setId(response?.benchmarkings?.id);
                setCategories(response?.categories);
                setItems(response?.items);
                setErrors([]);
                toast.success('Benchmarking questionnaire updated successfully');
            })
            .catch(({ response }) => {
                setErrors(response?.data?.errors);
                toast.error(getErrorMessage(response?.data, 'Unable to get benchmarkings'));
            })
            .finally(() => setLoading(false));
    };

    const getBenchmarkingValue = (itemId, categoryId) => {
        return benchmarkings?.[itemId]?.[categoryId];
    };

    const setBenchmarkingValue = (value, itemId, categoryId) => {
        const newBenchmarkings = { ...benchmarkings };
        newBenchmarkings[itemId][categoryId] = value;
        setBenchmarkings(newBenchmarkings);
    };

    return (
        <Box mt={4}>
            <BackButton to={backLink} label={backLabel} pl={3} />

            <Modal isOpen={modalOpen}>
                <PopupStageDetails
                    triggerClose={() => setModalOpen(false)}
                    details={t(task.description)}
                    name={t(task.stageName)}
                />
            </Modal>

            <Flex width={1}>
                <Box width={1 / 3}>
                    <Box variant="card.wrapper" p={30}>
                        <Box
                            sx={{
                                letterSpacing: '0.5px',
                                textTransform: 'uppercase',
                                fontSize: '10px',
                                fontWeight: 500,
                                marginBottom: 2,
                            }}
                        >
                            {t(task.stageName)}
                        </Box>
                        <Box mb={3} fontSize={18} fontWeight={700}>
                            {t(task.name)}
                        </Box>
                        <Box mb={3}>
                            {accreditedAdvisor && (
                                <Flex alignItems="center">
                                    <Box mr={2}>Change Status</Box>
                                    <TaskStatusChange
                                        taskStatus={task.status}
                                        companyTaskId={task.id}
                                        notifyUpdated={postTaskStatusUpdate}
                                    />
                                </Flex>
                            )}
                            {!accreditedAdvisor && (
                                <Button variant="task" mb={3} width={100} p={2} sx={statusStyle}>
                                    {statusName}
                                </Button>
                            )}
                        </Box>
                        <Box
                            variant="clickable"
                            fontSize={12}
                            sx={{ color: '#6F7782' }}
                            onClick={() => setModalOpen(true)}
                        >
                            {t(task.description)}
                        </Box>
                    </Box>
                    <Box variant="card.wrapper">
                        <Box variant="card.header">
                            <Flex>
                                <Heading>Useful Templates</Heading>
                                <Box ml={'auto'}>
                                    <Icon icon="download" title="Download Templates" stroke="#00AEEF" />
                                </Box>
                            </Flex>
                        </Box>
                        <Box variant="card.container">
                            {templates?.map((att) => (
                                <Box pb={3} key={att.id}>
                                    <Box variant="attachmentLink" fontSize={12} onClick={() => downloadTemplate(att)}>
                                        {att.fileName}
                                    </Box>
                                </Box>
                            ))}
                            {!templates?.length && <Box>There are no templates for this task</Box>}
                        </Box>
                    </Box>
                </Box>
                <Box width={2 / 3}>
                    <Box variant="card.wrapper">
                        <Flex variant="card.header" alignItems="center">
                            <Heading>Benchmarking Questionnaire</Heading>
                        </Flex>
                        <Box variant="card.container">
                            <Form onSubmit={handleBenchmarkingSubmit}>
                                <table className={styles.dataTable}>
                                    <thead>
                                        <tr>
                                            <td>Employment Information (please complete using your Payroll Reports)</td>
                                            {categories.map((category, index) => (
                                                <td key={index}>
                                                    {t(category.name)}{' '}
                                                    {category.tooltip && (
                                                        <Tooltip text={t(category.tooltip)} icon="questionMark" />
                                                    )}
                                                </td>
                                            ))}
                                            <td></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map((item, index) => [
                                            <tr key={index}>
                                                <td>
                                                    {t(item.name)}{' '}
                                                    {item.tooltip && (
                                                        <Tooltip text={t(item.tooltip)} icon="questionMark" />
                                                    )}
                                                </td>
                                                {categories.map((category, catIndex) => {
                                                    const value = getBenchmarkingValue(item.id, category.id);
                                                    return <td key={catIndex}>
                                                        {typeof value !== 'undefined' && <>
                                                            <Input
                                                                value={value ? value : ''}
                                                                placeholder={category.placeholder}
                                                                onChange={(event) =>
                                                                    setBenchmarkingValue(
                                                                        event.target.value,
                                                                        item.id,
                                                                        category.id
                                                                    )
                                                                }
                                                                sx={{
                                                                    textAlign: 'right',
                                                                    '::placeholder': {
                                                                        textAlign: 'left',
                                                                    },
                                                                }}
                                                            />
                                                            {errors?.[`benchmarkings.${item.id}.${category.id}`] && (
                                                                <Box
                                                                    mt={-18}
                                                                    mb={20}
                                                                    color="red"
                                                                    fontWeight="normal"
                                                                    fontSize={12}
                                                                >
                                                                    {
                                                                        errors[
                                                                        `benchmarkings.${item.id}.${category.id}`
                                                                        ][0]
                                                                    }
                                                                </Box>
                                                            )}</>}
                                                    </td>
                                                })}
                                                <td>
                                                    <Flex
                                                        variant="clickable"
                                                        alignItems="center"
                                                        onClick={() =>
                                                            setCommentUuid(
                                                                item.uuid === commentSectionUuid ? '' : item.uuid
                                                            )
                                                        }
                                                    >
                                                        <Icon icon="comment" />
                                                    </Flex>
                                                </td>
                                            </tr>,
                                            item.uuid === commentSectionUuid && (
                                                <tr key={`${item.uuid}-comment-section`}>
                                                    <td colSpan="99">
                                                        <CommentModule type="benchmarkings" id={id} uuid={item.uuid} />
                                                    </td>
                                                </tr>
                                            ),
                                        ])}
                                        <tr>
                                            <td>Total</td>
                                            <td>{amount ? amount : <>&mdash;</>}</td>
                                            <td>{employees ? employees : <>&mdash;</>}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <Flex mt={3}>
                                    <Button ml="auto">Save</Button>
                                </Flex>
                            </Form>
                        </Box>
                    </Box>
                </Box>
            </Flex>
        </Box>
    );
};

export default BenchmarkingTaskView;
