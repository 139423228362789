import theme from "@app/theme"
import { Icon } from "@svg/Icon"
import React from "react"
import ReactModal from "react-modal"
import { Box, Flex, Heading, Link } from "rebass"
import { css } from "theme-ui"
import "./styles.css"

export const Modal = ({
    children,
    isOpen,
    requestClose,
    ariaHideApp = false,
    shouldCloseOnEsc = true,
    showClose,
    zIndex = 1000,
    style,
    overlay = true,
    flex = false,
    minWidth = 500,
    maxWidth = 900,
    maxHeight = '90vh',
    minHeight,
    overflow,
    flexDirection,
    gap,
    title,
    icon,
    sx,
    titleSx,
    childSx,
    variant,
    footer,
    ...props
}) => (
    <ReactModal
        {...props}
        closeTimeoutMS={300}
        isOpen={isOpen}
        ariaHideApp={ariaHideApp}
        onRequestClose={requestClose}
        shouldCloseOnEsc={shouldCloseOnEsc}
        shouldCloseOnOverlayClick={shouldCloseOnEsc}
        style={{
            ...style,
            content: {
                ...css(theme.modal.content)(theme),
                ...css(theme.modal?.variants?.[variant]?.content)(theme),
                ...(flex ? {
                    width: '100%',
                } : {}),
                minWidth,
                maxWidth,
                minHeight,
                maxHeight,
                overflow,
                ...css(sx)(theme),
                ...css(style?.content)(theme),
            },
            overlay: {
                ...css(theme.modal.overlay)(theme),
                ...css(theme.modal?.variants?.[variant]?.overlay)(theme),
                zIndex,
                ...(overlay !== true ? {
                    background: typeof overlay === 'string'
                        ? overlay
                        : 'transparent',
                } : {}),
                ...css(style?.overlay)(theme),
            },
        }}
    >
        {(title || icon || showClose) && (
            <Flex sx={{
                ...css(theme.modal.title)(theme),
                ...css(theme.modal?.variants?.[variant]?.title)(theme),
                ...titleSx
            }}>
                {icon && <Icon icon={icon} title={title} />}
                {title && <Heading>{title}</Heading>}
                <Box flex={1} />
                {showClose !== false && (
                    <Link as="a" href="#close" variant="circleButton" onClick={requestClose}>
                        <Icon icon="close" stroke="#656D78" title="Close Popup" />
                    </Link>
                )}
            </Flex>
        )}
        <Box sx={{
            ...css(theme.modal.children)(theme),
            ...css(theme.modal?.variants?.[variant]?.children)(theme),
            ...(flex ? {
                display: 'flex',
            } : {}),
            flexDirection,
            gap,
            ...childSx
        }}>
            {children}
        </Box>
        {footer}
    </ReactModal>
)
