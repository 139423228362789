import axios from 'axios'

export const getCompanyAddbacks = (companyId) =>
    axios.get(`company/${companyId}/addbacks`)
        .then(({ data }) => data)

export const getClassifiedAddbacks = (companyId) =>
    axios.get(`company/${companyId}/classified-addbacks`)
        .then(({ data }) => data)

export const setCompanyAddbacks = (companyId, addbacks, normalisation) =>
    axios.post(`company/${companyId}/addbacks`, { addbacks, normalisation })
        .then(({ data }) => data)
