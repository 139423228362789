import { Icon } from '@svg/Icon';
import { isAdvisor, isAnalyst } from '@util/Functions';
import { isCeo, isSupervisor } from '@util/Functions/User';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Button, Flex, Heading } from 'rebass';

const SettingsCard = ({ title, icon, subtitle, button }) => {
    return (
        <Box width={[1 / 1, '', '', 1 / 2, 1 / 3, 1 / 4]} mb="30px">
            <Flex
                variant="card.wrapper"
                sx={{
                    bg: '#fff',
                    minHeight: '100%',
                    flexDirection: 'column',
                    p: 20,
                    fontSize: '14px',
                    fontWeight: 400,
                }}
            >
                <Flex alignItems="center" mb="15px">
                    <Box width={2 / 3}>
                        <Heading
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                                lineHeight: '18px',
                                fontWeight: 600,
                                fontSize: '16px',
                            }}
                        >
                            {icon && <Icon icon={icon} color="#9594AF" />}
                            {title}
                        </Heading>
                    </Box>
                </Flex>
                {subtitle}

                {button && (
                    <Flex mt="auto">
                        <Link to={button?.to} target={button?.target} style={{ display: 'block', width: '100%' }}>
                            <Button variant="gray" flex={1} width={1}>
                                {button?.title}
                            </Button>
                        </Link>
                    </Flex>
                )}
            </Flex>
        </Box>
    );
};

const SettingsCards = ({ company, user, show }) => {
    const { t } = useTranslation();
    const accreditedAdvisor = isAdvisor(user) || isAnalyst(user);
    const routePrefix = accreditedAdvisor ? '/advisor/settings' : '/company/profile/settings';

    return (
        <Flex sx={{ flexWrap: 'wrap' }}>
            <SettingsCard
                icon="userCircle"
                title={t('settings.accountDetails.title')}
                subtitle={t('settings.accountDetails.subtitle')}
                button={{
                    title: 'Edit your Account Details',
                    to: `${routePrefix}/account`,
                }}
            />
            {(isCeo(user) || isSupervisor(user)) && (
                <SettingsCard
                    icon="users"
                    title={t('settings.permissions.title')}
                    subtitle={t('settings.permissions.subtitle')}
                    button={{
                        title: 'Edit Accounts & Permissions',
                        to: `/organisation/settings/permissions`,
                    }}
                />
            )}
            {/*
            <SettingsCard
                icon="bell2"
                title={t('settings.notifications.title')}
                subtitle={t('settings.notifications.subtitle')}
                button={{
                    title: 'Edit Notifications',
                    to: `${routePrefix}/notifications`,
                }}
            />
            */}
            {(isCeo(user) || isSupervisor(user)) && <SettingsCard
                    icon="currency"
                    title={t('settings.billing.title')}
                    subtitle={t('settings.billing.subtitle')}
                    button={{
                        title: 'View Payment Information',
                        to: `${routePrefix}/billing`,
                    }}
                />
            }
            {isCeo(user) && <SettingsCard
                    icon="staff"
                    title={t('settings.organisation.title')}
                    subtitle={t('settings.organisation.subtitle')}
                    button={{
                        title: 'Edit Organisation',
                        to: `/organisation/settings/edit`,
                    }}
                />
            }
        </Flex>
    );
};

export default connect((state) => ({ user: state.user, company: state.company }))(SettingsCards);
