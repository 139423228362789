import moment from "moment"

export const timeFormat = 'YYYY/MM/DD HH:mm:ss';

export const dateFromString = (str, fallback = '') => (str && moment(str).isValid() ? moment(str).toDate() : fallback);

export const formatDate = (dateTime, fallback = '', format = 'DD/MM/YYYY') => (dateTime ? moment(dateTime, timeFormat).format(format) : fallback);

export const formatDateYear = (d, f) => formatDate(d, f, 'YYYY')
export const formatDateMonthYear = (d, f) => formatDate(d, f, 'MMMM YYYY')
export const formatDateMonth = (d, f) => formatDate(d, f, 'D MMMM YYYY')
export const formatDatePretty = (d, f) => formatDate(d, f, 'dddd MMMM D')
export const formatDateWeekday = (d, f) => formatDate(d, f, 'dddd, DD MMMM YYYY')
export const formatDateFull = (d, f) => formatDate(d, f, 'HH:mm A | dddd MMMM D');

export const formatDateDiff = (dateTime, fallback = '') => {
    if (!dateTime) return fallback

    const days = daysOld(dateTime)

    if (days === 0) return 'Today'
    if (days === 1) return 'Yesterday'
    return `${days} days ago`
}

const daysOld = (dateTime) => {
    const now = moment(new Date())
    const originalDate = moment(dateTime, timeFormat)
    return Math.round(moment.duration(now.diff(originalDate)).asDays())
}

export const currentYear = new Date().getFullYear();

export const currentFinancialYear = new Date().getFullYear() - (new Date().getMonth() + 1 < 7 ? 1 : 0)

export const yearsList = (totalYears = 6, yearOffset = 1, useFinancialYear = false, shortYear = false) => {
    // TODO: Fix fiscal years
    let startYear = useFinancialYear ? currentFinancialYear + 1 : currentYear + 1
    return new Array(totalYears).fill().map((val, key) => {
        const year = startYear + key - (totalYears - yearOffset);
        return { label: year, value: shortYear ? year - 2000 : year }
    }).reverse()
}

export const monthListSmall = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
export const monthList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
