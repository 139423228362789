import React, { useState, useEffect } from 'react'
import LoadingSpinner from '~/LoadingSpinner'
import { getCompanySettings } from "@app/Api"
import { toast } from 'react-toastify'
import SettingsRender from '~/Dashboard/Settings'
import { getErrorMessage } from '@util/Functions'
import { setCompany } from '@app/Store'
import { connect } from 'react-redux'

const AdvisorCompanySettings = ({ companyId, setCompany }) => {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (companyId) {
            setLoading(true)
            getCompanySettings(companyId)
                .then((company) => {
                    setCompany(company)
                    setLoading(false)
                })
                .catch(({ response }) =>
                    toast.error(getErrorMessage(response?.data, 'Unable to load settings data'), { toastId: 'getCompanySettingsError' }))
        }
    }, [companyId, setCompany])

    return loading ? <LoadingSpinner /> : <SettingsRender show={{ user: false, company: true }} />
}

export default connect(null, { setCompany })(AdvisorCompanySettings)
