import React, { useState, useEffect, useRef } from 'react';
import { validateCompanyInvite, assignCompanyUsers, getCompanyStages, removeCompanyUser } from '@app/Api';
import { toast } from 'react-toastify';
import { Card } from '~/Common/Card';
import { Accordion } from '~/Common/Accordion';
import { Flex, Box, Button } from 'rebass';
import '../styles.module.scss';
import { TextField } from '~/Form';
import { Checkbox, Radio, Label } from '@rebass/forms';
import { Icon } from '@svg';
import UserAvatar from '~/Dashboard/Avatar';
import LoadingSpinner from '~/LoadingSpinner';
import { connect } from 'react-redux';
import { setCompany } from '@app/Store';
import axios from 'axios';
import { Form } from '~/Common/Form';
import { Confirm } from '~/Common/Confirm';
import { useTranslation } from 'react-i18next';
import { Modal } from '~/Common/Modal';
import theme from "@app/theme";

const CompanyUsers = ({ company, user, setCompany }) => {
    const [modalForm,setModalForm] = useState(null)
    const [loading, setLoading] = useState(true);
    const [inviteErrors, setInviteErrors] = useState({});
    const [invites, setInvites] = useState([]);
    const [invitesConsultant, setInvitesConsultant] = useState([]);
    const [email, setEmail] = useState('');
    const [permissionType, setPermissionType] = useState('all');
    const [isConsultant, setIsConsultant] = useState(false);
    const [onboardingStages, setOnboardingStages] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [companyStages, setCompanyStages] = useState([]);
    const [companyRemoveUserConfirm, setCompanyRemoveUserConfirm] = useState(false);
    const addUserRef = useRef(null);
    const { t } = useTranslation();

    const smoothScroll = () => {
        window.scrollTo({ behavior: 'smooth', top: addUserRef.current.offsetTop });
    };
    
    useEffect(() => {
        if (company && user?.permissions?.companies?.assignUsers) {
            axios
                .all([
                    getCompanyStages({
                        companyId: company.id,
                        onboardingStages: true,
                    }),
                    getCompanyStages({
                        companyId: company.id,
                        onboardingStages: false,
                    }),
                ])
                .then(
                    axios.spread((onboardingResponse, companyResponse) => {
                        setOnboardingStages(onboardingResponse?.data ?? []);
                        setCompanyStages(companyResponse?.data ?? []);
                    })
                )
                .catch(() => {
                    toast.error('Unable to grab the company stages', { toastId: 'company-stages-error' });
                })
                .finally(() => setLoading(false));
        }
    }, [company, user]);

    const updateInvites = (e) => {
        e.preventDefault();

        setLoading(true);
        setInviteErrors({});

        validateCompanyInvite({
            email: email,
            tasks: permissionType === 'limited' ? tasks : null,
            isConsultant: isConsultant,
        })
            .then((response) => {
                if (response.hasOwnProperty('success') && response.success) {
                    if(isConsultant){
                        setInvitesConsultant([...invitesConsultant, response?.data]);
                    }else{
                        setInvites([...invites, response?.data]);
                    }
                    setEmail('');
                    setPermissionType('all');
                    setTasks([]);
                    setIsConsultant(false);
                }
            })
            .catch(({ response }) => {
                response?.data?.errors && setInviteErrors(response.data.errors);
                toast.error('Unable to add this email to the invite list', {
                    toastId: 'addCompany',
                });
            })
            .finally(() => {
                setLoading(false);
                setModalForm(false);
            });

        smoothScroll();
    };

    const removeInvite = (removeIndex,isConsultant) => {
        setLoading(true);
        const newInvites = (isConsultant ? invitesConsultant : invites).filter((item, index) => index !== removeIndex);
        if(isConsultant){
            setInvitesConsultant(newInvites);
        }else{
            setInvites(newInvites);
        }
        setLoading(false);
    };

    const sendInvites = (e,isConsultantUser) => {
        e.preventDefault();

        setLoading(true);
        setInviteErrors({});

        assignCompanyUsers(company.id, isConsultantUser ? invitesConsultant : invites)
            .then((response) => {
                isConsultantUser ? setInvitesConsultant([]) : setInvites([]);
                setEmail('');
                setPermissionType('all');
                setTasks([]);
                setIsConsultant(false);
                setCompany(response.company);
                toast.success(response.message);
            })
            .catch(({ response }) => {
                response?.data?.errors && setInviteErrors(response.data.errors);
                toast.error('Unable to assign users to the company');
            })
            .finally(() => setLoading(false));
    };

    const handlePermissionTask = (e) => {
        if (e.currentTarget.checked) {
            setTasks([...tasks, e.currentTarget.value]);
        } else {
            setTasks(tasks.filter((id) => id !== e.currentTarget.value));
        }
    };

    const handleRemoveCompanyUser = () => {
        if (!companyRemoveUserConfirm) {
            return;
        }

        removeCompanyUser(company.id, {
            userId: companyRemoveUserConfirm.id,
        })
            .then(({ data }) => {
                setCompany(data);
                setCompanyRemoveUserConfirm(false);
                toast.success(t('Successfully revoked access for user'));
            })
            .catch(({ response }) => {
                response?.data?.errors && setInviteErrors(response.data.errors);
                toast.error('Unable to remove user');
            })
            .finally(() => setLoading(false));
    };

    const openModalHandler = (type) =>{
        setModalForm(true);
        if(type === "consultant"){
            setIsConsultant(true)
        }else{
            setIsConsultant(false)
        }
    }
    const buttonFooterStyles = {
        position: "relative",
        bottom: '-16px',
        justifyContent: 'flex-end',
        left: '-22px',
        background: 'white',
        width: '108%',
        padding: '12px',
        borderTop: '1px solid #ededed',
      };

    const Users = ({ title, users, showTasks }) => <Card title={title} style={{background:"white"}} containerProps={{style:{padding:0}}} >
        <Box style={{ padding:"0px 30px", color: theme.colors.captionGray, fontSize:"12px", margin:"-10px 0 16px 0"}}>
            {showTasks ? "A company user is an employee within your business that you would like to have access to your file to work on projects, etc."
            : "A consultant user is an external party ( accountant, lawyer, business coach, board member, etc) whom you would like to be able to contribute to projects."
            }
        </Box>
        <Box style={{background:"#F5F7F8", padding:"24px 16px 8px 16px"}}>
            {users.length > 0 ? (
                <>
                    {users.map((user, index) => (
                        <Flex justifyContent="flex-start" mb={3} key={index}>
                            <Flex justifyContent="flex-start" alignItems="center">
                                <UserAvatar
                                    firstName={user?.firstName}
                                    surname={user?.surname}
                                    email={user?.email}
                                    avatar={user.avatar ? user.avatar : false}
                                    mr="12px"
                                />
                                <Box>
                                    {user.firstName} {user.surname} ({user.email})
                                </Box>
                            </Flex>
                            <Flex ml={'auto'} alignItems="center">
                                {showTasks && <Box mr="3">
                                    {user?.tasks !== undefined && user?.tasks !== null
                                        ? `Limited tasks (${Object.keys(user?.tasks).length})`
                                        : 'All tasks'}
                                </Box>}
                                <Button
                                    type="button"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    p={1}
                                    variant="red"
                                    onClick={() => setCompanyRemoveUserConfirm(user)}
                                >
                                    <Icon icon="close" stroke="white" title={`Remove access from ${company.name}`} />
                                </Button>
                            </Flex>  
                        </Flex>
                    ))}
                </>
            ) : (
                <Box>Want to share access? Use the "Add Users" box below to get started.</Box>
            )}
            {
                            (!showTasks? invitesConsultant : invites).length > 0 && (
                               <Box style={{padding:"16px 16px 24px 16px",background:"white",margin: "12px 0",borderRadius:"12px",}}>
                                <Box>
                                    <Label variant="body" color="blueDark2" mb="2">
                                        Pending Invites
                                    </Label>
                                    {(!showTasks? invitesConsultant : invites).map((invite, index) => (
                                        <Flex justifyContent="flex-start" mb={3} key={`new-invite-${index}`}>
                                            <Flex justifyContent="flex-start" alignItems="center">
                                                <UserAvatar
                                                    firstName={invite?.firstName}
                                                    surname={invite?.surname}
                                                    email={invite?.email}
                                                    avatar={invite?.avatar ?? false}
                                                    mr="12px"
                                                />

                                                <Box>
                                                    {invite?.existing ? (
                                                        <>
                                                            {invite.firstName} {invite.surname} ({invite.email})
                                                        </>
                                                    ) : (
                                                        <>{invite.email}</>
                                                    )}
                                                </Box>
                                            </Flex>
                                            <Flex ml={'auto'} alignItems="center">
                                                <Box mr="3">
                                                    {invite.tasks !== null
                                                        ? `Limited tasks (${invite.tasks.length})`
                                                        : 'All tasks'}
                                                </Box>
                                                <Button
                                                    type="button"
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    p={1}
                                                    variant="red"
                                                    onClick={() => removeInvite(index,!showTasks)}
                                                >
                                                    <Icon icon="close" stroke="white" title="Remove invite" />
                                                </Button>
                                            </Flex>
                                        </Flex>
                                    ))}
                                </Box>

                                <Form
                                    mt={4}
                                    display="flex"
                                    justifyContent="center"
                                    flexDirection="column"
                                    onSubmit={(e)=> sendInvites(e,!showTasks)}
                                >
                                    <Button variant="tertiary" type="submit" style={{color: theme.colors.blueDark,background:"#f3f3f3"}}>
                                        Send Invites
                                    </Button>
                                </Form>
                               </Box>
                            )
                                
        }
        </Box>
        <Flex style={{justifyContent:"end",padding:"12px 18px"}} >
            <Button alignItems="right" onClick={()=> openModalHandler(showTasks?"company":"consultant")}> Add New User</Button>
        </Flex>
        
                        
    </Card>

    return user?.permissions?.companies?.assignUsers && company?.users ? (
        <Box>
            <Confirm
                title={`${t('Are you sure you would like to remove access to')} ${company.name} ${t('for')} ${companyRemoveUserConfirm?.email}?`}
                isOpen={!!companyRemoveUserConfirm}
                requestClose={() => setCompanyRemoveUserConfirm(false)}
                onConfirm={handleRemoveCompanyUser}
            />

            <Users
                users={company?.users}
                title={"Company Users"}
                showTasks={true}
            />

            <Users
                users={company?.consultants}
                title={"Consultants"}
                showTasks={false}
            />

            <Modal 
            requestClose={()=>setModalForm(false)} 
            title={`Add new ${isConsultant ? "consultant user" : "company user"}`} 
            isOpen={modalForm}
            overflow="auto"
            >
                {loading ? (
                    <LoadingSpinner />
                ) : (
                    <div>
                        <Form onSubmit={updateInvites} ref={addUserRef}>
                            <TextField
                                label="Email address"
                                placeholder="Enter email address..."
                                value={email}
                                onChange={(val) => setEmail(val)}
                                errors={inviteErrors?.email}
                            />


                            {!isConsultant && <>
                                <Label variant="body" color="blueDark2" mb="2">
                                    Choose permission type
                                </Label>
                                <Box variant="forms.section" mb="2">
                                    <Label variant="forms.radio.label">
                                        <Radio
                                            name="permissionType"
                                            id="permissionTypeAll"
                                            value="all"
                                            checked={permissionType === 'all'}
                                            onChange={(e) => setPermissionType(e.currentTarget.value)}
                                        />
                                        Access to all
                                    </Label>
                                    
                                </Box>
                                <Box variant="forms.section" mb="2">
                                    <Label variant="forms.radio.label">
                                        <Radio
                                            name="permissionType"
                                            id="permissionTypeLimited"
                                            value="limited"
                                            checked={permissionType === 'limited'}
                                            onChange={(e) => setPermissionType(e.currentTarget.value)}
                                        />
                                        Access to limited tasks
                                    </Label>
                                </Box>
                                {permissionType === 'limited' && (
                                    <>
                                        <Label variant="body" color="blueDark2" mb="2">
                                            Choose access
                                        </Label>
                                        <Accordion
                                            title="Onboarding"
                                            expand={false}
                                            sx={{
                                                bg: 'white',
                                                borderRadius: 3,
                                                mb: 2,
                                            }}
                                            hidden
                                        >
                                            {onboardingStages?.map((stage) => (
                                                <Box key={`company-stage-${stage.id}`} variant="forms.section"  style={{overflow:"hidden"}} >
                                                    <Label
                                                        variant="body"
                                                        sx={{
                                                            color: 'blueDark2',
                                                            fontSize: 0,
                                                            mb: 2,
                                                        }}
                                                    >
                                                        {stage.name}
                                                    </Label>

                                                    {stage.steps?.map((step => (step.tasks?.map((task) => (
                                                        <Box key={`company-task=${task.id}`}>
                                                            <Label variant="forms.checkbox.label">
                                                                <Checkbox
                                                                    name="tasks"
                                                                    id={`companyTask${task.id}`}
                                                                    value={task.taskId}
                                                                    onChange={handlePermissionTask}
                                                                />
                                                                {task.name}
                                                            </Label>
                                                        </Box>
                                                    )))))}
                                                </Box>
                                            ))}
                                        </Accordion>

                                        <Accordion
                                            title="Projects"
                                            expand={false}
                                            sx={{
                                                bg: 'white',
                                                borderRadius: 3,
                                                mb: 2,
                                            }}
                                            maxHeight='2000px'
                                            hidden
                                        >
                                            {companyStages?.map((stage) => (
                                                <Box key={`company-stage-${stage.id}`} variant="forms.section" style={{overflow:"hidden"}}>
                                                    <Label
                                                        variant="body"
                                                        sx={{
                                                            color: 'blueDark2',
                                                            fontSize: 0,
                                                            mb: 2,
                                                        }}
                                                    >
                                                        {stage.name}
                                                    </Label>

                                                    {stage.steps?.map((step => (step.tasks?.map((task) => (
                                                        <Box key={`company-task=${task.id}`}>
                                                            <Label variant="forms.checkbox.label">
                                                                <Checkbox
                                                                    name="tasks"
                                                                    id={`companyTask${task.id}`}
                                                                    value={task.taskId}
                                                                    onChange={handlePermissionTask}
                                                                />
                                                                {task.name}
                                                            </Label>
                                                        </Box>
                                                    )))))}
                                                </Box>
                                            ))}
                                        </Accordion>
                                    </>
                                )}
                            </>}
                        <Flex style={buttonFooterStyles}>                          
                            <Button variant="primary" type="submit">
                                Invite user
                            </Button>
                        </Flex>     
                        </Form >
                    </div>
                )}
            </Modal >
        </Box >
    ) : null;
};

export default connect((state) => ({ user: state.user, company: state.company }), { setCompany })(CompanyUsers);
