import React from 'react';
import { Box, Button, Flex, Heading } from 'rebass';
import { Icon } from '@svg';
import { BetaFactorSliderScorecard, EsgScorecard, formatDateFull, FoundationScorecard, isAdvisor, StrategicGrowthScorecard } from '@util/Functions';
import SliderScorecard from '~/Dashboard/ScorecardView/SliderScorecard';
import { Switch } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const ScorecardResults = ({ reportName, questions, submitReport, completedOn, type, toggleNotApplicableResult, scorecardId, canGenerateScorecard = false, finalise, user }) => {
    const { t } = useTranslation();
    const isBetaFactor = type === BetaFactorSliderScorecard
    const isStrategicGrowth = type === StrategicGrowthScorecard
    const isFoundationScorecard = type === FoundationScorecard
    const allowToggle = isAdvisor(user) ? type === EsgScorecard : true

    return (
        <Box>
            <Box mb={4}>
                <Flex variant="card.header" justifyContent="space-between">
                    <Flex alignItems="center">{reportName} Scorecard</Flex>

                    <Box backgroundColor="transparent">
                        {!completedOn && (
                            <Box ml="auto" textAlign="center">
                                {canGenerateScorecard && <Button width="100%" variant="primary" onClick={() => submitReport()}>
                                    Generate Scorecard
                                </Button>}
                            </Box>
                        )}
                    </Box>
                </Flex>

                <Box variant="card.containerGapless" py={20} px={30}>
                    {(!isBetaFactor && !isStrategicGrowth && !isFoundationScorecard) && (
                        <Flex fontWeight="600" color="blueLight2" fontSize={12} justifyContent="center" py="6px" px={10} textAlign="center">
                            <Box width={3 / 6} />
                            <Box width={1 / 6}>Value</Box>
                            <Box width={1 / 6}>Target</Box>
                            <Box width={1 / 6}>Result</Box>
                            <Box width={1 / 6}>Not Applicable</Box>
                        </Flex>
                    )}

                    {questions?.sections?.map(({ name: sectionName, groups }, sectionId) => (
                        <Box key={sectionId} mb={4}>
                            <Heading mt={sectionId === 0 ? 0 : 3} mb={2} color="blueLight2" fontSize={2} fontWeight="600">
                                {t(sectionName)}
                            </Heading>

                            {groups.map(({ name: groupName, result, na }, groupId) => (
                                <Flex
                                    key={groupId}
                                    fontSize="12px"
                                    py={1}
                                    px="13px"
                                    fontWeight="600"
                                    bg={groupId % 2 === 0 ? 'rgba(232, 236, 238, 0.5)' : '#E8ECEE'}
                                    alignItems="center"
                                >
                                    <Box width={3 / 6}>{t(groupName)}</Box>
                                    <Box width={1 / 6} textAlign="center">
                                        {result !== null && result !== '' ? `${Math.round(result)}%` : 'N/A '}
                                    </Box>
                                    <Box width={1 / 6} textAlign="center">75%</Box>
                                    <Box width={1 / 6} textAlign="center" mt={1}>
                                        {result >= 75 && <Icon icon="completedYes" title="Group Completed" size="17px" />}
                                        {result < 75 && <Icon icon="completedNo" title="Group Incomplete" size="17px" />}
                                    </Box>
                                    <Box width={1 / 6} textAlign="center">
                                        {completedOn && na && 'N/A'}
                                        {allowToggle && !completedOn && <Switch value={groups[groupId].na} checked={!!groups[groupId].na} onChange={() => toggleNotApplicableResult(sectionId, groupId)} />}
                                    </Box>
                                </Flex>
                            ))}
                        </Box>
                    ))}

                    {isBetaFactor && questions?.sliders && <SliderScorecard sliders={questions.sliders} scorecardType={type} scorecardId={scorecardId} />}

                    {questions?.results?.map(({ name: sectionName, groups }, sectionId) => (
                        <Box key={sectionId} mb="4">
                            <Heading color="blueLight2" mb={15} fontSize={15} fontWeight="600">
                                {t(sectionName)}
                            </Heading>
                            {groups.map(({ name: groupName, result, value, target, na }, groupId) => (
                                <Flex
                                    key={groupId}
                                    fontSize="12px"
                                    py={1}
                                    px="13px"
                                    fontWeight="600"
                                    bg={groupId % 2 === 0 ? 'rgba(232, 236, 238, 0.5)' : '#E8ECEE'}
                                    alignItems="center"
                                >
                                    <Box width={1 / 2}>{t(groupName)}</Box>
                                    <Box width={1 / 6} textAlign="center">{value}</Box>
                                    <Box width={1 / 6} textAlign="center">{target}</Box>
                                    <Box width={1 / 6} textAlign="center" mt={1}>
                                        {result && <Icon icon="completedYes" title="Group Completed" size="17px" />}
                                        {!result && <Icon icon="completedNo" title="Group Incomplete" size="17px" />}
                                    </Box>
                                    <Box width={1 / 6} textAlign="center">
                                        {completedOn && na && 'N/A'}
                                        {allowToggle && !completedOn && <Switch value={groups[groupId].na} checked={!!groups[groupId].na} onChange={() => toggleNotApplicableResult(sectionId, groupId)} />}
                                    </Box>
                                </Flex>
                            ))}
                        </Box>
                    ))}
                    {finalise}
                </Box>
            </Box>
            {completedOn && (
                <Box pb={30} px={30}>
                    This scorecard was completed at {formatDateFull(completedOn)}
                </Box>
            )}
        </Box>
    )
};

export default connect((state) => ({ user: state.user }))(ScorecardResults);
