import React, { useState } from 'react';
import { Card } from '~/Common/Card';
import '../styles.module.scss';
import { connect } from 'react-redux';
import { Button } from '~/Common/Button';
import { Modal } from '~/Common/Modal';
import { toast } from 'react-toastify';
import { CodatLink } from './CodatLink';
import { useTranslation } from 'react-i18next';

const CodatIntegrations = ({ company }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const { t } = useTranslation()

    return company?.codat_id ? (
        <>
            <Card title="Integrations">
                <Button onClick={() => setModalOpen(true)}>Manage Third Party Integration</Button>
            </Card>
            <Modal
                isOpen={modalOpen}
                ariaHideApp={false}
                minWidth={0}
                childSx={{
                    p: 0,
                }}
            >
                <CodatLink
                    companyId={company.codat_id}
                    onConnection={(id) => { }} // Called each time a connection is established
                    onFinish={() => {
                        setModalOpen(false)
                        toast.success(t('Successfully connected account'), {
                            toastId: 'codatConnectionSuccess'
                        })
                    }}
                    onClose={() => setModalOpen(false)} // Called when the user clicks 'X' or completes the whole flow
                    onError={(err) => console.log('Error: ' + err)} // Called when an error is reached
                />
            </Modal>
        </>
    ) : (
        <></>
    );
};

export default connect((state) => ({ company: state.company }))(CodatIntegrations);
