import React, { useRef, useState } from 'react';
import {
    getOrganisationCompanies,
    assignAdvisorToCompany,
    unassignAdvisorFromCompany,
    assignSupervisorToCompany,
    unassignSupervisorFromCompany
} from '@app/Api';
import { setOrganisation } from '@app/Store';
import { connect } from 'react-redux';
import { Box, Flex } from 'rebass';
import { DropdownModal } from '~/Common/DropdownModal';
import { TextField } from '~/Form';
import { Button } from '~/Common/Button';
import { debounce } from '@material-ui/core';
import UserAvatar from '~/Dashboard/Avatar';
import LoadingSpinner from '~/LoadingSpinner';

const UserCompaniesButton = ({ children, companies, offset = 6, allowAssign, onClick }) => {
    if (companies && !companies.length) {
        return allowAssign ? <Button variant="rounded" onClick={onClick}>
            {children}
        </Button> : <></>
    }

    return (
        <Flex ml={`${offset}px`}>
            <Flex>
                {Array.isArray(companies) && <>
                    {[...Array(5).keys()].map((int) => companies[int] ? <UserAvatar
                        key={companies[int].id}
                        size="24px"
                        logo={companies[int].logo}
                        firstName={companies[int].name}
                        ml={`-${offset}px`}
                    /> : false)}
                    {companies.length > 5 && <UserAvatar
                        size="24px"
                        icon='dots'
                        ml={`-${offset}px`}
                        sx={{
                            transform: 'rotate(90deg)'
                        }}
                    />}
                </>}
            </Flex>
            {allowAssign && (
                <Button
                    variant="rounded"
                    onClick={onClick}
                    icon="plus"
                    size="24px"
                    sx={{
                        p: 0,
                        ml: `-${offset}px`,
                        zIndex: 10,
                    }}
                />
            )}
        </Flex>
    );
};

export const AssignCompaniesUncoupled = ({ user, org, setOrganisation, allowAssign, companyKey = 'advises', ...props }) => {
    const [results, setResults] = useState([]);
    const searchRef = useRef(null);

    const triggerSearch = (search = '') =>
        getOrganisationCompanies(org.id, search).then(({ data }) => setResults(Array.isArray(data) ? data : []));

    const handleSearch = async (search = '') => {
        if (search !== searchRef.current) {
            searchRef.current = search;
            return triggerSearch(search);
        } else {
            return false;
        }
    };

    const handleOpen = () => {
        if (searchRef.current === null) {
            searchRef.current = '';
            triggerSearch();
        }
    };

    const debouncedSearch = debounce(handleSearch);

    const handleAssignUserToCompany = (companyId) => () => {
        switch (companyKey) {
            case 'advises':
                return assignAdvisorToCompany(org.id, {
                    companyId,
                    userId: user.id,
                }).then(({ data }) => {
                    setOrganisation(data);
                });
            case 'supervises':
                return assignSupervisorToCompany(org.id, {
                    companyId,
                    userId: user.id,
                }).then(({ data }) => {
                    setOrganisation(data);
                });
            default:
                return false;
        }
    };

    const handleUnassignUser = (companyId) => () => {
        switch (companyKey) {
            case 'advises':
                return unassignAdvisorFromCompany(org.id, {
                    companyId,
                    userId: user.id,
                }).then(({ data }) => {
                    setOrganisation(data);
                });
            case 'supervises':
                return unassignSupervisorFromCompany(org.id, {
                    companyId,
                    userId: user.id,
                }).then(({ data }) => {
                    setOrganisation(data);
                });
            default:
                return false;
        }
    };

    const hasSearch = searchRef.current !== '';
    const companiesList = hasSearch
        ? results
        : [...results, ...user?.companies[companyKey]?.filter((c) => !results.find((r) => r.id === c.id))];
    const userHasCompany = (companyId) => !!user?.companies[companyKey]?.find((c) => c.id === companyId);
    const renderButton = (props) => (
        <UserCompaniesButton companies={user?.companies[companyKey]} allowAssign={allowAssign} {...props} />
    );

    return (
        <DropdownModal
            title="Assign Companies"
            renderButton={renderButton}
            onOpen={handleOpen}
            align={{ x: 'left' }}
            {...props}
        >
            <TextField
                variant="inputs.thin"
                onChange={debouncedSearch}
                placeholder="Search for companies"
                icon="search"
                iconColor="#C3C7D0"
                mb={3}
            />
            <Box sx={{ maxHeight: '300px', overflowY: 'auto', mx: '-20px', mb: '-20px', px: '20px' }}>
                {Array.isArray(companiesList) && companiesList.length ? (
                    companiesList.map((company) => {
                        const isActive = userHasCompany(company.id);
                        return (
                            <Flex
                                key={company.id}
                                sx={{
                                    py: '13px',
                                    '&:not(:last-child)': {
                                        borderBottom: '0.5px solid #E8EAEF',
                                    },
                                }}
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                {company.name}
                                <Button
                                    variant="rounded"
                                    size="small"
                                    icon={isActive ? 'tick' : ''}
                                    iconProps={{ size: '12px', sx: { padding: '3px', boxSizing: 'content-box' } }}
                                    onClick={(isActive ? handleUnassignUser : handleAssignUserToCompany)(company.id)}
                                    sx={{ width: '74px', bg: isActive ? '#D1F5FF' : undefined }}
                                >
                                    {isActive ? '' : 'Assign'}
                                </Button>
                            </Flex>
                        );
                    })
                ) : searchRef.current === null ? (
                    <LoadingSpinner size={30} />
                ) : (
                    <Flex justifyContent="center" py={4}>
                        No {hasSearch ? 'results' : 'companies'}
                    </Flex>
                )}
            </Box>
        </DropdownModal>
    );
};

export const AssignCompanies = connect((state) => ({ org: state.organisation }), { setOrganisation })(
    AssignCompaniesUncoupled
);
