import { createSlice } from '@reduxjs/toolkit';

const organisation = createSlice({
    name: 'organisation',
    initialState: false,
    reducers: {
        setOrganisation: (state, { payload }) => payload,
        resetOrganisation: () => {},
    },
});

export const { setOrganisation, resetOrganisation } = organisation.actions;

export default organisation.reducer;
