import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { GuestRoute, TwoFactorRoute, PrivateRoute, CompanyRoute, DemoRoute } from './RouteTypes';
import Dashboard from '@section/Dashboard';
import { useTranslation } from 'react-i18next';
import {
    CompanyHome,
    CompanyStages,
    CompanySettings,
    CompanyOnboardingDocuments,
    CompanyFinancialReports,
    Scorecards,
    Reports,
    Valuations,
    AdvisorHome,
    AdvisorInbox,
    AdvisorSettings,
    AddbackNormalisation,
    AdvisorCompanyBenchmarking,
    AdvisorCompanyNormalisation,
    AdvisorCompanyStages,
    AdvisorCompanyOverview,
    AdvisorCompanyFinancials,
    AdvisorCompanyFinancialImports,
    AdvisorCompanyCustomVariables,
    AdvisorCompanySettings,
    AdvisorCompanyScorecardEdit,
    AdvisorCompanyOnboarding,
    AdvisorCompanyDynamicRevaluation,
    AdvisorReportView,
    AdvisorReportEdit,
    AdvisorValuationEdit,
    OrganisationHome,
    OrganisationCompanies,
    AdminError,
    TaskView,
    ScorecardView,
    NoMatch404Page,
    Login,
    LoginTwoFactorChallenge,
    Logout,
    ForgotPassword,
    ResetPassword,
    DownloadFile,
    TermsAndConditions,
    IBISWorldData,
    ActionItems,
    StepQuestionnaire,
    ExitAssessmentTool,
} from '../../Pages';
import { DemoIcons, DemoButtons } from '../../Pages/Demo';
import CompanyContext from 'Context/Company';
import { UserRoles } from '@util/Enum/User';
import Support from 'Pages/Common/Support';
import Home from 'Pages/Consultant/Home';

const Routes = () => {
    const { t } = useTranslation();
    const orgRoles = [UserRoles.CEO, UserRoles.SUPERVISOR];

    return (
        <Switch>
            <GuestRoute path="/" component={Login} exact />
            <TwoFactorRoute path="/two-factor-challenge" component={LoginTwoFactorChallenge} exact />
            <GuestRoute path="/forgot-password" component={ForgotPassword} exact />
            <GuestRoute
                path="/reset-password/:email?/:hash?"
                component={({
                    match: {
                        params: { email, hash },
                    },
                }) => <ResetPassword email={email} hash={hash} />}
            />
            <PrivateRoute path="/logout" component={Logout} />

            <CompanyRoute
                path="/company/onboarding/:companyId?"
                exact
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => <Dashboard Component={CompanyStages} title="Onboarding" props={{ title: 'Onboarding Progress', companyId: companyId }} />}
            />
            <CompanyRoute
                path="/company/dashboard/:companyId?"
                exact
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => <Dashboard Component={CompanyHome} title="Dashboard" icon="computer" props={{ companyId: companyId }} />}
            />
            <CompanyRoute
                path="/company/action-items/:companyId?"
                exact
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => <Dashboard Component={ActionItems} title="Action Items" icon="checklist" props={{ companyId: companyId }} />}
            />
            <CompanyRoute
                path="/company/projects/:companyId?"
                exact
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => <Dashboard Component={CompanyStages} title="My Projects" props={{ companyId: companyId }} />}
            />
            <CompanyRoute
                path="/company/projects/task/:companyTaskId"
                component={({
                    match: {
                        params: { companyTaskId },
                    },
                }) => <Dashboard Component={TaskView} title="Onboarding Task" props={{ companyTaskId }} />}
            />
            <CompanyRoute
                path="/company/profile/onboarding"
                component={() => <Dashboard Component={CompanyOnboardingDocuments} title="Onboarding" />}
            />
            <PrivateRoute
                path="/company/profile/settings/:companyId?"
                exact
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => <Dashboard Component={CompanySettings} title="Settings" props={{ companyId: companyId }} />}
            />
            <CompanyRoute
                path="/company/financials/:companyId?"
                exact
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => <Dashboard Component={CompanyFinancialReports} title="Financials" props={{ companyId: companyId }} />}
            />
            <CompanyRoute
                path="/company/scorecards"
                component={() => <Dashboard Component={Scorecards} title={t('scorecards.title')} icon="scorecards" />}
                exact
            />
            <CompanyRoute
                path="/company/scorecards/:scorecardId"
                component={({
                    match: {
                        params: { scorecardId },
                    },
                }) => <Dashboard Component={ScorecardView} props={{ scorecardId }} title="Scorecard" />}
                exact
            />
            <CompanyRoute
                path="/company/valuations"
                component={() => <Dashboard Component={Valuations} title="Valuations" />}
                exact
            />
            <CompanyRoute
                path="/company/valuations/:valuationId"
                component={({
                    match: {
                        params: { valuationId },
                    },
                }) => (
                    <CompanyContext valuationId={valuationId}>
                        <Dashboard Component={AdvisorValuationEdit} props={{ valuationId }} title="Valuation" />
                    </CompanyContext>
                )}
                exact
            />
            <CompanyRoute
                path="/company/reports/:companyId?"
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => <Dashboard Component={Reports} title="Insights" props={{ companyId: companyId }} />}
            />
            <PrivateRoute
                path="/consultant/home"
                notRole={orgRoles}
                component={() => (
                    <Dashboard Component={Home} title="Dashboard" icon="computer" childSx={{ pt: 0 }} />
                )}
            />
            <PrivateRoute
                path="/company/settings/:companyId?"
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => <Dashboard Component={CompanySettings} title={t('settings.title')} props={{ companyId: companyId }} />}
            />

            <PrivateRoute
                path="/organisation/home"
                role={orgRoles}
                component={() => <Dashboard Component={OrganisationHome} title="Dashboard" icon="computer" />}
            />
            <PrivateRoute
                path="/organisation/companies"
                role={orgRoles}
                component={() => (
                    <Dashboard
                        Component={AdvisorHome}
                        title="Dashboard"
                        icon="computer"
                        childSx={{ pt: 0 }}
                        showBack={true}
                    />
                )}
            />
            <PrivateRoute
                path="/organisation/advisor/:advisorId/companies"
                role={orgRoles}
                component={() => <Dashboard Component={OrganisationCompanies} title="Companies" />}
            />
            <PrivateRoute
                path="/organisation/settings/:page?"
                component={() => <Dashboard Component={AdvisorSettings} title="Settings" />}
                role={orgRoles}
            />

            <PrivateRoute
                path="/advisor/home"
                notRole={orgRoles}
                component={() => (
                    <Dashboard Component={AdvisorHome} title="Dashboard" icon="computer" childSx={{ pt: 0 }} />
                )}
            />
            <PrivateRoute
                path="/advisor/inbox"
                component={() => <Dashboard Component={AdvisorInbox} title="Inbox" icon="bell" />}
            />
            <PrivateRoute
                path="/advisor/settings/:page?"
                component={() => <Dashboard Component={AdvisorSettings} title="Settings" />}
            />
            <PrivateRoute
                path="/advisor/exit-assessment"
                component={() => <Dashboard Component={ExitAssessmentTool} title="Value Gap Assessment" icon="paper" />}
            />

            <CompanyRoute
                path="/advisor/company/:companyId"
                exact
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => <Redirect to={`/advisor/company/${companyId}/overview`} />}
            />
            <CompanyRoute
                path="/advisor/company/:companyId/overview"
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => (
                    <CompanyContext companyId={companyId}>
                        <Dashboard
                            Component={AdvisorCompanyOverview}
                            props={{ companyId }}
                            title={t('companyOverview.title')}
                            icon="computer"
                        />
                    </CompanyContext>
                )}
                exact
            />
            <CompanyRoute
                path="/advisor/company/:companyId/revaluation"
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => (
                    <CompanyContext companyId={companyId}>
                        <Dashboard
                            Component={AdvisorCompanyDynamicRevaluation}
                            props={{ companyId }}
                            title={t('companyDR.title')}
                            icon="computer"
                        />
                    </CompanyContext>
                )}
                exact
            />
            <CompanyRoute
                path="/advisor/company/:companyId/revaluation/:stepId"
                component={({
                    match: {
                        params: { companyId, stepId },
                    },
                }) => (
                    <CompanyContext companyId={companyId}>
                        <Dashboard
                            Component={StepQuestionnaire}
                            props={{ companyId, stepId }}
                            title={t('companyDR.title')}
                            icon="computer"
                        />
                    </CompanyContext>
                )}
                exact
            />
            <CompanyRoute
                path="/advisor/company/:companyId/normalisation"
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => (
                    <Dashboard
                        Component={AdvisorCompanyNormalisation}
                        props={{ companyId }}
                        title={t('normalisation.title')}
                        icon="chartThin"
                    />
                )}
                exact
            />
            <CompanyRoute
                path="/advisor/company/:companyId/addbacks"
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => <Dashboard Component={AddbackNormalisation} props={{ companyId }} title={t('addbacks.title')} />}
                exact
            />
            <CompanyRoute
                path="/advisor/company/:companyId/ibis-world"
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => <Dashboard Component={IBISWorldData} props={{ companyId }} title={t('ibisWorld.title')} />}
                exact
            />
            <CompanyRoute
                path="/advisor/company/:companyId/benchmarking"
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => (
                    <Dashboard
                        Component={AdvisorCompanyBenchmarking}
                        props={{ companyId }}
                        title={t('benchmarking.title')}
                    />
                )}
                exact
            />
            <CompanyRoute
                path="/advisor/company/:companyId/stages"
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => (
                    <Dashboard Component={AdvisorCompanyStages} props={{ companyId }} title="Projects" icon="folder" color="blueDark2" />
                )}
                exact
            />
            <CompanyRoute
                path="/advisor/company/:companyId/action-items"
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => (
                    <Dashboard Component={ActionItems} props={{ companyId }} title="Action Items" icon="checklist" />
                )}
                exact
            />
            <CompanyRoute
                path="/advisor/company/:companyId/stages/task/:companyTaskId"
                component={({
                    match: {
                        params: { companyId, companyTaskId },
                    },
                }) => <Dashboard Component={TaskView} props={{ companyId, companyTaskId }} title="Onboarding Task" />}
            />
            <CompanyRoute
                path="/advisor/company/:companyId/onboarding"
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => (
                    <Dashboard
                        Component={AdvisorCompanyOnboarding}
                        props={{ companyId }}
                        title="Onboarding"
                        icon="clipboardCheck"
                    />
                )}
            />
            <CompanyRoute
                path="/advisor/company/:companyId/financial-reports"
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => (
                    <Dashboard
                        Component={AdvisorCompanyFinancials}
                        props={{ companyId }}
                        title={t('financialHistory.title')}
                    />
                )}
            />
            <CompanyRoute
                path="/advisor/company/:companyId/financial-imports"
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => (
                    <Dashboard
                        Component={AdvisorCompanyFinancialImports}
                        props={{ companyId }}
                        title="Financial Imports"
                        icon="chartThin"
                    />
                )}
            />
            <CompanyRoute
                path="/advisor/company/:companyId/scorecards"
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => (
                    <Dashboard
                        Component={Scorecards}
                        props={{ companyId }}
                        title={t('scorecards.title')}
                        icon="scorecards"
                    />
                )}
                exact
            />
            <CompanyRoute
                path="/advisor/company/:companyId/scorecards/:scorecardId"
                component={({
                    match: {
                        params: { scorecardId },
                    },
                }) => <Dashboard Component={ScorecardView} props={{ scorecardId }} title="Company Scorecard" />}
                exact
            />
            <CompanyRoute
                path="/advisor/company/:companyId/scorecards/:scorecardId/edit"
                component={({
                    match: {
                        params: { companyId, scorecardId },
                    },
                }) => <Dashboard Component={AdvisorCompanyScorecardEdit} props={{ companyId, scorecardId }} />}
                exact
            />
            <CompanyRoute
                path="/advisor/company/:companyId/reports"
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => <Dashboard Component={Reports} props={{ companyId }} title={t('reports.plural')} />}
                exact
            />
            <CompanyRoute
                path="/advisor/company/:companyId/reports/edit/:reportId"
                component={({
                    match: {
                        params: { companyId, reportId },
                    },
                }) => (
                    <CompanyContext companyId={companyId}>
                        <Dashboard
                            Component={AdvisorReportEdit}
                            props={{ companyId, reportId }}
                            title="Edit Company Report"
                        />
                    </CompanyContext>
                )}
                exact
            />
            <CompanyRoute
                path="/advisor/company/:companyId/reports/view/:reportId"
                component={({
                    match: {
                        params: { companyId, reportId },
                    },
                }) => (
                    <CompanyContext companyId={companyId}>
                        <Dashboard
                            Component={AdvisorReportView}
                            props={{ companyId, reportId }}
                            title="View Company Report"
                        />
                    </CompanyContext>
                )}
                exact
            />
            <CompanyRoute
                path="/advisor/company/:companyId/valuations"
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => (
                    <CompanyContext companyId={companyId}>
                        <Dashboard Component={Valuations} props={{ companyId }} title={t('valuations.title')} />
                    </CompanyContext>
                )}
                exact
            />
            <CompanyRoute
                path="/advisor/company/:companyId/valuations/edit/:valuationId"
                component={({
                    match: {
                        params: { companyId, valuationId },
                    },
                }) => (
                    <CompanyContext companyId={companyId}>
                        <Dashboard
                            Component={AdvisorValuationEdit}
                            props={{ companyId, valuationId }}
                            title="Edit Company Valuation"
                        />
                    </CompanyContext>
                )}
                exact
            />
            <CompanyRoute
                path="/advisor/company/:companyId/custom-variables"
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => (
                    <Dashboard
                        Component={AdvisorCompanyCustomVariables}
                        props={{ companyId }}
                        title={t('companyCustomVariables.title')}
                    />
                )}
            />
            <PrivateRoute
                path="/advisor/company/:companyId/settings"
                component={({
                    match: {
                        params: { companyId },
                    },
                }) => <Dashboard Component={AdvisorCompanySettings} props={{ companyId }} title="Company Settings" />}
            />

            <PrivateRoute
                path="/download/wysiwygFiles/:fileLocation/:fileName"
                component={({
                    match: {
                        params: { fileLocation, fileName },
                    },
                }) => <DownloadFile fileLocation={fileLocation} fileName={fileName} />}
            />

            <Route
                path="/admin/valuation/edit/:valuationId"
                component={({
                    match: {
                        params: { valuationId },
                    },
                }) => (
                    <CompanyContext valuationId={valuationId}>
                        <AdvisorValuationEdit valuationId={valuationId} allowFullEdits={true} viaAdmin={true} />
                    </CompanyContext>
                )}
                exact
            />

            <Route path="/terms-conditions" component={(props) => <TermsAndConditions />} />

            <DemoRoute path="/demo/icons" component={DemoIcons} />
            <DemoRoute path="/demo/buttons" component={DemoButtons} />

            <PrivateRoute
                path="/support"
                component={({
                    match: {
                        params: { url },
                    },
                }) => <Dashboard Component={Support} props={{ pageUrl: url }} />}
            />

            <Route path="/admin-error" component={AdminError} />

            <PrivateRoute
                path="/:url"
                component={({
                    match: {
                        params: { url },
                    },
                }) => <Dashboard Component={NoMatch404Page} props={{ pageUrl: url }} />}
            />
        </Switch>
    );
};

export default Routes;
