import React, { useState } from 'react';
import { Badge } from '~/Common/Badge';
import { actionItemStatuses } from '@util/Enum/ActionItemStatus';
import { Box } from 'rebass';
import styles from './Assets/styles.module.scss';
import { Button } from '~/Common/Button';
import { Icon } from '@svg/Icon';
import { ActionItemStatus } from '~/Common/ActionItemStatus';
import { connect } from 'react-redux';

const ActionItemTile = ({ showProject = true, collapsed = true, actionItem, onEditItem, mt = 3, user }) => {
    const [isOpen, setOpen] = useState(!collapsed);
    const isOverdue = actionItem.overdue && actionItem.status === 1; // status is pending

    return (
        <Box sx={{
            '&:not(:first-of-type)': {
                marginTop: mt
            }
        }} className={styles.actionItemBox}>
            <Box
                className={styles.header}
                sx={{ borderBottom: isOpen ? '1px solid #E8EAEF' : 'none' }}
                onClick={() => setOpen(isOpen => !isOpen)}
            >
                <Box sx={{ flex: 1, fontWeight: 600 }}>{showProject ? actionItem.companyTask.name : actionItem.task}</Box>
                <ActionItemStatus isOverdue={isOverdue} status={actionItem?.status} />
                <Icon icon="dropdownArrow" size="10px" sx={{ transition: 'transform 0.3s ease', transform: `rotate(${isOpen ? '-180deg' : '0'})` }} />
            </Box>
            <Box
                sx={{
                    display: isOpen ? 'flex' : 'none',
                    flexDirection: 'column',
                    gap: '16px',
                    p: 3,
                    mb: 1,
                    fontSize: '12px'
                }}
            >
                {showProject && (
                    <Box className={styles.row}>
                        <Box className={styles.rowHeader}>Action</Box>
                        {actionItem.task}
                    </Box>
                )}
                {isOverdue && (
                    <Box className={styles.row}>
                        <Box className={styles.rowHeader}>Status</Box>
                        <Badge variant={actionItem?.status === 2 ? 'success' : actionItem?.status === 3 ? 'purple' : 'warning'}>
                            {actionItemStatuses[actionItem?.status]?.label}
                        </Badge>
                    </Box>
                )}
                <Box className={styles.row}>
                    <Box className={styles.rowHeader}>Assigned To</Box>
                    {actionItem?.assignee?.name}
                </Box>
                <Box className={styles.row}>
                    <Box className={styles.rowHeader}>Due Date</Box>
                    {actionItem.dueDate}
                </Box>
                {actionItem.notes && (
                    <Box className={styles.row}>
                        <Box className={styles.rowHeader} sx={{ flex: '100%' }}>Notes</Box>
                        <Box className={styles.row}>
                            {actionItem.notes}
                        </Box>
                    </Box>
                )}
                {onEditItem && user?.permissions?.actionItems?.create && (
                    <Button onClick={() => onEditItem(actionItem)} icon="pencil" variant="smallGray" sx={{ width: '100%', mt: 3 }}>
                        Edit
                    </Button>
                )}
            </Box>
        </Box>
    )
}

export default connect((state) => ({
    user: state.user,
}), {})(ActionItemTile)
