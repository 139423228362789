import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Box } from 'rebass';
import styles from './styles.module.scss';

const MarkdownFormatter = ({ node, children, ...props }) => {
    const modifier = children[0] && typeof children[0] === 'string' ? children[0].substring(0, 1) : false;
    const content = children[0].substring(1);

    /*
     * Allows custom modifiers to be added to modify Markdown output
     *
     * To use, add a modifier below and use it as the first character within
     * an <em> (*) block in your markdown.
     *
     * *~This text would have it's style modified below*
     *
     * Using no modifier will result in regular italic text.
     */
    switch (modifier) {
        case '~':
            return <Box sx={{ fontSize: 10 }}>{content}</Box>
        case '@':
            return (
                <Box as="span" sx={{
                    background: '#d1f5ff',
                    padding: '2px 7px',
                    borderRadius: '4px',
                    color: '#000',
                    fontSize: '11px',
                    verticalAlign: 'middle',
                    marginRight: '6px',
                }}>
                    {content}
                </Box>
            )
        default:
            return <em {...props}>{children}</em>
    }
}

export const Markdown = ({ children, sx, ...props }) => {
    return (
        <Box sx={sx}>
            <ReactMarkdown
                components={{
                    p: 'span',
                    em: MarkdownFormatter
                }}
                className={styles.markdown}
                {...props}
            >
                {children}
            </ReactMarkdown>
        </Box>
    )
}
