import { debounce } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Flex, Heading } from 'rebass';
import { TextField } from '~/Form';
import ScorecardSlider from '../Sliders';
import styles from './style.module.scss';
import { sliderTypesBetaFactor } from '@util/Functions/Constants';
import { theme } from '@app/theme';
import SliderReasonBetaFactor from './sliderReason';

const ScorecardBetaFactor = ({ questions, updateQuestions, ...props }) => {
    const [averageMarketLeveraging] = useState(questions.beta.averageMarketLeveraging);
    const [marginalInterestRate] = useState(questions.beta.marginalInterestRate);
    const [marginalCorporateTaxRate] = useState(questions.beta.marginalCorporateTaxRate);
    const [riskFreeRateOfReturn] = useState(questions.beta.riskFreeRateOfReturn);
    const [marketRiskPremium] = useState(questions.beta.marketRiskPremium);
    const [marketValueDebt] = useState(questions.beta.marketValueDebt);
    const [sliders, setSliders] = useState(questions.sliders);
    const [netAdjustment, setNetAdjustment] = useState(questions.beta.netAdjustment || 0);

    const marketAssetBeta = useMemo(() => {
        if (averageMarketLeveraging < 7) return 1;
        else if (averageMarketLeveraging < 21) return 0.9;
        else if (averageMarketLeveraging < 33) return 0.8;
        else if (averageMarketLeveraging < 44) return 0.7;
        else if (averageMarketLeveraging < 54) return 0.6;
        else if (averageMarketLeveraging < 64) return 0.5;
        else if (averageMarketLeveraging < 73) return 0.4;
        else if (averageMarketLeveraging < 81) return 0.3;
        else if (averageMarketLeveraging < 90) return 0.2;
        else if (averageMarketLeveraging < 97) return 0.1;
        return 0;
    }, [averageMarketLeveraging]);

    const percentMarginalInterestRate = useMemo(() => parseFloat(marginalInterestRate) / 100, [marginalInterestRate]);
    const percentMarginalCorporateTaxRate = useMemo(
        () => parseFloat(marginalCorporateTaxRate) / 100,
        [marginalCorporateTaxRate]
    );
    const percentMarketValueDebt = useMemo(() => parseFloat(marketValueDebt) / 100, [marketValueDebt]);
    const percentMarketRiskPremium = useMemo(() => parseFloat(marketRiskPremium) / 100, [marketRiskPremium]);
    const percentRiskFreeReturn = useMemo(() => parseFloat(riskFreeRateOfReturn) / 100, [riskFreeRateOfReturn]);
    const assetBeta = useMemo(() => marketAssetBeta + netAdjustment, [netAdjustment, marketAssetBeta]);
    const kd = useMemo(
        () => percentMarginalInterestRate * (1 - percentMarginalCorporateTaxRate),
        [percentMarginalInterestRate, percentMarginalCorporateTaxRate]
    );
    const leveragedBeta = useMemo(
        () =>
            assetBeta *
            (1 + (1 - percentMarginalCorporateTaxRate) * (percentMarketValueDebt / (1 - percentMarketValueDebt))),
        [assetBeta, percentMarginalCorporateTaxRate, percentMarketValueDebt]
    );
    const ke = useMemo(
        () => percentRiskFreeReturn + leveragedBeta * percentMarketRiskPremium,
        [percentRiskFreeReturn, leveragedBeta, percentMarketRiskPremium]
    );
    const afterTaxWACC = useMemo(
        () => percentMarketValueDebt * kd + (1 - percentMarketValueDebt) * ke,
        [percentMarketValueDebt, kd, ke]
    );
    const beforeTaxWACC = useMemo(
        () => (percentMarginalInterestRate * percentMarketValueDebt) + (ke * (1 - percentMarketValueDebt)),
        [ke, percentMarketValueDebt, percentMarginalInterestRate]
    );

    const hasAllNecessaryData = useMemo(
        () =>
            !sliders.some(
                ({ value, defaultValue, comment }) =>
                    (value === 0.5 || value < defaultValue || value - 0.2 > defaultValue) && !comment
            ),
        [sliders]
    );

    const debounceUpdate = useCallback(debounce(updateQuestions, 400), []);

    const changeSlider = (val, index) => {
        const newSliders = [...sliders];
        newSliders[index].value = val;
        setSliders(newSliders);
        const count = sliders.reduce((total, { value }) => total + value, 0);
        setNetAdjustment(count);
    };

    const changeSliderComment = (comment, index) => {
        const newSliders = [...sliders];
        newSliders[index].comment = comment;
        setSliders(newSliders);
    };

    const handleSaveClick = () => {
        // cleanup comments
        const newSliders = [...sliders];
        newSliders.forEach(({ value, defaultValue }, index) => {
            if (!(value === 0.5 || value < defaultValue || value - 0.2 > defaultValue))
                newSliders[index].comment = undefined;
        });

        debounceUpdate({
            beta: {
                marketAssetBeta,
                averageMarketLeveraging,
                marginalInterestRate,
                marginalCorporateTaxRate,
                riskFreeRateOfReturn,
                marketRiskPremium,
                marketValueDebt,
                netAdjustment,
                assetBeta,
                kd,
                afterTaxWACC,
                beforeTaxWACC,
                leveragedBeta,
                ke,
            },
            sliders: newSliders,
        });
    };

    return (
        <Box>
            {sliderTypesBetaFactor.map((group, id) => (
                <Box variant="card.wrapper" key={id}>
                    <Box variant="card.header">{group}</Box>
                    <Box variant="card.container">
                        {sliders.map(({ name, value, type, defaultValue, comment }, index) => {
                            if (type !== group.toLowerCase()) return null;
                            var color = theme.colors.blueLight;
                            if (value !== defaultValue) {
                                if (value === 0.5) color = theme.colors.red;
                                else if (value - 0.1 === defaultValue) color = theme.colors.orangeLight;
                                else if (value - 0.2 === defaultValue) color = theme.colors.orange;
                                else color = theme.colors.red;
                            }
                            return (
                                <Box key={index}>
                                    <ScorecardSlider
                                        color={color}
                                        value={value}
                                        label={name}
                                        onChange={(val) => changeSlider(val, index)}
                                    />
                                    {color === theme.colors.red && (
                                        <SliderReasonBetaFactor
                                            comment={comment}
                                            setComment={(val) => changeSliderComment(val, index)}
                                        />
                                    )}
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
            ))}
            {!props.onboarding ? (
                <Box variant="card.wrapper">
                    <Box variant="card.container">
                        <Box>
                            <Box fontWeight="600" fontSize="0" className={styles.averageMarketLeveraging} mb={4}>
                                <Flex mb={2}>
                                    <Box width={1 / 2}>Average market leveraging</Box>
                                    <Box width={1 / 2}>{parseFloat(averageMarketLeveraging).toFixed(2)}</Box>
                                </Flex>
                                <Flex mb={2}>
                                    <Box width={1 / 2}>Market Asset Beta</Box>
                                    <Box width={1 / 2}>{parseFloat(marketAssetBeta).toFixed(2)}</Box>
                                </Flex>
                                <Flex mb={2}>
                                    <Box width={1 / 2}>Net Adjustment</Box>
                                    <Box width={1 / 2}>{parseFloat(netAdjustment).toFixed(2)}</Box>
                                </Flex>
                                <Flex mb={2}>
                                    <Box width={1 / 2}>Asset Beta</Box>
                                    <Box width={1 / 2}>{parseFloat(assetBeta).toFixed(2)}</Box>
                                </Flex>
                            </Box>

                            <Box mb={4}>
                                <Heading mb={3}>Cost of Debt</Heading>
                                <Flex>
                                    <Box width={1 / 2} mr={3}>
                                        <TextField
                                            label="Marginal interest rate (kmd)"
                                            value={marginalInterestRate}
                                            disabled
                                        />

                                        <TextField
                                            label="Cost of Borrowing Funds (kd)"
                                            value={parseFloat(kd * 100).toFixed(2)}
                                            disabled
                                        />
                                    </Box>
                                    <Box width={1 / 2}>
                                        <TextField
                                            label="Marginal corporate tax rate (t)"
                                            value={marginalCorporateTaxRate}
                                            disabled
                                        />
                                    </Box>
                                </Flex>
                            </Box>

                            <Box mb={4}>
                                <Heading mb={3}>Cost of Equity</Heading>
                                <Flex>
                                    <Box width={1 / 2} mr={3}>
                                        <TextField
                                            label="Risk-free rate of return (Rf)"
                                            value={riskFreeRateOfReturn}
                                            disabled
                                        />
                                    </Box>
                                    <Box width={1 / 2}>
                                        <TextField label="Market Risk Premium (MRP)" value={marketRiskPremium} disabled />
                                    </Box>
                                </Flex>
                            </Box>

                            <Box mb={4}>
                                <Heading mb={3}>Beta</Heading>
                                <Flex>
                                    <Box width={1 / 2} mr={3}>
                                        <TextField label="Asset Beta" value={parseFloat(assetBeta).toFixed(2)} disabled />
                                        <TextField
                                            label="Leveraged Beta (b)"
                                            value={parseFloat(leveragedBeta).toFixed(2)}
                                            disabled
                                        />
                                    </Box>
                                    <Box width={1 / 2}>
                                        <TextField
                                            label="Total Debt / Total Finance (wd)"
                                            value={marketValueDebt}
                                            disabled
                                        />
                                        <TextField
                                            label="Cost of Equity Funds (ke)"
                                            value={parseFloat(ke * 100).toFixed(2)}
                                            disabled
                                        />
                                    </Box>
                                </Flex>
                            </Box>

                            <Box mb={4}>
                                <Heading mb={3}>Weighted Average Cost of Capital</Heading>
                                <Flex>
                                    <Box width={1 / 2} mr={3}>
                                        <TextField
                                            label="Before-Tax WACC"
                                            value={parseFloat(beforeTaxWACC * 100).toFixed(2)}
                                            disabled
                                        />
                                    </Box>
                                    <Box width={1 / 2}>
                                        <TextField
                                            label="After-Tax WACC"
                                            value={parseFloat(afterTaxWACC * 100).toFixed(2)}
                                            disabled
                                        />
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>
                        <Flex alignItems="center">
                            {!hasAllNecessaryData && (
                                <Box color={theme.colors.red}>Please enter all required fields to be able to save</Box>
                            )}
                            <Box ml="auto">
                                <Button variant={hasAllNecessaryData ? 'primary' : 'disabled'} onClick={handleSaveClick}>
                                    Save & Continue
                                </Button>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            ) : (
                <Box variant="card.container">
                    <Flex alignItems="center">
                        <Box ml="auto">
                            <Button variant="primary" onClick={handleSaveClick}>
                                Save
                            </Button>
                        </Box>
                    </Flex>
                </Box>
            )}
        </Box>
    );
};

export default ScorecardBetaFactor;
