import React, { useState, useRef, useEffect } from 'react'
import { Box } from 'rebass'
import { Icon } from '@svg'

export const Accordion = ({
    title,
    caption,
    children,
    groupSx,
    expand = true,
    maxHeight = '1200px',
    customHeader = null,
    hidden,
    ...props
}) => {
    const bodyInner = useRef(null)
    const [expanded, setExpanded] = useState(expand)
    const [_maxHeight, setMaxHeight] = useState(maxHeight);

    useEffect(() => {
        const onResize = () => {
            setMaxHeight(bodyInner.current?.clientHeight ?? maxHeight)
        }
        onResize();
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, [maxHeight, children])

    const CustomHeader = customHeader;
    const hiddenContent = (hidden && expanded) || (!hidden)
    return (
        <Box variant="accordion.wrapper" {...props}>
            {!customHeader ? (
                <Box
                    variant="accordion.header"
                    onClick={() => setExpanded(!expanded)}
                    sx={{
                        py: expanded ? '24px' : '12px'
                    }}
                >
                    <Icon icon="arrowUp" color="#2C2960" title="Expand" className={`animate-transform ${expanded ? undefined : 'arrow--expanded'}`} mr="8px" />
                    {title}
                    {caption && <Box variant="accordion.caption">{caption}</Box>}
                </Box>
            ) : (
                <CustomHeader expanded={expanded} onClick={() => setExpanded(!expanded)} />
            )}
            <Box
                variant="accordion.body"
                sx={{
                    ...groupSx,
                    maxHeight: expanded ? _maxHeight : '0',
                    overflow: expanded ? 'visible' : 'hidden',
                    opacity: expanded ? '1' : '0',
                }}
            >
                {hiddenContent && <Box ref={bodyInner}>
                    {children}
                </Box>}
            </Box>
        </Box>
    )
}
