import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Box, Flex, Link, Heading } from "rebass";
import { Modal } from '~/Common/Modal';
import { getReportPayment, getSetupIntent, purchaseReport } from '@app/Api';
import PaymentMethod from '~/Common/PaymentMethod';
import LoadingSpinner from '~/LoadingSpinner';
import { toast } from 'react-toastify';
import { formatCurrency, getErrorMessage, updateUserStore } from '@util/Functions';
import { Icon } from '@svg/';

const PayForReport = ({ companyId, user }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [secret, setSecret] = useState('');
    const [price, setPrice] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);

        getReportPayment(user.id)
            .then((data) => {
                setPrice(data.price);

                if (data?.intent) {
                    setSecret(data.intent);
                }
            })
            .catch(({ response }) => {
                toast.error(getErrorMessage(response?.data, 'Unable to setup the payment gateway'));
            })
            .finally(() => setLoading(false));
    }, [user])

    const handleIntent = () => {
        setLoading(true);
        getSetupIntent(user.id)
            .then((data) => {
                setSecret(data?.intent);
            })
            .catch(({ response }) => {
                toast.error(getErrorMessage(response?.data, 'Unable to setup the payment gateway'));
            })
            .finally(() => setLoading(false));
    }

    const handlePayment = (e, data = {}) => {
        e.preventDefault();
        setLoading(true);

        const details = { companyId };

        if (data?.paymentMethod) {
            details.paymentMethod = data.paymentMethod;
        }

        purchaseReport(user.id, details)
            .then((data) => {
                updateUserStore();
                toast.success(data?.message ? data.message : 'Chief analyst successfully assigned');
                closeModal();
            })
            .catch(({ response }) => {
                toast.error(getErrorMessage(response?.data, 'Unable to setup the payment gateway'));
            })
            .finally(() => setLoading(false));
    }

    const closeModal = () => {
        setModalOpen(false);
        setSecret('');
    }

    return <>
        <Box>
            <Button variant="primary" type="button" onClick={() => setModalOpen(true)}>
                <Box>Purchase Report Now</Box>
            </Button>
        </Box>
        <Modal
            isOpen={modalOpen}
            flex={true}
            flexDirection="column"
        >
            <Box ml="auto" mb={2}>
                <Link as="a" href="#close" variant="clickable" onClick={closeModal}>
                    <Icon icon="close" stroke="#656D78" title="Close Popup" />
                </Link>
            </Box>
            {loading ? <LoadingSpinner /> : <>
                <Heading textAlign="center">{formatCurrency(price / 100, user.currency, 0, user.lang)} Once Off Payment</Heading>
                <Flex flexDirection="column" width={1} flexGrow="1" justifyContent="center">
                    {secret ? <PaymentMethod secret={secret} afterAction={(e, data) => handlePayment(e, data)} /> : <>
                        {user.pm_last_four ? <Button variant="tertiary" onClick={e => handlePayment(e)} mb={4}>Use Card Ending In {user.pm_last_four}</Button> : null}
                        <Button variant="tertiary" onClick={() => handleIntent()}>Add New Payment Method</Button></>}
                </Flex>
            </>}
        </Modal>

    </>
}

export default connect((state) => ({ user: state.user }))(PayForReport);
