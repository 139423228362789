import React, { useEffect, useState } from 'react'
import { Box, Button, Flex } from 'rebass'
import styles from './Assets/styles.module.scss'
import { useHistory } from "react-router-dom"
import { getTaskStatus } from "@util/Enum"
import { formatDatePretty, getErrorMessage } from "@util/Functions"
import UserAvatar from '~/Dashboard/Avatar'
import PopoutSidebar from '~/Dashboard/PopoutSidebar'
import LoadingSpinner from '~/LoadingSpinner'
import { getNotifications } from '@app/Api'
import { appendNotifications, subtractUnreadNotificationCount } from '@app/Store'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { Icon } from '@svg';

const Notifications = ({ notifications, requestClose, markNotificationIdAsSeen }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const redirectAway = (companyTaskId, seen) => {
        if (!seen) {
            dispatch(subtractUnreadNotificationCount(1));
        }
        if (companyTaskId) {
            history.push(`/company/projects/task/${companyTaskId}`);
        }
        requestClose();
    };
    const [loading, setLoading] = useState(false);

    const handleScroll = () => {
        if (document.querySelector('#popoutSidebarContent').offsetHeight + document.querySelector('#popoutSidebarContent').scrollTop !== document.querySelector('#popoutSidebarContent').scrollHeight || loading) {
            return;
        }
        if (notifications.hasMorePages) {
            const page = JSON.parse(notifications.page) + 1;
            setLoading(true);
            getNotifications({
                params: {
                    page: page
                }
            })
                .then((results) => {
                    dispatch(appendNotifications(results));
                })
                .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to load notifications')))
                .finally(() => setLoading(false));
        }
    };

    useEffect(() => {
        document.querySelector('#popoutSidebarContent').addEventListener('scroll', handleScroll);
        return () => {
            if (document.querySelector('#popoutSidebarContent')) {
                document.querySelector('#popoutSidebarContent').removeEventListener('scroll', handleScroll);
            }
        }
    });

    return (
        <PopoutSidebar title="Notifications" icon="bell" requestClose={requestClose}>
            {notifications.list.map(({ id, notification, created, taskStatus, companyTaskId, firstName, surname, email, avatar, seen, reportId, reportYear, valuationId, companyName }) => {
                if (companyTaskId) {
                    const taskStatusDetail = taskStatus ? getTaskStatus(taskStatus) : null
                    return <Box variant='clickable' key={id} onClick={() => redirectAway(companyTaskId, seen)}>
                        <Flex p={20} className={styles.notificationLine} alignItems="center">
                            <UserAvatar firstName={firstName} surname={surname} email={email} avatar={avatar} />
                            <Box ml={2} width={3 / 4}>
                                <Box>{notification}</Box>
                                <Box className={styles.notificationDate}>{formatDatePretty(created)}</Box>
                            </Box>
                            {taskStatus && <Button variant="task" mt={1} ml={2} width={100} p={2} sx={taskStatusDetail.sx}>{taskStatusDetail.name}</Button>}
                            {!seen && <Box className={styles.notificationIcon} title="New">
                                <Icon icon="notification" />
                            </Box>}
                        </Flex>
                    </Box>
                } else {
                    return <Box variant='clickable' key={id} onClick={() => markNotificationIdAsSeen(id, reportId, valuationId, seen, companyName, reportYear)}>
                        <Flex p={20} className={styles.notificationLine} alignItems="center">
                            <UserAvatar firstName={firstName} surname={surname} email={email} avatar={avatar} />
                            <Box ml={2} width={3 / 4}>
                                <Box>{notification}</Box>
                                <Box className={styles.notificationDate}>{formatDatePretty(created)}</Box>
                            </Box>
                            {(reportId || valuationId) && <Button variant="primarySmall" mt={1} ml={2} width={100} p={2}>
                                {reportId != null ? 'Download' : 'View'}
                            </Button>}
                            {!seen && <Box className={styles.notificationIcon} title="New">
                                <Icon icon="notification" />
                            </Box>}
                        </Flex>
                    </Box>
                }
            })}

            {!notifications.list.length && <Box p={30} className={styles.notificationLine}>There are no notifications</Box>}

            {loading ? <LoadingSpinner /> : null}
        </PopoutSidebar>
    );
}

export default Notifications;
