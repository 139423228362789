import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Flex, Box } from 'rebass';
import { Button } from '~/Common/Button';
import { Card } from '~/Common/Card';
import { Table } from '~/Common/Table';
import { indexCompanyTasks, exportCompanyTasks } from '@app/Api';
import { updateTasks } from '@app/Store';
import { connect } from 'react-redux';
import { TextField, CustomSelect } from '~/Form';
import { debounce } from '@material-ui/core';
import { tasksListColumns } from './columns';
import { useIsMounted } from '@hooks/useIsMounted';

const TasksList = ({ tasks, updateTasks, user, initialSearch, onSearchChange }) => {
    const [filters, setFilters] = useState([]);
    const [loading, setLoading] = useState(true);
    const [orderBy, setOrderBy] = useState(undefined);
    const [currentPage, setPage] = useState(1);
    const [searchInput, setSearchInput] = useState(initialSearch);
    const [search, setSearch] = useState(initialSearch);
    const resolveLoading = useRef(null);
    const userId = user?.id;
    const searchRef = useRef(null);
    const searchValueRef = useRef(null);
    const isMounted = useIsMounted();

    const handleExport = useCallback(() => {
        exportCompanyTasks({
            orderBy,
            filters,
            search,
            page: currentPage,
        });
    }, [orderBy, filters, search, currentPage]);

    useEffect(() => {
        setLoading(true);

        searchValueRef.current = search;

        indexCompanyTasks({
            orderBy,
            filters,
            search,
            page: currentPage,
        })
            .then(({ data }) => {
                if (!isMounted.current) {
                    return;
                }

                // Don't combine search results unless this callback if for the current search
                if (searchValueRef.current === search) {
                    updateTasks({
                        results: data,
                        page: currentPage,
                    });
                    if (resolveLoading.current) {
                        resolveLoading.current(data);
                        resolveLoading.current = null;
                    }
                    setLoading(false);
                }
            })
            .catch(() => setLoading(false));
    }, [updateTasks, userId, filters, orderBy, search, currentPage, isMounted]);

    const debouncedSearch = useCallback(
        debounce((val) => {
            setPage(1);
            setSearch(val);

            if (typeof onSearchChange === 'function') {
                onSearchChange(val);
            }
        }, 1000),
        [setSearch]
    );

    const handleLoadMore = () =>
        new Promise((resolve) => {
            setPage(currentPage + 1);
            resolveLoading.current = resolve;
        });

    const setFilter = (value) => {
        if (Array.isArray(filters)) {
            const fIndex = filters.indexOf(value);
            var newValue = [...filters];
            if (fIndex < 0) {
                newValue.push(value);
            } else {
                newValue.splice(fIndex, 1);
            }
            setFilters(newValue);
        } else {
            setFilters([value]);
        }
    };

    const sortByColumn = (column, order) => {
        setOrderBy(`${column},${order}`);
        setPage(1);
    };

    return (
        <Card title="Tasks" groupSx={{ p: 0 }}>
            <Flex sx={{ p: '10px 24px', bg: '#fff', borderTop: '1px solid #F5F7F8', justifyContent:'space-between' }}>
                <Flex>
                <CustomSelect
                    multi={true}
                    name="filter"
                    variant="inputs.secondary"
                    value={filters}
                    onChange={(option) => {
                        setPage(1);
                        setFilter(option.value);
                    }}
                    text="Filter"
                    icon="filter"
                    clearSelection={() => setFilters([])}
                    optionsTitle="Advanced Filter"
                    optionsLabel="Show me:"
                    buttonStyled={{padding: '0px', background: 'none',}}
                    options={[
                        {
                            value: 'mineOverdue',
                            label: 'Overdue assigned to me',
                        },
                        {
                            value: 'clientsOverdue',
                            label: 'Overdue assigned to clients',
                        },
                        {
                            value: 'mineAll',
                            label: 'All assigned to me',
                        },
                    ]}
                    sx={{
                        ml: 3,
                    }}
                    boxSx={null}
                />
                <Button variant="inputs.secondary" icon="export" sx={{ ml: 3 }} onClick={handleExport}>
                    Export
                </Button>                    
                </Flex>               
                 <Box>
                    <TextField
                        ref={searchRef}
                        variant="inputs.secondary"
                        value={searchInput}
                        onChange={(val) => {
                            setSearchInput(val);
                            debouncedSearch(val);
                        }}
                        placeholder="Search for companies and tasks"
                        icon="search"
                        mb={0}
                        sx={{width:'350px'}}
                        iconColor="#C3C7D0"
                    />
                </Box>


            </Flex>
            <Table
                columns={tasksListColumns}
                columnSort={sortByColumn}
                data={tasks.results}
                isLoading={loading}
                minWidth={1500}
                infiniteScroll={handleLoadMore}
                page={currentPage}
                emptyTemplate={{
                    icon: 'done',
                    heading: 'No tasks',
                    content: 'There are currently upcoming tasks',
                }}
            />
        </Card>
    );
};

export default connect((state) => ({ user: state.user, tasks: state.tasks }), { updateTasks })(TasksList);
