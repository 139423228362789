import React from 'react';
import { Box, Flex, Image } from 'rebass';
import { currentYear } from '@util/Functions';
import { Link } from 'react-router-dom';
import styles from '../Login/styles.module.scss';
import { BackButton } from '~/Common/BackButton';

const GeneralLayout = ({ children, handleBack, maxHeight, ...props }) => {
    return (
        <Box sx={{ textAlign: 'center', py: '50px', bg: '#F5F7F8' }}>
            <Image
                src={`${process.env.PUBLIC_URL}/img/capitaliz-logo.svg`}
                alt="Capitaliz Logo"
                className={styles.companyTitle}
                sx={{ width: 180, p: 0, mb: 4 }}
            />

            <Box
                sx={{
                    margin: '0 auto',
                    maxWidth: '1000px',
                    '@media(min-height: 800px)': {
                        pb: '100px',
                    },
                }}
            >
                {'function' === typeof handleBack && (
                    <Flex sx={{ width: '100%' }}>
                        <BackButton onClick={handleBack} label="Back" mb={3} />
                    </Flex>
                )}
                <Box variant="card.wrapper" bg="white" p={4} pb="58px" maxHeight={maxHeight} overflowY="auto">
                    {children}
                </Box>
            </Box>

            <Box
                sx={{
                    p: 4,
                    textAlign: 'left',
                    fontSize: '10px',
                    color: '#656D78',
                    '@media(min-height: 800px)': {
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                    },
                }}
            >
                <Box mb="10px">
                    <Link to="/terms-conditions">Terms & Conditions</Link>
                </Box>
                <Box>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.capitaliz.com/privacy-policy/">
                        Privacy Policy
                    </a>
                </Box>
                <Box pt={30} fontSize={8}>
                    © {currentYear} Capitaliz Holdings. All right reserved.
                </Box>
            </Box>
        </Box>
    );
};

GeneralLayout.defaultProps = {};

export default GeneralLayout;
