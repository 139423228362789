import React, { useState } from 'react';

export const DashboardContext = React.createContext();

export const DashboardProvider = ({ children, ...props }) => {
    const [title, setTitle] = useState(null);

    return (
        <DashboardContext.Provider
            value={{
                title,
                setTitle
            }}
            {...props}
        >
            {children}
        </DashboardContext.Provider>
    )
}
