import React, { useState } from 'react';
import { Flex, Button } from 'rebass';
import { isAnalyst } from '@util/Functions';
import { connect, useDispatch, useSelector } from 'react-redux';
import styles from './Assets/styles.module.scss';
import { setTrainingMode } from '@app/Store/storeUi';
import { useTranslation } from 'react-i18next';
import CompanyList from '~/Advisor/CompanyList';
import TasksList from '~/Advisor/TasksList';
import { useParams } from 'react-router-dom';
import { BackButton } from '~/Common/BackButton';
import { Tabs } from '~/Common/Tabs';

const OrganisationCompanies = ({ user }) => {
    const [currentTab, setTabValue] = useState('companies');
    const mode = useSelector((state) => state.ui.mode);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { advisorId } = useParams();

    const handleModeClick = (e, mode) => {
        e.preventDefault();
        dispatch(setTrainingMode(mode));
    };

    return (
        <>
            <Flex pt={4}>
                <BackButton to="/organisation/home" label="Back to Dashboard" />
            </Flex>
            <Flex
                sx={{
                    p: '20px',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 3,
                }}
            >
                <Tabs
                    label="Show me"
                    styleVariant="circle"
                    tabs={[
                        {
                            key: 'companies',
                            name: 'All Companies',
                            icon: 'grid',
                        },
                        { key: 'tasks', name: 'All Tasks', icon: 'list' },
                    ]}
                    selected={currentTab}
                    onChange={(tab) => setTabValue(tab.key)}
                    sx={{
                        mb: 0,
                    }}
                />
                {currentTab === 'companies' && isAnalyst(user) && (
                    <Flex flexWrap="wrap" className={styles.buttonGroup}>
                        <Button
                            type="button"
                            variant="tab"
                            className={mode === 'live' ? 'active' : ''}
                            onClick={(e) => handleModeClick(e, false)}
                        >
                            {t('Live')}
                        </Button>
                        <Button
                            type="button"
                            variant="tab"
                            className={mode === 'training' ? 'active red' : ''}
                            onClick={(e) => handleModeClick(e, true)}
                        >
                            {t('Training')}
                        </Button>
                    </Flex>
                )}
            </Flex>

            {currentTab === 'companies' && <CompanyList advisorId={advisorId} />}
            {currentTab === 'tasks' && <TasksList advisorId={advisorId} />}
        </>
    );
};

export default connect((state) => ({ user: state.user }), {})(OrganisationCompanies);
