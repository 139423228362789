import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Flex, Text } from 'rebass';
import { Button } from '~/Common/Button';
import { Card } from '~/Common/Card';
import { Table } from '~/Common/Table';
import { Tabs } from '~/Common/Tabs';
import UserAvatar from '~/Dashboard/Avatar';
import AddUserModal from './AddUserModal';
import { getOrganisation } from '@app/Api/Organisation';
import { AssignCompanies } from './AssignCompanies';
import { setOrganisation } from '@app/Store';
import { hasAdvisorRole, hasCeoRole, hasSupervisorRole, isCeo, isSupervisor } from '@util/Functions/User';

const permissionTabs = [
    {
        key: 'users',
        icon: 'users',
        name: 'Users',
    },
    /* {
        key: 'roles',
        icon: 'roles',
        name: 'Roles',
        disabled: true,
    }, */
];

const Permissions = ({ user, org, setOrganisation }) => {
    const [selectedTab, setSelectedTab] = useState('users');
    const [isAddUserModalOpen, setAddUserModalOpen] = useState(false);
    const orgId = org?.id;

    const columns = [
        {
            key: 'user',
            title: 'User',
            render: (user, isSubrow) => (
                <Flex sx={{ alignItems: 'center' }}>
                    <UserAvatar
                        name={user.name}
                        avatar={user.avatar}
                        mr={2}
                        sx={{ transform: isSubrow ? 'scale(0.75)' : '' }}
                    />
                    {user.name}
                </Flex>
            ),
        },
        {
            key: 'role',
            title: 'Role',
            render: (row) => <Text>{row.roles.map((r) => r.name).join(', ')}</Text>,
        },
        {
            key: 'supervises',
            title: 'Supervises',
            render: (row) => (hasSupervisorRole(row) || hasCeoRole(row)) ?
                <AssignCompanies user={row} companyKey='supervises' allowAssign={isCeo(user)} /> :
                <></>,
        },
        {
            key: 'advises',
            title: 'Advises',
            render: (row) => (hasAdvisorRole(row) || hasSupervisorRole(row) || hasCeoRole(row)) ?
                <AssignCompanies user={row} allowAssign={isSupervisor(user) || isCeo(user)} /> :
                <></>,
        },
    ];

    useEffect(() => {
        getOrganisation(orgId).then(({ data }) => setOrganisation(data));
    }, [orgId, setOrganisation]);

    return (
        <>
            {permissionTabs.length > 1 && (
                <Tabs
                    styleVariant="circle"
                    tabs={permissionTabs}
                    selected={selectedTab}
                    onChange={(tab) => setSelectedTab(tab.key)}
                    sx={{ ml: 3 }}
                />
            )}
            {selectedTab === 'users' && (
                <Card title="Users" icon="users" groupSx={{ p: 0 }}>
                    <Flex sx={{ p: '10px 24px', bg: '#fff', borderTop: '1px solid #F5F7F8' }}>
                        {isCeo(user) && <Button variant="smallGray" icon="plus" onClick={() => setAddUserModalOpen(true)}>
                            Add User
                        </Button>}
                    </Flex>
                    <Table
                        columns={columns}
                        data={org?.structure?.members}
                        isLoading={!Array.isArray(org?.structure?.members)}
                        minWidth={1500}
                        emptyTemplate={{
                            icon: 'users',
                            heading: 'No users',
                            content: 'There are currently no organisation users',
                        }}
                    />
                </Card>
            )}
            {isCeo(user) && <AddUserModal isOpen={isAddUserModalOpen} setOpen={setAddUserModalOpen} />}
        </>
    );
};

export default connect((state) => ({ user: state.user, org: state.organisation }), { setOrganisation })(Permissions);
