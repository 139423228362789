import axios from 'axios'

export const wysiwygUploadImage = (image) =>
    axios.post('advisor/company/image/upload', image)
        .then(({ data }) => data)

export const wysiwygUploadFile = (file) =>
    axios.post('advisor/company/file/upload', file)
        .then(({ data }) => data)

export const wysiwygGetFile = (fileLocation) =>
    axios.request({ url: `company/file/get`, method: 'POST', responseType: 'blob', data: { fileLocation } })
        .then(({ data }) => data)