export const TRANSLATIONS_EN_US = {
    companyOverview: {
        value_maximisation: 'Value maximization for {{name}}'
    },
    normalisation: {
        title: 'Normalization',
        subtitle: ''
    },
    reportsEdit: {
        subtitle: 'You can customize the report by adding pages, disabling pages or reordering page sequence on the right-hand side. Once completed, please press “Submit Report” button to send the report to our Analyst for review.'
    },
    reports: {
        valuation: 'Please finalize the valuation before approving the report for advisor review',
        finalise: 'Lock Report (No Further Changes)'
    },
    valuations: {
        cardTitle: 'This function will produce a financial valuation report, which can be adjusted as required, but will be locked once finalized.'
    },
    'Upload detailed financial statements for the last five years, budgets, financial modelling, and manage adjustments.': 'Upload detailed financial statements for the last five years, budgets, financial modeling, and manage adjustments.',
    'Compliance Uptodate': 'Compliance Up-to-date',
    'Other (user defined risk)': 'Business Risk (non-financial)',
    'Risk Free Rate Of Return': 'Risk-Free Rate Of Return',
    'After Tax W A C C': 'After-Tax WACC',
    'Before Tax W A C C': 'Before-Tax WACC',
    'Inventory (wip) Days': 'Inventory (WIP) Days',
    'Non-current Liabilities (ex Debt)': 'Non-current Liabilities (ex-Debt)',
    'Nopat %': 'NOPAT %',
    'Ecroce': 'ECROCE',
    'Mgmt Skills / Performance Record': 'Mgmt. Skills / Performance Record',
    'Stage Three: Maximise Value': 'Stage Three: Maximize Value',
    'Credit Scorecard analysing the likelihood of access to finance.': 'Credit Scorecard analyzing the likelihood of access to finance.',
    'Specialised Asset': 'Specialized Asset',
    'An analysis of non-financial business variables and prioritising action on areas of underperformance or risk to the business': 'An analysis of non-financial business variables and prioritizing action on areas of underperformance or risk to the business',
    'Determining whether a business is in the growth and expansion phase or efficiency phase where a focus on building systemised processes and efficiencies is required to protect the value of the business': 'Determining whether a business is in the growth and expansion phase or efficiency phase where a focus on building systemized processes and efficiencies is required to protect the value of the business',
    'A documented policies and procedures manual in place for each aspect of the business covering behaviour, systems, and operating machinery amongst others.': 'A documented policies and procedures manual in place for each aspect of the business covering behavior, systems, and operating machinery amongst others.',
    'A centralised Customer Relationship Management tool that captures key individual customer data and enables it to be manipulated into marketing campaigns.': 'A centralized Customer Relationship Management tool that captures key individual customer data and enables it to be manipulated into marketing campaigns.',
    'An analysis of the existing team against the ideal organisation chart for the future, staff development plans, and key roles needed to be filled.': 'An analysis of the existing team against the ideal organization chart for the future, staff development plans, and key roles needed to be filled.',
    'Does the sales process utilise automated online campaigns to help close business sales and proposals?': 'Does the sales process utilize automated online campaigns to help close business sales and proposals?',
    'Is standardised psychometric testing used in your hiring process?': 'Is standardized psychometric testing used in your hiring process?',
    'Are you using and analysing data to manage your business?': 'Are you using and analyzing data to manage your business?',
    'Which is the most applicable statement at your organisation?': 'Which is the most applicable statement at your organization?',
    'Is functional responsibility spread appropriately among individuals in the organisation?': 'Is functional responsibility spread appropriately among individuals in the organization?',
    'Is functional responsibility spread appropriately among individuals in the organisation': 'Is functional responsibility spread appropriately among individuals in the organization?',
    'Capitalisation Multiple (intrinsic)': 'Capitalization Multiple (intrinsic)',
    'Capitalisation Multiple (industry comparative)': 'Capitalization Multiple (industry comparative)',
    'Enterprise Value (Capitalisation of Earnings Method)': 'Enterprise Value (Capitalization of Earnings Method)',
    'Enterprise Value Override': 'Enterprise Value Override',
    '% of salary for a Fixed portion of Labour (essential workers that cannot be laid off at shutdown)': '% of salary for a Fixed portion of Labor (essential workers that cannot be laid off at shutdown)',
    'Direct Labour (directly involved in production of products / provision of services)': 'Direct Labor (directly involved in production of products / provision of services)',
    'All Other Labour (Management, Sales, Admin, Support, Other, excl. Owners)': 'All Other Labor (Management, Sales, Admin, Support, Other, excl. Owners)',
    'Finalise': 'Finalize',
    'This is the projected FY NOPAT (normalised).': 'This is the projected FY NOPAT (normalized).'
};
