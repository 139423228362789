import { getReportView } from '@app/Api';
import { getErrorMessage } from '@util/Functions';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Box } from 'rebass';
import LoadingSpinner from '~/LoadingSpinner';
import { BackButton } from '~/Common/BackButton';
import styles from './reportStyles.css';

const AdvisorReportView = ({ reportId, companyId }) => {
    const [reportView, setReportView] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getReportView({ reportId })
            .then((report) => {
                setReportView(report);
                setLoading(false);
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to load report details')));
    }, [reportId]);

    if (loading) return <LoadingSpinner />;

    return (
        <Box mt={4}>
            <BackButton to={`/advisor/company/${companyId}/reports`} label="Back to Reports" />
            {reportView && <Box p="2" backgroundColor="white" dangerouslySetInnerHTML={{ __html: reportView }} className={styles.wrapper} />}
        </Box>
    );
};

export default AdvisorReportView;
