import React, { useEffect, useState, useCallback } from 'react';
import { Box, Button } from 'rebass';
import { Label } from '@rebass/forms';
import { VerifyField, TextField } from '~/Form';
import { confirmTwoFactor, getTwoFactorQrCode, loginTwoFactorChallengeUser } from '@app/Api';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '~/LoadingSpinner';
import { updateUserStore } from '@util/Functions';
import CredentialsLayout from '../CredentialsLayout/CredentialsLayout';

const LoginTwoFactorChallenge = ({ twoFactorConfirmed, ...props }) => {
    const [code, setCode] = useState('');
    const [recoveryCode, setRecoveryCode] = useState('');
    const [mode, setMode] = useState('code');
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [twoFactorQrCode, setTwoFactorQrCode] = useState(null);
    const { t } = useTranslation();

    const handleSubmit = useCallback(
        (e) => {
            if (e !== undefined) {
                e.preventDefault();
            }

            if (isSubmitting) {
                return;
            }

            setIsSubmitting(true);

            if (code !== '' || recoveryCode !== '') {
                if (twoFactorConfirmed) {
                    loginTwoFactorChallengeUser({ code: code, recovery_code: recoveryCode, device_name: 'app' })
                        .then(() => {
                            localStorage.removeItem('authTokenScope');
                            updateUserStore();
                            setMode('code');
                        })
                        .catch(({ response }) => {
                            response?.data?.errors && setErrors(response.data.errors);
                            setIsSubmitting(false);
                        })
                        .finally(() => {
                            setCode('');
                            setRecoveryCode('');
                        });
                } else {
                    confirmTwoFactor({
                        code: code,
                        device_name: 'app',
                    })
                        .then(() => {
                            localStorage.removeItem('authTokenScope');
                            updateUserStore();
                            setMode('code');
                        })
                        .catch(({ response }) => {
                            response?.data?.errors && setErrors(response.data.errors);
                            setIsSubmitting(false);
                        });
                }
            }
        },
        [code, recoveryCode, twoFactorConfirmed, isSubmitting]
    );

    useEffect(() => {
        if (isSubmitting) {
            return;
        }

        if (!twoFactorConfirmed && !twoFactorQrCode) {
            setIsSubmitting(true);
            getTwoFactorQrCode()
                .then((response) => setTwoFactorQrCode(response?.svg))
                .finally(() => setIsSubmitting(false));
        }

        // Auto submit once a 6 digit code is in place
        if (code.length >= 6) {
            handleSubmit();
        }
    }, [code, handleSubmit, isSubmitting, twoFactorConfirmed, twoFactorQrCode]);

    return (
        <CredentialsLayout
            heading={t('login.two_factor_title', { site: process.env.REACT_APP_NAME })}
            subheading={t(
                twoFactorConfirmed
                    ? 'Please enter the 6-digit security code provided in your authenticator app:'
                    : 'settings.two_factor.instructions.qrCode'
            )}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            {...props}
        >
            {isSubmitting ? (
                <LoadingSpinner />
            ) : (
                <>
                    {mode === 'code' ? (
                        <>
                            {!twoFactorConfirmed && twoFactorQrCode && (
                                <div dangerouslySetInnerHTML={{ __html: twoFactorQrCode }} />
                            )}

                            <Box my={3}>
                                <VerifyField
                                    name="code"
                                    errors={errors.code}
                                    onChangeOverride={(val) => {
                                        setCode(val);
                                    }}
                                />
                            </Box>

                            {twoFactorConfirmed && (
                                <Link
                                    as="a"
                                    to="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setMode('recovery_code');
                                    }}
                                >
                                    {t('Supply a recovery code instead')}
                                </Link>
                            )}
                        </>
                    ) : (
                        <>
                            <Box my={3}>
                                <Label mb={4} sx={{ justifyContent: 'center' }}>
                                    {t('Please enter your recovery code below:')}
                                </Label>

                                <TextField
                                    label={t('Recovery code')}
                                    name="recovery_code"
                                    value={recoveryCode}
                                    onChange={(val) => setRecoveryCode(val)}
                                    errors={errors.code}
                                />
                            </Box>

                            <Button variant="primary" width="100%" type="submit" mb={3}>
                                {t('buttons.submit')}
                            </Button>

                            <Link
                                as="a"
                                to="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setMode('code');
                                }}
                                ml={2}
                            >
                                {t('Supply a code instead')}
                            </Link>
                        </>
                    )}
                </>
            )}
        </CredentialsLayout>
    );
};

export default LoginTwoFactorChallenge;
