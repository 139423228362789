import { COMPANY, ADVISOR, CEO, SUPERVISOR, ANALYST, CHIEF_ANALYST, ADMIN, CONSULTANT, accessLevelLabels } from "@util/Enum/User";

const companyAccess = [COMPANY, CONSULTANT];
const advisorAccess = [ADVISOR, CEO, SUPERVISOR];
const adminAccess = [ADMIN];
const analystAccess = [ANALYST, CHIEF_ANALYST];
const chiefAnalystAccess = [CHIEF_ANALYST];
const ceoAccess = [CEO];
const supervisorAccess = [SUPERVISOR];
const consultantAccess = [CONSULTANT];

const permissions = (level, access) => level && access.includes(level);


export const isCompany = (user) => permissions(user?.accessLevel, companyAccess);
export const isAdvisor = (user) => permissions(user?.accessLevel, advisorAccess);
export const isAdmin = (user) => permissions(user?.accessLevel, adminAccess);
export const isAnalyst = (user) => permissions(user?.accessLevel, analystAccess);
export const isChiefAnalyst = (user) => permissions(user?.accessLevel, chiefAnalystAccess);
export const isCeo = (user) => permissions(user?.accessLevel, ceoAccess) || user?.has_ceo_access;
export const isSupervisor = (user) => permissions(user?.accessLevel, supervisorAccess);
export const isConsultant = (user) => permissions(user?.accessLevel, consultantAccess);

export const hasSupervisorRole = (user) => user?.roles?.find(r => r.id === SUPERVISOR);
export const hasAdvisorRole = (user) => user?.roles?.find(r => r.id === ADVISOR);
export const hasCeoRole = (user) => user?.roles?.find(r => r.id === CEO);

export const isCompanyOnboarded = (user) => user?.companyStatus > 1;

export const isTestUser = (user) => user !== null && user?.live !== true;

export const getRoleLabel = (accessLevel) => accessLevelLabels?.[accessLevel] ?? '';

export const redirectAuthenticated = (user) => {
    const isAuthenticated = !!user;
    if (isAuthenticated) {
        if (isAdmin(user)) return '/admin-error';
        else if (isCeo(user) || isSupervisor(user)) return '/organisation/home';
        else if (isAdvisor(user) || isAnalyst(user)) return '/advisor/home';
        else if (isConsultant(user)) return '/consultant/home';
        else if (isCompanyOnboarded(user)) return '/company/dashboard';
        else return '/company/onboarding';
    }
};
