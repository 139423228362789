import React from "react";
import { Modal } from "~/Common/Modal";
import { Box, Flex, Heading, Button } from "rebass";

const FilterModal = ({ isOpen, requestClose, filters, setFilters, setFilter, resetFilters, ...props }) => {
    let alphabetOptions = [];

    for (let i = 65; i <= 90; i++) {
        alphabetOptions.push(String.fromCharCode(i));
    }

    return (
        <Modal
            isOpen={isOpen}
            ariaHideApp={false}
            requestClose={requestClose}
            showClose={true}
            title={'Filters'}
            variant={'light'}
            minWidth={'720px'}
        >
            <Flex>
                <Box sx={{ minWidth: '200px' }}>
                    <Heading as={'h3'} sx={{ whiteSpace: 'nowrap' }}>Filter companies</Heading>
                </Box>
                <Flex style={{ gap: '12px', justifyContent: 'space-between', flexGrow: 1 }}>
                    <Button
                        variant="paginations"
                        width={1}
                        className={filters?.filter === 'all' ? 'active' : undefined}
                        title="Show all companies"
                        onClick={() => { setFilters(current => ({ ...current, filter: 'all', page: 1 })) }}
                    >
                        All
                    </Button>
                    <Button
                        variant="paginations"
                        width={1}
                        className={filters?.filter === 'active' ? 'active' : undefined}
                        title="Filter by active companies"
                        onClick={() => { setFilters(current => ({ ...current, filter: 'active', page: 1 })) }}
                    >
                        Active
                    </Button>
                    <Button
                        variant="paginations"
                        width={1}
                        className={filters?.filter === 'recentlyViewed' ? 'active' : undefined}
                        title="Filter by recently viewed companies"
                        onClick={() => { setFilters(current => ({ ...current, filter: 'recentlyViewed', page: 1 })) }}
                    >
                        Recently Viewed
                    </Button>
                </Flex>
            </Flex>
            <hr />
            <Flex>
                <Box sx={{ minWidth: '200px' }}>
                    <Heading as={'h3'} sx={{ whiteSpace: 'nowrap' }}>Filter by name</Heading>
                </Box>
                <Box style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr', gap: '16px' }}>
                    <Button
                        mb={0}
                        variant="paginations"
                        title="Show all companies"
                        className={!filters?.letters?.length ? 'active' : undefined}
                        onClick={() => { setFilters(current => ({ ...current, page: 1, letters: [] })) }}
                        sx={{
                            gridColumn: '1 / 3'
                        }}
                    >
                        All
                    </Button>
                    <Button
                        mb={0}
                        variant="paginations"
                        className={filters?.letters?.includes('#') ? 'active' : undefined}
                        title="Filter by company names that begin with a number or special character"
                        onClick={() => { setFilter('letters', '#'); }}
                    >
                        #
                    </Button>
                    {alphabetOptions.map((letter) =>
                        <Button
                            mb={0}
                            variant="paginations"
                            key={`letter-${letter}`}
                            className={filters?.letters?.includes(letter) ? 'active' : undefined}
                            title={`Filter by company names that begin with ${letter}`}
                            onClick={() => { setFilter('letters', letter); }}
                        >
                            {letter}
                        </Button>
                    )}
                </Box>
            </Flex>
            <hr style={{ marginRight: '-16px', marginLeft: '-16px' }} />
            <Flex style={{ justifyContent: 'space-between', alignItems: 'center', paddingTop: '18px' }}>
                <Box
                    onClick={() => resetFilters()}
                >
                    <Heading sx={{
                        fontSize: '14px',
                        cursor: 'pointer'
                    }}>
                        Clear all
                    </Heading>
                </Box>

                <Flex style={{ gap: '8px', alignItems: 'center' }}>
                    <Button
                        variant="paginations"
                        onClick={() => requestClose()}
                        sx={{
                            fontSize: '14px',
                            marginBottom: '0px',
                            color: 'captionGray'
                        }}
                    >
                        Cancel
                    </Button>
                </Flex>
            </Flex>
        </Modal>
    );
};

export default FilterModal;
