import React from 'react';
import { Card, CardItem } from '~/Common/Card';
import TeamUserTile from '~/Organisation/TeamUserTile';
import LoadingSpinner from '~/LoadingSpinner';
import { Box, Heading } from 'rebass';
import UserAvatar from '~/Dashboard/Avatar';
import { Badge } from '~/Common/Badge';

const BadgeWrap = ({ sx, children, ...props }) => (
    <Box sx={{ flex: '100%', display: 'flex', justifyContent: 'right', ...sx }} {...props}>{children}</Box>
)

export const TeamListCEO = ({ heading, emptyMessage, teams, isLoading }) => {
    return (isLoading ? <LoadingSpinner />
        : (
            Array.isArray(teams) && teams.length > 0 && <>
                <Heading m={3} sx={{ color: 'blueDark2', fontSize: '16px' }}>
                    {heading}
                </Heading>
                {teams.map(({ supervisor, advisors }) => {
                    const stats = supervisor?.statistics;
                    return Array.isArray(advisors) && advisors.length > 0 && (
                        <Card
                            key={supervisor.id}
                            title={
                                <>
                                    <UserAvatar
                                        name={supervisor.name}
                                        email={supervisor.email}
                                        avatar={supervisor.avatar}
                                        mr={2}
                                        sx={{ transform: 'scale(0.8)' }}
                                    />
                                    {supervisor.name}
                                </>
                            }
                            subtitle={`${advisors.length} Advisors`}
                            collapsable={true}
                            collapse={!advisors.length}
                            titleSx={{
                                py: 2,
                                px: 3,
                                fontSize: 16,
                            }}
                        >
                            <Box sx={{
                                display: 'grid',
                                gridTemplate: 'auto / repeat(5, 1fr)',
                                mx: -4,
                                mt: '-24px',
                                mb: '24px',
                                borderTop: '1px solid #E8EAEF',
                            }}>
                                <CardItem title="Onboarding" variant="statBorderless">
                                    {stats?.onboarding?.total ?? (isLoading ? <LoadingSpinner size="30" /> : 'N/A')}
                                    {!!stats?.onboarding?.overdue && (
                                        <BadgeWrap>
                                            <Badge variant={'error'}>{stats?.onboarding?.overdue} overdue</Badge>
                                        </BadgeWrap>
                                    )}
                                </CardItem>
                                <CardItem title="In Progress" variant="statBorderless">
                                    {stats?.inProgress?.total ?? (isLoading ? <LoadingSpinner size="30" /> : 'N/A')}
                                    {!!stats?.inProgress?.overdue && (
                                        <BadgeWrap>
                                            <Badge variant={'error'}>{stats?.inProgress?.overdue} overdue</Badge>
                                        </BadgeWrap>
                                    )}
                                </CardItem>
                                <CardItem title="In Review" variant="statBorderless">
                                    {stats?.inReview?.total ?? (isLoading ? <LoadingSpinner size="30" /> : 'N/A')}
                                    {!!stats?.inReview?.overdue && (
                                        <BadgeWrap>
                                            <Badge variant={'error'}>{stats?.inReview?.overdue} overdue</Badge>
                                        </BadgeWrap>
                                    )}
                                </CardItem>
                                <CardItem title="Projects" variant="statBorderless">
                                    {stats?.projects?.total ?? (isLoading ? <LoadingSpinner size="30" /> : 'N/A')}
                                    {!!stats?.projects?.overdue && (
                                        <BadgeWrap>
                                            <Badge variant={'purple'}>{stats?.projects?.overdue} inactive</Badge>
                                        </BadgeWrap>
                                    )}
                                </CardItem>
                                <CardItem title="Archive" variant="statBorderless">
                                    {stats?.archive?.total ?? (isLoading ? <LoadingSpinner size="30" /> : 'N/A')}
                                </CardItem>
                            </Box>
                            <Box sx={{ display: 'grid', gridTemplate: 'auto / repeat(4, 1fr)', gridGap: '24px' }}>
                                {advisors.length
                                    ? advisors.map((userData) => <TeamUserTile key={userData.id} user={userData} />)
                                    : emptyMessage}
                            </Box>
                        </Card>
                    )
                })}
            </>
        )
    );
};
