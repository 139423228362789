import axios from 'axios'

export const getReports = (reports) =>
    axios.post('company/report/list', reports)
        .then(({ data }) => data)

export const updateReport = (report) =>
    axios.post('advisor/report/update', report)
        .then(({ data }) => data)

export const createReport = (report) =>
    axios.post('advisor/report/create', report)
        .then(({ data }) => data)

export const getReport = (report) =>
    axios.post('advisor/report/get', report)
        .then(({ data }) => data)

export const getReportView = (report) =>
    axios.post('advisor/report/get-view', report)
        .then(({ data }) => data)

export const downloadDraftReport = (reportId, watermark = null) =>
    axios.request({ url: `company/report/draft`, method: 'POST', responseType: 'blob', data: { reportId, watermark } })
        .then(({ data }) => data)

export const submitReport = (report) =>
    axios.post('advisor/report/submit', report)
        .then(({ data }) => data)

export const previewReport = (reportId) =>
    axios.request({ url: `advisor/report/preview`, method: 'POST', responseType: 'blob', data: { reportId } })
        .then(({ data }) => data)

export const downloadReport = (reportId) =>
    axios.request({ url: `company/report/download`, method: 'POST', responseType: 'blob', data: { reportId } })
        .then(({ data }) => data)

export const setReportType = (reportId, report_type_id) =>
    axios
        .post(`advisor/report/${reportId}`, {
            report_type_id,
        })
        .then(({ data }) => data);

export const addAdvisorNotes = (reportId, notes) =>
    axios.post(`advisor/report/${reportId}/notes`, { notes })
        .then(({ data }) => data);

export const createReportPage = (reportId) =>
    axios.post('advisor/report/create-page', reportId)
        .then(({ data }) => data)

export const approveReport = (report) =>
    axios.post('advisor/report/approve', report)
        .then(({ data }) => data)

export const reworkReport = (report) =>
    axios.post('advisor/report/rework', report)
        .then(({ data }) => data)

export const shareReport = (report) =>
    axios.post('advisor/report/share', report)
        .then(({ data }) => data)
