import React from "react";
import { Modal } from '~/Common/Modal';
import { Button } from '~/Common/Button';
import { SelectField } from "~/Form";

const ReportTypeModal = ({
    isOpen,
    handleCreateReport,
    setReportTypeId,
    setIsOpen,
    reportTypeId,
    types
}) => {
    return (
        <Modal
            isOpen={isOpen}
            showClose={true}
            requestClose={() => setIsOpen(false)}
            title={'Choose the report type'}
            flex={true}
            flexDirection={'column'}
            maxWidth={'540px'}
            titleSx={{ borderRadius: '16px 16px 0 0' }}
        >
            <SelectField
                placeholder="Select report type"
                name="reportType"
                label="Report Type"
                boxSx={{ width: "100%", mb: 4 }}
                onChange={(value) => setReportTypeId(value)}
                value={reportTypeId}
                options={Array.isArray(types)
                    ? types.map((type) => ({
                        label: type.name,
                        value: type.id,
                    }))
                    : []}
            />
            <Button
                as={'button'}
                disabled={reportTypeId === null}
                variant={reportTypeId === null ? 'disabled' : 'primary'}
                onClick={() => handleCreateReport()}
            >
                Create
            </Button>
        </Modal>
    );
};

export default ReportTypeModal;