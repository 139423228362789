import { createSlice } from '@reduxjs/toolkit'

const companies = createSlice({
    name: 'companies',
    initialState: [],
    reducers: {
        setCompanies: (state, { payload }) => payload,
        resetCompanies: () => [],
        updateCompany: (state, { payload }) => {
            if (state.find(c => c.id === payload.id)) {
                return state.map(c => c.id === payload.id ? payload : c)
            } else {
                state.push(payload)
                return state
            }
        }
    }
})

export const { setCompanies, resetCompanies, updateCompany } = companies.actions

export default companies.reducer
