import React from 'react'
import { Box } from 'rebass'
import { Label } from '@rebass/forms'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const customStyles = {
    control: (base, state) => ({
        ...base,
        // none of react-select's styles are passed to <Control />
        padding: '5px 5px',
        marginBottom: '20px',
        border: state.isFocused ? '1px solid #19CEFF' : '1px solid rgba(132, 146, 166, 0.2)',
        outline: state.isFocused ? '3px auto #19CEFF' : 0,
        borderRadius: '5px',
        backgroundColor: 'white',
        fontSize: '16px',
        fontFamily: 'Open Sauce Two, system-ui, sans-serif',
        '&:hover': {
            border: '1px solid #19CEFF',
        },
        boxShadow: 'none'
    }),
    option: (base, state) => ({
        ...base,
        color: state.isSelected ? '#093467' : '#2C2960',
        backgroundColor: state.isFocused ? (state.isSelected ? '#19CEFF' : 'rgba(132, 146, 166, 0.2)') : (state.isSelected ? '#19CEFF' : 'white')
    })
}


const GooglePlacesAutocompleteField = React.forwardRef(
    (
        { value, onChange, name, errors = null, label = null, helpText = '', placeholder, options, disabled, sx, boxSx = { marginBottom: 20 }, nullable = false },
        ref
    ) => (
    <Box sx={boxSx}>
        {label && <Label htmlFor={name} mb={1} flexDirection='column'>{label} {helpText && <><br /><small>{helpText}</small></>}</Label>}
        <GooglePlacesAutocomplete
            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            autocompletionRequest={{
                componentRestrictions: {
                    country: ['us', 'ca', 'uk', 'au'],
                }
            }}
            selectProps={{
                id: name,
                name: name,
                ref: ref,
                placeholder: 'Start typing your address...',
                styles: customStyles,
                disabled: disabled,
                onChange: onChange
            }}
        />

        {errors && (
            <Box mt="2px" mb={20} color='red' fontSize={12}>
                {errors[0]}
            </Box>
        )}
    </Box>))


export default GooglePlacesAutocompleteField
