import React, { useEffect, useState } from 'react';
import { Box, Flex } from 'rebass';
import { createReport, getReports, shareReport } from '@app/Api';
import { toast } from 'react-toastify';
import {
    currentFinancialYear,
    defaultCompanyLogoLink,
    downloadReportFile,
    formatDateMonthYear,
    getErrorMessage,
    isAdvisor,
    ReportCreated,
    ReportFinalised,
    ReportShared,
    permit,
    yearsList,
    ReportAdviserReview,
    downloadDraftReportFile
} from '@util/Functions';
import LoadingSpinner from '~/LoadingSpinner';
import styles from './styles.module.scss';
import { connect } from 'react-redux';
import { Icon } from '@svg';
import { Link, useHistory } from 'react-router-dom';
import { PageTitle } from '~/Common/PageTitle';
import { theme } from '@app/theme';
import { isAnalyst, isCompany } from '@util/Functions/User';
import { useTranslation } from "react-i18next";
import PayForReport from '../PayForReport';
import { SelectField } from '~/Form';
import { Button } from '~/Common/Button';
import { getBIRFilename } from '@util/Functions/Company';
import ReportTypeModal from '~/Modals/ReportTypeModal';

const Reports = ({ companyId = null, user, companyData }) => {
    const [loading, setLoading] = useState(true);
    const [reports, setReports] = useState([]);
    const [available, setAvailable] = useState(false);
    const [errors, setErrors] = useState(null);
    const [reportYear, setReportYear] = useState(currentFinancialYear);
    const [isOpen, setIsOpen] = useState(false);
    const [reportTypeId, setReportTypeId] = useState(null);
    const [types, setTypes] = useState([])

    const isAdvisorOrAnalyst = isAdvisor(user) || isAnalyst(user);
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true);
        getReports({ companyId })
            .then((response) => {
                setReports(response.reports);
                setAvailable(response.available);
                setTypes(response.types);
            })
            .catch(({ response }) => {
                toast.error(getErrorMessage(response?.data, 'Unable to load reports'))
            })
            .finally(() => setLoading(false));

    }, [companyId]);


    if (loading) return <LoadingSpinner />;

    const handleCreateReport = () => {
        if (companyId) {
            createReport({ year: reportYear, companyId, report_type_id: parseInt(reportTypeId) })
                .then((reportId) => {
                    history.push(`/advisor/company/${companyId}/reports/edit/${reportId}`);
                })
                .catch(({ response }) => {
                    if (response.data.status) setErrors(response.data.status);
                    if (response.data.status) setIsOpen(false);
                    toast.error(getErrorMessage(response?.data, 'Unable to create report'));
                });
        }
    };

    const handleDownloadReport = (reportId, companyData, reportYear) =>
        downloadReportFile({ reportId, fileName: getBIRFilename(companyData, reportYear) });

    const isReportStatusCreated = (status) => status === ReportCreated;

    const isReportStatusFinalised = (status) => status === ReportFinalised;

    const isReportShared = (status) => status === ReportShared;

    const linkStyle = { color: '#19ceff', marginLeft: 5, textDecoration: 'underline' };

    const share = (reportId) => {
        setLoading(true);
        shareReport({ reportId })
            .then((response) => {
                setReports(response.reports);
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to load reports')))
            .finally(() => setLoading(false));
    }

    const handleDownloadReview = (reportId, companyData, year) => {
        setLoading(true);
        downloadDraftReportFile(
            { reportId, fileName: `REVIEW ${getBIRFilename(companyData, year)}` },
            companyData?.versions?.reports,
            'REVIEW ONLY'
        ).finally(() => setLoading(false));
    };



    return <>
        <ReportTypeModal
            setIsOpen={setIsOpen}
            handleCreateReport={handleCreateReport}
            setReportTypeId={setReportTypeId}
            isOpen={isOpen}
            reportTypeId={reportTypeId}
            types={types}
        />
        <Box>
            {isAdvisorOrAnalyst && !available && (
                <Flex width={1}>
                    <Box variant='warning' width={1}>
                        <Box variant='warningHeader'>Report Not Available</Box>
                        <Box variant='card.container'>
                            <Box>Purchase a report credit to enable the creation of a new insights report.</Box>
                            {isAdvisor(user) && <Box mt={2}><PayForReport companyId={companyId} /></Box>}
                        </Box>
                    </Box>
                </Flex>
            )}
            <Box px={3}>
                <PageTitle
                    subtitle={t('reports.subtitle')}
                >
                    {isAdvisorOrAnalyst && available && permit(user.permissions, 'reports.create') && (
                        <Flex ml="auto" alignItems="center">
                            <SelectField
                                name="year"
                                value={reportYear}
                                onChange={val => setReportYear(val)}
                                options={yearsList()}
                                sx={{ padding: '10px 30px 10px 16px', minWidth: '200px', fontSize: 14, fontWeight: 600 }}
                                boxSx={null}
                            />
                            <Button variant="primary" onClick={() => setIsOpen(true)} sx={{ ml: 3, py: 2, lineHeight: '27px' }}>
                                <Box pr={1}>Create {t('reports.singular')}</Box>
                                <Icon
                                    icon="addCircle"
                                    stroke={theme.colors.primary}
                                    title={`Add ${t('reports.singular')}`}
                                    width={21}
                                    height={21}
                                />
                            </Button>
                        </Flex>
                    )}
                </PageTitle>
            </Box>

            {errors && (
                <Box p={20} variant="card.wrapper">
                    <Box mb={1}>
                        {t('reports.errors.create')}
                    </Box>
                    <Box mt={2}>
                        <Flex alignItems="center" mb={1}>
                            {errors.financials ? (
                                <Icon icon="completedNo" size="18" />
                            ) : (
                                <Icon icon="completedYes" size="18" />
                            )}
                            <Box fontSize={1} fontWeight={600} ml={1}>
                                Financials History
                                {errors.financials ? (
                                    <Link to={`/advisor/company/${companyId}/financial-reports`} style={linkStyle}>
                                        edit
                                    </Link>
                                ) : (
                                    ''
                                )}
                            </Box>
                        </Flex>
                        <Flex alignItems="center" mb={1}>
                            {errors.customVariables ? (
                                <Icon icon="completedNo" size="18" />
                            ) : (
                                <Icon icon="completedYes" size="18" />
                            )}
                            <Box fontSize={1} fontWeight={600} ml={1}>
                                Custom Variables
                                {errors.customVariables ? (
                                    <Link to={`/advisor/company/${companyId}/custom-variables`} style={linkStyle}>
                                        edit
                                    </Link>
                                ) : (
                                    ''
                                )}
                            </Box>
                        </Flex>
                        <Flex alignItems="center" mb={1}>
                            {errors.scorecard ? (
                                <Icon icon="completedNo" size="18" />
                            ) : (
                                <Icon icon="completedYes" size="18" />
                            )}
                            <Box fontSize={1} fontWeight={600} ml={1}>
                                Scorecards
                                {errors.scorecard ? (
                                    <Link to={`/advisor/company/${companyId}/scorecards`} style={linkStyle}>
                                        edit
                                    </Link>
                                ) : (
                                    ''
                                )}
                            </Box>
                        </Flex>
                        <Flex alignItems="center" mb={1}>
                            {errors.valuation ? (
                                <Icon icon="completedNo" size="18" />
                            ) : (
                                <Icon icon="completedYes" size="18" />
                            )}
                            <Box fontSize={1} fontWeight={600} ml={1}>
                                Valuation
                                {errors.valuation ? (
                                    <Link to={`/advisor/company/${companyId}/valuations`} style={linkStyle}>
                                        edit
                                    </Link>
                                ) : (
                                    ''
                                )}
                            </Box>
                        </Flex>
                        <Flex alignItems="center" mb={1}>
                            {errors.addbacks ? (
                                <Icon icon="completedNo" size="18" />
                            ) : (
                                <Icon icon="completedYes" size="18" />
                            )}
                            <Box fontSize={1} fontWeight={600} ml={1}>
                                Addbacks
                                {errors.addbacks ? (
                                    <Link to={`/advisor/company/${companyId}/addbacks`} style={linkStyle}>
                                        edit
                                    </Link>
                                ) : (
                                    ''
                                )}
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            )}

            {reports?.length > 0 && (
                <Flex sx={{ flexWrap: 'wrap' }}>
                    {reports.map(({ id, year, company, updated, status, permissions }) => (
                        <Box
                            variant="card.wrapper"
                            p={20}
                            width={1 / 3}
                            key={id}
                            sx={{ bg: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                        >
                            <Flex mb={3}>
                                <img
                                    src={companyData.logo || defaultCompanyLogoLink}
                                    alt="Company"
                                    onError={(e) => (e.target.src = defaultCompanyLogoLink)}
                                    className={styles.companyLogo}
                                />
                                <Box fontWeight={600}>Insights {t('reports.singular')} - {year}</Box>
                            </Flex>
                            <Box>
                                <Box as="p" fontSize={12} mb={3}>
                                    {t('reports.summary')}
                                </Box>

                                <hr color="#19CEFF" />

                                <Flex mt={3}>
                                    <Box fontSize={12} mr={3} fontWeight="600">
                                        Last Edit:
                                    </Box>
                                    <Box fontSize={12} color="#6F7782" ml="auto">
                                        {formatDateMonthYear(updated, 'N/A')}
                                    </Box>
                                </Flex>
                                <Flex mt={3}>
                                    {isReportStatusFinalised(status) && isAdvisorOrAnalyst && <Button
                                        mr="1"
                                        variant="gray"
                                        width={1 / 2}
                                        fontSize={12}
                                        onClick={() => history.push(`/advisor/company/${companyId}/reports/view/${id}`)}
                                    >
                                        View {t('reports.singular')}
                                    </Button>}
                                    {permit(permissions, 'report.download') && (
                                        <Button
                                            flex={1}
                                            variant="gray"
                                            onClick={() => handleDownloadReport(id, companyData, year)}
                                            fontSize={12}
                                        >
                                            Download {t('reports.singular')}
                                        </Button>
                                    )}
                                    {status === ReportAdviserReview && isCompany(user) && <Button
                                        variant="gray"
                                        width={1}
                                        fontSize={12}
                                        onClick={() => handleDownloadReview(id, companyData, year)}
                                    >
                                        Download Review
                                    </Button>}
                                </Flex>
                                {
                                    isAdvisorOrAnalyst && !isReportStatusFinalised(status) && !isReportShared(status) && (
                                        <Link to={`/advisor/company/${companyId}/reports/edit/${id}`}>
                                            <Button mt={3} variant="gray" width={1} fontSize={12}>
                                                {isReportStatusCreated(status) ? 'Edit' : 'View'} {t('reports.singular')}
                                            </Button>
                                        </Link>
                                    )
                                }
                                {
                                    permit(permissions, 'report.share') && (
                                        <Button mt={2} variant="gray" width={1} fontSize={12} onClick={() => share(id)}>
                                            {t('reports.finalise')}
                                        </Button>
                                    )
                                }
                            </Box >
                        </Box >
                    ))}
                </Flex >
            )}
            {
                !reports?.length && (
                    <Box p={20} variant="card.wrapper">
                        {t('reports.noresults')}
                    </Box>
                )
            }
        </Box >
    </>
};

export default connect((state) => ({ user: state.user, companyData: state.company }))(Reports);
