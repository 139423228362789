import React from 'react'
import sanitizeHtml from 'sanitize-html'

const defaultOptions = {
    allowedTags: ['b', 'i', 'u', 's', 'em', 'strong', 'a', 'img', 'ul', 'li', 'ol', 'p'],
    allowedAttributes: {
        'a': ['href'],
        'img': ['src']
    }
}

const sanitize = (dirty, options) =>
    ({ __html: sanitizeHtml(dirty, { ...defaultOptions, ...options }) })

const SanitizedField = ({ html, options }) =>
    <div dangerouslySetInnerHTML={sanitize(html, options)} />

export default SanitizedField