import React from 'react';
import { Icon } from '@svg';
import styles from './styles.module.scss';
import { Box } from 'rebass';

const Tooltip = ({ children, text, icon, sx, boxSx, width = '300px', ...props }) => {
    return (
        <Box className={styles.tooltip} sx={boxSx} {...props}>
            {children}
            {icon && <Icon icon={icon} />}
            <Box className={styles.tooltiptext} sx={{
                textAlign: 'left',
                width,
                ...sx
            }}>{text}</Box>
        </Box>
    )
};

export default Tooltip;
