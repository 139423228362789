/*
 * Download file data
 * https://stackoverflow.com/questions/13405129/create-and-save-a-file-with-javascript
 */
export const downloadFile = (data, filename, type) => {
    const file = new Blob([data], { type })

    // IE10+
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, filename)
      return
    }

    const a = document.createElement('a')
    const url = URL.createObjectURL(file)

    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()

    setTimeout(() => {
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    }, 0)
  }
