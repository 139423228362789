import { Icon } from '@svg';
import { getScorecardQuestions, updateCompanyScorecard, updateBetaFactor } from '@app/Api';
import { BetaFactorSliderScorecard, EsgScorecard, formatNumber, getErrorMessage, isAdvisor, updateUserStore } from '@util/Functions';
import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Box, Button, Flex, Heading } from 'rebass';
import { SelectField, AreaField } from '~/Form';
import ScorecardSpecialField from './SpecialField';
import ScorecardBetaFactor from './BetaFactor';
import styles from './styles.module.scss';
import LoadingSpinner from '~/LoadingSpinner';
import ScorecardResults from './Results';
import { useHistory } from 'react-router-dom';
import { BackButton } from '~/Common/BackButton';
import { debounce } from '@material-ui/core';
import { PageTitle } from '~/Common/PageTitle';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';

const FinaliseAndSave = ({ finalise, disableInput, nextSection }) => {
    const { t } = useTranslation();

    return <Flex flexDirection={'row'} alignItems={'flex-start'} justifyContent={'end'}>
        <Button variant={disableInput ? 'disabled' : 'secondary'} onClick={() => finalise()} disabled={disableInput}>
            {t('Finalise')}
        </Button>
        <Button variant={disableInput ? 'disabled' : 'primary'} onClick={() => nextSection()} disabled={disableInput} sx={{ ml: 2 }}>
            Save
        </Button>
    </Flex>
}

const AdvisorEditScorecard = ({ companyId, scorecardId, onboarding = false, betaFactor = null, children, user, ...props }) => {
    const [loading, setLoading] = useState(false);
    const [questions, setQuestions] = useState(null);
    const [type, setType] = useState(null);
    const [scorecardType, setScorecardType] = useState(null);

    const [secId, setSecId] = useState(null);
    const [groups, setGroups] = useState(null);

    const [sliders, setSliders] = useState(false);
    const [results, setResults] = useState(false);

    const [completedOn, setCompletedOn] = useState(false);
    const [disableInput, setDisableInput] = useState(false);
    const [canGenerateScorecard, setCanGenerateScorecard] = useState(false);
    const history = useHistory();

    const questionsEl = useRef();
    const { t } = useTranslation();

    const overseasCustomersId = 191;
    const forexId = 192;
    const forexExposureId = 193;

    useEffect(() => {
        if (onboarding && betaFactor !== null) {
            setLoading(true);
            setType(BetaFactorSliderScorecard);
            setQuestions(betaFactor.questions);
            viewSpecialSection('sliders');
            setLoading(false);
        } else if (scorecardId) {
            setLoading(true);
            getScorecardQuestions({ scorecardId })
                .then(({ data, permissions }) => {
                    const questions = data?.questions;
                    const completedOn = data?.completedOn;

                    setType(data?.type?.id);
                    setScorecardType(data?.type?.name);
                    setQuestions(questions);
                    setCompletedOn(completedOn);
                    setDisableInput(!!completedOn);
                    setCanGenerateScorecard(permissions?.generate);

                    // Open Appropriate Tab
                    if (questions.sections?.length) changeSection(0, questions.sections[0].groups);
                    else if (questions.sliders) viewSpecialSection('sliders');
                    else viewSpecialSection('results');

                    setLoading(false);
                })
                .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to get scorecard')));
        }
    }, [scorecardId, onboarding, betaFactor]);

    useEffect(() => {
        questionsEl && questionsEl.current && questionsEl.current.scrollIntoView({ behavior: 'smooth' });
    }, [secId]);

    const sectionsCompleted = useMemo(() => {
        // Keep Track of all completed sections
        return (
            questions?.sections?.map(({ groups }) => {
                return !groups.some((section) => {
                    return section.questions.some(({ value, dependsOnScorecardAnswerIds, specialFieldRender }) => {
                        const isEmpty =
                            value === null ||
                            value === '' ||
                            (value instanceof Array && value.some((arrayVal) => arrayVal === null || arrayVal === ''));
                        if (!isEmpty) return false;
                        const isCalculatedField = specialFieldRender?.type === 'calculated'
                        const isVisible =
                            !isCalculatedField && (!dependsOnScorecardAnswerIds ||
                                groups.some(({ questions }) =>
                                    questions.some(({ value }) => dependsOnScorecardAnswerIds.split(',').includes(value))
                                ));
                        return isVisible;
                    });
                });
            }) || []
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questions, groups]);

    const advisorOnboardingBypass = onboarding && isAdvisor(user)

    const handleUpdateScorecard = (complete = false, q = null, taskId = null) =>
        updateCompanyScorecard({ id: scorecardId, questions: q || questions, complete, taskId }).catch(({ response }) =>
            toast.error(getErrorMessage(response?.data, 'Unable to update scorecard'))
        );

    const debounceUpdate = useCallback(
        debounce((q) => handleUpdateScorecard(false, q), 400),
        []
    );

    if (loading) return <LoadingSpinner />;

    const changeAnswer = (val, questionId, groupId, specialFieldResult = 0, debounce = false) => {
        const newGroups = [...groups];
        newGroups[groupId].questions[questionId].result =
            specialFieldResult || getAnswerResult(newGroups[groupId].questions[questionId].options, parseInt(val));
        newGroups[groupId].questions[questionId].value = val;
        newGroups[groupId].result = getGroupResult(newGroups[groupId].questions);
        setGroups(newGroups);
        debounce ? debounceUpdate(questions) : handleUpdateScorecard();
    };

    const toggleNotApplicableResult = (sectionId, groupId) => {
        const newQuestions = { ...questions };
        if (newQuestions.sections && newQuestions.sections.length) {
            const naValue = !newQuestions.sections[sectionId].groups[groupId].na
            newQuestions.sections[sectionId].groups[groupId].na = naValue
            newQuestions.sections[sectionId].groups[groupId].result = naValue ? 100 : getGroupResult(newQuestions.sections[sectionId].groups[groupId].questions)
        }
        else if (newQuestions.results && newQuestions.results.length) {
            const naValue = !newQuestions.results[sectionId].groups[groupId].na
            newQuestions.results[sectionId].groups[groupId].na = naValue
            newQuestions.results[sectionId].groups[groupId].result = naValue ? 100 : getGroupResult(newQuestions.results[sectionId].groups[groupId].questions)
        }
        setQuestions(newQuestions)
        debounceUpdate(questions)
    }

    const getAnswerResult = (options, val) => options.find(({ value }) => value === val)?.weight || 0;

    const getGroupResult = (questions) => {
        let groupResult = questions.reduce((total, { result, dependsOnScorecardAnswerIds }) => {
            let score = 0

            if (!!dependsOnScorecardAnswerIds) {
                if (checkDependableAnswer(dependsOnScorecardAnswerIds)) {
                    score = parseFloat(result) || 0
                }
            } else {
                score = parseFloat(result) || 0
            }

            return parseFloat(total) + score
        }, 0);

        // INFO: This is a workaround for calculating the foreign exchange exposure result for the foundation scorecard.
        // If the scorecard results are not being calculated correctly this is likely the culprit.
        if (questions.find(question => [overseasCustomersId, forexId, forexExposureId].includes(question.id))) {
            const forex = questions.find(question => question.id === forexId);

            if (forex && checkDependableAnswer(forex?.dependsOnScorecardAnswerIds)) {
                const forexResult = parseFloat(forex?.result || 0);

                const overseasCustomers = questions.find(question => question.id === overseasCustomersId);
                const overseasCustomersResult = parseFloat(overseasCustomers?.result || 0);

                // If the result of these two questions equals 100, the result is 100. Otherwise, sum together all three questions.
                if (overseasCustomersResult + forexResult === 100.0) {
                    groupResult = 100.0;
                }
            }
        }

        return groupResult
    }

    const changeComment = async (comment, groupId) => {
        const newGroups = [...groups];
        newGroups[groupId].comment = comment;
        setGroups(newGroups);
        debounceUpdate(questions);
    };

    const changeSection = (sectionId, groups) => {
        setSliders(false);
        setResults(false);
        setSecId(sectionId);
        setGroups(groups);
    };

    const checkDependableAnswer = (ids) =>
        groups?.some(({ questions }) => questions.some(({ value }) => ids.split(',').includes(value)));

    const viewSpecialSection = (section) => {
        setSliders(section === 'sliders');
        setResults(section === 'results');
        setGroups(null);
        setSecId(null);
    };

    const submitReport = () =>
        handleUpdateScorecard(true).then(() => {
            updateUserStore().then(() => history.push(`/advisor/company/${companyId}/scorecards`));
        });

    const updateScorecardBetaFactor = (questions) => {
        setQuestions(questions);
        if (!onboarding) {
            updateCompanyScorecard({ id: scorecardId, questions, complete: false })
                .then(() => {
                    toast.success('Scorecard updated successfully');
                    viewSpecialSection('results');
                })
                .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to update scorecard')));
        } else {
            setLoading(true);
            updateBetaFactor({ companyId, questions, taskId: props.task?.id, year: betaFactor?.year })
                .then(() => {
                    setLoading(false);
                    history.push(`/advisor/company/${companyId}/onboarding`);
                    toast.success('Beta factor updated successfully');
                })
                .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to update beta factor')));
        }
    };

    const nextSection = () => {
        const sections = questions?.sections?.length - 1;

        if ((!onboarding || advisorOnboardingBypass) && secId >= sections) {
            viewSpecialSection('results');
        } else {
            const newSectionId = secId >= sections ? 0 : secId + 1;

            if (!completedOn) {
                handleUpdateScorecard().then(() => {
                    if (newSectionId === 0) {
                        toast.success(t('Scorecard responses saved'));
                    }
                });
            }

            changeSection(newSectionId, questions.sections[newSectionId].groups);
        }
    };

    const finalise = () => {
        setLoading(true);
        handleUpdateScorecard(true, questions, props.task?.id)
            .then(() => {
                setDisableInput(true);
                history.push(`/advisor/company/${companyId}/onboarding`);
                toast.success('Responses saved successfully');
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to save responses')))
            .finally(() => setLoading(false));
    }

    const isSectionCompleted = (sectionId) => {
        return sectionsCompleted?.length > sectionId && sectionsCompleted[sectionId];
    };

    const isFinalSection = () => secId >= questions?.sections?.length - 1;

    const getSectionContent = (id) => {
        switch (type) {
            case EsgScorecard:
                switch (id) {
                    case 0:
                        return 'Considers the impact of environmental risk factors on the industry/business and the processes the business utilises to manage risks.';
                    case 1:
                        return 'Calculates the social impact of your business and its policies.';
                    case 2:
                        return 'Looks at the governance factors, including key person dependency, board structure and composition, management team and Health and safety management.';
                    default:
                        break;
                }
                break;
            default:
                switch (id) {
                    case 0:
                        return 'Considers Governance risk from 5 key areas: Key Person Dependence, Board Structure and composition, Management Team and OH&S';
                    case 1:
                        return 'Considers risks in  Business Planning; Feedback sources; Cultural alignment, Management Succession and Sales';
                    case 2:
                        return 'Considers People retention and culture risks from the perspectives of HR, Training, Compensation and Incentivisation.';
                    case 3:
                        return 'Considers the type of business analysis, forecasting and reporting tools and resources available to decisionmakers to be able to make informed decisions.';
                    case 4:
                        return 'Considers the robustness of risk management policies and procedures, Business Continuity, Business and Shareholder agreements and Insurances';
                    default:
                        break;
                }
                break;
        }
    };

    return (
        <Box>
            <Box px={3}>
                <PageTitle title={props.heading ? props.heading : `${scorecardType} Scorecard`} />
            </Box>

            <Box sx={{
                mt: 4,
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: '22px',
            }}>
                {!!(new URLSearchParams(window.location.search)).get('onboarding') ?
                    <BackButton to={`/advisor/company/${companyId}/onboarding`} label={"Back to Onboarding"} m={0} /> :
                    <BackButton to={props.backLink ? props.backLink : `/advisor/company/${companyId}/scorecards`} label={props.backLabel ? props.backLabel : "Back to Scorecards"} m={0} />
                }
                {children}
            </Box>

            <Flex ref={questionsEl}>
                <Box width={1 / 3}>
                    <Box sx={{ position: 'sticky', top: 30 }}>
                        {props.taskComponent}
                        {questions?.sections?.map(({ name: sectionName, groups }, sectionId) => (
                            <Box
                                className={styles.reportBlock}
                                onClick={() => changeSection(sectionId, groups)}
                                key={sectionId}
                                backgroundColor={secId === sectionId ? 'white' : null}
                                variant="card.wrapper"
                            >
                                <Flex>
                                    <Box width={1}>
                                        <Heading fontSize={16} fontWeight="600" mb={2}>
                                            {sectionId + 1}. {t(sectionName)}
                                        </Heading>
                                        <Box className={styles.reportName}>{getSectionContent(sectionId)}</Box>
                                    </Box>
                                    <Flex px={2} alignItems="center">
                                        {isSectionCompleted(sectionId) ? (
                                            <Icon icon="success" fill={'#19CEFF'} title="View Section" height={15} />
                                        ) : (
                                            <Icon icon="arrow" stroke="#00AEEF" title="View Section" />
                                        )}
                                    </Flex>
                                </Flex>
                            </Box>
                        ))}

                        {type === BetaFactorSliderScorecard && questions?.sliders && (
                            <Box
                                className={styles.reportBlock}
                                variant="card.wrapper"
                                onClick={() => viewSpecialSection('sliders')}
                                backgroundColor={sliders ? 'white' : null}
                            >
                                <Flex>
                                    <Box width={1}>
                                        <Heading fontSize={16} fontWeight="600" mb={2}>
                                            Beta Factor
                                        </Heading>
                                        <Box className={styles.reportName}>Business Valuation</Box>
                                    </Box>
                                    <Flex px={2} alignItems="center">
                                        <Icon icon="arrow" stroke="#00AEEF" title="View Section" />
                                    </Flex>
                                </Flex>
                            </Box>
                        )}

                        {(!onboarding || advisorOnboardingBypass) && (
                            <Box
                                className={styles.reportBlock}
                                onClick={() => viewSpecialSection('results')}
                                variant="card.wrapper"
                                backgroundColor={results ? 'white' : null}
                                mb={1}
                            >
                                <Flex>
                                    <Box width={1}>
                                        <Heading fontSize={16} fontWeight="600">
                                            Review Results
                                        </Heading>
                                    </Box>
                                    <Flex px={2} alignItems="center">
                                        <Icon icon="arrow" stroke="#00AEEF" title="View Section" />
                                    </Flex>
                                </Flex>
                            </Box>
                        )}
                    </Box>
                </Box>

                <Box width={2 / 3}>
                    {groups && (
                        <Box variant="card.wrapper">
                            {groups.map(({ name, result, comment, questions }, groupId) => (
                                <Box key={groupId}>
                                    <Box variant="card.header">
                                        {secId + 1}.{groupId + 1} {t(name)}
                                    </Box>
                                    <Box variant="card.container">
                                        <Heading
                                            mb={3}
                                            className={styles.groupHeader}
                                            fontWeight="600"
                                            color="blueLight2"
                                        >
                                            {name}
                                        </Heading>
                                        {questions.map(
                                            (
                                                {
                                                    question,
                                                    options,
                                                    value,
                                                    specialFieldRender,
                                                    dependsOnScorecardAnswerIds,
                                                    htmlContent
                                                },
                                                questionId
                                            ) =>
                                                (!dependsOnScorecardAnswerIds ||
                                                    checkDependableAnswer(dependsOnScorecardAnswerIds)) && (
                                                    <Box key={questionId} fontSize={14}>
                                                        <Box pb={2} fontWeight="600" fontSize={0}>
                                                            {t(question)}
                                                        </Box>
                                                        {htmlContent && <Box pb={2} fontWeight="600" fontSize={0} dangerouslySetInnerHTML={{ __html: htmlContent }} />}
                                                        <Box>
                                                            {!specialFieldRender && (
                                                                <SelectField
                                                                    value={value}
                                                                    onChange={(val) =>
                                                                        changeAnswer(val, questionId, groupId)
                                                                    }
                                                                    options={options}
                                                                    sx={{
                                                                        padding: '10px 30px 10px 16px',
                                                                        minWidth: '200px',
                                                                        fontSize: '14px',
                                                                        fontWeight: 600,
                                                                    }}
                                                                    disabled={disableInput}
                                                                />
                                                            )}
                                                            {specialFieldRender && (
                                                                <ScorecardSpecialField
                                                                    name={name}
                                                                    options={options}
                                                                    field={specialFieldRender}
                                                                    value={value || ''}
                                                                    onChange={(val, result) =>
                                                                        changeAnswer(
                                                                            val,
                                                                            questionId,
                                                                            groupId,
                                                                            result,
                                                                            true
                                                                        )
                                                                    }
                                                                    disabled={disableInput}
                                                                />
                                                            )}
                                                        </Box>
                                                    </Box>
                                                )
                                        )}
                                        <Box fontSize={12} fontWeight={600} mb={3}>
                                            Mark for {name}: {formatNumber(result, 0) || 0} / 100%
                                        </Box>
                                        <Box pb={2} fontWeight="600" fontSize={0}>
                                            Other Comment from Advisor
                                        </Box>
                                        <AreaField
                                            value={comment}
                                            onChange={(comment) => changeComment(comment, groupId)}
                                            disabled={disableInput}
                                        />
                                    </Box>
                                </Box>
                            ))}
                            <Box textAlign="right" pr={4} pb={4}>
                                {onboarding && !advisorOnboardingBypass && isFinalSection() ?
                                    <FinaliseAndSave
                                        finalise={finalise}
                                        disableInput={disableInput}
                                        nextSection={nextSection}
                                    /> :
                                    <Button variant="primary" onClick={() => nextSection()}>
                                        Continue To Next Step
                                    </Button>
                                }
                            </Box>
                        </Box>
                    )}

                    {type === BetaFactorSliderScorecard && sliders && (
                        <ScorecardBetaFactor questions={questions} updateQuestions={updateScorecardBetaFactor} onboarding={onboarding} />
                    )}

                    {results && (
                        <Box variant="card.wrapper">
                            <ScorecardResults
                                questions={questions}
                                submitReport={() => submitReport()}
                                completedOn={completedOn}
                                reportName={scorecardType}
                                type={type}
                                toggleNotApplicableResult={toggleNotApplicableResult}
                                scorecardId={scorecardId}
                                canGenerateScorecard={canGenerateScorecard}
                                finalise={onboarding && <FinaliseAndSave
                                    finalise={finalise}
                                    disableInput={disableInput}
                                    nextSection={nextSection}
                                />}
                            />
                        </Box>
                    )}
                </Box>
            </Flex>
        </Box>
    );
};

export default connect((state) => ({ user: state.user }))(AdvisorEditScorecard);
