import React, { Suspense, useRef, useState, useEffect } from "react";
import { Flex, Box } from 'rebass';
import { Skeleton } from '~/Common/Skeleton';
import LoadingSpinner from "~/LoadingSpinner";

const RenderOnViewportEntry = ({
  children,
  setPageNumber,
  pageNumber,
  threshold = 0,
  root = null,
  rootMargin = "0px 0px 0px 0px",

  ...wrapperDivProps
}) => {
  const screenRef = useRef(null);
  const [entered, setEntered] = useState(false);

  useEffect(() => {
    const currentScreenRef = screenRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setEntered(true);
          observer.disconnect();
        }
      },
      { threshold, root, rootMargin }
    );

    if (currentScreenRef) {
      observer.observe(currentScreenRef);
    }

    return () => {
      if (currentScreenRef) {
        observer.unobserve(currentScreenRef);
      }
    };

  }, [threshold, root, rootMargin, setPageNumber, pageNumber]);

  return (
    <div {...wrapperDivProps} ref={screenRef}>
      {entered ? <Suspense fallback={<LoadingSpinner />}>{children}</Suspense> :
        <>
          <Box variant="card.wrapper" width={1} sx={{ bg: 'white', my: '12px', minWidth: '356px', height: '250px' }}>
            <Box p={4} px="16px" pt={'40px'} sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'flex-end'
            }}>

              <Skeleton height="10px" width="80px" sx={{
                position: 'absolute',
                top: 0,
                left: '20px',
                borderRadius: '0px 0px 8px 8px',
                padding: '6px 10px 4px 10px',
              }} />

              <Flex>
                <Skeleton variant="circle" size="32px" mr={2} />
                <Skeleton height="32px" width="70%" />
              </Flex>

              <Box sx={{
                flex: 1,
                mt: '18px',
                fontSize: '12px'
              }}>
                <Skeleton height="16px" width="100%" mb={1} />
                <Skeleton height="16px" width="100%" mb={1} />
                <Skeleton height="16px" width="100%" mb={1} />
              </Box>

              <Box justifyContent="space-between" alignItems="end" sx={{ mt: '18px' }}>
                <Skeleton variant="button" height="48px" />
              </Box>
            </Box>
          </Box>
        </>}
    </div>
  );
};

export default RenderOnViewportEntry;
