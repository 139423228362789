import { createSlice } from '@reduxjs/toolkit'

const initialState = [];

const analysts = createSlice({
    name: 'analysts',
    initialState,
    reducers: {
        setAnalysts: (state, { payload }) => payload,
        resetAnalysts: () => initialState
    }
})

export const { setAnalysts, resetAnalysts, fetchAnalysts } = analysts.actions

export default analysts.reducer
