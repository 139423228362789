import React from 'react';
import { Link, Flex, Heading, Text, Box } from 'rebass';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon } from '@svg';
import UserAvatar from '~/Dashboard/Avatar';
import { StatusTag } from '~/Dashboard/StatusTag';

const TeamUserTile = ({ children, user, groupSx, ...props }) => {
    const { t } = useTranslation();
    const companies = user.companies;
    const stats = user.statistics;

    return (
        <Box
            variant="card.wrapper"
            width={1}
            sx={{
                bg: 'white',
                m: 0,
                '& .hover--color': {
                    transition: 'color 0.3s ease-in-out',
                },
                '&:hover .hover--color': {
                    color: '#19CEFF',
                },
            }}
            {...props}
        >
            <Box
                p={4}
                px="16px"
                pt={'40px'}
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'flex-end',
                    flex: 0,
                }}
            >
                <Flex sx={{ flex: 0, flexDirection: 'column', alignItems: 'center' }}>
                    <UserAvatar sx={{ mb: 1 }} name={user.name} avatar={user.avatar} />
                    <Heading color="blueDark" fontWeight={600} fontSize="16px">
                        {user.name}
                    </Heading>
                    <Text variant="light">{user.role}</Text>
                </Flex>

                <Flex
                    sx={{
                        mt: '18px',
                        fontSize: '12px',
                        textAlign: 'center',
                        whiteSpace: 'pre-wrap',
                    }}
                >
                    <Box sx={{ flex: 1 }}>
                        <Text variant="dark">{stats?.onboarding?.total ?? 0}</Text>
                        <Text variant="lightSmall" px={2}>
                            {t("Onboarding")}
                        </Text>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Text variant="dark">{stats?.inProgress?.total ?? 0}</Text>
                        <Text variant="lightSmall" px={2}>
                            {t("In Progress")}
                        </Text>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Text variant="dark">{stats?.inReview?.total ?? 0}</Text>
                        <Text variant="lightSmall" px={2}>
                            {t("In Review")}
                        </Text>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Text variant="dark">{stats?.projects?.total ?? 0}</Text>
                        <Text variant="lightSmall" px={2}>
                            {t("Projects")}
                        </Text>
                    </Box>
                </Flex>

                {Array.isArray(companies) && !!companies.length && (
                    <Flex sx={{ mt: 3, alignItems: 'center', flexDirection: 'column', flex: 1 }}>
                        {companies.map((company, index) => (
                            <Link
                                key={index}
                                variant="tileLink"
                                as={RouterLink}
                                to={`/advisor/company/${company.id}/overview`}
                                sx={{ width: '100%' }}
                            >
                                <Flex
                                    sx={{
                                        overflow: 'hidden',
                                        alignItems: 'center',
                                        p: '7px 12px',
                                        flexWrap: 'wrap',
                                        gap: '8px',
                                    }}
                                >
                                    <Box sx={{ flex: 1 }}>{company.name}</Box>
                                    <Flex className="company--actions">
                                        <Icon icon="paper2" sx={{ ml: '7px' }} className="no-hover" />
                                        <Icon icon="chart" sx={{ ml: '12px' }} />
                                    </Flex>
                                    <Box sx={{ display: 'flex', gap: '6px', flex: '100%' }}>
                                        <StatusTag
                                            status={company.status}
                                            inReview={company.inReview}
                                            overdue={company.overdue}
                                            inactive={company.inactive}
                                            archived={!!company.archivedAt}
                                        />
                                    </Box>
                                </Flex>
                            </Link>
                        ))}
                    </Flex>
                )}

                {children && (
                    <Box
                        sx={{
                            mt: '20px',
                            ...groupSx,
                        }}
                        justifyContent="space-between"
                        alignItems="end"
                    >
                        {children}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default TeamUserTile;
