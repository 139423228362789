import { createSlice } from '@reduxjs/toolkit';

const actionItems = createSlice({
    name: 'actionItems',
    initialState: {
        status: 'idle',
        currentCompanyId: null,
        actionItems: [],
        assignees: [],
        companyTasks: [],
        all: {
            status: 'idle',
            data: [],
        }
    },
    reducers: {
        setActionItems: (state, { payload }) => ({
            status: 'complete',
            all: {
                ...state.all,
                ...payload.all,
            },
            ...state,
            ...payload
        }),
        resetActionItems: () => null,
    },
});

export const { setActionItems, resetActionItems } = actionItems.actions;

export default actionItems.reducer;
