import { getScorecardResult } from '@app/Api';
import { getErrorMessage, BetaFactorSliderScorecard, ucWordsCamelCase, isCompany, StrategicGrowthScorecard, RiskScorecard } from '@util/Functions';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Box } from 'rebass';
import LoadingSpinner from '~/LoadingSpinner';
import StandardScorecardView from '~/Dashboard/ScorecardView/StandardScorecardView.js';
import SliderScorecard from '~/Dashboard/ScorecardView/SliderScorecard.js';
import { BackButton } from '~/Common/BackButton';
import { PageTitle } from '~/Common/PageTitle';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";

const ScorecardView = ({ scorecardId, user }) => {
    const [scorecard, setScorecard] = useState(null);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (scorecardId) {
            setLoading(true);
            getScorecardResult({ scorecardId })
                .then((scorecard) => {
                    setLoading(false);
                    setScorecard(scorecard.data);
                })
                .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to get financials')));
        }
    }, [scorecardId]);

    if (loading || !scorecard) return <LoadingSpinner />;

    const isCompanyUser = isCompany(user)
    const betaRatios = scorecard?.questions?.beta ? Object.entries(scorecard.questions.beta) : [];
    const isBetaFactorScorecard = scorecard.type.id === BetaFactorSliderScorecard
    const isStrategicGrowth = scorecard.type.id === StrategicGrowthScorecard
    const isRiskScorecard = scorecard.type.id === RiskScorecard
    const isStandardScorecard = !isBetaFactorScorecard && !isRiskScorecard
    const pageTitle = isBetaFactorScorecard ? 'Beta Factor' : isStrategicGrowth ? 'Strategic Growth' : isRiskScorecard ? 'Risk' : 'Foundation'

    return (
        <Box>
            {isStandardScorecard && <StandardScorecardView scorecard={scorecard} />}
            {!isStandardScorecard && (
                <>
                    <Box px={3}>
                        <PageTitle title={`${pageTitle} Scorecard`} />
                    </Box>
                    <Box mt={4}>
                        <BackButton to={isCompanyUser ? `/company/scorecards` : `/advisor/company/${scorecard.companyId}/scorecards`} label="Back to Scorecards" />
                    </Box>
                    <Box pb={2}>
                        <Box variant="card.wrapper">
                            <Box variant="card.header">{`${pageTitle} Assessment`}</Box>
                            <Box variant="card.container">
                                <SliderScorecard sliders={scorecard?.result} scorecardType={scorecard.type.id} finalResult={scorecard?.finalResult} />
                            </Box>
                        </Box>

                        {isBetaFactorScorecard && <Box variant="card.wrapper">
                            <Box variant="card.header">Ratios Assessment </Box>
                            <Box variant="card.container">
                                {betaRatios.map(([key, value], index) => (
                                    <Box key={index} mb={2} fontSize={1}>
                                        <b>{t(ucWordsCamelCase(key))}</b>: {parseFloat(value).toFixed(2)}
                                    </Box>
                                ))}
                            </Box>
                        </Box>}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default connect((state) => ({ user: state.user }))(ScorecardView)
