import React from "react";
import { Label } from "@rebass/forms";
import { Checkbox as RebassCheckbox } from "@rebass/forms";

export const Checkbox = ({
    children,
    labelVariant = "forms.checkbox.label",
    sx,
    label,
    ...props
}) => {
    const element = (
        <RebassCheckbox sx={sx} {...props}>
            {children}
        </RebassCheckbox>
    );

    if (label) {
        return (
            <Label display="flex" alignItems="center" variant={labelVariant}>
                {element}
                {label}
            </Label>
        );
    } else {
        return <>{element}</>;
    }
};
