import axios from 'axios';

export const getScorecardList = companyId => axios.post(companyId ? `company/scorecard/list/${companyId}` : 'company/scorecard/list').then(({ data }) => data);

export const getScorecardResult = (scorecards) =>
    axios.post('company/scorecard/result', scorecards).then(({ data }) => data);

export const getScorecardQuestions = (scorecards) =>
    axios.post('company/scorecard/questions', scorecards).then(({ data }) => data);

export const getCompanyScorecardList = (scorecard) =>
    axios.post('advisor/scorecard/list', scorecard).then(({ data }) => data);

export const createCompanyScorecard = (scorecard) =>
    axios.post('company/scorecard/create', scorecard).then(({ data }) => data);

export const updateCompanyScorecard = (scorecard) =>
    axios.post('company/scorecard/update', scorecard).then(({ data }) => data);

export const getScorecardPreviewSliders = (scorecards) =>
    axios.post('advisor/scorecard/preview-sliders', scorecards).then(({ data }) => data);


export const resetCompanyScorecard = (scorecard) =>
    axios.delete(`advisor/scorecard/${scorecard}`).then(({ data }) => data);
