import React from 'react';
import { Box } from 'rebass';
import { Icons, Icon } from '@svg';
import Tooltip from '~/Common/Tooltip';

export const DemoIcons = ({ ...props }) => {

    return (
        <Box sx={{
            display: 'grid',
            gridTemplate: 'auto / repeat(6, 1fr)',
            maxWidth: '500px',
            margin: '0 auto'
        }}>
            {Object.keys(Icons).map((icon, key) => (
                <Box key={key} sx={{ p: 3 }}>
                    <Tooltip text={icon} sx={{ textAlign: 'center', width: 'auto' }}>
                        <Icon icon={icon} size="100%" />
                    </Tooltip>
                </Box>
            ))}
        </Box>
    );
};
