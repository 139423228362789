import React, { useEffect, useState } from 'react'
import { Markdown } from '~/Common/Markdown';
import { Modal } from '~/Common/Modal';
import updatesFile from '../../whats-new.md';

const WhatsNew = ({ isOpen, requestClose, ...props }) => {
    const [content, setContent] = useState(null);

    useEffect(() => {
        fetch(updatesFile)
            .then(async (r) => {
                setContent(await r.text())
            })
            .catch((e) => {
                setContent('Something went wrong');
            })
    }, [])

    return (
        <Modal
            isOpen={isOpen}
            requestClose={requestClose}
            title="Application Updates"
            icon="whatsNew"
            childSx={{
                maxHeight: '668px',
                overflowY: 'auto',
            }}
        >
            <Markdown>
                {content}
            </Markdown>
        </Modal>
    );
}

export default WhatsNew;
