import React, { useState, useEffect } from 'react';
import { Flex, Box, Heading, Button } from 'rebass';
import { toast } from 'react-toastify';
import { downloadTemplate, getErrorMessage, yearsList } from '@util/Functions';
import { getClassifiedAddbacks, getCompanyAddbacks, setCompanyAddbacks } from '@app/Api';
import { PopupStageDetails } from '~/Dashboard/TaskView';
import LoadingSpinner from '~/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { BackButton } from '~/Common/BackButton';
import { SelectField } from '~/Form';
import { getTaskStatus } from '@util/Enum';
import { setTaskAsSeen } from '@app/Api';
import { readTaskNotification } from '@app/Store';
import TaskStatusChange from '~/Dashboard/TaskStatusChange';
import { Input } from '@rebass/forms';
import CommentModule from '~/Common/CommentModule';
import { Modal } from '~/Common/Modal';
import { Icon } from '@svg';
import { Form } from '~/Common/Form';

const AddbackTaskView = ({
    companyTaskId,
    companyId,
    accreditedAdvisor,
    backLink,
    backLabel,
    templates,
    classify = false,
}) => {
    const [loading, setLoading] = useState(true);
    const [task, setTask] = useState({});
    const [minYearList, setMinYearList] = useState(null);
    const [maxYearList, setMaxYearList] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [commentSectionUuid, setCommentSectionUuid] = useState('');
    const [addbacks, setAddbacks] = useState([]);
    const [classifications, setClassifications] = useState({});
    const [enableComments, setEnableComments] = useState(false);
    const [dataId, setDataId] = useState(0);
    const [count, setCount] = useState(0);
    const [current, setCurrent] = useState(true);

    const { sx: statusStyle, name: statusName } = getTaskStatus(classify ? task.normalisationStatus : task.status);
    const postTaskStatusUpdate = (status) =>
        classify ? setTask({ ...task, ...{ normalisationStatus: status } }) : setTask({ ...task, ...{ status } });
    const { t } = useTranslation();

    const setAddbackData = (response) => {
        const task = response?.data?.task;
        setTask(task);
        setTaskAsSeen(task?.id).then(() => readTaskNotification(task?.id));
        setAddbacks(response?.data?.addbacks);
        setClassifications(response?.data?.classifications);
        setDataId(response?.data?.id);
        setEnableComments(response?.data?.id > 0);
        setCurrent(response?.data?.current);
    };

    useEffect(() => {
        setLoading(true);

        if (classify) {
            getClassifiedAddbacks(companyId)
                .then((response) => {
                    setAddbackData(response);
                })
                .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to get addbacks')))
                .finally(() => {
                    setModalOpen(false);
                    setLoading(false);
                });
        } else {
            getCompanyAddbacks(companyId)
                .then((response) => {
                    setAddbackData(response);
                })
                .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to get addbacks')))
                .finally(() => {
                    setModalOpen(false);
                    setLoading(false);
                });
        }
    }, [companyTaskId, companyId, classify]);

    if (loading) return <LoadingSpinner />;

    // TODO: Fix fiscal years
    const yearsRange = yearsList(7, 1).reverse();

    const getAddbackByUuid = (uuid) => addbacks.findIndex((record) => record.uuid === uuid);

    const getAddbackYearValue = (uuid, year) => {
        const index = getAddbackByUuid(uuid);

        return index !== -1 && addbacks[index].data[year] ? addbacks[index].data[year] : '';
    };

    const handleAddBackSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setCompanyAddbacks(companyId, addbacks, classify)
            .then((response) => {
                setAddbackData(response);
                toast.success('Add back schedule updated successfully');
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to save addbacks')))
            .finally(() => setLoading(false));
    };

    const handleValueInput = (uuid, year, value) => {
        const newItems = [...addbacks];
        const index = getAddbackByUuid(uuid);
        if (index !== -1) {
            newItems[index].data[year] = value;
            setAddbacks(newItems);
        }
    };

    const handleCategorySelect = (uuid, value) => {
        const newItems = [...addbacks];
        const index = getAddbackByUuid(uuid);
        if (index !== -1) {
            newItems[index].classification_id = parseInt(value);
            setAddbacks(newItems);
        }
    };

    const handleTitleInput = (uuid, title) => {
        const newItems = [...addbacks];
        const index = getAddbackByUuid(uuid);
        if (index !== -1) {
            newItems[index].title = title;
            setAddbacks(newItems);
        }
    };

    const addRow = (profit) => {
        setAddbacks((current) => [
            ...current,
            {
                newAddback: true,
                uuid: count,
                profit: profit,
                active: true,
                title: '',
                // Default 'Variable' classification
                classification_id: 7,
                data: {},
            },
        ]);

        setCount((current) => ++current);
    };

    return (
        <Box mt={4}>
            <BackButton to={backLink} label={backLabel} pl={3} />

            <Modal isOpen={modalOpen}>
                <PopupStageDetails
                    triggerClose={() => setModalOpen(false)}
                    details={t(task.description)}
                    name={t(task.stageName)}
                />
            </Modal>

            <Flex width={1}>
                <Box width={1 / 3}>
                    <Box variant="card.wrapper" p={30}>
                        <Box
                            sx={{
                                letterSpacing: '0.5px',
                                textTransform: 'uppercase',
                                fontSize: '10px',
                                fontWeight: 500,
                                marginBottom: 2,
                            }}
                        >
                            {t(task.stageName)}
                        </Box>
                        <Box mb={3} fontSize={18} fontWeight={700}>
                            {t(task.name)}
                        </Box>
                        <Box mb={3}>
                            {accreditedAdvisor && (
                                <Flex alignItems="center">
                                    <Box mr={2}>Change Status</Box>
                                    <TaskStatusChange
                                        taskStatus={classify ? task.normalisationStatus : task.status}
                                        companyTaskId={task.id}
                                        notifyUpdated={postTaskStatusUpdate}
                                        normalisation={classify}
                                    />
                                </Flex>
                            )}
                            {!accreditedAdvisor && (
                                <Button variant="task" mb={3} width={100} p={2} sx={statusStyle}>
                                    {statusName}
                                </Button>
                            )}
                        </Box>
                        <Box
                            variant="clickable"
                            fontSize={12}
                            sx={{ color: '#6F7782' }}
                            onClick={() => setModalOpen(true)}
                        >
                            {t(task.description)}
                        </Box>
                    </Box>

                    <Box variant="card.wrapper">
                        <Box variant="card.header">
                            <Heading>Add back items</Heading>
                        </Box>
                        <Box variant="card.container">
                            {[true, false].map((isProfit, key) => (
                                <Box key={key}>
                                    <Heading mb={1}>
                                        {isProfit ? 'Addbacks to Profit' : 'Deductions from Profit'}
                                    </Heading>
                                    {addbacks
                                        ?.filter(({ profit }) => profit === isProfit)
                                        .map(
                                            ({ title, active, uuid, newAddback }, index) =>
                                                !newAddback && (
                                                    <Flex
                                                        key={index}
                                                        className={styles.taskItem}
                                                        onClick={() => {
                                                            let newItems = [...addbacks];
                                                            const index = getAddbackByUuid(uuid);
                                                            if (index !== -1) {
                                                                newItems[index].active = !active;
                                                                setAddbacks(newItems);
                                                            }
                                                        }}
                                                    >
                                                        <Box mr="auto">{t(title)}</Box>
                                                        <Box flexShrink="0">
                                                            {active ? (
                                                                <Icon icon="roundCheck" stroke="#38C3FF" />
                                                            ) : (
                                                                <Icon icon="addCircle" stroke="#38C3FF" />
                                                            )}
                                                        </Box>
                                                    </Flex>
                                                )
                                        )}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box variant="card.wrapper">
                        <Box variant="card.header">
                            <Flex>
                                <Heading>Useful Templates</Heading>
                                <Box ml={'auto'}>
                                    <Icon icon="download" title="Download Templates" stroke="#00AEEF" />
                                </Box>
                            </Flex>
                        </Box>
                        <Box variant="card.container">
                            {templates?.map((att) => (
                                <Box pb={3} key={att.id}>
                                    <Box variant="attachmentLink" fontSize={12} onClick={() => downloadTemplate(att)}>
                                        {att.fileName}
                                    </Box>
                                </Box>
                            ))}
                            {!templates?.length && <Box>There are no templates for this task</Box>}
                        </Box>
                    </Box>
                </Box>
                <Box width={2 / 3}>
                    {classify && !current && (
                        <Box variant="warning">
                            <Box variant="warningHeader">New data is available in Onboarding &gt; Addbacks</Box>
                            <Box variant="card.container">
                                <Box>Please review and update manually.</Box>
                            </Box>
                        </Box>
                    )}
                    <Box variant="card.wrapper">
                        <Flex variant="card.header" alignItems="center">
                            <Heading>Add back schedule</Heading>
                            <Box ml="auto">
                                <SelectField
                                    value={minYearList}
                                    onChange={(val) => setMinYearList(val)}
                                    options={yearsRange}
                                    placeholder="From Year"
                                    sx={{
                                        padding: '10px 30px 10px 16px',
                                        minWidth: '200px',
                                        fontSize: 14,
                                        fontWeight: 600,
                                    }}
                                    boxSx={null}
                                />
                            </Box>
                            <Box ml="2">
                                <SelectField
                                    value={maxYearList}
                                    onChange={(val) => setMaxYearList(val)}
                                    options={yearsRange}
                                    placeholder="To Year"
                                    sx={{
                                        padding: '10px 30px 10px 16px',
                                        minWidth: '200px',
                                        fontSize: 14,
                                        fontWeight: 600,
                                    }}
                                    boxSx={null}
                                />
                            </Box>
                        </Flex>
                        <Box variant="card.container">
                            <Form onSubmit={handleAddBackSubmit}>
                                <Box sx={{ overflowX: 'scroll' }}>
                                    <table className={styles.dataTable}>
                                        <thead>
                                            <tr>
                                                <td></td>
                                                {classify && <td></td>}
                                                {yearsRange.map(
                                                    ({ value }, yearIndex) =>
                                                        (!minYearList || value >= minYearList) &&
                                                        (!maxYearList || value <= maxYearList) && (
                                                            <td key={yearIndex}>{value}</td>
                                                        )
                                                )}
                                                {enableComments && <td></td>}
                                            </tr>
                                        </thead>
                                        {[true, false].map((isProfit, key) => (
                                            <tbody key={key}>
                                                <tr>
                                                    <th
                                                        colSpan="99"
                                                        style={{
                                                            textAlign: 'left',
                                                            background: 'white',
                                                            padding: '5px',
                                                        }}
                                                    >
                                                        {isProfit ? 'Addbacks to Profit' : 'Deductions from Profit'}
                                                    </th>
                                                </tr>
                                                {addbacks
                                                    .filter(({ active, profit }) => active && profit === isProfit)
                                                    .map(
                                                        (
                                                            { title, uuid, newAddback, classification_id },
                                                            addbackKey
                                                        ) => [
                                                                <tr key={addbackKey}>
                                                                    <td className={styles.dataTableRowHeader}>
                                                                        {newAddback ? (
                                                                            <Input
                                                                                value={title}
                                                                                onChange={(e) =>
                                                                                    handleTitleInput(uuid, e.target.value)
                                                                                }
                                                                                mb={0}
                                                                            />
                                                                        ) : (
                                                                            t(title)
                                                                        )}
                                                                    </td>

                                                                    {classify && (
                                                                        <td>
                                                                            <SelectField
                                                                                value={classification_id}
                                                                                onChange={(val) =>
                                                                                    handleCategorySelect(uuid, val)
                                                                                }
                                                                                options={classifications}
                                                                                placeholder="Select Category"
                                                                                sx={{
                                                                                    padding: '10px 30px 10px 16px',
                                                                                    minWidth: '200px',
                                                                                    fontSize: 14,
                                                                                    fontWeight: 600,
                                                                                }}
                                                                                boxSx={null}
                                                                                grouped={true}
                                                                            />
                                                                        </td>
                                                                    )}

                                                                    {yearsRange.map(
                                                                        ({ value }, yearIndex) =>
                                                                            (!minYearList || value >= minYearList) &&
                                                                            (!maxYearList || value <= maxYearList) && (
                                                                                <td key={`${addbackKey}-${yearIndex}`}>
                                                                                    <Input
                                                                                        value={getAddbackYearValue(
                                                                                            uuid,
                                                                                            value
                                                                                        )}
                                                                                        onChange={(e) =>
                                                                                            handleValueInput(
                                                                                                uuid,
                                                                                                value,
                                                                                                e.target.value
                                                                                            )
                                                                                        }
                                                                                        textAlign="right"
                                                                                        mb={0}
                                                                                    />
                                                                                </td>
                                                                            )
                                                                    )}
                                                                    {enableComments && !newAddback && (
                                                                        <td>
                                                                            <Flex
                                                                                variant="clickable"
                                                                                alignItems="center"
                                                                                onClick={() =>
                                                                                    setCommentSectionUuid(
                                                                                        uuid === commentSectionUuid
                                                                                            ? ''
                                                                                            : uuid
                                                                                    )
                                                                                }
                                                                            >
                                                                                <Icon icon="comment" />
                                                                            </Flex>
                                                                        </td>
                                                                    )}
                                                                </tr>,
                                                                uuid === commentSectionUuid && (
                                                                    <tr key={`${uuid}-comment-section`}>
                                                                        <td colSpan="99">
                                                                            <CommentModule
                                                                                type="addback"
                                                                                id={dataId}
                                                                                uuid={uuid}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                ),
                                                            ]
                                                    )}
                                                <tr>
                                                    <td colSpan="99">
                                                        <Flex alignItems="center" mb="2" sx={{ cursor: 'pointer' }}>
                                                            <Icon icon="addCircle" onClick={() => addRow(isProfit)} />
                                                            <Box ml={2} onClick={() => addRow(isProfit)}>
                                                                Add extra line
                                                            </Box>
                                                        </Flex>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ))}
                                    </table>
                                </Box>
                                <Flex mt={3}>
                                    <Button ml="auto">Save</Button>
                                </Flex>
                            </Form>
                        </Box>
                    </Box>
                </Box>
            </Flex>
        </Box>
    );
};

export default AddbackTaskView;
