import { Box, Flex, Text, Link } from 'rebass';
import { useState } from 'react';
import { Icon } from '@svg';
import styles from './styles.module.scss';
import { formatDateFull, getErrorMessage } from '@util/Functions';
import { toggleCommentLike, deleteTaskComment } from '@app/Api';
import React from 'react';
import { toast } from 'react-toastify';
import UserAvatar from '~/Dashboard/Avatar';
import SanitizedField from '~/Form/SanitizedField';
import { AttachmentPreview } from '~/Common/AttachmentPreview';
import { Confirm } from '~/Common/Confirm';
import { useTranslation } from 'react-i18next';

const TaskComments = ({ messages, attachments, isAdvisor, updateMessages, updateAttachments }) => {
    const [currentAttachment, setCurrentAttachment] = useState(null);
    const { t } = useTranslation();

    const handleLikeClick = (messageIndex) => {
        const taskCommentId = messages[messageIndex].id;
        toggleCommentLike(taskCommentId)
            .then(() => {
                const newMessages = [...messages];
                newMessages[messageIndex].advisorLike = messages[messageIndex].advisorLike > 0 ? null : 1;
                updateMessages(newMessages);
                toast.success('Comment like updated successfully');
            })
            .catch(({ response }) =>
                toast.error(getErrorMessage(response?.data, 'There was a system error while submitting your like'))
            );
    };

    const [deleteTaskCommentUuid, setDeleteTaskCommentUuid] = useState(null);
    const handleDeleteTaskComment = () => {
        if (!deleteTaskCommentUuid) {
            return;
        }

        deleteTaskComment(deleteTaskCommentUuid)
            .then((data) => {
                updateMessages(data.messages);
                updateAttachments(data.attachments);
                setDeleteTaskCommentUuid(null);
                toast.success('Comment deleted successfully');
            })
            .catch(({ response }) => {
                toast.error(getErrorMessage(response?.data, 'Unable to remove comment'));
            });
    };

    return (
        <Box>
            <Confirm
                isOpen={!!deleteTaskCommentUuid}
                title={t('Are you sure you would like to delete this comment?')}
                requestClose={() => setDeleteTaskCommentUuid(false)}
                onConfirm={handleDeleteTaskComment}
                isWarning={true}
                confirmText={t('Delete Comment')}
            />
            <AttachmentPreview attachment={currentAttachment} close={() => setCurrentAttachment(null)} />

            {messages?.map(({ id, uuid, firstName, surname, email, avatar, created, advisorLike, logo, comment, canDelete }, messageIndex) => (
                <Flex mt={3} key={`messages${id}`} sx={{
                    px: 2,
                    py: 3,
                    borderBottom: '1px solid #E5E9EB'
                }}>
                    <UserAvatar firstName={firstName} surname={surname} email={email} avatar={avatar} mr={2} />

                    <Box flex={1}>
                        <Flex>
                            <Text fontSize={14} fontWeight={'bold'} mr={2}>
                                {firstName !== null && surname !== null ? <>
                                    {firstName} {surname}
                                </> : <>
                                    {email}
                                </>}
                            </Text>
                            <Text fontSize={10} sx={{ color: '#6F7782', lineHeight: '20px' }}>
                                {formatDateFull(created)}
                            </Text>
                        </Flex>
                        <Text mt={2} fontSize={14}>
                            <Flex sx={{
                                alignItems: 'center'
                            }}>
                                <Box className={styles.commentBox}>
                                    <SanitizedField html={comment} />
                                </Box>
                            </Flex>
                        </Text>
                        {canDelete &&
                            <Box mt={1} mb={1}>
                                <Link variant="actionLink" title="Delete this comment" onClick={() => setDeleteTaskCommentUuid(uuid)}>
                                    Delete
                                </Link>
                            </Box>
                        }
                        {attachments.map(
                            (att) =>
                                att.companyTaskCommentId === id && (
                                    <Flex
                                        variant="attachmentLink"
                                        key={`attachment${att.id}`}
                                        onClick={() => setCurrentAttachment(att)}
                                        alignItems='center'
                                    >
                                        <Icon icon="attachment" title="Download Attachment" width={10} height={10} />{' '}
                                        <Text fontSize={12} ml={2}>{att.fileName}</Text>
                                    </Flex>
                                )
                        )}
                    </Box>
                    <Box pl={1}>
                        {isAdvisor && (
                            <Box
                                onClick={() => handleLikeClick(messageIndex)}
                                className={styles.likeIcon}
                                title="Let the company know that you acknowledge the message"
                            >
                                {advisorLike === 1 && <Icon icon="likeOn" />}
                                {advisorLike !== 1 && <Icon icon="likeOff" stroke="#656D78" />}
                            </Box>
                        )}
                        {!isAdvisor && !!advisorLike && <Icon icon="likeOn" title="Advisor acknowledges this message" />}
                    </Box>
                </Flex>
            ))}
        </Box>
    );
};

export default TaskComments;
