import React, { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Flex, Text, Link } from 'rebass';
import { Icon as SvgIcon } from '@svg';
import styles from './styles.module.scss';

export const IconButton = ({ to = '#', label, sx, wsx, icon, iconAlign = 'right', iconSize = 10, ...rest }) => {
    const Icon = useCallback(
        () => (
            <Flex
                sx={{
                    width: '24px',
                    height: '24px',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <SvgIcon
                    icon={icon ?? (iconAlign === 'left' ? "arrowLeft" : "arrow")}
                    className={styles.arrow}
                    height={iconSize}
                />
            </Flex>
        ),
        [icon, iconAlign, iconSize]
    );

    return (
        <Link
            variant="transparent"
            as={RouterLink}
            to={to}
            className={styles.iconLink}
            cursor="pointer"
            sx={{
                transition: 'opacity 0.5s ease',
                '&:hover': {
                    '.icon': {
                        transform: `translateX(${iconAlign === 'left' ? -2 : 2}px)`
                    }
                },
                ...wsx,
            }}
            {...rest}
        >
            <Flex
                alignItems="center"
                color="#093467"
                sx={{
                    fontSize: 12,
                    fontWeight: 600,
                    gap: 2,
                    ...sx,
                }}
            >
                {iconAlign === 'left' && <Icon />}
                <Text as="span">
                    {label}
                </Text>
                {iconAlign === 'right' && <Icon />}
            </Flex>
        </Link>
    );
};
