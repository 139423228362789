import { createSlice } from '@reduxjs/toolkit';

const tasks = createSlice({
    name: 'tasks',
    initialState: {
        results: [],
        page: 1,
    },
    reducers: {
        setTasks: (state, { payload }) =>
            Array.isArray(payload)
                ? {
                      results: payload,
                      page: 1,
                  }
                : payload,
        updateTasks: (state, { payload, ...rest }) => {
            if (payload.page > 1) {
                payload.results = [...state.results, ...payload.results];
            }

            return payload;
        },
        resetTasks: () => null,
    },
});

export const { updateTasks, setTasks, resetTasks } = tasks.actions;

export default tasks.reducer;
