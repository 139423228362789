import React, { useState } from 'react';
import { Box, Flex } from 'rebass';
import { Link, withRouter } from 'react-router-dom';
import styles from './Assets/stylesAssignedCompanies.module.scss';
import { connect } from 'react-redux';
import { defaultCompanyLogoLink } from '@util/Functions';
import cx from 'classnames';
import { TextField } from '~/Form';
import CreateCompany from '~/Common/CreateCompany';
import UserAvatar from '~/Dashboard/Avatar';
import { isConsultant } from '@util/Functions/User';

const AssignedCompanies = ({ user, companies, org, showMenu, expand }) => {
    const pageUrl = window.location.pathname;
    const [searchText, setSearchText] = useState('');

    return (
        <Box
            sx={{
                overflow: 'hidden',
            }}
        >
            <Box
                sx={{
                    transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
                    transform: expand ? 'translateY(0)' : 'translateY(-100%)',
                    opacity: expand ? 1 : 0,
                }}
            >
                {showMenu && (
                    <Box px={2} pb={3} pt="3px">
                        <TextField
                            variant="sidebarInput"
                            value={searchText}
                            onChange={(val) => setSearchText(val)}
                            placeholder="Search company"
                            icon="search"
                        />
                    </Box>
                )}
                <Box
                    fontSize={11}
                    height="calc(100vh - 660px)"
                    overflowY="auto"
                    py={2}
                    px={2}
                    sx={{
                        border: '1px solid rgba(255,255,255,0.3)',
                        borderLeft: 'none',
                        borderRight: 'none',
                    }}
                >
                    {companies.reduce((filteredArray, { id, name, logo, archived }) => {
                        if (name.toLowerCase().search(searchText.toLowerCase()) !== -1 && !archived) {
                            const url = `/advisor/company/${id}/`;
                            filteredArray.push(
                                <Link to={isConsultant(user) ? `/company/dashboard/${id}` : `${url}overview`} key={id}>
                                    <Box
                                        className={cx(
                                            styles.menuLinks,
                                            pageUrl.indexOf(url) === 0 ? styles.active : ''
                                        )}
                                        my={1}
                                    >
                                        <Flex alignItems="center" justifyContent={showMenu ? 'left' : 'center'}>
                                            <UserAvatar
                                                logo={logo || defaultCompanyLogoLink}
                                                size={20}
                                                flexShrink={0}
                                            />
                                            {showMenu && (
                                                <Box fontSize={13} fontWeight={600} pt={0} pl="10px">
                                                    {' '}
                                                    {name}{' '}
                                                </Box>
                                            )}
                                        </Flex>
                                    </Box>
                                </Link>
                            );
                        }
                        return filteredArray;
                    }, [])}
                </Box>
                <Box
                    sx={{
                        px: 2,
                        py: 3,
                    }}
                >
                    <CreateCompany showMenu={showMenu} />
                </Box>
            </Box>
        </Box>
    );
};

export default withRouter(
    connect((state) => ({ companies: state.companies, user: state.user, org: state.organisation }))(AssignedCompanies)
);
