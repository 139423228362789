import { createSlice } from '@reduxjs/toolkit';

const saveToLocalStorage = (key, value) => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
        console.error(e);
    }
};

const loadFromLocalStorage = (key) => {
    try {
        const stateStr = localStorage.getItem(key);
        return stateStr ? JSON.parse(stateStr) : undefined;
    } catch (e) {
        console.error(e);
        return undefined;
    }
};

const ui = createSlice({
    name: 'ui',
    initialState: {
        sidebarOpen: true,
        mode: 'live',
        recentlyViewedCompanies: loadFromLocalStorage('recentlyViewedCompanies') ?? []
    },
    reducers: {
        toggleSidebarOpen: (state, { payload }) => {
            state.sidebarOpen = !state.sidebarOpen;
        },

        setTrainingMode: (state, { payload }) => {
            state.mode = payload;
        },

        addToRecentlyViewedCompanies: (state, { payload }) => {
            if (state.recentlyViewedCompanies.includes(payload) === false) {
                state.recentlyViewedCompanies.push(payload);

                saveToLocalStorage('recentlyViewedCompanies', state.recentlyViewedCompanies);
            }
        },
    },
});

export const { toggleSidebarOpen, setTrainingMode, addToRecentlyViewedCompanies } = ui.actions;

export default ui.reducer;
