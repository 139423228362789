import axios from 'axios'

export const getUserDetails = companyId =>
    axios.get(companyId ? `user/details/get/${companyId}` : 'user/details/get')
        .then(({ data }) => data)

export const setUserDetails = (userDetails, companyId) =>
    axios.post(companyId ? `user/details/set/${companyId}` : 'user/details/set', userDetails)
        .then(({ data }) => data)

export const setUserAvatar = (avatar) =>
    axios.post('user/avatar/set', avatar)
        .then(({ data }) => data)

export const setAdvisorLogo = (logo) =>
    axios.post('user/logo/set', logo)
        .then(({ data }) => data)

export const getAdvisorCompanyDetails = (details) =>
    axios.post('advisor/company/details/get', details)
        .then(({ data }) => data)

export const setAdvisorCompanyDetails = (userDetails) =>
    axios.post('advisor/company/details/set', userDetails)
        .then(({ data }) => data)

export const getUserStore = () =>
    axios.get('user/store/get')
        .then(({ data }) => data)

export const getAnalysts = () =>
    axios.get('user/analysts')
        .then(({ data }) => data)
