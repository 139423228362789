import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Modal } from '~/Common/Modal';
import { Box, Button, Flex, Heading } from 'rebass';
import { Icon } from '@svg/Icon';

const getPosition = (ref) =>
    ref.current
        ? ref.current.getBoundingClientRect()
        : {
            top: 0,
            left: 0,
            bottom: 0,
        }

export const DropdownModal = ({
    sx,
    title,
    buttonText,
    renderButton: CustomButton,
    renderActions: Actions,
    onOpen,
    variant = 'rounded',
    children,
    showHeader = true,
    buttonProps,
    align,
    ...props
}) => {
    const [isOpen, setOpen] = useState(false);
    const buttonRef = useRef(null);
    const modalRef = useRef(null);
    const [transition, setTransition] = useState(false);
    const [position, setPosition] = useState({
        align: {
            x: 'left',
            y: 'bottom'
        },
        coords: null
    });
    const toggleOpen = () => setOpen((open) => !open);

    const buttonLabel = buttonText ?? title ?? 'Show Modal';
    const requestClose = () => setOpen(false);

    const onResize = useCallback(() => {
        if (isOpen && modalRef.current) {
            setPosition(() => {
                const { scrollHeight, scrollWidth } = document.documentElement;
                const { width, height } = modalRef.current.getBoundingClientRect();
                const box = getPosition(buttonRef);
                const bottom = box.bottom + height;
                const right = box.left + width;
                const aligned = {
                    x: align?.x && align.x !== 'auto'
                        ? align.x
                        : right + window.scrollX >= scrollWidth ? 'right' : 'left',
                    y: align?.y && align.y !== 'auto'
                        ? align.y
                        : bottom + window.scrollY >= scrollHeight ? 'top' : 'bottom'
                };

                return {
                    align: aligned,
                    coords: {
                        top: aligned?.y === 'top' ? 'auto' : `${box?.top + box?.height}px`,
                        left: aligned?.x === 'right' ? 'auto' : `${box?.left + window.scrollX}px`,
                        right: aligned?.x === 'right' ? `${(window.innerWidth - box?.right) + window.scrollX - 14}px` : 'auto',
                        bottom: aligned?.y === 'top'
                            ? `${document.documentElement.scrollHeight - (window.scrollY + box.bottom)}px`
                            : 'auto',
                    }
                };
            });
        }
    }, [isOpen, align]);

    useLayoutEffect(() => {
        if (position.coords !== null) {
            const timeout = setTimeout(() => setTransition(true), 20);
            return () => clearTimeout(timeout);
        }
    }, [position.coords])

    useLayoutEffect(() => {
        window.addEventListener('resize', onResize);
        window.addEventListener('scroll', onResize);

        setTimeout(onResize, 100);

        return () => {
            window.removeEventListener('scroll', onResize)
            window.removeEventListener('resize', onResize)
        }
    }, [isOpen, onResize]);

    useEffect(() => {
        if (isOpen && typeof onOpen === 'function') {
            onOpen();
        }
    }, [isOpen, onOpen]);

    return (
        <Box {...props} ref={buttonRef} sx={{ position: 'relative', ...sx }}>
            {typeof CustomButton === 'function' ? (
                <CustomButton onClick={toggleOpen} requestClose={requestClose} {...buttonProps}>{buttonLabel}</CustomButton>
            ) : (
                <Button
                    variant={variant}
                    onClick={toggleOpen}
                    {...buttonProps}
                    sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        ...buttonProps.sx,
                    }}
                    className={`${isOpen ? 'active' : ''} ${buttonProps?.className ?? ''}`}
                >
                    {buttonProps?.icon && (
                        <Icon
                            icon={buttonProps.icon}
                            sx={{
                                ...buttonProps.iconProps?.sx,
                                mr: 2,
                            }}
                            color="inherit"
                            {...buttonProps.iconProps}
                        />
                    )}
                    {buttonLabel}
                </Button>
            )}
            <Modal
                overlay={false}
                isOpen={isOpen}
                requestClose={requestClose}
                shouldCloseOnEsc={true}
                contentRef={(ref) => ref !== null && (modalRef.current = ref)}
                sx={{
                    background: '#fff',
                    ...position.coords,
                    maxWidth: 362,
                    minWidth: 362,
                    overflow: 'hidden',
                    border: 'none',
                    boxShadow: '4px 2px 20px 10px rgba(44, 41, 96, 0.1)',
                    margin: '16px 0',
                    transform: 'none',
                    visibility: transition ? 'visible' : 'hidden',
                    transition: transition ? 'inset 0.3s ease' : undefined,
                }}
            >
                {showHeader && (
                    <Flex justifyContent="space-between" alignItems="center" mb="3">
                        {title && <Heading>{title}</Heading>}
                        {Actions
                            ? <Actions requestClose={requestClose} />
                            : <Button variant="gray" onClick={toggleOpen}>Done</Button>
                        }
                    </Flex>
                )}
                {typeof children === 'function' ? children({ requestClose }) : children}
            </Modal>
        </Box>
    );
};
