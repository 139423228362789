import defaultCompany from '@svg/attachment.svg';

export const defaultCompanyLogoLink = defaultCompany;

export const defaultUserLogoLink = defaultCompany;

export const getInitials = (firstName, lastName) =>
    ''.concat(firstName ? firstName.charAt(0) : ' ', lastName ? lastName.charAt(0) : ' ').toUpperCase();

export const isAllCaps = (str) => str.toUpperCase() === str;

export const formatCurrency = (value, currency, digits = 2, lang = null) => {
    const options = {
        style: 'currency',
        currency: currency ?? 'AUD',
        currencyDisplay: 'narrowSymbol',
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    };

    return new Intl.NumberFormat(lang ?? 'en-AU', options).format(value);
};

export const currencySymbol = (isoCode) => formatCurrency(0, isoCode, 0).replace(/\d/g, '').trim();

export const formatNumber = (value, digits) => {
    if (digits !== undefined) {
        return parseFloat(new Intl.NumberFormat().format(value)).toFixed(digits);
    }
    return new Intl.NumberFormat().format(value);
};

export const formatPercent = (value) => parseFloat(value).toFixed(2) + '%';

export const getErrorMessage = (data, defaultMessage = 'An unknown error has occurred') => {
    if (data?.errors) {
        const keys = Object.keys(data.errors);
        if (keys.length >= 1 && data.errors[keys[0]][0]) {
            return data.errors[keys[0]][0];
        }
    }
    return data?.message || defaultMessage;
};

export const percentCompleted = (val, total) => (total ? Math.round((val / total) * 100) : 0);

export const capitalizeFirstLetter = (string) => string.charAt(0).toLowerCase().toUpperCase() + string.slice(1);

export const ucWords = (string) =>
    (string + '').toLowerCase().replace(/^([a-z])|\s+([a-z])/g, ($1) => $1.toUpperCase());

export const camelCaseWords = (string) => string.replace(/([A-Z])/g, ' $1');

export const ucWordsCamelCase = (string) => capitalizeFirstLetter(camelCaseWords(string));
