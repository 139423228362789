import React, { useEffect, useState, useRef } from 'react'
import { Box, Text, Button, Heading, Link } from 'rebass'
import { Icon } from '@svg';
import { useMenuClose } from '@hooks';
import { Checkbox } from '@rebass/forms'
import { Portal } from '@material-ui/core';

const CustomSelect = ({
    value,
    onChange,
    name,
    text,
    errors = null,
    label = null,
    placeholder,
    options,
    disabled,
    sx,
    nullable = false,
    helpText = '',
    grouped = false,
    prefix = false,
    suffix = false,
    multi = false,
    showSelection,
    optionsTitle = false,
    optionsLabel = false,
    outline = false,
    clearSelection,
    clearLabel,
    showOrderBy = false,
    icon,
    iconColor,
    buttonStyled,
    ...props
}) => {
    const [optionLabel, setLabel] = useState(null);
    const [isOpen, setOpen] = useState(false);
    const [orderBy, setOrderBy] = useState('asc');
    const [anchor, setAnchor] = useState('left');
    const selectRef = useRef(null);
    const dropdownRef = useRef(null);
    const showSelectionLabel = showSelection ?? !multi;

    const isSelected = (v) => {
        if (Array.isArray(value)) {
            return value.reduce((prev, cur) => prev || cur === v, false);
        }
        return value === v;
    }

    useMenuClose(selectRef, () => setOpen(false))

    useEffect(() => {
        const onResize = () => {
            if (dropdownRef.current) {
                const box = dropdownRef.current.getBoundingClientRect();
                setAnchor(box.x + box.width > window.innerWidth ? 'right' : 'left');
            }
        };

        document.addEventListener('resize', onResize);
        onResize();

        return () => {
            document.removeEventListener('resize', onResize);
        }
    }, [])

    useEffect(() => {
        const findValue = val => {
            if (Array.isArray(options)) {
                const current = options.find(o => o.value === val);

                if (current) {
                    return current;
                } else if (nullable) {
                    return null;
                } else if (process.env.NODE_ENV !== 'production') {
                    console.error(`No options match the value "${val}". Available options:`, options);
                }
            }
            return false;
        }

        if (Array.isArray(options)) {
            if (multi && Array.isArray(value)) {
                const labels = value.map(v => findValue(v)?.label).filter(i => typeof i === 'string');
                setLabel(labels.length ? labels.join(', ') : null);
            } else {
                const current = findValue(value);
                setLabel(current ? current.label : null);
            }
        }
    }, [value, options, multi, nullable]);

    const handleSelect = (option) => {
        var newOrderBy = orderBy;
        if (option.value === value) {
            newOrderBy = orderBy === 'asc' ? 'desc' : 'asc';
            setOrderBy(newOrderBy);
        }

        onChange && onChange(option, newOrderBy);
        if (!multi) {
            setOpen(false);
        }
    }
    const box = selectRef?.current?.getBoundingClientRect() ?? {};

    return (
        <Box ref={selectRef} variant="dropdown.wrapper" sx={sx} {...props}>
            <Button
                variant="dropdown.button"
                onClick={() => setOpen(!isOpen)}
                className={outline ? 'outline' : ''}
                style={buttonStyled}
            >
                {icon && <Icon icon={icon} color={iconColor ?? '#2C2960'} size={12} sx={{ mr: 2 }} />}
                {prefix && <Text mr="4px">{prefix}</Text>}
                <Text
                    className="value"
                    sx={{
                        flex: 1,
                        textAlign: 'left',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}
                >
                    {optionLabel && showSelectionLabel ? optionLabel : text}
                </Text>
                {suffix && <Text ml="4px">{suffix}</Text>}
                <Icon icon="dropdownArrow" sx={{ ml: '20px' }} />
            </Button>
            <Portal>
                <Box
                    ref={dropdownRef}
                    variant="dropdown.options"
                    sx={{
                        minWidth: box.width,
                        pointerEvents: isOpen ? 'all' : 'none',
                        transform: `translateY(${isOpen ? '0' : '-200%'})`,
                        opacity: isOpen ? 1 : 0,
                        top: box.top + box.height + 10,
                        [anchor]: box?.[anchor] ?? 0,
                        width: 'auto',
                    }}
                >
                    {multi && clearSelection && (
                        <Link
                            sx={{
                                color: '#6F7782',
                                position: 'absolute',
                                top: '12px',
                                right: '24px'
                            }}
                            onClick={clearSelection}
                        >
                            Clear all
                        </Link>
                    )}
                    {optionsTitle && <Heading variant="dropdown.optionsTitle">{optionsTitle}</Heading>}
                    {optionsLabel && <Text variant="dropdown.optionsLabel">{optionsLabel}</Text>}
                    {nullable && !multi && (
                        <Button
                            onClick={() => handleSelect({ value: null })}
                            variant="dropdown.item"
                        >
                            {clearLabel ?? 'None'}
                        </Button>
                    )}
                    {options && options.map((option, key) => {
                        const isOptionSelected = isSelected(option.value);

                        return (
                            <Button
                                key={key}
                                onClick={() => handleSelect(option)}
                                variant="dropdown.item"
                                className={isOptionSelected ? `sort--${orderBy}` : ''}
                                sx={{
                                    fontWeight: multi || isOptionSelected ? 600 : 400
                                }}
                            >
                                {multi && <Checkbox sx={{ mr: 0 }} checked={isOptionSelected} onChange={() => {}} color="#2C2960" />}
                                {option.color && (
                                    <Box sx={{
                                        width: '8px',
                                        height: '8px',
                                        bg: option.color,
                                        borderRadius: '50%',
                                    }} />
                                )}
                                {option.label}
                                {showOrderBy && isOptionSelected && option.showOrderBy !== false && <Icon color="#C3C7D0" icon="sort" sx={{ ml: 2 }} />}
                            </Button>
                        )
                    })}
                </Box>
            </Portal>
        </Box>
    )
}

export default CustomSelect
