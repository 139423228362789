import React from 'react';
import { IconButton } from '../IconButton';

export const BackButton = ({ to = '#', label = 'Back', sx, wsx, ...props }) => {
    return (
        <IconButton
            to={to}
            label={label}
            sx={sx}
            wsx={{
                marginBottom: '21px',
                marginLeft: '19px',
                ...wsx,
            }}
            iconAlign="left"
            {...props}
        />
    )
};
