import axios from 'axios'

export const login = (loginDetails) =>
    axios.post('auth/login', loginDetails)
        .then(({ data }) => {
            localStorage.setItem('authTokenScope', data?.two_factor ? 'twoFactorRequired' : 'full');
            localStorage.setItem('authToken', data.authToken)
            return data
        })

export const loginTwoFactorChallengeUser = (params) =>
    axios.post('user/two-factor-challenge', params)
        .then(({ data }) => {
            localStorage.removeItem('authTokenScope');
            localStorage.setItem('authToken', data.authToken);
            return data;
        })

export const enableTwoFactor = (params) =>
    axios.post('user/two-factor-authentication', params)
        .then(({ data }) => data)

export const confirmTwoFactor = (params) =>
    axios.post('user/two-factor-authentication-confirm', params)
        .then(({ data }) => {
            localStorage.removeItem('authTokenScope');
            localStorage.setItem('authToken', data.authToken);
            return data;
        })

export const getTwoFactorQrCode = () =>
    axios.get('user/two-factor-qr-code')
        .then(({ data }) => data)

export const getTwoFactorRecoveryCodes = () =>
    axios.get('user/two-factor-recovery-codes')
        .then(({ data }) => data)

export const refreshTwoFactorRecoveryCodes = () =>
    axios.post('user/two-factor-recovery-codes')
        .then(({ data }) => data)

export const disableTwoFactor = () =>
    axios.delete('user/two-factor-authentication')
        .then(({ data }) => data)

export const logout = () =>
    axios.get('auth/logout')
        .finally(() => {
            localStorage.removeItem('authToken');
            localStorage.removeItem('authTokenScope');
        })

export const resetPassword = (loginDetails) =>
    axios.post('auth/reset-password', loginDetails)
        .then(({ data }) => data)

export const updatePassword = (loginDetails) =>
    axios.post('auth/update-password', loginDetails)
        .then(({ data }) => data)