import axios from 'axios'
import { toast } from 'react-toastify'

const initializer = (version) => {
    const initializer = axios.create();

    initializer.defaults.withCredentials = true;
    initializer.defaults.baseURL = process.env.REACT_APP_API_BASE_URL + version;

    initializer.interceptors.request.use(function (config) {
        const authToken = localStorage.getItem('authToken')
        if (authToken) config.headers.Authorization = 'Bearer ' + authToken
        return config
    })

    initializer.interceptors.response.use((response) => response,
        error => {
            if (401 === error.response?.status) {
                toast.error('Sorry, your session has expired. Please try login again', {
                    toastId: 'session_expired'
                })
                if (window.location.pathname !== '/') {
                    window.location.href = '/';
                }
            } else {
                return Promise.reject(error)
            }
        })

    return initializer;
}

export default initializer('/api/2021-07/');
