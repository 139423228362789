import React from 'react';
import { Box } from 'rebass';
import { Label, Select } from '@rebass/forms';

const SelectField = ({
    value,
    onChange,
    name,
    errors = null,
    label = null,
    placeholder,
    showPlaceholder = true,
    options,
    disabled,
    sx,
    boxSx = { marginBottom: 20 },
    nullable = false,
    helpText = '',
    grouped = false,
    ...props
}) => (
    <Box sx={boxSx}>
        {label && (
            <Label htmlFor={name} mb={1} flexDirection="column">
                {label}{' '}
                {helpText && (
                    <>
                        <br />
                        <small>{helpText}</small>
                    </>
                )}
            </Label>
        )}
        <Select
            id={name}
            name={name}
            onChange={(e) => typeof onChange === 'function' && onChange(e.target.value, name, e)}
            sx={{ borderRadius: '5px', px: 15, py: 10, ...sx }}
            disabled={disabled}
            {...(value === undefined
                ? {
                    defaultValue: '',
                }
                : {
                    value: value !== null ? value : '',
                })}
            {...props}
        >
            {showPlaceholder && (
                <option value="" disabled={!nullable}>
                    {' '}
                    {placeholder || `-- Select ${name || 'field'} --`}{' '}
                </option>
            )}
            {grouped
                ? Object.keys(options).map((group, groupIndex) => (
                    <optgroup key={`group-${groupIndex}`} label={group}>
                        {options[group].map(({ value, label, children }, itemIndex) => (
                            <React.Fragment key={`item-${itemIndex}`}>
                                {children && children.length > 0 ?
                                    <>
                                        <option key={value} value={value} style={{ color: "midnightblue", fontWeight: "bold" }} >
                                            {label}
                                        </option>
                                        {
                                            children.map((child) => (
                                                <option key={child.value} value={child.value}>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;{child.label}
                                                </option>
                                            ))
                                        }
                                    </>

                                    : (
                                        <option key={value} value={value}>
                                            {label}
                                        </option>
                                    )}
                            </React.Fragment>
                        ))}
                    </optgroup>
                ))
                : Array.isArray(options)
                    ? options.map(({ value, label }) => (
                        <option key={value} value={value}>
                            {label}
                        </option>
                    ))
                    : null}
        </Select>
        {errors && (
            <Box mt="2px" mb={20} color="red" fontSize={12}>
                {errors[0]}
            </Box>
        )}
    </Box>
);

export default SelectField;
