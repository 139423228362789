import React, { useState, useEffect } from 'react';
import LoadingSpinner from '~/LoadingSpinner';
import { getCompanyStages } from '@app/Api';
import { toast } from 'react-toastify';
import CompanyStages from '~/Dashboard/CompanyStages';
import { connect } from 'react-redux';
import { setStages } from '@app/Store';
import { getErrorMessage } from '@util/Functions';

const CompanyStagesPage = ({ companyId, ...props }) => {
    const [stages, setStages] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (companyId) {
            setLoading(true);
            getCompanyStages({ companyId, onboardingStages: false })
                .then(({ data }) => {
                    setStages(data);
                    setLoading(false);
                })
                .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to load company data'), { toastId: 'getCompanyStagesError' }));
        }
    }, [companyId]);

    const updateStages = () => getCompanyStages({ companyId, onboardingStages: false })
        .then(({ data }) => {
            setStages(data);
            setLoading(false);
        })
        .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to load company data'), { toastId: 'getCompanyStagesError' }));

    if (loading || !stages) return <LoadingSpinner />;

    return <CompanyStages stages={stages} companyId={companyId} title="Projects" updateStages={updateStages} onboardingStages={false} />;
};

export default connect((state) => ({ companies: state.companies }), { setStages })(CompanyStagesPage);
