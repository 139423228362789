import { getContentPage } from '@app/Api'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import LoadingSpinner from '~/LoadingSpinner'
import SanitizedField from "~/Form/SanitizedField"
import { Box } from 'rebass'

const NoMatch404 = ({ pageUrl }) => {

    const [loading, setLoading] = useState(true)
    const [content, setContent] = useState('')
    useEffect(() => {
        getContentPage(pageUrl)
            .then((content) => {
                setLoading(false)
                setContent(content)
            })
            .catch(({ response }) =>
                toast.error(response?.data?.message || 'There was an unexpected error while trying to log in'))
    }, [pageUrl])

    return loading ? <LoadingSpinner /> :
        <Box sx={{ maxWidth: '900px', ml: 5, pb: 6 }}>
            <SanitizedField
                html={content ? content : 'Page not found'}
                options={{ allowedTags: false }}
            />
        </Box>
}

export default NoMatch404
