import React, { useState } from 'react';
import { Box, Flex } from 'rebass';
import { useHistory, Link, Redirect } from 'react-router-dom';
import { Icon } from '@svg';
import styles from './Assets/styles.module.scss';
import cx from 'classnames';
import { connect, useSelector } from 'react-redux';
import { isAdmin, isAdvisor, isCompanyOnboarded, currentYear, isTestUser } from '@util/Functions';
import AssignedCompanies from './AssignedCompanies';
import { CompanyHeader } from '~/Common/CompanyHeader';
import { isAnalyst, isCeo, isConsultant } from '@util/Functions/User';
import { useTranslation } from 'react-i18next';
import { Bubble } from '~/Common/Bubble';
import { Skeleton } from '~/Common/Skeleton';

const NavigationBar = ({ user, showMenu, setShowMenu }) => {
    const [expand, setExpand] = useState(true);
    const history = useHistory();
    const pageUrl = window.location.pathname;
    const notifications = useSelector((state) => state.notifications);
    const { t } = useTranslation();

    if (isAdmin(user)) return <Redirect to="/admin-error" />;

    const routeList = [
        [
            { title: 'Dashboard', icon: 'computer', prefix: '/organisation/home' },
            { title: 'Inbox', icon: 'bell', prefix: '/advisor/inbox' },
            { title: 'Settings', icon: 'gear', prefix: '/advisor/settings' },
            { title: 'Value Gap Assessment', icon: 'paper', prefix: '/advisor/exit-assessment' },
        ],
        [
            { title: 'Dashboard', icon: 'computer', prefix: '/advisor/home' },
            { title: 'Inbox', icon: 'bell', prefix: '/advisor/inbox' },
            { title: 'Settings', icon: 'gear', prefix: '/advisor/settings' },
            { title: 'Capitaliz Academy', icon: 'academy', to: 'https://cap-academy.capitaliz.com/users/sign_in' },
            { title: 'Value Gap Assessment', icon: 'paper', prefix: '/advisor/exit-assessment' },
        ],
        [
            { title: 'Dashboard', icon: 'computer', prefix: '/company/dashboard' },
            { title: 'Onboarding', icon: 'roundCheck', prefix: '/company/onboarding' },
            { title: 'Settings', icon: 'gear', prefix: '/company/profile/settings' },
        ],
        [
            { title: 'Dashboard', icon: 'computer', prefix: '/company/dashboard' },
            { title: 'Projects', icon: 'roundCheck', prefix: '/company/projects' },
            {
                title: t('actionItems.title'),
                icon: 'checklist',
                prefix: `/company/action-items`,
            },
            {
                title: 'Financials',
                icon: 'stats',
                prefix: '/company/financials',
            },
            // if removed remove the routes as possibly the endpoints
            /*{
                title: 'Scorecards',
                icon: 'stats',
                prefix: '/company/scorecards',
            },
            {
                title: 'Valuations',
                icon: 'stats',
                prefix: '/company/valuations',
            },*/
            {
                title: 'Reports',
                icon: 'reports',
                prefix: '/company/reports',
            },
            {
                title: 'Settings',
                icon: 'gear',
                prefix: '/company/profile/settings',
            },
        ],
    ];

    const accreditedAdvisor = isAdvisor(user) || isAnalyst(user);
    const companyRoutesIndex = accreditedAdvisor ? (isCeo(user) ? 0 : 1) : isCompanyOnboarded(user) ? 3 : 2;
    const routes = isConsultant(user) ? [] : routeList[companyRoutesIndex];

    return (
        <div className={cx(styles.navigationBlock, showMenu ? styles.navigationBlockExpand : '')}>
            <Box px={20} pt={3}>
                <Flex>
                    <div className={styles.menuExpandButton} onClick={() => setShowMenu()}>
                        <Icon icon="menu" stroke="#fff" title="Menu" className={showMenu ? undefined : styles.rotate} />
                    </div>
                </Flex>

                {showMenu && (accreditedAdvisor || isConsultant(user)) ? (
                    <Box mt={3}>
                        <Link to="/">
                            <Icon
                                icon="logo"
                                width="152px"
                                height="44px"
                                sx={{ mb: 3 }}
                                className={styles.logo}
                                alt="Capitaliz"
                            />
                            {isTestUser(user) ? (
                                <Box variant="training" mb={3}>
                                    {t('Training Environment')}
                                </Box>
                            ) : (
                                ''
                            )}
                        </Link>
                    </Box>
                ) : (
                    <CompanyHeader
                        isAdvisor={false}
                        companyId={user?.companyId}
                        condensed={!showMenu}
                        color="white"
                        sx={{ marginTop: '20px' }}
                        companyName={user?.companyName}
                        companyLogo={user?.companyLogo}
                    />
                )}
            </Box>

            <Box px={2}>
                {routes.map(({ title, icon, prefix, to }, key) => {
                    const active = pageUrl.indexOf(prefix) === 0;
                    return (
                        <div key={key}>
                            {user ? (
                                <Flex
                                    className={cx(styles.menuLinks, active ? styles.active : '')}
                                    onClick={() => (to ? (window.location.href = to) : history.push(prefix))}
                                    mb="2"
                                >
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            width: '24px',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Icon
                                            icon={icon}
                                            title={t(title)}
                                            style={{ color: active ? '#19CEFF' : '#fff', transition: 'color 0.5s ease-in-out' }}
                                        />
                                        {title === 'Inbox' && notifications.newCount > 0 && <Bubble small={true} sx={{ width: 'auto', minWidth: '16px' }} top="-6px" right="-50%">{notifications.newCount}</Bubble>}
                                    </Box>

                                    {showMenu && (
                                        <Box fontSize={1} className={styles.menuTitle}>
                                            {t(title)}
                                        </Box>
                                    )}
                                </Flex>
                            ) : (
                                <Flex mt={3} mb={2}>
                                    <Skeleton variant="circle" size="30px" mr={3} /> <Skeleton height="24px" width="70%" />
                                </Flex>
                            )}
                        </div>
                    );
                })}
            </Box>

            {showMenu && (accreditedAdvisor || isConsultant(user)) && (
                <Box py={10}>
                    <Flex sx={{ cursor: 'pointer' }} onClick={() => setExpand(!expand)} pt="5px" px={2}>
                        <Box pt="3px" fontWeight={500}>
                            {t('Assigned Companies')}
                        </Box>
                        <Box ml="auto" pt="2px">
                            <Icon
                                icon="arrowUp"
                                color="#E8ECEE"
                                title="Expand"
                                className={expand ? undefined : styles.rotate}
                            />
                        </Box>
                    </Flex>
                </Box>
            )}

            {(accreditedAdvisor || isConsultant(user)) && <AssignedCompanies expand={expand} pageUrl={pageUrl} showMenu={showMenu} />}


            {showMenu ? (
                <Box p={20} className={styles.termsConditions}>
                    <Box mb="10px">
                        {user ? <Link to="/terms-conditions">Terms & Conditions</Link> : <Skeleton width="70%" height="24px" />}
                    </Box>
                    <Box>
                        {user ? <a target="_blank" rel="noopener noreferrer" href="https://www.capitaliz.com/privacy-policy/">
                            Privacy Policy
                        </a> : <Skeleton width="70%" height="24px" />}
                    </Box>
                    <Box pt={30} fontSize={10}>
                        © {currentYear} Capitaliz Holdings. All right reserved.
                    </Box>
                </Box>
            ) : null}
        </div>
    );
};

export default connect((state) => ({ user: state.user }))(NavigationBar);
