import React, { Fragment, useRef, useState } from "react";
import { connect } from "react-redux";
import { Flex, Box, Heading, Button } from "rebass";
import { Label } from "@rebass/forms";
import styles from "./Assets/styles.module.scss";
import TaskLink from "./TaskLink";
import { isAdvisor, isAnalyst, isCompany, percentCompleted } from "@util/Functions";
import TaskProgressBar from "../TaskProgressBar";
import { PageTitle } from "~/Common/PageTitle";
import { addCustomTask } from "@app/Api";
import { toast } from "react-toastify";
import { SelectField, TextField, TextAreaField } from "~/Form";
import { Icon } from "@svg";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import RoadmapTable from "./RoadMap/RoadmapTable";
import { useLocation, Link } from "react-router-dom";
import { Form } from "~/Common/Form";
import { ProjectIcon } from "~/Common/ProjectIcon";
import { Modal } from "~/Common/Modal";
import { Tabs } from "~/Common/Tabs";
import { PageNavigation } from "../PageNavigation";
import { Card } from "~/Common/Card";
import { Accordion } from "~/Common/Accordion";
import { StatusTag } from "../StatusTag";
import { ExpandIcon } from "~/Common/ExpandIcon";
import theme from "@app/theme";

const StepAccordionHeader = ({
    step,
    onClick,
    redirect,
    companyId,
    expanded,
    ...props
}) => {
    return (
        <Flex
            onClick={onClick}
            sx={{
                bg: "#fff",
                borderTop: "1px solid #E8EAEF",
                cursor: "pointer",
                py: "16px",
                alignItems: "center",
                padding: "20px 24px",
            }}
        >
            <Heading as="h3" fontSize="14px" sx={{ flex: "250px" }}>
                {step.step}
            </Heading>
            <Box sx={{ flex: 1 }}>
                <StatusTag
                    type="step"
                    status={step.complete ? 2 : 1}
                    sx={{ display: "inline-block" }}
                />
            </Box>
            <RevaluationValue companyId={companyId} step={step} />
            <ExpandIcon expanded={expanded} />
        </Flex>
    );
};

const RevaluationValue = ({ step }) => {
    return step?.questionnaire ? <Box sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px 12px',
        background: '#2C2960',
        color: 'white',
        borderRadius: '4px',
        minWidth: step.revaluation ? '100px' : '0',
        justifyContent: 'space-between',
        fontSize: '12px',
        height: '36px',
        mr: '16px'
    }}>
        {step.revaluation ? <>
            <Icon icon="valuationUp" color="#16E4B3" mr="8px" />
            {step.revaluation.formatted}
        </> : <Icon icon="smallDynamicRevaluation" />}
    </Box> : <></>
}

const StepOutcome = ({ companyId, step, user }) => {
    const { t } = useTranslation();
    const heading = `Step Outcomes | ${step?.step}`
    const revaluable = step.potentialRevaluation?.raw
    const outcome = <Box sx={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/dr-background.svg)`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'right',
        backgroundColor: step.revaluation ? theme.colors.primary : 'white',
        color: step.revaluation ? 'white' : theme.colors.primary,
        padding: '14px 24px',
        borderRadius: '8px',
        backgroundSize: 'contain',
        mt: '14px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }}>
        <Icon icon={step.revaluation ? "whiteDynamicRevaluation" : "dynamicRevaluation"} />
        {(step.revaluation || step.potentialRevaluation.raw) && <Box sx={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'flex-start',
            ml: '32px',
            fontSize: '12px'
        }}>
            <Box sx={{ fontWeight: '700' }}>{t(step.revaluation ? 'Revaluation' : 'Potential')}</Box>
            <Icon icon="valuationUp" color="#16E4B3" mr="8px" ml="16px" />
            {step.revaluation ? step.revaluation.formatted : step.potentialRevaluation.formatted}
        </Box>}
        {revaluable && <Box display="flex" alignItems="center" sx={{ fontSize: '12px' }}>
            {step.revaluation ? <>
                {t(isAdvisor(user) ? 'Update' : 'View')}
                <Icon
                    sx={{ ml: '14px' }}
                    icon={isAdvisor(user) ? "pencil" : "arrow"}
                    color="white"
                    title={heading}
                />
            </> : <>
                {heading}
                <Icon
                    sx={{ ml: '14px' }}
                    icon="arrow"
                    color={theme.colors.primary}
                    title={heading}
                />
            </>}

        </Box>}
    </Box>

    if (revaluable && !isCompany(user)) {
        return <Link to={`/advisor/company/${companyId}/revaluation/${step.id}`}>
            {outcome}
        </Link>
    } else {
        return outcome
    }
}



const CompanyStages = ({
    user,
    stages,
    companyId,
    updateStages,
    companies,
    onboardingStages = true,
}) => {
    const location = useLocation();
    const stageId = isNaN(location?.state?.stageId)
        ? null
        : location.state.stageId - 1;
    const [activeStage, setActiveStage] = useState(stageId);
    const [roadMapView, setRoadMapView] = useState(false);
    const [showCustomTaskForm, setShowCustomTaskForm] = useState(false);
    const [errors, setErrors] = useState([]);
    const [currentSteps, setCurrentSteps] = useState([]);
    const { t } = useTranslation();
    const roadmapRef = useRef();
    const tableRef = useRef();
    const company = companies.find(
        (company) => company.id === parseInt(companyId)
    );
    const accreditedAdvisor = isAdvisor(user) || isAnalyst(user);

    const changeStage = (index) => {
        setActiveStage(index);
    };

    const handleAddCustomTask = () => {
        const stage = stages[activeStage] ?? null;
        setShowCustomTaskForm(true);

        setCurrentSteps(
            stage?.steps?.map(({ id, step }) => {
                return {
                    label: step,
                    value: id,
                };
            })
        );
    };

    const handleCustomTaskSubmit = (e) => {
        e.preventDefault();
        const stage = stages[activeStage] ?? null;
        const data = {
            companyId: parseInt(companyId),
            companyStageId: parseInt(stage.id),
            stepId: parseInt(e.target.stepId.value),
            name: e.target.name.value,
            description: e.target.description.value,
        };

        setErrors([]);
        addCustomTask(data)
            .then(() => {
                updateStages();
                setShowCustomTaskForm(false);
                toast.success("Task added successfully", {
                    toastId: "taskSuccess",
                });
            })
            .catch(({ response }) => {
                const errorMessage =
                    response?.data?.message || "Unable to add task";
                toast.error(errorMessage, { toastId: "taskError" });
                response?.data?.errors && setErrors(response.data.errors);
            });
    };

    return (
        <>
            <PageNavigation
                icon="folder"
                title="Projects"
                subtitle={stages[activeStage] && stages[activeStage].name}
            />
            <Box sx={{ px: 3 }}>
                <PageTitle subtitle={t("companyStages.subtitle")} />

                <Flex sx={{ gap: 3 }}>
                    <Tabs
                        label="Show me"
                        styleVariant="circle"
                        tabs={[
                            {
                                key: "tasks",
                                name: "Tasks",
                                icon: "grid",
                            },
                            {
                                key: "roadmap",
                                name: "Roadmap",
                                icon: "map",
                            },
                        ]}
                        selected={roadMapView ? "roadmap" : "tasks"}
                        onChange={(tab) =>
                            setRoadMapView(tab.key === "roadmap")
                        }
                        sx={{ m: 0 }}
                    />

                    {roadMapView && (
                        <Box>
                            <ReactToPrint
                                trigger={() => (
                                    <Button
                                        variant="transparentBorder2"
                                        sx={{ lineHeight: 1 }}
                                    >
                                        Print Roadmap
                                    </Button>
                                )}
                                content={() => roadmapRef.current}
                                onBeforePrint={() => document.title = `${company.name} Implementation plan`}
                            />
                        </Box>
                    )}
                </Flex>
            </Box>

            {showCustomTaskForm && (
                <Modal isOpen={true}>
                    <Form onSubmit={handleCustomTaskSubmit}>
                        <Flex>
                            <Heading>
                                Add task to "
                                {stages[activeStage]
                                    ? stages[activeStage].name
                                    : ""}
                                "
                            </Heading>
                            <Box ml="auto">
                                <Box
                                    variant="clickable"
                                    p={10}
                                    m="auto"
                                    pt={2}
                                    onClick={() => setShowCustomTaskForm(false)}
                                >
                                    <Icon
                                        icon="close"
                                        stroke="#656D78"
                                        title="Close Popup"
                                    />
                                </Box>
                            </Box>
                        </Flex>

                        <SelectField
                            name="stepId"
                            label="Step"
                            placeholder="Select the relevant step"
                            options={currentSteps}
                            errors={errors?.stepId}
                            nullable={false}
                        />

                        <TextField
                            name="name"
                            label="Name"
                            errors={errors?.name}
                        />

                        <TextAreaField
                            label="Description"
                            placeholder="Please include a short description about this task"
                            name="description"
                            errors={errors?.description}
                        />

                        <Box width={1} mt={2} textAlign="right">
                            <Button
                                variant="secondary"
                                type="button"
                                mr={2}
                                onClick={() => setShowCustomTaskForm(false)}
                            >
                                Cancel
                            </Button>
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </Box>
                    </Form>
                </Modal>
            )}

            {roadMapView && (
                <RoadmapTable
                    roadmapRef={roadmapRef}
                    tableRef={tableRef}
                    company={company}
                    stages={stages}
                    updateStages={updateStages}
                />
            )}

            {!roadMapView && (
                <Flex width={1} pb={5} mt={4}>
                    <Box width={1 / 2} sx={{ maxWidth: "380px" }}>
                        <Box>
                            {stages.map(
                                (
                                    {
                                        name,
                                        totalTasks,
                                        completedTasks,
                                        stageId,
                                    },
                                    index
                                ) => (
                                    <Box
                                        className={`${styles.stageBlock}${activeStage === index
                                            ? ` ${styles.active}`
                                            : ""
                                            }`}
                                        onClick={() => changeStage(index)}
                                        key={index}
                                        variant="card.wrapper"
                                        sx={{
                                            borderRadius: "8px",
                                            mb: "12px",
                                        }}
                                    >
                                        <Flex
                                            sx={{
                                                gridGap: "20px",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Box width={1}>
                                                <Flex
                                                    alignItems="center"
                                                    mb={1}
                                                >
                                                    <ProjectIcon id={stageId} />
                                                    <Heading
                                                        fontSize={14}
                                                        ml={2}
                                                    >
                                                        {t(name)}
                                                    </Heading>
                                                </Flex>
                                                <Flex mt={3}>
                                                    <Box
                                                        variant="chip"
                                                        color={"blueDark"}
                                                    >
                                                        <span>
                                                            {t(
                                                                "Tasks complete"
                                                            )}
                                                        </span>
                                                        {completedTasks}/
                                                        {totalTasks}
                                                    </Box>
                                                </Flex>
                                            </Box>
                                            <ExpandIcon
                                                expanded={activeStage === index}
                                            />
                                        </Flex>
                                    </Box>
                                )
                            )}
                        </Box>
                    </Box>

                    <Box width={1 / 2}>
                        {activeStage !== null && (
                            <>
                                <Card
                                    title={stages[activeStage].name}
                                    icon={
                                        <ProjectIcon
                                            id={stages[activeStage].stageId}
                                        />
                                    }
                                    sx={{ bg: "white" }}
                                    groupSx={{ py: 0 }}
                                    titleSx={{ pb: "8px" }}
                                >
                                    <Box
                                        sx={{
                                            pb: "24px",
                                        }}
                                    >
                                        <Box
                                            mb={3}
                                            className={styles.stageDescription}
                                            as="p"
                                        >
                                            {t(stages[activeStage].description)}
                                        </Box>
                                        <Flex>
                                            <Label
                                                mb={2}
                                                fontSize={10}
                                                color={"blueDark"}
                                            >
                                                {stages[activeStage].completedTasks} OF{" "}
                                                {stages[activeStage].totalTasks} TASKS
                                                COMPLETED
                                            </Label>
                                            <Box
                                                ml="auto"
                                                variant="taskPercent"
                                                fontWeight={600}
                                            >
                                                {percentCompleted(
                                                    stages[activeStage].completedTasks,
                                                    stages[activeStage].totalTasks
                                                )}
                                                %
                                            </Box>
                                        </Flex>
                                        <TaskProgressBar
                                            completed={
                                                stages[activeStage].completedTasks
                                            }
                                            total={stages[activeStage].totalTasks}
                                        />
                                    </Box>
                                    {activeStage < stages.length && (
                                        <>
                                            {stages[activeStage].steps.map(
                                                (step) => {
                                                    const el = (
                                                        <Accordion
                                                            key={step.id}
                                                            customHeader={(
                                                                props
                                                            ) => (
                                                                <StepAccordionHeader
                                                                    {...props}
                                                                    companyId={
                                                                        companyId
                                                                    }
                                                                    step={step}
                                                                />
                                                            )}
                                                            expand={false}
                                                            sx={{
                                                                bg: "#F5F7F8",
                                                                mx: "-24px",
                                                            }}
                                                        >
                                                            <Box p={24}>
                                                                {Array.isArray(
                                                                    step.tasks
                                                                ) &&
                                                                    step.tasks.map(
                                                                        (
                                                                            task
                                                                        ) => (
                                                                            <Fragment
                                                                                key={
                                                                                    task.taskId
                                                                                }
                                                                            >
                                                                                <TaskLink
                                                                                    {...task}
                                                                                    companyId={
                                                                                        companyId
                                                                                    }
                                                                                    disabled={
                                                                                        task.disabled
                                                                                    }
                                                                                />
                                                                            </Fragment>
                                                                        )
                                                                    )}
                                                                {step?.questionnaire && <StepOutcome companyId={companyId} step={step} user={user} />}
                                                            </Box>
                                                        </Accordion>
                                                    );
                                                    return el;
                                                }
                                            )}
                                        </>
                                    )}
                                </Card>

                                <>
                                    {accreditedAdvisor &&
                                        !showCustomTaskForm &&
                                        !onboardingStages ? (
                                        <Box mb={30} px={3}>
                                            <Button
                                                variant="primarySmall"
                                                onClick={() =>
                                                    handleAddCustomTask()
                                                }
                                            >
                                                Add Task
                                            </Button>
                                        </Box>
                                    ) : null}
                                </>
                            </>
                        )}
                    </Box>
                </Flex>
            )}
        </>
    );
};

export default connect((state) => ({
    user: state.user,
    companies: state.companies,
}))(CompanyStages);
