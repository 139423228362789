import { createSlice } from '@reduxjs/toolkit'

const stages = createSlice({
    name: 'stages',
    initialState: [],
    reducers: {
        setStages: (state, {payload}) => payload,
        resetStages: () => [],
    }
})

export const { setStages, resetStages } = stages.actions

export default stages.reducer
