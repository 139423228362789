import React, { useState, useEffect } from "react";
import { Link, Box, Button, Flex, Heading } from "rebass";
import { Modal } from "~/Common/Modal";
import { Icon } from "@svg";
import { toast } from "react-toastify";
import {
    SelectField,
    TextField,
    SearchableSelectField,
    TextAreaField,
    GooglePlacesAutocompleteField,
} from "~/Form";
import { connect } from "react-redux";
import UserAvatar from "~/Dashboard/Avatar";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import {
    isAdvisor,
    getErrorMessage,
    updateUserStore,
    isTestUser,
} from "@util/Functions";
import {
    addCompany,
    getIndustries,
    getSettings,
    validateCompanyInvite,
} from "@app/Api";
import LoadingSpinner from "~/LoadingSpinner";
import { geocodeByAddress } from "react-google-places-autocomplete";
import PaymentMethod from "../PaymentMethod";
import { Checkbox, Label } from "@rebass/forms";
import { formatCurrency } from "@util/Functions";
import { Form } from "~/Common/Form";
import { getCountryOptions } from "@util/Enum/Address";
import { ImageUpload } from "~/Form/ImageUpload";

const CreateCompany = ({ user, settings, showMenu }) => {
    const [newCompanyAdded, setNewCompanyAdded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [inviteLoading, setInviteLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [step, setStep] = useState(1);
    const [logo, setLogo] = useState("");
    const [logoPreview, setLogoPreview] = useState("");
    const [company, setCompany] = useState();
    const [companyName, setCompanyName] = useState("");
    const [country, setCountry] = useState("");
    const [extension, setExtension] = useState("+61");
    const [entity, setEntity] = useState("");
    const [job, setJob] = useState("");
    const [territory, setTerritory] = useState("");
    const [territoryOptions, setTerritoryOptions] = useState({});
    const [industryOptions, setIndustryOptions] = useState({});
    const [entityOptions, setEntityOptions] = useState({});
    const [jobOptions, setJobOptions] = useState({});
    const [territoryTerminology, setTerritoryTerminology] = useState("");
    const [postcodeTerminology, setPostcodeTerminology] = useState("");
    const [suburbTerminology, setSuburbTerminology] = useState("");
    const [industry, setIndustry] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [suburb, setSuburb] = useState("");
    const [postcode, setPostcode] = useState("");
    const [acn, setAcn] = useState("");
    const [email, setEmail] = useState("");
    const [website, setWebsite] = useState("");
    const [description, setDescription] = useState("");
    const [invites, setInvites] = useState([]);
    const [payment, setPayment] = useState({});
    const [purchaseSubscription, setPurchaseSubscription] = useState(false);
    const [purchaseReport, setPurchaseReport] = useState(false);
    const [useDefaultPayment, setUseDefaultPayment] = useState(true);
    const [paymentOptionSelected, setPaymentOptionSelected] = useState(false);
    const [clientSecret, setClientSecret] = useState("");
    const { t } = useTranslation();

    const subscriptionRequired = () =>
        payment?.products?.subscription?.required;
    const subscriptionPrice = () =>
        formatCurrency(
            payment?.products?.subscription?.price / 100,
            user.currency?.toUpperCase(),
            0,
            user.lang
        );
    const reportRequired = () => payment?.products?.report?.required;
    const paymentMethodRequired = () => payment?.method?.required;

    const paymentRequired = () => subscriptionRequired() || reportRequired();
    const setupPayment = () =>
        paymentOptionSelected &&
        (paymentMethodRequired() || !useDefaultPayment);

    useEffect(() => {
        let objectUrl = null;

        if (logo) {
            objectUrl = URL.createObjectURL(logo);
            setLogoPreview(objectUrl);
        }
        return () => objectUrl && URL.revokeObjectURL(objectUrl);
    }, [logo]);

    useEffect(() => {
        let isMounted = true;

        if (Object.keys(settings.countries).length === 0) {
            isMounted && setLoading(true);
        } else {
            isMounted && setLoading(false);
        }

        if (country) {
            getIndustries({
                params: {
                    country_code: country,
                },
            })
                .then((values) => {
                    const industryList = values.map(({ id, code, name }) => ({
                        value: id,
                        label: `${code} - ${name}`,
                    }));

                    isMounted && setIndustryOptions(industryList);
                })
                .catch(({ response }) =>
                    toast.error(
                        getErrorMessage(
                            response?.data,
                            "Unable to load industries data"
                        )
                    )
                );
        }


        return () => { isMounted = false };
    }, [country, settings]);

    if (!isAdvisor(user) || isTestUser(user)) {
        return <></>;
    }

    const extensionOptions = [
        {
            label: "+1",
            value: "+1",
        },
        {
            label: "+44",
            value: "+44",
        },
        {
            label: "+61",
            value: "+61",
        },
        {
            label: "+64",
            value: "+64",
        },
    ];

    const openModal = (e) => {
        e.preventDefault();
        setModalOpen(true);
    };

    const backStep = (e) => {
        e.preventDefault();
        setStep(step - 1);
    };

    const closeModal = (e) => {
        e.preventDefault();
        setModalOpen(false);
        if (newCompanyAdded) {
            updateUserStore();
        }
    };

    const onCountrySelect = (val) => {
        setCountry(val);
        setTerritory("");
        setIndustry("");

        getSettings().then((data) => {
            const options = getCountryOptions(val, data);
            setTerritoryOptions(options.territory);
            setEntityOptions(options.entity);
            setJobOptions(options.jobTypes);
            setTerritoryTerminology(options.terms.territory);
            setSuburbTerminology(options.terms.suburb);
            setPostcodeTerminology(options.terms.postcode);
            setExtension(options.terms.extension);
        });
    };

    const updateInvites = (e) => {
        e.preventDefault();

        setInviteLoading(true);
        setErrors({});

        validateCompanyInvite({
            email: email,
        })
            .then((response) => {
                if (response.hasOwnProperty("success") && response.success) {
                    setInvites((arr) => [...arr, response?.data]);
                    setEmail("");
                }

                setInviteLoading(false);
            })
            .catch(({ response }) => {
                setInviteLoading(false);
                response?.data?.errors && setErrors(response.data.errors);
                toast.error("Unable to add this email to the invite list", {
                    toastId: "addCompany",
                });
            });
    };

    const removeInvite = (removeIndex) => {
        setInviteLoading(true);
        const newInvites = invites.filter(
            (item, index) => index !== removeIndex
        );
        setInvites(newInvites);
        setInviteLoading(false);
    };

    const handleSubmit = (e, data) => {
        e.preventDefault();
        setErrors({});
        setLoading(true);
        const formData = new FormData();
        formData.append("step", step);

        switch (step) {
            case 1:
                formData.append("name", companyName);
                formData.append("country_code", country);
                formData.append("state", territory);
                formData.append("logo", logo);
                break;

            case 2:
                formData.append("entity", entity);
                formData.append("job_type_id", job);
                formData.append("industryId", industry?.value);
                formData.append("acn", acn);
                break;

            case 3:
                formData.append("name", companyName);
                formData.append("country_code", country);
                formData.append("state", territory);
                formData.append("logo", logo);
                formData.append("entity", entity);
                formData.append("job_type_id", job);
                formData.append("industryId", industry?.value);
                formData.append("acn", acn);
                formData.append("extension", extension);
                formData.append("phone", phone);
                formData.append("address", address);
                formData.append("suburb", suburb);
                formData.append("postcode", postcode);
                formData.append("website", website);
                formData.append("description", description);
                break;
            case 4:
                formData.append("companyId", company?.id);
                formData.append("purchaseSubscription", +purchaseSubscription);
                formData.append("purchaseReport", +purchaseReport);
                formData.append("setupPayment", +setupPayment());
                formData.append(
                    "paymentMethod",
                    data?.paymentMethod ? data.paymentMethod : ""
                );
                break;

            case 5:
                formData.append("companyId", company?.id);
                invites.map((invite) => {
                    formData.append("invites[][email]", invite?.email);
                    return invite;
                });
                break;

            default:
                break;
        }

        switch (step) {
            case 1:
            case 2:
                addCompany(formData)
                    .then((response) => {
                        if (
                            response.hasOwnProperty("success") &&
                            response.success
                        ) {
                            setStep(step + 1);
                            setLoading(false);
                        }
                    })
                    .catch(({ response }) => {
                        setLoading(false);
                        response?.data?.errors &&
                            setErrors(response.data.errors);
                        toast.error(
                            "Unable to move to the next step, please review the errors",
                            {
                                toastId: "addCompany",
                            }
                        );
                    });

                break;

            case 3:
                addCompany(formData)
                    .then((response) => {
                        if (
                            response.hasOwnProperty("success") &&
                            response.success
                        ) {
                            setStep(step + 1);
                            setCompany(response?.data?.company);
                            setNewCompanyAdded(true);
                            setPayment(response?.data?.payment);
                        }
                        setLoading(false);
                    })
                    .catch(({ response }) => {
                        setLoading(false);
                        response?.data?.errors &&
                            setErrors(response.data.errors);
                        toast.error(
                            "Unable to add the company, please review the errors",
                            {
                                toastId: "addCompany",
                            }
                        );
                    });

                break;

            case 4:
                if (setupPayment() && !data?.paymentMethod) {
                    addCompany(formData)
                        .then((response) => {
                            if (
                                response.hasOwnProperty("success") &&
                                response.success
                            ) {
                                setClientSecret(response?.data?.intent);
                            }
                            setLoading(false);
                        })
                        .catch(({ response }) => {
                            setLoading(false);
                            response?.data?.errors &&
                                setErrors(response.data.errors);
                            toast.error("Payment setup failed", {
                                toastId: "addCompany",
                            });
                        });
                } else {
                    addCompany(formData)
                        .then((response) => {
                            if (
                                response.hasOwnProperty("success") &&
                                response.success
                            ) {
                                setStep(step + 1);
                            }
                            setLoading(false);
                        })
                        .catch(({ response }) => {
                            setLoading(false);
                            response?.data?.errors &&
                                setErrors(response.data.errors);
                            toast.error("Payment failed", {
                                toastId: "addCompany",
                            });
                        });
                }

                break;

            case 5:
                addCompany(formData)
                    .then((response) => {
                        if (
                            response.hasOwnProperty("success") &&
                            response.success
                        ) {
                            setStep(1);
                            setCompany(null);

                            toast.info("Successfully sent the invites", {
                                toastId: "addCompany",
                            });

                            // Reset values
                            resetForm();
                            setModalOpen(false);
                            if (newCompanyAdded) {
                                updateUserStore();
                            }
                        }
                        setLoading(false);
                    })
                    .catch(({ response }) => {
                        setLoading(false);
                        response?.data?.errors &&
                            setErrors(response.data.errors);
                        toast.error(
                            "Unable to add the company, please review the errors",
                            {
                                toastId: "addCompany",
                            }
                        );
                    });
                break;

            default:
                break;
        }
    };

    const skipInvites = (e) => {
        e.preventDefault();

        resetForm();
        setModalOpen(false);
        if (newCompanyAdded) {
            updateUserStore();
        }
    };

    const resetForm = () => {
        setStep(1);
        setCompanyName("");
        setCountry("");
        setTerritory("");
        setLogo("");
        setEntity("");
        setIndustry("");
        setAcn("");
        setExtension("");
        setPhone("");
        setAddress("");
        setSuburb("");
        setPostcode("");
        setWebsite("");
        setDescription("");
        setPayment({});
        setPurchaseSubscription(false);
        setPurchaseReport(false);
        setUseDefaultPayment(true);
        setPaymentOptionSelected(false);
        setClientSecret("");
    };

    return (
        <Box>
            <Button
                variant="menuitem"
                onClick={openModal}
                sx={{ width: "100%" }}
                className={modalOpen ? "active" : ""}
            >
                <Flex
                    alignItems="center"
                    justifyContent={showMenu ? "left" : "center"}
                >
                    <Icon icon="addUser" />
                    {showMenu && (
                        <Box ml={"11px"}>{t("newCompany.buttonText")}</Box>
                    )}
                </Flex>
            </Button>

            <Modal
                isOpen={modalOpen}
                flex={true}
                maxWidth={600}
                minHeight={600}
            >
                <Flex
                    flexGrow="1"
                    flexDirection="column"
                    justifyContent="space-between"
                >
                    <Flex>
                        {step > 1 && (
                            <Link
                                as="a"
                                href="#back"
                                className={styles.navigateBack}
                                variant="clickable"
                                display="flex"
                                alignItems="center"
                                onClick={backStep}
                            >
                                <Icon icon="arrowLeft" title="Go Back" />
                                {` ${t("Back")}`}
                            </Link>
                        )}
                        <Box ml="auto" mb={2}>
                            <Link
                                as="a"
                                href="#close"
                                variant="clickable"
                                onClick={closeModal}
                            >
                                <Icon
                                    icon="close"
                                    stroke="#656D78"
                                    title="Close Popup"
                                />
                            </Link>
                        </Box>
                    </Flex>
                    {/* First Step */}
                    {step === 1 && (
                        <>
                            {loading ? (
                                <LoadingSpinner />
                            ) : (
                                <Form onSubmit={handleSubmit}>
                                    <Flex
                                        flexDirection="column"
                                        justifyContent="space-between"
                                        flexGrow="1"
                                    >
                                        <Box>
                                            <Box
                                                textAlign="center"
                                                mb={4}
                                                className={styles.headerContent}
                                            >
                                                <Heading mb={3}>
                                                    {t(
                                                        "newCompany.step1.title"
                                                    )}
                                                </Heading>
                                                {t("newCompany.step1.subtitle")}
                                            </Box>
                                            <TextField
                                                label="Add Company/Trading Name"
                                                name="addCompanyName"
                                                placeholder="Company Name/Trading Name"
                                                value={companyName}
                                                onChange={(val) =>
                                                    setCompanyName(val)
                                                }
                                                errors={errors?.name}
                                            />
                                            <SelectField
                                                label="Select Location"
                                                placeholder="Country"
                                                name="addCompanyCountry"
                                                value={country}
                                                onChange={(val) =>
                                                    onCountrySelect(val)
                                                }
                                                options={
                                                    settings.options.countries
                                                }
                                                errors={errors?.country_code}
                                            />
                                            {country &&
                                                Array.isArray(
                                                    territoryOptions
                                                ) &&
                                                territoryOptions.length && (
                                                    <SelectField
                                                        label={`Select ${territoryTerminology}`}
                                                        placeholder={`Select ${territoryTerminology}`}
                                                        name="addCompanyState"
                                                        value={territory}
                                                        onChange={(val) =>
                                                            setTerritory(val)
                                                        }
                                                        options={
                                                            territoryOptions
                                                        }
                                                        errors={errors?.state}
                                                    />
                                                )}
                                            <ImageUpload
                                                uploading={loading}
                                                text={t("upload.logo.title")}
                                                description={t(
                                                    "upload.logo.subtitle"
                                                )}
                                                value={logoPreview}
                                                onChange={(e) =>
                                                    setLogo(e.target.files[0])
                                                }
                                            />
                                            {errors.logo && (
                                                <Box
                                                    mt={-18}
                                                    mb={20}
                                                    color="red"
                                                    fontSize={12}
                                                >
                                                    {errors.logo[0]}
                                                </Box>
                                            )}
                                        </Box>
                                        <Box textAlign="center">
                                            <Button
                                                variant="tertiary"
                                                type="submit"
                                            >
                                                {`${t("Next:")} ${t(
                                                    "newCompany.step2.title"
                                                )}`}
                                            </Button>
                                        </Box>
                                    </Flex>
                                </Form>
                            )}
                        </>
                    )}

                    {/* Second Step */}
                    {step === 2 && (
                        <>
                            {loading ? (
                                <LoadingSpinner />
                            ) : (
                                <Form onSubmit={handleSubmit}>
                                    <Flex
                                        flexDirection="column"
                                        justifyContent="space-between"
                                        flexGrow="1"
                                    >
                                        <Box>
                                            <Box
                                                textAlign="center"
                                                mb={4}
                                                className={styles.headerContent}
                                            >
                                                <Heading mb={3}>
                                                    {t(
                                                        "newCompany.step2.title"
                                                    )}
                                                </Heading>
                                                {t("newCompany.step2.subtitle")}
                                            </Box>
                                            {entityOptions && (
                                                <SelectField
                                                    label="Select Type of Entity"
                                                    placeholder="Select Entity"
                                                    name="addCompanyEntity"
                                                    value={entity}
                                                    onChange={(val) =>
                                                        setEntity(val)
                                                    }
                                                    options={entityOptions}
                                                    errors={errors?.entity}
                                                />
                                            )}
                                            {jobOptions && (
                                                <SelectField
                                                    label="Job Type"
                                                    placeholder="Select Job"
                                                    name="addCompanyJob"
                                                    value={job}
                                                    onChange={(val) =>
                                                        setJob(val)
                                                    }
                                                    options={jobOptions?.map(({ id, name }) => ({ value: id, label: name }))}
                                                    errors={errors?.job_type_id}
                                                />
                                            )}
                                            {industryOptions && (
                                                <SearchableSelectField
                                                    label="What industry does the entity relate to?"
                                                    name="addCompanyIndustry"
                                                    isMulti={false}
                                                    placeholder="Select Business Category"
                                                    value={industry}
                                                    onChange={(val) =>
                                                        setIndustry(val)
                                                    }
                                                    options={industryOptions}
                                                    errors={errors?.industryId}
                                                />
                                            )}
                                            <TextField
                                                label="Business registration number"
                                                name="addCompanyAcn"
                                                placeholder={t(
                                                    `location.businessRegistrationNumber.${country}`
                                                )}
                                                value={acn}
                                                onChange={(val) => setAcn(val)}
                                                errors={errors?.acn}
                                            />
                                        </Box>
                                        <Box textAlign="center">
                                            <Button
                                                variant="tertiary"
                                                type="submit"
                                            >
                                                Next:{" "}
                                                {t("newCompany.step3.title")}
                                            </Button>
                                        </Box>
                                    </Flex>
                                </Form>
                            )}
                        </>
                    )}

                    {/* Third Step */}
                    {step === 3 && (
                        <>
                            {loading ? (
                                <LoadingSpinner />
                            ) : (
                                <Form onSubmit={handleSubmit}>
                                    <Flex
                                        flexDirection="column"
                                        justifyContent="space-between"
                                        flexGrow="1"
                                    >
                                        <Box>
                                            <Box
                                                textAlign="center"
                                                mb={4}
                                                className={styles.headerContent}
                                            >
                                                <Heading mb={3}>
                                                    {t(
                                                        "newCompany.step3.title"
                                                    )}
                                                </Heading>
                                                {t("newCompany.step3.subtitle")}
                                            </Box>

                                            <GooglePlacesAutocompleteField
                                                label="Start typing full address"
                                                name="addCompanyAddress"
                                                value={address}
                                                onChange={(output) => {
                                                    geocodeByAddress(
                                                        output?.label
                                                    )
                                                        .then((details) => {
                                                            setPostcode("");
                                                            setSuburb("");

                                                            if (
                                                                details?.[0]
                                                                    ?.address_components
                                                            ) {
                                                                details[0].address_components.map(
                                                                    (entry) => {
                                                                        switch (
                                                                        entry
                                                                            .types?.[0]
                                                                        ) {
                                                                            case "locality":
                                                                                setSuburb(
                                                                                    entry.long_name
                                                                                );
                                                                                break;

                                                                            case "postal_code":
                                                                                setPostcode(
                                                                                    entry.long_name
                                                                                );
                                                                                break;

                                                                            default:
                                                                                break;
                                                                        }
                                                                        return entry;
                                                                    }
                                                                );
                                                            }
                                                            return;
                                                        })
                                                        .catch((error) =>
                                                            console.error(error)
                                                        );
                                                    setAddress(
                                                        output?.value
                                                            ?.structured_formatting
                                                            ?.main_text
                                                    );
                                                }}
                                            />

                                            <TextField
                                                label="Address"
                                                name="addCompanyAddress"
                                                placeholder="Address"
                                                value={address}
                                                onChange={(val) =>
                                                    setAddress(val)
                                                }
                                                errors={errors?.address}
                                            />
                                            <Flex mb={3}>
                                                <Box width={1 / 2}>
                                                    <TextField
                                                        label={
                                                            suburbTerminology
                                                        }
                                                        placeholder={suburbTerminology}
                                                        name="addCompanySuburb"
                                                        value={suburb}
                                                        onChange={(val) =>
                                                            setSuburb(val)
                                                        }
                                                        errors={errors?.suburb}
                                                    />
                                                </Box>
                                                <Box width={1 / 2} ml={2}>
                                                    <TextField
                                                        label={
                                                            postcodeTerminology
                                                        }
                                                        placeholder={postcodeTerminology}
                                                        name="addCompanyPostcode"
                                                        value={postcode}
                                                        onChange={(val) =>
                                                            setPostcode(val)
                                                        }
                                                        errors={
                                                            errors?.postcode
                                                        }
                                                    />
                                                </Box>
                                            </Flex>
                                            <Flex>
                                                <Box width={1 / 6}>
                                                    {extensionOptions && (
                                                        <SelectField
                                                            label="Extension"
                                                            name="addCompanyExtension"
                                                            placeholder=" "
                                                            value={extension}
                                                            onChange={(val) =>
                                                                setExtension(
                                                                    val
                                                                )
                                                            }
                                                            options={
                                                                extensionOptions
                                                            }
                                                            errors={
                                                                errors?.extension
                                                            }
                                                        />
                                                    )}
                                                </Box>
                                                <Box width={5 / 6} ml={2}>
                                                    <TextField
                                                        label="Business Phone Number"
                                                        placeholder="Enter Business Phone Number"
                                                        name="addCompanyPhone"
                                                        value={phone}
                                                        onChange={(val) =>
                                                            setPhone(val)
                                                        }
                                                        errors={errors?.phone}
                                                    />
                                                </Box>
                                            </Flex>
                                            <TextField
                                                label="Website address"
                                                placeholder="Full website address"
                                                name="addCompanyWebsite"
                                                helpText="Please include the full https:// address"
                                                value={website}
                                                onChange={(val) =>
                                                    setWebsite(val)
                                                }
                                                errors={errors?.website}
                                            />
                                            <TextAreaField
                                                label="Description"
                                                placeholder="Please include a short description about this company"
                                                name="addCompanyDescription"
                                                value={description}
                                                onChange={(val) =>
                                                    setDescription(val)
                                                }
                                                errors={errors?.description}
                                            />
                                        </Box>
                                        <Box textAlign="center">
                                            <Button
                                                variant="tertiary"
                                                type="submit"
                                            >
                                                Create New Company
                                            </Button>
                                        </Box>
                                    </Flex>
                                </Form>
                            )}
                        </>
                    )}

                    {/* Fourth Step */}
                    {step === 4 &&
                        (loading ? (
                            <LoadingSpinner />
                        ) : paymentRequired() && clientSecret ? (
                            <PaymentMethod
                                afterAction={(e, data) => handleSubmit(e, data)}
                                secret={clientSecret}
                            />
                        ) : paymentRequired() ? (
                            <>
                                <Form onSubmit={handleSubmit} flexGrow="1" mx={-2} mb={4} display="flex">
                                    <Flex
                                        className={styles.paymentOptionHighlighted}
                                        mx={2}
                                        display="flex"
                                        flexGrow="1"
                                        flexDirection="column"
                                        justifyContent="space-between"
                                    >
                                        <Box className={styles.paymentOptionTop}>
                                            <Box>
                                                <Box className={styles.paymentOptionPill}>Process Payment</Box>
                                                <Box className={styles.paymentOptionFeatured}>
                                                    {subscriptionPrice()}
                                                    <span>/mo subscription per company</span>
                                                </Box>
                                                <Box className={styles.paymentOptionAddon}>
                                                    <span>Minimum 6 month subscription</span>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Box className={styles.paymentOptionDetails}>
                                                    Add new client company and start a Business Insights Reports
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className={styles.paymentOptionBottom}>
                                            <Box className={styles.paymentOptionInclusion}>
                                                <Icon icon="included" />
                                                New Company Profile
                                            </Box>
                                            <Box className={styles.paymentOptionInclusion}>
                                                <Icon icon="included" />
                                                Business Insights Report
                                            </Box>
                                            <Button
                                                mt={2}
                                                variant="tertiary"
                                                width={1}
                                                type="submit"
                                                onClick={() => {
                                                    setPurchaseSubscription(true);
                                                    setPurchaseReport(true);
                                                    setPaymentOptionSelected(true);
                                                }}
                                            >
                                                Pay Now
                                            </Button>
                                        </Box>
                                    </Flex>
                                </Form>
                                {Boolean(!paymentMethodRequired() && user.pm_last_four) ? (
                                    <Label display="flex" alignItems="center" justifyContent="center">
                                        <Checkbox
                                            checked={useDefaultPayment}
                                            onChange={() =>
                                                setUseDefaultPayment(
                                                    !useDefaultPayment
                                                )
                                            }
                                        />
                                        Use Card Ending In {user.pm_last_four}
                                    </Label>
                                ) : null}
                            </>
                        ) : (
                            setStep(step + 1)
                        ))}

                    {/* Fifth Step */}
                    {step === 5 && (
                        <>
                            {loading ? (
                                <LoadingSpinner />
                            ) : (
                                <Flex
                                    flexDirection="column"
                                    justifyContent="center"
                                    flexGrow="1"
                                >
                                    <Box textAlign="center">
                                        <Heading
                                            mt={4}
                                            mb={4}
                                            className={styles.successMessage}
                                        >
                                            {t("newCompany.step5.title")}
                                        </Heading>

                                        <Box>
                                            <Box
                                                textAlign="center"
                                                mb={4}
                                                className={styles.headerContent}
                                            >
                                                <Heading mb={3}>
                                                    {t(
                                                        "newCompany.step5.invites.title",
                                                        {
                                                            companyName:
                                                                company?.name,
                                                        }
                                                    )}
                                                </Heading>
                                                {t(
                                                    "newCompany.step5.invites.subtitle"
                                                )}
                                            </Box>

                                            {company?.logo && (
                                                <Box mb={4}>
                                                    <img
                                                        src={company.logo}
                                                        alt={company.name}
                                                        className={
                                                            styles.companyLogo
                                                        }
                                                    />
                                                </Box>
                                            )}

                                            <Form onSubmit={updateInvites}>
                                                <Flex width={1}>
                                                    <Box flexGrow="1">
                                                        <TextField
                                                            placeholder="Enter email address..."
                                                            value={email}
                                                            onChange={(val) =>
                                                                setEmail(val)
                                                            }
                                                        />
                                                    </Box>
                                                    <Box ml={2}>
                                                        <Button
                                                            variant="tertiary"
                                                            type="submit"
                                                        >
                                                            Add invite
                                                        </Button>
                                                    </Box>
                                                </Flex>
                                                {errors?.email && (
                                                    <Box
                                                        mt={-18}
                                                        mb={20}
                                                        color="red"
                                                        fontSize={12}
                                                        sx={{
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        {errors?.email[0]}
                                                    </Box>
                                                )}
                                            </Form>

                                            {inviteLoading ? (
                                                <LoadingSpinner />
                                            ) : (
                                                <Flex flexDirection="column">
                                                    <Flex
                                                        justifyContent="flex-start"
                                                        mb={3}
                                                    >
                                                        <Flex alignItems="center">
                                                            <UserAvatar
                                                                firstName={
                                                                    user.firstName
                                                                }
                                                                surname={
                                                                    user.surname
                                                                }
                                                                email={
                                                                    user?.email
                                                                }
                                                                avatar={
                                                                    user.avatar
                                                                }
                                                                mr="12px"
                                                            />

                                                            <Box>
                                                                {user.firstName}{" "}
                                                                {user.surname}{" "}
                                                                (You)
                                                            </Box>
                                                        </Flex>
                                                        <Flex
                                                            ml={"auto"}
                                                            alignItems="center"
                                                        >
                                                            advisor
                                                        </Flex>
                                                    </Flex>
                                                    {invites.map(
                                                        (invite, index) => (
                                                            <Flex
                                                                justifyContent="flex-start"
                                                                mb={3}
                                                                key={index}
                                                            >
                                                                <Flex
                                                                    justifyContent="flex-start"
                                                                    alignItems="center"
                                                                >
                                                                    {invite?.avatar ? (
                                                                        <UserAvatar
                                                                            firstName={
                                                                                invite?.firstName
                                                                            }
                                                                            surname={
                                                                                invite?.surname
                                                                            }
                                                                            email={
                                                                                invite?.email
                                                                            }
                                                                            avatar={
                                                                                invite?.avatar
                                                                            }
                                                                            mr="12px"
                                                                        />
                                                                    ) : (
                                                                        <Box
                                                                            className={
                                                                                styles.newAvatar
                                                                            }
                                                                            mr="12px"
                                                                        />
                                                                    )}

                                                                    <Box>
                                                                        {invite?.existing ? (
                                                                            <>
                                                                                {
                                                                                    invite.firstName
                                                                                }{" "}
                                                                                {
                                                                                    invite.surname
                                                                                }{" "}
                                                                                (
                                                                                {
                                                                                    invite.email
                                                                                }

                                                                                )
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {
                                                                                    invite.email
                                                                                }
                                                                            </>
                                                                        )}
                                                                    </Box>
                                                                </Flex>
                                                                <Flex
                                                                    ml={"auto"}
                                                                    alignItems="center"
                                                                >
                                                                    <Button
                                                                        type="button"
                                                                        display="flex"
                                                                        justifyContent="center"
                                                                        alignItems="center"
                                                                        p={1}
                                                                        variant="red"
                                                                        onClick={() =>
                                                                            removeInvite(
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        <Icon
                                                                            icon="close"
                                                                            stroke="white"
                                                                            title="Remove invite"
                                                                        />
                                                                    </Button>
                                                                </Flex>
                                                            </Flex>
                                                        )
                                                    )}

                                                    <Form
                                                        mt={4}
                                                        mb={4}
                                                        display="flex"
                                                        justifyContent="center"
                                                        flexDirection="column"
                                                        onSubmit={handleSubmit}
                                                    >
                                                        <Box
                                                            mb={3}
                                                            variant="small"
                                                        >
                                                            {t(
                                                                "newCompany.step5.invites.send"
                                                            )}
                                                        </Box>

                                                        <Button
                                                            variant="tertiary"
                                                            type="submit"
                                                        >
                                                            Send Invites
                                                        </Button>
                                                    </Form>
                                                </Flex>
                                            )}
                                        </Box>
                                        <Link
                                            as="a"
                                            href="#later"
                                            mb={4}
                                            className={styles.successClose}
                                            variant="clickable"
                                            display="flex"
                                            justifyContent="center"
                                            onClick={skipInvites}
                                        >
                                            Maybe Later
                                        </Link>
                                    </Box>
                                </Flex>
                            )}
                        </>
                    )}

                    {step < 5 && (
                        <Flex width={1} alignItems="center" mt={4}>
                            {step === 1 && (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    variant="subHeader"
                                >
                                    <Icon icon="oneActive" />
                                    Details
                                </Box>
                            )}
                            {step > 1 && (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    variant="subHeader"
                                >
                                    <Icon icon="done" />
                                    Details
                                </Box>
                            )}
                            <Box className={styles.progressBarDivider} />
                            {step < 2 && (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    variant="subHeader"
                                >
                                    <Icon icon="twoUpcoming" />
                                    Sector
                                </Box>
                            )}
                            {step === 2 && (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    variant="subHeader"
                                >
                                    <Icon icon="twoActive" />
                                    Sector
                                </Box>
                            )}
                            {step > 2 && (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    variant="subHeader"
                                >
                                    <Icon icon="done" />
                                    Sector
                                </Box>
                            )}
                            <Box className={styles.progressBarDivider} />
                            {step < 3 && (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    variant="subHeader"
                                >
                                    <Icon icon="threeUpcoming" />
                                    Address
                                </Box>
                            )}
                            {step === 3 && (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    variant="subHeader"
                                >
                                    <Icon icon="threeActive" />
                                    Address
                                </Box>
                            )}
                            {step > 3 && (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    variant="subHeader"
                                >
                                    <Icon icon="done" />
                                    Address
                                </Box>
                            )}
                            <Box className={styles.progressBarDivider} />
                            {step < 4 && (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    variant="subHeader"
                                >
                                    <Icon icon="fourUpcoming" />
                                    Payment
                                </Box>
                            )}
                            {step === 4 && (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    variant="subHeader"
                                >
                                    <Icon icon="fourActive" />
                                    Payment
                                </Box>
                            )}
                            {step > 4 && (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    variant="subHeader"
                                >
                                    <Icon icon="done" />
                                    Payment
                                </Box>
                            )}
                        </Flex>
                    )}
                </Flex>
            </Modal>
        </Box>
    );
};

export default connect((state) => ({
    user: state.user,
    settings: state.settings,
}))(CreateCompany);
