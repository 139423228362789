export const getAustralianStates = [
    { value: 'ACT', label: 'Australian Capital Territory' },
    { value: 'NSW', label: 'New South Wales' },
    { value: 'NT', label: 'Northern Territory' },
    { value: 'QLD', label: 'Queensland' },
    { value: 'SA', label: 'South Australia' },
    { value: 'TAS', label: 'Tasmania' },
    { value: 'VIC', label: 'Victoria' },
    { value: 'WA', label: 'Western Australia' },
];

export const getCountries = [
    { value: 'AU', label: 'Australia' },
    { value: 'CA', label: 'Canada' },
    { value: 'GB', label: 'United Kingdom' },
    { value: 'US', label: 'United States' },
    { value: 'NZ', label: 'New Zealand' },
];

export const getLanguages = [
    { value: 'en', label: 'English' },
    { value: 'en-US', label: 'English (US)' },
];

export const getCountryByCode = (code) => {
    return getCountries.map((country) => {
        if (country.value === code) {
            return country.label;
        }
        return null;
    });
};

export const getCountryOptions = (code, settings) => {
    const country = {
        territory: settings.options.territories[code] || [],
        entity: settings.options.entities[code] || [],
        jobTypes: settings?.jobTypes,
        terms: {
            territory: 'State',
            suburb: 'City',
            postcode: 'Postcode',
            extension: '+61',
        },
    };

    switch (code) {
        case 'CA':
            country.terms = {
                territory: 'Province',
                suburb: 'City',
                postcode: 'ZIP / Postal code',
                extension: '+1',
            };
            break;
        case 'GB':
            country.terms = {
                territory: 'County',
                suburb: 'City',
                postcode: 'Postcode',
                extension: '+44',
            };
            break;
        case 'US':
            country.terms = {
                territory: 'State',
                suburb: 'City',
                postcode: 'ZIP code',
                extension: '+1',
            };
            break;
        case 'NZ':
            country.terms = {
                territory: 'Region',
                suburb: 'Suburb',
                postcode: 'Postcode',
                extension: '+64',
            };
            break;
        default:
            break;
    }

    return country;
};
