import React from 'react';
import { Box } from 'rebass';

import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as Clock } from './clock.svg';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as Menu } from './menu.svg';
import { ReactComponent as Archive } from './archive.svg';
import { ReactComponent as Gear } from './gear.svg';
import { ReactComponent as ArrowUp } from './arrowUp.svg';
import { ReactComponent as ArrowLeft } from './arrowLeft.svg';
import { ReactComponent as AddUser } from './addUser.svg';
import { ReactComponent as Home } from './home.svg';
import { ReactComponent as Bell } from './bell.svg';
import { ReactComponent as Bell2 } from './bell2.svg';
import { ReactComponent as Sort } from './sort.svg';
import { ReactComponent as Report } from './report.svg';
import { ReactComponent as Reports } from './reports.svg';
import { ReactComponent as RoundCheck } from './round-check.svg';
import { ReactComponent as Arrow } from './arrow.svg';
import { ReactComponent as DropdownArrow } from './dropdown-arrow.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Chart } from './chart.svg';
import { ReactComponent as ChartThin } from './chart-thin.svg';
import { ReactComponent as ClipboardCheck } from './clipboard-check.svg';
import { ReactComponent as Attachment } from './attachment.svg';
import { ReactComponent as LikeOff } from './likeOff.svg';
import { ReactComponent as LikeOn } from './likeOn.svg';
import { ReactComponent as Download } from './download.svg';
import { ReactComponent as Clipboard } from './clipboard.svg';
import { ReactComponent as AddCircle } from './addCircle.svg';
import { ReactComponent as UploadPhoto } from './photoUpload.svg';
import { ReactComponent as CompletedNo } from './completedNo.svg';
import { ReactComponent as CompletedYes } from './completedYes.svg';
import { ReactComponent as Folder } from './folder.svg';
import { ReactComponent as Exit } from './exit.svg';
import { ReactComponent as Eye } from './eye.svg';
import { ReactComponent as Upload } from './upload.svg';
import { ReactComponent as RadiosOn } from './radiosOn.svg';
import { ReactComponent as RadiosOff } from './radiosOff.svg';
import { ReactComponent as ProgressLine } from './progressLine.svg';
import { ReactComponent as Warning } from './warning.svg';
import { ReactComponent as Notification } from './notificationIcon.svg';
import { ReactComponent as Drag } from './drag.svg';
import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as List } from './list.svg';
import { ReactComponent as ArrowLeftCircle } from './arrowLeftCircle.svg';
import { ReactComponent as Tick } from './tick.svg';
import { ReactComponent as Camera } from './camera.svg';
import { ReactComponent as Cog } from './cog.svg';
import { ReactComponent as Computer } from './computer.svg';
import { ReactComponent as Grid } from './grid.svg';
import { ReactComponent as Paper } from './paper.svg';
import { ReactComponent as Paper2 } from './paper2.svg';
import { ReactComponent as Paper3 } from './paper3.svg';
import { ReactComponent as Notepad } from './notepad.svg';
import { ReactComponent as Stats } from './stats.svg';
import { ReactComponent as Hamburger } from './hamburger.svg';
import { ReactComponent as HamburgerClose } from './hamburger-close.svg';
import { ReactComponent as Success } from './success.svg';
import { ReactComponent as Error } from './error.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as Link } from './link.svg';
import { ReactComponent as User } from './user.svg';
import { ReactComponent as Users } from './users.svg';
import { ReactComponent as UserCircle } from './user-circle.svg';
import { ReactComponent as Export } from './export.svg';
import { ReactComponent as Texture } from './texture.svg';
import { ReactComponent as Map } from './map.svg';

import { ReactComponent as Asic } from './asic.svg';
import { ReactComponent as Briefcase } from './briefcase.svg';
import { ReactComponent as Currency } from './currency.svg';
import { ReactComponent as ExtractValue } from './extract-value.svg';
import { ReactComponent as IdentifyValue } from './identify-value.svg';
import { ReactComponent as ProtectValue } from './protect-value.svg';
import { ReactComponent as Staff } from './staff.svg';
import { ReactComponent as Stakeholders } from './stakeholders.svg';
import { ReactComponent as Valuation } from './valuation.svg';
import { ReactComponent as Roles } from './roles.svg';
import { ReactComponent as Card } from './card.svg';

import { ReactComponent as ScorecardIconBetafactor } from './scorecard-betafactor.svg';
import { ReactComponent as ScorecardIconCredit } from './scorecard-credit.svg';
import { ReactComponent as ScorecardIconExit } from './scorecard-exit.svg';
import { ReactComponent as ScorecardIconFinancial } from './scorecard-financial.svg';
import { ReactComponent as ScorecardIconNonFinancial } from './scorecard-non-financial.svg';
import { ReactComponent as ScorecardFoundation } from './scorecard-foundation.svg';
import { ReactComponent as ChartGrowth } from './chart-growth.svg';

import { ReactComponent as GainArrow } from './gainArrow.svg';

import { ReactComponent as Dot } from './dot.svg';
import { ReactComponent as Dots } from './dots.svg';

import { ReactComponent as Filter } from './Filter.svg';

import { ReactComponent as Comment } from './comment.svg';
import { ReactComponent as CommentNew } from './commentNew.svg';

import { ReactComponent as Done } from './done.svg';
import { ReactComponent as OneActive } from './one-active.svg';
import { ReactComponent as TwoUpcoming } from './two-upcoming.svg';
import { ReactComponent as TwoActive } from './two-active.svg';
import { ReactComponent as ThreeUpcoming } from './three-upcoming.svg';
import { ReactComponent as ThreeActive } from './three-active.svg';
import { ReactComponent as FourUpcoming } from './four-upcoming.svg';
import { ReactComponent as FourActive } from './four-active.svg';
import { ReactComponent as PlusCircle } from './plus-circle.svg';
import { ReactComponent as Plus } from './plus.svg';
import { ReactComponent as QuestionMark } from './question-mark.svg';
import { ReactComponent as QuestionMark2 } from './question-mark-2.svg';
import { ReactComponent as QuestionMark3 } from './question-mark-3.svg';

import { ReactComponent as Included } from './included.svg';
import { ReactComponent as Excluded } from './excluded.svg';

import { ReactComponent as MaximiseValue } from './maximise-value.svg';
import { ReactComponent as Scorecards } from './scorecards.svg';
import { ReactComponent as ArrowCircle } from './arrow-circle.svg';

import { ReactComponent as Trash } from './trash.svg';
import { ReactComponent as Pencil } from './pencil.svg';
import { ReactComponent as Academy } from './academy.svg';
import { ReactComponent as Checklist } from './checklist.svg';
import { ReactComponent as WhatsNew } from './whats-new.svg';
import { ReactComponent as Nopat } from './nopat.svg';
import { ReactComponent as Revaluation } from './revaluation.svg';

import { ReactComponent as DynamicRevaluation } from './dynamic-revaluation.svg';
import { ReactComponent as WhiteDynamicRevaluation } from './white-dynamic-revaluation.svg';
import { ReactComponent as SmallDynamicRevaluation } from './small-dynamic-revaluation.svg';
import { ReactComponent as ValuationUp } from './valuationUp.svg';
import { ReactComponent as Maximize } from './maximize.svg';
import { ReactComponent as Minimize } from './minimize.svg';

export const Icons = {
    academy: Academy,
    arrowCircle: ArrowCircle,
    texture: Texture,
    logo: Logo,
    calendar: Calendar,
    clock: Clock,
    scorecards: Scorecards,
    list: List,
    pencil: Pencil,
    menu: Menu,
    archive: Archive,
    gear: Gear,
    arrowUp: ArrowUp,
    arrowLeft: ArrowLeft,
    dropdownArrow: DropdownArrow,
    addUser: AddUser,
    home: Home,
    trash: Trash,
    bell: Bell,
    bell2: Bell2,
    report: Report,
    reports: Reports,
    roundCheck: RoundCheck,
    arrow: Arrow,
    close: Close,
    card: Card,
    chart: Chart,
    chartThin: ChartThin,
    attachment: Attachment,
    likeOff: LikeOff,
    likeOn: LikeOn,
    download: Download,
    clipboard: Clipboard,
    addCircle: AddCircle,
    uploadPhoto: UploadPhoto,
    completedNo: CompletedNo,
    completedYes: CompletedYes,
    exit: Exit,
    eye: Eye,
    folder: Folder,
    upload: Upload,
    radiosOn: RadiosOn,
    radiosOff: RadiosOff,
    progressLine: ProgressLine,
    warning: Warning,
    notification: Notification,
    drag: Drag,
    edit: Edit,
    arrowLeftCircle: ArrowLeftCircle,
    tick: Tick,
    camera: Camera,
    cog: Cog,
    computer: Computer,
    grid: Grid,
    paper: Paper,
    paper2: Paper2,
    paper3: Paper3,
    notepad: Notepad,
    stats: Stats,
    hamburger: Hamburger,
    hamburgerClose: HamburgerClose,
    success: Success,
    error: Error,
    search: Search,
    link: Link,
    user: User,
    users: Users,
    userCircle: UserCircle,
    sort: Sort,

    export: Export,

    asic: Asic,
    briefcase: Briefcase,
    currency: Currency,
    extractValue: ExtractValue,
    identifyValue: IdentifyValue,
    protectValue: ProtectValue,
    staff: Staff,
    roles: Roles,
    stakeholders: Stakeholders,
    valuation: Valuation,

    scorecardBetafactor: ScorecardIconBetafactor,
    scorecardCredit: ScorecardIconCredit,
    scorecardExit: ScorecardIconExit,
    scorecardFinancial: ScorecardIconFinancial,
    scorecardNonFinancial: ScorecardIconNonFinancial,
    scorecardFoundation: ScorecardFoundation,
    chartGrowth: ChartGrowth,

    gainArrow: GainArrow,

    dot: Dot,
    dots: Dots,

    map: Map,

    filter: Filter,

    comment: Comment,
    commentNew: CommentNew,

    done: Done,
    oneActive: OneActive,
    twoUpcoming: TwoUpcoming,
    twoActive: TwoActive,
    threeUpcoming: ThreeUpcoming,
    threeActive: ThreeActive,
    fourUpcoming: FourUpcoming,
    fourActive: FourActive,
    plusCircle: PlusCircle,
    questionMark: QuestionMark,
    questionMark2: QuestionMark2,
    questionMark3: QuestionMark3,

    included: Included,
    excluded: Excluded,

    clipboardCheck: ClipboardCheck,
    maximiseValue: MaximiseValue,
    plus: Plus,
    checklist: Checklist,
    whatsNew: WhatsNew,
    nopat: Nopat,
    revaluation: Revaluation,
    dynamicRevaluation: DynamicRevaluation,
    whiteDynamicRevaluation: WhiteDynamicRevaluation,
    smallDynamicRevaluation: SmallDynamicRevaluation,
    valuationUp: ValuationUp,

    maximize: Maximize,
    minimize: Minimize
};

export const Icon = ({ icon, sx, size, width, height, color, className, stroke, variant = 'default', ...props }) => {
    const pixelMe = (val) => {
        val = val.toString();
        return val === 'auto' || val.includes('%') || val.includes('px') ? val : `${val}px`;
    };
    const svgWidth = width !== undefined ? pixelMe(width) : pixelMe(size);
    const svgHeight = height !== undefined ? pixelMe(height) : pixelMe(size);

    // Add styles from props
    sx = sx || {};
    if (color) sx = { color: color, ...sx };
    if (stroke) sx.svg = { stroke, ...sx.svg };

    const renderIcon = (icon) => {
        if (Icons.hasOwnProperty(icon)) {
            const Icon = Icons[icon];
            return <Icon />;
        }
        if (process.env.NODE_ENV !== 'production') {
            console.log(`Missing icon: ${icon}`);
        }
        return '';
    };

    return (
        <Box
            className={`icon ${className ?? ''}`}
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: svgWidth,
                height: svgHeight,
                '> svg': {
                    width: svgWidth !== 'auto' ? '100%' : 'auto',
                    height: svgHeight !== 'auto' ? '100%' : 'auto',
                    maxWidth: '100%',
                    maxHeight: '100%',
                },
                ...sx,
            }}
            variant={`icons.${variant}`}
            {...props}
        >
            {renderIcon(icon)}
        </Box>
    );
};

Icon.defaultProps = {
    size: 'auto',
};
