import React, { useEffect, useState } from 'react';
import { Flex, Box, Button, Heading } from 'rebass';
import { Link } from 'react-router-dom';
import LoadingSpinner from '~/LoadingSpinner';
import { PageTitle } from '~/Common/PageTitle';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { getTaskStatus } from '@util/Enum';
import { Badge } from '~/Common/Badge';

const CompanyNormalisation = ({ companyId }) => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const companies = useSelector((state) => state.companies);
    const company = companies.find((company) => company.id === parseInt(companyId));
    const todo = company?.todo;
    const statusClasses = {
        financial: getTaskStatus(todo?.financials >= 4 ? 3 : 1),
        addbacks: getTaskStatus(todo?.addbacks),
        benchmarking: getTaskStatus(todo?.benchmarking)
    };

    useEffect(() => {
        if (companyId) {
            setLoading(true);

            setTimeout(function () {
                setLoading(false);
            }, 250)
        }
    }, [companyId]);

    if (loading) return <LoadingSpinner />;

    return (
        <>
            <Box px={3}>
                <PageTitle
                    subtitle={t('normalisation.subtitle')}
                />
            </Box>
            <Flex sx={{ flexWrap: 'wrap' }}>
                <Box width={[1 / 1, '', '', 1 / 2, 1 / 3]} mb="30px">
                    <Flex variant="card.wrapper" sx={{ bg: '#fff', minHeight: '100%', flexDirection: 'column' }} p={20}>
                        <Flex alignItems="center" mb="15px">
                            <Box width={2 / 3}>
                                <Heading>
                                    {t('financialImports.title')}
                                </Heading>
                            </Box>
                            <Box textAlign="right" width={1 / 3}>
                                <Badge sx={statusClasses.financial.sx}>
                                    {statusClasses.financial.name}
                                </Badge>
                            </Box>
                        </Flex>
                        {t('financialImports.subtitle')}

                        <Flex mt="auto">
                            <Link to={`/advisor/company/${companyId}/financial-imports`} style={{ display: 'block', width: '100%' }}>
                                <Button variant="gray" flex={1} width={1}>
                                    {statusClasses.financial.name === 'COMPLETE' ? 'View Financials' : 'Upload Financials'}
                                </Button>
                            </Link>
                        </Flex>
                    </Flex>
                </Box>
                <Box width={[1 / 1, '', '', 1 / 2, 1 / 3]} mb="30px">
                    <Flex variant="card.wrapper" sx={{ bg: '#fff', minHeight: '100%', flexDirection: 'column' }} p={20}>
                        <Flex alignItems="center" mb="15px">
                            <Box width={2 / 3}>
                                <Heading>
                                    {t('addbacks.title')}
                                </Heading>
                            </Box>
                            <Box textAlign="right" width={1 / 3}>
                                <Badge sx={statusClasses.addbacks.sx}>
                                    {statusClasses.addbacks.name}
                                </Badge>
                            </Box>
                        </Flex>
                        {t('addbacks.subtitle')}

                        <Flex mt="auto">
                            <Link to={`/advisor/company/${companyId}/addbacks`} style={{ display: 'block', width: '100%' }}>
                                <Button variant="gray" flex={1} width={1}>
                                    {statusClasses.addbacks.name === 'COMPLETE' ? 'View Addbacks' : 'Categorize Addbacks'}
                                </Button>
                            </Link>
                        </Flex>
                    </Flex>
                </Box>
                {false && <Box width={[1 / 1, '', '', 1 / 2, 1 / 3]} mb="30px">
                    <Flex variant="card.wrapper" sx={{ bg: '#fff', minHeight: '100%', flexDirection: 'column' }} p={20}>
                        <Flex alignItems="center" mb="15px">
                            <Box width={2 / 3}>
                                <Heading>
                                    {t('benchmarking.title')}
                                </Heading>
                            </Box>
                            <Box textAlign="right" width={1 / 3}>
                                <Button variant="task" ml="auto" py="8px" sx={statusClasses.benchmarking.sx}>
                                    {statusClasses.benchmarking.name}
                                </Button>
                            </Box>
                        </Flex>
                        {t('benchmarking.subtitle')}

                        <Flex mt="auto">
                            <Link to={`/advisor/company/${companyId}/benchmarking`} style={{ display: 'block', width: '100%' }}>
                                <Button variant="gray" flex={1} width={1}>
                                    View Benchmarking
                                </Button>
                            </Link>
                        </Flex>
                    </Flex>
                </Box>}
            </Flex>
        </>
    );
};

export default CompanyNormalisation;
