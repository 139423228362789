import styled from '@emotion/styled';
import { Box } from 'rebass';

export const LanguageSwitcherStyled =  styled(Box)`
    display: flex;

    button {
        font-size: 9px;
        padding: 4px 8px;
    }

`;
