import React, { forwardRef } from 'react';
import { Box } from 'rebass';

export const Form = forwardRef(({ children, ...props }, ref) => {
    return (
        <Box ref={ref} as="form" {...props}>
            {children}
        </Box>
    );
});
