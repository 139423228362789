import React, { useCallback, useEffect, useState } from "react";
import { Text } from "rebass";
import { Table } from "~/Common/Table";
import { getCompanyQuestionnaire } from "@app/Api/Advisor";
import { toast } from "react-toastify";
import { formatDate, getErrorMessage } from "@util/Functions";
import { IconButton } from "~/Common/IconButton";
import { CompanyStepStatus } from "~/Common/CompanyStepStatus";

const getRowLink = (row, companyId) => row.status !== 1 ? `/advisor/company/${companyId}/revaluation/${row.id}` : null

const getColumns = (companyId) => {
    let columns = [
        {
            key: "stage",
            title: "Stage",
            sx: {
                minWidth: 300
            },
            render: (row) => (
                <Text
                    sx={{
                        fontWeight: 600,
                        fontSize: "12px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                    }}
                >
                    {row?.stage?.name}
                </Text>
            ),
        },
        {
            key: "step",
            title: "Step",
            sx: {
                minWidth: 200
            },
            render: (row) => (
                <Text
                    sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        color: "#6F7782",
                    }}
                >
                    {row.step}
                </Text>
            ),
        },
        {
            key: "status",
            title: "Status",
            sx: {
                minWidth: 180
            },
            render: (row) => {
                return (
                    <CompanyStepStatus status={row.status} />
                );
            },
        },
        {
            key: "nopat",
            title: "NOPAT Value",
            sx: {
                minWidth: 200
            },
            render: (row) => (
                <Text
                    sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        color: "#6F7782",
                    }}
                >
                    {row.nopat ?? "N/A"}
                </Text>
            ),
        },
        {
            key: "date",
            title: "Date Completed",
            sx: {
                minWidth: 200
            },
            render: (row) => (
                <Text
                    sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        color: "#6F7782",
                    }}
                >
                    {formatDate(row.dateCompleted, "-")}
                </Text>
            ),
        },
        {
            key: "uplift",
            title: "Uplift",
            sx: {
                minWidth: 200
            },
            render: (row) => (
                <Text
                    sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        color: "#6F7782",
                    }}
                >
                    {row?.revaluation?.formatted ?? '-'}
                </Text>
            ),
        },
        {
            key: 'actions',
            sx: {
                justifyContent: 'flex-end'
            },
            sortable: false,
            render: (row) => {
                const rowLink = getRowLink(row, companyId)

                return rowLink ? <IconButton to={rowLink} label={'Update Step'} /> : <></>
            },
        }
    ];

    return columns;
};

export const StepOutcomes = ({ companyId, user }) => {
    const [isLoading, setLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [orderBy, setOrderBy] = useState(null);

    const fetchQuestionnaires = useCallback(() => {
        setLoading(true);
        getCompanyQuestionnaire(companyId, { orderBy })
            .then((response) => {
                setData(response?.data);
                setLoading(false);
            })
            .catch(({ response }) =>
                toast.error(
                    getErrorMessage(
                        response?.data,
                        "Unable to load company dynamic revaluation data"
                    ),
                    { toastId: "getCompanyQuestionnaireError" }
                )
            );
    }, [companyId, orderBy])

    useEffect(() => {
        fetchQuestionnaires()
        setColumns(getColumns(companyId));
    }, [companyId, fetchQuestionnaires]);

    return (
        <Table
            rowProps={row => ({ rowLink: getRowLink(row, companyId) })}
            columns={columns}
            columnSort={(column, order) => setOrderBy(`${column},${order}`)}
            data={data}
            isLoading={isLoading}
            minWidth={1000}
            emptyTemplate={{
                // icon: 'done',
                heading: "No step outcomes",
                // content: 'There are no action items matching the current options',
            }}
        />
    );
};
