import React from 'react'
import { Box, Flex, Heading } from 'rebass'
import { Icon } from '@svg'
import styles from './Assets/styles.module.scss'

const PopoutSidebar = ({ title, icon, requestClose, children, ...props }) => {
    return <div className={styles.dropdownBox} {...props}>
        <Box p={23} className={styles.dropdownLine}>
            <Flex>
                {icon && (
                    <Box>
                        <Icon icon={icon} stroke='#093467' title={title} className={styles.dropdownIcon} />
                    </Box>
                )}
                {title && (<Heading flex={1} ml={20} fontSize={20}>{title}</Heading>)}
                <Box onClick={() => requestClose()} variant='clickable' >
                    <Icon icon="close" stroke="#656D78" title={`Close ${title}`} className={styles.dropdownCloseButton} />
                </Box>
            </Flex>
        </Box>

        <Box variant='headerShading' sx={{ overflowY: 'auto' }} id="popoutSidebarContent">
            {children}
        </Box>
    </div>
}

export default PopoutSidebar;
