import React, { useState } from 'react';
import { formatCurrency, getErrorMessage, updateUserStore } from '@util/Functions';
import { getStripeCustomerPortal, resumeSubscription } from '@app/Api';
import { toast } from 'react-toastify';
import { Flex, Box, Button, Link, Heading } from 'rebass';
import { Icon } from '@svg';
import { connect } from 'react-redux';
import { Modal } from '~/Common/Modal';
import LoadingSpinner from '~/LoadingSpinner';
import { useHistory } from 'react-router-dom';
import PayForSubscription from 'Pages/Common/PayForSubscription';
import { useTranslation } from 'react-i18next';

const ManageSubscriptions = ({ user }) => {
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const history = useHistory();
    const { t } = useTranslation();

    const navigateToCustomerPortal = (userId) => {
        setLoading(true);
        getStripeCustomerPortal(userId)
            .then((response) => {
                if (response.hasOwnProperty('success') && response.success) {
                    window.location = response.url;
                }
            })
            .catch(({ response }) => {
                toast.error(response?.data?.message || 'Unable to access customer billing portal');
                setLoading(false);
            });
    };

    const submitResume = (companyId) => {
        setLoading(true);
        resumeSubscription(companyId)
            .then((data) => {
                updateUserStore().then(() => setLoading(false));
                toast.success(data?.message ? data.message : 'Subscription resumed');
            })
            .catch(({ response }) => {
                toast.error(getErrorMessage(response?.data, 'Unable to resume subscription'));
                setLoading(false);
            });
    };

    const billing = subscription => {
        if (subscription?.cancelled) {
            return `Subscription ending ${subscription?.endsAt}`
        } else {
            const price = formatCurrency(subscription?.price?.unit_amount / 100, subscription?.price?.currency)
            const date = subscription?.nextBillingDate

            return `${price || date ? 'Next payment' : 'Subscription active'}${price ? ` ${price}` : ''}${date ? ` on ${date}` : ''}.`
        }
    }

    return loading ? (
        <LoadingSpinner />
    ) : (
        <>
            <Modal
                isOpen={modalOpen}
                flex={true}
                maxWidth={600}
                minHeight={600}
            >
                <Flex flexGrow="1" flexDirection="column" justifyContent="space-between">
                    <Flex>
                        <Box ml="auto" mb={2}>
                            <Link as="a" href="#close" variant="clickable" onClick={() => setModalOpen(false)}>
                                <Icon icon="close" stroke="#656D78" title="Close Popup" />
                            </Link>
                        </Box>
                    </Flex>
                    <Flex flexDirection="column" flexGrow="1" alignItems="center" justifyContent="center">
                        <Heading textAlign="center" mb={2}>
                            Pause Subscription
                        </Heading>
                        <Box textAlign="center" mb={4}>
                            To request a pause on the company subscription, please reach out to our Customer Support
                            team using the "Help" widget on the bottom right.
                        </Box>
                        <Button variant="tertiary" onClick={() => setModalOpen(false)} mb={4}>
                            OK
                        </Button>
                    </Flex>
                </Flex>
            </Modal>
            <Box variant="card.wrapper">
                <Box variant="card.header" display="flex" justifyContent="space-between">
                    Manage Subscriptions
                    {user.isStripeCustomer && (
                        <Button variant="primarySmall" onClick={() => navigateToCustomerPortal(user.id)}>
                            Manage Payments
                        </Button>
                    )}
                </Box>
                <Box variant="card.container">
                    {Array.isArray(user.paidCompanies) && user.paidCompanies.length ? (
                        user.paidCompanies?.map((subscription, index) => (
                            <Flex key={index} justifyContent="space-between" alignItems="center" mb={20}>
                                <Flex flexDirection="column">
                                    <Box as="h4">{subscription?.company?.name}</Box>
                                    <Box color="gray3" fontWeight="400">
                                        {billing(subscription)}
                                    </Box>
                                </Flex>
                                {(subscription?.trialing && subscription?.cancelled) ? <PayForSubscription
                                    companyId={subscription?.company?.id}
                                    shouldActivate={true}
                                    buttonText={t('Activate Subscription')}
                                    variant="primarySmall"
                                /> : subscription?.cancelled ?
                                    <Button
                                        variant="primarySmall"
                                        onClick={() => submitResume(subscription?.company?.id)}
                                    >
                                        Resume Subscription
                                    </Button> :
                                    <Button
                                        variant="warningSmall"
                                        onClick={() => {
                                            history.push('/support', {
                                                name: `${user?.firstName} ${user?.surname}`,
                                                email: user?.email,
                                                message: `Hi there, I would like to pause a subscription for ${subscription?.company?.name}, in order to resume it later.`,
                                            });
                                        }}
                                    >
                                        Pause Subscription
                                    </Button>}
                            </Flex>
                        ))
                    ) : (
                        <Box>You have no active subscriptions</Box>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default connect((state) => ({ user: state.user }))(ManageSubscriptions);
