import React, { useState, useEffect } from 'react';
import { Flex, Button } from 'rebass';
import { isAnalyst } from '@util/Functions';
import { connect, useDispatch, useSelector } from 'react-redux';
import styles from './Assets/styles.module.scss';
import { setTrainingMode } from '@app/Store/storeUi';
import { useTranslation } from 'react-i18next';
import CompanyList from '~/Advisor/CompanyList';
import TasksList from '~/Advisor/TasksList';
import { getAnalysts } from '@app/Api';
import { setAnalysts } from '@app/Store';
import { Tabs } from '~/Common/Tabs';

const AccreditedAdvisorHome = ({ user }) => {
    const [currentTab, setTabValue] = useState('companies');
    const [search, setSearch] = useState('');
    const mode = useSelector((state) => state.ui.mode);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleSearchChange = (val) => setSearch(val);

    const handleModeClick = (e, mode) => {
        e.preventDefault();
        dispatch(setTrainingMode(mode));
    };

    useEffect(() => {
        getAnalysts().then(({ data }) => dispatch(setAnalysts(data)));
    }, [dispatch]);

    return (
        <>
            <Flex
                sx={{
                    p: '20px',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 3,
                }}
            >
                <Tabs
                    label="Show me"
                    styleVariant="circle"
                    tabs={[
                        {
                            key: 'companies',
                            name: 'All Companies',
                            icon: 'grid',
                        },
                        { key: 'tasks', name: 'All Tasks', icon: 'list' },
                    ]}
                    selected={currentTab}
                    onChange={(tab) => setTabValue(tab.key)}
                    sx={{
                        mb: 0,
                    }}
                />
                {currentTab === 'companies' && (
                    <Flex flexWrap="wrap" className={styles.buttonGroup}>
                        <Button
                            type="button"
                            variant="tab"
                            className={mode === 'live' ? 'active' : ''}
                            onClick={(e) => handleModeClick(e, 'live')}
                        >
                            {t('Live')}
                        </Button>
                        <Button
                            type="button"
                            variant="tab"
                            className={mode === 'archived' ? 'active' : ''}
                            onClick={(e) => handleModeClick(e, 'archived')}
                        >
                            {t('Archive')}
                        </Button>

                        {isAnalyst(user) ? (
                            <Button
                                type="button"
                                variant="tab"
                                className={mode === 'training' ? 'active red' : ''}
                                onClick={(e) => handleModeClick(e, 'training')}
                            >
                                {t('Training')}
                            </Button>
                        ) : null}
                    </Flex>
                )}
            </Flex>

            {currentTab === 'companies' && <CompanyList initialSearch={search} onSearchChange={handleSearchChange} />}
            {currentTab === 'tasks' && <TasksList initialSearch={search} onSearchChange={handleSearchChange} />}
        </>
    );
};

export default connect((state) => ({ user: state.user }))(AccreditedAdvisorHome);
