import React from 'react';
import { Badge } from '../Badge';
import { actionItemStatuses } from '@util/Enum/ActionItemStatus';

export const ActionItemStatus = ({ status, isOverdue, ...props }) => {
    return (
        <Badge
            mr={2}
            variant={
                isOverdue ? 'error'
                    : status === 2 ? 'success'
                    : status === 3 ? 'purple'
                    : 'warning'
            }>
            {isOverdue ? 'Overdue' : actionItemStatuses[status]?.label}
        </Badge>
    )
};
