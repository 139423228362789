import React, { useState, useEffect } from 'react';
import { Flex, Box, Heading, Button } from 'rebass';
import { toast } from 'react-toastify';
import { currencySymbol, formatPercent, getErrorMessage, yearsList } from '@util/Functions';
import { PopupStageDetails } from '~/Dashboard/TaskView';
import LoadingSpinner from '~/LoadingSpinner';
import { useTranslation } from "react-i18next";
import styles from '../styles.module.scss';
import { BackButton } from '~/Common/BackButton';
import { getTaskStatus } from '@util/Enum';
import TaskStatusChange from '~/Dashboard/TaskStatusChange';
import { Input } from '@rebass/forms';
import CommentModule from '~/Common/CommentModule';
import { Modal } from '~/Common/Modal';
import { Radio, Label } from '@theme-ui/components';
import { getCompanyEffectiveTaxRates, updateCompanyEffectiveTaxRates } from '@app/Api';
import { taxRateTypes } from '@util/Enum';
import { connect } from 'react-redux';

const EffectiveTaskRateTaskView = ({ initTask, backLink, backLabel, companyId, companies }) => {
    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [task, setTask] = useState({});
    const [taskRateType, setTaxRateType] = useState(6);
    const [effectiveTaxRates, setEffectiveTaxRates] = useState({});
    const [errors, setErrors] = useState({});
    const [profit2017, setProfit2017] = useState('');
    const [permissions, setPermissions] = useState(false);

    const { sx: statusStyle, name: statusName } = getTaskStatus(task.status);
    const postTaskStatusUpdate = (status) => setTask({ ...task, ...{ status } });
    const { t } = useTranslation();

    // Each key represents the portion of profit to which the rate value applies
    const taxBrackets = [
        { value: 50000, rate: 15 },
        { value: 25000, rate: 25 },
        { value: 25000, rate: 34 },
        { value: 235000, rate: 39 },
        { value: 9665000, rate: 34 },
        { value: 5000000, rate: 35 },
        { value: 3333333, rate: 38 }
    ];

    useEffect(() => {
        setLoading(true);
        setTask(initTask);

        getCompanyEffectiveTaxRates(initTask.id)
            .then((rates) => {
                setEffectiveTaxRates(rates);
                setTaxRateType(rates.effective_tax_rate_type_id ? rates.effective_tax_rate_type_id : 6);
                setProfit2017(rates.profit_2017 ? rates.profit_2017 : '');
                setPermissions(rates?.permissions);
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to get effective tax rates')))
            .finally(() => setLoading(false));
    }, [initTask]);

    const yearsRange = yearsList(5, 1, true).reverse();

    const setCustomValue = (e, year) => {
        e.preventDefault();
        const newRates = { ...effectiveTaxRates };
        newRates.data[year] = e.target.value;
        setEffectiveTaxRates(newRates);
    }

    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true);
        updateCompanyEffectiveTaxRates(task.id, { rates: effectiveTaxRates.data, typeId: taskRateType, taskId: task.id, profit2017 })
            .then((rates) => {
                setErrors({});
                setEffectiveTaxRates(rates);
                setTaxRateType(rates.effective_tax_rate_type_id);
                setProfit2017(rates.profit_2017 ? rates.profit_2017 : '');
                setPermissions(rates?.permissions);
                postTaskStatusUpdate(rates.task.status);
                toast.success('Effective tax rates updated successfully');
            })
            .catch(({ response }) => {
                setErrors(response?.data?.errors);
                toast.error(getErrorMessage(response?.data, 'Unable to get effective tax rates'));
            })
            .finally(() => setLoading(false));
    }

    const federalRate2017 = () => {
        let total = 0;
        let value = profit2017;

        taxBrackets.some(bracket => {
            if (value - bracket.value > 0) {
                total += bracket.value * (bracket.rate / 100);
            } else {
                total += value * (bracket.rate / 100);
            }

            value -= bracket.value;

            return value <= 0;
        });

        // All profits over $18,333,333 are taxed at 35%
        if (profit2017 > 18333333) {
            total += (profit2017 - 18333333) * (35 / 100);
        }

        return profit2017 > 0 ? (total / profit2017) * 100 : '';
    }

    const isNum = num => typeof num === 'number';

    const getTaxRate = (type, year) => effectiveTaxRates.defaultRates.find(rate => rate.id === type)?.rates[year];

    const formatRate = rate => isNum(rate) ? formatPercent(rate) : <span>&mdash;</span>;

    const creditAgainstFederalRate2017 = () => {
        const difference = differenceRate2017();
        const calced = calcedCredit2017();

        return isNum(difference) && isNum(calced) ? difference - calced : '';
    }

    const calcedCredit2017 = () => {
        const fedRate = federalRate2017();
        const locRate = stateRate(2017);

        return isNum(fedRate) && isNum(locRate) ? ((fedRate * locRate) / 100) : '';
    }

    const effectiveTaxRate2017 = () => {
        const fedRate = federalRate2017();
        const credit = creditAgainstFederalRate2017();
        const locRate = stateRate(2017);

        return isNum(fedRate) && isNum(credit) && isNum(locRate) ? fedRate + credit + locRate : '';
    }

    const differenceRate2017 = () => getTaxRate(taxRateTypes.difference, 2017);

    const effectiveTaxRate = year => getTaxRate(taxRateTypes.effective, year);

    const federalRate = year => getTaxRate(taxRateTypes.federal, year);

    const stateRate = year => getTaxRate(taxRateTypes.state, year);

    const adjustedFederalRate = year => {
        const effective = effectiveTaxRate(year);
        const locRate = stateRate(year);

        return isNum(effective) && isNum(locRate) ? effective - locRate : '';
    }

    const creditAgainstFederalRate = year => {
        const adjustedFederal = adjustedFederalRate(year);
        const federal = federalRate(year);

        return isNum(adjustedFederal) && isNum(federal) ? adjustedFederal - federal : '';
    }

    const averageTaxRate = year => getTaxRate(taxRateTypes.average, year);

    const company = companyId ? companies.find(company => company.id === companyId) : null;

    if (loading) return <LoadingSpinner />;

    return (
        <Box mt={3}>
            <BackButton to={backLink} label={backLabel} pl={3} />

            <Modal isOpen={modalOpen}>
                <PopupStageDetails
                    triggerClose={() => setModalOpen(false)}
                    details={t(task.description)}
                    name={t(task.stageName)}
                />
            </Modal>

            <Flex width={1}>
                <Box width={1 / 3}>
                    <Box variant="card.wrapper" p={30}>
                        <Box
                            sx={{
                                letterSpacing: '0.5px',
                                textTransform: 'uppercase',
                                fontSize: '10px',
                                fontWeight: 500,
                                marginBottom: 2,
                            }}
                        >
                            {t(task.stageName)}
                        </Box>
                        <Box mb={3} fontSize={18} fontWeight={700}>
                            {t(task.name)}
                        </Box>
                        <Box mb={3}>
                            {permissions?.canUpdateStatus ?
                                <Flex alignItems="center">
                                    <Box mr={2}>Change Status</Box>
                                    <TaskStatusChange
                                        taskStatus={task.status}
                                        companyTaskId={task.id}
                                        notifyUpdated={postTaskStatusUpdate}
                                    />
                                </Flex> :
                                <Button variant="task" mb={3} width={100} p={2} sx={statusStyle}>
                                    {statusName}
                                </Button>}
                        </Box>
                        <Box
                            variant="clickable"
                            fontSize={12}
                            sx={{ color: '#6F7782' }}
                            onClick={() => setModalOpen(true)}
                        >
                            {t(task.description)}
                        </Box>
                    </Box>
                </Box>
                <Box width={2 / 3}>
                    <Box variant="card.wrapper">
                        <Flex variant="card.header" alignItems="center">
                            <Heading>
                                {t('Please choose Effective Corporate Tax Rates to be used for normalized accounts')}
                            </Heading>
                        </Flex>
                        <Box variant="card.container">
                            <Flex width={1} mb={3}>
                                <table className={styles.dataTableCenter}>
                                    <thead>
                                        <tr>
                                            <th colSpan="2"></th>
                                            {yearsRange.map(({ value }, yearIndex) => <th key={yearIndex}>{value}</th>)}
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{t('Net Profit before Tax')}</td>
                                            <td></td>
                                            {yearsRange.map(({ value }, yearIndex) =>
                                                value === 2017 ? <td key={yearIndex}>
                                                    <Input
                                                        value={profit2017}
                                                        placeholder={currencySymbol(company?.currency?.isoCode)}
                                                        onChange={(e) => setProfit2017(e.target.value)}
                                                        disabled={!permissions?.canUpdate}
                                                    />
                                                    {errors[`profit2017`] && (
                                                        <Box mt={-18} mb={20} color="red" fontWeight="normal" fontSize={12}>
                                                            {errors[`profit2017`]}
                                                        </Box>
                                                    )}</td> : <td key={yearIndex}></td>
                                            )}
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>{t('Country/Federal')}</td>
                                            <td>{t(`location.country.${effectiveTaxRates.country}`)}</td>
                                            {yearsRange.map(({ value }, yearIndex) =>
                                                value === 2017 ? <td key={yearIndex}>{formatRate(federalRate2017())}</td> :
                                                    <td key={yearIndex}>{formatRate(federalRate(value))}</td>
                                            )}
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>{t(`location.state.${effectiveTaxRates.country}`)}</td>
                                            <td>
                                                {effectiveTaxRates.state}
                                            </td>
                                            {yearsRange.map(({ value }, yearIndex) => (
                                                <td key={yearIndex}>{formatRate(stateRate(value))}</td>
                                            ))}
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>{t('Credit against Federal')}</td>
                                            <td></td>
                                            {yearsRange.map(({ value }, yearIndex) => (
                                                <td key={yearIndex}>{formatRate(value === 2017 ? creditAgainstFederalRate2017(value) : creditAgainstFederalRate(value))}</td>
                                            ))}
                                            <td></td>
                                        </tr>
                                        <tr className={styles.dataTableBold}>
                                            <td>{t('Location-based Tax Rate	')}</td>
                                            <td></td>
                                            {yearsRange.map(({ value }, yearIndex) => (
                                                <td key={yearIndex}>{formatRate(value === 2017 ? effectiveTaxRate2017(value) : effectiveTaxRate(value))}</td>
                                            ))}
                                            <td>
                                                <Label>
                                                    <Radio
                                                        disabled={!permissions?.canUpdate}
                                                        onChange={(e) => setTaxRateType(parseInt(e.target.value))}
                                                        name='effective-tax-rate'
                                                        value={taxRateTypes.effective}
                                                        defaultChecked={taskRateType === taxRateTypes.effective}
                                                    />
                                                    select
                                                </Label>
                                            </td>
                                        </tr>
                                        <tr className={styles.dataTableBold}>
                                            <td>{t('US Average Tax Rate')}</td>
                                            <td></td>
                                            {yearsRange.map(({ value }, yearIndex) => (
                                                <td key={yearIndex}>{formatRate(averageTaxRate(value))}</td>
                                            ))}
                                            <td>
                                                <Label>
                                                    <Radio
                                                        disabled={!permissions?.canUpdate}
                                                        onChange={(e) => setTaxRateType(parseInt(e.target.value))}
                                                        name='effective-tax-rate'
                                                        value={taxRateTypes.average}
                                                        defaultChecked={taskRateType === taxRateTypes.average}
                                                    />
                                                    select
                                                </Label>
                                            </td>
                                        </tr>
                                        <tr className={styles.dataTableBold}>
                                            <td>{t('Custom Tax Rate')}</td>
                                            <td></td>
                                            {yearsRange.map(({ value }, yearIndex) => (
                                                <td key={yearIndex}>
                                                    <Input
                                                        placeholder="%"
                                                        disabled={taskRateType !== taxRateTypes.custom || !permissions?.canUpdate}
                                                        onChange={e => setCustomValue(e, value)}
                                                        value={effectiveTaxRates.data[value] ? effectiveTaxRates.data[value] : ""}
                                                    />
                                                    {errors[`rates.${value}`] && (
                                                        <Box mt={-18} mb={20} color="red" fontWeight="normal" fontSize={12}>
                                                            {errors[`rates.${value}`][0]}
                                                        </Box>
                                                    )}
                                                </td>
                                            ))}
                                            <td>
                                                <Label>
                                                    <Radio
                                                        disabled={!permissions?.canUpdate}
                                                        onChange={(e) => setTaxRateType(parseInt(e.target.value))}
                                                        name='effective-tax-rate'
                                                        value={taxRateTypes.custom}
                                                        defaultChecked={taskRateType === taxRateTypes.custom}
                                                    />
                                                    select
                                                </Label>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Flex>

                            {permissions?.canUpdate && <Flex mb={3}>
                                <Button ml="auto" onClick={e => handleSubmit(e)}>
                                    Submit
                                </Button>
                            </Flex>}

                            {effectiveTaxRates.id && <CommentModule type='effective-tax-rate' id={effectiveTaxRates.id} />}
                        </Box>
                    </Box>
                </Box>
            </Flex>
        </Box>
    );
};

export default connect((state) => ({ companies: state.companies }))(EffectiveTaskRateTaskView);
