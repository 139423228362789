import { Icon } from '@svg'
import React from 'react'
import { Box, Flex } from 'rebass'
import { TextField } from '~/Form'
import { useTranslation } from "react-i18next"

const ScorecardSpecialField = ({ field, value, onChange, disabled, name, options }) => {
    const { t } = useTranslation()

    if (field.type === 'calculated') return null

    if (field.type === 'text') return <TextField value={value} onChange={(val) => onChange(val)} disabled={disabled} />

    if (field.type === 'yesNoRadios') {
        if (!field.options?.length)
            return (
                <Box pb={3} color='red'>
                    No radios options
                </Box>
            )

        const setValue = (val, key) => {
            const newValue = value ? [...value] : new Array(field.options.length).fill(null)
            newValue[key] = val
            let result = 0
            if (field.weight?.length) {
                field.weight.forEach((weight, index) => (result += weight && newValue[index] === 'yes' ? weight : 0))
            }
            onChange(newValue, result)
        }

        return (
            <Box mb={4} mt={3}>
                {field.options.length &&
                    field.options.map((option, key) => (
                        <Flex key={key} mb={3} fontWeight='600' fontSize={0}>
                            <Box width={3 / 7}>{option}</Box>
                            <Box width={1 / 2}>
                                <Flex>
                                    <Flex onClick={() => !disabled && setValue('yes', key)} variant='clickable' mr={2}>
                                        {(value?.length && value[key] === 'yes' && <Icon icon="radiosOn" />) || <Icon icon="radiosOff" />}
                                        <Box ml={1}>Yes</Box>
                                    </Flex>
                                    <Flex onClick={() => !disabled && setValue('no', key)} variant='clickable'>
                                        {(value?.length && value[key] === 'no' && <Icon icon="radiosOn" />) || <Icon icon="radiosOff" />}
                                        <Box ml={1}>No</Box>
                                    </Flex>
                                </Flex>
                            </Box>
                        </Flex>
                    ))}
            </Box>
        )
    }

    if (field.type === 'automated') {
        const rate = options?.find(option => parseInt(option?.value) === parseInt(value))?.label
        return <Box mb={3} sx={{ fontWeight: 'bold' }}>{rate ? rate : t(`${name ? name : 'This field'} is automatically calculated.`)}</Box>;
    }

    return (
        <Box pb={3} color='red'>
            Unknown special field
        </Box>
    )
}

export default ScorecardSpecialField
