import { Icon } from '@svg';
import {
    getReport,
    updateReport,
    submitReport,
    previewReport,
    createReportPage,
    approveReport,
    reworkReport,
} from '@app/Api';
import {
    getErrorMessage,
    updateUserStore,
    permit,
    downloadDraftReportFile,
    ReportCreated,
    ReportChiefAnalystReview,
    ReportAdviserReview,
    isAnalyst,
    ReportSubmitted,
    isAdvisor,
} from '@util/Functions';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Button, Flex, Heading } from 'rebass';
import LoadingSpinner from '~/LoadingSpinner';
import styles from './styles.module.scss';
import { ReactSortable } from 'react-sortablejs';
import { Switch } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PDFViewer from '~/Dashboard/PDFViewer';
import { Modal } from '~/Common/Modal';
import { TextField, WysiwygField } from '~/Form';
import { BackButton } from '~/Common/BackButton';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CommentModule from '~/Common/CommentModule';
import { previewReport as previewReportV202107 } from '@app/Api/V202107';
import { CompanyContextData } from 'Context/Company';
import { addAdvisorNotes } from '@app/Api/Reports';
import { getBIRFilename } from '@util/Functions/Company';
import { Badge } from '~/Common/Badge';


const AdvisorReportEdit = ({ reportId, user }) => {
    const history = useHistory();
    const [report, setReport] = useState({});
    const [permissions, setPermissions] = useState({});
    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [downloading, setDownloading] = useState(false);
    const [blob, setBlob] = useState(null);
    const [modalPage, setModalPage] = useState(null);
    const [errors, setErrors] = useState({});
    const [valuationFinalised, setValuationFinalised] = useState(false);
    const { t } = useTranslation();
    const company = useContext(CompanyContextData);

    useEffect(() => {
        setLoading(true);
        getReport({ reportId })
            .then(({ report, pages, permissions, types, valuationFinalised }) => {
                if (report.publishedOn) {
                    toast.error('This report has already been published');
                    history.push('/advisor/home');
                }
                setPages(pages);
                setReport(report);
                setPermissions(permissions);
                setValuationFinalised(valuationFinalised)
                setLoading(false);
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to load report details')));
    }, [reportId, history]);

    if (loading) return <LoadingSpinner />;

    const handleUpdate = (report) => {
        updateReport(report).catch(({ response }) =>
            toast.error(getErrorMessage(response?.data, 'Unable to update report'))
        );
    };

    const toggleVisible = (index) => {
        const newPages = [...pages];
        newPages[index].visible = !newPages[index].visible ? 1 : 0;
        setPages(newPages);
        updateCleanPages();
    };

    const updateCleanPages = () => {
        const cleanPages = pages.map(({ selected, chosen, ...rest }) => rest);
        handleUpdate({ reportId, pages: cleanPages });
    };

    const getReportPreview = () => {
        setDownloading(true);
        setBlob(null);
        switch (company?.versions?.valuations) {
            case 'V202107':
                previewReportV202107(reportId)
                    .then((file) => setBlob(file))
                    .catch(() => toast.error('There was an error while trying to download the report preview'))
                    .finally(() => setDownloading(false));
                break;
            default:
                previewReport(reportId)
                    .then((file) => setBlob(file))
                    .catch(() => toast.error('There was an error while trying to download the report preview'))
                    .finally(() => setDownloading(false));
                break;
        }
    };

    const saveReport = () => {
        setDownloading(true);
        submitReport({ reportId })
            .then(() => {
                updateUserStore()
                    .then(() => {
                        toast.success('Report generated successfully');
                        history.push(`/advisor/company/${report.companyId}/reports`);
                    })
                    .catch(() => toast.error('Unable to get user store.'));
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to load report details')))
            .finally(() => setDownloading(false));
    };

    const editPageContent = (pageIndex) => {
        const selectedPage = pages[pageIndex];
        const modalPage = {
            pageIndex,
            content: selectedPage.content,
            name: selectedPage.name,
        };
        setModalPage(modalPage);
    };

    const updatePageContent = (value) => {
        const newModalPage = { ...modalPage };
        newModalPage.content = value;
        setModalPage(newModalPage);
    };

    const updatePage = () => {
        const newPages = [...pages];
        newPages[modalPage.pageIndex].content = modalPage.content;
        newPages[modalPage.pageIndex].name = modalPage.name;
        setPages(newPages);
        updateCleanPages();
        setModalPage(null);
    };

    const createNewContentPage = () => createNewPage(false);

    const createNewBreakPage = () => createNewPage(true);

    const createNewPage = (isBreakPage) => {
        createReportPage({ reportId, isBreakPage })
            .then(({ report, pages }) => {
                setPages(pages);
                setReport(report);
                if (!isBreakPage && pages[0]) {
                    const modalPage = {
                        pageIndex: 0,
                        content: pages[0].content,
                        name: pages[0].name,
                    };
                    setModalPage(modalPage);
                }
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to create report page')));
    };

    const approvalMessage = () => {
        switch (report.status) {
            case ReportCreated:
            case ReportChiefAnalystReview:
                return 'Send to Adviser';

            case ReportSubmitted:
                return 'Finalise Report';

            case ReportAdviserReview:
                return 'Approve Report';

            default:
                return 'Submit Report';
        }
    };

    const approve = () => {
        if (report.status === ReportAdviserReview) {
            saveReport();
        } else {
            setLoading(true);
            approveReport({ reportId })
                .then(({ report, pages, permissions }) => {
                    if (report.publishedOn) {
                        toast.error('This report has already been published');
                        history.push('/advisor/home');
                    }
                    setPages(pages);
                    setReport(report);
                    setPermissions(permissions);
                    toast.success('Report submitted for review');
                    setLoading(false);
                })
                .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to load report details')));
        }
    };

    const handleDownloadDraft = () => {
        setDownloading(true);
        downloadDraftReportFile(
            { reportId, fileName: `DRAFT ${getBIRFilename(company, report.year)}` },
            company?.versions?.reports
        ).finally(() => setDownloading(false));
    };

    const handleDownloadReview = () => {
        setDownloading(true);
        downloadDraftReportFile(
            { reportId, fileName: `REVIEW${company && ` ${company.name}`} Business Insights Report.pdf` },
            company?.versions?.reports,
            'REVIEW ONLY'
        ).finally(() => setDownloading(false));
    };

    const rework = () => {
        setDownloading(true);
        reworkReport({ reportId })
            .then(({ report, permissions }) => {
                if (report.publishedOn) {
                    toast.error('This report has already been published');
                    history.push('/advisor/home');
                }
                setReport(report);
                setPermissions(permissions);
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to load report details')))
            .finally(() => setDownloading(false));
    };

    const submitAdvisorNotes = content => {
        setErrors({});
        addAdvisorNotes(report.id, content)
            .then(({ report, permissions, pages }) => {
                setModalPage(null);
                setReport(report);
                setPermissions(permissions);
                setPages(pages);
            })
            .catch(({ response }) => setErrors(response?.data?.errors))
    };

    return (
        <Box mt={4}>
            <BackButton to={`/advisor/company/${report.companyId}/reports`} label="Back to Reports" />
            <Flex
                sx={{
                    flexDirection: 'column',
                }}
            >
                <Box>
                    <Box variant="card.wrapper" p={30}>
                        <Box className={styles.headerLabel}>New Report</Box>
                        <Box mb={3} fontSize={18} fontWeight={700}>
                            {t('reportsEdit.title')}
                        </Box>
                        <Box mb={3} fontSize={12}>
                            {t('reportsEdit.subtitle')}
                        </Box>
                        {!downloading && <>
                            <Flex sx={{ gap: 2, alignItems: 'flex-end' }}>
                                {blob ? <Button
                                    variant="secondary"
                                    className={styles.sidebarButtons}
                                    onClick={() => setBlob(null)}
                                >
                                    Close Report
                                </Button> :
                                    <Button
                                        variant="secondary"
                                        className={styles.sidebarButtons}
                                        onClick={() => getReportPreview()}
                                    >
                                        Preview Report
                                    </Button>
                                }

                                {permit(permissions, 'reports.addAdvisorNotes') && (
                                    <Button
                                        variant="secondary"
                                        className={styles.sidebarButtons}
                                        onClick={() => setModalPage({
                                            content: report?.advisor_notes,
                                            heading: 'Add notes',
                                            hideTitle: true,
                                            onSubmit: submitAdvisorNotes
                                        })}
                                    >
                                        Add Notes
                                    </Button>
                                )}

                                {report.status === ReportCreated && isAnalyst(user) && (
                                    <Button
                                        variant="secondary"
                                        className={styles.sidebarButtons}
                                        onClick={() => handleDownloadDraft()}
                                    >
                                        Download Draft
                                    </Button>
                                )}

                                {report.status === ReportAdviserReview && isAdvisor(user) && (
                                    <Button
                                        variant="secondary"
                                        className={styles.sidebarButtons}
                                        onClick={() => handleDownloadReview()}
                                    >
                                        Download Review
                                    </Button>
                                )}

                                {permit(permissions, 'reports.approve') && (
                                    <Button
                                        variant="primary"
                                        className={styles.sidebarButtons}
                                        onClick={() => approve()}
                                    >
                                        {approvalMessage()}
                                    </Button>
                                )}

                                {permit(permissions, 'reports.rework') && (
                                    <Button
                                        variant="primary"
                                        className={styles.sidebarButtons}
                                        onClick={() => rework()}
                                    >
                                        Send to Rework
                                    </Button>
                                )}
                            </Flex>
                            {isAnalyst(user) && !valuationFinalised && <Badge variant={'error'} mt={3}>
                                {t('reports.valuation')}
                            </Badge>}
                        </>}
                        {downloading && <LoadingSpinner size={30} />}
                    </Box>
                </Box>
                <Box>
                    {blob && <PDFViewer file={blob} close={() => setBlob(null)} />}
                    {!blob && permit(permissions, 'reports.edit') && (
                        <>
                            <Flex
                                variant="card.wrapper"
                                py={2}
                                px={3}
                                sx={{ borderRadius: 10, color: '#19CEFF' }}
                                alignItems="center"
                            >
                                <Flex
                                    sx={{ cursor: 'pointer', alignItems: 'center' }}
                                    onClick={createNewContentPage}
                                    m="auto"
                                >
                                    <Box ml="auto">Create new page</Box>
                                    <Box mr="auto" ml={2} pt="2px">
                                        <Icon icon="addCircle" stroke="#19CEFF" title="Add New Page" size={18} mt="2" />
                                    </Box>
                                </Flex>
                                |
                                <Flex
                                    sx={{ cursor: 'pointer', alignItems: 'center' }}
                                    onClick={createNewBreakPage}
                                    m="auto"
                                >
                                    <Box ml="auto">Add page break</Box>
                                    <Box mr="auto" ml={2} pt="2px">
                                        <Icon
                                            icon="addCircle"
                                            stroke="#19CEFF"
                                            title="Add Break Page"
                                            size={18}
                                            mt="2"
                                        />
                                    </Box>
                                </Flex>
                            </Flex>
                            <ReactSortable
                                list={pages}
                                setList={setPages}
                                handle=".draggableHandle"
                                onSort={() => updateCleanPages()}
                            >
                                {pages?.map(({ id, name, template, visible }, index) => (
                                    <Flex
                                        variant="card.wrapper"
                                        py={2}
                                        px={3}
                                        sx={{ borderRadius: 10 }}
                                        key={index}
                                        alignItems="center"
                                    >
                                        <Box className="draggableHandle" sx={{ cursor: 'move', paddingTop: '4px' }}>
                                            <Icon icon="drag" title="Drag Page" />
                                        </Box>
                                        <Box ml={2}>{name}</Box>
                                        {template === 'page-break' && (
                                            <Box
                                                style={{
                                                    background: '#656D78',
                                                    height: '1px',
                                                    flex: '1',
                                                    margin: '0 50px',
                                                }}
                                            />
                                        )}
                                        <Flex ml="auto" textAlign="right">
                                            {!template && (
                                                <Flex
                                                    color="#19CEFF"
                                                    sx={{ cursor: 'pointer' }}
                                                    onClick={() => editPageContent(index)}
                                                    mt={'7px'}
                                                >
                                                    <Box mr="2px" mt="2px">
                                                        Edit
                                                    </Box>
                                                    <Icon icon="edit" stroke="#19CEFF" />
                                                </Flex>
                                            )}
                                            <Switch
                                                value={visible}
                                                checked={!!visible}
                                                onChange={() => toggleVisible(index)}
                                            />
                                        </Flex>
                                    </Flex>
                                ))}
                            </ReactSortable>
                        </>
                    )}
                    {permit(user.permissions, 'reports.comment') && (
                        <Box variant="card.wrapper">
                            <Box variant="card.container">
                                <CommentModule type="report" id={report.id} />
                            </Box>
                        </Box>
                    )}
                </Box>

                {modalPage && (
                    <Modal isOpen={true}>
                        <Box>
                            <Flex>
                                <Heading pb={4}>{modalPage?.heading ? modalPage.heading : `Edit Page ${modalPage.name}`}</Heading>
                                <Box ml="auto">
                                    <Box variant="clickable" p={20} m="auto" onClick={() => setModalPage(null)} pt={2}>
                                        <Icon icon="close" stroke="#656D78" title="Close Popup" />
                                    </Box>
                                </Box>
                            </Flex>
                            {!modalPage?.hideTitle && <TextField
                                value={modalPage.name}
                                onChange={(value) => setModalPage({ ...modalPage, ...{ name: value } })}
                                label="Page Title"
                                placeholder="New Report Page"
                            />}
                            <WysiwygField
                                value={modalPage.content}
                                setValue={updatePageContent}
                                fullEdit={true}
                                companyId={report.companyId}
                                extraClasses="extra-large-editor"
                            />
                            {errors?.notes && errors.notes.map((error, i) => <Box mt="2px" mb={20} color="red" fontSize={12} key={`notesError${i}`}>
                                {error}
                            </Box>)}
                            <Box textAlign="right" mt={2}>
                                <Button variant="secondary" onClick={() => setModalPage(null)} mr="2">
                                    Cancel
                                </Button>
                                <Button variant="primary" onClick={modalPage?.onSubmit ? () => modalPage.onSubmit(modalPage.content) : updatePage}>
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                )}
            </Flex>
        </Box>
    );
};

export default connect((state) => ({ user: state.user }))(AdvisorReportEdit);
