import React, { useState, useEffect, useMemo } from 'react';
import { Box, Flex, Heading } from 'rebass';
import { Icon } from '@svg';
import NavigationBar from '../NavigationBar';
import Header from '../Header';
import Notifications from '../Notifications';
import { connect, useSelector } from 'react-redux';
import { getUnreadNotificationCount, getNotifications, setNotificationAsSeen } from '@app/Api';
import { setUnreadNotificationsCount, setNotifications, setNotificationsLastUpdate, setClosedMenu, readNotification, setCompany, subtractUnreadNotificationCount } from '@app/Store';
import styles from './styles.module.scss';
import { Sidebar } from '@section/Sidebar';
import { downloadReportFile, isAdvisor, isCompany, isOnboarded, isConsultant, getBIRFilename } from '@util/Functions';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDashboard } from '@hooks/useDashboard';
import { BackButton } from '~/Common/BackButton';
import ActionItems from '@section/ActionItems';
import CreateActionItemModal from '~/Modals/CreateActionItemModal';
import WhatsNew from '@section/WhatsNew';
import { Skeleton } from '~/Common/Skeleton';

const Dashboard = ({
    setClosedMenu,
    closedMenu,
    notifications,
    Component,
    props,
    readNotification,
    setNotifications,
    setUnreadNotificationsCount,
    setNotificationsLastUpdate,
    title: _title,
    icon,
    iconSize,
    childSx,
    showBack,
    backTo,
    backLabel,
    setCompany,
    company,
}) => {
    const history = useHistory();
    const [selectedItem, setSelectedItem] = useState(null);
    const [showCreate, setShowCreate] = useState(false);
    const [showActionItems, setShowActionItems] = useState(false);
    const [showWhatsNew, setShowWhatsNew] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const [showMenu, setShowMenu] = useState(!closedMenu);
    const user = useSelector((state) => state.user);
    const sidebarOpen = useSelector((state) => state.ui.sidebarOpen);
    const companies = useSelector((state) => state.companies);
    const companyId = props?.companyId ?? user?.companyId;
    const { t } = useTranslation();
    const dashboardData = useDashboard();
    const title = dashboardData.title ?? _title;

    useEffect(() => {
        if (Array.isArray(companies) && companyId) {
            setCompany(companies.find((company) => company.id === parseInt(companyId)) ?? null);
        }
    }, [companyId, companies, setCompany]);

    useEffect(() => {
        const timestamp = new Date().getTime();
        if (!notifications.lastUpdate || notifications.lastUpdate < timestamp) {
            // Wait 50 seconds for next notification update
            setNotificationsLastUpdate(timestamp + 50000);
            getNotifications().then((data) => setNotifications(data));
            getUnreadNotificationCount().then((count) => setUnreadNotificationsCount(count));
        }
    }, [setUnreadNotificationsCount, setNotificationsLastUpdate, notifications.lastUpdate, setNotifications]);

    const toggleMenu = () => {
        setClosedMenu(showMenu);
        setShowMenu(!showMenu);
    };

    const markNotificationIdAsSeen = (id, reportId, valuationId, seen, companyName, reportYear) => {
        setNotificationAsSeen(id).then(() => {
            readNotification(id);

            if (!seen) {
                subtractUnreadNotificationCount(1);
            }
            if (reportId) downloadReportFile({ reportId, fileName: getBIRFilename(companyName, reportYear) });
            if (valuationId) history.push(`/company/valuations/${valuationId}`);
        });
    };

    const sidebarLinks = useMemo(() => {
        const todo = company?.todo;

        if (isAdvisor(user)) {
            return [
                { title: 'Client Dashboard', icon: 'computer', link: `/advisor/company/${companyId}/overview` },
                { title: 'Dynamic Revaluation', icon: 'chart', link: `/advisor/company/${companyId}/revaluation` },
                {
                    title: 'Reporting',
                    icon: 'stats',
                    subLinks: [
                        {
                            title: 'Onboarding',
                            link: `/advisor/company/${companyId}/onboarding`,
                            todo: todo?.onboarding >= 1 ? 'completed' : 'In Progress',
                        },
                        {
                            title: 'Reports',
                            link: `/advisor/company/${companyId}/reports`,
                            todo: company?.reportYears,
                        },
                    ],
                },
                { title: 'Projects', icon: 'paper', link: `/advisor/company/${companyId}/stages` },
                {
                    title: t('actionItems.title'),
                    icon: 'checklist',
                    link: `/advisor/company/${companyId}/action-items`,
                },
                {
                    title: t('ibisWorld.menuItem'),
                    icon: 'chartGrowth',
                    link: `/advisor/company/${companyId}/ibis-world`,
                },
            ];
        } else if (isConsultant(user)) {
            if (isOnboarded(company)) {
                return [
                    { title: 'Dashboard', icon: 'computer', link: `/company/dashboard/${companyId}` },
                    { title: 'Projects', icon: 'roundCheck', link: `/company/projects/${companyId}` },
                    { title: t('actionItems.title'), icon: 'checklist', link: `/company/action-items/${companyId}` },
                    { title: 'Financials', icon: 'stats', link: `/company/financials/${companyId}` },
                    { title: 'Reports', icon: 'reports', link: `/company/reports/${companyId}` },
                    { title: 'Settings', icon: 'gear', link: `/company/profile/settings/${companyId}` },
                ]
            } else {
                return [
                    { title: 'Dashboard', icon: 'computer', link: `/company/dashboard/${companyId}` },
                    { title: 'Onboarding', icon: 'roundCheck', link: `/company/onboarding/${companyId}` },
                    { title: 'Settings', icon: 'gear', link: `/company/profile/settings/${companyId}` },
                ];
            }
        } else {
            return [
                { title: 'Client Dashboard', icon: 'computer', link: `/advisor/company/${companyId}/overview` },
                { title: 'Dynamic Revaluation', icon: 'chart', link: `/advisor/company/${companyId}/revaluation` },
                {
                    title: 'Reporting',
                    icon: 'stats',
                    subLinks: [
                        {
                            title: 'Onboarding',
                            link: `/advisor/company/${companyId}/onboarding`,
                            todo: todo?.onboarding >= 1 ? 'completed' : 'In Progress',
                        },
                        {
                            title: t('normalisation.title'),
                            link: `/advisor/company/${companyId}/normalisation`,
                            todo: todo?.normalisation >= 2 ? 'completed' : `${todo?.normalisation || 0} of 2`,
                            subActiveLinks: [
                                `/advisor/company/${companyId}/financial-imports`,
                                `/advisor/company/${companyId}/addbacks`,
                                //`/advisor/company/${companyId}/benchmarking`
                            ],
                        },
                        {
                            title: 'Financial History',
                            link: `/advisor/company/${companyId}/financial-reports`,
                            todo: todo?.financials >= 4 ? 'completed' : `${todo?.financials} of 4`,
                        },
                        {
                            title: 'Custom Variables',
                            link: `/advisor/company/${companyId}/custom-variables`,
                            todo: todo?.customVariables >= 1 ? 'completed' : `${todo?.customVariables} of 1`,
                        },
                        {
                            title: 'Scorecards',
                            link: `/advisor/company/${companyId}/scorecards`,
                            todo: todo?.scorecards,
                        },
                        {
                            title: 'Valuations',
                            link: `/advisor/company/${companyId}/valuations`,
                            todo: todo?.valuations >= 1 ? 'completed' : `${todo?.valuations} of 1`,
                        },
                        {
                            title: 'Reports',
                            link: `/advisor/company/${companyId}/reports`,
                            todo: company?.reportYears,
                        },
                    ],
                },
                { title: 'Projects', icon: 'paper', link: `/advisor/company/${companyId}/stages` },
                {
                    title: t('actionItems.title'),
                    icon: 'checklist',
                    link: `/advisor/company/${companyId}/action-items`,
                },
                {
                    title: t('ibisWorld.menuItem'),
                    icon: 'chart',
                    link: `/advisor/company/${companyId}/ibis-world`,
                },
            ];
        }
    }, [company, companyId, user, t]);

    const isCompanyPage = props?.companyId;

    const calcPagePadding = () => {
        if (sidebarOpen && isCompanyPage) {
            return '290px';
        } else if (isCompanyPage) {
            return '48px';
        }

        return 0;
    };

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <Box className={styles.sideBar} width={showMenu ? 285 : 68}>
                <NavigationBar setShowMenu={toggleMenu} showMenu={showMenu} />
            </Box>

            {props?.companyId && (
                <Sidebar showMenu={showMenu} links={sidebarLinks} user={user} companyId={props.companyId} />
            )}

            <Box flex={1} ml={showMenu ? 285 : 68} pl={calcPagePadding()} sx={{ transition: 'all 0.2s ease' }}>
                <Header
                    toggleNotifications={() => setShowNotifications((val) => !val)}
                    toggleShowActionItems={() => setShowActionItems((val) => !val)}
                    toggleWhatsNew={() => setShowWhatsNew((val) => !val)}
                    newNotificationsCount={notifications.newCount}
                >
                    {user ? (
                        <>
                            {title && (
                                <Flex alignItems="center" sx={{ color: 'blueDark' }}>
                                    {icon && <Icon icon={icon} width={iconSize} variant="headerIcon" />}
                                    {title && (
                                        <Heading type="h4" ml={icon ? '14px' : null}>
                                            {t(title)}
                                        </Heading>
                                    )}
                                </Flex>
                            )}
                        </>
                    ) : <Skeleton height="24px" width="100px" />}
                </Header>
                <Box id="headerPortal" />
                <Box sx={{ px: '10px', pb: '50px', pt: 4, minHeight: 'calc(100vh - 110px)', ...childSx }}>
                    {showBack && <BackButton to={backTo ?? '/'} label={backLabel ?? 'Back to Dashboard'} mt={4} />}
                    <Component {...props} />
                </Box>
            </Box>

            {showNotifications && (
                <Notifications
                    requestClose={() => setShowNotifications(false)}
                    notifications={notifications}
                    markNotificationIdAsSeen={markNotificationIdAsSeen}
                />
            )}
            {user?.permissions?.actionItems?.view && (
                <>
                    {showActionItems && (
                        <ActionItems
                            companyId={props?.companyId ?? (isCompany(user) ? user?.companyId : null)}
                            onCreateItem={() => {
                                setSelectedItem(null);
                                setShowCreate(true);
                                setShowActionItems(false);
                            }}
                            onEditItem={(item) => {
                                setSelectedItem(item);
                                setShowCreate(true);
                                setShowActionItems(false);
                            }}
                            requestClose={() => setShowActionItems(false)}
                        />
                    )}
                    <CreateActionItemModal
                        isOpen={showCreate}
                        selectedItem={selectedItem}
                        requestClose={() => setShowCreate(false)}
                    />
                </>
            )}
            <WhatsNew isOpen={showWhatsNew} requestClose={() => setShowWhatsNew(false)} />
        </Box>
    );
};

export default connect(
    (state) => ({ notifications: state.notifications, closedMenu: state.user?.closedMenu, company: state.company }),
    {
        readNotification,
        setUnreadNotificationsCount,
        setNotifications,
        setNotificationsLastUpdate,
        setClosedMenu,
        setCompany
    }
)(Dashboard);
