import React from 'react';
import { Text, Link, Button as ButtonRebass } from 'rebass';
import { Badge } from '~/Common/Badge';
import { getTaskStatus } from '@util/Enum/TaskStatus';
import { Link as RouterLink } from 'react-router-dom';
import { defaultCompanyLogoLink } from '@util/Functions';

export const tasksListColumns = [
    {
        key: 'task',
        title: 'Task name',
        sx: {
            minWidth: '340px',
        },
        render: (row) => (
            <Link
                as={RouterLink}
                to={`/advisor/company/${row.companyId}/stages/task/${row.id}`}
                sx={{ fontWeight: 400 }}
            >
                {row.name}
            </Link>
        ),
    },
    {
        key: 'status',
        title: 'Task status',
        render: (row) => {
            var value = getTaskStatus(row.status, row.overdue ? row.daysOverdue : false);

            return <Badge sx={value.sx}>{value.name}</Badge>;
        },
    },
    {
        key: 'company',
        title: 'Company',
        width: '240px',
        render: (row) => (
            <ButtonRebass
                variant="transparent"
                as={RouterLink}
                to={`/advisor/company/${row.companyId}/overview`}
                sx={{ display: 'flex', height: '34px', alignItems: 'center', p: '5px 10px', m: '-5px -10px' }}
            >
                <img
                    src={row?.companyLogo || defaultCompanyLogoLink}
                    alt="Company"
                    onError={(e) => (e.target.src = defaultCompanyLogoLink)}
                    style={{
                        width: '24px',
                        marginRight: '6px',
                    }}
                />
                <Text
                    sx={{
                        fontWeight: 400,
                        fontSize: '12px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {row.companyName}
                </Text>
            </ButtonRebass>
        ),
    },
    {
        key: 'category',
        title: 'Task Category',
        width: '300px',
        render: (row) => {
            const category = row.category.split('|');

            return (
                <Link
                    as={RouterLink}
                    to={{
                        pathname: `/advisor/company/${row.companyId}/stages`,
                        state: { stageId: row.stageId },
                    }}
                    sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}
                >
                    <Text sx={{ fontSize: '12px', color: '#19CEFF', flexShrink: 0 }}>{category[0]}</Text>
                    <Text sx={{ color: '#D1F5FF', mx: '4px' }}>{'|'}</Text>
                    <Text sx={{ fontSize: '12px', color: '#19CEFF', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {category[1]}
                    </Text>
                </Link>
            );
        },
    },
    {
        key: 'accessed',
        title: 'Last Accessed',
        render: (row) => (
            <Text
                sx={{
                    fontSize: '10px',
                    color: '#6F7782',
                }}
            >
                {row.lastAccessed ?? '-'}
            </Text>
        ),
    },
];
