import React from "react"
import { Icon } from "@svg/Icon"
import { Flex } from "rebass"

export const ExpandIcon = ({ expanded, sx, ...props }) => {
    return (
        <Flex sx={{
            alignItems: 'center',
            justifyContent: 'center',
            bg: expanded ? '#D1F5FF' : '#F5F7F8',
            borderRadius: '4px',
            width: '36px',
            height: '36px',
            minWidth: '36px',
            minHeight: '36px',
            transform: 'rotate(90deg)',
            ...sx,
        }} {...props}>
            <Icon size="32px" icon="arrowUp" color="#2C2960" title="Expand" className={`animate-transform ${expanded ? undefined : 'arrow--expanded-90'}`} />
        </Flex>
    )
}
