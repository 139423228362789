export const tasks = {
    generalInformation: 63,
    nonFinancial: 64,
    exitReadiness: 71,
    betaFactor: 73,
    benchmarking: 45,
    addbacks: 49,
    effectiveTaxRate: 74,
    foundation: 75,
    strategicGrowth: 76,
    esg: 96,
}