import React, { useEffect, useState } from "react";
import { Box } from "rebass";
import { toast } from "react-toastify";
import LoadingSpinner from "~/LoadingSpinner";
import { getErrorMessage } from "@util/Functions";
import { PageTitle } from "~/Common/PageTitle";
import DynamicRevaluationChart from "~/Dashboard/DynamicRevaluationChart";
import { useTranslation } from "react-i18next";
import { Card } from "~/Common/Card";
import { getCompanyDynamicRevaluation } from "@app/Api/Advisor";
import { Grid } from "theme-ui";
import { StepOutcomes } from "~/Advisor/StepOutcomes";
import { ValuationTabs } from "~/Advisor/ValuationTabs";
import { DataCard } from "~/Common/DataCard";
import theme from "@app/theme";

const DynamicRevaluation = ({ companyId }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [graphData, setGraphData] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        if (companyId) {
            setLoading(true);
            getCompanyDynamicRevaluation(companyId)
                .then(({ data }) => {
                    setData(data);
                    setGraphData(data?.dynamicRevaluation?.graph)
                    setLoading(false);
                })
                .catch(({ response }) =>
                    toast.error(
                        getErrorMessage(
                            response?.data,
                            "Unable to load company dynamic revaluation data"
                        ),
                        { toastId: "getCompanyDynamicRevaluationError" }
                    )
                );
        }
    }, [companyId]);

    if (loading || data === null) return <LoadingSpinner />;

    return (
        <>
            <Box px={3}>
                <PageTitle subtitle={t("companyDR.subtitle")} />
            </Box>
            <Grid
                columns="auto 380px"
                gap={0}
                sx={{ pb: "32px", alignItems: "start" }}
            >
                <Card
                    sx={{ bg: "#fff", marginBottom: 0 }}
                    groupSx={{
                        height: "100%",
                    }}
                >
                    <DynamicRevaluationChart
                        data={graphData}
                    />
                </Card>
                <ValuationTabs companyId={companyId} data={data} afterUpdate={data => setGraphData(data)} />
            </Grid>
            {
                Array.isArray(data?.variables?.industry) && data.variables.industry.length &&
                <Card
                    title={t("Industry specific variables")}
                    grid={true}
                    columns={2}
                    sx={{ flex: "100%" }}
                    titleSx={{ px: '24px' }}
                >
                    {data.variables.industry.map((variable, index) => {
                        const titleSx = {
                            fontSize: '16px !important',
                            fontWeight: 600
                        }

                        return variable?.label === 'Outlook Analysis' ?
                            <Box
                                sx={{ gridColumn: '1 / span 2' }}
                                key={index}
                            >
                                <Box as={'h3'} sx={titleSx}>
                                    {t(variable?.label)}
                                </Box>
                                <Box
                                    dangerouslySetInnerHTML={{ __html: variable?.value }}
                                    sx={{
                                        fontSize: '12px',
                                        color: theme.colors.captionGray
                                    }}
                                />
                            </Box> :
                            <DataCard
                                key={index}
                                title={t(variable?.label)}
                                caption={t(variable?.caption)}
                                titleSx={titleSx}
                            >
                                <Box
                                    as={'p'}
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: '15px',
                                        lineHeight: 1.4,
                                        span: {
                                            fontWeight: 600
                                        }
                                    }}
                                    dangerouslySetInnerHTML={{ __html: variable?.value }}
                                />
                            </DataCard>
                    })}
                </Card>
            }
            {
                Array.isArray(data?.variables?.region) && data.variables.region.length &&
                <Card
                    title={t("Region specific variables")}
                    grid={true}
                    columns={data.variables.region.length}
                    sx={{ flex: "100%" }}
                >
                    {data.variables.region.map((variable, index) => <DataCard
                        key={index}
                        title={t(variable?.label)}
                        caption={t(variable?.caption)}
                    >
                        {variable?.value}
                    </DataCard>)}
                </Card>
            }
            <Card title={t("Step Outcomes")} groupSx={{ p: 0 }}>
                <StepOutcomes companyId={companyId} />
            </Card>
        </>
    );
};

export default DynamicRevaluation;
