import { Flex } from "rebass"
import { Modal } from "../Modal"
import React from "react"
import { Button } from "../Button"


export const Confirm = ({
    title,
    isOpen,
    requestClose,
    onConfirm,
    onCancel,
    confirmText = 'Yes',
    cancelText = 'Cancel',
    showConfirm = true,
    showCancel = true,
    isWarning,
    children,
    ...props
}) => {

    return (
        <Modal title={title} isOpen={isOpen} requestClose={requestClose} {...props}>
            {children}
            <Flex sx={{ justifyContent: 'stretch', gap: 2, mt: children ? 2 : 0 }}>
                {showConfirm && (
                    <Button variant={isWarning ? 'red' : 'secondary'} type="button" onClick={() => onConfirm()} sx={{ flex: 1 }}>
                        {confirmText}
                    </Button>
                )}
                {showCancel && (
                    <Button variant={isWarning ? 'secondary' : 'primary'} type="button" sx={{ flex: 1 }} onClick={() => {
                        if (typeof onCancel === 'function') {
                            onCancel();
                        }
                        requestClose();
                    }}>
                        {cancelText}
                    </Button>
                )}
            </Flex>
        </Modal>
    )
}
