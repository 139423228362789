import React from 'react'
import { Box } from 'rebass'
import styles from './styles.module.scss'

const TaskProgressBar = ({ completed, total }) => {
    const getPercentCompleted = (done, total) => {
        const completed = (done / total) * 100
        if (completed > 1) return <Box className={styles.progressLine} sx={{ left: `${completed - 1}%` }} />
        else return null
    }
    return (
        <Box variant='barProgressBackground'>
            <Box variant='barProgress' width={completed / total} />
            {getPercentCompleted(completed, total)}
        </Box>
    )
}

export default TaskProgressBar
