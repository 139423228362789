import React, { useState, useEffect, useCallback, useRef } from "react";
import { Flex, Box } from "rebass";
import { Card } from "~/Common/Card";
import {
    getCompanies,
    exportCompanies,
    setChiefAnalyst,
    setAnalyst,
} from "@app/Api";
import { companyStatuses } from "@util/Enum";
import { toast } from "react-toastify";
import { connect, useSelector } from "react-redux";
import { getErrorMessage } from "@util/Functions";
import CompanyTile from "~/Dashboard/CompanyTile";
import { Icon } from "@svg";
import { TextField } from "~/Form";
import { debounce } from "@material-ui/core";
import { Button } from "~/Common/Button";
import AssigneeSelect from "~/Dashboard/AssigneeSelect";
import { useTranslation } from "react-i18next";
import FilterModal from "~/Modals/FilterModal";
import { useIsMounted } from "@hooks/useIsMounted";
import { Skeleton } from "~/Common/Skeleton";
import RenderOnViewportEntry from "~/Common/RenderOnViewportEntry";

const LazyCompanyTile = React.lazy(() => import('~/Dashboard/CompanyTile'));

const CompanyList = ({
    user,
    recentlyViewedCompanies,
    analysts,
    initialSearch,
    onSearchChange,
}) => {
    const { t } = useTranslation();
    const mode = useSelector((state) => state.ui.mode);
    const searchRef = useRef();
    const isMounted = useIsMounted();
    const baseFilters = {
        page: 1,
        mode: mode,
        status: [],
        filter: 'all',
        recentlyViewed: recentlyViewedCompanies,
        letters: [],
        search: initialSearch,
    }

    const [companies, setCompanies] = useState([]);
    const [stats, setStats] = useState([]);
    const [filterOpen, setFilterOpen] = useState(false);
    const [lastPage, setLastPage] = useState(1);
    const [loadPagination, setLoadPagination] = useState(false);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState(baseFilters);

    useEffect(() => {
        if (searchRef.current && initialSearch) {
            searchRef.current.value = initialSearch;
        }
    }, [initialSearch]);

    const handleExport = useCallback(() => {
        exportCompanies(filters);
    }, [filters]);

    const canAssignAnalyst = user?.permissions?.companies?.assignAnalyst;
    const canAssignChiefAnalyst = user?.permissions?.companies?.assignChiefAnalyst;

    const updateCompanyAnalyst = useCallback(
        (company, option) => {
            const id = option?.value ? option.value : null;

            setAnalyst(company.id, id)
                .then((response) => {
                    const analyst = analysts?.analysts.find(i => i.id === id)

                    setCompanies((current) =>
                        current.map((item) => ({
                            ...item,
                            analyst: company.id === item.id ? analyst : item.analyst,
                        }))
                    );

                    toast.success(
                        response?.message
                            ? response.message
                            : "Analyst successfully assigned"
                    );
                })
                .catch(({ response }) =>
                    toast.error(
                        getErrorMessage(
                            response?.data,
                            "Unable to assign analyst"
                        )
                    )
                );
        },
        [analysts]
    );

    const updateCompanyChiefAnalyst = useCallback(
        (company, option) => {
            const id = option?.value ? option.value : null;

            setChiefAnalyst(company.id, id)
                .then((response) => {
                    const chiefAnalyst = analysts?.chiefAnalysts.find(i => i.id === id)

                    setCompanies((current) =>
                        current.map((item) => ({
                            ...item,
                            chiefAnalyst: company.id === item.id ? chiefAnalyst : item.chiefAnalyst,
                        }))
                    );

                    toast.success(
                        response?.message
                            ? response.message
                            : "Chief analyst successfully assigned"
                    );
                })
                .catch(({ response }) =>
                    toast.error(
                        getErrorMessage(
                            response?.data,
                            "Unable to assign chief analyst"
                        )
                    )
                );
        },
        [analysts]
    );

    useEffect(() => {
        if (filters?.page === 1) {
            setLoading(true)
        }

        setLoadPagination(true)

        getCompanies(filters)
            .then((response) => {
                if (!isMounted.current) {
                    return;
                }

                setLastPage(response.meta.last_page)
                setStats(response.stats)
                setCompanies(current => filters?.page === 1 ?
                    response.data :
                    [
                        ...current,
                        ...response.data
                    ])
            })
            .catch(({ response }) =>
                toast.error(
                    getErrorMessage(
                        response?.data,
                        "Unable to load company data"
                    ),
                    { toastId: "getCompaniesError" }
                )

            )
            .finally(() => {
                if (!isMounted.current) {
                    return;
                }

                setLoading(false);
                setLoadPagination(false)
            });
    }, [
        filters,
        mode,
        isMounted,
    ]);

    const debouncedSearch = useCallback(
        debounce((e) => {
            const value =
                searchRef.current.value.length >= 2
                    ? searchRef.current.value
                    : undefined;

            setFilters({
                ...filters,
                page: 1,
                search: value,
            });

            if (typeof onSearchChange === "function") {
                onSearchChange(value);
            }
        }, 300),
        [recentlyViewedCompanies, searchRef]
    );

    useEffect(() => {
        const paginate = () => {
            const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
            const scroll = scrollTop + clientHeight >= scrollHeight - 20;

            if (!loadPagination && scroll && lastPage > filters.page) {
                setLoadPagination(true)
                setFilters(current => {
                    const currentVal = current.page ?? 1

                    return {
                        ...current,
                        page: currentVal + 1
                    }
                })
            }
        };

        window.addEventListener('scroll', paginate);

        return () => {
            window.removeEventListener('scroll', paginate);
        };
    }, [
        loadPagination,
        lastPage,
        filters,
    ]);

    const setFilter = (key, value) => {
        setFilters(current => {
            const currentVal = current?.[key] ?? []

            if (currentVal.includes(value)) {
                return {
                    ...current,
                    [key]: currentVal.filter(v => v !== value)
                }
            } else {
                currentVal.push(value)

                return {
                    ...current,
                    page: 1,
                    [key]: currentVal
                }
            }
        })
    }

    const resetFilters = () => setFilters(baseFilters)

    return (
        <Card title="Companies" groupSx={{ p: 1 }}>
            <>
                <FilterModal
                    isOpen={filterOpen}
                    requestClose={() => setFilterOpen(false)}
                    filters={filters}
                    setFilters={setFilters}
                    setFilter={setFilter}
                    resetFilters={resetFilters}
                />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: [
                            "column",
                            "column",
                            "column",
                            "column",
                            "column",
                            "row",
                        ],
                        flexWrap: [
                            "wrap",
                            "wrap",
                            "wrap",
                            "wrap",
                            "wrap",
                            "nowrap",
                        ],
                        alignItems: "flex-start",
                        m: -1,
                        mb: 2,
                        p: "10px 24px",
                        bg: "#fff",
                        borderTop: "1px solid #F5F7F8",
                        gap: 1,
                        justifyContent: "space-between",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: ["column", "column", "row"],
                            width: ["100%", "100%", "auto"],
                            mb: [2, 2, 2, 2, 2, 0],
                            gap: 1,
                        }}
                    >
                        {loading ? <>{new Array(Object.keys(companyStatuses).length).fill(null).map((val, index) =>
                            <Skeleton key={index} variant="button" height="48px" width="148px" />)}</> :
                            Object.entries(companyStatuses).map(([statusId, status]) =>
                                <Button
                                    key={statusId}
                                    variant="paginations"
                                    sx={{ whiteSpace: "nowrap" }}
                                    className={Array.isArray(filters.status) && filters.status.includes(statusId) ? 'active' : null}
                                    onClick={() => setFilter('status', statusId)}
                                >
                                    {status.name}&nbsp;
                                    <Box>{stats?.[statusId] ?? 0}</Box>
                                </Button>
                            )
                        }
                    </Box>

                    <Flex style={{ gap: '6px' }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: ["column", "column", "row"],
                                width: ["100%", "100%", "auto"],
                                mb: [2, 2, 2, 2, 2, 1],
                            }}
                            onClick={() => setFilterOpen(true)}
                        >
                            <Button
                                variant="inputs.secondary"
                                icon="filter"
                                sx={{
                                    ml: [0, 0, 0, 0, 0, 1],
                                    color: "blueDark",
                                }}
                            >
                                Filter
                                <Icon icon="dropdownArrow" />
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: ["column", "column", "row"],
                                width: ["100%", "100%", "auto"],
                                mb: [2, 2, 2, 2, 2, 1],
                            }}
                        >
                            <Button
                                variant="inputs.secondary"
                                icon="export"
                                sx={{
                                    ml: [0, 0, 0, 0, 0, 1],
                                    color: "blueDark",
                                }}
                                onClick={handleExport}
                            >
                                Export
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                mb: [2, 2, 2, 2, 2, 2],
                                ml: [0, 0, 0, 0, 0, 1],
                                width: "320px",
                                mr: "auto",
                            }}
                        >
                            <TextField
                                variant="inputs.secondary"
                                ref={searchRef}
                                autoComplete={"off"}
                                onChange={(e) => {
                                    debouncedSearch(e);
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        debouncedSearch(e);
                                    }
                                }}
                                placeholder="Search for companies"
                                icon="search"
                                mb={0}
                                boxSx={{ width: "320px" }}
                                iconColor="#C3C7D0"
                            />
                        </Box>


                    </Flex>
                </Box>
            </>
            {loading ? (
                <>
                    <Flex flexWrap="wrap">
                        {new Array(12).fill(null).map((val, index) => (
                            <Box
                                key={index}
                                width={[1 / 1, "", "", 1 / 2, 1 / 3, 1 / 4]}
                                style={{ display: "flex" }}
                            >
                                <CompanyTile company={null} />
                            </Box>
                        ))}
                    </Flex>
                </>
            ) : <>
                {companies ? <Flex flexWrap="wrap">
                    {companies.map((company) => <Box
                        key={company?.id}
                        width={[
                            1 / 1,
                            "",
                            "",
                            1 / 2,
                            1 / 3,
                            1 / 4,
                        ]}
                        style={{ display: "flex" }}
                    >

                        <RenderOnViewportEntry
                            style={{ minHeight: '300px', display: 'flex', gap: '16px', width: '100%', height: '100%' }}
                        >

                            <LazyCompanyTile company={company}>
                                {(canAssignAnalyst ||
                                    canAssignChiefAnalyst) && (
                                        <Box
                                            flexGrow="1"
                                            mt="20px"
                                        >
                                            {canAssignAnalyst && (
                                                <AssigneeSelect
                                                    label={
                                                        "Assign an analyst"
                                                    }
                                                    company={
                                                        company
                                                    }
                                                    handleAssignment={
                                                        updateCompanyAnalyst
                                                    }
                                                    analysts={
                                                        analysts?.analysts
                                                    }
                                                    name={`assign${company?.id}Analyst`}
                                                    current={
                                                        company
                                                            ?.analyst
                                                            ?.id
                                                    }
                                                />
                                            )}
                                            {canAssignChiefAnalyst && (
                                                <Box
                                                    sx={{
                                                        mt: 3,
                                                    }}
                                                >
                                                    <AssigneeSelect
                                                        label={
                                                            "Assign a chief analyst"
                                                        }
                                                        company={
                                                            company
                                                        }
                                                        handleAssignment={
                                                            updateCompanyChiefAnalyst
                                                        }
                                                        analysts={
                                                            analysts?.chiefAnalysts
                                                        }
                                                        name={`assign${company?.id}ChiefAnalyst`}
                                                        current={
                                                            company
                                                                ?.chiefAnalyst
                                                                ?.id
                                                        }
                                                    />
                                                </Box>
                                            )}
                                        </Box>
                                    )}

                            </LazyCompanyTile>



                        </RenderOnViewportEntry>
                    </Box>)}
                    {loadPagination && new Array(4).fill(null).map((company, key) => (
                        <Box
                            key={key}
                            width={[1 / 1, "", "", 1 / 2, 1 / 3, 1 / 4]}
                            style={{ display: "flex" }}
                        >
                            <CompanyTile company={null} />
                        </Box>
                    ))}
                </Flex> :
                    <Box
                        p={4}
                        pt={0}
                        sx={{
                            fontSize: "14px",
                            color: "#898989",
                        }}
                    >
                        {t(
                            "There are currently no companies with this status."
                        )}
                    </Box>}
            </>}
        </Card>
    );
};

export default connect((state) => ({
    user: state.user,
    recentlyViewedCompanies: state.ui.recentlyViewedCompanies,
    analysts: state.analysts,
}))(CompanyList);
