import { finaliseStepQuestionnaire, storeStepQuestionnaire, updateStepQuestionnaire } from "@app/Api";
import theme from "@app/theme";
import { Icon } from "@svg/Icon";
import { currencySymbol, formatPercent, getErrorMessage, isAdvisor, isCompany } from "@util/Functions";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Box, Flex } from "rebass";
import { BackButton } from "~/Common/BackButton";
import { Button } from "~/Common/Button";
import { Card } from "~/Common/Card";
import { Form } from "~/Common/Form";
import { Modal } from "~/Common/Modal";
import { PageNavigation } from "~/Dashboard/PageNavigation";
import { SelectField, TextField } from "~/Form";
import LoadingSpinner from "~/LoadingSpinner";
import { Link, useHistory } from 'react-router-dom';
import DateField from "~/Form/DateField";

const Question = ({ question, onChange, errors, company, disabled, index }) => {
    switch (question.type) {
        case 'select':
            return <SelectField
                nullable={true}
                label={question.question}
                placeholder="Select Option"
                name={`stepQuestionnaireQuestion_${question.id}`}
                value={question.response ? parseInt(question.response) : null}
                onChange={(val) => onChange(val ? parseInt(val) : null, question)}
                options={question.options}
                errors={errors?.[`data.${index}.response`]}
                disabled={disabled}
            />
        case 'monetary':
            return <TextField
                label={question.question}
                type="number"
                step=".01"
                placeholder={currencySymbol(company?.currency?.isoCode)}
                onChange={(val) => onChange(val, question)}
                value={question.response ? parseFloat(question.response) : null}
                errors={errors?.[`data.${index}.response`]}
                disabled={disabled}
            />
        case 'date':
            return <DateField
                placeholder="Select Date"
                label={question.question}
                value={question.response}
                errors={errors?.[`data.${index}.response`]}
                boxSx={{ width: '100%' }}
                onChange={(val) => onChange(val, question)}
                disabled={disabled}
            />
        case 'multi':
            return <MultiQuestion
                question={question}
                onChange={onChange}
                errors={errors}
                questionIndex={index}
                disabled={disabled}
            />
        default:
            return <></>
    }
}

const MultiQuestion = ({ question, onChange, errors, questionIndex, disabled }) => {
    const [newRow, setNewRow] = useState([]);
    const count = question?.inputs?.length

    useEffect(() => {
        const row = question.inputs.map(q => ({ ...q, response: '' }))
        setNewRow(row)

        if (!question.response) {
            onChange([row], question)
        }
    }, [question, onChange])

    const updateResponse = (val, responseIndex, inputIndex) => {
        const response = question.response
        response[responseIndex][inputIndex].response = val
        onChange(response, question)
    }

    const inputErrors = (responseIndex, inputIndex) => errors?.[`data.${questionIndex}.response.${responseIndex}.${inputIndex}.response`]

    const field = (input, responseIndex, inputIndex) => {
        switch (input.type) {
            case 'text':
                return <TextField
                    label={input.label}
                    placeholder={input.label}
                    value={input.response}
                    onChange={val => updateResponse(val, responseIndex, inputIndex)}
                    errors={inputErrors(responseIndex, inputIndex)}
                    disabled={disabled}
                />
            case 'date':
                return <DateField
                    label={input.label}
                    placeholder={input.label}
                    value={input.response}
                    onChange={val => updateResponse(val, responseIndex, inputIndex)}
                    errors={inputErrors(responseIndex, inputIndex)}
                    disabled={disabled}
                />
            default:
                return <></>
        }
    }

    return <Box mb="24px">
        <Box as="p" sx={{ fontSize: '12px', fontWeight: '600', mb: '12px' }}>
            {question.question}
        </Box>

        {question.response?.map((inputs, responseIndex) => <Flex key={`response_${responseIndex}`} sx={{ gap: 3 }}>
            {inputs.map((input, inputIndex) => <Box key={`input_${inputIndex}`} width={1 / count}>
                {field(input, responseIndex, inputIndex)}
            </Box>)}
        </Flex>)}

        {!disabled && <Flex
            onClick={() => onChange([...question.response, newRow], question)}
            sx={{
                justifyContent: 'center',
                '&:hover': {
                    cursor: 'pointer'
                }
            }}
        >
            <Icon icon="plus" mr="8px" />Add Row
        </Flex>}
    </Box>
}

const SubmissionModal = ({ show, variables, onClose, companyId, finalise }) => {
    const { t } = useTranslation();

    return <Modal
        isOpen={show}
        title={t('Update Dynamic Revaluation')}
        icon={'chart'}
        requestClose={() => onClose()}
        childSx={{
            maxHeight: '480px',
            overflow: 'scroll'
        }}
        footer={<Box sx={{ background: 'white', padding: '12px 24px', display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => onClose()} variant="secondary">{t("Cancel")}</Button>
            <Button onClick={() => finalise()} sx={{ ml: '16px' }}>{t("Confirm Submission")}</Button>
        </Box>}
    >
        <Box as="p" sx={{ mt: '8px', mb: '32px' }}>
            {t('You are about to complete the dynamic revaluation for this stage.')}
        </Box>
        <Box as="p" sx={{ mb: '8px' }}>
            {t('Current Net Operating Profit')}
        </Box>
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '8px',
            backgroundColor: 'white',
            border: '1px solid #F5F7F8',
            borderRadius: '8px',
            justifyContent: 'space-between',
            mb: '32px'
        }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
            }}>
                <Box sx={{
                    backgroundColor: '#16E4B3',
                    borderRadius: '4px',
                    width: '36px',
                    height: '36px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mx: '8px'
                }}>
                    <Icon
                        size="24px"
                        icon="nopat"
                        color="#2C2960"
                    />
                </Box>
                <Box sx={{ fontWeight: '500', fontSize: '14px' }} as="p">{variables.nopat}</Box>
            </Box>
            <Box sx={{ color: '#6F7782', fontSize: '10px', ml: '64px', flexGrow: 1 }} as="p">Updated: 20 April 2023</Box>
            <Link to={`/advisor/company/${companyId}/revaluation`}>
                <Button variant="secondary">{t('View Net Operating Profit')}</Button>
            </Link>
        </Box>
        <VariablesTable heading={t('Region Specific Variables')} data={variables?.region} />
        <VariablesTable heading={t('Industry Specific Variables')} data={variables?.industry?.map((v) => { return { label: v.value, value: v.label } })} />
    </Modal>
}

const VariablesTable = ({ heading, data }) => {
    return Array.isArray(data) && data.length ? <Box sx={{
        border: '1px solid #F5F7F8',
        borderRadius: '8px',
        background: 'white',
        mb: '32px'
    }}>
        <Box sx={{ background: theme.colors.primary, color: 'white', padding: '16px 12px', borderRadius: '8px 8px 0 0' }}>{heading}</Box>
        {data.map((variable, index) => <Box key={index} sx={{ display: 'flex', padding: '12px' }}>
            <Box sx={{ fontWeight: '500', fontSize: '16px', minWidth: '148px', mr: '16px' }}>{variable?.value}</Box>
            <Box
                dangerouslySetInnerHTML={{ __html: variable?.label }}
                sx={{
                    p: { marginTop: 0 }
                }}
            />
        </Box>)}
    </Box> : <></>
}

const StepQuestionnaire = ({ companyId, stepId, user, company }) => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})
    const [questions, setQuestions] = useState([])
    const [errors, setErrors] = useState({})
    const [variables, setVariables] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [disabled, setDisabled] = useState(true)

    const { t } = useTranslation();
    let history = useHistory();

    useEffect(() => {
        setLoading(true)
        storeStepQuestionnaire(companyId, stepId)
            .then(({ data }) => {
                setData(data)
                setQuestions(data?.questions)
            })
            .catch(({ response }) =>
                toast.error(
                    getErrorMessage(
                        response?.data,
                        "Unable to load questionnaire data"
                    ),
                    { toastId: "storeStepQuestionnaireError" }
                )
            )
            .finally(() => setLoading(false))
    }, [companyId, stepId])

    useEffect(() => {
        setDisabled(user ? !isAdvisor(user) : true)
    }, [user])

    const score = useMemo(() => {
        return questions?.reduce((sum, question) => {
            if (question?.type === 'select') {
                const response = question?.response
                const option = question?.options?.filter(option => option?.value === response)?.[0]

                return sum + (option?.score ?? 0)
            }

            return sum
        }, 0)
    }, [questions])

    const setResponse = (val, question) => {
        setQuestions(current => {
            current[current.findIndex(q => q.id === question.id)] = {
                ...question,
                response: val
            }

            // Destructuring so a brand new reference is returned,
            // and React actually recognises the change of state.
            return [...current]
        })
    }

    const submitQuestionnaire = (submit = false) => {
        setLoading(true)
        setErrors({})
        updateStepQuestionnaire(companyId, stepId, { data: questions, submit: submit })
            .then(({ data }) => {
                if (submit) {
                    setVariables(data)
                    setShowModal(true)
                } else {
                    setData(data)
                    setQuestions(data?.questions)
                    toast.success(t('Questionnaire updated'))
                }
            })
            .catch(({ response }) => {
                const errorMessage = response?.data?.message || "Unable to submit questionnaire";
                toast.error(t(errorMessage), { toastId: "submitQuestionnaireError" });
                setErrors(response?.data?.errors)
            })
            .finally(() => setLoading(false))
    }

    const finaliseQuestionnaire = () => {
        setLoading(true)
        setErrors({})
        finaliseStepQuestionnaire(companyId, stepId, { data: questions })
            .then(() => {
                toast.success(t('Revaluation applied'))
                history.push(`/advisor/company/${companyId}/revaluation`)
            })
            .catch(({ response }) =>
                setErrors(response?.data?.errors)
            )
            .finally(() => setLoading(false))
    }

    return loading ? <LoadingSpinner /> : <>
        <PageNavigation
            icon="folder"
            title="Projects"
            subtitle={data?.stage?.name}
            section={data?.name}
        />
        <BackButton
            to={isCompany(user) ? `/company/projects` :
                `/advisor/company/${companyId}/${data?.stage?.isOnboarding ? 'onboarding' : 'stages'}`}
            label={`Back to ${data?.stage?.isOnboarding ? 'Onboarding' : 'Projects'}`}
        />
        <Card
            sx={{ maxWidth: '660px' }}
            title={data?.name}
            subtitle={data?.stage?.description}
            titleSx={{ flexDirection: 'column', alignItems: 'flex-start' }}
            subtitleSx={{ ml: 0 }}
            grid={true}
            columns={1}
        >
            <Form>
                {questions?.map((question, index) => <Question
                    company={company}
                    key={index}
                    question={question}
                    onChange={setResponse}
                    errors={errors}
                    index={index}
                    disabled={disabled}
                />)}
            </Form>
            <Flex justifyContent="space-between" alignItems="end">
                <Flex>
                    {!disabled && <>
                        {data?.complete && <Button sx={{ mr: '16px' }} onClick={() => submitQuestionnaire(true)}>{t("Submit Questionnaire")}</Button>}
                        <Button onClick={() => submitQuestionnaire()} variant="secondary">{t("Save Draft")}</Button>
                    </>}
                </Flex>
                <Box as="p" sx={{ fontSize: '12px', fontWeight: '600' }}>
                    {`Score: ${formatPercent(score)}`}
                </Box>
            </Flex>
            {!disabled && !data?.complete && <Box as="p" sx={{ fontSize: '12px', fontWeight: '600' }}>
                {t('Please complete all tasks before submitting the questionnaire')}
            </Box>}
        </Card>
        <SubmissionModal
            show={showModal}
            variables={variables}
            onClose={() => setShowModal(false)}
            companyId={companyId}
            finalise={finaliseQuestionnaire}
        />
    </>
};

export default connect((state) => ({ user: state.user, company: state.company }))(StepQuestionnaire);
