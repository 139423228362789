import React from 'react';
import { SearchableSelectField } from '~/Form';

const AssigneeSelect = ({ company, analysts, handleAssignment, label, name, current }) => {
    const options = analysts?.map(i => ({ value: i.id, label: i.name })) ?? [];

    return <SearchableSelectField
        nullable={true}
        label={label + ':'}
        placeholder={label}
        options={options}
        boxSx={null}
        name={name}
        menuPosition={'fixed'}
        isMulti={false}
        value={options.find(i => i.value === current)}
        onChange={(option) => handleAssignment(company, option)}
    />
}

export default AssigneeSelect;
