import { createSlice } from '@reduxjs/toolkit';

const user = createSlice({
    name: 'user',
    initialState: null,
    reducers: {
        setUser: (state, { payload }) => payload,
        resetUser: () => null,
        setUserCompanyStatus: (state, { payload }) => {
            const newState = { ...state };
            newState.companyStatus = payload;
            return newState;
        },
        setClosedMenu: (state, { payload }) => {
            state.closedMenu = payload;
            return state;
        },
    },
});

export const { setUser, resetUser, setUserCompanyStatus, setClosedMenu } = user.actions;

export default user.reducer;
