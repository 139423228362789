import { connect } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import CurrentTasks from '@section/NavigationBar/CurrentTask'

const selectStages = (state) => state.stages

const taskToDo = (status) => status && status !== 3

export const selectIncompleteTasks = createSelector(
    [selectStages],
    (stages) => {
        const incompleteTasks = []
        stages.map(({ steps }) =>
            steps.map(({ tasks, name }) =>
                tasks.map((task) =>
                    taskToDo(task.status) && incompleteTasks.push({ ...task, ...{ stageName: name } }))))
        return incompleteTasks
    }
)

const stateProps = (state) => ({ tasks: selectIncompleteTasks(state) })

export default connect(stateProps)(CurrentTasks)
