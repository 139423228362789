import React, { forwardRef } from 'react';
import { Box } from 'rebass';
import { Label, Input } from '@rebass/forms';
import { Icon } from '@svg';
import { useTranslation } from 'react-i18next';

const TextField = forwardRef(({
    value = undefined,
    onChange,
    name,
    helpText = '',
    errors = null,
    label = null,
    placeholder = '',
    type = 'text',
    disabled = false,
    sx = null,
    boxSx = null,
    icon,
    iconColor = 'inherit',
    allowNull = true,
    errorsSx = null,
    onKeyPress,
    ...props
}, ref) => {
    const { t } = useTranslation()

    return (
        <Box sx={boxSx}>
            {label && (
                <Label htmlFor={name} mb={1} flexDirection="column">
                    {label}{' '}
                    {helpText && (
                        <>
                            <br />
                            <small>{helpText}</small>
                        </>
                    )}
                </Label>
            )}
            <Box sx={{ position: 'relative' }}>
                <Input
                    id={name}
                    name={name}
                    ref={ref}
                    type={type}
                    placeholder={placeholder}
                    value={value ?? (!allowNull ? '' : undefined)}
                    onChange={(e) => typeof onChange === 'function' && onChange(e.target.value, name)}
                    disabled={disabled}
                    onKeyDown={onKeyPress}
                    sx={{
                        pr: icon ? '40px' : 0,
                        ...sx,
                    }}
                    {...props}
                />
                {icon && (
                    <Box
                        sx={{
                            position: 'absolute',
                            right: '15px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            fontSize: '0px',
                            pointerEvents: 'none',
                        }}
                    >
                        <Icon color={iconColor} icon={icon} />
                    </Box>
                )}
            </Box>
            {errors && (
                <Box sx={{
                    mt: -18,
                    mb: 20,
                    color: 'red',
                    fontSize: 12,
                    ...errorsSx
                }}>
                    {t(errors[0])}
                </Box>
            )}
        </Box>
    );
});

export default TextField;
