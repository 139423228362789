import React, { useEffect, useState } from 'react';
import { getCompanyStages } from '@app/Api';
import LoadingSpinner from '~/LoadingSpinner';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@util/Functions';
import CompanyStages from '~/Dashboard/CompanyStages';

const CompanyOnboardingDocuments = ({ companyId }) => {
    const [stages, setStages] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (companyId) {
            setLoading(true);
            getCompanyStages({ companyId, onboardingStages: true })
                .then(({ data }) => {
                    setStages(data);
                    setLoading(false);
                })
                .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to load company data', { toastId: 'getCompanyStagesError' })));
        }
    }, [companyId]);

    const updateStages = () => getCompanyStages({ companyId, onboardingStages: true })
        .then(({ data }) => {
            setStages(data);
            setLoading(false);
        })
        .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to load company data'), { toastId: 'getCompanyStagesError' }));

    if (loading) return <LoadingSpinner />;

    return <CompanyStages stages={stages} companyId={companyId} title="Onboarding" updateStages={updateStages} onboardingStages={true} />;
};

export default CompanyOnboardingDocuments;
