import React from 'react';
import { isAdvisor } from '@util/Functions';
import { Box } from 'rebass';
import { PageTitle } from '~/Common/PageTitle';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PayForSubscription from 'Pages/Common/PayForSubscription';
import SettingsCards from './SettingsCards';
import AccountDetails from './AccountDetails';
import Permissions from './Organisation/Permissions';
import Billing from './Organisation/Billing';
import EditOrganisation from './Organisation/EditOrganisation';
import { isCeo, isSupervisor } from '@util/Functions/User';

const SettingsRender = ({ company, user, show, page, nested }) => {
    const { t } = useTranslation();
    const canSubscribe = isAdvisor(user) && company?.permissions?.purchaseSubscription;

    const pageData = (() => {
        if (nested && !page) {
            return {
                name: 'cards',
                component: <SettingsCards />,
                subtitle: 'settings.subtitle',
            };
        }

        switch (page) {
            case 'edit':
                if (isCeo(user)) {
                    return {
                        name: 'edit',
                        component: <EditOrganisation />,
                        subtitle: 'settings.organisation.subtitle',
                    };
                }
                break;
            case 'permissions':
                if (isCeo(user) || isSupervisor(user)) {
                    return {
                        name: 'permissions',
                        component: <Permissions />,
                        subtitle: 'settings.permissions.subtitle',
                    };
                }
                break;
            case 'notifications':
                return {
                    name: 'notifications',
                    component: 'Notifications',
                    subtitle: 'settings.notifications.subtitle',
                };
            case 'billing':
                if (isCeo(user) || isSupervisor(user)) {
                    return {
                        name: 'billing',
                        component: <Billing />,
                        subtitle: 'settings.billing.subtitle',
                    };
                }
                break;
            case 'account':
            default:
                if (page === 'account' || !nested) {
                    return {
                        name: 'account',
                        component: <AccountDetails company={company} user={user} show={show} />,
                        subtitle:
                            company && user
                                ? 'settings.account.subtitle'
                                : company
                                    ? 'settings.account.companySubtitle'
                                    : 'settings.account.userSubtitle',
                    };
                } else {
                    return {
                        name: null,
                        component: null,
                        subtitle: '',
                    };
                }
        }
    })();

    return (
        <>
            <Box sx={{ px: 3 }}>
                <PageTitle subtitle={t(pageData.subtitle)} />
            </Box>
            {company?.subscription && !company?.subscription.paid && pageData.name !== 'account' && (
                <Box variant="warning">
                    <Box variant="warningHeader">Company Access is locked</Box>
                    <Box variant="card.container">
                        <Box>
                            Your access to this company is currently locked. Please{' '}
                            {canSubscribe
                                ? 'restart your subscription to receive'
                                : 'contact your adviser to grant you'}{' '}
                            access.
                        </Box>
                        {canSubscribe && (
                            <Box mt={3}>
                                <PayForSubscription companyId={company.id} variant="warning" />
                            </Box>
                        )}
                    </Box>
                </Box>
            )}
            {pageData.component}
        </>
    );
};

export default connect((state) => ({ user: state.user, company: state.company }))(SettingsRender);
