import React, { useCallback, useState } from 'react';
import { Box, Heading } from 'rebass';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { debounce, Slider } from '@material-ui/core';
import { theme } from '@app/theme';

const ScorecardSlider = ({ value = 0, label, onChange, color }) => {
    const [status, setStatus] = useState(value);

    const muiTheme = createMuiTheme({
        overrides: {
            MuiSlider: {
                track: {
                    height: '8px',
                },
                rail: {
                    height: '8px',
                    color: theme.colors.gray2,
                },
                mark: {
                    height: '8px',
                    color: 'white',
                },
                marked: {
                    color: '#000',
                },
                markActive: {
                    color: '#000',
                },
                markLabelActive: {
                    color: theme.colors.blueDark,
                    textTransform: 'uppercase',
                    fontSize: '10px',
                    transform: 'none',
                    fontWeight: 600,
                },
                markLabel: {
                    color: theme.colors.blueDark,
                    textTransform: 'uppercase',
                    fontSize: '10px',
                    transform: 'translateX(-100%)',
                    fontWeight: 600,
                },
                thumb: {
                    backgroundColor: 'transparent',
                    '&:after': {
                        content: 'none',
                    },
                },
            },
        },
    });

    const marks = [
        { value: -0.5, label: 'Low Risk' },
        { value: -0.4 },
        { value: -0.3 },
        { value: -0.2 },
        { value: -0.1 },
        { value: 0 },
        { value: 0.1 },
        { value: 0.2 },
        { value: 0.3 },
        { value: 0.4 },
        { value: 0.5, label: 'High Risk' },
    ];

    const debounceChange = useCallback(debounce(onChange, 500), []);

    const updateStatus = (val) => {
        setStatus(val);
        debounceChange(val);
    };

    return (
        <Box mt={4}>
            {label && <Heading mb="45px">{label}</Heading>}
            <Box px={0}>
                <ThemeProvider theme={muiTheme}>
                    <Slider
                        value={status}
                        max={0.5}
                        min={-0.5}
                        step={0.1}
                        marks={marks}
                        valueLabelDisplay="on"
                        onChange={(e, val) => updateStatus(val)}
                        style={{ color }}
                    />
                </ThemeProvider>
            </Box>
        </Box>
    );
};

export default ScorecardSlider;
