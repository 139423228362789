import React, { useEffect, useState } from 'react';
import { getCompany, getValuationCompany } from '@app/Api';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@util/Functions';
import { connect } from 'react-redux';
import { setActionItems } from '@app/Store/storeActionItems';
import { getActionItems } from '@app/Api/Company';

export const CompanyContextData = React.createContext({});

const CompanyContext = ({ companyId, valuationId, setActionItems, ...props }) => {
    const [company, setCompany] = useState(null);

    useEffect(() => {
        if (companyId) {
            getCompany(companyId)
                .then((response) => {
                    setCompany(response);
                })
                .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to get company details')));

            getActionItems(companyId)
                .then(({ data }) => {
                    setActionItems({
                        currentCompanyId: companyId,
                        ...data,
                    });
                })
                .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to get company action items')))

            return () => {
                setActionItems({
                    currentCompanyId: null
                });
            }
        } else if (valuationId) {
            getValuationCompany(valuationId)
                .then((response) => {
                    setCompany(response);
                })
                .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to get company details')));
        }
    }, [companyId, valuationId, setActionItems]);

    return <CompanyContextData.Provider value={company}>{props.children}</CompanyContextData.Provider>;
};

export default connect(null, { setActionItems })(CompanyContext);
