import { updateOrganisation } from '@app/Api/Organisation';
import { setOrganisation } from '@app/Store';
import { getCountryOptions } from '@util/Enum/Address';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Grid } from 'theme-ui';
import { Button } from '~/Common/Button';
import { Card } from '~/Common/Card';
import { Form } from '~/Common/Form';
import { SelectField, TextField } from '~/Form';

const EditOrganisation = ({ company, user, org, settings, setOrganisation }) => {
    const [values, setValues] = useState({
        name: '',
        acn: '',
        address: '',
        suburb: '',
        state: '',
        postcode: '',
        countryCode: '',
    });
    const [errors, setErrors] = useState([]);
    const [isTouched, setTouched] = useState(false);
    const [territoryOptions, setTerritoryOptions] = useState([]);
    const [terms, setTerms] = useState({
        territory: 'State',
        suburb: 'City',
        postcode: 'Postcode',
    });
    const allowSubmit = isTouched;
    const countryCode = values.countryCode;

    const resetValues = (org) => {
        setValues({
            name: org.name,
            acn: org.acn,
            address: org.address,
            suburb: org.suburb,
            state: org.state,
            postcode: org.postcode,
            countryCode: org.countryCode,
        });
    };

    const handleChange = (value, field) => {
        setTouched(true);
        setValues((values) => ({
            ...values,
            [field]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        updateOrganisation(org.id, values)
            .then(({ data, ...response }) => {
                setTouched(false);
                setOrganisation(data);
                resetValues(data);
                toast.success(response?.message ? response.message : 'The organisation has been updated');
            })
            .catch(({ response }) => {
                response?.data?.errors && setErrors(response.data.errors);
                toast.error('Something went wrong while updating the organisation', {
                    toastId: 'updateOrganisation',
                });
            });
    };

    useEffect(() => {
        if (org) {
            resetValues(org);
        }
    }, [org]);

    useEffect(() => {
        const options = getCountryOptions(countryCode, settings);
        setTerritoryOptions(options.territory);
        setTerms(options.terms);
    }, [countryCode, settings]);

    return (
        <Card title="Edit organisation" icon="staff" iconSize="21px" maxWidth="750px">
            <Form onSubmit={handleSubmit}>
                <TextField
                    name="name"
                    label="Name"
                    value={values.name}
                    onChange={handleChange}
                    boxSx={{ width: '100%' }}
                    errors={errors?.name}
                />
                <TextField
                    name="acn"
                    label="ACN"
                    onChange={handleChange}
                    value={values.acn}
                    boxSx={{ width: '100%' }}
                    errors={errors?.acn}
                />
                <TextField
                    name="address"
                    label="Address"
                    value={values.address}
                    onChange={handleChange}
                    boxSx={{ width: '100%' }}
                    errors={errors?.address}
                />

                <Grid
                    sx={{
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gridGap: 3,
                    }}
                >
                    <TextField
                        name="suburb"
                        value={values.suburb}
                        label={terms.suburb}
                        onChange={handleChange}
                        boxSx={{ width: '100%' }}
                        errors={errors?.suburb}
                    />
                    <TextField
                        name="postcode"
                        value={values.postcode}
                        label={terms.postcode}
                        onChange={handleChange}
                        boxSx={{ width: '100%' }}
                        errors={errors?.postcode}
                    />
                </Grid>
                <Grid
                    sx={{
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gridGap: 3,
                    }}
                >
                    <SelectField
                        label="Select Country"
                        placeholder="Country"
                        name="countryCode"
                        value={values.countryCode}
                        onChange={handleChange}
                        options={settings.options.countries}
                        errors={errors?.countryCode}
                    />
                    {values.countryCode && territoryOptions && (
                        <SelectField
                            label={`Select ${terms.territory}`}
                            placeholder={`Select ${terms.territory}`}
                            name="state"
                            value={values.state}
                            onChange={handleChange}
                            options={territoryOptions}
                            errors={errors?.state}
                        />
                    )}
                </Grid>
                <Button variant={!allowSubmit ? 'disabled' : 'primary'} sx={{ width: '100%' }}>
                    Save
                </Button>
            </Form>
        </Card>
    );
};

export default connect((state) => ({ user: state.user, org: state.organisation, settings: state.settings }), {
    setOrganisation,
})(EditOrganisation);
