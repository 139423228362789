import { indexExitAssessments, storeExitAssessment } from '@app/Api';
import { exitAssessmentStatuses } from '@util/Enum/ExitAssessmentStatus';
import { getErrorMessage } from '@util/Functions';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Box, Flex } from 'rebass';
import { Button } from '~/Common/Button';
import { Card } from '~/Common/Card';
import { Form } from '~/Common/Form';
import { Modal } from '~/Common/Modal';
import { StatusTag } from '~/Dashboard/StatusTag';
import { TextField } from '~/Form';

const ExitAssessmentModal = ({ open, setOpen, setAssessments }) => {
    const [company, setCompany] = useState('')
    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState({})

    const close = () => {
        setCompany('')
        setEmail('')
        setOpen(false)
    }

    const submit = (e) => {
        e.preventDefault()
        setErrors({})

        storeExitAssessment({ company, email })
            .then(({ data }) => {
                setAssessments(data)
                close()
            })
            .catch(({ response }) =>
                setErrors(response.data?.errors)
            )
    }

    return <Modal
        isOpen={open}
        requestClose={close}
        title={'Add a new company'}
        shouldCloseOnEsc={true}
        showClose={true}
        setAssessments={setAssessments}
    >
        <Form onSubmit={submit}>
            <TextField
                name={'company'}
                placeholder={'Company'}
                label={'Company'}
                value={company}
                onChange={v => setCompany(v)}
                errors={errors.company}
            />
            <TextField
                name={'email'}
                placeholder={'Email'}
                label={'Email'}
                value={email}
                onChange={v => setEmail(v)}
                errors={errors.email}
            />
            <Button as={'button'} type={'submit'}>
                Add Company
            </Button>
        </Form>
    </Modal>
}

const ExitAssessmentTool = ({ user }) => {
    const [open, setOpen] = useState(false)
    const [assessmentUrl, setAssessmentUrl] = useState('')
    const [assessments, setAssessments] = useState([])

    useEffect(() => {
        indexExitAssessments()
            .then(({ data, assessment_link }) => {
                setAssessments(data)
                setAssessmentUrl(assessment_link)
            })
            .catch(({ response }) =>
                toast.error(
                    getErrorMessage(response?.data, 'Unable to fetch exit assessments'),
                    { toastId: 'indexExitAssessmentsError' }
                )
            )
    }, [user])

    return <>
        <ExitAssessmentModal
            open={open}
            setOpen={setOpen}
            setAssessments={setAssessments}
        />
        <Flex mx={3} flexDirection={'column'}>
            <Flex mb={4}>
                <Button
                    as={'button'}
                    onClick={() => setOpen(true)}
                    icon={'plus'}
                >
                    Add Company
                </Button>
                {assessmentUrl && <Button
                    as={'button'}
                    onClick={() => {
                        navigator.clipboard.writeText(assessmentUrl)
                        toast.success('Successfully copied assessment URL')
                    }}
                    icon={'clipboard'}
                    variant={'secondary'}
                    sx={{ ml: 3 }}
                >
                    Copy Your Link
                </Button>}
            </Flex>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gap: 3
                }}
            >
                {assessments.map((assessment, index) =>
                    <Card
                        sx={{
                            position: 'relative'
                        }}
                        key={index}
                        title={assessment.company}
                        m={0}
                        subtitle={assessment.email}
                        titleSx={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            paddingTop: 4
                        }}
                        subtitleSx={{
                            margin: 0
                        }}
                    >
                        <StatusTag type='exit' status={assessment.status} absolute={true} />
                        <Flex>
                            {exitAssessmentStatuses[assessment.status].key !== 'complete' ? <Button
                                as={'button'}
                                onClick={(e) => {
                                    e.preventDefault()
                                    navigator.clipboard.writeText(assessment.link)
                                    toast.success('Value Gap Assessment link copied to clipboard')
                                }}
                                sx={{
                                    width: '100%'
                                }}
                                variant={'secondary'}
                            >
                                Copy Link
                            </Button> : <Button
                                as={'a'}
                                to={assessment.report}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    width: '100%'
                                }}
                            >
                                Download Report
                            </Button>}
                        </Flex>
                    </Card>
                )}
            </Box>
        </Flex>
    </>
};

export default connect((state) => ({ user: state.user }))(ExitAssessmentTool);
