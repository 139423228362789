import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Flex, Button, Text } from 'rebass';
import { getComments, createComment } from '@app/Api';
import LoadingSpinner from '~/LoadingSpinner';
import styles from './styles.module.scss';
import { formatDateFull, getErrorMessage } from '@util/Functions';
import { WysiwygField } from '~/Form';
import UserAvatar from '~/Dashboard/Avatar';
import SanitizedField from '~/Form/SanitizedField';
import { connect } from 'react-redux';

const CommentModule = ({ user, type, id, uuid }) => {
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState(null);
    const [comments, setComments] = useState([]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (type && id) {
            setLoading(true);
            getComments(type, id)
                .then((comments) => {
                    setComments(uuid ? comments.filter(comment => comment.uuid === uuid) : comments);
                    setErrors({});
                })
                .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to get comments')))
                .finally(() => setLoading(false))
        }
    }, [type, id, uuid]);

    if (loading) return <LoadingSpinner />

    const handleSubmitComment = e => {
        e.preventDefault();
        createComment(type, id, comment, uuid)
            .then((comments) => {
                setComments(uuid ? comments.filter(comment => comment.uuid === uuid) : comments);
                setErrors({});
                setComment(null);
            })
            .catch(({ response }) => {
                const errorMessage = response?.data?.message || 'Unable to add comment';
                toast.error(errorMessage);
                response?.data?.errors && setErrors(response.data.errors);
            });
    }

    return (<>
        <Box className={styles.postMessage} p={4}>
            <Flex>
                <UserAvatar firstName={user.firstName} surname={user.surname} email={user?.email} avatar={user.avatar} mr={2} />
                <Box flex={1}>
                    <Box>
                        <WysiwygField value={comment} setValue={setComment} />
                        {errors.comment && (
                            <Box mb={-15} color="red" fontSize={12}>
                                {errors.comment[0]}
                            </Box>
                        )}
                    </Box>
                    <Box textAlign="right" mt={3}>
                        <Button variant={loading ? 'disabled' : 'primary'} ml="auto" onClick={(e) => handleSubmitComment(e)}>
                            Leave Comment
                        </Button>
                    </Box>
                </Box>
            </Flex>
        </Box>
        {comments?.map(({ comment, user, created_at }, key) => (
            <Flex mt={3} key={key}>
                <UserAvatar firstName={user?.firstName} surname={user?.surname} email={user?.email} avatar={user?.avatar} mr={2} />

                <Box flex={1}>
                    <Flex>
                        <Text fontSize={14} fontWeight={'bold'}>
                            {user?.firstName} {user?.surname}
                        </Text>
                        <Text ml={2} fontSize={10} sx={{ color: '#6F7782', lineHeight: '20px' }}>
                            {formatDateFull(created_at)}
                        </Text>
                    </Flex>
                    <Text mt={2} fontSize={14}>
                        <Box className={styles.commentBox}>
                            <SanitizedField html={comment} />
                        </Box>
                    </Text>
                </Box>
            </Flex>
        ))}
    </>);
};
export default connect((state) => ({ user: state.user }))(CommentModule);
