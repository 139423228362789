import React, { Fragment, useEffect, useState } from "react";
import { Tabs } from "../Tabs";
import { Box } from "rebass";
import { Card } from "../Card";

export const TabCard = ({
    label,
    tabs,
    tabProps,
    sx,
    height = "100%",
    children,
    contentHeight,
    ...props
}) => {
    const [selected, setSelected] = useState(null);

    const TabBody = selected?.component ?? Fragment;

    useEffect(() => {
        setSelected((selected) =>
            selected === null ? tabs?.[0] ?? null : selected
        );
    }, [tabs]);

    return (
        <Card
            flexDirection="column"
            {...props}
            gapless={true}
            sx={{
                bg: "transparent",
                height,
                borderRadius: 0,
                ...sx,
            }}
            groupSx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
            }}
        >
            <Tabs
                styleVariant="header"
                label={label}
                tabs={tabs}
                selected={selected?.key}
                onChange={(tab) => setSelected(tab)}
                sx={{ mb: 0 }}
            />
            <Box
                variant="tabCard"
                sx={{
                    maxHeight: `${contentHeight + 76}px`,
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        overflowY: "auto",
                    }}
                >
                    <TabBody {...tabProps?.[selected?.key]} />
                </Box>
                {children && (
                    <Box
                        sx={{
                            display: "flex",
                            gap: "8px",
                            padding: "16px",
                            justifyContent: "space-between",
                        }}
                    >
                        {children}
                    </Box>
                )}
            </Box>
        </Card>
    );
};
