import { Icon } from '@svg'
import { getValuation, overrideValuation, updateValuation } from '@app/Api'
import { formatCurrency, formatNumber, formatPercent, getErrorMessage, isCompany, yearsList } from '@util/Functions'
import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { Box, Flex, Heading } from 'rebass'
import { Card } from '~/Common/Card'
import LoadingSpinner from '~/LoadingSpinner'
import styles from './styles.module.scss'
import { useHistory } from 'react-router-dom'
import { Modal } from '~/Common/Modal';
import { AreaField, SelectField, TextField } from '~/Form'
import { BackButton } from '~/Common/BackButton'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next";
import Tooltip from '~/Common/Tooltip'
import { Button } from '~/Common/Button'

const AdvisorValuationEditV202110 = ({ valuationId, user, allowFullEdits, companies, viaAdmin }) => {
    const history = useHistory()
    const [valuation, setValuation] = useState()
    const [loading, setLoading] = useState(false)
    const [editRatios, setEditRatios] = useState(false)
    const [allowFinalise, setAllowFinalise] = useState(false)
    const [editOverride, setEditOverride] = useState(false);

    const [errors, setErrors] = useState([])

    const [year1GrowthRate, setYear1GrowthRate] = useState('')
    const [year1NoPat, setYear1NoPat] = useState('')
    const [year1Reason, setYear1Reason] = useState('')
    const [year1Weight, setYear1Weight] = useState('')
    const [year1NoPatDisabled, setYear1NoPatDisabled] = useState(true)

    const [year2GrowthRate, setYear2GrowthRate] = useState('')
    const [year2NoPat, setYear2NoPat] = useState('')
    const [year2Reason, setYear2Reason] = useState('')
    const [year2Weight, setYear2Weight] = useState('')
    const [year2NoPatDisabled, setYear2NoPatDisabled] = useState(true)

    const [year3GrowthRate, setYear3GrowthRate] = useState('')
    const [year3NoPat, setYear3NoPat] = useState('')
    const [year3Reason, setYear3Reason] = useState('')
    const [year3Weight, setYear3Weight] = useState('')
    const [year3NoPatDisabled, setYear3NoPatDisabled] = useState(true)

    const [year4GrowthRate, setYear4GrowthRate] = useState('')
    const [year4NoPat, setYear4NoPat] = useState('')
    const [year4Reason, setYear4Reason] = useState('')
    const [year4Weight, setYear4Weight] = useState('')
    const [year4NoPatDisabled, setYear4NoPatDisabled] = useState(true)

    const [year5GrowthRate, setYear5GrowthRate] = useState('')
    const [year5NoPat, setYear5NoPat] = useState('')
    const [year5Reason, setYear5Reason] = useState('')
    const [year5NoPatDisabled, setYear5NoPatDisabled] = useState(true)

    const [reason, setReason] = useState('')

    const [override, setOverride] = useState(null)

    const { t } = useTranslation();

    // If we want to implement focus on disabled click => https://stackoverflow.com/questions/28889826/how-to-set-focus-on-an-input-field-after-rendering

    useEffect(() => {
        setLoading(true)
        getValuation({ valuationId })
            .then((valuation) => {
                setReason(valuation.reason)
                setValuation(valuation)
                setAllowFinalise(allowFullEdits || valuation?.permissions?.finalise)
                setOverride(valuation.beyond_enterprise_value_capitalization_override)
                setLoading(false)
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to load valuation details')))
    }, [valuationId, history, allowFullEdits])

    const handleSetOverride = (v) => {
        const value = v === false ? null : override;
        setEditOverride(false);
        overrideValuation(valuation.id, { override: value })
            .then((valuation) => {
                setValuation(valuation)
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to update valuation')))
    }

    const company = valuation?.companyId ? companies.find(company => company.id === valuation.companyId) : null
    const isoCode = company?.currency?.isoCode

    const beyondFmpValuation = useMemo(() =>
        parseFloat(valuation?.beyondEnterpriseValueCapitalization) +
        parseFloat(valuation?.cashAndEquivalents) +
        parseFloat(valuation?.financialAssets) +
        parseFloat(valuation?.nonOperatingAssets) +
        parseFloat(valuation?.loanToDirectors) +
        parseFloat(valuation?.surplusAssets) -
        parseFloat(valuation?.shortTermDebt) -
        parseFloat(valuation?.longTermDebt) -
        parseFloat(valuation?.loanFromDirectors), [valuation])

    if (loading) return <LoadingSpinner />

    const handleDeleteOverride = () => {
        setOverride(null);
        setEditOverride(false);
        handleSetOverride(false);
    }

    const handleUpdateValuation = (updatedValuation) => {
        updatedValuation = { ...updatedValuation, ...{ valuationId } }
        updateValuation(updatedValuation)
            .then((valuation) => {
                setValuation(valuation)
                setEditRatios(false)
                setErrors([])
                toast.success('Valuation updated successfully')
            })
            .catch(({ response }) => {
                const errorMessage = response?.data?.message || 'Unable to update valuation'
                toast.error(errorMessage)
                response?.data?.errors && setErrors(response.data.errors)
            })
    }

    const setFirstYear = (firstYear) => handleUpdateValuation({ firstYear })

    const toggleForecastYear = (year) => {
        const value = valuation[`forecastYear${year}`] === 1 ? 0 : 1
        handleUpdateValuation({ [`forecastYear${year}`]: value })
    }

    const updateRatios = () => {
        const ratios = {
            year1GrowthRate: year1NoPatDisabled ? year1GrowthRate : null,
            year1NoPat: !year1NoPatDisabled ? year1NoPat : null,
            year2GrowthRate: year2NoPatDisabled ? year2GrowthRate : null,
            year2NoPat: !year2NoPatDisabled ? year2NoPat : null,
            year3GrowthRate: year3NoPatDisabled ? year3GrowthRate : null,
            year3NoPat: !year3NoPatDisabled ? year3NoPat : null,
            year4GrowthRate: year4NoPatDisabled ? year4GrowthRate : null,
            year4NoPat: !year4NoPatDisabled ? year4NoPat : null,
            year5GrowthRate: year5NoPatDisabled ? year5GrowthRate : null,
            year5NoPat: !year5NoPatDisabled ? year5NoPat : null,
            year1Reason,
            year2Reason,
            year3Reason,
            year4Reason,
            year5Reason,
            year1Weight,
            year2Weight,
            year3Weight,
            year4Weight
        }
        handleUpdateValuation(ratios);
    }

    const columnsList = [...Array(6).keys()]

    const getYearLabel = (index) => {
        const indexYear = parseInt(valuation.firstYear) + index
        return `${indexYear} ${valuation[`forecastYear${index}`] || index === 4 ? 'Forecast' : ''}`
    }

    const handleEditRatiosClick = () => {
        if (valuation.year1NoPat !== null) {
            setYear1GrowthRate(null)
            setYear1NoPat(valuation.year1NoPat)
            setYear1NoPatDisabled(false)
        } else {
            setYear1GrowthRate(valuation.year1GrowthRate)
            setYear1NoPat(null)
            setYear1NoPatDisabled(true)
        }
        if (valuation.year2NoPat !== null) {
            setYear2GrowthRate(null)
            setYear2NoPat(valuation.year2NoPat)
            setYear2NoPatDisabled(false)
        } else {
            setYear2GrowthRate(valuation.year2GrowthRate)
            setYear2NoPat(null)
            setYear2NoPatDisabled(true)
        }
        if (valuation.year3NoPat !== null) {
            setYear3GrowthRate(null)
            setYear3NoPat(valuation.year3NoPat)
            setYear3NoPatDisabled(false)
        } else {
            setYear3GrowthRate(valuation.year3GrowthRate)
            setYear3NoPat(null)
            setYear3NoPatDisabled(true)
        }
        if (valuation.year4NoPat !== null) {
            setYear4GrowthRate(null)
            setYear4NoPat(valuation.year4NoPat)
            setYear4NoPatDisabled(false)
        } else {
            setYear4GrowthRate(valuation.year4GrowthRate)
            setYear4NoPat(null)
            setYear4NoPatDisabled(true)
        }
        if (valuation.year5NoPat !== null) {
            setYear5GrowthRate(null)
            setYear5NoPat(valuation.year5NoPat)
            setYear5NoPatDisabled(false)
        } else {
            setYear5GrowthRate(valuation.year5GrowthRate)
            setYear5NoPat(null)
            setYear5NoPatDisabled(true)
        }

        setYear1Reason(valuation.year1Reason)
        setYear2Reason(valuation.year2Reason)
        setYear3Reason(valuation.year3Reason)
        setYear4Reason(valuation.year4Reason)
        setYear5Reason(valuation.year5Reason)

        setYear1Weight(valuation.year1Weight)
        setYear2Weight(valuation.year2Weight)
        setYear3Weight(valuation.year3Weight)
        setYear4Weight(valuation.year4Weight)

        setErrors(false)
        setEditRatios(true)
    }

    const handleSubmitValuation = () => handleUpdateValuation({ submit: 1 })

    const handleFinalizeValuation = () => handleUpdateValuation({ finalize: 1, reason })

    const isCompanyUser = isCompany(user)

    const adminCanEdit = () => allowFinalise && valuation?.status === 1

    const advisorCanEdit = () => !allowFinalise && valuation?.status === 0

    return (
        <Box>
            {valuation && <Box>
                {!viaAdmin && (
                    <Box mt={4}>
                        <BackButton to={isCompanyUser ? `/company/valuations` : `/advisor/company/${valuation.companyId}/valuations`} label='Back to Valuations' />
                    </Box>
                )}
                <Card title="Valuation">
                    <table className={styles.table}>
                        <thead>
                            {adminCanEdit() && <tr>
                                <th colSpan="2">Select Source Financial Periods to include in the Valuation</th>
                                <th>{<Button onClick={() => toggleForecastYear(1)}>{valuation.forecastYear1 ? <span>&nbsp;</span> : 'X'}</Button>}</th>
                                <th>{<Button onClick={() => toggleForecastYear(2)}>{valuation.forecastYear2 ? <span>&nbsp;</span> : 'X'}</Button>}</th>
                                <th>{<Button onClick={() => toggleForecastYear(3)}>{valuation.forecastYear3 ? <span>&nbsp;</span> : 'X'}</Button>}</th>
                                <th colSpan="2">
                                    <SelectField
                                        label='First Year'
                                        name='year'
                                        value={valuation.firstYear}
                                        onChange={setFirstYear}
                                        options={yearsList(4, 1, true)}
                                    />
                                </th>
                            </tr>}
                            <tr className={styles.tableHeader}>
                                <th></th>
                                {[...Array(5).keys()].map(index => <th key={index} className={styles.dataColumn}>{getYearLabel(index)}</th>)}
                                <th className={styles.dataColumn}></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={styles.tableColHeader}>Revenue</td>
                                {columnsList.map((yearIndex) =>
                                    <td key={yearIndex}>
                                        {valuation.financials[yearIndex].revenue !== null ? formatCurrency(valuation.financials[yearIndex].revenue, isoCode, 0) : ''}
                                    </td>)}
                            </tr>
                            <tr>
                                <td className={styles.tableColHeader}>EBIT</td>
                                {columnsList.map((yearIndex) =>
                                    <td key={yearIndex}>
                                        {valuation.financials[yearIndex].ebit !== null ? formatCurrency(valuation.financials[yearIndex].ebit, isoCode, 0) : ''}
                                    </td>)}
                            </tr>
                            <tr>
                                <td className={styles.tableColHeader}>Op. Tax</td>
                                {columnsList.map((yearIndex) =>
                                    <td key={yearIndex}>
                                        {valuation.financials[yearIndex].optax !== null ? formatCurrency(valuation.financials[yearIndex].optax, isoCode, 0) : ''}
                                    </td>)}
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr className={styles.tableFooterTop}>
                                <td className={styles.tableColHeader}>NOPAT</td>
                                {columnsList.map((yearIndex) =>
                                    <td key={yearIndex}>
                                        {valuation.financials[yearIndex].nopat !== null && yearIndex !== 5 ? formatCurrency(valuation.financials[yearIndex].nopat, isoCode, 0) : ''}
                                    </td>)}
                            </tr>
                            <tr>
                                <td className={styles.tableColHeader}>Growth Rate</td>
                                {columnsList.map((yearIndex) =>
                                    <td key={yearIndex}>
                                        {valuation.financials[yearIndex].growthRate !== null ? formatPercent(valuation.financials[yearIndex].growthRate, 0) : ''}
                                    </td>)}
                            </tr>
                            <tr>
                                <td className={styles.tableColHeader}>Weighting</td>
                                {columnsList.map((yearIndex) =>
                                    <td key={yearIndex}>
                                        {!isNaN(valuation[`year${yearIndex}Weight`]) ? formatPercent(valuation[`year${yearIndex}Weight`]) : ''}
                                    </td>)}
                            </tr>
                            <tr className={styles.tableFooterBottom}>
                                <td className={styles.tableColHeader}>Weighted Average of Earnings</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className={styles.bold}>{formatCurrency(valuation.financials[5].nopat, isoCode, 0)}</td>
                            </tr>
                        </tfoot>
                    </table>

                    <Box mt="3">
                        <table className={styles.table}>
                            <thead>
                                <tr className={styles.tableHeader}>
                                    <th colSpan='7'>Enterprise Valuation</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan='2' className={styles.tableSubColHeader}>Cost of Capital</td>
                                    <td className={styles.dataColumn}></td>
                                    <td className={styles.dataColumn}></td>
                                    <td className={styles.dataColumn}></td>
                                    <td className={styles.dataColumn}></td>
                                    <td>{!isNaN(valuation.financials[5].costOfCapital) ? formatPercent(valuation.financials[5].costOfCapital, 0) : ''}</td>
                                </tr>
                                <tr>
                                    <td colSpan='2' className={styles.tableSubColHeader}>Growth Rate</td>
                                    <td className={styles.dataColumn}></td>
                                    <td className={styles.dataColumn}></td>
                                    <td className={styles.dataColumn}></td>
                                    <td className={styles.dataColumn}></td>
                                    <td>{formatPercent(valuation.financials[5].growthRate)}</td>
                                </tr>
                                {!true &&
                                    <>
                                        <tr>
                                            <td colSpan='2' className={styles.tableSubColHeader}>NPV of NOPAT</td>
                                            {columnsList.map((yearIndex) =>
                                                yearIndex > 0 && <td key={yearIndex} className={styles.dataColumn}>
                                                    {!isNaN(valuation.financials[yearIndex].npvOfNopat) ? formatCurrency(valuation.financials[yearIndex].npvOfNopat, isoCode, 0) : ''}
                                                </td>)}
                                        </tr>
                                        <tr>
                                            <td colSpan='2' className={styles.tableSubColHeader}>Cumulative NPV</td>
                                            {columnsList.map((yearIndex) =>
                                                yearIndex > 0 && <td key={yearIndex} className={styles.dataColumn}>
                                                    {!isNaN(valuation.financials[yearIndex].cumulativeNpv) ? formatCurrency(valuation.financials[yearIndex].cumulativeNpv, isoCode, 0) : ''}
                                                </td>)}
                                        </tr>
                                    </>
                                }
                                <tr>
                                    <td colSpan='2' className={styles.tableSubColHeader}>{t('Capitalisation Multiple (intrinsic)')}</td>
                                    <td className={styles.dataColumn}></td>
                                    <td className={styles.dataColumn}></td>
                                    <td className={styles.dataColumn}></td>
                                    <td className={styles.dataColumn}></td>
                                    <td className={styles.dataColumn}>{!isNaN(valuation.financials[5].capitalizationMultipleIntrinsic) ? formatNumber(valuation.financials[5].capitalizationMultipleIntrinsic, 2) : ''}</td>
                                </tr>
                                <tr style={{
                                    opacity: override === null || editOverride ? 1 : '0.3'
                                }}>
                                    <td colSpan='6' className={styles.tableColHeader}>{t('Enterprise Value (Capitalisation of Earnings Method)')}</td>
                                    <td className={styles.bold}>{!isNaN(valuation.financials[5].beyondEnterpriseValueCapitalization) ? formatCurrency(valuation.financials[5].beyondEnterpriseValueCapitalization, isoCode, 0) : ''}</td>
                                </tr>
                                <tr>
                                    <td colSpan="5" className={styles.tableColHeader}>
                                        <Flex sx={{ alignItems: 'center', gap: '8px' }}>
                                            {t('Enterprise Value Override')}
                                            <Tooltip
                                                text="Allows the enterprise value to be overridden with a custom value."
                                                width="182px"
                                                boxSx={{ display: 'block', maxHeight: '20px' }}
                                            >
                                                <Icon icon="questionMark3" size="16px" />
                                            </Tooltip>
                                        </Flex>
                                    </td>
                                    <td colSpan="2" className={styles.bold}>
                                        {allowFinalise ? (editOverride ? (
                                            <Flex sx={{ gap: '8px', width: '100%', justifyContent: 'right' }}>
                                                <TextField
                                                    value={override}
                                                    onChange={(value) => {
                                                        setOverride(value)
                                                    }}
                                                    sx={{
                                                        m: 0,
                                                        p: '8px 16px',
                                                    }}
                                                />
                                                <Button
                                                    onClick={() => handleSetOverride()}
                                                    variant="primarySmall"
                                                >
                                                    {t('Save')}
                                                </Button>
                                            </Flex>
                                         ) : (
                                            override === null ? (
                                                <Button onClick={() => setEditOverride(true)} variant="transparentBorder2" inline={true}>{t('Override Valuation')}</Button>
                                            ) : (
                                                <Flex sx={{ gap: '8px', justifyContent: 'flex-end' }}>
                                                    <Button
                                                        onClick={() => setEditOverride(true)}
                                                        variant="iconCircle"
                                                        sx={{
                                                            bg: '#E5E9EB',
                                                            'svg': { transition: 'stroke 0.3s ease' },
                                                            '&:hover svg': { stroke: '#fff' }
                                                        }}
                                                        icon="edit"
                                                        iconProps={{ size: '15px', stroke: '#6F7782' }}
                                                        inline={true}
                                                    />
                                                    <Button
                                                        onClick={handleDeleteOverride}
                                                        variant="iconCircle"
                                                        sx={{
                                                            bg: '#E5E9EB',
                                                            'svg': { transition: 'stroke 0.3s ease' },
                                                            '&:hover svg': { stroke: '#fff' }
                                                        }}
                                                        icon="trash"
                                                        iconProps={{ size: '15px', stroke: '#6F7782' }}
                                                        inline={true}
                                                    />
                                                    {!isNaN(override) && formatCurrency(override, isoCode, 0)}
                                                </Flex>
                                            )
                                        )) : (
                                            !isNaN(override) && formatCurrency(override, isoCode, 0)
                                        )}
                                    </td>
                                </tr>
                                <tr className={styles.tableHeader}>
                                    <th colSpan='7'>Equity Valuation</th>
                                </tr>
                                <tr>
                                    <td colSpan='6' className={styles.tableSubColHeader}>Add: Cash & Cash Equivalents</td>
                                    <td>{formatCurrency(valuation.cashAndEquivalents, isoCode, 0)}</td>
                                </tr>
                                <tr>
                                    <td colSpan='6' className={styles.tableSubColHeader}>Add: Financial Assets</td>
                                    <td>{formatCurrency(valuation.financialAssets, isoCode, 0)}</td>
                                </tr>
                                <tr>
                                    <td colSpan='6' className={styles.tableSubColHeader}>Add: Non-operating Assets (land & buildings, vacant land)</td>
                                    <td>{formatCurrency(valuation.nonOperatingAssets, isoCode, 0)}</td>
                                </tr>
                                <tr>
                                    <td colSpan='6' className={styles.tableSubColHeader}>Add: Loans to directors / related parties</td>
                                    <td>{formatCurrency(valuation.loanToDirectors, isoCode, 0)}</td>
                                </tr>
                                <tr>
                                    <td colSpan='6' className={styles.tableSubColHeader}>Add: Other Surplus Assets (artworks, personal use assets (cars etc.)</td>
                                    <td>{formatCurrency(valuation.surplusAssets, isoCode, 0)}</td>
                                </tr>
                                <tr>
                                    <td colSpan='6' className={styles.tableSubColHeader}>Less: Short-term Debt</td>
                                    <td>{formatCurrency(valuation.shortTermDebt, isoCode, 0)}</td>
                                </tr>
                                <tr>
                                    <td colSpan='6' className={styles.tableSubColHeader}>Less: Long-term Debt</td>
                                    <td>{formatCurrency(valuation.longTermDebt, isoCode, 0)}</td>
                                </tr>
                                <tr>
                                    <td colSpan='6' className={styles.tableSubColHeader}>Less: Loans from Directors / Related Parties</td>
                                    <td>{formatCurrency(valuation.loanFromDirectors, isoCode, 0)}</td>
                                </tr>
                                <tr>
                                    <td colSpan='6' className={styles.tableColHeader}>Equity Value (Capitalisation of Earnings Method)</td>
                                    <td className={styles.bold}>{!isNaN(beyondFmpValuation) ? formatCurrency(beyondFmpValuation, isoCode, 0) : ''}</td>
                                </tr>
                                <tr>
                                    <td colSpan='6' className={styles.tableSubColHeader}>Number of Shares Issued</td>
                                    <td>{formatNumber(valuation.numberOfShares)}</td>
                                </tr>
                                <tr>
                                    <td colSpan='6' className={styles.tableSubColHeader}>{t("Owner's Value per Share")}</td>
                                    <td>{!isNaN(beyondFmpValuation) ? formatCurrency(valuation.numberOfShares ? beyondFmpValuation / valuation.numberOfShares : 0, isoCode) : ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Box>

                    {!isCompanyUser && <>
                        <Box mt="3">
                            {adminCanEdit() && <AreaField value={reason} onChange={val => setReason(val)} label="Reason for changes" errors={errors['reason']} />}
                            {!adminCanEdit() && valuation.reason && <Box>
                                <Heading mb={3}>Reason</Heading>
                                <Box ml={3}>{valuation.reason}</Box>
                            </Box>}
                        </Box>

                        <Box mt="3" textAlign="right">
                            {(adminCanEdit() || advisorCanEdit()) &&
                                <Button variant='secondary' onClick={() => handleEditRatiosClick()} mr='2'>
                                    Edit Ratios
                                </Button>}
                            {advisorCanEdit() && <Button variant='primary' onClick={() => handleSubmitValuation()}>
                                Submit Valuation for Review
                            </Button>}
                            {adminCanEdit() && allowFinalise && <Button variant='primary' onClick={() => handleFinalizeValuation()}>
                                Finalize Valuation
                            </Button>}
                        </Box>
                    </>}
                </Card>
            </Box>}

            {editRatios && (
                <Modal
                    isOpen={true}
                    maxWidth={1000}
                    minWidth={500}
                >
                    <Box>
                        <Flex>
                            <Heading pb={4}>Specify NOPAT Growth Rates</Heading>
                            <Box ml='auto'>
                                <Box variant='clickable' p={20} m='auto' onClick={() => setEditRatios(false)} pt={2}>
                                    <Icon icon="close" stroke='#656D78' title='Close Popup' />
                                </Box>
                            </Box>
                        </Flex>
                        <Box>
                            <table className={styles.table}>
                                <thead>
                                    <tr className={styles.tableHeader}>
                                        <th width="15%"></th>
                                        <th width="15%">Custom Growth Rate (%)</th>
                                        <th width="15%">Effective Growth Rate from Source Financials</th>
                                        <th width="15%">Custom NOPAT</th>
                                        <th width="15%">Weight (%)</th>
                                        <th width="20%">Reasoning for Values Entered</th>
                                        <th width="5%"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className={styles.tableColHeader}>{getYearLabel(1)}</td>
                                        <td onClick={() => setYear1NoPatDisabled(true)}><TextField disabled={!allowFinalise || !year1NoPatDisabled} placeholder={(allowFinalise && !year1NoPatDisabled) ? 'Click to Edit' : undefined} name='year1GrowthRate' value={year1GrowthRate} onChange={(v) => setYear1GrowthRate(v)} errors={errors['year1GrowthRate']} /></td>
                                        <td><TextField disabled name='year1ActualGrowthRate' value={formatPercent(valuation.financials[1].effectiveGrowthRate)} /></td>
                                        <td onClick={() => setYear1NoPatDisabled(false)}><TextField disabled={!allowFinalise || year1NoPatDisabled} placeholder={(allowFinalise && year1NoPatDisabled) ? 'Click to Edit' : undefined} name='year1NoPat' value={year1NoPat} onChange={(v) => setYear1NoPat(v)} errors={errors['year1NoPat']} /></td>
                                        <td><TextField disabled={!allowFinalise} name='year1Weight' value={year1Weight} onChange={(v) => setYear1Weight(v)} errors={errors['year1Weight']} /></td>
                                        <td><TextField disabled={!allowFinalise || (!year1NoPat && !year1GrowthRate)} name='year1Reason' value={year1Reason} onChange={(v) => setYear1Reason(v)} errors={errors['year1Reason']} /></td>
                                        <th><Icon icon="comment" /></th>
                                    </tr>
                                    <tr>
                                        <td className={styles.tableColHeader}>{getYearLabel(2)}</td>
                                        <td onClick={() => setYear2NoPatDisabled(true)}><TextField disabled={!allowFinalise || !year2NoPatDisabled} placeholder={(allowFinalise && !year2NoPatDisabled) ? 'Click to Edit' : undefined} name='year2GrowthRate' value={year2GrowthRate} onChange={(v) => setYear2GrowthRate(v)} errors={errors['year2GrowthRate']} /></td>
                                        <td><TextField disabled name='year2ActualGrowthRate' value={formatPercent(valuation.financials[2].effectiveGrowthRate)} /></td>
                                        <td onClick={() => setYear2NoPatDisabled(false)}><TextField disabled={!allowFinalise || year2NoPatDisabled} placeholder={(allowFinalise && year2NoPatDisabled) ? 'Click to Edit' : undefined} name='year2NoPat' value={year2NoPat} onChange={(v) => setYear2NoPat(v)} errors={errors['year2NoPat']} /></td>
                                        <td><TextField disabled={!allowFinalise} name='year2Weight' value={year2Weight} onChange={(v) => setYear2Weight(v)} errors={errors['year2Weight']} /></td>
                                        <td><TextField disabled={!allowFinalise || (!year2NoPat && !year2GrowthRate)} name='year2Reason' value={year2Reason} onChange={(v) => setYear2Reason(v)} errors={errors['year2Reason']} /></td>
                                        <th><Icon icon="comment" /></th>
                                    </tr>
                                    <tr>
                                        <td className={styles.tableColHeader}>{getYearLabel(3)}</td>
                                        <td onClick={() => setYear3NoPatDisabled(true)}><TextField disabled={!allowFinalise || !year3NoPatDisabled} placeholder={(allowFinalise && !year3NoPatDisabled) ? 'Click to Edit' : undefined} name='year3GrowthRate' value={year3GrowthRate} onChange={(v) => setYear3GrowthRate(v)} errors={errors['year3GrowthRate']} /></td>
                                        <td><TextField disabled name='year3ActualGrowthRate' value={formatPercent(valuation.financials[3].effectiveGrowthRate)} /></td>
                                        <td onClick={() => setYear3NoPatDisabled(false)}><TextField disabled={!allowFinalise || year3NoPatDisabled} placeholder={(allowFinalise && year3NoPatDisabled) ? 'Click to Edit' : undefined} name='year3NoPat' value={year3NoPat} onChange={(v) => setYear3NoPat(v)} errors={errors['year3NoPat']} /></td>
                                        <td><TextField disabled={!allowFinalise} name='year3Weight' value={year3Weight} onChange={(v) => setYear3Weight(v)} errors={errors['year3Weight']} /></td>
                                        <td><TextField disabled={!allowFinalise || (!year3NoPat && !year3GrowthRate)} name='year3Reason' value={year3Reason} onChange={(v) => setYear3Reason(v)} errors={errors['year3Reason']} /></td>
                                        <th><Icon icon="comment" /></th>
                                    </tr>
                                    <tr>
                                        <td className={styles.tableColHeader}>Forecasting Year 4</td>
                                        <td onClick={() => setYear4NoPatDisabled(true)}><TextField disabled={!year4NoPatDisabled} placeholder={!year4NoPatDisabled ? 'Click to Edit' : undefined} name='year4GrowthRate' value={year4GrowthRate} onChange={(v) => setYear4GrowthRate(v)} errors={errors['year4GrowthRate']} /></td>
                                        <td><TextField disabled name='year4ActualGrowthRate' value={formatPercent(valuation.financials[4].effectiveGrowthRate)} /></td>
                                        <td onClick={() => setYear4NoPatDisabled(false)}><TextField disabled={year4NoPatDisabled} placeholder={year4NoPatDisabled ? 'Click to Edit' : undefined} name='year4NoPat' value={year4NoPat} onChange={(v) => setYear4NoPat(v)} errors={errors['year4NoPat']} /></td>
                                        <td><TextField disabled={!allowFinalise} name='year4Weight' value={year4Weight} onChange={(v) => setYear4Weight(v)} errors={errors['year4Weight']} /></td>
                                        <td><TextField disabled={!year4NoPat && !year4GrowthRate} name='year4Reason' value={year4Reason} onChange={(v) => setYear4Reason(v)} errors={errors['year4Reason']} /></td>
                                        <th><Icon icon="comment" /></th>
                                    </tr>
                                    <tr>
                                        <td className={styles.tableColHeader}>Beyond Period</td>
                                        <td onClick={() => setYear5NoPatDisabled(true)}><TextField disabled={!allowFinalise || !year5NoPatDisabled} placeholder={(allowFinalise && !year5NoPatDisabled) ? 'Click to Edit' : undefined} name='year5GrowthRate' value={year5GrowthRate} onChange={(v) => setYear5GrowthRate(v)} errors={errors['year5GrowthRate']} /></td>
                                        <td><TextField disabled name='year5ActualGrowthRate' value={formatPercent(valuation.financials[5].effectiveGrowthRate)} /></td>
                                        <td onClick={() => setYear5NoPatDisabled(false)}><TextField disabled={!allowFinalise || year5NoPatDisabled} placeholder={(allowFinalise && year5NoPatDisabled) ? 'Click to Edit' : undefined} name='year5NoPat' value={year5NoPat} onChange={(v) => setYear5NoPat(v)} errors={errors['year5NoPat']} /></td>
                                        <td></td>
                                        <td><TextField disabled={!allowFinalise || (!year5NoPat && !year5GrowthRate)} name='year5Reason' value={year5Reason} onChange={(v) => setYear5Reason(v)} errors={errors['year5Reason']} /></td>
                                        <th><Icon icon="comment" /></th>
                                    </tr>
                                </tbody>
                            </table>
                        </Box>
                        <Box textAlign='right' mt={2}>
                            <Button variant='secondary' onClick={() => setEditRatios(false)} mr='2'>
                                Cancel
                            </Button>
                            <Button variant='primary' onClick={() => updateRatios()}>
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Modal>)}
        </Box>
    )
}

export default connect((state) => ({ user: state.user, companies: state.companies }))(AdvisorValuationEditV202110);
