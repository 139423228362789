import React, { useContext } from 'react';
import { CompanyContextData } from 'Context/Company';
import AdvisorValuationEditV202107 from './V202107';
import AdvisorValuationEditV202110 from './V202110';

const AdvisorValuationEdit = ({ valuationId, allowFullEdits, viaAdmin }) => {
    const company = useContext(CompanyContextData);

    switch (company?.versions?.valuations) {
        case 'V202107':
            return <AdvisorValuationEditV202107 valuationId={valuationId} allowFullEdits={allowFullEdits} viaAdmin={viaAdmin} />;
        default:
            return <AdvisorValuationEditV202110 valuationId={valuationId} allowFullEdits={allowFullEdits} viaAdmin={viaAdmin} />
    }
};

export default AdvisorValuationEdit;
