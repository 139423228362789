import React from 'react'
import { useParams } from 'react-router-dom'
import SettingsRender from '~/Dashboard/Settings'

const AdvisorSettings = () => {
    const { page } = useParams();
    return <SettingsRender nested={true} page={page} show={{ user: true, company: false }} />
}

export default AdvisorSettings
