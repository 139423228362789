import React, { useState, useEffect } from 'react';
import LoadingSpinner from '~/LoadingSpinner';
import { getStages } from '@app/Api';
import { toast } from 'react-toastify';
import { setStages, setUserCompanyStatus } from '@app/Store';
import { connect } from 'react-redux';
import CompanyStages from '~/Dashboard/CompanyStages';
import { useHistory } from 'react-router-dom';
import { getErrorMessage } from '@util/Functions';

const Onboarding = ({ user, stages, setStages, setUserCompanyStatus, title, companyId }) => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        getStages(companyId)
            .then(({ stages, companyStatus }) => {
                setLoading(false);
                setStages(stages);
                if (companyStatus >= 0 && user.companyStatus >= 0 && companyStatus !== user.companyStatus) {
                    setUserCompanyStatus(companyStatus);
                    if (companyStatus > 1) {
                        toast.success('Onboarding is now complete!');
                        history.push('/company/dashboard');
                    } else {
                        toast.warn('Onboarding is incomplete!');
                        history.push('/company/onboarding');
                    }
                }
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to load onboarding data'), { toastId: 'getStagesError' }));
    }, [setStages, history, setUserCompanyStatus, user.companyStatus, companyId]);

    if (loading) return <LoadingSpinner />;

    return <CompanyStages stages={stages} title={title} companyId={companyId} />;
};

export default connect((state) => ({ stages: state.stages, user: state.user }), { setStages, setUserCompanyStatus })(
    Onboarding
);
