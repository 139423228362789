import React from 'react';
import Tooltip from '~/Common/Tooltip';

const notAvailableMessages = {
    not_available: 'This data is not available yet.',
}

export const NotAvailableTooltip = ({
    children = 'N/A',
    type = 'not_available',
    width = '200px',
    ...props
}) => (
    <Tooltip
        width={width}
        text={notAvailableMessages[type]}
        {...props}
    >
        {children}
    </Tooltip>
)
