import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { Box, Flex, Link, Heading, Button } from 'rebass';
import { Icon } from '@svg/Icon';
import { SelectField, TextAreaField, TextField } from '~/Form';
import styles from './style.module.scss';
import { Form } from '~/Common/Form';
import { getErrorMessage } from '@util/Functions';
import { toast } from 'react-toastify';
import DateField from '~/Form/DateField';
import { BackButton } from '~/Common/BackButton';
import { createActionItem, getActionItems, updateActionItem } from '@app/Api/Company';
import { setActionItems } from '@app/Store/storeActionItems';
import { actionItemStatuses } from '@util/Enum/ActionItemStatus';
import { useIsMounted } from '@hooks/useIsMounted';
import LoadingSpinner from '~/LoadingSpinner';

const CreateActionItemModal = ({
    setActionItems,
    selectedItem,
    isOpen,
    requestClose,
    currentCompanyId,
    companyId,
    companyTasks,
    assignees,
    status,
    onEditItem,
    onCreateItem,
}) => {
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [projectName, setProjectName] = useState('');
    const isMounted = useIsMounted();

    useEffect(() => {
        setProjectName(selectedItem?.companyTask?.name ?? 'No project selected');
    }, [selectedItem]);

    useEffect(() => {
        // Only called if companyId is set via props
        if (isOpen && companyId && (status === 'idle' || currentCompanyId !== companyId) && !isLoading) {
            setLoading(true);
            getActionItems(companyId)
                .then(({ data }) => {
                    if (isMounted.current) {
                        setActionItems({
                            status: 'complete',
                            currentCompanyId: companyId,
                            ...data,
                        });
                    }
                })
                .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to get company action items')))
                .finally(() => {
                    if (isMounted.current) {
                        setLoading(false)
                    }
                });
        }
    }, [companyId, isOpen, status, isLoading, isMounted, setActionItems, currentCompanyId]);

    const tasksGrouped = !companyTasks ? [] : companyTasks.reduce(
        (tasks, task) => ({
            ...tasks,
            [task.stageName]: [
                ...(tasks[task.stageName] ?? []),
                {
                    value: task.id,
                    label: task.name,
                },
            ],
        }),
        {}
    );

    const steps = [
        {
            title: 'What project does this action item relate to?',
            subtitle: 'This action item will need to be completed before the project can be marked complete.',
            fieldNames: ['companyTaskId'],
            fields: (
                <>
                    <SelectField
                        placeholder="Select related project"
                        name="companyTaskId"
                        label="Project"
                        boxSx={{ width: '100%' }}
                        errors={errors?.companyTaskId}
                        grouped={true}
                        defaultValue={selectedItem?.companyTask?.id ?? ''}
                        options={tasksGrouped}
                        onChange={(val, name, e) => setProjectName(e.target.options[e.target.selectedIndex].text)}
                    />
                </>
            ),
        },
        {
            title: 'Who would you like to assign this action item to?',
            fieldNames: ['assigneeId'],
            fields: (
                <>
                    <SelectField
                        placeholder="Select assignee"
                        name="assigneeId"
                        label="Assignee"
                        boxSx={{ width: '100%' }}
                        errors={errors?.assigneeId}
                        defaultValue={selectedItem?.assignee?.id ?? ''}
                        options={Array.isArray(assignees) ? assignees.map((user) => ({
                            value: user.id,
                            label: user?.displayName,
                        })) : []}
                        isLoading={!Array.isArray(assignees)}
                    />
                </>
            ),
        },
        {
            title: 'What is the title of this action item?',
            subtitle: 'Provide context on what action is required.',
            fieldNames: ['task', 'due_date'],
            fields: (
                <>
                    <TextField
                        readOnly={true}
                        label="Project"
                        value={projectName}
                        boxSx={{ width: '100%', pointerEvents: 'none' }}
                    />
                    <TextField
                        placeholder="What action is required?"
                        name="task"
                        label="Action"
                        boxSx={{ width: '100%' }}
                        defaultValue={selectedItem?.task ?? ''}
                        errors={errors?.task}
                    />
                    <DateField
                        placeholder="Due Date"
                        name="dueDate"
                        label="Due Date"
                        boxSx={{ width: '100%' }}
                        value={selectedItem?.dueDate ?? ''}
                        errors={errors?.dueDate}
                    />
                    {selectedItem?.id && (
                        <SelectField
                            placeholder="Status"
                            name="status"
                            label="Status"
                            boxSx={{ width: '100%' }}
                            errors={errors?.status}
                            defaultValue={selectedItem?.status ?? ''}
                            options={Object.values(actionItemStatuses)}
                        />
                    )}
                </>
            ),
        },
        {
            title: 'Are there any additional notes for this action item?',
            fieldNames: ['notes'],
            nextText: `${selectedItem?.id ? 'Update' : 'Create'} action item`,
            submit: true,
            fields: (
                <>
                    <TextAreaField
                        resize={false}
                        rows={5}
                        placeholder="Notes"
                        name="notes"
                        label="Notes"
                        boxSx={{ width: '100%' }}
                        defaultValue={selectedItem?.notes ?? ''}
                        errors={errors?.notes}
                    />
                </>
            ),
        },
    ];

    const gotoErrorStep = (errors) => {
        const errorFields = Object.keys(errors ?? {});
        const stepIndex = steps.findIndex((step) => {
            return step.fieldNames.some((field) => errorFields.includes(field));
        });

        setActiveStep(stepIndex >= 0 ? stepIndex : 0);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);

        if (selectedItem?.id) {
            updateActionItem(companyId ?? currentCompanyId, selectedItem.id, {
                companyTaskId: data.get('companyTaskId'),
                assigneeId: data.get('assigneeId'),
                task: data.get('task'),
                dueDate: data.get('dueDate'),
                status: data.get('status'),
                notes: data.get('notes'),
            })
                .then(({ data }) => {
                    setActiveStep(0);
                    setActionItems({
                        all: {
                            status: 'idle',
                        },
                        ...data
                    });
                    if (typeof onEditItem === 'function') {
                        onEditItem();
                    }
                    requestClose();
                    toast.success('Action item has been updated successfully');
                })
                .catch(({ response }) => {
                    response?.data?.errors && setErrors(response.data.errors);
                    gotoErrorStep(response?.data?.errors);
                    toast.error(
                        getErrorMessage(response?.data, 'Something went wrong while updating the action item'),
                        {
                            toastId: 'updateActionItem',
                        }
                    );
                });
        } else {
            createActionItem(companyId ?? currentCompanyId, {
                companyTaskId: data.get('companyTaskId'),
                assigneeId: data.get('assigneeId'),
                task: data.get('task'),
                dueDate: data.get('dueDate'),
                status: 1,
                notes: data.get('notes'),
            })
                .then(({ data }) => {
                    setActiveStep(0);
                    setActionItems(data);
                    if (typeof onCreateItem === 'function') {
                        onCreateItem();
                    }
                    requestClose();
                    toast.success('Action item has been created successfully');
                })
                .catch(({ response }) => {
                    response?.data?.errors && setErrors(response.data.errors);
                    gotoErrorStep(response?.data?.errors);
                    toast.error(
                        getErrorMessage(response?.data, 'Something went wrong while creating the action item.'),
                        {
                            toastId: 'addActionItem',
                        }
                    );
                });
        }
    };

    const handleBack = (e) => {
        if (activeStep > 0) {
            e.preventDefault();
            setActiveStep((index) => index - 1);
        }
    };

    const handleNext = (e) => {
        if (activeStep < steps.length - 1) {
            e.preventDefault();
            setActiveStep((index) => index + 1);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            ariaHideApp={false}
            style={{
                overlay: {
                    zIndex: 999,
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: 531,
                    width: '100%',
                    display: 'flex',
                    background: '#F5F7F8',
                    borderRadius: '16px',
                    border: 'none',
                    padding: 0,
                    boxShadow: '0px 0px 34px -9px #48484861',
                    overflow: 'hidden',
                },
            }}
        >
            {isLoading ? <LoadingSpinner /> : (
                <Flex flexGrow="1" flexDirection="column" justifyContent="space-between">
                    <Flex sx={{ bg: '#fff' }}>
                        <Box p="14px">
                            <Box
                                sx={{
                                    bg: '#D1F5FF',
                                    borderRadius: '20px',
                                    padding: '4px 10px',
                                    fontWeight: 600,
                                    fontSize: '9px',
                                    lineHeight: '10px',
                                    letterSpacing: '0.5px',
                                }}
                            >
                                {activeStep + 1} / {steps.length}
                            </Box>
                        </Box>
                        <Box ml="auto" p={2}>
                            <Link
                                as="a"
                                href="#close"
                                variant="circleButton"
                                onClick={() => {
                                    requestClose();
                                    setActiveStep(0);
                                }}
                            >
                                <Icon icon="close" stroke="#656D78" title="Close Popup" />
                            </Link>
                        </Box>
                    </Flex>
                    <Form onSubmit={handleSubmit} sx={{ position: 'relative', paddingTop: '30px' }}>
                        <BackButton
                            onClick={handleBack}
                            wsx={{
                                opacity: activeStep > 0 ? 1 : 0,
                                pointerEvents: activeStep > 0 ? 'all' : 'none',
                                m: 0,
                                position: 'absolute',
                                zIndex: 999,
                                padding: '10px',
                                top: 0,
                            }}
                            label="Back"
                        />
                        <Flex
                            sx={{
                                width: `${steps.length * 100}%`,
                                transform: `translateX(-${activeStep * (100 / steps.length)}%)`,
                                transition: 'transform 0.4s ease-in-out',
                            }}
                        >
                            {steps.map((step, index) => (
                                <Flex
                                    key={index}
                                    className={index === activeStep ? styles.activeStep : styles.step}
                                    width="100%"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Heading textAlign="center" mb={2} sx={{ fontSize: '16px', color: '#413E70' }}>
                                        {step.title}
                                    </Heading>
                                    <Box
                                        textAlign="center"
                                        mb={4}
                                        sx={{
                                            fontSize: '14px',
                                            color: '#6F7782',
                                        }}
                                    >
                                        {step.subtitle}
                                    </Box>
                                    {step.fields}
                                    <Button
                                        type={step?.submit ? 'submit' : 'button'}
                                        onClick={handleNext}
                                        variant="primary"
                                        mt={4}
                                        mb={4}
                                        sx={{ lineHeight: 1, minWidth: '130px' }}
                                    >
                                        {step.nextText ?? 'Next'}
                                    </Button>
                                </Flex>
                            ))}
                        </Flex>
                    </Form>
                    <Flex
                        sx={{
                            gap: 3,
                            width: '100%',
                            padding: '24px',
                        }}
                    >
                        {steps.map((step, index) => (
                            <Box
                                key={index}
                                sx={{
                                    flex: 1,
                                    bg: activeStep === index ? '#19CEFF' : '#E5E9EB',
                                    borderRadius: '16px',
                                    height: '4px',
                                    transition: 'background 0.3s ease-in-out',
                                    cursor: 'pointer',
                                }}
                                onClick={() => setActiveStep(index)}
                            />
                        ))}
                    </Flex>
                </Flex>
            )}
        </Modal>
    );
};

export default connect((state) => state.actionItems, { setActionItems })(CreateActionItemModal);
