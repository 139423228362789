import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Flex } from 'rebass';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@svg';
import { Button } from '../Button';

export const CardItem = ({ title, titleSx, children, variant = 'card.item', sx, to, flex = true, bg, flexDirection, height = '87px', ...props }) => {
    const hasLink = !!to;

    return (
        <Box
            variant={variant}
            as={hasLink ? RouterLink : Box}
            to={to}
            sx={{
                display: flex ? 'flex' : 'block',
                flexDirection,
                height,
                bg,
                ...sx,
            }}
            {...props}
        >
            {title && (
                <Box className="card-item--title" sx={titleSx}>
                    {title}
                    {hasLink ? <Icon icon="link" className="link--icon" size="13px" ml="12px" color="#19CEFF" /> : null}
                </Box>
            )}
            {children}
        </Box>
    );
};

export const Card = ({
    title,
    subtitle,
    children,
    variant = 'card.wrapper',
    sx,
    groupSx,
    titleSx,
    grid,
    columns = 3,
    gapless = false,
    icon,
    iconColor,
    iconSize,
    collapsable = false,
    collapse = true,
    maxHeight = null,
    borderRadius,
    subtitleSx,
    containerProps,
    ...props
}) => {
    const containerRef = useRef(null);
    const [collapsed, setCollapsed] = useState(collapse && collapsable);
    const [_maxHeight, setMaxHeight] = useState(maxHeight);
    const showHeader = title || collapsable || icon || subtitle;

    const ContainerWrapper = collapsable ? props => <Box sx={containerStyles} {...props} /> : Fragment;

    const onResize = useCallback(() => {
        setMaxHeight(maxHeight === null ? containerRef.current?.clientHeight : maxHeight);
    }, [maxHeight]);

    const toggleCollapsed = () => {
        onResize();
        setCollapsed((c) => !c);
    }

    const styles = grid
        ? {
            display: 'grid',
            gridTemplate: `auto / repeat(${columns}, 1fr)`,
            gridGap: '24px',
        }
        : {};

    useEffect(() => {
        onResize();
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, [onResize]);

    var containerStyles = {};

    if (collapsable) {
        containerStyles = {
            ...containerStyles,
            minHeight: 0,
            transition: 'max-height 0.3s ease-in-out',
            overflow: 'hidden',
            maxHeight: collapsed ? 0 : _maxHeight
        }
    }

    return (
        <Box
            variant={variant}
            sx={{
                borderRadius,
                ...sx,
            }}
            {...props}
        >
            {showHeader && (
                <Flex
                    variant="card.header"
                    sx={{
                        alignItems: 'center',
                        borderRadius: collapsed ? '24px' : undefined,
                        transition: `border-radius ${collapsed ? '0.05s 0.25s' : '0.05s'} ease-in-out`,
                        ...titleSx,
                    }}
                >
                    {icon && (
                        <Flex alignItems="center" sx={{ mr: 2 }}>
                            {typeof icon === 'string' ? <Icon icon={icon} color={iconColor ?? '#9594AF'} size={iconSize} /> : icon}
                        </Flex>
                    )}
                    {title}
                    {subtitle && (
                        <Box sx={{ fontSize: '12px', color: 'blueDark2', ml: 3, fontWeight: 400, ...subtitleSx }}>{subtitle}</Box>
                    )}
                    {collapsable && (
                        <Flex sx={{ flex: 1, justifyContent: 'right' }}>
                            <Button
                                variant="transparent"
                                icon="arrow"
                                iconProps={{ sx: { transform: `rotate(${collapsed ? 90 : -90}deg)` } }}
                                onClick={toggleCollapsed}
                            />
                        </Flex>
                    )}
                </Flex>
            )}
            <ContainerWrapper>
                <Box
                    ref={containerRef}
                    variant={`card.container${gapless ? 'Gapless' : ''}`}
                    sx={{
                        ...styles,
                        ...groupSx,
                    }}
                    {...containerProps}
                >
                    {children}
                </Box>
            </ContainerWrapper>
        </Box>
    );
};
