import React from 'react';
import { Icon } from '@svg';
import { Box, Flex, Heading, Text } from 'rebass';
import { PageTitle } from '~/Common/PageTitle';
import { BackButton } from '~/Common/BackButton';
import { formatNumber, isCompany } from '@util/Functions';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";

const StandardScorecardView = ({ scorecard, user }) => {
    const { t } = useTranslation();
    const calculateResult = (groups) => groups.reduce((total, group) => (group?.result && (group?.applicable ?? true) ? total + 1 : total), 0);

    const calculateResultColor = (score, total) => {
        if (total === 0) return '#2C2960';
        const result = total ? score / total : 0;
        if (result > 0.75) return '#C8E900';
        if (result > 0.25) return '#EBC02C';
        return '#D74254';
    };

    const calculateFinalResultColor =
        scorecard?.finalResult?.score ?
            (scorecard.finalResult.score > 66.6 ? '#C8E900' : scorecard.finalResult.score > 33.2 ? '#EBC02C' : '#D74254') :
            '#2C2960';

    const scorecardResultStyle = {
        border: `15px solid ${calculateFinalResultColor}`,
        borderRadius: '50%',
        height: '200px',
        width: '200px',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const isCompanyUser = isCompany(user)

    return (
        <Box>
            <PageTitle title={`${scorecard?.type?.name} Scorecard`} />
            <Box mt={4}>
                <BackButton to={isCompanyUser ? `/company/scorecards` : `/advisor/company/${scorecard.companyId}/scorecards`} label="Back to Scorecards" pl={3} />
            </Box>

            <Flex mt={3}>
                <Box width={1 / 5} textAlign="center">
                    <Flex fontSize="80px" sx={scorecardResultStyle} mx="auto">
                        <Text sx={{ position: 'relative', top: '-2px' }}>{scorecard?.finalResult?.label || 'N/A'}</Text>
                    </Flex>
                </Box>

                <Flex width={4 / 5} flexWrap="wrap">
                    {scorecard.result?.map(({ name, groups }, sectionId) => {
                        const score = calculateResult(groups);
                        const total = groups.filter(group => group?.applicable ?? true).length;
                        const bgColor = calculateResultColor(score, total);
                        return (
                            <Box key={sectionId} p={3} width={1 / 3}>
                                <Flex mb={2} alignItems="center">
                                    <Heading
                                        sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                                        fontSize={14}
                                    >
                                        {name}
                                    </Heading>
                                    <Box
                                        ml="auto"
                                        fontSize={12}
                                        fontWeight="700"
                                        textAlign="center"
                                        px="15px"
                                        py="5px"
                                        color={['#D74254', '#2C2960'].includes(bgColor) ? 'white' : 'black'}
                                        backgroundColor={bgColor}
                                        sx={{ whiteSpace: 'nowrap', borderRadius: '10px' }}
                                    >
                                        {score} / {total}
                                    </Box>
                                </Flex>
                                {groups.map(({ name: groupName, result, value, target }, groupId) => {
                                    const hasResult = target !== null && target !== undefined
                                    return <Flex
                                        variant="card.wrapper"
                                        alignItems="center"
                                        mt="5px"
                                        mx="0"
                                        mb={2}
                                        key={groupId}
                                        py={3}
                                    >
                                        <Flex
                                            width={1 / 5}
                                            textAlign="center"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            {hasResult ?
                                                <Box>
                                                    {result ? <Icon icon="completedYes" title="Group Completed" size={25} /> :
                                                        <Icon icon="completedNo" title="Group Incomplete" size={25} />}
                                                </Box> :
                                                <Box as='p' sx={{ fontSize: 12 }}>N/A</Box>
                                            }
                                        </Flex>
                                        <Box width={4 / 5} fontSize={12}>
                                            <Box sx={{ fontWeight: 'bold' }} fontSize={14}>
                                                {t(groupName)}
                                            </Box>
                                            {hasResult ? <Flex alignItems="center" mt={1}>
                                                <Flex alignItems="center">
                                                    <b>Target</b>: {target}
                                                </Flex>
                                                <Flex alignItems="center" ml={2}>
                                                    <b>Result</b>: {typeof value === "number" ? formatNumber(value, 0) : value}
                                                </Flex>
                                            </Flex> : <b>Not Applicable</b>}
                                        </Box>
                                    </Flex>
                                })}
                            </Box>
                        );
                    })}
                    {scorecard.comments?.length > 0 && (
                        <Box flex="1 1 100%" p={3}>
                            <Heading mb={3}>Comments</Heading>
                            {scorecard.comments.map((comment, index) => (
                                <Box key={index}>{comment}</Box>
                            ))}
                        </Box>
                    )}
                </Flex>
            </Flex>
        </Box>
    );
};

export default connect((state) => ({ user: state.user }))(StandardScorecardView)
