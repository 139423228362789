import React, { useState } from 'react';
import { Text, Flex, Box } from 'rebass';
import { downloadAttachment } from '@util/Functions';
import styles from './styles.module.scss';
import cx from 'classnames';
import { Icon } from '@svg';
import { PageTitle } from '~/Common/PageTitle';
import { Card } from '~/Common/Card';

const OnboardingStages = ({ stages, documents }) => {
    const [stageDocuments, setStageDocuments] = useState(null);
    const [activeStageId, setActiveStageId] = useState(null);
    var taskName = null;

    const getDocuments = (companyStageId) => {
        setActiveStageId(companyStageId);
        setStageDocuments(documents.filter((doc) => doc.companyStageId === companyStageId));
    };

    return (
        <>
            <PageTitle
                title="Onboarding"
                subtitle="A high level summary of your progression through the Onboarding data and documentation capture process, including a slider summary of number of tasks completed."
            />
            <Flex>
                <Box width={1 / 2}>
                    <Card title="Onboarding Documents" gapless={true}>
                        {stages?.length > 0 &&
                            stages.map(({ id, name }) => (
                                <Flex
                                    key={id}
                                    className={cx(
                                        styles.stageBlock,
                                        activeStageId === id ? styles.stageActive : ''
                                    )}
                                    onClick={() => getDocuments(id)}
                                >
                                    <Box pt={1}> {name} </Box>
                                    <Box px={2} ml="auto">
                                        <Icon icon="arrow" stroke="#00AEEF" title="View Task" />
                                    </Box>
                                </Flex>
                            ))}
                    </Card>
                </Box>

                <Box width={1 / 2}>
                    {stageDocuments !== null && (
                        <>
                            {stageDocuments.map(({ id, name, fileName }) => {
                                const el = (
                                    <Box variant="card.wrapper" mb={3}>
                                        <Box key={id}>
                                            {taskName !== name && (
                                                <Box>
                                                    <Box variant="card.header">{name}</Box>
                                                </Box>
                                            )}
                                            <Flex
                                                alignItems="center"
                                                variant="attachmentLink"
                                                py={3}
                                                px={30}
                                                key={id}
                                                onClick={() => downloadAttachment({ id, fileName })}
                                            >
                                                <Icon icon="attachment" title="Download Attachment" width={18} height={18} />{' '}
                                                <Text as="span" fontWeight="500" ml={2} fontSize={1}>
                                                    {fileName}
                                                </Text>
                                            </Flex>
                                        </Box>
                                    </Box>
                                );
                                taskName = name;
                                return el;
                            })}
                            {!stageDocuments?.length && <Box p={30}>This stage has no documents</Box>}
                        </>
                    )}
                </Box>
            </Flex>
        </>
    );
};

export default OnboardingStages;
