import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Box, Button } from 'rebass';
import LoadingSpinner from '~/LoadingSpinner';
import { Form } from '~/Common/Form';

const CardDetails = ({ afterAction, children, onReady, forceLoading }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    const isLoading = loading || forceLoading;

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!stripe || !elements) {
            return;
        }

        stripe
            .confirmSetup({
                elements,
                redirect: 'if_required',
            })
            .then((result) => {
                if (result.error) {
                    setErrorMessage(result.error.message);
                } else {
                    afterAction(event, { paymentMethod: result.setupIntent.payment_method });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Box hidden={!isLoading}>
                <LoadingSpinner />
            </Box>

            <Form hidden={isLoading} onSubmit={handleSubmit} disabled={isLoading}>
                {children}
                <PaymentElement
                    disabled={isLoading}
                    onReady={() => {
                        setLoading(false);
                        typeof onReady === 'function' && onReady();
                    }}
                />
                {errorMessage && (
                    <Box mt={20} color="red" fontSize={12}>
                        {errorMessage}
                    </Box>
                )}
                <Button
                    width={1}
                    disabled={!stripe || isLoading}
                    variant={!stripe || isLoading ? 'disabled' : 'tertiary'}
                    type="submit"
                    mt={4}
                >
                    Submit
                </Button>
            </Form>
        </>
    );
};

export default CardDetails;
