import React, { useEffect, useState } from 'react';
import { Flex, Box, Heading } from 'rebass';
import LoadingSpinner from '~/LoadingSpinner';
import { PageTitle } from '~/Common/PageTitle';
import { useTranslation } from "react-i18next";
import styles from './styles.module.scss';
import { TextField } from '~/Form';
import CommentModule from '~/Common/CommentModule';
import { Icon } from '@svg';
import { connect } from 'react-redux';
import { currencySymbol } from '@util/Functions';

const CompanyBenchmarking = ({ companyId, companies }) => {
    const [loading, setLoading] = useState(false);
    const [commentSectionName, setCommentSectionName] = useState('');
    const { t } = useTranslation();

    const benchmarkingItems = [
        'Average Negotiated Hours per annum per person (adjusted as required)',
        'Total Owners working directly in the business',
        'Direct Labour (directly involved in production of products / provision of',
        'All Other Labour (Management, Sales, Admin, Support, Other)',
        'Total Employees',
    ]

    const company = companyId ? companies.find(company => company.id === companyId) : null;
    const symbol = currencySymbol(company?.currency?.isoCode)

    useEffect(() => {
        if (companyId) {
            setLoading(true);

            setTimeout(function () {
                setLoading(false);
            }, 250)
        }
    }, [companyId]);

    if (loading) return <LoadingSpinner />;

    return (
        <>
            <Box px={3}>
                <PageTitle
                    subtitle={t('benchmarking.subtitle')}
                />
            </Box>
            <Box variant="card.wrapper">
                <Flex variant="card.header" alignItems="center">
                    <Heading>
                        Benchmarking Questionnaire
                    </Heading>
                </Flex>
                <Box variant="card.container">
                    <table className={styles.dataTable}>
                        <thead>
                            <tr>
                                <td>Employment Information (please complete using payroll reports)</td>
                                <td>Staff Members</td>
                                <td>Total Hours per Annum</td>
                                <td>Salaries & Wages</td>
                                <td>Super</td>
                                <td>Other benefits (bonus, allowance etc)</td>
                                <td>% of salary for a Fixed portion of Labour (essential workers that cannot be laid off at shutdown)</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {benchmarkingItems.map((rowName, index) => ([<tr key={index} className={rowName === 'Total Employees' ? styles.footerRow : null}>
                                <td>{rowName}</td>
                                <td>{index > 0 && <TextField placeholder="#" />}</td>
                                <td><TextField placeholder="#" /></td>
                                <td>{index > 1 && <TextField placeholder={symbol} />}</td>
                                <td>{index > 1 && <TextField placeholder={symbol} />}</td>
                                <td>{index > 1 && <TextField placeholder={symbol} />}</td>
                                <td>{index > 1 && <TextField placeholder="0%" />}</td>
                                <td>{index > 1 && <TextField placeholder="0%" />}</td>
                                <td>
                                    <Flex variant="clickable" alignItems="center" onClick={() => setCommentSectionName(rowName === commentSectionName ? '' : rowName)}>
                                        <Icon icon="comment" />
                                    </Flex>
                                </td>
                            </tr>,
                            rowName === commentSectionName && <tr key='comment-section'><td colSpan="99"><CommentModule companyId={companyId} sectionName={rowName} /></td></tr>
                            ]))}
                        </tbody>
                    </table>
                </Box>
            </Box>
        </>
    );
};

export default connect((state) => ({ companies: state.companies }))(CompanyBenchmarking);
