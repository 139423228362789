import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    list: [],
    page: 1,
    hasMorePages: false,
    newCount: 0,
    lastUpdate: null
};

const notifications = createSlice({
    name: 'notifications',
    initialState: initialState,
    reducers: {
        setUnreadNotificationsCount: (state, { payload }) => {
            state.newCount = payload;
        },
        setNotifications: (state, { payload }) => {
            const { data, meta } = payload;
            state.list = data;
            state.page = meta?.current_page ?? 1;
            state.hasMorePages = Boolean(meta?.current_page < meta?.last_page);
        },
        appendNotifications: (state, { payload }) => {
            const { data, meta } = payload;
            state.list = [...state.list, ...data];
            state.page = meta?.current_page ?? 1;
            state.hasMorePages = Boolean(meta?.current_page < meta?.last_page);
        },
        readTaskNotification: (state, { payload }) => {
            if (!state.list) {
                return state
            }
            state.list = state.list.map(n => {
                if (n.companyTaskId === parseInt(payload)) n.seen = 1
                return n
            })
        },
        readNotification: (state, { payload }) => {
            state.list = state.list.map(n => {
                if (n.id === parseInt(payload)) n.seen = 1
                return n
            })
            state.newCount = JSON.parse(state.newCount) - 1;
        },
        setNotificationsLastUpdate: (state, { payload }) => {
            state.lastUpdate = payload
        },
        resetNotifications: () => initialState,
        subtractUnreadNotificationCount: (state, { payload }) => {
            state.newCount = JSON.parse(state.newCount) - parseInt(payload);
        },
    }
})

export const { setUnreadNotificationsCount, setNotifications, appendNotifications, readTaskNotification, readNotification, setNotificationsLastUpdate, resetNotifications, subtractUnreadNotificationCount } = notifications.actions

export default notifications.reducer
