import React from 'react'
import styles from './styles.module.scss'

const LoadingSpinner = ({ size = '100', margin }) => {
    const style = { width: `${size}px`, height: `${size}px`, margin: `${margin ?? size}px auto`}
    return <div className={styles.spinner} style={style}>
        <div className={styles.doubleBounce1} />
        <div className={styles.doubleBounce2} />
    </div>
}
export default LoadingSpinner
