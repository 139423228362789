import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Flex, Heading, Text } from 'rebass';
import { getTaskStatus } from '@util/Enum';
import { toast } from 'react-toastify';
import { createCompanyScorecard, resetCompanyScorecard } from '@app/Api';
import { isAnalyst, isChiefAnalyst } from '@util/Functions/User';
import {
    BetaFactorSliderScorecard,
    CreditScorecard,
    ExitReadinessScorecard,
    FinancialScorecard,
    FoundationScorecard,
    getErrorMessage,
    NonFinancialScorecard,
    RiskScorecard,
    StrategicGrowthScorecard,
} from '@util/Functions';
import { Icon } from '@svg';
import { Confirm } from '~/Common/Confirm';
import { useTranslation } from 'react-i18next';

const ScorecardList = ({ companyId, scorecards, year, requiresFinancials, setErrors, resetTrigger }) => {
    const history = useHistory();
    const isFinancialOrCredit = (type) => type === FinancialScorecard || type === CreditScorecard;
    const resetScorecardId = useRef(null);
    const [showConfirmResetModal, setShowConfirmResetModal] = useState(false);
    const { t } = useTranslation();

    const redirect = (id, completedOn, type) => {
        if (completedOn) {
            const link = companyId ? `/advisor/company/${companyId}/scorecards/${id}` : `/company/scorecards/${id}`;
            history.push(link);
        } else if (requiresFinancials && isFinancialOrCredit(type)) {
            history.push(`/advisor/company/${companyId}/financial-reports`);
        } else if (companyId) {
            if (id) history.push(`/advisor/company/${companyId}/scorecards/${id}/edit`);
            else
                createCompanyScorecard({ year, companyId, type })
                    .then((scorecardId) => {
                        toast.success('Scorecard created successfully');
                        history.push(
                            type === RiskScorecard
                                ? `/advisor/company/${companyId}/scorecards/${scorecardId}`
                                : `/advisor/company/${companyId}/scorecards/${scorecardId}/edit`
                        );
                    })
                    .catch(({ response }) => {
                        if (response.data.status) setErrors(response.data.status);
                        toast.error(
                            getErrorMessage(response?.data, 'There was an error while trying to create the scorecard')
                        );
                    });
        }
    };

    const getResultButtonText = (id, completedOn, type) => {
        if (completedOn) return 'View Results';
        if (requiresFinancials && isFinancialOrCredit(type)) return 'Upload Financial History';
        if (id || (isFinancialOrCredit(type) && !requiresFinancials))
            return companyId ? 'Complete Scorecard' : 'In Progress';
        return companyId ? 'Create Scorecard' : 'To be Started';
    };

    const viewInput = (id) => history.push(`/advisor/company/${companyId}/scorecards/${id}/edit`);

    const handleResetScorecard = () => {
        if (resetScorecardId.current === null) {
            return;
        }

        resetCompanyScorecard(resetScorecardId.current)
            .then(() => {
                toast.success('Scorecard reset successfully');
                resetTrigger((prevState) => prevState + 1);
            })
            .catch(({ response }) => {
                if (response.data.status) setErrors(response.data.status);
                toast.error(
                    getErrorMessage(response?.data, 'There was an error while trying to reset the scorecard')
                );
            });
    }
    const resetScorecard = (id) => {
        resetScorecardId.current = id;
        setShowConfirmResetModal(true);
    };

    return (
        <Box>
            <Confirm
                title={t('Are you sure you want to reset this scorecard?')}
                isOpen={showConfirmResetModal}
                requestClose={() => setShowConfirmResetModal(false)}
                onConfirm={handleResetScorecard}
            />
            <Flex sx={{ flexWrap: 'wrap' }}>
                {scorecards?.map(({ id, completedOn, type, name, description }, index) => {
                    type = type?.id ?? parseInt(type);

                    const { sx: statusStyle, name: statusName } = getTaskStatus(
                        completedOn ? 3 : id || (isFinancialOrCredit(type) && !requiresFinancials) ? 1 : 0
                    );
                    const linkUrl = () => redirect(id, completedOn, type);

                    return (
                        <Box key={index} width={[1 / 1, '', '', 1 / 2, 1 / 3]} mb="30px">
                            <Flex
                                variant="card.wrapper"
                                sx={{ bg: '#fff', minHeight: '100%', flexDirection: 'column' }}
                                p={20}
                            >
                                <Flex alignItems="center">
                                    <Box width={1 / 2}>
                                        {type === FinancialScorecard && <Icon icon="scorecardFinancial" />}
                                        {type === NonFinancialScorecard && <Icon icon="scorecardNonFinancial" />}
                                        {type === CreditScorecard && <Icon icon="scorecardCredit" />}
                                        {type === ExitReadinessScorecard && <Icon icon="scorecardExit" />}
                                        {type === BetaFactorSliderScorecard && <Icon icon="scorecardBetafactor" />}
                                        {type === FoundationScorecard && <Icon icon="scorecardFoundation" />}
                                        {type === StrategicGrowthScorecard && <Icon icon="scorecardStrategicGrowth" color="blueDark2" />}
                                    </Box>
                                    <Box textAlign="right" width={1 / 2}>
                                        <Button variant="task" ml="auto" py="8px" sx={statusStyle} onClick={linkUrl}>
                                            {statusName}
                                        </Button>
                                    </Box>
                                </Flex>
                                <Heading mt="20px" mb="15px" sx={{ fontWeight: 600 }}>
                                    {name}
                                </Heading>
                                <Text variant="scorecard.description">{description}</Text>

                                <Flex mt="auto" sx={{ gap: '20px' }}>
                                    {companyId &&
                                        completedOn &&
                                        (type === NonFinancialScorecard ||
                                            type === ExitReadinessScorecard ||
                                            type === StrategicGrowthScorecard ||
                                            type === FoundationScorecard) && (
                                            <Button variant="gray" width={1 / 2} mr={1} onClick={() => viewInput(id)}>
                                                View Input
                                            </Button>
                                        )}
                                    <Button variant="gray" flex={1} onClick={linkUrl}>
                                        {getResultButtonText(id, completedOn, type)}
                                    </Button>

                                    {id && (isAnalyst || isChiefAnalyst) && (
                                        <Button variant="gray" flex={1} onClick={() => resetScorecard(id)}>
                                            Reset
                                        </Button>
                                    )}
                                </Flex>
                            </Flex>
                        </Box>
                    );
                })}
            </Flex>
            {!scorecards?.length && (
                <Box p={30} variant="card.wrapper">
                    There are no scorecards to display.
                </Box>
            )}
        </Box>
    );
};

export default ScorecardList;
