import React, { useEffect, useState } from 'react'
import { getOnboardingStagesDocuments } from "@app/Api"
import LoadingSpinner from "~/LoadingSpinner"
import { toast } from "react-toastify"
import { getErrorMessage } from "@util/Functions"
import OnboardingStages from '~/Dashboard/OnboardingStages'

const OnboardingDocuments = () => {
    const [stages, setStages] = useState([])
    const [documents, setDocuments] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        getOnboardingStagesDocuments()
            .then(({ stages, documents }) => {
                setStages(stages)
                setDocuments(documents)
                setLoading(false)
            })
            .catch(({ response }) =>
                toast.error(getErrorMessage(response?.data, 'Unable to load document data'), { toastId: 'getOnboardingStagesDocumentsError' }))
    }, [setLoading])

    if (loading)
        return <LoadingSpinner />

    return <OnboardingStages stages={stages} documents={documents} />
}

export default OnboardingDocuments
