import React, { useRef, useState } from 'react';
import { Icon } from '@svg';
import { Box, Flex } from 'rebass';
import styles from './styles.module.scss';
import { Button } from '~/Common/Button';
import { useTranslation } from 'react-i18next';

export const ImageUpload = ({ value, uploading, text, description, errors, onChange, theme, ...props }) => {
    const [logoPreview, setLogoPreview] = useState(value);
    const fileInput = useRef(null);
    const { t } = useTranslation();

    const onLogoChange = (e) => {
        const file = e.target.files[0];

        if (logoPreview) {
            URL.revokeObjectURL(logoPreview);
        }

        if (file) {
            const preview = URL.createObjectURL(file);
            setLogoPreview(preview);
            if ('function' === typeof onChange) {
                onChange({ file, preview });
            }
        }
    };

    return (
        <Box className={styles.wrapper}>
            <Flex flexDirection="column" className={styles.selector} tabIndex="0" onClick={() => fileInput.current.click()}>
                <Box className={`${styles.preview} ${theme === 1 ? styles.darkMode : ''}`}>
                    {logoPreview && (
                        <img
                            src={logoPreview}
                            onError={() => setLogoPreview(null)}
                            alt="Upload"
                            className={styles.companyLogo}
                            style={{
                                ...(uploading === true ? { opacity: 0.5 } : {}),
                                width:'auto', height:'85px',
                            }}
                        />
                    )}
                    {!logoPreview && <Icon icon="uploadPhoto" title="Upload Photo" size={64} />}
                    <input
                        ref={fileInput}
                        type="file"
                        name="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={onLogoChange}
                    />
                </Box>
                <Flex flexDirection="column" className={styles.content}>
                    <Button
                        type="button"
                        variant="attachmentLink"
                        tabIndex="-1"
                        sx={{
                            pointerEvents: 'none',
                            mb: 2,
                            fontWeight: 600,
                        }}
                    >
                        {text ?? t('upload.default.title')}
                    </Button>
                    <Box sx={{ color: 'gray3', fontSize: '12px' }}>{description ?? t('upload.default.subtitle')}</Box>
                </Flex>
            </Flex>
            {errors?.logo && (
                <Box mb={20} color="red" fontSize={12}>
                    {errors.logo[0]}
                </Box>
            )}
        </Box>
    );
};
