import { getAttachment, getTemplate, previewReport, downloadReport, wysiwygGetFile, downloadCompanyFinancialImports, downloadDraftReport } from "@app/Api"
import { downloadDraftReport as downloadDraftReportV202107 } from "@app/Api/V202107"
import { toast } from "react-toastify"

export const downloadAttachment = ({ id, fileName }) =>
    getAttachment(id)
        .then(file => promptDownload(fileName, file))
        .catch(() => toast.error('There was an error while trying to download the attachment'))

export const downloadTemplate = ({ id, fileName }) =>
    getTemplate(id)
        .then(file => promptDownload(fileName, file))
        .catch(() => toast.error('There was an error while trying to download the template'))

export const downloadReportPreview = ({ reportId, fileName }) =>
    previewReport(reportId)
        .then(file => promptDownload(fileName, file))
        .catch(() => toast.error('There was an error while trying to download the report preview'))

export const downloadReportFile = ({ reportId, fileName }) =>
    downloadReport(reportId)
        .then(file => promptDownload(fileName, file))
        .catch(() => toast.error('There was an error while trying to download the report'))

export const downloadDraftReportFile = ({ reportId, fileName }, version, watermark = null) =>
    version === 'V202107' ?
        downloadDraftReportV202107(reportId, watermark)
            .then(file => promptDownload(fileName, file))
            .catch(() => toast.error('There was an error while trying to download the report')) :
        downloadDraftReport(reportId, watermark)
            .then(file => promptDownload(fileName, file))
            .catch(() => toast.error('There was an error while trying to download the report'))

export const downloadWysiwygFile = ({ fileLocation, fileName }) =>
    wysiwygGetFile(fileLocation)
        .then(file => promptDownload(fileName, file))
        .catch(() => toast.error('There was an error while trying to download the file'))

export const downloadFinancialImportFile = ({ importId, companyId, fileName }) =>
    downloadCompanyFinancialImports(importId, companyId)
        .then(file => promptDownload(fileName, file))
        .catch(() => toast.error('There was an error while trying to download the import file'))

const promptDownload = (fileName, blobData) => {
    const url = window.URL.createObjectURL(new Blob([blobData]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
}
