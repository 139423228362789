export const companyStepStatuses = {
    1: {
        value: 1,
        label: 'To Be Started',
    },
    2: {
        value: 2,
        label: 'In Progress',
    },
    3: {
        value: 3,
        label: 'Complete',
    },
};
