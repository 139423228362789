import axios from "axios";
import { downloadFile } from "@util/Functions";

export const getCompanies = ({ search, orderBy, ...data }) =>
    axios
        .get(`advisor/company/list`, {
            params: {
                search:
                    typeof search === "string" && search.length > 0
                        ? search
                        : undefined,
                orderBy,
                ...data,
            },
        })
        .then(({ data }) => data);

export const exportCompanies = (filters) =>
    axios
        .get("advisor/company/export", {
            params: filters,
        })
        .then((r) => {
            downloadFile(
                r.data,
                "Company Export.csv",
                r.headers["content-type"]
            );
        });

export const getCompanySettings = (companyId) =>
    axios
        .post(`advisor/company/details/get`, { companyId })
        .then(({ data }) => data);

export const getCompanyStages = (data) =>
    axios.post(`advisor/company/stage`, data).then(({ data }) => data);

export const getCompanyOnboardingDocuments = (companyId) =>
    axios
        .post("advisor/company/onboarding-documents", { companyId })
        .then(({ data }) => data);

export const getCompanyOverview = (companyId) =>
    axios
        .post(`advisor/company/overview`, { companyId })
        .then(({ data }) => data);

export const getCompanyQuestionnaire = (companyId, params) =>
    axios
        .get(`advisor/company/${companyId}/questionnaire`, { params })
        .then(({ data }) => data);

export const getCompanyDynamicRevaluation = (companyId) =>
    axios
        .get(`advisor/company/${companyId}/dynamic-revaluation`)
        .then(({ data }) => data);

export const addCompanyNopatValue = (companyId, data) =>
    axios
        .post(`advisor/company/${companyId}/nopat`, data)
        .then(({ data }) => data);

export const updateTaskStatus = (
    companyTaskId,
    status,
    normalisation = false
) =>
    axios.post(`advisor/task/status/set`, {
        companyTaskId,
        status,
        normalisation,
    });

export const addCustomTask = (data = null) =>
    axios.post("advisor/task/add", data).then(({ data }) => data);

export const updateCustomTask = (companyTask, data = null) =>
    axios.post(`advisor/task/${companyTask}`, data).then(({ data }) => data);

export const deleteCustomTask = (companyTask) =>
    axios.delete(`advisor/task/${companyTask}`).then(({ data }) => data);

export const getCompanyCustomVariables = (company) =>
    axios
        .post("advisor/company/custom-variables/get", company)
        .then(({ data }) => data);

export const setOnboardingCustomVariables = (customVariables) =>
    axios
        .post("advisor/company/custom-variables/onboarding", customVariables)
        .then(({ data }) => data);

export const setCompanyCustomVariables = (customVariables) =>
    axios
        .post("analyst/company/custom-variables/update", customVariables)
        .then(({ data }) => data);

export const addCompany = (data = null) =>
    axios.post("advisor/company/add", data).then(({ data }) => data);

export const validateCompanyInvite = (data = null) =>
    axios
        .post("advisor/company/validate-invite", data)
        .then(({ data }) => data);

export const assignCompanyUsers = (company, data = null) =>
    axios
        .post(`advisor/company/${company}/users`, { invites: data })
        .then(({ data }) => data);

export const removeCompanyUser = (company, data = null) =>
    axios
        .post(`advisor/company/${company}/remove-user`, data)
        .then(({ data }) => data);
