import React from 'react';
import { Link, Flex, Button, Heading, Text, Box } from 'rebass';
import { Link as RouterLink } from 'react-router-dom';
import styles from './styles.module.scss';
import { useTranslation } from "react-i18next";
//import { PercentageGain } from '~/Common/PercentageGain';
import { Badge } from '~/Common/Badge';
import { defaultCompanyLogoLink, formatCurrency } from '@util/Functions';
import { Icon } from '@svg';
import { StatusTag } from '../StatusTag';
import UserAvatar from '../Avatar';
import { Skeleton } from '~/Common/Skeleton';


const StatsRow = ({ title, children, ...props }) => (
    <Box sx={{
        display: 'grid',
        gridTemplate: 'auto / repeat(2, 1fr)',
        borderBottom: '1px solid #F5F7F8',
        py: 1
    }}>
        <Text color="blueDark2">{title}</Text>
        <Box sx={{
            textAlign: 'right',
            fontWeight: 600,
            color: '#2C2960'
        }}>{children}</Box>
    </Box>
)

const CompanyTile = ({ children, company, groupSx, ...props }) => {
    const {
        archived,
        live,
        id,
        name,
        logo,
        lastInsightReport,
        notifications,
        status,
        realTimeValuation,
        priorityTask
    } = company ?? {
        archived: null,
        live: null,
        id: null,
        name: null,
        logo: null,
        lastInsightReport: null,
        notifications: null,
        status: null,
        realTimeValuation: null,
        priorityTask: null
    };
    const { t } = useTranslation();

    return (
        <Box variant="card.wrapper" width={1} sx={{ bg: 'white', my: '12px' }} {...props}>
            <Box p={4} px="16px" pt={'40px'} sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'flex-end'
            }}>
                {company ? (
                    <>
                        {!live && <Box className={styles.ribbonContainer}><Box className={styles.trainingRibbon}>{t('Training')}</Box></Box>}
                        {archived && <Box className={styles.ribbonContainer}><Box className={styles.archivedRibbon}>{t('Archived')}</Box></Box>}
                        <StatusTag status={status} absolute={true} />
                    </>
                ) : (
                    <Skeleton height="10px" width="80px" sx={{
                        position: 'absolute',
                        top: 0,
                        left: '20px',
                        borderRadius: '0px 0px 8px 8px',
                        padding: '6px 10px 4px 10px',
                    }} />
                )}

                <Flex>
                    {company ? <UserAvatar
                        logo={logo || defaultCompanyLogoLink}
                        size={20}
                        mr={2}
                        flexShrink={0}
                    /> : <Skeleton variant="circle" size="32px" mr={2} />}
                    {company ? <Heading color="blueDark" fontWeight={600} fontSize="16px">{name}</Heading> : <Skeleton height="32px" width="70%" />}
                </Flex>

                <Box sx={{
                    flex: 1,
                    mt: '18px',
                    fontSize: '12px'
                }}>
                    {company ? <StatsRow title={t('Current valuation')}>
                        <Flex sx={{ justifyContent: 'flex-end' }}>
                            {realTimeValuation ? formatCurrency(realTimeValuation, company?.currency?.isoCode, 0) : 'N/A'}
                        </Flex>
                    </StatsRow> : <Skeleton height="16px" width="100%" mb={1} />}
                    {company ? <StatsRow title={t('Last insights report')}>{lastInsightReport ? lastInsightReport : 'N/A'}</StatsRow> : <Skeleton height="16px" width="100%" mb={1} />}
                    {company ? <StatsRow title={t('New notifications')}>{notifications ? notifications : 0}</StatsRow> : <Skeleton height="16px" width="100%" mb={1} />}
                </Box>

                {company ? (
                    <>
                        {!archived ? (
                            <>
                                {priorityTask && (
                                    <Box sx={{
                                        mt: '23px',
                                        fontSize: '12px'
                                    }}>
                                        <Flex>
                                            <Heading as="h4" sx={{ fontSize: '12px', flex: 1 }}>Priority Task</Heading>
                                            <Link as={RouterLink} to={`/advisor/company/${id}/overview`}>
                                                {t('View all')}
                                            </Link>
                                        </Flex>
                                        <Link variant="tileLink" as={RouterLink} to={`/advisor/company/${id}/stages/task/${priorityTask.id}`} sx={{
                                            mt: '11px',
                                            '& .taskLink--actions': {
                                                transform: 'translateX(20px)',
                                                transition: 'transform 0.2s ease-in-out',
                                                '.icon': {
                                                    transform: 'translateX(10px)',
                                                    transition: 'transform 0.2s ease-in-out'
                                                }
                                            },
                                            '&:hover .taskLink--actions': {
                                                transform: 'translateX(0px)',
                                                '.icon': {
                                                    transform: 'translateX(0px)'
                                                }
                                            }
                                        }}>
                                            <Flex sx={{
                                                overflow: 'hidden',
                                                alignItems: 'center',
                                                p: '7px 12px'
                                            }}>
                                                <Box sx={{ flex: 1 }}>{priorityTask.name}</Box>
                                                <Flex className="taskLink--actions">
                                                    <Badge variant="warning">Upcoming</Badge>
                                                    <Icon icon="link" sx={{ ml: '7px', color: '#19CEFF !important' }} />
                                                </Flex>
                                            </Flex>
                                        </Link>
                                    </Box>
                                )}
                            </>
                        ) : null}

                        <Box sx={{
                            mt: '20px',
                            ...groupSx
                        }} justifyContent="space-between" alignItems="end">
                            <Link as={RouterLink} variant="transparent" to={`/advisor/company/${id}/overview`} style={{ marginTop: '8px' }}>
                                <Button variant="smallGray">
                                    <Icon icon="computer" style={{ width: '13px', height: 'auto', marginRight: '9px' }} />
                                    {t('Company Dashboard')}
                                </Button>
                            </Link>
                            {!archived ? (
                                <>
                                    {children}
                                </>
                            ) : null}
                        </Box>
                    </>
                ) : (
                    <Box sx={{
                        mt: '20px',
                        ...groupSx
                    }} justifyContent="space-between" alignItems="end">
                        <Skeleton variant="button" height="48px" />
                    </Box>
                )}
            </Box>
        </Box>
    );
}

export default CompanyTile;
