import React from 'react';
import { useDrop } from 'react-dnd';
import styles from '../Assets/styles.module.scss';

const RoadmapContainer = ({ date, taskId, children, id }) => {
    const [, drop] = useDrop(() => ({
        accept: `task${taskId}`,
        drop: () => ({ date }),
    }),[date]);
    return <td ref={drop} id={id} className={styles.noPadding} style={{position:'relative', maxWidth:'50px'}} key={Math.random()}>
        {children}
    </td>;
};

export default RoadmapContainer
