import './Assets/Fonts/OpenSauceTwo/OpenSauceTwo-Regular.ttf'
import React, { useEffect, useState } from 'react'
import { ThemeProvider } from 'theme-ui'
import { BrowserRouter } from 'react-router-dom'
import Routes from '@app/Routes'
import { theme } from '@app/theme'
import { getCsrfCookie } from '@app/Api'
import { Toast } from '~/Common/Toast'
import { updateUserStore } from '@util/Functions'
import LoadingSpinner from '~/LoadingSpinner'
import "@translations/i18n"
import { DashboardProvider } from 'Context/Dashboard'
import { SupportLink } from '~/Common/SupportLink'
import Dashboard from '@section/Dashboard'
import { Login } from 'Pages'

const App = () => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getCsrfCookie()
        if (localStorage.getItem('authToken')) {
            updateUserStore().then(() => setLoading(false));
        } else {
            setLoading(false)
        }
    }, [])

    return (
        <BrowserRouter>
            <DashboardProvider>
                <ThemeProvider theme={theme}>
                    {loading ? (
                        <>
                            {localStorage.getItem('authToken') ? <Dashboard Component={LoadingSpinner} /> : <Login />}
                        </>
                    ) : (
                        <>
                            <Routes />
                            <Toast />
                            <SupportLink />
                        </>
                    )}
                </ThemeProvider>
            </DashboardProvider>
        </BrowserRouter>
    )
}



export default App
