export const COMPANY = 1;
export const ADVISOR = 2;
export const ADMIN = 3;
export const ANALYST = 4;
export const CHIEF_ANALYST = 5;
export const CEO = 6;
export const SUPERVISOR = 7;
export const CONSULTANT = 8;

export const UserRoles = {
    COMPANY,
    ADVISOR,
    ADMIN,
    ANALYST,
    CHIEF_ANALYST,
    CEO,
    SUPERVISOR,
    CONSULTANT,
};

export const accessLevelLabels = {
    [COMPANY]: 'Company',
    [ADVISOR]: 'Advisor',
    [ADMIN]: 'Admin',
    [ANALYST]: 'Analyst',
    [CHIEF_ANALYST]: 'Chief Analyst',
    [CEO]: 'CEO',
    [SUPERVISOR]: 'Supervisor',
    [CONSULTANT]: 'Consultant'
}
