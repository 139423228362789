import axios from "axios";

export const storeStepQuestionnaire = (companyId, stepId) =>
    axios.post(`advisor/company/${companyId}/questionnaire`, { stepId })
        .then(({ data }) => data);

export const showStepQuestionnaire = (companyId, stepId) =>
    axios.get(`advisor/company/${companyId}/questionnaire/${stepId}`)
        .then(({ data }) => data);

export const updateStepQuestionnaire = (companyId, stepId, data) =>
    axios.patch(`advisor/company/${companyId}/questionnaire/${stepId}`, data)
        .then(({ data }) => data);

export const finaliseStepQuestionnaire = (companyId, stepId, data) =>
    axios.post(`advisor/company/${companyId}/questionnaire/${stepId}/finalise`, data)
        .then(({ data }) => data);
