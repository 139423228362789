import { getIbisWorldData } from '@app/Api';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, Flex, Heading, Text } from 'rebass';
import { Card } from '~/Common/Card';
import StatisticTile from '~/Dashboard/StatisticTile';
import LoadingSpinner from '~/LoadingSpinner';

const KeyStatTile = ({ statistic, ...props }) => {
    return (
        <StatisticTile {...statistic}>
            <Heading fontSize={22}>{statistic?.value}</Heading>
        </StatisticTile>
    );
};

const IBISWorldData = ({ companyId }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        getIbisWorldData(companyId)
            .then(({ data }) => setData(data))
            .catch(({ response }) => toast.error(response?.data?.message, 'Unable to retreive IBISWorld data'), { toastId: 'getIbisWorldDataError' })
            .finally(() => setLoading(false));
    }, [companyId]);

    return (
        <>
            {loading ? (
                <LoadingSpinner />
            ) : data ? (
                <>
                    {data?.industry && <Heading sx={{ ml: 3, mb: 4 }}>[{data.industry.code}] {data.industry.name}</Heading>}
                    <Card title="Key Statistics">
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(3, 1fr)',
                                gap: 3,
                            }}
                        >
                            {data?.keyStatistics?.map((statistic) => {
                                return <KeyStatTile statistic={statistic} />;
                            })}
                        </Box>
                    </Card>

                    <Card title="Key External Drivers">
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(4, 1fr)',
                                gap: 3,
                            }}
                        >
                            {data?.keyExternalDrivers?.map((keyExternalDriver) => (
                                <Flex
                                    sx={{
                                        textAlign: 'center',
                                        bg: '#fff',
                                        py: 4,
                                        px: 2,
                                        gap: 2,
                                        flexDirection: 'column',
                                        borderRadius: '16px',
                                    }}
                                >
                                    <Heading fontSize={36} m={0}>
                                        {keyExternalDriver?.value ?? '-'}
                                    </Heading>
                                    <Text fontSize={14}>{keyExternalDriver?.Driver}</Text>
                                </Flex>
                            ))}
                        </Box>
                    </Card>
                    <Card title="Industry Structure">
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(3, 1fr)',
                                gap: 3,
                            }}
                        >
                            <StatisticTile
                                name="Positive Impact"
                                stats={data?.industryStructure?.PositiveImpact?.map((impact) => ({
                                    title: impact?.Name,
                                    color: impact?.Color ?? 'inherit',
                                    value: impact?.Level,
                                }))}
                            />
                            <StatisticTile
                                name="Mixed Impact"
                                stats={data?.industryStructure?.MixedImpact?.map((impact) => ({
                                    title: impact?.Name,
                                    color: impact?.Color ?? 'inherit',
                                    value: impact?.Level,
                                }))}
                            />
                            <StatisticTile
                                name="Negative Impact"
                                stats={data?.industryStructure?.NegativeImpact?.map((impact) => ({
                                    title: impact?.Name,
                                    color: impact?.Color ?? 'inherit',
                                    value: impact?.Level,
                                }))}
                            />
                        </Box>
                    </Card>
                </>
            ) : (
                <h1>No Data Available</h1>
            )}
        </>
    );
};

export default IBISWorldData;
