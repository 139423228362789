import React from "react"
import { Portal } from "@material-ui/core"
import { Icon } from "@svg/Icon"
import { Box, Flex } from "rebass"
import theme from "@app/theme"

export const PageNavigation = ({ icon, title, subtitle, section }) => {

    return (
        <Portal container={document.getElementById('headerPortal')}>
            <Flex sx={{
                bg: '#F5F7F8',
                fontSize: '12px',
                fontWeight: 500,
                alignItems: 'center',
                height: '40px',
                px: '30px'
            }}>
                <Flex alignItems="center" sx={{
                    gap: '8px',
                    px: '16px'
                }}>
                    {icon && <Icon icon={icon} size="18px" />}
                    <Box>{title}</Box>
                </Flex>
                {subtitle && <>
                    <Box sx={{
                        mx: '16px',
                        height: '21px',
                        width: '1px',
                        background: '#000',
                    }} />
                    <Flex sx={{
                        alignItems: 'center',
                        px: '24px',
                    }}>{subtitle}</Flex>
                </>}
                {section && <Flex sx={{
                    alignItems: 'center',
                    px: '24px',
                    background: theme.colors.background,
                    height: '100%'
                }}>{section}</Flex>}
            </Flex>
        </Portal>
    )
}
