import React, { useRef, useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Box, Flex, Button } from 'rebass';
import { getCompanyFinancialImports, updateCompanyFinancialImports, finaliseCompanyFinancialImports, normaliseCompanyFinancialImports, importCodat } from '@app/Api';
import LoadingSpinner from '~/LoadingSpinner';
import styles from './styles.module.scss';
import { currentFinancialYear, formatCurrency, getErrorMessage, isAllCaps, ucWords, yearsList } from '@util/Functions';
import { connect } from 'react-redux';
import { setFinancialHistoryYear } from '@app/Store';
import { PageTitle } from '~/Common/PageTitle';
import { Card } from '~/Common/Card';
import { theme } from '@app/theme';
import { SelectField, TextField } from '~/Form';
import { downloadFinancialImportFile } from '@util/Functions/Download';
import { useTranslation } from "react-i18next";
import { Icon } from '@svg';
import CommentModule from '~/Common/CommentModule';
import cx from 'classnames';
import { DropdownModal } from '~/Common/DropdownModal';

const FinancialImports = ({ companyId, uploadFinancials, company }) => {
    const fileInput = useRef(null)
    const [loading, setLoading] = useState(false)
    const [currency, setCurrency] = useState('AUD')
    const [accounts, setAccounts] = useState(null)
    const [accountsNormalised, setAccountsNormalised] = useState(null)
    const [importId, setImportId] = useState(null)
    const [editing, setEditing] = useState(false)
    const [minYearList, setMinYearList] = useState(null)
    const [maxYearList, setMaxYearList] = useState(null)
    const [activeSections, setActiveSections] = useState([])
    const [commentSectionName, setCommentSectionName] = useState('')
    const [type, setType] = useState('P&L')
    const [isNormalised, setIsNormalised] = useState(false)
    const [classifications, setClassifications] = useState({});
    const [errors, setErrors] = useState({});
    const [yearsRange, setYearsRange] = useState([])
    const { t } = useTranslation()

    const fetchFinancialImports = useCallback(() => {
        setLoading(true);
        getCompanyFinancialImports({ companyId })
            .then(({ data, id, dataNormalised, classifications, currency }) => {
                setLoading(false)
                setCurrency(currency)
                if (data) setAccounts(data)
                if (dataNormalised) {
                    setAccountsNormalised(dataNormalised)
                    setIsNormalised(true)
                    setMinYearList(currentFinancialYear)
                }
                setClassifications(classifications)
                setImportId(id)
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to get financials')))
    }, [companyId])

    useEffect(() => { if (companyId) fetchFinancialImports() }, [companyId, fetchFinancialImports])

    useEffect(() => {
        const firstFinancialYear = accounts?.firstFinancialYear

        if (firstFinancialYear) {
            setYearsRange(new Array(6).fill().map((val, key) => {
                const year = firstFinancialYear + key;
                return { label: year, value: year }
            }))
        } else {
            // TODO: Fix fiscal years
            setYearsRange(yearsList(6, 3, true).reverse())
        }
    }, [accounts])

    function getParentValue(val) {
        const classificationId = parseInt(val)
        let classification = null

        // eslint-disable-next-line
        for (const [type, typeClassifications] of Object.entries(classifications)) {
            classification = typeClassifications.find((classification) => classification.value === classificationId)

            if (!classification) {
                const child = typeClassifications.filter(typeClassification => {
                    const children = typeClassification?.children
                    if (children.find((classification) => classification.value === classificationId)) {
                        return typeClassification
                    } else {
                        return false
                    }
                })

                if (child?.[0]) {
                    classification = child?.[0]
                }
            }

            if (classification) break
        }

        return classification?.value ?? classificationId
    }

    const updateAccountsMatch = (section, row, value) => {
        let parentId = getParentValue(value)
        let newAccounts = { ...accounts }
        newAccounts[type][section][row].label = value
        newAccounts[type][section][row].match = parentId.toString()
        setAccounts(newAccounts)
    }

    const updateAccountsValue = (section, row, year, value) => {
        let newAccounts = { ...accounts }
        newAccounts[type][section][row].data[year] = value
        setAccounts(newAccounts)
    }

    const saveUpdates = () => {
        setLoading(true)
        setErrors({})
        updateCompanyFinancialImports({ id: importId, companyId, data: accounts })
            .then(({ data, id }) => {
                setEditing(false)
                if (data) setAccounts(data)
                setImportId(id)
                toast.success(t('Financials successfully updated'))
            })
            .catch(({ response }) => {
                setErrors(response?.data?.errors)
                toast.error(t('Unable to update financials'))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const isBalanceSheetActive = type === 'BS'

    const getClassificationName = (id, label) => {
        id = parseInt(label || id)
        const bsName = classifications?.['Balance Sheet'].find(({ value }) => value === id)
        if (bsName) return bsName.label
        const plName = classifications?.['Profit & Loss'].find(({ value }) => value === id)
        if (plName) return plName.label
        return ''
    }

    const normalise = () => {
        setActiveSections([]);
        normaliseCompanyFinancialImports({ companyId, id: importId })
            .then(() => {
                fetchFinancialImports()
                toast.success('Financials Normalised Successfully')
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to normalise')))
    }

    const finalise = () => {
        setActiveSections([]);
        finaliseCompanyFinancialImports({ companyId, id: importId })
            .then(() => {
                fetchFinancialImports()
                toast.success('Financials Finalised Successfully')
            })
            .catch(({ response }) => toast.error(getErrorMessage(response?.data, 'Unable to finalise')))
    }

    // List of Sections and relative boolean if they are totals or not
    const sectionsList = {
        'P&L': [
            ['Income', false],
            ['COGS or COS', false],
            ['Gross Profit', true],
            ['Other Income', false],
            ['Operating Expenses', false],
            ['Net Profit', true],
        ],
        'BS': [
            ['Cash And Cash Equivalents', false],
            ['Current Assets', false],
            ['Non-Current Assets', false],
            ['Fixed Assets', false],
            ['Total Assets', true],
            ['Current Liabilities', false],
            ['Non-Current Liabilities', false],
            ['Total Liabilities', true],
            ['Net Assets', true],
            ['Retained Earnings', false],
            ['Other Equity', false],
            ['Total Equity', true],
        ]
    };

    const classificationsMapping = {
        "Sales": 1,
        "COS Goods": 2,
        "COS Other": 3,
        "COS Fixed": 4,
        "Non-Cash COS": 5,
        "Fixed": 6,
        "Variable": 7,
        "Non-Cash Expenses": 8,
        "Other Income": 9,
        "Interest expense": 10,
        "Other loan interest": 11,
        "Interest Received (Excess Cash)": 12,
        //"Tax expense (notional)": 13,
        //"Adjustments"
        "Dividends paid": 14,
        //"Adjustments to Retained Income"
        "Share Capital": 37,
        "Accum. retained income": 38,
        "Reserves": 39,
        "Other Equity": 40,
        "Deferred tax": 29,
        "Dividends (other funding)": 30,
        "Other Non-Current Liabilities": 31,
        "Short term debt": 33,
        "Long term debt": 34,
        "Other Loans": 35,
        "Excess Cash": 36,
        "Cash And Cash Equivalents": 17,
        "Accounts receivable": 18,
        "Inventory": 19,
        "Other current assets": 20,
        "Accounts payable": 21,
        "Tax liability": 22,
        "Other current liabilities": 23,
        "Fixed assets": 24,
        "Intangibles": 25,
        "Other non-current assets": 26,
        "Investments": 27,
    }


    const relatedClassifications = (classificationName) => {
        if (classificationsMapping.hasOwnProperty(classificationName)) {
            const classificationId = classificationsMapping[classificationName]
            return accountsNormalised?.classifications?.[classificationId]?.[minYearList]?.rows || []
        }
        return []
    }

    const formatTitle = (title) => {
        return ucWords(title).replaceAll('Cos ', 'COS ').replaceAll(' Cos', ' COS')
    }

    const importCodatFinancials = () => {
        setLoading(true)

        importCodat(company)
            .then(({ message }) => {
                toast.success(message)
                fetchFinancialImports()
            })
            .finally(() => setLoading(false))
    }

    if (loading) return <LoadingSpinner />;

    return (
        <Box>
            <Box px={3}>
                <PageTitle
                    subtitle={t('financialImports.subtitle')}
                >
                    <input
                        ref={fileInput}
                        type="file"
                        accept=".xlsx, .xls, .csv"
                        name="file"
                        style={{ display: 'none' }}
                        onChange={(e) => uploadFinancials(e.target.files[0])}
                    />
                </PageTitle>
            </Box>

            <Card title={(
                <>
                    <Box>{t('Accounts')} - {t(isNormalised ? 'Normalised' : 'Original')}</Box>

                    <Flex sx={{
                        ml: 'auto',
                        height: '30px',
                        alignItems: 'stretch',
                        gap: 3,
                        '& button': {
                            height: '100%',
                        }
                    }}>
                        <DropdownModal
                            title="Filter"
                            buttonText="Filter"
                            buttonProps={{
                                icon: 'filter'
                            }}
                            variant="smallGray"
                        >
                            <Box>{t('Select Year')} {!isNormalised && t('Range')}</Box>
                            <Flex mt={1}>
                                <Box>
                                    <SelectField
                                        value={minYearList}
                                        onChange={(val) => setMinYearList(val)}
                                        options={yearsRange}
                                        placeholder="From Year"
                                        sx={{ padding: '10px 30px 10px 16px', minWidth: '130px', fontSize: 12 }}
                                        boxSx={null}
                                    />
                                </Box>
                                {!isNormalised && <Box ml="2">
                                    <SelectField
                                        value={maxYearList}
                                        onChange={(val) => setMaxYearList(val)}
                                        options={yearsRange}
                                        placeholder="To Year"
                                        sx={{ padding: '10px 30px 10px 16px', minWidth: '130px', fontSize: 12 }}
                                        boxSx={null}
                                    />
                                </Box>}
                            </Flex>
                            <Box mt={3}>Show Me</Box>
                            <Flex mt={1}>
                                <Button variant={!isBalanceSheetActive ? 'primary' : 'secondary'} onClick={() => { setActiveSections([]); setType('P&L') }} sx={{ fontSize: '12px', width: '130px', padding: '10px' }}>Profit & Loss</Button>
                                <Button variant={isBalanceSheetActive ? 'primary' : 'secondary'} ml="1" onClick={() => { setActiveSections([]); setType('BS') }} sx={{ fontSize: '12px', width: '130px', padding: '10px' }}>Balance Sheet</Button>
                            </Flex>
                            <Box mt={3}>Type</Box>
                            <Flex mt={1}>
                                <Button variant={!isNormalised ? 'primary' : 'secondary'} onClick={() => { setActiveSections([]); setIsNormalised(false) }} sx={{ fontSize: '12px', width: '130px', padding: '10px' }}>Original</Button>
                                <Button variant={isNormalised ? 'primary' : 'secondary'} ml="1" onClick={() => { setActiveSections([]); setIsNormalised(true) }} sx={{ fontSize: '12px', width: '130px', padding: '10px' }}>Normalised</Button>
                            </Flex>
                        </DropdownModal>

                        <DropdownModal
                            title="More Options"
                            buttonText="More Options"
                            buttonProps={{
                                icon: 'dots',
                                sx: {
                                    p: '4px 8px'
                                }
                            }}
                            variant="transparent"
                        >
                            <Box mb={1}>{t('Financials')}</Box>
                            {company?.codat_id &&
                                <Button variant='secondary' onClick={() => importCodatFinancials()} sx={{ fontSize: '12px', padding: '10px', width: '100%', mb: 2 }}>
                                    <Flex alignItems='center'>
                                        <Icon
                                            icon="upload"
                                            title="Import Financial Statement"
                                            stroke={theme.colors.primary}
                                        />
                                        <Box pl={2}>
                                            {t('Import Financial Accounts')}
                                        </Box>
                                    </Flex>
                                </Button>
                            }
                            <Button variant='secondary' onClick={() => fileInput.current.click()} sx={{ fontSize: '12px', padding: '10px', width: '100%' }}>
                                <Flex alignItems='center'>
                                    <Icon
                                        icon="upload"
                                        title="Add Financial Statement"
                                        stroke={theme.colors.primary}
                                    />
                                    <Box pl={2}>
                                        {t('Upload Financial Accounts')}
                                    </Box>
                                </Flex>
                            </Button>
                            {accounts !== null &&
                                <Button variant='secondary' mt={2} onClick={() => downloadFinancialImportFile({ importId, companyId, fileName: 'Financial Import.xlsx' })} sx={{ fontSize: '12px', padding: '10px', width: '100%' }}>
                                    <Flex alignItems='center'>
                                        <Icon
                                            icon="download"
                                            title="Download Financial Statement"
                                            stroke={theme.colors.primary}
                                        />
                                        <Box pl={2}>
                                            {t('Download Financial Accounts')}
                                        </Box>
                                    </Flex>
                                </Button>}
                            <Box mt={3}>{t('Normalise')}</Box>
                            <Flex mt={1}>
                                <Button variant='secondary' onClick={() => normalise()} sx={{ fontSize: '12px', width: '130px', padding: '10px' }}>{t('Generate')}</Button>
                                <Button variant='secondary' ml="1" onClick={() => finalise()} sx={{ fontSize: '12px', width: '130px', padding: '10px' }}>{t('Finalise')}</Button>
                            </Flex>
                        </DropdownModal>

                        {!editing ? <Button variant='smallGray' onClick={() => setEditing(true)}>{t('Edit')}</Button> : (
                            <>
                                <Button variant='primarySmall' onClick={() => saveUpdates()}>{t('Save')}</Button>
                                <Button variant='smallGray' onClick={() => setEditing(false)}>{t('Cancel')}</Button>
                            </>
                        )}
                    </Flex>
                </>
            )} titleSx={{ alignItems: 'center' }}>
                {isNormalised && <>
                    {!accountsNormalised && 'Accounts have not been normalised please generate them from the "More Options" dropdown.'}
                    {!minYearList && 'Select the financial year from the filter dropdown.'}
                    {minYearList && accountsNormalised && accountsNormalised.financialReports && <>
                        {accountsNormalised.financialReports[minYearList] && accountsNormalised.financialReports[minYearList].map((report, index) => {
                            if ((type === 'P&L' && index === 0) || (type === 'BS' && (index === 1 || index === 2)))
                                return <Box key={index}>
                                    {report.map((blocks, reportIndex) => <Box className={styles.activeReportBlock} pb={15} key={reportIndex}>
                                        {blocks.map(([title, value], indexBlock) => {
                                            const isHeader = isAllCaps(title);
                                            const secondHeader = value !== null;
                                            const sectionVal = `${reportIndex}-${indexBlock}`
                                            const isActiveSection = activeSections.includes(sectionVal)
                                            const el = <Box key={sectionVal}>
                                                <Flex
                                                    className={cx(
                                                        secondHeader && isHeader && styles.activeReportBar,
                                                        isHeader && styles.activeReportHeader,
                                                        !isHeader && styles.reportRow
                                                    )}
                                                    mb={isHeader ? 1 : 0}
                                                    mt={isHeader ? 2 : 0}
                                                    alignItems="center"
                                                    onClick={() => setActiveSections(isActiveSection ? activeSections.filter(id => sectionVal !== id) : [...activeSections, sectionVal])}>
                                                    {!isHeader && <Box><Icon icon="arrowUp" stroke="#093467" className={!isActiveSection ? undefined : styles.rotate} /></Box>}
                                                    <Box>{formatTitle(title)}</Box>
                                                    {value !== null && <Box ml="auto"> {formatCurrency(value, currency)} </Box>}
                                                </Flex>
                                                {isActiveSection && relatedClassifications(title).map(([type, title, value], index) => <Flex
                                                    className={styles.reportRow}
                                                    key={`active-${index}`}>
                                                    <Box>{ucWords(type)}: {ucWords(title)}</Box>
                                                    {value !== null && <Box ml="auto"> {formatCurrency(value, currency)} </Box>}
                                                </Flex>)}
                                            </Box>;
                                            return el;
                                        })}
                                    </Box>
                                    )}
                                </Box>
                            return null;
                        })}
                        {!accountsNormalised.financialReports[minYearList] && 'There are no financial records for this year'}
                    </>
                    }
                </>}
                {!isNormalised && !!accounts && typeof accounts === 'object' && Object.keys(accounts).length > 0 && <table className={styles.dataTable} cellSpacing="0" cellPadding="0">
                    <thead>
                        <tr>
                            <td />
                            <td>Item</td>
                            <td>Category Type</td>
                            {yearsRange.map(({ value }, yearIndex) => (!minYearList || value >= minYearList) && (!maxYearList || value <= maxYearList) && <td key={yearIndex} className={styles.textRight}>{value}</td>)}
                            <td />
                        </tr>
                    </thead>
                    {accounts.hasOwnProperty(type) && sectionsList[type].map(([sectionName, isTotal], sectionIndex) => {
                        if (isTotal && accounts.Totals && accounts.Totals[type].hasOwnProperty(sectionName))
                            return <tbody key={sectionIndex}>
                                <tr className={styles.headerTotalRow}>
                                    <td />
                                    <td>{sectionName}</td>
                                    <td />
                                    {yearsRange.map(({ value }, yearIndex) => !!accounts.Totals && (!minYearList || value >= minYearList) && (!maxYearList || value <= maxYearList) &&
                                        <td key={yearIndex} className={styles.currency}>{!isNaN(accounts.Totals[type][sectionName][value]) && formatCurrency(accounts.Totals[type][sectionName][value], currency)}</td>)}
                                    <td />
                                </tr>
                            </tbody>
                        if (accounts[type].hasOwnProperty(sectionName)) {
                            const sectionRows = typeof accounts[type][sectionName] === 'object' ? Object.entries(accounts[type][sectionName]) : null
                            const isActiveSection = activeSections.includes(sectionIndex)
                            return <tbody key={sectionIndex}>
                                <tr
                                    className={styles.headerRow}
                                    onClick={() => setActiveSections(isActiveSection ? activeSections.filter(id => sectionIndex !== id) : [...activeSections, sectionIndex])}>
                                    <td><Icon icon="arrowUp" color="#093467" className={!isActiveSection ? undefined : styles.rotate} /></td>
                                    <td>{sectionName}</td>
                                    <td />
                                    {yearsRange.map(({ value }, yearIndex) => !!accounts.Totals && (!minYearList || value >= minYearList) && (!maxYearList || value <= maxYearList) &&
                                        <td key={yearIndex} className={styles.currency}>{!isActiveSection && !isNaN(accounts.Totals[type][sectionName][value]) && formatCurrency(accounts.Totals[type][sectionName][value], currency)}</td>)}
                                    <td />
                                </tr>
                                {isActiveSection && <>
                                    {sectionRows?.map(([rowName, { data, match, label }], rowIndex) => ([
                                        <tr key={rowIndex} className={styles.normalRows}>
                                            <td />
                                            <td> {rowName} </td>
                                            <td>
                                                {!editing && getClassificationName(match, label)}
                                                {editing &&
                                                    <SelectField
                                                        value={label || match}
                                                        onChange={(value) => updateAccountsMatch(sectionName, rowName, value)}
                                                        options={classifications}
                                                        placeholder="Select Category"
                                                        sx={{ padding: '10px 30px 10px 16px', minWidth: '200px', fontSize: 14, fontWeight: 600 }}
                                                        boxSx={null}
                                                        grouped={true}
                                                    />}
                                            </td>
                                            {yearsRange.map(({ value }, yearIndex) => (!minYearList || value >= minYearList) && (!maxYearList || value <= maxYearList) &&
                                                <td key={`${rowIndex}-${yearIndex}`} className={styles.currency}>
                                                    {(!editing || yearIndex + 1 !== yearsRange.length) && data.hasOwnProperty(value) && formatCurrency(data[value], currency)}
                                                    {/* TODO: Fix fiscal years */}
                                                    {editing && yearIndex + 2 >= yearsRange.length && <TextField
                                                        value={data[value]}
                                                        onChange={(val) => updateAccountsValue(sectionName, rowName, value, val)}
                                                        boxSx={{ width: '120px' }}
                                                        sx={{ textAlign: 'right', margin: 0, padding: '5px' }}
                                                        errors={errors?.[`data.${type}.${sectionName}.${rowName}.data.${value}`]}
                                                        errorsSx={{
                                                            margin: 0,
                                                            textAlign: 'left'
                                                        }}
                                                    />}
                                                </td>
                                            )}
                                            <td>
                                                <Flex variant="clickable" alignItems="center" onClick={() => setCommentSectionName(rowName === commentSectionName ? '' : rowName)} className={styles.commentBubble}>
                                                    <Icon icon="commentNew" />
                                                </Flex>
                                            </td>
                                        </tr>,
                                        rowName === commentSectionName && <tr key='comment-section' className={styles.normalRows}><td colSpan="99"><CommentModule type={'financial-import'} id={importId} /></td></tr>]
                                    ))}

                                    <tr className={styles.subTotalRows}>
                                        <td></td>
                                        <td>Total {sectionName}</td>
                                        <td />
                                        {yearsRange.map(({ value }, yearIndex) => !!accounts.Totals && (!minYearList || value >= minYearList) && (!maxYearList || value <= maxYearList) &&
                                            <td key={yearIndex} className={styles.currency}>{!isNaN(accounts.Totals[type][sectionName][value]) && formatCurrency(accounts.Totals[type][sectionName][value], currency)}</td>)}
                                        <td />
                                    </tr>
                                </>}
                            </tbody>
                        }
                        return null
                    })}
                </table>}
            </Card>
        </Box>
    );
};

export default connect((state) => ({ financialHistoryYear: state.yearSelector.financialHistoryYear, company: state.company }), { setFinancialHistoryYear })(FinancialImports);
