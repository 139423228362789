import { getTaskStatus } from '@util/Enum';
import { Link } from 'react-router-dom';
import { Box, Flex } from 'rebass';
import styles from '../Assets/styles.module.scss';
import { Icon } from '@svg';
import React from 'react';
import { connect } from 'react-redux';
import { isAdvisor } from '@util/Functions';
import { isAnalyst, isConsultant } from '@util/Functions/User';
import { Badge } from '~/Common/Badge';

const TaskLink = ({ id, companyId, name, status, withLink = true, user, disabled = false, redirect = null }) => {
    const { sx: statusStyle, name: statusName } = getTaskStatus(status);

    const element = (
        <Flex className={`${styles.taskBlock}${disabled ? ' disabled' : ''}`} mb={10} alignItems="center">
            <Flex alignItems="center" fontSize={1} className={disabled ? styles.disabledLink : ''} sx={{ pr: 2 }}>
                {name}
            </Flex>

            <Box ml={'auto'} className={styles.taskStatusBox} flexShrink={0}>
                <Badge sx={statusStyle}>{statusName}</Badge>
            </Box>

            <Flex alignItems="center" pl="20px">
                <Icon icon="arrow" color={disabled ? '#E8ECEE' : undefined} title="View Task" />
            </Flex>
        </Flex>
    );

    const link = redirect
        ? redirect
        : (isAdvisor(user) || isAnalyst(user) || isConsultant(user)) && companyId
            ? `/advisor/company/${companyId}/stages/task/${id}`
            : `/company/projects/task/${id}`;

    return withLink && !disabled ? <Link to={link}>{element}</Link> : element;
};

export default connect((state) => ({ tasks: state.notifications.list, user: state.user }))(TaskLink);
